import {Injectable, OnDestroy} from '@angular/core';
import {
    CommunicationChannelConfigurationDto, CommunicationChannelService,
    ContactConfigurationService,
} from '../_services/configuration-services';
import {BehaviorSubject, Subscription} from 'rxjs';
import {CustomerConfigService} from './customer-config-service';
import {RolesService} from './roles-service';

@Injectable({
    providedIn: 'root'
})
export class CommunicationChannelConfigService implements OnDestroy {

    private static configLoaded = false;

    private communicationChannelConfiguration: CommunicationChannelConfigurationDto | null;

    private communicationChannelConfigurationLoadedSubject$ = new BehaviorSubject(false);
    public configurationLoadedMessage = this.communicationChannelConfigurationLoadedSubject$.asObservable();

    private hasUnreadMessageSubject$ = new BehaviorSubject(false);
    public unreadMessage = this.hasUnreadMessageSubject$.asObservable();

    private hasUserAccessToContact = false;

    private customerConfigLoadedSubscription: Subscription;

    constructor(private readonly rolesService: RolesService,
                private readonly customerConfigService: CustomerConfigService,
                private readonly contactConfigurationService: ContactConfigurationService,
                private readonly communicationChannelService: CommunicationChannelService) {

        this.customerConfigLoadedSubscription = this.customerConfigService.configurationLoadedMessage.subscribe(isConfigurationLoaded => {
            if (isConfigurationLoaded) {
                this.communicationChannelConfiguration = this.customerConfigService.communicationChannelConfiguration;

                if (!this.rolesService.isVisitor()) {
                    this.fetchContactPageAccess();
                    this.fetchHasUnreadMessage();
                }
            }
        });
    }

    static isConfigLoaded(): boolean {
        return CommunicationChannelConfigService.configLoaded;
    }

    public fetchContactPageAccess(): void {
        if (!CommunicationChannelConfigService.configLoaded) {
            this.contactConfigurationService.hasAccessToContact().pipe().subscribe(hasAccess => {
                CommunicationChannelConfigService.configLoaded = true;

                this.hasUserAccessToContact = hasAccess;
                this.communicationChannelConfigurationLoadedSubject$.next(true);

            }, error => {
                this.communicationChannelConfigurationLoadedSubject$.next(false);
                throw error;
            });
        }
    }

    public fetchHasUnreadMessage(): void {
        if (this.customerConfigService.communicationChannelConfiguration.informationEnabled ||
            this.customerConfigService.communicationChannelConfiguration.surveyEnabled) {

            this.communicationChannelService.hasUnreadContentForUser().pipe().subscribe(hasUnread => {
                this.hasUnreadMessageSubject$.next(hasUnread);
            });
        }
    }

    isCommunicationChannelEnabled(): boolean {
        return this.isCommunicationChannelInformationEnabled()
            || this.isCommunicationChannelContactEnabled()
            || this.isCommunicationChannelSurveyEnabled();
    }

    isCommunicationChannelInformationEnabled(): boolean {
        return this.communicationChannelConfiguration?.informationEnabled ?? false;
    }

    isCommunicationChannelContactEnabled(): boolean {
        return (this.communicationChannelConfiguration?.contactEnabled ?? false) && this.hasUserAccessToContact;
    }

    isCommunicationChannelSurveyEnabled(): boolean {
        return this.communicationChannelConfiguration?.surveyEnabled ?? false;
    }

    ngOnDestroy(): void {
        if (!!this.communicationChannelConfigurationLoadedSubject$) {
            this.communicationChannelConfigurationLoadedSubject$.next(false);
            this.communicationChannelConfigurationLoadedSubject$.complete();
            this.communicationChannelConfigurationLoadedSubject$ = null;
        }

        this.customerConfigLoadedSubscription.unsubscribe();
    }
}

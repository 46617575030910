<div class="modal-header">
    <h4 class="modal-title" translate="ewallet.epayment.modal.title"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="reloadWalletModal.dismiss('Cross click')">
    </button>
</div>


<div class="modal-body">

    <form [formGroup]="reloadWalletForm">

        <div class="row">
            <div class="col-12">
                <!-- Amount -->
                <label class="form-label" translate="ewallet.epayment.modal.message"></label>
                <div class="input-group">
                    <input class="form-control" id="amount" name="amount" type="number"
                           [formControl]="amount"
                           (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                           [min]="0.01">
                    <span class="input-group-text">CHF</span>
                </div>
                <app-form-error [control]="amount"></app-form-error>
            </div>
        </div>

        <app-show-cgv [formGroup]="reloadWalletForm">
        </app-show-cgv>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-primary"
            [disabled]="reloadWalletForm.invalid"
            (click)="startDatatransPayment()"
            translate="ewallet.epayment.modal.confirm">
    </button>
</div>


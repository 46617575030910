import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    IotRegisterDto,
    IotRegisterService, ISortCriteriaDto,
    PageableRequestDtoOfUserAlarmSearchCriteriaDto,
    PagedResultDtoOfUserAlarmDto,
    SortCriteriaDto,
    SortDirection,
    UserAlarmSearchCriteriaDto,
    UserAlarmService,
} from '../../../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormUtils} from '../../../../_shared/form-utils';
import {faBan, faCalendar, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {DateUtils} from '../../../../_shared/date-utils';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from '../../../../_shared/custom-date-formatter';
import {TranslateUtils} from '../../../../_shared/translate-utils';

@Component({
    selector: 'app-user-alarm-logs',
    templateUrl: './user-alarm-logs.component.html',
    providers: [
        {provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class UserAlarmLogsComponent implements OnInit, OnDestroy {

    @Input() serviceId: string;

    @ViewChild('alarmsTable') alarmsTable;

    // Filters
    filter: {
        formGroup: UntypedFormGroup,
        // State DropDown
        statesDropdownList: any[]
    } = {
        formGroup: null,
        statesDropdownList: [{
            key: 'active',
            value: true
        }, {
            key: 'released',
            value: false
        }]
    };

    textInputPattern = FormUtils.textInputPattern;
    dateTimeFormat = DateUtils.dateTimeFormat;

    // Form
    availableIotRegisters: IotRegisterDto[] = [];

    // Icons
    icons = {
        times: faTimes,
        calendar: faCalendar,
        disable: faBan
    };

    // Pagination
    readonly pageSize = 10;
    crtPage = 0;

    // Table content
    alarms: PagedResultDtoOfUserAlarmDto = new PagedResultDtoOfUserAlarmDto();

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Desc,
        property: 'startDateTime'
    });

    private pageRequest: PageableRequestDtoOfUserAlarmSearchCriteriaDto;

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(private readonly translateService: TranslateService,
                private readonly userAlarmService: UserAlarmService,
                private readonly iotRegisterService: IotRegisterService)
    {
        this.filter.formGroup = new UntypedFormGroup({
                iotRegister: new UntypedFormControl(''),
                designation: new UntypedFormControl(''),
                state: new UntypedFormControl(''),
                startPeriod: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
                endPeriod: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
            },
            {updateOn: 'change'});

        this.filter.formGroup.reset();
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.initializeBasePageRequest();

        this.fetchIotRegisters();
        this.fetchUserAlarms(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    fetchIotRegisters(): void {
        this.iotRegisterService.getMyAlarmRegisters().pipe().subscribe(registers => {
            this.availableIotRegisters = registers;
        });
    }

    fetchUserAlarms(pageableRequest: PageableRequestDtoOfUserAlarmSearchCriteriaDto): void {
        this.userAlarmService.searchAlarms(pageableRequest).pipe().subscribe(alarms => {
            this.alarms = alarms;
        });
    }

    applyFilters(): void {
        if (this.filter.formGroup.invalid) {
            return;
        }

        this.crtPage = 0;

        const startPeriod = this.startPeriod.value;
        const endPeriod = this.endPeriod.value;

        const startDateFrom = DateUtils.ngbDateStructToDate(startPeriod.from);
        startDateFrom?.setHours(0, 0, 0, 0);
        const startDateTo = DateUtils.ngbDateStructToDate(startPeriod.to);
        startDateTo?.setHours(23, 59, 59, 59);

        const endDateFrom = DateUtils.ngbDateStructToDate(endPeriod.from);
        endDateFrom?.setHours(0, 0, 0, 0);
        const endDateTo = DateUtils.ngbDateStructToDate(endPeriod.to);
        endDateTo?.setHours(23, 59, 59, 59);

        this.initializeBasePageRequest();

        this.pageRequest.criteria = new UserAlarmSearchCriteriaDto({
            designation: this.designation.value,
            iotRegisterIds: this.iotRegister.value ? [this.iotRegister.value.id] : null,
            startDateFrom,
            startDateTo,
            endDateFrom,
            endDateTo,
            active: this.state.value != null ? this.state.value : null,
            sortCriteriaDto: this.currentSort
        });

        this.fetchUserAlarms(this.pageRequest);
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;

        this.fetchUserAlarms(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;

        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = new SortCriteriaDto(sortCriteriaDto);

        this.fetchUserAlarms(this.pageRequest);
    }

    clearFilter(): void {
        // Clear inputs
        this.filter.formGroup.reset();

        // clear search
        this.initializeBasePageRequest();
        this.fetchUserAlarms(this.pageRequest);
    }

    private initializeBasePageRequest(): void {
        this.pageRequest = new PageableRequestDtoOfUserAlarmSearchCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new UserAlarmSearchCriteriaDto({
            designation: null,
            iotRegisterIds: null,
            startDateFrom: null,
            startDateTo: null,
            endDateFrom: null,
            endDateTo: null,
            active: null,
            sortCriteriaDto: this.currentSort
        });
    }

    getIotRegisterLabel(iotRegister: IotRegisterDto): string {
        return iotRegister['label' + this.crtLang.toUpperCase()];
    }

    resetDatePicker(control: UntypedFormControl) {
        control.setValue(null);
    }

    get iotRegister(): UntypedFormControl {
        return this.filter.formGroup.get('iotRegister') as UntypedFormControl;
    }

    get designation(): UntypedFormControl {
        return this.filter.formGroup.get('designation') as UntypedFormControl;
    }

    get state(): UntypedFormControl {
        return this.filter.formGroup.get('state') as UntypedFormControl;
    }

    get startPeriod(): UntypedFormGroup {
        return this.filter.formGroup.get('startPeriod') as UntypedFormGroup;
    }

    get startPeriodFrom(): UntypedFormControl {
        return this.startPeriod.get('from') as UntypedFormControl;
    }

    get startPeriodTo(): UntypedFormControl {
        return this.startPeriod.get('to') as UntypedFormControl;
    }

    get endPeriod(): UntypedFormGroup {
        return this.filter.formGroup.get('endPeriod') as UntypedFormGroup;
    }

    get endPeriodFrom(): UntypedFormControl {
        return this.endPeriod.get('from') as UntypedFormControl;
    }

    get endPeriodTo(): UntypedFormControl {
        return this.endPeriod.get('to') as UntypedFormControl;
    }

}

import {Injectable} from '@angular/core';
import {
    BookingSlotDto,
} from '../_services/configuration-services';
import {DateUtils} from './date-utils';

@Injectable({
    providedIn: 'root',
})
export class BookingSlotUtils {

    public static isSlotInPast(slot: BookingSlotDto): boolean {
        const startDate = DateUtils.dateToNgbDateStruct(slot.date);
        const startTime = DateUtils.stringToNgbTimeStruct(slot.startTime);

        const bookingStartDate = DateUtils.ngbDateStructToDateTime(startDate, startTime);

        return DateUtils.isInPast(bookingStartDate);
    }

}

<div class="content-container bg-white" [appHasAnyRole]="[AppRoles.admin, AppRoles.alarmManager]">

    <h1 translate="alerts.management.title"></h1>

    <form [formGroup]="filter.formGroup"
          (keyup.enter)="applyFilters()">

        <div class="row">

            <!-- Classification -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="alerts.management.filter.label.classification"></label>
                <select class="form-control form-select" id="classification" name="classification"
                        formControlName="classification">
                    <option [ngValue]="null">-</option>
                    <option *ngFor="let classification of filter.classificationsDropdownList"
                            [ngValue]="classification">{{'common.alarmClassification.' + classification | translate}}</option>
                </select>
            </div>

            <!-- Appeared Date -->
            <div class="default-filter-form-field">
                <app-date-period-picker inputLabel="alerts.management.filter.label.appearedDate"
                                        [periodControl]="period"></app-date-period-picker>
            </div>

            <!-- Location -->
            <div class="default-filter-form-field">
                <label class="form-label" for="location" translate="alerts.management.filter.label.location"></label>
                <input class="form-control" id="location" type="text" name="location"
                       formControlName="location"
                       placeholder="{{'alerts.management.filter.label.location' | translate}}"
                       maxlength="50" pattern="{{textInputPattern}}" />
            </div>

            <!-- Designation -->
            <div class="default-filter-form-field">
                <label class="form-label" for="designation" translate="alerts.management.filter.label.designation"></label>
                <input class="form-control" id="designation" type="text" name="designation"
                       formControlName="designation"
                       placeholder="{{'alerts.management.filter.label.designation' | translate}}"
                       maxlength="50" pattern="{{textInputPattern}}" />
            </div>

            <!-- Comparison symbol -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="alerts.device.filter.label.comparisonSymbol"></label>
                <select class="form-control form-select" id="comparisonSymbol" name="comparisonSymbol"
                        formControlName="comparisonSymbol">
                    <option [ngValue]="null">-</option>
                    <option *ngFor="let cs of filter.comparisonSymbolsDropdownList"
                            [ngValue]="cs">{{'common.comparisonSymbol.' + cs | translate}}</option>
                </select>
            </div>

            <!-- Threshold -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="alerts.device.filter.label.threshold"></label>
                <input class="form-control" id="threshold" type="number" name="threshold"
                       placeholder="{{'alerts.management.filter.label.threshold' | translate}}"
                       formControlName="threshold"/>
            </div>

        </div>

        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   (click)="clearFilter()">

            <input class="btn btn-primary" type="button"
                   [value]="'common.filter' | translate"
                   (click)="applyFilters()"
                   [disabled]="filter.formGroup.invalid">
        </div>

    </form>

    <!-- Table -->
    <ngx-datatable #devicesTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="alerts.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   rowHeight="50"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="alerts.rowCount"
                   [sorts]="[{prop: 'appearedDateTime', dir: 'desc'}]"
                   [messages]="{
                       totalMessage: 'common.datatable.total' | translate
                   }"
                   [offset]="crtPage"
                   [limit]="alerts.pageSize"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [scrollbarH]="true">

        <ngx-datatable-column [width]="100" prop="alertClassification" sortable="true"
                              name="{{'alerts.management.table.classification' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ 'common.alarmClassification.' + row.alertClassification | translate }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="125" prop="appearedDateTime" sortable="true"
                              name="{{'alerts.management.table.appearedDate' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.appearedDateTime | date:dateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>


        <ngx-datatable-column [width]="250" prop="deviceDesignation{{crtLang.toUpperCase()}}" sortable="true"
                              name="{{'alerts.management.table.designation' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="location" sortable="true"
                              name="{{'alerts.management.table.location' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="125" prop="comparisonSymbol" sortable="true"
                              name="{{'alerts.management.table.comparisonSymbol' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span>{{'common.comparisonSymbol.' + row.comparisonSymbol | translate}} {{row.threshold}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="35" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <button class="btn btn-link btn-sm"
                        (click)="releaseAlert(row)"
                        title="{{ 'common.tooltip.release' | translate }}">
                    <fa-icon [icon]="icons.release"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div class="page-count">
                    {{ rowCount }} {{ 'common.datatable.total' | translate }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="devicesTable.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

</div>

<div class="modal-header">
    <h4 translate="map.modal.title"></h4>
    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <h5 translate="map.modal.import.title"></h5>
    <form>
        <div class="row">
            <div class="col-12">
                <label class="form-label" translate="map.modal.import.maxSize"></label>
                <input type="file" accept="image/svg+xml"
                       (change)="fileSelected($event.target.files)">
                <p class="text-danger font-italic" *ngIf="!mapValid"
                   translate="map.modal.import.maxSizeError"></p>
            </div>
        </div>
    </form>

    <div class="d-flex flex-row-reverse">
        <button class="btn btn-primary" type="submit"
                (click)="uploadMap()"
                [disabled]="!mapToUpload || !mapValid"
                translate="services.actions.import">
        </button>
    </div>
    <hr class="mt-4 mb-4">

    <form [formGroup]="mapForm">
        <h5 translate="map.modal.coordinates.title"></h5>

        <div class="row">

            <div class="col-12">
                <label class="form-label" translate="map.modal.coordinates.tl"></label>

                <div class="input-group mb-3">
                    <span class="input-group-text">
                        <label class="form-label" translate="map.modal.latitude"></label>
                    </span>
                    <input class="form-control" type="text"
                           [placeholder]="placeholder" pattern="{{textInputPattern}}"
                           formControlName="latitudeTl">
                </div>

                <div class="input-group mb-3">
                    <span class="input-group-text">
                        <label class="form-label" translate="map.modal.longitude"></label>
                    </span>
                    <input class="form-control" type="text"
                           [placeholder]="placeholder" pattern="{{textInputPattern}}"
                           formControlName="longitudeTl">
                </div>
            </div>

            <div class="col-12">
                <label class="form-label" translate="map.modal.coordinates.tr"></label>

                <div class="input-group mb-3">
                    <span class="input-group-text">
                        <label class="form-label" translate="map.modal.latitude"></label>
                    </span>
                    <input class="form-control" type="text"
                           [placeholder]="placeholder" pattern="{{textInputPattern}}"
                           formControlName="latitudeTr">
                </div>

                <div class="input-group mb-3">
                    <span class="input-group-text">
                        <label class="form-label" translate="map.modal.longitude"></label>
                    </span>
                    <input class="form-control" type="text"
                           [placeholder]="placeholder" pattern="{{textInputPattern}}"
                           formControlName="longitudeTr">
                </div>
            </div>

            <div class="col-12">
                <label class="form-label" translate="map.modal.coordinates.br"></label>

                <div class="input-group mb-3">
                    <span class="input-group-text">
                        <label class="form-label" translate="map.modal.latitude"></label>
                    </span>
                    <input class="form-control" type="text"
                           [placeholder]="placeholder" pattern="{{textInputPattern}}"
                           formControlName="latitudeBr">
                </div>

                <div class="input-group mb-3">
                    <span class="input-group-text">
                        <label class="form-label" translate="map.modal.longitude"></label>
                    </span>
                    <input class="form-control" type="text"
                           [placeholder]="placeholder" pattern="{{textInputPattern}}"
                           formControlName="longitudeBr">
                </div>
            </div>

            <div class="col-12">
                <label class="form-label" translate="map.modal.coordinates.bl"></label>

                <div class="input-group mb-3">
                    <span class="input-group-text">
                        <label class="form-label" translate="map.modal.latitude"></label>
                    </span>
                    <input class="form-control" type="text"
                           [placeholder]="placeholder"  pattern="{{textInputPattern}}"
                           formControlName="latitudeBl">
                </div>

                <div class="input-group mb-3">
                    <span class="input-group-text">
                        <label class="form-label" translate="map.modal.longitude"></label>
                    </span>
                    <input class="form-control" type="text"
                           [placeholder]="placeholder" pattern="{{textInputPattern}}"
                           formControlName="longitudeBl">
                </div>
            </div>

        </div>

    </form>

    <div class="btn-bar">
        <button class="btn btn-primary" type="submit"
                (click)="save()"
                [disabled]="mapForm.invalid"
                translate="common.save">
        </button>
    </div>
</div>



import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    LightCompleteDto, PublicLightingExternalStatus,
    PublicLightingLightService, PublicLightingState
} from '../../../../../_services/configuration-services';
import {firstValueFrom, Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormUtils} from '../../../../../_shared/form-utils';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {DateUtils} from "../../../../../_shared/date-utils";

@Component({
  selector: 'app-view-light-modal',
  templateUrl: './view-light-modal.component.html'
})
export class ViewLightModalComponent implements OnInit, OnDestroy {

    @Input() lightId: string;

    light: LightCompleteDto;
    PublicLightingExternalStatus = PublicLightingExternalStatus;
    PublicLightingState = PublicLightingState;
    isLoaded = false;

    crtLang: string;
    FormUtils = FormUtils;
    dateTimeFormat = DateUtils.dateTimeFormat;
    private languageSubscription: Subscription;

    constructor(readonly activeModal: NgbActiveModal,
                private readonly lightService: PublicLightingLightService,
                private readonly translateService: TranslateService,
                private router: Router) {

        this.crtLang = this.translateService.currentLang;
        this.languageSubscription = this.translateService.onLangChange.subscribe(langChangeEvent => {
            this.crtLang = langChangeEvent.lang;
        });
    }

    async ngOnInit(): Promise<void> {
        this.light = await firstValueFrom(this.lightService.getLightById(this.lightId));
        this.isLoaded = true;
    }

    ngOnDestroy(): void {
        this.languageSubscription.unsubscribe();
    }

    async goToTechnicalAlarmDetails(): Promise<void> {
        const queryParams = { alarmId: this.light.alarmId };
        await this.router.navigate(['/alarms/alarm-management'], { queryParams: queryParams });
    }
}

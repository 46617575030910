<div class="content-container bg-white">
    <h1>{{ (!equipmentId ? 'equipments.technical.addTitle' : 'equipments.technical.modifyTitle') | translate }}</h1>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary"
                (click)="back()"
                translate="common.back">
        </button>

        <button class="btn btn-outline-danger" *ngIf="!!equipmentId"
                (click)="deleteEquipment()"
                translate="services.actions.delete">
        </button>

        <button class="btn btn-primary" *ngIf="!!equipmentId"
                (click)="openEventModal()" translate="equipments.events.add">
        </button>
    </div>

    <h4 translate="equipments.technical.equipment"></h4>

    <form [formGroup]="equipmentForm">

        <div class="row">

            <div class="col-12 col-md-6">
                <label class="form-label" for="equipmentName">{{'equipments.technical.form.equipment.name'|translate}}*</label>
                <input class="form-control" id="equipmentName" type="text"
                       [formControl]="equipmentName"
                       placeholder="{{ 'equipments.technical.placeholder.equipment.name' | translate }}">
                <app-form-error [control]="equipmentName"></app-form-error>
            </div>

            <div class="col-12 col-md-6">
                <label class="form-label" for="equipmentIdNumber" translate="equipments.technical.form.equipment.idNumber"></label>
                <input class="form-control col-11" id="equipmentIdNumber" type="text"
                       [formControl]="equipmentIdNumber"
                       placeholder="{{ 'equipments.technical.placeholder.equipment.idNumber' | translate }}">
            </div>

            <div class="col-12 col-md-6">
                <label class="form-label" for="equipmentLocation">{{ 'equipments.technical.form.equipment.location' | translate }}*</label>
                <input class="form-control" id="equipmentLocation" type="text"
                       [formControl]="equipmentLocation"
                       placeholder="{{ 'equipments.technical.placeholder.equipment.location' | translate }}">
                <app-form-error [control]="equipmentLocation"></app-form-error>
            </div>

            <div class="col-12 col-md-6">
                <label class="form-label" for="equipmentInstallDate" translate="equipments.technical.form.equipment.installDate"></label>
                <div class="input-group">
                    <input class="form-control" id="equipmentInstallDate" name="equipmentInstallDate"
                           [formControl]="equipmentInstallDate"
                           placeholder="{{ 'common.placeholders.dateFormat' | translate}}"
                           ngbDatepicker #d="ngbDatepicker">
                    <span class="input-group-text">
                        <button class="btn btn-link btn-sm" (click)="d.toggle()">
                            <fa-icon [icon]="icons.calendar"></fa-icon>
                        </button>
                        <button class="btn btn-link btn-sm" *ngIf="!!equipmentInstallDate.value"
                                (click)="equipmentInstallDate.setValue(null);equipmentInstallDate.markAsDirty();">
                            <fa-icon [icon]="icons.close"></fa-icon>
                        </button>
                    </span>
                </div>
                <app-form-error [control]="equipmentInstallDate"></app-form-error>
            </div>

            <div class="col-12 col-md-6">
                <label class="col-form-label" for="equipmentCategory">{{ 'equipments.technical.form.equipment.category' | translate }}*</label>
                <select class="form-control form-select" id="equipmentCategory" name="equipmentCategory"
                        [formControl]="equipmentCategory">
                    <option *ngFor="let item of equipmentCategories;"
                            [ngValue]="item.id">{{item['label' + crtLang.toUpperCase()]}}</option>
                </select>
                <app-form-error [control]="equipmentCategory"></app-form-error>
            </div>

        </div>

        <hr class="mb-6">

        <div class="row">
            <div class="col-12 col-md-6">
                <label class="form-label" for="equipmentBrand">{{ 'equipments.technical.form.equipment.brand' | translate }}</label>
                <input class="form-control" id="equipmentBrand" type="text"
                       [formControl]="equipmentBrand"
                       placeholder="{{ 'equipments.technical.placeholder.equipment.brand' | translate }}">
            </div>

            <div class="col-12 col-md-6">
                <label class="form-label" for="equipmentProductName">{{ 'equipments.technical.form.equipment.productName' | translate }}</label>
                <input class="form-control" id="equipmentProductName" type="text"
                       [formControl]="equipmentProductName"
                       placeholder="{{ 'equipments.technical.placeholder.equipment.productName' | translate }}">
            </div>

            <div class="col-12 col-md-6">
                <label class="form-label" for="equipmentSupplierName">{{ 'equipments.technical.form.equipment.supplierName' | translate }}</label>
                <input class="form-control" id="equipmentSupplierName" type="text"
                       [formControl]="equipmentSupplierName"
                       placeholder="{{ 'equipments.technical.placeholder.equipment.supplierName' | translate }}">
            </div>

            <div class="col-12 col-md-6">
                <label class="form-label" for="equipmentSupplierContact">{{ 'equipments.technical.form.equipment.supplierContact' | translate }}</label>
                <textarea class="form-control" id="equipmentSupplierContact" type="textarea"
                          maxlength="255"
                          name="equipmentSupplierContact"
                          [formControl]="equipmentSupplierContact"
                          placeholder="{{ 'equipments.technical.placeholder.equipment.supplierContact' | translate }}"></textarea>
            </div>

        </div>


        <div class="btn-bar">
            <button class="btn btn-outline-secondary"
                    (click)="back()"
                    translate="common.back">
            </button>

            <button type="submit" class="btn btn-primary"
                    (click)="addOrUpdateEquipment()"
                    [disabled]="equipmentForm.invalid || equipmentForm.pristine">
                {{ (!!equipmentId ? 'common.save' : 'common.create') | translate }}
            </button>
        </div>
    </form>

    <hr class="mb-5" *ngIf="!!equipmentId">

    <document-management-component *ngIf="equipmentId"
                                   [id]="equipmentId"
                                   [documentManagementType]="documentManagementTypeEnum.Equipment"></document-management-component>

    <hr class="mb-5" *ngIf="!!equipmentId">

    <app-maintenance-contract-view *ngIf="!!equipmentId"
                                   #maintenanceContractViewComponent
                                   [contractForm]="maintenanceForm"
                                   [contractTitle]='"equipments.technical.maintenanceContract"'
                                   [contract]="maintenanceContract"
                                   [contractType]="ContractType.Maintenance"
                                   [equipmentId]="equipmentId"
                                   (updateData)="updateContractData($event)"></app-maintenance-contract-view>

    <hr class="mb-6" *ngIf="!!equipmentId">

    <app-maintenance-contract-view *ngIf="!!equipmentId"
                                   #troubleshootingContractViewComponent
                                   [contractForm]="troubleshootingForm"
                                   [contractTitle]='"equipments.technical.troubleshootingContract"'
                                   [contract]="troubleshootingContract"
                                   [contractType]="ContractType.Troubleshooting"
                                   [equipmentId]="equipmentId"
                                   (updateData)="updateContractData($event)"></app-maintenance-contract-view>

    <div class="btn-bar" *ngIf="!!equipmentId">
        <button class="btn btn-outline-secondary"
                (click)="back()"
                translate="common.back">
        </button>
    </div>

</div>

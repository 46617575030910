import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {RolesService} from './roles-service';

@Directive({
    selector: '[appHasAnyRole]'
})
export class HasAnyRoleDirective implements OnInit {

    private static readonly visibleCssClass = 'has-any-role-hidden';

    @Input('appHasAnyRole') // @ts-ignore
    allowedRoles: string[] = null;

    constructor(private readonly element: ElementRef,
                private readonly renderer: Renderer2,
                private readonly rolesService: RolesService) {
    }

    ngOnInit(): void {
        this.setVisible(this.rolesService.hasAnyRole(this.allowedRoles));
    }

    private setVisible(visible: boolean): void {
        if (visible) {
            this.renderer.removeClass(this.element.nativeElement, HasAnyRoleDirective.visibleCssClass);
        } else {
            this.renderer.addClass(this.element.nativeElement, HasAnyRoleDirective.visibleCssClass);
        }
    }
}

import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {
    MeasuringPointLightDto,
    MeasuringPointService,
    MeasuringPointType,
    SearchMeasuringPointDto,
    SearchMeasuringPointResultDto,
    SearchUserDto, SortCriteriaDto, SortDirection
} from '../../../_services/configuration-services';
import {EMPTY, firstValueFrom, merge, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {RolesService} from '../../roles-service';
import {faEraser, faUndoAlt} from '@fortawesome/free-solid-svg-icons';
import {FormUtils} from '../../form-utils';
import {TranslateUtils} from '../../translate-utils';

@Component({
    selector: 'app-search-measuring-point',
    templateUrl: './search-measuring-point.component.html'
})
export class SearchMeasuringPointComponent implements OnInit, OnDestroy, OnChanges {

    @Input() serviceId: string | null = null;
    @Input() userId: string | null = null;
    @Input() measuringPointTypes: MeasuringPointType[] = [];
    @Input() serviceUnassignedOnly = false;
    @Input() itemToBookUnassignedOnly = true;
    @Input() userUnassignedOnly: boolean | null = null;
    @Input() includeMeasuringPointWithHistoricalAssignments: boolean = false;
    @Input() disabled = false;
    @Input() defaultMeasuringPoint: MeasuringPointLightDto | SearchMeasuringPointResultDto | null = null;
    @Input() allowDropdownEmptySelection = false;
    @Input() sort: SortCriteriaDto =
        new SortCriteriaDto({
            direction: SortDirection.Desc,
            property: 'NameFR'
        });

    @Output() selectedMeasuringPointEvent = new EventEmitter<SearchMeasuringPointResultDto>();

    searchSubject$ = new Subject<string>();

    selectedMeasuringPoint: SearchMeasuringPointResultDto | MeasuringPointLightDto | null = null;
    printedSelectedMeasuringPoint: string | null = null;
    searchMeasuringPointDto: SearchMeasuringPointDto;
    searchText: string | null = null;

    private langChangeSubscription: Subscription;
    textInputPattern = FormUtils.textInputPattern;

    searchFailed = false;
    crtLang = TranslateUtils.defaultLanguage;

    // icons
    icons = {
        undo: faUndoAlt,
        erase: faEraser
    };

    displayDropDown = false;
    userMeasuringPoints = new Array<SearchMeasuringPointResultDto>();

    formatter = (result: SearchMeasuringPointResultDto | null) => result ? `${result['name' + this.crtLang.toUpperCase()]}` : '';

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged());

        return merge(debouncedText$, this.searchSubject$).pipe(
            distinctUntilChanged(),
            switchMap(term => {
                if (term.length < 1) {
                    this.selectMeasuringPoint(null);
                }

                if (term === this.printedSelectedMeasuringPoint) {
                    // Ignore the term if it is the same as the selected MeasuringPoint
                    return EMPTY;
                }

                this.searchMeasuringPointDto.name = term;
                return this.measuringPointService.searchMeasuringPoints(this.searchMeasuringPointDto)
                    .pipe(tap(res => {
                            if (res == null || res.length === 0) {

                                // Check if default value still match
                                if (this.defaultMeasuringPoint && this.isDefaultMeasuringPointStillMatch()) {
                                    this.searchFailed = false;
                                    this.selectMeasuringPoint(this.defaultMeasuringPoint);
                                    return of([]);

                                } else {
                                    this.searchFailed = true;
                                    this.selectMeasuringPoint(null);
                                    return of([]);
                                }
                            }
                            this.searchFailed = false;
                            return of([]);
                        }),
                        catchError(() => {
                            this.searchFailed = true;
                            this.selectMeasuringPoint(null);
                            return of([]);
                        }));
            }));
    }

    constructor(private readonly translateService: TranslateService,
                private readonly measuringPointService: MeasuringPointService,
                private readonly roleService: RolesService) {
    }

    async ngOnInit(): Promise<void> {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(async _ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                    this.searchMeasuringPointDto.language = this.crtLang;
                    this.clearInput();
                    await this.getMeasuringPointForUser();
                }
            });

        this.selectedMeasuringPoint = this.defaultMeasuringPoint;

        this.searchMeasuringPointDto = new SearchMeasuringPointDto({
            language: this.crtLang,
            name: this.searchText,
            serviceUnassignedOnly: this.serviceUnassignedOnly,
            measuringPointTypes: this.measuringPointTypes.length > 0 ? this.measuringPointTypes : [MeasuringPointType.User, MeasuringPointType.Admin],
            serviceId: this.serviceId,
            userId: this.userId,
            itemToBookUnassignedOnly: this.itemToBookUnassignedOnly,
            userUnassignedOnly: this.userUnassignedOnly,
            includeMeasuringPointWithHistoricalAssignments: this.includeMeasuringPointWithHistoricalAssignments,
            sortCriteriaDto: this.sort
        });

        await this.toogleDisplayDropDownIfNeeded();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    async ngOnChanges(update: any): Promise<void> {
        if (update.userId) {
            this.userId = update.userId.currentValue;
            if (this.searchMeasuringPointDto) {
                this.searchMeasuringPointDto.userId = this.userId;
            }

            await this.toogleDisplayDropDownIfNeeded();

        } else if (!update.defaultMeasuringPoint?.firstChange) {
            // Revert the default value because we won't use changes as default value
            this.defaultMeasuringPoint = update.oldValue;
        }
    }

    public clearInput(): Array<SearchUserDto> {
        this.searchFailed = false;
        this.selectMeasuringPoint(null, true);
        return [];
    }

    selectItem(selectItemEvent: NgbTypeaheadSelectItemEvent<SearchMeasuringPointResultDto>): void {
        this.selectMeasuringPoint(selectItemEvent.item);
    }

    userMeasuringPointChange(measuringPoint: SearchMeasuringPointResultDto): void {
        this.selectMeasuringPoint(measuringPoint);
    }

    selectBackDefaultMeasuringPoint(): void {
        this.selectMeasuringPoint(this.defaultMeasuringPoint);
    }

    private selectMeasuringPoint(measuringPoint: SearchMeasuringPointResultDto | MeasuringPointLightDto | null, force = false): void {
        if (measuringPoint || force) {
            this.selectedMeasuringPoint = measuringPoint;
            this.printedSelectedMeasuringPoint = this.formatter(measuringPoint);
            this.selectedMeasuringPointEvent.emit(measuringPoint);

        } else {
            this.selectedMeasuringPointEvent.emit(null);
        }
    }

    private async getMeasuringPointForUser(): Promise<void> {
        if (this.displayDropDown) {
            this.userMeasuringPoints = this.searchMeasuringPointDto ? await firstValueFrom(this.measuringPointService.searchMeasuringPoints(this.searchMeasuringPointDto)) : [];

            if (!this.allowDropdownEmptySelection) {
                this.selectedMeasuringPoint = this.userMeasuringPoints.length > 0 ? this.userMeasuringPoints[0] : null;
            }
        }
    }

    private isDefaultMeasuringPointStillMatch(): boolean {
        if (this.searchMeasuringPointDto.language === 'fr') {
            if (this.defaultMeasuringPoint.nameFR.indexOf(this.searchMeasuringPointDto.name) === -1) {
                return false;
            }
        } else if (this.searchMeasuringPointDto.language === 'de') {
            if (this.defaultMeasuringPoint.nameDE.indexOf(this.searchMeasuringPointDto.name) === -1) {
                return false;
            }
        } else if (this.searchMeasuringPointDto.language === 'en') {
            if (this.defaultMeasuringPoint.nameEN.indexOf(this.searchMeasuringPointDto.name) === -1) {
                return false;
            }
        } else if (this.searchMeasuringPointDto.language === 'it') {
            if (this.defaultMeasuringPoint.nameIT.indexOf(this.searchMeasuringPointDto.name) === -1) {
                return false;
            }
        }
        return true;
    }

    private async toogleDisplayDropDownIfNeeded(): Promise<void> {
        // Displays the dropdown only if the user has the role user only
        this.displayDropDown = (this.roleService.hasRoleUser() && !this.roleService.hasRoleAdmin()) || this.userId !== null;

        if (this.displayDropDown) {
            await this.getMeasuringPointForUser();
        }
    }
}

<div class="modal-header">
    <h4 class="modal-title" translate="services.doorGroups.modal.addTitle"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <div class="row">

        <div class="col-12">
            <div class="form-check" *ngFor="let doorGroup of doorGroups">
                <input class="form-check-input" type="radio"
                       id="{{ doorGroup.doorGroupId }}"
                       [value]="doorGroup"
                       [disabled]="doorGroup.serviceId !== null"
                       [(ngModel)]="selectedDoorGroup">
                <label class="form-check-label" for="{{ doorGroup.doorGroupId }}">{{ doorGroup.name }}</label>
            </div>
        </div>

    </div>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>
    <button class="btn btn-primary"
            (click)="assignDoorGroup()"
            [disabled]="selectedDoorGroup === null"
            translate="common.add">
    </button>
</div>

import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {
    EquipmentInterventionInputDto,
    EquipmentInterventionService,
    EquipmentLightDto,
} from '../../../../_services/configuration-services';
import {faCalendar, faTimes} from '@fortawesome/free-solid-svg-icons';
import {CustomDateFormatter} from '../../../../_shared/custom-date-formatter';
import {DateUtils} from '../../../../_shared/date-utils';
import {SearchEquipmentComponent} from '../../../../_shared/_components/search-equipment/search-equipment.component';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {ConfirmModalService} from '../../../../_shared/_components/confirm-modal/confirm-modal.component';
import {DocumentManagementTypeEnum} from '../../../../_shared/_components/document-management-component/document-management.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
    selector: 'app-manage-intervention',
    templateUrl: './manage-intervention.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class ManageInterventionComponent implements OnInit {

    @ViewChild('searchEquipmentComponent') searchEquipmentComponent: SearchEquipmentComponent;

    interventionId: string | null = null;

    interventionForm: UntypedFormGroup;

    icons = {
        calendar: faCalendar,
        close: faTimes
    };

    documentManagementTypeEnum: typeof DocumentManagementTypeEnum = DocumentManagementTypeEnum;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly location: Location,
        private notificationService: NotificationsService,
        private confirmService: ConfirmModalService,
        private equipmentInterventionService: EquipmentInterventionService) {

        this.interventionForm = new UntypedFormGroup({
            equipment: new UntypedFormControl(null, Validators.required),
            date: new UntypedFormControl(null, Validators.required),
            personInCharge: new UntypedFormControl(null, Validators.required),
            monitoringPerson: new UntypedFormControl(null, Validators.required),
            description: new UntypedFormControl(null, Validators.required),
        });
    }

    ngOnInit(): void {
        this.interventionId = this.route.snapshot.params['id'] ?? null;

        if (this.interventionId) {
            this.equipmentInterventionService.getEquipmentInterventionById(this.interventionId).pipe().subscribe(intervention => {
                this.searchEquipmentComponent.setEquipment(intervention.equipmentId);

                this.date.setValue(DateUtils.dateToNgbDateStruct(intervention.date));
                this.personInCharge.setValue(intervention.personInCharge);
                this.monitoringPerson.setValue(intervention.monitoringPerson);
                this.description.setValue(intervention.description);

                this.interventionForm.markAsPristine();
            });
        }
    }

    saveIntervention(): void {
        const equipmentIntervention = new EquipmentInterventionInputDto({
            id: this.interventionId,
            equipmentId: this.equipment.value.id,
            date: DateUtils.ngbDateStructToDate(this.date.value),
            description: this.description.value,
            monitoringPerson: this.monitoringPerson.value,
            personInCharge: this.personInCharge.value
        });

        if (!!this.interventionId) {
            this.equipmentInterventionService.updateEquipmentIntervention(this.interventionId, equipmentIntervention).pipe().subscribe(_ => {
                this.notificationService.success({title: 'equipments.interventions.notifications.updateSuccess'});
                this.interventionForm.markAsPristine();
            });

        } else {
            this.equipmentInterventionService.createEquipmentIntervention(equipmentIntervention).pipe().subscribe(value => {
                this.notificationService.success({title: 'equipments.interventions.notifications.addSuccess'});
                this.router.navigate(['../' + value.id], { relativeTo: this.route, replaceUrl: true});
            });
        }
    }

    deleteEquipmentIntervention(): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.equipmentInterventionService.deleteEquipmentIntervention(this.interventionId).pipe().subscribe(_ => {
                    this.notificationService.success({title: 'equipments.interventions.notifications.deleteSuccess'});
                    this.back();
                });
            }
        });
    }

    back(): void {
        this.location.back();
    }

    onEquipmentChange($event: EquipmentLightDto | null): void {
        if (!!this.equipment.value) {
            this.equipment.setValue($event);
            this.equipment.markAsDirty();

        } else {
            this.equipment.setValue($event);
        }
    }

    get date(): UntypedFormControl {
        return this.interventionForm.get('date') as UntypedFormControl;
    }

    get personInCharge(): UntypedFormControl {
        return this.interventionForm.get('personInCharge') as UntypedFormControl;
    }

    get monitoringPerson(): UntypedFormControl {
        return this.interventionForm.get('monitoringPerson') as UntypedFormControl;
    }

    get description(): UntypedFormControl {
        return this.interventionForm.get('description') as UntypedFormControl;
    }

    get equipment(): UntypedFormControl {
        return this.interventionForm.get('equipment') as UntypedFormControl;
    }

}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertDeviceModalComponent} from './alert-device-modal/alert-device-modal.component';
import {
    AlarmClassification,
    AlarmDeviceDto, AlarmSearchCriteriaDto,
    AlertComparisonSymbol,
    AlertDeviceDto,
    AlertDeviceSearchCriteriaDto,
    AlertDevicesService,
    ISortCriteriaDto,
    MeasuringPointType, PageableRequestDtoOfAlarmSearchCriteriaDto,
    PageableRequestDtoOfAlertDeviceSearchCriteriaDto,
    PagedResultDtoOfAlertDeviceDto,
    RegisterType,
    SortCriteriaDto,
    SortDirection
} from '../../_services/configuration-services';
import {NotificationsService} from '../../_shared/notifications.service';
import {AppRoles} from 'src/app/app.constants';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormUtils} from '../../_shared/form-utils';
import {faBan, faCalendar, faPen, faTimes, faToggleOff, faToggleOn, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {RolesService} from '../../_shared/roles-service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {SearchIotRegisterComponent} from '../../_shared/_components/search-iot-register/search-iot-register.component';
import {SearchMeasuringPointComponent} from '../../_shared/_components/search-measuring-point/search-measuring-point.component';
import {ConfirmModalService} from '../../_shared/_components/confirm-modal/confirm-modal.component';
import {TranslateUtils} from '../../_shared/translate-utils';

@Component({
    selector: 'app-alert-device-management',
    templateUrl: './alert-device-management.component.html'
})
export class AlertDeviceManagementComponent implements OnInit, OnDestroy {

    @ViewChild('iotRegisterSearch') iotRegisterSearch: SearchIotRegisterComponent;
    @ViewChild('measuringPointSearch') measuringPointSearch: SearchMeasuringPointComponent;
    @ViewChild('devicesTable') devicesTable;

    AppRoles = AppRoles;
    RegisterType = RegisterType;
    MeasuringPointType = MeasuringPointType;

    // Filters
    filter: {
        formGroup: UntypedFormGroup,
        classificationsDropdownList: string[]
        statesDropdownList: any[],
        comparisonSymbolsDropdownList: any[]
    } = {
        formGroup: null,
        classificationsDropdownList: Object.keys(AlarmClassification).filter(c => c !== AlarmClassification.Unknown),
        statesDropdownList: [{
            key: 'active',
            value: true
        }, {
            key: 'inactive',
            value: false
        }],
        comparisonSymbolsDropdownList: Object.keys(AlertComparisonSymbol).filter(c => c !== AlertComparisonSymbol.Unknown),

    };

    textInputPattern = FormUtils.textInputPattern;

    // Icons
    icons = {
        calendar: faCalendar,
        edit: faPen,
        delete: faTrash,
        disable: faBan,
        times: faTimes,
        toggleOn: faToggleOn,
        toggleOff: faToggleOff
    };

    // Pagination
    readonly pageSize = 10;
    crtPage = 0;

    // Table content
    devices: PagedResultDtoOfAlertDeviceDto = new PagedResultDtoOfAlertDeviceDto();

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Asc,
        property: 'classification'
    });

    private pageRequest: PageableRequestDtoOfAlertDeviceSearchCriteriaDto;

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(private readonly modalService: NgbModal,
                readonly rolesService: RolesService,
                private readonly translateService: TranslateService,
                private readonly confirmService: ConfirmModalService,
                private readonly notificationsService: NotificationsService,
                private readonly alertDevicesService: AlertDevicesService) {

        this.filter.formGroup = new UntypedFormGroup({
                classification: new UntypedFormControl(null),
                iotRegister: new UntypedFormControl(null),
                measuringPoint: new UntypedFormControl(null),
                location: new UntypedFormControl(null),
                state: new UntypedFormControl(null),
                threshold: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
                comparisonSymbol: new UntypedFormControl(null),
            },
            {updateOn: 'change'});

        this.filter.formGroup.reset();
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.initializeBasePageRequest();

        this.fetchDevices(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    fetchDevices(pageableRequest: PageableRequestDtoOfAlertDeviceSearchCriteriaDto): void {
        this.alertDevicesService.searchAlertDevices(pageableRequest).pipe().subscribe(devices => {
            this.devices = devices;
        });
    }

    createNewDevice(): void {
        const modal = this.modalService.open(AlertDeviceModalComponent, {centered: true, size: 'lg'});
        modal.result
            .then(_ => {
                this.fetchDevices(this.pageRequest);
            }, () => { /* catch the rejection */ });
    }

    editDevice(device: AlarmDeviceDto): void {
        const modal = this.modalService.open(AlertDeviceModalComponent, {centered: true, size: 'lg'});
        modal.componentInstance.alertDeviceId = device?.id;
        modal.result
            .then(_ => {
                this.fetchDevices(this.pageRequest);
            }, () => { /* catch the rejection */ });
    }

    activateDevice(deviceId: string): void {
        this.alertDevicesService.activateAlertDevice(deviceId).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'alerts.device.notification.activateDeviceSuccess'});
            this.fetchDevices(this.pageRequest);
        });
    }

    deactivateDevice(deviceId: string): void {
        this.alertDevicesService.deactivateAlertDevice(deviceId).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'alerts.device.notification.deactivateDeviceSuccess'});
            this.fetchDevices(this.pageRequest);
        });
    }


    deleteDevice(device: AlertDeviceDto): void {
        this.confirmService.confirm({
            titleKey: 'common.confirmModal.title.delete',
            messageKey: 'common.confirmModal.message.deleteAlertDevice'
        }).then(result => {
            if (result === ConfirmModalService.yes) {
                this.alertDevicesService.deleteAlertDevice(device.id).pipe().subscribe(_ => {
                    this.notificationsService.success({title: 'alarms.device.notification.deleteDeviceSuccess'});
                    this.fetchDevices(this.pageRequest);
                });
            }
        });
    }

    applyFilters(): void {
        if (this.filter.formGroup.invalid) {
            return;
        }

        this.crtPage = 0;

        this.initializeBasePageRequest();

        this.pageRequest.criteria = new AlertDeviceSearchCriteriaDto({
            classification: AlarmClassification[this.classification.value],
            iotRegisterId: this.iotRegister.value?.id,
            measuringPointId: this.measuringPoint.value?.id,
            location: this.location.value,
            active: this.state.value,
            threshold: this.threshold.value,
            comparisonSymbol: this.comparisonSymbol.value,
            sortCriteriaDto: this.currentSort
        });

        this.fetchDevices(this.pageRequest);
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;

        this.fetchDevices(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;

        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = new SortCriteriaDto(sortCriteriaDto);

        this.fetchDevices(this.pageRequest);
    }

    clearFilter(): void {
        // Clear inputs
        this.filter.formGroup.reset();
        this.iotRegisterSearch.clearInput();
        this.measuringPointSearch.clearInput();

        // clear search
        this.initializeBasePageRequest();
        this.fetchDevices(this.pageRequest);
    }

    get classification(): UntypedFormControl {
        return this.filter.formGroup.get('classification') as UntypedFormControl;
    }

    get iotRegister(): UntypedFormControl {
        return this.filter.formGroup.get('iotRegister') as UntypedFormControl;
    }

    get measuringPoint(): UntypedFormControl {
        return this.filter.formGroup.get('measuringPoint') as UntypedFormControl;
    }

    get location(): UntypedFormControl {
        return this.filter.formGroup.get('location') as UntypedFormControl;
    }

    get state(): UntypedFormControl {
        return this.filter.formGroup.get('state') as UntypedFormControl;
    }

    get comparisonSymbol(): UntypedFormControl {
        return this.filter.formGroup.get('comparisonSymbol') as UntypedFormControl;
    }

    get threshold(): UntypedFormControl {
        return this.filter.formGroup.get('threshold') as UntypedFormControl;
    }

    private initializeBasePageRequest(): void {
        this.pageRequest = new PageableRequestDtoOfAlertDeviceSearchCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new AlertDeviceSearchCriteriaDto({
            classification: null,
            iotRegisterId: null,
            measuringPointId: null,
            location: null,
            active: null,
            threshold: null,
            comparisonSymbol: null,
            sortCriteriaDto: this.currentSort
        });
    }

}

import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../loader-service';

@Component({
  selector: 'app-application-loader',
  template: `
    <div class="application-loader"
         *ngIf="loading">
      <div class="backdrop"></div>
      <div class="loader-container">
        <div class="loader">
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./application-loader.component.scss']
})
export class ApplicationLoaderComponent implements OnInit, OnDestroy {

  private loadingDeferred = false;
  private loadingDeferredTimeout = null;
  loading = false;

  private subscription: Subscription;

  constructor(private readonly loaderService: LoaderService,
              private readonly ngZone: NgZone) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.loaderService.getLoading().subscribe((state) => {
      this.loadingDeferred = state;

      // In all cases we wait 200ms before displaying or hiding the loader.
      // This avoid ugly blink effects and doesn't show the loader on quick queries.
      if (!this.loadingDeferredTimeout) {
        clearTimeout(this.loadingDeferredTimeout);
      }

      this.loadingDeferredTimeout = setTimeout(() => {
        this.ngZone.run(() => {
          this.loading = this.loadingDeferred;
        });

      }, 200);
    });
  }
}

import {Component, Input} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {DateUtils} from "../../../_shared/date-utils";
import {NgbActiveModal, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {BillingService, BillingStateDto} from "../../../_services/configuration-services";
import {CustomDateFormatter} from "../../../_shared/custom-date-formatter";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {FormUtils} from "../../../_shared/form-utils";

@Component({
    selector: 'app-billing-state-modal',
    templateUrl: './billing-state-modal.component.html',
    styleUrls: ['./billing-state-modal.component.scss'],
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class BillingStateModalComponent {

    billingDate: UntypedFormControl;
    calendar = faCalendar;

    @Input() billingStateDto: BillingStateDto;


    constructor(public activeModal: NgbActiveModal,
                private readonly billingService: BillingService,) {
        this.billingDate = new UntypedFormControl(DateUtils.dateToNgbDateStruct(new Date()), [FormUtils.datePatternValidator, Validators.required]);
    }

    confirm(): void {
        this.billingStateDto.dateTime = DateUtils.ngbDateStructToDate(this.billingDate.value);
        this.billingService.changeBillingState(this.billingStateDto).pipe().subscribe(value => {
            this.activeModal.close({
                action: 'ok',
                state: value.state.toLowerCase(),
            });
        });
    }

}

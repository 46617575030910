import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    AlarmClassification,
    AlertComparisonSymbol,
    AlertDeviceDto,
    AlertDeviceInputDto,
    AlertDevicesService,
    IotRegisterDto,
    MeasuringPointType,
    RegisterType,
} from '../../../_services/configuration-services';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../_shared/notifications.service';
import {FormUtils} from '../../../_shared/form-utils';
import {SearchIotRegisterComponent} from '../../../_shared/_components/search-iot-register/search-iot-register.component';
import {TranslateUtils} from '../../../_shared/translate-utils';

@Component({
    selector: 'app-alert-device-modal',
    templateUrl: './alert-device-modal.component.html'
})
export class AlertDeviceModalComponent implements OnInit, OnDestroy {

    private langChangeSubscription: Subscription;
    private crtLang = TranslateUtils.defaultLanguage;
    private alertDevice: AlertDeviceDto;

    @ViewChild('searchIotRegisterComponent') searchIotRegisterComponent: SearchIotRegisterComponent;
    @Input() alertDeviceId: string | null;

    MeasuringPointType = MeasuringPointType;
    RegisterType = RegisterType;

    deviceForm: UntypedFormGroup;

    availableClassifications = Object.keys(AlarmClassification).filter(c => c !== AlarmClassification.Unknown);
    comparisonSymbols = Object.keys(AlertComparisonSymbol).filter(c => c !== AlertComparisonSymbol.Unknown);

    isEditMode = false;

    textInputPattern = FormUtils.textInputPattern;


    constructor(public activeModal: NgbActiveModal,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly alertDevicesService: AlertDevicesService) {

        this.deviceForm = new UntypedFormGroup({
                classification: new UntypedFormControl('', Validators.required),
                iotRegister: new UntypedFormControl('', Validators.required),
                threshold: new UntypedFormControl(0, [Validators.required, FormUtils.numberDecimalAllowingNegativePatternValidator]),
                unit: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
                comparisonSymbol: new UntypedFormControl('', Validators.required),
                location: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
                state: new UntypedFormControl(true),
                comment: new UntypedFormControl('', Validators.maxLength(255)),
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        if (this.alertDeviceId) {
            this.isEditMode = true;
            this.fetchDevice();
        }
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    saveDevice(): void {
        const alert = new AlertDeviceInputDto({
            classification: this.classification.value,
            iotRegisterId: this.iotRegister.value.id,
            comparisonSymbol: this.comparisonSymbol.value,
            unit: this.unit.value,
            threshold: +this.threshold.value,
            location: this.location.value,
            active: this.state.value,
            comment: this.comment.value
        });

        if (this.alertDevice) {
            this.alertDevicesService.updateAlertDevice(this.alertDevice.id, alert).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'alerts.device.notification.updateDeviceSuccess'});
                this.activeModal.close('success');
            });
        } else {
            this.alertDevicesService.createAlertDevice(alert).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'alerts.device.notification.addDeviceSuccess'});
                this.activeModal.close('success');
            });
        }


    }

    private fetchDevice(): void {
        this.alertDevicesService.getAlertDevice(this.alertDeviceId).subscribe(device => {
            this.alertDevice = device;
            this.searchIotRegisterComponent.selectedRegister = device.iotRegister;
            this.classification.setValue(this.alertDevice.classification);
            this.iotRegister.setValue(this.alertDevice.iotRegister);
            this.location.setValue(this.alertDevice.location);
            this.state.setValue(this.alertDevice.active);
            this.comment.setValue(this.alertDevice.comment);
            this.threshold.setValue(this.alertDevice.threshold);
            this.comparisonSymbol.setValue(this.alertDevice.comparisonSymbol);
            this.unit.setValue(this.alertDevice.unit);
        });
    }

    getIotRegisterLabel(iotRegister: IotRegisterDto): string {
        return `${iotRegister['label' + this.crtLang.toUpperCase()]}`;
    }

    getIotRegisterEquipment(iotRegister: IotRegisterDto): string {
        return `${iotRegister.measuringPoint['name' + this.crtLang.toUpperCase()]}`;
    }

    get classification(): UntypedFormControl {
        return this.deviceForm.get('classification') as UntypedFormControl;
    }

    get threshold(): UntypedFormControl {
        return this.deviceForm.get('threshold') as UntypedFormControl;
    }

    get unit(): UntypedFormControl {
        return this.deviceForm.get('unit') as UntypedFormControl;
    }

    get comparisonSymbol(): UntypedFormControl {
        return this.deviceForm.get('comparisonSymbol') as UntypedFormControl;
    }

    get iotRegister(): UntypedFormControl {
        return this.deviceForm.get('iotRegister') as UntypedFormControl;
    }

    get location(): UntypedFormControl {
        return this.deviceForm.get('location') as UntypedFormControl;
    }

    get state(): UntypedFormControl {
        return this.deviceForm.get('state') as UntypedFormControl;
    }

    get comment(): UntypedFormControl {
        return this.deviceForm.get('comment') as UntypedFormControl;
    }


}

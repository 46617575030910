import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    ChargingProcessSearchCriteriaDto, ChargingProcessService,
    ChargingProcessStatus, ChargingStationDto,
    ChargingStationService, ISortCriteriaDto,
    PageableRequestDtoOfChargingProcessSearchCriteriaDto,
    PagedResultDtoOfChargingProcessDto, SearchUserDto, SortCriteriaDto, SortDirection,
} from '../../../_services/configuration-services';
import {DateUtils} from '../../../_shared/date-utils';
import {RolesService} from '../../../_shared/roles-service';
import {firstValueFrom, Subscription} from 'rxjs';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {faPlugCirclePlus, faPlugCircleXmark} from '@fortawesome/free-solid-svg-icons';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from '../../../_shared/custom-date-formatter';
import {SearchUserComponent} from "../../../_shared/_components/search-user/search-user.component";
import { FormControl, FormGroup} from "@angular/forms";
import {FormUtils} from "../../../_shared/form-utils";
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {NotificationsService} from '../../../_shared/notifications.service';

@Component({
    selector: 'app-charging-process-management',
    templateUrl: './charging-process-management.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class ChargingProcessManagementComponent implements OnInit, OnDestroy {

    @Input() serviceId!: string;

    @ViewChild('userSearch') userSearch: SearchUserComponent;
    @ViewChild('chargingProcessesTable') table;

    filterFormGroup: FormGroup<FilterFormGroup>;

    availableStatus = Object.values(ChargingProcessStatus);

    // Table content
    chargingProcesses: PagedResultDtoOfChargingProcessDto = new PagedResultDtoOfChargingProcessDto();

    icons = {
        extend: faPlugCirclePlus,
        terminate: faPlugCircleXmark
    }

    // Pagination
    readonly pageSize = 10;
    crtPage = 0;

    private pageRequest: PageableRequestDtoOfChargingProcessSearchCriteriaDto;

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Desc,
        property: 'chargeStartDateTime'
    });

    dateTimeFormat = DateUtils.dateTimeFormat;

    chargingStations = new Array<ChargingStationDto>();

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(private readonly chargingStationService: ChargingStationService,
                private readonly chargingProcessService: ChargingProcessService,
                private readonly translateService: TranslateService,
                public readonly rolesService: RolesService,
                private readonly confirmModal: ConfirmModalService,
                private readonly notificationsService: NotificationsService) {

        this.filterFormGroup = new FormGroup<FilterFormGroup>({
                period: new FormGroup<PeriodControl>({
                    from: new FormControl<NgbDateStruct>(null, [FormUtils.datePatternValidator]),
                    to: new FormControl<NgbDateStruct>(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
                userId: new FormControl<string>(null),
                chargingStationId: new FormControl<string>(null),
                status: new FormControl<ChargingProcessStatus>(null),
            },
            {updateOn: 'change'}
        );
    }

    async ngOnInit(): Promise<void> {
        this.crtLang = this.translateService.currentLang;

        this.initializeBasePageRequest();

        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.chargingStations = await firstValueFrom(this.chargingStationService.getChargingStationsForService(this.serviceId));

        await this.fetchChargingProcesses(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    terminateChargingProcess(chargeProcessId: string) {
        this.confirmModal.confirm({titleKey: 'common.confirmModal.title.disable'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.chargingProcessService.deactivateChargingProcess(chargeProcessId).pipe().subscribe(async (): Promise<void> => {
                    this.notificationsService.success({
                        title: 'activations.terminateSuccessMessage',
                        message: 'activations.terminateSuccessExplanation',
                    });
                    await this.fetchChargingProcesses(this.pageRequest);
                });
            }
        });
    }

    private async fetchChargingProcesses(pageRequest: PageableRequestDtoOfChargingProcessSearchCriteriaDto): Promise<void> {
        this.chargingProcesses = await firstValueFrom(this.chargingProcessService.searchChargingProcesses(pageRequest));
    }

    async setPage(page: any): Promise<void> {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;

        await this.fetchChargingProcesses(this.pageRequest);
    }

    async onSort(sortOrder: any): Promise<void> {
        this.goToFirstPage();

        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteria = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = new SortCriteriaDto(sortCriteriaDto);

        await this.fetchChargingProcesses(this.pageRequest);
    }

    selectUser(user: SearchUserDto | null): void {
        this.userId.setValue(user?.id);
    }

    async applyFilter(): Promise<void> {
        this.initializeBasePageRequest();
        await this.fetchChargingProcesses(this.pageRequest);
    }

    public async clearFilter(): Promise<void> {
        // Clear inputs
        this.filterFormGroup.reset();
        this.userSearch?.clearInput();

        await this.applyFilter();
    }

    private initializeBasePageRequest(): void {
        const period = this.period.value;

        const dateFrom = DateUtils.ngbDateStructToDate(period.from);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(period.to);
        dateTo?.setHours(23, 59, 59, 59);

        this.pageRequest = new PageableRequestDtoOfChargingProcessSearchCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });

        this.pageRequest.criteria = new ChargingProcessSearchCriteriaDto({
            serviceId: this.serviceId,
            userId: this.userId?.value,
            chargingStationId: this.chargingStationId.value,
            from: dateFrom,
            to: dateTo,
            status: this.status.value,
            sortCriteria: this.currentSort
        });
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }

    get period(): FormGroup<PeriodControl> {
        return this.filterFormGroup.get('period') as FormGroup<PeriodControl>;
    }

    get userId(): FormControl<string> {
        return this.filterFormGroup.get('userId') as FormControl<string>;
    }

    get chargingStationId(): FormControl<string> {
        return this.filterFormGroup.get('chargingStationId') as FormControl<string>;
    }

    get status(): FormControl<ChargingProcessStatus> {
        return this.filterFormGroup.get('status') as FormControl<ChargingProcessStatus>;
    }
}

interface FilterFormGroup {
    period: FormGroup<PeriodControl>,
    userId: FormControl<string>,
    chargingStationId: FormControl<string>,
    status: FormControl<ChargingProcessStatus>
}

interface PeriodControl {
    from: FormControl<NgbDateStruct>,
    to: FormControl<NgbDateStruct>
}

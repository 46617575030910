import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TransactionService} from '../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../_shared/notifications.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-transactions-import-modal',
    templateUrl: './transactions-import-modal.component.html'
})
export class TransactionsImportModalComponent {

    importFileFormGroup: UntypedFormGroup;

    constructor(public readonly activeModal: NgbActiveModal,
                private readonly transactionService: TransactionService,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService) {

        this.importFileFormGroup = new UntypedFormGroup({
            file: new UntypedFormControl(null, [Validators.required])
        }, {updateOn: 'change'});
    }

    fileSelected(files: FileList): void {
        if (files.length > 0) {
            const file = files[0];
            this.file.setValue(file);
            this.file.updateValueAndValidity();
        }
    }

    import(): void {
        this.notificationsService.clearAll();
        this.transactionService.importTransactions({
            data: this.file.value,
            fileName: this.file.value.name
        })
            .pipe()
            .subscribe(result => {
                this.notificationsService.success({
                    title: 'transactions.notifications.importSuccess',
                    interpolateParams: {transactionCount: result}
                });
                this.activeModal.close('imported');
            }, error => {
                const status = (error as any).status;
                switch (status) {
                    case 422: // Business Exception
                        const response = JSON.parse(error.response);
                        if (response.businessCode) {
                            let message;
                            if (!!response.extensions.subBusinessCode) {
                                message = 'error.network.businessException.' + response.extensions.subBusinessCode;
                            } else {
                                message = null;
                            }

                            if (!!response.extensions.subBusinessDetails?.columnName) {
                                response.extensions.subBusinessDetails.columnName = this.translateService.instant(
                                    'transactions.modal.import.' + response.extensions.subBusinessDetails.columnName);
                            }

                            if (response.businessCode === 902
                                && !!response.extensions.multipleLines
                                && response.extensions.multipleLines.length > 1) {
                                this.notificationsService.error({
                                    title: 'error.network.businessException.902MultiLine',
                                    message,
                                    interpolateParams: {...response.extensions, ...response.extensions.subBusinessDetails}
                                }, true);
                            } else {
                                this.notificationsService.error({
                                    title: 'error.network.businessException.' + response.businessCode,
                                    message,
                                    interpolateParams: {...response.extensions, ...response.extensions.subBusinessDetails}
                                }, true);
                            }
                        } else {
                            throw error;
                        }
                        break;
                    default:
                        throw error;
                }
            });
    }

    get file(): UntypedFormControl {
        return this.importFileFormGroup.get('file') as UntypedFormControl;
    }

}

<div *ngIf="measuringPoints">

    <h5 translate="services.measuringPoints.title"></h5>

    <div class="d-table w-100">
        <div class="d-table-row" *ngFor="let measuringPoint of measuringPoints |
        slice: (pagination.page-1) * pagination.maxMeasuringPoints : pagination.page * pagination.maxMeasuringPoints">
            <div class="d-table-cell">
                <p><strong>{{ measuringPoint['name' + crtLang.toUpperCase()] }}</strong></p>
            </div>
            <div class="d-table-cell">
                <span class="dot gray-border" [ngClass]="{
                        'green': measuringPoint.measuringPointUsageState === MeasuringPointUsageState.Free,
                        'red': measuringPoint.measuringPointUsageState === MeasuringPointUsageState.Error,
                        'yellow': measuringPoint.measuringPointUsageState === MeasuringPointUsageState.Busy,
                        'gray': measuringPoint.measuringPointUsageState === MeasuringPointUsageState.Unknown }"></span>

                {{ 'common.measuringPointUsageState.' + measuringPoint.measuringPointUsageState | translate }}
            </div>
            <div class="d-table-cell" *ngIf="isAdmin()">
                <button class="btn btn-outline-secondary m-2"
                        [disabled]="measuringPoint.measuringPointUsageState !== MeasuringPointUsageState.Free"
                        title="{{ 'common.activate' | translate }}"
                        (click)="activateMeasuringPoint.emit(measuringPoint.id)"
                        translate="common.activate">
                </button>
            </div>
        </div>
    </div>

    <div class="mt-3">
        <ngb-pagination *ngIf="measuringPoints.length > pagination.maxMeasuringPoints"
                        [(page)]="pagination.page"
                        [pageSize]="pagination.maxMeasuringPoints"
                        [collectionSize]="measuringPoints.length"></ngb-pagination>
    </div>

    <div *ngIf="measuringPoints.length < 1">
        <p translate="services.measuringPoints.noDataAvailable"></p>
    </div>
</div>

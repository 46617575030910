<div class="modal-header">

    <h4 class="modal-title">{{ alarm?.alarmDevice.iotRegister.measuringPoint['name' + crtLang.toUpperCase()] }}</h4>

    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body" *ngIf="alarm">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">

        <li ngbNavItem="form">

            <a ngbNavLink translate="alarms.management.detail.section.information"></a>

            <ng-template ngbNavContent>

                <div class="row">

                    <!-- Left Panel -->
                    <div class="col-12 col-sm-6">

                        <h4 class="mt-2" translate="alarms.management.detail.section.alarm"></h4>

                        <!-- Classification -->
                        <div class="col-12">
                            <label class="form-label mb-0" translate="alarms.management.detail.label.classification"></label>
                            <p>{{ ('common.alarmClassification.' + alarm.alarmDevice.classification) | translate }}</p>
                        </div>

                        <!-- Appeared Date -->
                        <div class="col-12">
                            <label class="form-label mb-0" translate="alarms.management.detail.label.appearedDate"></label>
                            <p>{{ alarm.appearedDateTime | date: dateTimeFormat }}</p>
                        </div>

                        <!-- AlarmState -->
                        <div class="col-12">
                            <label class="form-label mb-0" translate="alarms.management.detail.label.alarmState"></label>
                            <p>
                                <span class="dot white-border"
                                      [class.red]="alarm.alarmState === AlarmState.Active"
                                      [class.yellow]="alarm.alarmState === AlarmState.InProgress"
                                      [class.green]="alarm.alarmState === AlarmState.Solved"></span>
                                    {{ 'common.alarmState.' + alarm.alarmState | translate }}
                            </p>
                        </div>

                        <!-- Alarm Code -->
                        <div class="col-12" *ngIf="alarm.code">
                            <label class="form-label mb-0" translate="alarms.management.detail.label.code"></label>
                            <p>{{ alarm.code }}</p>
                        </div>

                        <!-- Alarm Description -->
                        <div class="col-12" *ngIf="alarm.description">
                            <label class="form-label mb-0" translate="alarms.management.detail.label.description"></label>
                            <pre>{{ alarm.description }}</pre>
                        </div>


                        <!-- Alarm Value -->
                        <div class="col-12" *ngIf="alarm.value">
                            <label class="form-label mb-0" translate="alarms.management.detail.label.value"></label>
                            <p>{{ alarm.value }}</p>
                        </div>

                    </div>

                    <!-- Right Panel -->
                    <div class="col-12 col-sm-6">

                        <h4 class="mt-2" translate="alarms.management.detail.section.device"></h4>

                        <!-- Equipment -->
                        <div class="col-12">
                            <label class="form-label mb-0" translate="alarms.management.detail.label.equipment"></label>
                            <p>{{ alarm.alarmDevice.iotRegister.measuringPoint['name' + crtLang.toUpperCase()] }}</p>
                        </div>

                        <!-- Designation -->
                        <div class="col-12">
                            <label class="form-label mb-0" translate="alarms.management.detail.label.designation"></label>
                            <p>{{ alarm.alarmDevice.iotRegister['label' + crtLang.toUpperCase()] }}</p>
                        </div>

                        <!-- Location -->
                        <div class="col-12">
                            <label class="form-label mb-0" translate="alarms.management.detail.label.location"></label>
                            <p>{{ alarm.alarmDevice.location }}</p>
                        </div>

                        <!-- Comment -->
                        <div class="col-12">
                            <label class="form-label mb-0" translate="alarms.management.detail.label.comment"></label>
                            <pre disabled>{{ alarm.alarmDevice.comment }}</pre>
                        </div>

                    </div>

                </div>

                <div class="btn-bar">
                    <button class="btn btn-primary" *ngIf="alarm.alarmState === AlarmState.Active"
                            (click)="setAlarmInProgress(alarm)"
                            title="{{ 'common.tooltip.inProgress' | translate }}">
                        <fa-icon [icon]="icons.inProgress"></fa-icon>
                        <span> {{ 'alarms.management.detail.button.setInProgress' | translate }}</span>
                    </button>

                    <button class="btn btn-primary" *ngIf="alarm.alarmState !== AlarmState.Solved"
                            (click)="releaseAlarm(alarm)"
                            title="{{ 'common.tooltip.release' | translate }}">
                        <fa-icon [icon]="icons.release"></fa-icon>
                        <span> {{ 'alarms.management.detail.button.release' | translate }}</span>
                    </button>
                </div>


            </ng-template>
        </li>

        <li ngbNavItem="events" *ngIf="alarm.alarmEvents.length > 0">

            <a ngbNavLink translate="alarms.management.detail.section.events"></a>

            <ng-template ngbNavContent>

                <h4 class="mt-2" translate="alarms.management.detail.section.events"></h4>

                <!-- Alarm Events Table-->
                <div class="table-responsive mt-4 table-wrapper-scroll-y">
                    <table class="table table-striped"
                           [attr.aria-label]="'alarms.management.detail.section.events' | translate">
                        <thead>
                        <tr>
                            <th scope="col" style="width: 175px;" translate="alarms.management.detail.events.dateTime"></th>
                            <th scope="col" translate="alarms.management.detail.events.event"></th>
                            <th scope="col" style="width: 175px;" translate="alarms.management.detail.events.user"></th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr *ngFor="let event of alarm.alarmEvents;">

                            <td>{{ event.dateTime | date: dateTimeFormat }}</td>
                            <td>{{ ('common.alarmEventType.' + event.alarmEventType) | translate : {ContactName: event.contactName} }}</td>
                            <td>
                                <span *ngIf="event.user">{{ event.user.firstName + ' ' + event.user.lastName }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </ng-template>
        </li>

        <li ngbNavItem="technicalEvents" *ngIf="alarm.technicalEvents.length > 0">

            <a ngbNavLink translate="alarms.management.detail.section.technicalEvents"></a>

            <ng-template ngbNavContent>

                <h4 class="mt-2" translate="alarms.management.detail.section.technicalEvents"></h4>

                <!-- Alarm Technical Events Table-->
                <div class="table-responsive mt-4 table-wrapper-scroll-y">
                    <table class="table table-striped"
                           [attr.aria-label]="'alarms.management.detail.section.technicalEvents' | translate">
                        <thead>
                        <tr>
                            <th scope="col" translate="alarms.management.detail.technicalEvents.creationDateTime"></th>
                            <th scope="col" translate="alarms.management.detail.technicalEvents.originatorComponent"></th>
                            <th scope="col" translate="alarms.management.detail.technicalEvents.technicalCode"></th>
                            <th scope="col" translate="alarms.management.detail.technicalEvents.description"></th>
                            <th scope="col" translate="alarms.management.detail.technicalEvents.sourceEventId"></th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr *ngFor="let event of alarm.technicalEvents;">

                            <td>{{ event.creationDateTime | date: dateTimeFormat }}</td>
                            <td>{{ event.originatorComponentName ?? event.originatorComponentId }}</td>
                            <td>{{ event.technicalCode }}</td>
                            <td>{{ event.description }}</td>
                            <td>{{ event.sourceEventId }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </ng-template>
        </li>

    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>

</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.close">
    </button>
</div>

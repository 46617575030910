import {Component, Input} from '@angular/core';
import {AppRoles} from '../../../app.constants';
import {ServiceDto} from '../../../_services/configuration-services';
import {CraningBookingUtils} from './craning-booking/craning-booking-utils';

@Component({
    selector: 'app-craning',
    templateUrl: './craning.component.html'
})
export class CraningComponent {

    @Input() service!: ServiceDto;

    AppRoles = AppRoles;
    CraningView = CraningView;

    craningView = CraningView.BookingView;

    constructor(public readonly craningBookingUtils: CraningBookingUtils) { }

    changeCraningView(view: CraningView): void {
        this.craningView = view;
    }
}

enum CraningView {
    BookingView,
    BillingView
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {WebcamDto, WebcamService} from '../../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-webcam',
    templateUrl: './webcam.component.html'
})
export class WebcamComponent implements OnInit, OnDestroy {

    serviceId: string;

    webcams: WebcamDto[] = [];

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription$: Subscription;

    constructor(private readonly webcamService: WebcamService,
                private readonly translateService: TranslateService,
                private readonly route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.serviceId = this.route.snapshot.params['serviceId'];

        this.fetchWebcams();

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription$ = this.translateService.onLangChange
            .subscribe(_ => {
                this.crtLang = this.translateService.currentLang;
                window.location.reload();
            });
    }

    private fetchWebcams(): void {
        this.webcamService.getActiveWebcamsByServiceId(this.serviceId).pipe().subscribe(webcams => {
            this.webcams = webcams;
            this.webcams.sort((a, b) => a.position - b.position);
        });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$.unsubscribe();
    }

}

import {Component, Input, OnInit} from '@angular/core';
import {
    RfidCardDto, RfidCardService, SearchUserDto
} from '../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {NotificationsService} from '../../../_shared/notifications.service';
import {FormUtils} from '../../../_shared/form-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-rfid-card-modal',
    templateUrl: './user-rfid-card-modal.component.html'
})
export class UserRfidCardModalComponent implements OnInit {

    @Input() userId: string | null = null;
    @Input() rfidCards: RfidCardDto[] = [];

    rfidCard: RfidCardDto;

    alreadyAssigned = false;
    selfAssigned = false;
    textInputPattern = FormUtils.textInputPattern;

    // SearchCard
    searchFailed = false;
    rfidCardResult: RfidCardDto;

    formatter = (result: RfidCardDto) => this.printFormattedCardIdentifier(result);

    constructor(public activeModal: NgbActiveModal,
                private readonly rfidCardService: RfidCardService,
                private readonly notificationsService: NotificationsService,
                private readonly translateService: TranslateService) {

    }

    ngOnInit(): void {
        this.rfidCard = new RfidCardDto();
    }

    searchRfidCards = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap(term => {
                if (term.length < 1) {
                    this.clearInput();
                    return of([]);
                }
                term = term.replace('N°', '').replace(' - ', '').replace('UID', '');
                return this.rfidCardService.searchUnassignedRfidCards(term).pipe(
                    tap(res => {
                        if (res == null || res.length === 0) {
                            this.searchFailed = true;
                            return of([]);

                        } else {
                            this.searchFailed = false;
                            return of([]);
                        }
                    }),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([]);
                    }));
            }))

    async addOrUpdateRfidCard(): Promise<void> {
        if (!this.userId) {
            this.rfidCardService.isRfidCardAlreadyAssigned(this.rfidCardResult.uid).pipe().subscribe(alreadyAssigned => {
                // Card can be locally assigned (at user's creation)
                const userCards = this.rfidCards.map(r => r.cardNumber);
                if (alreadyAssigned) {
                    this.alreadyAssigned = true;

                } else if (userCards.find(value => value === this.rfidCardResult.cardNumber)) {
                    this.selfAssigned = true;

                } else {
                    this.rfidCards.push(this.rfidCardResult);
                    this.activeModal.close(this.rfidCards);
                }
            });

        } else {
            this.rfidCardService.assignRfidCardToUser(this.userId, this.rfidCardResult).pipe().subscribe(rfiCard => {
                this.rfidCards.push(rfiCard);
                this.activeModal.close(this.rfidCards);
                this.notificationsService.success({ message: 'rfidCards.notifications.assignSuccess' });
            });
        }
    }

    private clearInput(): Array<SearchUserDto> {
        this.rfidCardResult = null;
        return [];
    }

    printFormattedCardIdentifier(rfidCard: RfidCardDto): string {
        return this.translateService.instant('users.rfid.formattedIdentifier', {cardNumber: rfidCard.cardNumber, uid: rfidCard.uid});
    }
}

<div class="row" *ngIf="datatransTransactionStatus !== ''">
    <div class="col-12">
        <div class="alert alert-success" role="alert" *ngIf="datatransTransactionStatus === 'Success'"
             translate="epayment.success">
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="datatransTransactionStatus === 'Revert'"
             translate="epayment.revert">
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="datatransTransactionStatus === 'Error'"
             translate="epayment.error">
        </div>

        <div class="alert alert-info" role="alert" *ngIf="datatransTransactionStatus === 'Cancel'"
             translate="epayment.cancel">
        </div>
    </div>
</div>

<!-- Warning Message to allow cam access  -->
<div class="row" *ngIf="(qrCodeReaderEnabled && !hasPermission) || displayCameraAccessMessage">
    <div class="col-12">
        <div class="alert alert-warning d-flex justify-content-start" role="alert"
             translate="services.view.allowCamAccess">
        </div>
    </div>
</div>

<div class="content-container" *ngIf="!!service">

    <!-- Title -->
    <div class="d-flex flex-row">

        <div class="title-container">
            <img src="{{ buildIconUrl(service.serviceConfig.iconUrl, 'gray') }}" alt="icon" height="40px" width="auto">
            <h1>{{ service['name' + crtLang.toUpperCase()] }}</h1>
        </div>
        <div class="disponibility-container">
            <fa-icon class="circle" [icon]="faCircle"
                     [ngStyle]="{ 'color': service.active ? 'green' : 'orange' }"></fa-icon>
            <p class="service-disponibility" [translate]="service.active ? 'services.view.available' : 'services.view.unavailable'"></p>
        </div>
    </div>

    <div class="btn-bar justify-content-start">
        <button class="btn btn-link" *ngIf="!cameraIsActivated"
                [appHasAnyRole]="[AppRoles.admin, AppRoles.configurator]"
                (click)="manageService()">
            <fa-icon class="me-2" [icon]="faPen"></fa-icon>
            <span translate="common.manageInfo"></span>
        </button>
    </div>

    <!-- Action Buttons -->
    <div class="btn-bar">
        <button class="btn btn-primary" *ngIf="canScanQrCode()"
                (click)="startQrCodeReader()"
                translate="services.view.scan">
        </button>

        <button class="btn btn-outline-secondary" *ngIf="cameraIsActivated"
                (click)="stopQrCodeReader()"
                translate="services.view.closeCam">
        </button>

        <button class="btn btn-primary" *ngIf="hasServiceAccessForServiceType(ServiceType.Wifi)"
                (click)="startBuyProcess()"
                translate="services.view.purchase.purchasable">
        </button>

        <button class="btn btn-primary"
                *ngIf="service?.serviceConfig.serviceType === ServiceType.FalconicDumpster && hasRole(AppRoles.admin)"
                (click)="exportPottingerStatistics()"
                translate="services.pottinger.actions.exportStatistics">
        </button>
    </div>

    <!-- QRCode scanner -->
    <div *ngIf="qrCodeReaderEnabled">
        <zxing-scanner [enable]="qrCodeReaderEnabled"
                       [formats]="[BarcodeFormat.QR_CODE]"
                       (scanSuccess)="scanSuccessHandler($event)"
                       (camerasFound)="camerasFoundHandler()"
                       (permissionResponse)="permissionResponse($event)">
        </zxing-scanner>
    </div>

    <!-- Common Service Details -->
    <div class="row">

        <div class="col-12 col-lg-6">

            <div class="d-flex flex-column gap-3">

                <!-- Description -->
                <div class="no-border-radius area-link" *ngIf="displayDescription"
                     [innerHTML]="service['description' + crtLang.toUpperCase()] | urlTextToLink">
                </div>

                <!-- Horaire -->
                <div *ngIf="lowestTariff?.nightRate">
                    <h5 translate="services.tariff.schedule"></h5>
                        <p class="mb-0">{{ 'services.tariff.modal.dayRateInfo' | translate: {
                            from: DateUtils.ngbTimeStructToString(DateUtils.stringToNgbTimeStruct(lowestTariff.nightRateTo), true),
                            to: DateUtils.ngbTimeStructMinusOneMinuteToString(DateUtils.stringToNgbTimeStruct(lowestTariff.nightRateFrom)) } }}</p>
                        <p>{{ 'services.tariff.modal.nightRateInfo' | translate: {
                            from: DateUtils.ngbTimeStructToString(DateUtils.stringToNgbTimeStruct(lowestTariff.nightRateFrom), true),
                            to: DateUtils.ngbTimeStructMinusOneMinuteToString(DateUtils.stringToNgbTimeStruct(lowestTariff.nightRateTo)) } }}</p>
                </div>

                <!-- Tarif -->
                <div *ngIf="service.tariffs.length > 0 && hasRoleToAccessTheService()">
                    <h5 translate="services.tariff.title"></h5>

                    <ng-container *ngIf="!!lowestTariff.customUnitFR">

                        <p class="mb-0" *ngFor="let price of lowestTariff?.tariffPrices">
                            <ng-container *ngIf="price.tariffConfigUnit">
                                {{ price.priceByUnit | currencyFormat }} / {{ 'common.units.' + price.tariffConfigUnit.unitEnumName + '.' + price.tariffConfigUnit.unitEnumValue | translate }}

                                <span *ngIf="price.nightRate">({{ 'services.tariff.modal.nightRate' | translate }})</span>
                            </ng-container>

                            <ng-container *ngIf="!price.tariffConfigUnit">
                                {{ price.priceByUnit | currencyFormat }} / {{ lowestTariff['customUnit' + crtLang.toUpperCase()] ? lowestTariff['customUnit' + crtLang.toUpperCase()] : lowestTariff.customUnitFR }}

                                <span *ngIf="price.nightRate">({{ 'services.tariff.modal.nightRate' | translate }})</span>
                            </ng-container>
                        </p>
                    </ng-container>

                    <ng-container *ngIf="!lowestTariff.customUnitFR">
                        <p class="mb-0" *ngFor="let price of lowestTariff.tariffPrices">
                            {{ price.priceByUnit | currencyFormat }} / {{ 'common.units.' + price.tariffConfigUnit.unitEnumName + '.' + price.tariffConfigUnit.unitEnumValue | translate }}

                            <span *ngIf="price.nightRate">({{ 'services.tariff.modal.nightRate' | translate }})</span>
                        </p>
                    </ng-container>
                </div>

                <!-- Availability Ranges -->
                <div *ngIf="service.availabilityRanges.length > 0">

                    <h5 translate="services.view.availabilityRange.title"></h5>

                    <div *ngFor="let item of service.availabilityRanges; let index = index" class="mb-3">

                        <!-- Availability Dates -->
                        <p class="m-0">
                                <span *ngIf="!item.endDate">{{ 'services.view.availabilityRange.sinceDate' | translate: {
                                    startDate: (item.startDate | date: dateFormat) } }}
                                </span>

                            <span *ngIf="item.endDate">{{ 'services.view.availabilityRange.fromToDate' | translate: {
                                startDate: (item.startDate | date: dateFormat),
                                endDate: (item.endDate | date: dateFormat) } }}
                                </span>
                        </p>

                        <!-- Availability Days -->
                        <p class="m-0" *ngIf="item.days.length > 0">
                            {{ item.days | dayRangeFormat }}
                        </p>

                        <!-- Availability Hours -->
                        <p class="m-0">
                                <span *ngIf="item.startTime === '00:00:00' && item.endTime === '23:59:00'">
                                    {{ 'services.view.availabilityRange.allDay' | translate }}
                                </span>
                            <span *ngIf="item.startTime !== '00:00:00' && item.endTime === '23:59:00'">
                                    {{ 'services.view.availabilityRange.sinceTime' | translate: {
                                startTime: formatTimeStringToDisplay(item.startTime) } }}
                                </span>
                            <span *ngIf="item.startTime === '00:00:00' && item.endTime !== '23:59:00'">
                                    {{ 'services.view.availabilityRange.untilTime' | translate: {
                                endTime: formatTimeStringToDisplay(item.endTime) } }}
                                </span>
                            <span *ngIf="item.startTime !== '00:00:00' && item.endTime !== '23:59:00'">
                                    {{ 'services.view.availabilityRange.fromToTime' | translate: {
                                startTime: formatTimeStringToDisplay(item.startTime),
                                endTime: formatTimeStringToDisplay(item.endTime) } }}
                                </span>
                        </p>
                    </div>
                </div>

                <!-- Measure points view-->
                <app-measuring-points-cockpit #measuringPointsCockpitComponent *ngIf="hasServiceAccessForServiceType(ServiceType.TimeLimited)"
                                              (activateMeasuringPoint)="activateMeasuringPoint($event)"
                                              [serviceId]="serviceId"></app-measuring-points-cockpit>

                <!-- Charging Stations view-->
                <app-charging-stations-cockpit *ngIf="hasServiceAccessForServiceType(ServiceType.ChargingStations) && service.active"
                                               [serviceId]="serviceId"></app-charging-stations-cockpit>

                <!-- Koco Dumpster view-->
                <app-koco-dumpster *ngIf="hasServiceAccessForServiceType(ServiceType.KocoDumpster)"
                                   [serviceId]="serviceId"></app-koco-dumpster>

            </div>

        </div>

        <!-- Complementary Informations -->
        <div *ngIf="displayInstruction || service.imageUrl" class="col-12 col-lg-6 mt-lg-0 mt-4">

            <div class="d-flex flex-column gap-3">

                <div class="instructions-container" *ngIf="displayInstruction">
                    <div class="logo-title-container">
                        <img src="../assets/icons/information.svg" alt="icon" height="40px" width="auto"/>
                        <h5><em translate>services.placeholder.{{ 'instruction' + crtLang.toUpperCase() }}</em></h5>
                    </div>

                    <div class="no-border-radius area-link"
                         [innerHTML]="service['instruction' + crtLang.toUpperCase()] | urlTextToLink">
                    </div>
                </div>

                <img class="img-thumbnail" *ngIf="service.imageUrl"
                     src="{{service.imageUrl}}" alt="Illustration">
            </div>

        </div>

    </div>
</div>

<app-activation-management #activationManagementComponent
                           *ngIf="loggedIn && hasServiceAccessForServiceType(ServiceType.TimeLimited)
                                  && ( hasRole(AppRoles.admin) || hasRole(AppRoles.user) )"
                           [serviceId]="serviceId"></app-activation-management>

<!-- Energy view -->
<app-energy *ngIf="hasServiceAccessForServiceType(ServiceType.Energy)"></app-energy>

<!-- Mooring place view -->
<app-mooring-place *ngIf="hasServiceAccessForServiceType(ServiceType.MooringPlace)"
                   [serviceId]="serviceId"></app-mooring-place>

<!--Wifi view-->
<app-wifi-subscription-list-for-admin *ngIf="hasServiceAccessForServiceType(ServiceType.Wifi) && hasRole(AppRoles.admin)"></app-wifi-subscription-list-for-admin>
<app-wifi-subscription-list-for-user *ngIf="hasServiceAccessForServiceType(ServiceType.Wifi) && !hasRole(AppRoles.admin)"></app-wifi-subscription-list-for-user>

<!-- User Alarms view -->
<app-user-alarm-amanagement *ngIf="hasServiceAccessForServiceType(ServiceType.UserAlarm)"
                            [serviceId]="serviceId"
                            [serviceActive]="service.active"
                            [tariff]="lowestTariff"></app-user-alarm-amanagement>

<!--Booking view-->
<app-booking-cockpit *ngIf="hasAccessToBookingCockpit()"
                     [serviceId]="service.serviceId"
                     [items]="service.items"
                     [tariff]="lowestTariff"></app-booking-cockpit>

<!-- Craning view -->
<app-craning *ngIf="hasServiceAccessForServiceType(ServiceType.CraningAndWinterizing)"
             [service]="service"></app-craning>

<!-- Webcam view-->
<app-webcam *ngIf="hasServiceAccessForServiceType(ServiceType.Webcam)"></app-webcam>

<!-- Weather view-->
<app-weather-cockpit *ngIf="hasServiceAccessForServiceType(ServiceType.Weather)"
                     [serviceId]="serviceId"></app-weather-cockpit>

<!-- Public Lighting view -->
<app-public-lighting *ngIf="hasServiceAccessForServiceType(ServiceType.PublicLighting)"
                     [serviceId]="serviceId"></app-public-lighting>

<!-- Charging Process view-->
<app-charging-process-management #chargingProcessManagementComponent *ngIf="hasServiceAccessForServiceType(ServiceType.ChargingStations) && loggedIn"
                                 [serviceId]="serviceId"></app-charging-process-management>

<div class="content-container">
    <div class="btn-bar">
        <button class="btn btn-outline-secondary"
                (click)="back()" translate="common.back">
        </button>
    </div>
</div>


import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {RoleDto, RoleService, UserService} from "../../_services/configuration-services";
import {TranslateService} from "@ngx-translate/core";
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from "@angular/forms";
import * as fileSaver from 'file-saver';
import {RoleManagementComponent} from "../../_shared/_components/role-management/role-management.component";

@Component({
    selector: 'app-user-export-modal',
    templateUrl: './user-export-modal.component.html'
})
export class UserExportModalComponent implements OnInit {
    @ViewChild(RoleManagementComponent) roleComponent: RoleManagementComponent;

    exportForm: UntypedFormGroup;
    customRoles: RoleDto[] = [];
    basicRoles: RoleDto[] = [];


    constructor(public activeModal: NgbActiveModal,
                private readonly translateService: TranslateService,
                private readonly roleService: RoleService,
                private readonly userService: UserService) {

        this.exportForm = new UntypedFormGroup({
            rolesForm: new UntypedFormGroup({
                baseRolesForm: new UntypedFormArray([]),
                customRolesForm: new UntypedFormArray([], {updateOn: 'change'})
            }, {
                validators: UserExportModalComponent.atLeastOneRoleSelectedValidator
            }),
        });
    }

    ngOnInit(): void {
        this.roleService.getAllRoles(false).pipe().subscribe(roles => {
            this.customRoles = roles.filter(r => !r.isBase);
            this.basicRoles = roles.filter(r => r.isBase);
            this.initializeRolesForm();

        });
    }

    exportUsers() {
        this.userService.exportUsers(this.roleComponent.getSelectedRoles()).pipe().subscribe(response => {
            fileSaver.saveAs(response.data, response.fileName);
            this.activeModal.close('success')
        });
    }

    private initializeRolesForm(): void {
        this.customRoles.forEach(_ => this.customRolesFormArray.push(
            new UntypedFormControl(false)
        ));

        this.basicRoles.forEach(_ => this.baseRolesFormArray.push(
            new UntypedFormControl(false)
        ));
    }

    get rolesFormArray(): UntypedFormGroup {
        return this.exportForm.get('rolesForm') as UntypedFormGroup;
    }

    get baseRolesFormArray(): UntypedFormArray {
        return this.rolesFormArray.get('baseRolesForm') as UntypedFormArray;
    }

    get customRolesFormArray(): UntypedFormArray {
        return this.rolesFormArray.get('customRolesForm') as UntypedFormArray;
    }

    public static atLeastOneRoleSelectedValidator(formGroup: UntypedFormGroup): ValidationErrors | null {
        let controls: AbstractControl[] = [];
        Object.keys(formGroup.controls).forEach(key => {
            let array = formGroup.get(key) as UntypedFormArray
            for (const control of array.controls) {
                controls.push(control)
            }
        });

        for (const control of controls) {
            if (Validators.requiredTrue(control) === null) {
                return null;
            }
        }

        return {noneSelected: true};
    }
}

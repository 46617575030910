<div class="modal-header">
    <h4 class="modal-title" translate>
        {{ isEditMode ? 'alerts.device.modal.title.edit' : 'alerts.device.modal.title.add' }}
    </h4>

    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">

    <form [formGroup]="deviceForm">
        <div class="row">

            <!-- Left Form - Base Config -->
            <div class="col-12 col-lg-6">
                <h4 translate="alerts.device.modal.section.device"></h4>

                <div class="row">

                    <div class="col-12">
                        <label class="form-label" for="iotRegister">{{ 'alerts.device.modal.form.designation' | translate }}*</label>
                        <app-search-iot-register id="iotRegister" #searchIotRegisterComponent
                                                 (selectedRegisterEvent)="iotRegister.setValue($event)"
                                                 [registerTypes]="[RegisterType.HistoricUsage, RegisterType.CurrentUsage]"
                                                 [measuringPointType]="MeasuringPointType.Admin"
                                                 [disabled]="isEditMode"></app-search-iot-register>
                    </div>

                    <div class="col-12" *ngIf="iotRegister.value">
                        <label class="form-label" for="identifier">{{ 'alerts.device.modal.form.identifier' | translate }}*</label>
                        <input class="form-control" id="identifier" type="text" name="identifier"
                               [value]="iotRegister.value.name" disabled/>
                    </div>

                    <div class="col-12" *ngIf="iotRegister.value">
                        <label class="form-label" for="equipment">{{ 'alerts.device.modal.form.equipment' | translate }}*</label>
                        <input class="form-control" id="equipment" type="text" name="equipment"
                               [value]="getIotRegisterEquipment(iotRegister.value)" disabled/>
                    </div>

                    <div class="col-12">
                        <label class="form-label" for="location">{{ 'alerts.device.modal.form.location' | translate }}*</label>
                        <input class="form-control" id="location" type="text" name="location"
                               formControlName="location"
                               maxlength="50" pattern="{{textInputPattern}}"/>
                        <app-form-error [control]="location"></app-form-error>
                    </div>

                    <div class="col-12">
                        <label class="form-label" for="classification">{{ 'alerts.device.modal.form.classification' | translate }}*</label>
                        <select class="form-control form-select" id="classification" name="classification"
                                formControlName="classification">
                            <option *ngFor="let classification of availableClassifications;"
                                    [ngValue]="classification">{{ 'common.alarmClassification.' + classification | translate }}</option>
                        </select>
                        <app-form-error [control]="classification"></app-form-error>
                    </div>

                    <div class="col-12">
                        <label class="form-label">{{ 'alerts.device.modal.form.state' | translate }}*</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" id="state" type="checkbox" name="state"
                                   formControlName="state"/>
                            <label class="form-check-label" for="state">
                                {{ (this.state.value ? 'alerts.device.state.active' : 'alerts.device.state.inactive') | translate }}
                            </label>
                        </div>
                    </div>

                    <div class="col-12">
                        <label class="form-label">{{ 'alerts.device.modal.form.comment' | translate }}</label>
                        <textarea class="form-control" id="comment" name="comment"
                                  formControlName="comment"
                                  pattern="{{textInputPattern}}" maxlength="255"></textarea>
                    </div>

                </div>

            </div>

            <!-- Right Form - Rules Config -->
            <div class="col-12 col-lg-6">
                <h4 translate="alerts.device.modal.section.configuration"></h4>

                <div class="row">

                    <div class="col-12">
                        <label class="form-label" for="comparisonSymbol">{{ 'alerts.device.modal.form.comparisonSymbol' | translate }}*</label>
                        <select class="form-control form-select" id="comparisonSymbol" name="comparisonSymbol"
                                formControlName="comparisonSymbol">
                            <option *ngFor="let comparisonSymbol of comparisonSymbols;"
                                    [ngValue]="comparisonSymbol">{{ 'common.comparisonSymbol.' + comparisonSymbol | translate }}</option>
                        </select>
                        <app-form-error [control]="comparisonSymbol"></app-form-error>
                    </div>

                    <div class="col-12">
                        <label class="form-label" for="threshold">{{ 'alerts.device.modal.form.threshold' | translate }}*</label>
                        <input class="form-control" id="threshold" type="text" name="threshold"
                               formControlName="threshold"
                               maxlength="50" pattern="{{textInputPattern}}"/>
                        <app-form-error [control]="threshold"></app-form-error>
                    </div>

                    <div class="col-12">
                        <label class="form-label" for="unit">{{ 'alerts.device.modal.form.unit' | translate }}*</label>
                        <input class="form-control" id="unit" type="text" name="unit"
                               formControlName="unit"
                               maxlength="50" pattern="{{textInputPattern}}"/>
                        <app-form-error [control]="unit"></app-form-error>
                    </div>

                </div>

            </div>

        </div>
    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>

    <button type="submit" (click)="saveDevice()" class="btn btn-primary"
            [disabled]="deviceForm.invalid"
            translate>
        {{ isEditMode ? 'common.modify' : 'common.add' }}
    </button>
</div>

import {Component, Input, ViewChild} from '@angular/core';
import {ChartConfiguration, ChartData} from 'chart.js';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {DateUtils} from '../../../../_shared/date-utils';
import {BaseChartDirective} from 'ng2-charts';

@Component({
    selector: 'app-consumption-bar-chart',
    templateUrl: './consumption-bar-chart.component.html'
})
export class ConsumptionBarChartComponent {

    @Input() set consumptionData(consumptionData: ConsumptionData[]) {
        this.display = consumptionData.length > 1;
        this.processData(consumptionData);
    }

    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    display = false;

    private monthDateFormat = 'MM.yyyy';
    private dayDateFormat = DateUtils.dateFormat;
    private hourDateFormat = 'HH:00';
    private dateFormat: string = this.hourDateFormat;

    barChartData: ChartData<'bar'> = {
        labels: [],
        datasets: [],
    };

    barChartOptions: ChartConfiguration['options'];

    constructor(private readonly datePipe: DatePipe,
                private readonly translateService: TranslateService) {

        this.barChartOptions = {
            responsive: true,
            scales: {
                x: {},
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: this.translateService.instant('common.units.EnergyConsumptionUnitType.Kwh')
                    }
                },
            },
            plugins: {
                legend: {
                    display: true,
                }
            },
        };
    }

    processData(consumptionData: ConsumptionData[]): void {
        if (!this.display){
            this.barChartData.datasets[0].data = [];
            this.barChartData.labels[0] = [];
            return;
        }

        consumptionData.sort((a: ConsumptionData, b: ConsumptionData) => {
            return a.date.getTime() - b.date.getTime();
        });

        // check if we need to display the data by hour / day / month
        const diffInDays = (consumptionData[consumptionData.length - 1].date.getTime()
            - consumptionData[0].date.getTime()) / 1000 / 60 / 60 / 24;
        if (diffInDays < 1) {
            this.dateFormat = this.hourDateFormat;

        } else if (diffInDays < 32) {
            this.dateFormat = this.dayDateFormat;

        } else {
            this.dateFormat = this.monthDateFormat;
        }

        this.barChartData.datasets[0] = {
            data: consumptionData.map(d => +d.value.toFixed(2)),
            label: this.translateService.instant('services.energy.cockpit.user.chart-title'),
            backgroundColor: '#003a7c',
            hoverBackgroundColor: '#4b68b1',
        };

        this.barChartData.labels = consumptionData.map(d => this.datePipe.transform(d.date, this.dateFormat));

        this.chart?.update();
    }

}

export interface ConsumptionData {
    value: number;
    date: Date;
}

<div class="modal-header">

    <h4 [translate]="!track ? 'services.hydrowash.addTrack.title' : 'services.hydrowash.updateTrack.title'"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="trackForm">

        <div class="row">

            <div class="col-12 col-md-6">
                <label class="form-label" for="trackId" translate="services.hydrowash.trackId"></label>
                <input class="form-control" id="trackId" pattern="{{ textInputPattern }}" type="text"
                       formControlName="trackId" disabled />
            </div>

            <div class="col-12 col-md-6">
                <label class="form-label" for="trackName">{{ 'services.hydrowash.trackName' | translate }}*</label>
                <input class="form-control" id="trackName" pattern="{{textInputPattern}}" type="text"
                       placeholder="{{ 'services.hydrowash.trackName' | translate }}"
                       maxLength="25"
                       formControlName="trackName" />
                <app-form-error [control]="trackName"></app-form-error>
            </div>

            <div class="col-12">
                <label class="form-label" for="trackDescription">{{ 'services.hydrowash.trackDescription' | translate }}</label>
                <textarea class="form-control" id="trackDescription"
                          placeholder="{{ 'services.hydrowash.trackDescription' | translate }}"
                          maxLength="50" rows="2"
                          formControlName="trackDescription"></textarea>
            </div>
        </div>

    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="services.hydrowash.action.cancel">
    </button>
    <button type="submit" class="btn btn-primary"
            (click)="createOrUpdateTrack()"
            [disabled]="trackForm.invalid"
            translate="services.hydrowash.action.save">
    </button>
</div>


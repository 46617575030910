import {Component, Input, OnInit} from '@angular/core';
import {FormUtils} from '../../../../../_shared/form-utils';
import {FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LightZoneDto, LightZoneInputDto, PublicLightingZoneService} from '../../../../../_services/configuration-services';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {CustomerConfigService} from '../../../../../_shared/customer-config-service';

@Component({
  selector: 'app-light-zone-management-modal',
  templateUrl: './light-zone-management-modal.component.html'
})
export class LightZoneManagementModalComponent implements OnInit {

    @Input() serviceId: string;
    @Input() lightZone: LightZoneDto | null = null;

    FormUtils = FormUtils;

    lightZoneForm: UntypedFormGroup;

    constructor(public activeModal: NgbActiveModal,
                private readonly publicLightingZoneService: PublicLightingZoneService,
                private readonly notificationService: NotificationsService,
                public readonly customerConfigService: CustomerConfigService) {

        this.lightZoneForm = new UntypedFormGroup({
            code: new FormControl<string>(null, [Validators.required, Validators.maxLength(255)]),
            descriptionFR: new FormControl<string>(null,[Validators.required, Validators.maxLength(255)]),
            descriptionDE: new FormControl<string | null>(null, Validators.maxLength(255)),
            descriptionIT: new FormControl<string | null>(null, Validators.maxLength(255)),
            descriptionEN: new FormControl<string | null>(null, Validators.maxLength(255)),
        })
    }
    
    public ngOnInit(): void {
        if (this.lightZone != null) {
            this.code.setValue(this.lightZone.code);
            this.descriptionDE.setValue(this.lightZone.descriptionDE);
            this.descriptionFR.setValue(this.lightZone.descriptionFR);
            this.descriptionIT.setValue(this.lightZone.descriptionIT);
            this.descriptionEN.setValue(this.lightZone.descriptionEN);
        }
    }
    
    public saveLightZone(): void {
        const lightZoneToSave = new LightZoneInputDto({
            code: this.code.value,
            serviceId: this.serviceId,
            descriptionFR: this.descriptionFR.value,
            descriptionDE: this.descriptionDE.value,
            descriptionIT: this.descriptionIT.value,
            descriptionEN: this.descriptionEN.value,
        });
        
        if (this.lightZone == null) {
            this.publicLightingZoneService.createLightZone(lightZoneToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.publicLighting.lightZone.notifications.addSuccess'});
                this.activeModal.close('success');
            });
        } else {
            this.publicLightingZoneService.updateLightZone(this.lightZone.id, lightZoneToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.publicLighting.lightZone.notifications.editSuccess'});
                this.activeModal.close('success');
            });
        }
    }

    get code(): FormControl {
        return this.lightZoneForm.get('code') as FormControl<string>;
    }

    get descriptionFR(): FormControl {
        return this.lightZoneForm.get('descriptionFR') as FormControl<string>;
    }

    get descriptionDE(): FormControl {
        return this.lightZoneForm.get('descriptionDE') as FormControl<string | null>;
    }

    get descriptionIT(): FormControl {
        return this.lightZoneForm.get('descriptionIT') as FormControl<string | null>;
    }

    get descriptionEN(): FormControl {
        return this.lightZoneForm.get('descriptionEN') as FormControl<string | null>;
    }

}

import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {
    CraningServiceDto,
    CraningServicePricingDto,
    CraningServiceService
} from '../../../../../_services/configuration-services';
import {Subscription} from 'rxjs';
import {faPen, faTrash, faQuestion} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {CraningServiceManagementModalComponent} from './craning-service-management-modal/craning-service-management-modal.component';
import {CraningServicePricingManagementModalComponent} from './craning-service-pricing-management-modal/craning-service-pricing-management-modal.component';
import {CraningServiceQuestionManagementModalComponent} from './craning-service-question-management-modal/craning-service-question-management-modal.component';
import {ConfirmModalService} from '../../../../../_shared/_components/confirm-modal/confirm-modal.component';
import {TranslateUtils} from '../../../../../_shared/translate-utils';
import {faDollarSign} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-craning-service-management',
    templateUrl: './craning-service-management.component.html',
})
export class CraningServiceManagementComponent implements OnInit, OnDestroy {

    @Input() serviceId: string;
    private langChangeSubscription: Subscription;

    icons = {
        edit: faPen,
        delete: faTrash,
        pricings: faDollarSign,
        questions: faQuestion
    };

    crtLang = TranslateUtils.defaultLanguage;

    craningServices: CraningServiceDto[] = [];

    constructor(
        private readonly craningServiceService: CraningServiceService,
        private readonly translateService: TranslateService,
        private readonly notificationsService: NotificationsService,
        private readonly confirmService: ConfirmModalService,
        private readonly modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                }
            });
        this.fetchCraningServices();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription?.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    getCraningServiceLabel(craningService: CraningServiceDto): string {
        return craningService['label' + this.crtLang.toUpperCase()];
    }

    getCraningServicePricingCategoryName(craningServicePricing: CraningServicePricingDto): string {
        return craningServicePricing['categoryName' + this.crtLang.toUpperCase()];
    }

    createCraningService(): void {
        this.updateCraningService(null);
    }

    updateCraningService(craningService: CraningServiceDto): void {
        const modal = this.modalService.open(CraningServiceManagementModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.craningService = !craningService ? null : new CraningServiceDto(craningService);
        modal.result
            .then(value => {
                if (value === 'success') {
                    this.fetchCraningServices();
                }
            }, () => { /* catch the rejection */ });
    }

    deleteCraningService(craningService: CraningServiceDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.craningServiceService.delete(craningService.id).pipe().subscribe(_ => {
                    this.notificationsService.success({title: 'services.craning.notifications.deleteServiceSuccess'});
                    this.fetchCraningServices();
                });
            }
        });
    }

    editCraningServicePricings(craningService: CraningServiceDto): void {
        this.openSubManagementModal(craningService, CraningServicePricingManagementModalComponent);
    }

    editCraningServiceQuestions(craningService: CraningServiceDto): void {
        this.openSubManagementModal(craningService, CraningServiceQuestionManagementModalComponent);
    }

    private openSubManagementModal(craningService: CraningServiceDto, modalComponent: any): void {
        const modal = this.modalService.open(modalComponent, {
            centered: true,
            size: 'lg'
        });
        modal.componentInstance.craningServiceId = craningService.id;
        modal.componentInstance.crtLang = this.crtLang;
        modal.result
            .then(_ => {
                this.fetchCraningServices();
            }, () => { /* catch the rejection */ });
    }

    private fetchCraningServices(): void {
        this.craningServiceService.getAll(this.serviceId).pipe().subscribe(craningServices => {
            this.craningServices = craningServices;
        });
    }
}

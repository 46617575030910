<div>
    <div class="modal-header">
        <h4 class="modal-title" translate>services.craning.slots.modal.deletion.title</h4>

        <button class="btn-close" aria-label="Close" (click)="craningBookingSlotsDeletionModal.dismiss('Cross click')">

        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="craningBookingSlotsDeletionForm">

            <!-- date period -->
            <div class="row">
                <app-date-period-picker [required]="true"
                                        [fromMinDate]="minDate"
                                        [toMinDate]="minDate"
                                        [periodControl]="datePeriod"></app-date-period-picker>
            </div>

        </form>
    </div>

    <div class="modal-footer">

        <button type="submit" class="btn btn-outline-secondary" (click)="craningBookingSlotsDeletionModal.close()" translate>
            common.close
        </button>

        <button class="btn btn-primary" type="submit"
                [disabled]="craningBookingSlotsDeletionForm.invalid"
                (click)="deleteCraningBookingSlots()" translate>
            common.delete
        </button>

    </div>
</div>


import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../_shared/form-utils';
import {
    Day,
    OfficeHourDto,
    OfficeHourService
} from '../../../_services/configuration-services';
import {NotificationsService} from '../../../_shared/notifications.service';
import {DateUtils} from '../../../_shared/date-utils';

@Component({
    selector: 'app-office-hour-modal',
    templateUrl: './office-hour-modal.component.html'
})
export class OfficeHourModalComponent implements OnInit {
    @Input() officeHour: OfficeHourDto;

    officeHourForm: UntypedFormGroup;

    daysOfWeek = Object.keys(Day);

    constructor(public officeHourModal: NgbActiveModal,
                private readonly officeHourService: OfficeHourService,
                private readonly notificationsService: NotificationsService) {
        const dayFormArray = [];
        for (const day of this.daysOfWeek) {
            dayFormArray.push(new UntypedFormControl(true));
        }

        this.officeHourForm = new UntypedFormGroup({
            openingHour: new UntypedFormGroup({
                from: new UntypedFormControl(null, Validators.required),
                to: new UntypedFormControl(null, Validators.required),
            }, [FormUtils.timePeriodValidator]),
            days: new UntypedFormArray(dayFormArray)
        }, {updateOn: 'change'});

    }

    ngOnInit(): void {
        for (const [index, day] of this.daysOfWeek.entries()) {
            this.days.get('' + index).setValue(this.isDayActive(day));
        }

        if (!!this.officeHour.startHour) {
            this.startHour.setValue(DateUtils.stringToNgbTimeStruct(this.officeHour.startHour));
        }
        if (!!this.officeHour.endHour) {
            this.endHour.setValue(DateUtils.stringToNgbTimeStruct(this.officeHour.endHour));
        }
    }

    updateOfficeHour(): void {
        if (!this.officeHourForm.valid) {
            return;
        }

        const officeHour = new OfficeHourDto({
            endHour: DateUtils.ngbTimeStructToString(this.endHour.value),
            startHour: DateUtils.ngbTimeStructToString(this.startHour.value),
        });

        for (const [index, day] of this.daysOfWeek.entries()) {
            this.setDayActive(officeHour, day, this.days.get('' + index).value);
        }

        this.officeHourService.setOfficeHour(officeHour).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'officeHours.notifications.updateOfficeHourSuccess'});
            this.officeHourModal.close('success');
        });
    }

    isOpeningHourValid(): boolean {
        return this.openingHour.valid || !this.startHour.value || !this.endHour.value;
    }

    get openingHour(): UntypedFormGroup {
        return this.officeHourForm.get('openingHour') as UntypedFormGroup;
    }

    get startHour(): UntypedFormControl {
        return this.openingHour.get('from') as UntypedFormControl;
    }

    get endHour(): UntypedFormControl {
        return this.openingHour.get('to') as UntypedFormControl;
    }

    get days(): UntypedFormArray {
        return this.officeHourForm.get('days') as UntypedFormArray;
    }

    private isDayActive(day: string): boolean {
        switch (day) {
            case Day.Monday:
                return this.officeHour.monday;
            case Day.Tuesday:
                return this.officeHour.tuesday;
            case Day.Wednesday:
                return this.officeHour.wednesday;
            case Day.Thursday:
                return this.officeHour.thursday;
            case Day.Friday:
                return this.officeHour.friday;
            case Day.Saturday:
                return this.officeHour.saturday;
            case Day.Sunday:
                return this.officeHour.sunday;
            default:
                return false;
        }
    }

    private setDayActive(data: OfficeHourDto, day: string, value: boolean): void {
        switch (day) {
            case Day.Monday:
                data.monday = value;
                break;
            case Day.Tuesday:
                data.tuesday = value;
                break;
            case Day.Wednesday:
                data.wednesday = value;
                break;
            case Day.Thursday:
                data.thursday = value;
                break;
            case Day.Friday:
                data.friday = value;
                break;
            case Day.Saturday:
                data.saturday = value;
                break;
            case Day.Sunday:
                data.sunday = value;
                break;
            default:
                break;
        }
    }
}

<h4 translate="services.items.title"></h4>

<div class="btn-bar">
    <button class="btn btn-primary"
            (click)="createItem()"
            translate="services.items.actions.add">
    </button>
</div>

<div class="table-responsive">
    <table class="table table-striped" *ngIf="items.length > 0"
           [attr.aria-label]="'services.items.title' | translate">

        <thead>
        <tr>
            <th scope="col" style="min-width:250px" translate="services.items.table.item"></th>
            <th scope="col" style="min-width:325px" translate="services.items.table.description"></th>
            <th scope="col" style="min-width:200px" translate="services.items.table.measuringPoint"></th>
            <th scope="col" style="min-width:100px"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="items.length === 0">
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td></td>
        </tr>
        <tr *ngFor="let item of items;">
            <td>{{item['name' + crtLang.toUpperCase()]}}</td>

            <td class="text-pre-wrap">{{item['description' + crtLang.toUpperCase()]}}</td>

            <td>{{ getMeasuringPointName(item.measuringPoint) }}</td>

            <td class="text-end">
                <button class="btn btn-link btn-sm"
                        (click)="updateItem(item)"
                        title="{{'common.tooltip.edit' | translate}}">
                    <fa-icon [icon]="icons.edit"></fa-icon>
                </button>

                <button class="btn btn-link btn-sm"
                        (click)="editItemQuestions(item)"
                        title="{{'services.craning.tooltip.questions' | translate}}">
                    <fa-icon [icon]="icons.questions"></fa-icon>
                </button>

                <button class="btn btn-link btn-sm"
                        (click)="deleteItem(item)"
                        title="{{'common.tooltip.delete' | translate}}">
                    <fa-icon [icon]="icons.delete"></fa-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="modal-header">
    <h4 class="modal-title" translate="services.kocoDumpster.activation.title"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <div translate="services.kocoDumpster.activation.confirmation"></div>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>
    <button type="submit" class="btn btn-primary"
            (click)="activateService()"
            translate="common.confirm">
    </button>
</div>




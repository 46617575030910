
<h4 translate="services.userAlarm.logs.title"></h4>

<!-- Filters -->
<form [formGroup]="filter.formGroup"
      (keyup.enter)="applyFilters()">

    <div class="row">

        <!-- IotRegister -->
        <div class="default-filter-form-field">
            <label class="form-label" for="iotRegister" translate="services.userAlarm.logs.filter.label.iotRegister"></label>
            <select class="form-control form-select" id="iotRegister" name="iotRegister"
                    formControlName="iotRegister">
                <option [ngValue]="null">-</option>
                <option *ngFor="let iotRegister of availableIotRegisters;"
                        [ngValue]="iotRegister">{{ getIotRegisterLabel(iotRegister) }}</option>
            </select>
        </div>

        <!-- Designation -->
        <div class="default-filter-form-field">
            <label class="form-label" for="designation" translate="services.userAlarm.logs.filter.label.designation"></label>
            <input class="form-control" id="designation" type="text" name="designation"
                   formControlName="designation"
                   maxlength="50" pattern="{{textInputPattern}}" />
        </div>

        <!-- State -->
        <div class="default-filter-form-field">
            <label class="form-label" translate="services.userAlarm.logs.filter.label.state"></label>
            <select class="form-control form-select" id="state" name="state"
                    formControlName="state">
                <option [ngValue]="null">-</option>
                <option *ngFor="let state of filter.statesDropdownList"
                        [ngValue]="state.value">{{'common.alarm.state.' + state.key | translate}}</option>
            </select>
        </div>

        <!-- Appeared Datetime -->
        <div class="default-filter-form-field" formGroupName="startPeriod">
            <app-date-period-picker [periodControl]="startPeriod"
                                    inputLabel="services.userAlarm.logs.filter.label.startDate"></app-date-period-picker>
        </div>

        <!-- Reelased DateTime -->
        <div class="default-filter-form-field" formGroupName="endPeriod">
            <app-date-period-picker [periodControl]="endPeriod"
                                    inputLabel="services.userAlarm.logs.filter.label.endDate"></app-date-period-picker>
        </div>

    </div>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary"
                (click)="clearFilter()"
                translate="common.clearFilter"></button>

        <button class="btn btn-primary"
                (click)="applyFilters()"
                [disabled]="filter.formGroup.invalid"
                translate="services.wifi.subscriptions.filter.filter"></button>
    </div>
</form>

<ngx-datatable #alarmsTable class="material"
               [columnMode]="'force'"
               [rows]="alarms.results"
               [headerHeight]="50"
               [footerHeight]="50"
               rowHeight="50"
               [reorderable]="false"
               [externalPaging]="true"
               [externalSorting]="true"
               [count]="alarms.rowCount"
               [sorts]="[{prop: 'StartDateTime', dir: 'desc'}]"
               [messages]="{
                    totalMessage: 'common.datatable.total' | translate
                }"
               [offset]="crtPage"
               [limit]="alarms.pageSize"
               (page)="setPage($event)"
               (sort)="onSort($event)"
               [scrollbarH]="true">

    <ngx-datatable-column [width]="150" prop="userAlarmSubscription.iotRegister.label{{crtLang.toUpperCase()}}" sortable="true"
                          name="{{'services.userAlarm.logs.table.iotRegister' | translate}}">
    </ngx-datatable-column>

    <ngx-datatable-column [width]="150" prop="userAlarmSubscription.designation" sortable="true"
                          name="{{'services.userAlarm.logs.table.designation' | translate}}">
    </ngx-datatable-column>

    <ngx-datatable-column [width]="150" prop="startDateTime" sortable="true"
                          name="{{'services.userAlarm.logs.table.startDate' | translate}}">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{ row.startDateTime | date:dateTimeFormat}}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="150" prop="endDateTime" sortable="true"
                          name="{{'services.userAlarm.logs.table.endDate' | translate}}">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{ row.endDateTime | date:dateTimeFormat}}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="150" prop="active" sortable="true"
                          name="{{'services.userAlarm.logs.table.state' | translate}}">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{ (row.active ? 'common.alarm.state.active' : 'common.alarm.state.released') | translate }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
        <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            let-isVisible="isVisible">
            <div class="page-count">
                {{ rowCount }} {{ 'common.datatable.total' | translate }}
            </div>
            <datatable-pager
                [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'"
                [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!((rowCount / pageSize) > 1)"
                (change)="alarmsTable.onFooterPage($event)">
            </datatable-pager>
        </ng-template>
    </ngx-datatable-footer>
</ngx-datatable>

import {Component, Input} from '@angular/core';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {
    BookingLightDto,
    BookingService
} from '../../../../../_services/configuration-services';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DatePeriod} from "../../../../../_shared/_components/date-period-picker/date-period-picker.component";
import {DateUtils} from "../../../../../_shared/date-utils";
import {firstValueFrom} from "rxjs";
import {ConfirmModalService} from "../../../../../_shared/_components/confirm-modal/confirm-modal.component";

@Component({
    selector: 'app-booking-form-recurring-partial-cancel-modal',
    templateUrl: './booking-form-recurring-partial-cancel-modal.component.html'
})
export class BookingFormRecurringPartialCancelModalComponent {

    @Input() booking!: BookingLightDto;
    @Input() hasAdminRoleForBooking = false;

    datePeriod: FormGroup<DatePeriod>;

    minDate: NgbDateStruct;

    constructor(public activeModal: NgbActiveModal,
                private readonly notificationsService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                private readonly bookingService: BookingService) {

        this.datePeriod = new FormGroup<DatePeriod> ({
            from: new FormControl<NgbDateStruct>(null, Validators.required),
            to: new FormControl<NgbDateStruct>(null, Validators.required),
        });

        this.minDate = DateUtils.dateToNgbDateStruct(this.booking?.startDate ?? new Date());
    }

    async validate(reason: string): Promise<void> {
        this.confirmService.confirm({
            titleKey: 'common.confirmModal.title.cancel',
            messageKey: 'common.confirmModal.message.deleteBookingPartially'
        }).then(async result => {
            if (result === ConfirmModalService.yes) {
                if (this.hasAdminRoleForBooking) {
                    await firstValueFrom(this.bookingService.cancelPartialRecurringBookingForAdmin(this.booking.id, DateUtils.ngbDateStructToDate(this.startDate.value), DateUtils.ngbDateStructToDate(this.endDate.value)));
                } else {
                    await firstValueFrom(this.bookingService.cancelPartialRecurringBookingForUser(this.booking.id, DateUtils.ngbDateStructToDate(this.startDate.value), DateUtils.ngbDateStructToDate(this.endDate.value)));
                }

                this.notificationsService.success({title: 'bookings.notifications.cancelBookingPartialSuccess'});

                this.activeModal.close(reason);
            }
        });


    }

    cancel(reason: string): void {
        this.activeModal.dismiss(reason);
    }

    get startDate(): FormControl<NgbDateStruct> {
        return this.datePeriod.get('from') as FormControl<NgbDateStruct>;
    }

    get endDate(): FormControl<NgbDateStruct> {
        return this.datePeriod.get('to') as FormControl<NgbDateStruct>;
    }
}

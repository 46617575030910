<div>
    <div class="modal-header">
        <h4 class="modal-title" translate="services.craning.slots.modal.management.title"></h4>

        <button class="btn-close" aria-label="Close"
                (click)="craningBookingSlotManagementModal.dismiss('Cross click')">
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="craningBookingSlotManagementForm">
            <div class="row" >

                <div class="col-12 col-sm-6" formGroupName="timePeriod">
                    <label class="form-label" for="startTime">{{ 'services.craning.slots.form.management.startTime' | translate }}*</label>

                    <ngb-timepicker id="startTime" formControlName="from"></ngb-timepicker>
                    <app-form-error [control]="startTime"></app-form-error>
                </div>

                <div class="col-12 col-sm-6" formGroupName="timePeriod">
                    <label class="form-label" for="duration">{{ 'services.craning.slots.form.management.slotDuration' | translate }}*</label>

                    <ngb-timepicker id="duration" formControlName="duration"></ngb-timepicker>
                    <app-form-error [control]="duration"></app-form-error>
                    <app-form-error [control]="timePeriod"></app-form-error>
                </div>

                <!-- state -->
                <div class="col-12" *ngIf="authorizedCraningBookingSlotStates.length > 1">

                    <label class="form-label">{{'services.craning.slots.form.management.state' | translate}}*</label>

                    <div>
                        <div class="btn-group" role="group">
                            <ng-container *ngFor="let s of authorizedCraningBookingSlotStates; let first = first; let last = last">
                                <input class="btn-check" type="radio"
                                       [formControl]="state"
                                       [value]="s">

                                <label class="btn btn-toggle h-auto"
                                       [ngClass]='{
                                       "active": s === state.value,
                                       "btn-toggle-left": first,
                                       "btn-toggle-right": last }'
                                       (click)="state.setValue(s)"
                                       (keyup)="state.setValue(s)">
                                    {{ 'services.craning.slots.stateEnum.' + s | translate }}
                                </label>
                            </ng-container>
                        </div>
                    </div>

                </div>

            </div>
        </form>
    </div>

    <div class="modal-footer">

        <button type="submit" class="btn btn-outline-danger" *ngIf="canDeleteSlot()"
                (click)="deleteSlot()"
                translate="common.delete">
        </button>

        <button type="submit" class="btn btn-outline-secondary" *ngIf="canDisplayBooking()"
                (click)="onBookingClick('display')"
                translate="services.craning.booking.actions.displayBooking">
        </button>

        <button type="submit" class="btn btn-outline-secondary" *ngIf="canCreateBooking()"
                (click)="onBookingClick('create')"
                translate="services.craning.booking.actions.addBooking">
        </button>

        <button type="submit" class="btn btn-outline-secondary" *ngIf="canEditBooking()"
                (click)="onBookingClick('edit')"
                translate="bookings.update">
        </button>

        <button class="btn btn-primary" type="submit"
                [disabled]="craningBookingSlotManagementForm.invalid"
                (click)="updateSlot()"
                translate="common.save">
        </button>

    </div>
</div>


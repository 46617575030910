<div class="modal-header">
    <h4 class="modal-title" translate="services.chargingStations.chargingProcesses.activate"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">

    <form [formGroup]="activationForm">

        <div class="row" *ngIf="chargingStation">

            <div class="col-12 col-lg-6">
                <label class="form-label">{{ 'services.chargingStations.chargingProcesses.chargingStation' | translate }}</label>
                <p>{{ chargingStation['name' + crtLang.toUpperCase()] }}</p>
            </div>

            <div class="col-12 col-lg-6">
                <label class="form-label" translate="services.chargingStations.plug"></label>
                <p>{{ evseIndex }}</p>
            </div>

            <div class="col-12 col-lg-6">
                <label class="form-label" translate="services.chargingStations.chargingProcesses.plugState"></label>
                <p>{{ 'common.evseStatus.' + evseState | translate }}</p>
            </div>

            <div class="col-12 col-lg-6">
                <label class="form-label">{{ 'services.activation.form.tariff' | translate }}</label>
                <p>{{ defaultTariff.tariffConfig['label' + crtLang.toUpperCase()] }}</p>
            </div>

            <div class="col-12 col-lg-6">
                <label class="form-label">{{ 'services.activation.form.price' | translate }}</label>

                <ng-container *ngIf="defaultTariff.customUnitFR">
                    <p class="mb-0" *ngFor="let price of defaultTariff.tariffPrices">
                        <ng-container *ngIf="price.tariffConfigUnit">
                            {{ price.priceByUnit | currencyFormat }} / {{ 'common.units.' + price.tariffConfigUnit.unitEnumName + '.' + price.tariffConfigUnit.unitEnumValue | translate }}

                            <span *ngIf="price.nightRate">({{ 'services.tariff.modal.nightRate' | translate }})</span>
                        </ng-container>

                        <ng-container *ngIf="!price.tariffConfigUnit">
                            {{ price.priceByUnit | currencyFormat }} / {{ defaultTariff['customUnit' + crtLang.toUpperCase()] ? defaultTariff['customUnit' + crtLang.toUpperCase()] : defaultTariff.customUnitFR }}

                            <span *ngIf="price.nightRate">({{ 'services.tariff.modal.nightRate' | translate }})</span>
                        </ng-container>
                    </p>
                </ng-container>

                <ng-container *ngIf="!defaultTariff.customUnitFR">
                    <p class="mb-0" *ngFor="let price of defaultTariff.tariffPrices">
                        {{ price.priceByUnit | currencyFormat }} / {{ 'common.units.' + price.tariffConfigUnit.unitEnumName + '.' + price.tariffConfigUnit.unitEnumValue | translate }}

                        <span *ngIf="price.nightRate">({{ 'services.tariff.modal.nightRate' | translate }})</span>
                    </p>
                </ng-container>
            </div>

            <div *ngIf="!userId">
                <app-user-data-form #userDataFormComponent
                                    [contactForm]="visitorForm"></app-user-data-form>
            </div>

            <div class="col-12" *ngIf="availablePaymentTypes.length > 1">
                <label class="form-label">{{ 'common.terms.paymentType' | translate }}*</label>

                <div>
                    <app-payment-type-chooser #paymentTypeChooserComponent
                                              [availablePaymentTypes]="availablePaymentTypes"
                                              (selectedPaymentType)="selectPaymentType($event)"></app-payment-type-chooser>
                </div>
                <app-form-error [control]="paymentType"></app-form-error>
            </div>

            <app-show-cgv [formGroup]="activationForm">
            </app-show-cgv>

        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>
    <button type="submit"
            class="btn btn-primary"
            (click)="activateService()"
            [disabled]="activationForm.invalid"
            translate="common.activate">
    </button>
</div>





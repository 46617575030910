import {Injectable} from '@angular/core';
import {
    CraningBillingDto,
    CraningBookingSlotDto,
    CraningBookingSlotItemDto,
    CraningBookingSlotState,
    MooringPlaceBoatDto
} from '../../../../_services/configuration-services';
import {DateUtils} from '../../../../_shared/date-utils';
import {RolesService} from '../../../../_shared/roles-service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CraningBookingUtils {

    constructor(
        private readonly rolesService: RolesService,
        private readonly router: Router
    ) {
    }

    public isSlotAvailableToBook(craningBookingSlot: CraningBookingSlotItemDto): boolean {
        if (!craningBookingSlot) {
            return false;
        }

        if (craningBookingSlot.state === CraningBookingSlotState.Free ||
            craningBookingSlot.isMine && craningBookingSlot.state === CraningBookingSlotState.Booked) {
            return this.isSlotDateValidToEdit(craningBookingSlot.date);
        }

        return false;
    }

    public isSlotValidToEdit(craningBookingSlot: CraningBookingSlotDto | null): boolean {
        if (!craningBookingSlot) {
            return false;
        }

        if (craningBookingSlot.state === CraningBookingSlotState.Paid) {
            return false;
        }

        return this.isSlotDateValidToEdit(craningBookingSlot.date);
    }

    public hasAdminView(): boolean {
        return this.rolesService.hasRoleAdmin() || this.rolesService.hasRoleHarbourMaster();
    }

    public printCraningBooking(serviceId: string, craningBookingId: number): void {
        this.printCraningBookings(serviceId, craningBookingId.toString());
    }

    public printCraningBookingsFromDateRange(serviceId: string, from: Date, to: Date): void {
        const fromFormatted = from.toISOString();
        const toFormatted = to.toISOString();

        this.printCraningBookings(serviceId, `${fromFormatted}/${toFormatted}`);
    }

    public calculateCraningBookingDuration(craningBookingSlot: CraningBookingSlotDto): string {
        return DateUtils.ngbTimeStructToString(
            DateUtils.subtractNgbTimeStruct(
                DateUtils.stringToNgbTimeStruct(craningBookingSlot.endTime),
                DateUtils.stringToNgbTimeStruct(craningBookingSlot.startTime)));
    }

    public getBoatLabel(boat: MooringPlaceBoatDto): string {
        if (!boat) {
            return null;
        }
        if (!boat.boatRegistrationNumber) {
            return boat.placeId;
        } else {
            return boat.placeId + ' - ' + boat.boatRegistrationNumber;
        }
    }

    private printCraningBookings(serviceId: string, urlSuffix: string): void {
        const baseUrl = window.location.href.replace(this.router.url, '');
        const url = baseUrl + this.router.serializeUrl(
            this.router.createUrlTree([`services/${serviceId}/craning-booking/print/${urlSuffix}`])
        );

        window.open(url, '_blank');
    }

    private isSlotDateValidToEdit(slotDate: Date): boolean {
        let limitDate;

        if (this.hasAdminView()) {
            limitDate = new Date();

        } else {
            const dayAfterTomorrow = new Date();
            dayAfterTomorrow.setDate(new Date().getDate() + 2);

            limitDate = dayAfterTomorrow;
        }

        const limitDateStruct = DateUtils.dateToNgbDateStruct(limitDate);
        const slotDateStruct = DateUtils.dateToNgbDateStruct(slotDate);

        return DateUtils.afterOrEquals(slotDateStruct, limitDateStruct);
    }
}

export enum CraningBookingFormMode {
    Hidden,
    NewReservation,
    UpdateReservation,
    DisplayReservation = 3,
    BillingReservation
}

export interface CraningBillingEvent {
    craningBillingDto: CraningBillingDto;
    slotId: number;
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
    AlarmClassification,
    AlertComparisonSymbol,
    AlertHistorySearchCriteriaDto,
    AlertService,
    ISortCriteriaDto,
    MeasuringPointType,
    PageableRequestDtoOfAlertHistorySearchCriteriaDto,
    PagedResultDtoOfAlertHistoryLightDto,
    RegisterType,
    SortCriteriaDto,
    SortDirection
} from '../../_services/configuration-services';
import {faCalendar, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {FormUtils} from '../../_shared/form-utils';
import {AppRoles} from '../../app.constants';
import {DateUtils} from '../../_shared/date-utils';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from '../../_shared/custom-date-formatter';
import {SearchIotRegisterComponent} from '../../_shared/_components/search-iot-register/search-iot-register.component';
import {SearchMeasuringPointComponent} from '../../_shared/_components/search-measuring-point/search-measuring-point.component';
import {SearchUserComponent} from '../../_shared/_components/search-user/search-user.component';
import {TranslateUtils} from '../../_shared/translate-utils';

@Component({
    selector: 'app-alert-history',
    templateUrl: './alert-history.component.html',
    providers: [
        {provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class AlertHistoryComponent implements OnInit, OnDestroy {

    @ViewChild('iotRegisterSearch') iotRegisterSearch: SearchIotRegisterComponent;
    @ViewChild('measuringPointSearch') measuringPointSearch: SearchMeasuringPointComponent;
    @ViewChild('userSearch') userSearch: SearchUserComponent;

    AppRoles = AppRoles;
    RegisterType = RegisterType;
    MeasuringPointType = MeasuringPointType;

    // Filters
    filter: {
        formGroup: UntypedFormGroup,
        classificationsDropdownList: string[]
        comparisonSymbolsDropdownList: string[]
    } = {
        formGroup: null,
        classificationsDropdownList: Object.keys(AlarmClassification).filter(c => c !== AlarmClassification.Unknown),
        comparisonSymbolsDropdownList: Object.keys(AlertComparisonSymbol).filter(c => c !== AlertComparisonSymbol.Unknown)
    };

    textInputPattern = FormUtils.textInputPattern;
    dateTimeFormat = DateUtils.dateTimeFormat;

    // Icons
    icons = {
        calendar: faCalendar,
        times: faTimes
    };

    // Pagination
    readonly pageSize = 10;
    crtPage = 0;

    // Table content
    alerts: PagedResultDtoOfAlertHistoryLightDto = new PagedResultDtoOfAlertHistoryLightDto();

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Desc,
        property: 'releasedDateTime'
    });

    private pageRequest: PageableRequestDtoOfAlertHistorySearchCriteriaDto;

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(
        private readonly translateService: TranslateService,
        private readonly alertService: AlertService) {

        this.filter.formGroup = new UntypedFormGroup({
                iotRegister: new UntypedFormControl(null),
                measuringPoint: new UntypedFormControl(null),
                appearedPeriod: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
                releasedPeriod: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
                user: new UntypedFormControl(null),
                threshold: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
                comparisonSymbol: new UntypedFormControl(null),
            },
            {updateOn: 'change'});

        this.filter.formGroup.reset();
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.initializeBasePageRequest();

        this.fetchAlerts(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    fetchAlerts(pageableRequest: PageableRequestDtoOfAlertHistorySearchCriteriaDto): void {
        this.alertService.searchAlertHistory(pageableRequest).pipe().subscribe(alerts => {
            this.alerts = alerts;
        });
    }


    applyFilters(): void {
        if (this.filter.formGroup.invalid) {
            return;
        }

        this.crtPage = 0;
        this.initializeBasePageRequest();

        const appearedPeriod = this.appearedPeriod.value;
        const dateAppearedFrom = DateUtils.ngbDateStructToDate(appearedPeriod.from);
        dateAppearedFrom?.setHours(0, 0, 0, 0);
        const dateAppearedTo = DateUtils.ngbDateStructToDate(appearedPeriod.to);
        dateAppearedTo?.setHours(23, 59, 59, 59);

        const releasedPeriod = this.releasedPeriod.value;
        const dateReleasedFrom = DateUtils.ngbDateStructToDate(releasedPeriod.from);
        dateAppearedFrom?.setHours(0, 0, 0, 0);
        const dateReleasedTo = DateUtils.ngbDateStructToDate(releasedPeriod.to);
        dateReleasedTo?.setHours(23, 59, 59, 59);

        this.pageRequest.criteria = new AlertHistorySearchCriteriaDto({
            iotRegisterId: this.iotRegister.value?.id,
            measuringPointId: this.measuringPoint.value?.id,
            releasedUserId: this.user.value?.id,
            appearedFrom: dateAppearedFrom,
            appearedTo: dateAppearedTo,
            releasedFrom: dateReleasedFrom,
            releasedTo: dateReleasedTo,
            threshold: this.threshold.value,
            comparisonSymbol: this.comparisonSymbol.value,
            sortCriteriaDto: this.currentSort,
            language: this.crtLang,
        });

        this.fetchAlerts(this.pageRequest);
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;

        this.fetchAlerts(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;

        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = new SortCriteriaDto(sortCriteriaDto);

        this.fetchAlerts(this.pageRequest);
    }

    clearFilter(): void {
        // Clear inputs
        this.filter.formGroup.reset();
        this.iotRegisterSearch.clearInput();
        this.measuringPointSearch.clearInput();
        this.userSearch.clearInput();


        // clear search
        this.initializeBasePageRequest();
        this.fetchAlerts(this.pageRequest);
    }

    get iotRegister(): UntypedFormControl {
        return this.filter.formGroup.get('iotRegister') as UntypedFormControl;
    }

    get measuringPoint(): UntypedFormControl {
        return this.filter.formGroup.get('measuringPoint') as UntypedFormControl;
    }

    get appearedPeriod(): UntypedFormGroup {
        return this.filter.formGroup.get('appearedPeriod') as UntypedFormGroup;
    }

    get appearedPeriodFrom(): UntypedFormControl {
        return this.appearedPeriod.get('from') as UntypedFormControl;
    }

    get appearedPeriodTo(): UntypedFormControl {
        return this.appearedPeriod.get('to') as UntypedFormControl;
    }

    get releasedPeriod(): UntypedFormGroup {
        return this.filter.formGroup.get('releasedPeriod') as UntypedFormGroup;
    }

    get releasedPeriodFrom(): UntypedFormControl {
        return this.releasedPeriod.get('from') as UntypedFormControl;
    }

    get releasedPeriodTo(): UntypedFormControl {
        return this.releasedPeriod.get('to') as UntypedFormControl;
    }

    get user(): UntypedFormControl {
        return this.filter.formGroup.get('user') as UntypedFormControl;
    }

    get comparisonSymbol(): UntypedFormControl {
        return this.filter.formGroup.get('comparisonSymbol') as UntypedFormControl;
    }

    get threshold(): UntypedFormControl {
        return this.filter.formGroup.get('threshold') as UntypedFormControl;
    }

    private initializeBasePageRequest(): void {
        this.pageRequest = new PageableRequestDtoOfAlertHistorySearchCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new AlertHistorySearchCriteriaDto({
            measuringPointId: null,
            iotRegisterId: null,
            releasedUserId: null,
            appearedFrom: null,
            appearedTo: null,
            releasedFrom: null,
            releasedTo: null,
            sortCriteriaDto: this.currentSort,
            comparisonSymbol: null,
            threshold: null
        });
    }
}

<h4 translate="services.webcam.title"></h4>

<div class="btn-bar">
    <button class="btn btn-primary" [appHasAnyRole]="[AppRoles.configurator]"
            (click)="createOrEditWebcam()"
            translate="services.webcam.actions.add">
    </button>
    <button class="btn btn-primary" [appHasAnyRole]="[AppRoles.admin]" *ngIf="webcams.length > 1"
            (click)="orderWebcams()"
            translate="common.sort">
    </button>
</div>

<div class="table-responsive">
    <table class="table table-striped"
           [attr.aria-label]="'common.webcams' | translate">
        <thead>
        <tr>
            <th scope="col" style="min-width:175px" translate="services.webcam.table.location"></th>
            <th scope="col" style="min-width:250px" translate="services.webcam.table.imageUrl"></th>
            <th scope="col" style="min-width:275px" translate="services.webcam.table.description"></th>
            <th scope="col" style="min-width:100px"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="webcams.length === 0">
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td></td>
        </tr>

        <tr *ngFor="let webcam of webcams;">
            <td>{{ webcam['location' + crtLang.toUpperCase()] }}</td>

            <td style="max-width:250px;" class="text-truncate">{{ webcam.imageUrl }}</td>

            <td>{{ webcam['description' + crtLang.toUpperCase()] }}</td>

            <td class="text-end">
                <button class="btn btn-link btn-sm text-danger" *ngIf="!webcam.active"
                        (click)="activateWebcam(webcam)"
                        title="{{'common.tooltip.deactivate' | translate}}">
                    <fa-icon [icon]="icons.toggleOff"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm text-success" *ngIf="webcam.active"
                        (click)="deactivateWebcam(webcam)"
                        title="{{'common.tooltip.activate' | translate}}">
                    <fa-icon [icon]="icons.toggleOn"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm"
                        (click)="updateWebcam(webcam)"
                        title="{{'common.tooltip.edit' | translate}}">
                    <fa-icon [icon]="icons.edit"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm" [appHasAnyRole]="[AppRoles.configurator]"
                        (click)="deleteWebcam(webcam)"
                        title="{{'common.tooltip.delete' | translate}}">
                    <fa-icon [icon]="icons.delete"></fa-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="row mt-4" *ngIf="userBoats.length > 0">
    <fieldset class="col-12">
        <legend translate="common.debtorBoatsDetails"></legend>

        <div class="col-sm-6">
            <table class="table table-borderless no-padding"
                   [attr.aria-label]="'common.debtorBoatsDetails' | translate">
                <thead>
                <tr>
                    <th scope="col" translate="mooringPlace.modal.boatRegistrationNumber"></th>
                    <th scope="col" translate="mooringPlace.modal.boatWeight"></th>
                    <th scope="col" translate="services.craning.booking.form.boatDimensions"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let boat of userBoats">
                    <td>{{ boat.boatRegistrationNumber }}</td>
                    <td>{{ boat.boatWeightInKg | numberFormat: Csts.defaultNumeralFormat }} kg</td>
                    <td>{{ boat.boatWidthInMeter | numberFormat: Csts.defaultNumeralFormat }} m
                        x {{ boat.boatLengthInMeter | numberFormat: Csts.defaultNumeralFormat }} m</td>
                </tr>
                </tbody>
            </table>
        </div>
    </fieldset>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {TranslateUtils} from '../_shared/translate-utils';

@Component({
    selector: 'app-cgu',
    templateUrl: './cgu.component.html',
    styleUrls: ['./cgu.component.scss']
})
export class CguComponent implements OnInit, OnDestroy {

    private langChangeSubscription: Subscription;

    crtLang = TranslateUtils.defaultLanguage;

    clientContacts = [];

    cgu = environment.cgu;

    constructor(private readonly translateService: TranslateService) { }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        if(environment.cgu.email){
            this.clientContacts.push(environment.cgu.email);
        }

        if(environment.cgu.phone){
            this.clientContacts.push(environment.cgu.phone);
        }
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchUserDto, UserContactDto, UserService} from '../../../_services/configuration-services';
import {EMPTY, merge, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {faEraser} from '@fortawesome/free-solid-svg-icons';
import {FormUtils} from '../../form-utils';

@Component({
    selector: 'app-search-user',
    templateUrl: './search-user.component.html'
})
export class SearchUserComponent implements OnInit {

    @Input() userContact: UserContactDto | null;
    @Input() disabled = false;

    @Output() selectedUserEvent = new EventEmitter<SearchUserDto>();

    searchSubject$ = new Subject<string>();

    searchText: string | null = null;
    selectedUser: SearchUserDto | null = null;
    printedSelectedUser: string | null = null;

    textInputPattern = FormUtils.textInputPattern;

    searchFailed = false;

    icons = {
        erase: faEraser
    };

    formatter = (user: SearchUserDto | null) => user ? `${user.customerNumber} ${user.lastName} ${user.firstName}` : '';

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged());

        return merge(debouncedText$, this.searchSubject$).pipe(
            switchMap(term => {
                if (term.length < 1) {
                    return of([]);
                }

                if (this.printedSelectedUser === term) {
                    return EMPTY;
                }

                return this.userService.searchUser(term)
                    .pipe(tap(res => {
                        if (res == null || res.length === 0) {
                            this.searchFailed = true;
                            this.selectUser(null);
                            return of([]);
                        }
                        this.searchFailed = false;
                        return of([]);
                    }),
                    catchError(() => {
                        this.searchFailed = true;
                        this.selectUser(null);
                        return of([]);
                    }))
                }
            ));
    }

    constructor(private readonly userService: UserService) {
    }

    ngOnInit(): void {
        this.selectUser(this.userContact);
    }

    selectItem(selectItemEvent: NgbTypeaheadSelectItemEvent<SearchUserDto>): void {
        this.selectUser(selectItemEvent.item);
    }

    public setSelectedUser(userContact: UserContactDto | null): void {
        this.selectUser(userContact);
    }

    public clearInput(): Array<SearchUserDto> {
        this.searchFailed = false;
        this.selectUser(null, true);
        return [];
    }

    private selectUser(user: UserContactDto | null, force = false): void {
        if (user || force) {
            this.selectedUser = user;
            this.userContact = user;
            this.printedSelectedUser = this.formatter(user);
            this.selectedUserEvent.emit(user);

        } else {
            this.selectedUserEvent.emit(null);
        }
    }

}

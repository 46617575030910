import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'urlTextToLink'})
export class UrlTextToLinkPipe implements PipeTransform {
    transform(text: string): string {
        text = UrlTextToLinkPipe.transformUrlsToLink(text);
        text = UrlTextToLinkPipe.transformEmailsToLink(text);
        return text;
    }

    private static transformUrlsToLink(text: string): string {
        const regex = /(?:(?:https?|ftp|file):\/\/)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;
        const urlDetected = text.match(regex);

        if (urlDetected !== null) {
            for (let item of urlDetected) {
                text = text.replace(item, '<a href="' + item + '" target="_blank">' + item + '</a>')
            }
        }

        return text;
    }

    private static transformEmailsToLink(text: string) {
        const regex = /(?!<a[^>]*>[^<])(([a-zA-Z\d._%+-])+@[a-zA-Z\d._%+-]+?(\.[a-zA-Z\d]{2,})+)(?![^<]*<\/a>)/gim;
        const emailDetected = text.match(regex);

        if (emailDetected !== null) {
            for (let item of emailDetected) {
                text = text.replace(item, '<a href="mailto:' + item + '" target="_blank">' + item + '</a>')
            }
        }

        return text;
    }
}

<div class="modal-header">
    <h4 class="modal-title" translate="{{ userAlarmSubscription ? 'services.userAlarm.modal.title.edit' : 'services.userAlarm.modal.title.add' }}"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="alarmForm">

        <div class="row">
            <div class="col-12">
                <label class="form-label" for="iotRegister">{{ 'services.userAlarm.modal.form.iotRegister' | translate }}*</label>
                <select class="form-control form-select" id="iotRegister" name="iotRegister"
                        formControlName="iotRegister">
                    <option *ngFor="let iotRegister of availableIotRegisters;"
                            [ngValue]="iotRegister">{{ getIotRegisterLabel(iotRegister) }}</option>
                </select>
            </div>

            <div class="col-12">
                <label class="form-label" for="designation">{{ 'services.userAlarm.modal.form.designation' | translate }}*</label>
                <input class="form-control" id="designation" type="text" name="designation"
                       formControlName="designation" pattern="{{textInputPattern}}" maxlength="50" />
            </div>

            <div class="col-12">
                <label class="form-label">{{ 'services.userAlarm.modal.form.active' | translate }}</label>

                <div class="form-check form-switch">
                    <input class="form-check-input" id="active" type="checkbox" name="active"
                           formControlName="active" />
                    <label class="form-check-label" for="active">
                        {{ (active.value ? 'common.state.active' : 'common.state.inactive') | translate }}
                    </label>
                </div>
            </div>

            <div class="col-12">
                <label class="form-label">{{ 'services.userAlarm.modal.form.transmissionMode' | translate }}</label>

                <div class="form-check form-switch">
                    <input class="form-check-input" id="checkbox-mail" type="checkbox" name="checkbox-mail"
                           formArrayName="transmissionMode"
                           [formControl]="transmissionMode.get('0')"
                           [value]="transmissionMode.value[0]"/>
                    <label class="form-check-label" for="checkbox-mail" translate="services.userAlarm.modal.form.transmissionModes.mail"></label>
                </div>
                <div class="form-check form-switch">
                    <input class="form-check-input" id="checkbox-push" type="checkbox" name="checkbox-push"
                           formArrayName="transmissionMode"
                           [formControl]="transmissionMode.get('1')"
                           [value]="transmissionMode.value[1]"/>
                    <label class="form-check-label" for="checkbox-push" translate="services.userAlarm.modal.form.transmissionModes.push"></label>
                </div>
            </div>

            <!-- Payment type -->
            <div class="col-12" *ngIf="availablePaymentTypes.length > 1 && !userAlarmSubscription">
                <label class="form-label">{{ 'common.terms.paymentType' | translate }}*</label>

                <div>
                    <app-payment-type-chooser
                        [availablePaymentTypes]="availablePaymentTypes"
                        (selectedPaymentType)="selectPaymentType($event)"></app-payment-type-chooser>
                </div>
            </div>

            <app-show-cgv *ngIf="!userAlarmSubscription"
                          [formGroup]="alarmForm"></app-show-cgv>

        </div>

    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>
    <button class="btn btn-primary" type="submit"
            (click)="userAlarmSubscription ? editSubscription() : createSubscription()"
            [disabled]="alarmForm.invalid || alarmForm.pristine"
            translate="{{ userAlarmSubscription ? 'common.modify' : 'common.subscribe' }}">
    </button>
</div>

<div *ngIf="displayDropDown">
    <select class="form-control form-select" name="serviceType"
            [(ngModel)]="selectedMeasuringPoint"
            (ngModelChange)="userMeasuringPointChange($event)">
        <option [ngValue]="null" *ngIf="allowDropdownEmptySelection">-</option>
        <option *ngFor="let userMeasuringPoint of userMeasuringPoints;"
                [ngValue]="userMeasuringPoint">{{ userMeasuringPoint['name' + this.crtLang.toUpperCase()] }}</option>
    </select>
</div>

<div class="input-group" *ngIf="!displayDropDown">
    <input type="text" class="form-control" pattern="{{textInputPattern}}"
           [class.is-invalid]="searchFailed"
           [ngbTypeahead]="search"
           [inputFormatter]="formatter"
           [resultFormatter]="formatter"
           [resultTemplate]="rtMeasurePoint"
           [disabled]="disabled"
           [(ngModel)]="selectedMeasuringPoint"
           (selectItem)="selectItem($event)"
           (focus)="searchSubject$.next($event.target['value'])"
           (keyup.enter)="searchSubject$.next($event.target.value)"
           placeholder="{{ 'measuringPoints.findMeasurePoint.placeholder' | translate }}"/>

    <span class="input-group-text">
        <button class="btn btn-link btn-sm" type="button" *ngIf="!disabled && defaultMeasuringPoint"
                title="{{'common.tooltip.undo' | translate}}"
                (click)="selectBackDefaultMeasuringPoint()">
            <fa-icon [icon]="icons.undo"></fa-icon>
        </button>

        <button class="btn btn-link btn-sm" type="button" *ngIf="!disabled && (selectedMeasuringPoint || searchText)"
                title="{{ 'common.tooltip.erase' | translate }}"
                (click)="clearInput()">
            <fa-icon [icon]="icons.erase"></fa-icon>
        </button>
    </span>

    <div class="invalid-feedback" *ngIf="searchFailed" translate="measuringPoints.findMeasurePoint.searchFailed"></div>

    <ng-template #rtMeasurePoint let-measuringPoint="result" let-t="term">
        <ngb-highlight [result]="formatter(measuringPoint)" [term]="t"></ngb-highlight>
    </ng-template>
</div>

<div class="content-container bg-white" [appHasAnyRole]="[AppRoles.admin, AppRoles.alarmManager]">

    <div class="btn-bar">
        <button class="btn btn-primary" [appHasAnyRole]="[AppRoles.admin]"
                (click)="createNewDevice()"
                translate="alerts.device.button.add">
        </button>
    </div>


    <!-- Filters -->
    <form [formGroup]="filter.formGroup"
          (keyup.enter)="applyFilters()">

        <div class="row">

            <!-- Classification -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="alerts.device.filter.label.classification"></label>
                <select class="form-control form-select" id="classification" name="classification"
                        formControlName="classification">
                    <option [ngValue]="null">-</option>
                    <option *ngFor="let classification of filter.classificationsDropdownList"
                            [ngValue]="classification">{{'common.alarmClassification.' + classification | translate}}</option>
                </select>
            </div>

            <!-- IotRegister -->
            <div class="default-filter-form-field">
                <label class="form-label" for="iotRegister" translate="alerts.device.filter.label.iotRegister"></label>
                <app-search-iot-register id="iotRegister" #iotRegisterSearch
                                         (selectedRegisterEvent)="iotRegister.setValue($event)"
                                         [registerTypes]="[RegisterType.HistoricUsage, RegisterType.CurrentUsage]"
                                         [measuringPointType]="MeasuringPointType.Admin"
                                         [disabled]="false"></app-search-iot-register>
            </div>

            <!-- Measuring Point -->
            <div class="default-filter-form-field">
                <label class="form-label" for="measuringPoint" translate="alerts.device.filter.label.equipment"></label>
                <app-search-measuring-point id="measuringPoint" #measuringPointSearch
                                            (selectedMeasuringPointEvent)="measuringPoint.setValue($event)"
                                            [measuringPointTypes]="[MeasuringPointType.Admin]"></app-search-measuring-point>
            </div>

            <!-- Location -->
            <div class="default-filter-form-field">
                <label class="form-label" for="location" translate="alerts.device.filter.label.location"></label>
                <input class="form-control" id="location" type="text" name="location"
                       formControlName="location"
                       placeholder="{{'alerts.management.filter.label.location' | translate}}"
                       maxlength="50" pattern="{{textInputPattern}}"/>
            </div>

            <!-- State -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="alerts.device.filter.label.state"></label>
                <select class="form-control form-select" id="state" name="state"
                        formControlName="state">
                    <option [ngValue]="null">-</option>
                    <option *ngFor="let state of filter.statesDropdownList"
                            [ngValue]="state.value">{{'common.state.' + state.key | translate}}</option>
                </select>
            </div>

            <!-- Comparison symbol -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="alerts.device.filter.label.comparisonSymbol"></label>
                <select class="form-control form-select" id="comparisonSymbol" name="comparisonSymbol"
                        formControlName="comparisonSymbol">
                    <option [ngValue]="null">-</option>
                    <option *ngFor="let cs of filter.comparisonSymbolsDropdownList"
                            [ngValue]="cs">{{'common.comparisonSymbol.' + cs | translate}}</option>
                </select>
            </div>

            <!-- Threshold -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="alerts.device.filter.label.threshold"></label>
                <input class="form-control" id="threshold" type="number" name="threshold"
                       placeholder="{{'alerts.management.filter.label.threshold' | translate}}"
                       formControlName="threshold"/>
            </div>

        </div>

        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   (click)="clearFilter()">

            <input class="btn btn-primary" type="button"
                   [value]="'common.filter' | translate"
                   (click)="applyFilters()"
                   [disabled]="filter.formGroup.invalid">
        </div>
    </form>


    <!-- Table -->
    <ngx-datatable #devicesTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="devices.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   rowHeight="50"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="devices.rowCount"
                   [sorts]="[{prop: 'classification', dir: 'asc'}]"
                   [messages]="{
                       totalMessage: 'common.datatable.total' | translate
                   }"
                   [offset]="crtPage"
                   [limit]="devices.pageSize"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [scrollbarH]="true">

        <ngx-datatable-column [width]="100" prop="classification" sortable="true"
                              name="{{'alerts.device.table.classification' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ 'common.alarmClassification.' + row.classification | translate }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="iotRegister.label{{crtLang.toUpperCase()}}" sortable="true"
                              name="{{'alerts.device.table.designation' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="iotRegister.measuringPoint.name{{crtLang.toUpperCase()}}" sortable="true"
                              name="{{'alerts.device.table.equipment' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="location" sortable="true"
                              name="{{'alerts.device.table.location' | translate}}">
        </ngx-datatable-column>


        <ngx-datatable-column [width]="150" prop="comparisonSymbol" sortable="true"
                              name="{{'alerts.device.table.comparisonSymbol' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span>{{'common.comparisonSymbol.' + row.comparisonSymbol | translate}} {{row.threshold}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="50" prop="active" sortable="true"
                              name="{{'alerts.device.table.state' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">

                <button *ngIf="row.active" class="btn btn-link btn-sm text-success"
                        title="{{'common.tooltip.deactivate' | translate}}"
                        (click)="deactivateDevice(row.id)" [disabled]="!rolesService.hasRoleAdmin()">
                    <fa-icon [icon]="icons.toggleOn"></fa-icon>
                </button>
                <button *ngIf="!row.active" class="btn btn-link btn-sm text-danger"
                        title="{{'common.tooltip.activate' | translate}}"
                        (click)="activateDevice(row.id)" [disabled]="!rolesService.hasRoleAdmin()">
                    <fa-icon [icon]="icons.toggleOff"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="75" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <button class="btn btn-link btn-sm" [appHasAnyRole]="[AppRoles.admin]"
                        (click)="editDevice(row)"
                        title="{{'common.tooltip.edit' | translate}}">
                    <fa-icon [icon]="icons.edit"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm" [appHasAnyRole]="[AppRoles.admin]"
                        (click)="deleteDevice(row)"
                        title="{{'common.tooltip.delete' | translate}}">
                    <fa-icon [icon]="icons.delete"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div class="page-count">
                    {{ rowCount }} {{ 'common.datatable.total' | translate }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="devicesTable.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

</div>

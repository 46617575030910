import {Component, Input, OnInit} from '@angular/core';
import {
    CraningServiceDto,
    CraningServiceInputDto,
    CraningServiceService
} from '../../../../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../../../../../_shared/notifications.service';
import {FormUtils} from '../../../../../../_shared/form-utils';
import {CustomerConfigService} from '../../../../../../_shared/customer-config-service';

@Component({
    selector: 'app-craning-service-management-modal',
    templateUrl: './craning-service-management-modal.component.html'
})
export class CraningServiceManagementModalComponent implements OnInit {

    @Input() serviceId: string;
    @Input() craningService: CraningServiceDto | null;

    craningServiceForm: UntypedFormGroup;

    textInputPattern = FormUtils.textInputPattern;

    constructor(public activeModal: NgbActiveModal,
                private readonly craningServiceService: CraningServiceService,
                private readonly notificationsService: NotificationsService,
                public readonly customerConfigService: CustomerConfigService) {

        this.craningServiceForm = new UntypedFormGroup({
            labelFR: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
            labelDE: new UntypedFormControl(null, Validators.maxLength(255)),
            labelEN: new UntypedFormControl(null, Validators.maxLength(255)),
            labelIT: new UntypedFormControl(null, Validators.maxLength(255))
        }, {updateOn: 'change'});
    }

    ngOnInit(): void {
        if (!!this.craningService) {
            this.labelFR.setValue(this.craningService.labelFR);
            this.labelDE.setValue(this.craningService.labelDE);
            this.labelEN.setValue(this.craningService.labelEN);
            this.labelIT.setValue(this.craningService.labelIT);
        }
    }

    addOrUpdateCraningService(): void {
        if (!this.craningServiceForm.valid) {
            return;
        }
        if (!this.craningService) {
            this.craningServiceService.create(this.serviceId, this.getFormResult()).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.craning.notifications.createServiceSuccess'});
                this.activeModal.close('success');
            });
        } else {
            this.craningServiceService.update(this.craningService.id, this.getFormResult()).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.craning.notifications.updateServiceSuccess'});
                this.activeModal.close('success');
            });
        }
    }

    get labelFR(): UntypedFormControl {
        return this.craningServiceForm.get('labelFR') as UntypedFormControl;
    }

    get labelDE(): UntypedFormControl {
        return this.craningServiceForm.get('labelDE') as UntypedFormControl;
    }

    get labelEN(): UntypedFormControl {
        return this.craningServiceForm.get('labelEN') as UntypedFormControl;
    }

    get labelIT(): UntypedFormControl {
        return this.craningServiceForm.get('labelIT') as UntypedFormControl;
    }

    private getFormResult(): CraningServiceInputDto {
        return new CraningServiceInputDto({
            labelFR: this.labelFR.value,
            labelDE: this.labelDE.value,
            labelEN: this.labelEN.value,
            labelIT: this.labelIT.value
        });
    }
}

import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, UntypedFormArray, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {DateUtils} from './date-utils';

@Injectable({
    providedIn: 'root'
})
export class FormUtils {

    public static readonly textInputPattern = '[^<>]+';

    public static readonly phoneNumberPatternValidator = Validators.pattern(/^\++[0-9 ]{1,4}[0-9 ]{1,4}[0-9 ]{1,4}[0-9 ]{1,4}[0-9 ]{0,4}[0-9 ]{0,4}$/);

    public static readonly numberDecimalPatternValidator = Validators.pattern('\\d+(\\.\\d{0,2})?');
    public static readonly numberDecimalAllowingNegativePatternValidator = Validators.pattern('-?\\d+(\\.\\d{0,2})?');
    public static readonly allNumberDecimalExceptZeroPatternValidator = Validators.pattern('^-?(?!0+\\.?0*$)\\d+(\\.\\d{0,2})?');

    public static readonly coordinatesPatternValidator = Validators.pattern(/^\d{1,7}(\.\d{1,4})?$/);

    public static atLeastOneSelectedValidator(array: UntypedFormArray): ValidationErrors | null {

        for (const control of array.controls) {
            if (Validators.requiredTrue(control) === null) {
                return null;
            }
        }

        return {noneSelected: true};
    }

    public static atLeastOneFilledValidator(array: UntypedFormArray): ValidationErrors | null {

        for (const control of array.controls) {
            if (Validators.required(control) === null) {
                return null;
            }
        }

        return {noneFilled: true};
    }

    public static allRequiredValidator(array: UntypedFormArray): ValidationErrors | null {

        for (const control of array.controls) {
            if (Validators.required(control) !== null) {
                return {allRequired: true};
            }
        }

        return null;
    }

    public static datePatternValidator(control: AbstractControl): ValidationErrors | null {
        if (!control.value){
            return null;
        }

        if (!DateUtils.isDateValid(control.value)) {
            return {
                ngbDate: true
            };
        }

        return null;
    }

    public static periodValidator(group: AbstractControl): ValidationErrors | null {
        const fromCtrl = group.value.from;
        const toCtrl = group.value.to;

        if (!fromCtrl || !toCtrl) {
            return null;
        }

        return DateUtils.after(fromCtrl, toCtrl) ? {invalidDatePeriod: true} : null;
    }

    public static dateTimeAfterNowValidator(group: AbstractControl): ValidationErrors | null {
        const dateCtrl = group.value.date;
        const timeCtrl = group.value.time;

        if (!dateCtrl || !timeCtrl) {
            return null;
        }

        const dateTime = DateUtils.ngbDateStructToDateTime(dateCtrl, timeCtrl);

        return dateTime <= new Date() ? { dateInPast: true } : null;
    }

    public static dateTimePeriodValidator(group: AbstractControl): ValidationErrors | null {
        const startDateCtrl = group.value.startDate;
        const endDateCtrl = group.value.endDate;
        const startTimeCtrl = group.value.startTime;
        const endTimeCtrl = group.value.endTime;

        if (!startDateCtrl || !endDateCtrl || !startTimeCtrl || !endTimeCtrl) {
            return null;
        }

        const startDateTime = DateUtils.ngbDateStructToDate(startDateCtrl);
        startDateTime.setHours(startTimeCtrl.hour, startTimeCtrl.minute, 0, 0);

        const endDateTime = DateUtils.ngbDateStructToDate(endDateCtrl);
        endDateTime.setHours(endTimeCtrl.hour, endTimeCtrl.minute, 0, 0);

        return startDateTime > endDateTime ? { invalidDatePeriod: true } : null;
    }

    public static dateTimeMaxIntervalValidator(maxIntervalInHour: number,
                                               startDateControlName: string = 'startDate',
                                               endDateControlName: string = 'endDate',
                                               startTimeControlName: string = 'startTime',
                                               endTimeControlName: string = 'endTime'): ValidatorFn | null {
        return (group: AbstractControl): ValidationErrors | null => {
            const startDateCtrl = group.get(startDateControlName);
            const endDateCtrl = group.get(endDateControlName);
            const startTimeCtrl = group.get(startTimeControlName);
            const endTimeCtrl = group.get(endTimeControlName);

            if (!startDateCtrl?.value || !endDateCtrl?.value || !startTimeCtrl?.value || !endTimeCtrl?.value) {
                return null;
            }

            const startDateTime: Date = DateUtils.ngbDateStructToDate(startDateCtrl.value);
            startDateTime.setHours(startTimeCtrl.value.hour, startTimeCtrl.value.minute, 0, 0);

            const endDateTime: Date = DateUtils.ngbDateStructToDate(endDateCtrl.value);
            endDateTime.setHours(endTimeCtrl.value.hour, endTimeCtrl.value.minute, 0, 0);

            const difference = endDateTime.getTime() - startDateTime.getTime();
            const hourDifference = difference / 1000 / 3600;

            return hourDifference > maxIntervalInHour ? { invalidInterval: { max: maxIntervalInHour }} : null;
        };
    }

    public static timePeriodValidator(group: AbstractControl): ValidationErrors | null {
        const fromCtrl = group.value.from;
        const toCtrl = group.value.to;

        if (!fromCtrl || !toCtrl) {
            return null;
        }

        const startDateTime = new Date();
        startDateTime.setHours(fromCtrl.hour, fromCtrl.minute, 0, 0);

        const endDateTime = new Date();
        endDateTime.setHours(toCtrl.hour, toCtrl.minute, 0, 0);

        return startDateTime > endDateTime ? { invalidTimePeriod: true } : null;
    }

    public static timePeriodMaxIntervalValidator(maxInterval: number, fromControlName: string = 'from', toControlName: string = 'to'): ValidatorFn | null {
        return (group: AbstractControl): ValidationErrors | null => {
            const fromCtrl = group.get(fromControlName);
            const toCtrl = group.get(toControlName);

            if (!fromCtrl || !toCtrl || !fromCtrl.value || !toCtrl.value) {
                return null;
            }

            const startDateTime = new Date();
            startDateTime.setHours(fromCtrl.value.hour, fromCtrl.value.minute, 0, 0);

            const endDateTime = new Date();
            endDateTime.setHours(toCtrl.value.hour, toCtrl.value.minute, 0, 0);


            const dateTimeInterval = (endDateTime.getHours() * 60 + endDateTime.getMinutes())
                - (startDateTime.getHours() * 60 + startDateTime.getMinutes());

            if (dateTimeInterval > 0) {
                return dateTimeInterval > maxInterval * 60 ? { invalidInterval: { max: maxInterval }} : null;
            } else {
                return dateTimeInterval > -(maxInterval * 60)  ? { invalidInterval: { max: maxInterval }} : null;
            }
        };
    }

    public static timeDurationInsideSameDayValidator(group: AbstractControl): ValidationErrors | null {
        const fromCtrl = group.value.from;
        const durationCtrl = group.value.duration;

        if (!fromCtrl || !durationCtrl) {
            return null;
        }

        const startDateTime = new Date();
        startDateTime.setHours(fromCtrl.hour, fromCtrl.minute, 0, 0);

        const durationDateTime = new Date();
        durationDateTime.setHours(durationCtrl.hour, durationCtrl.minute, 0, 0);

        const endDateTime = new Date();
        endDateTime.setTime(startDateTime.getTime() + (durationCtrl.hour * 60 * 60 * 1000) + (durationCtrl.minute * 60 * 1000));

        return startDateTime > endDateTime ||
        endDateTime.getDate() !== startDateTime.getDate()
            ? { invalidTimeDuration : true}
            : null;
    }

    public static requiredOneTrueValidator(array: UntypedFormArray): ValidationErrors | null {
        let hasOneTrueValue = false;
        array.controls.forEach(control => {
            if (control.value === true) {
                hasOneTrueValue = true;
            }
        });

        if (!hasOneTrueValue) {
            return {
                requiredOneTrueValue: true
            };
        }
        return null;
    }

    public static requiredOneSelectedValidator(control: AbstractControl): ValidationErrors | null {
        if (control.value.length <= 0) {
            return {
                requiredOneSelectedValidator: true
            };
        }
        return null;
    }

    public static emailValidator(control: AbstractControl): ValidationErrors | null {
        const emailValidator = Validators.pattern(/^[a-z0-9._%+-]+@([a-z0-9-]+[.]?)+\.[a-z]{2,}$/i);

        const result = emailValidator(control);
        if (result !== null) {
            return {
                invalidEmailAddress: true
            };
        }
        return null;
    }

    public static urlValidator(control: AbstractControl): ValidationErrors | null {
        const validator = Validators.pattern(/^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#$!:.?+=&%@\-/]*)?$/);

        const result = validator(control);
        if (result !== null) {
            return {
                invalidUrl: true
            };
        }
        return null;
    }

    public static integerPatternValidator(control: AbstractControl): ValidationErrors | null {
        if (!control.value){
            return null;
        }

        const regExp = /^\d+$/;
        if (!regExp.test(control.value)) {
            return {
                invalidInteger: true
            };
        }

        return null;
    }

    public static rangeValidator(group: AbstractControl): ValidationErrors | null {
        const minCtrl = group.value.min;
        const maxCtrl = group.value.max;

        if ((!minCtrl && minCtrl !== 0) || (!maxCtrl && maxCtrl !== 0)) {
            return null;
        }

        return minCtrl > maxCtrl ? {invalidRange: true} : null;
    }

    public static validateInputNumberLength(event, maxLength): void {
        switch (event.keyCode || event.charCode){
            case 8: // backspace
            case 46: // delete
                return;
            default:
                break;
        }

        if (event.target.value.length >= maxLength) {
            event.preventDefault();
        }
    }

    public static allFilledOrNoneValidator(group: FormGroup): ValidationErrors | null {
        const filledCount = Object.keys(group.controls)
            .map(key => group.get(key).value)
            .filter(value => value !== null && value !== '')
            .length;

        // If all fields are empty or all filled, the form is valid
        return filledCount === 0 || filledCount === Object.keys(group.controls).length ? null : { 'allOrNone': true };
    };
}

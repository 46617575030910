import {Component, OnInit} from '@angular/core';
import {
    EwalletClosureDocumentDto,
    EwalletClosureDocumentService,
} from '../../../_services/configuration-services';
import {firstValueFrom} from 'rxjs';
import {DateUtils} from '../../../_shared/date-utils';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-ewallet-closure-documents',
  templateUrl: './ewallet-closure-documents.component.html'
})
export class EwalletClosureDocumentsComponent implements OnInit {

    closureDocuments: EwalletClosureDocumentDto[] = [];

    DateUtils = DateUtils;
    icons = {
        download: faDownload
    }

    constructor(private ewalletClosureDocumentService: EwalletClosureDocumentService) {
    }

    async ngOnInit(): Promise<void> {
        this.closureDocuments = await firstValueFrom(this.ewalletClosureDocumentService.getEwalletClosureDocuments());
    }

    async downloadClosureDocument(document: EwalletClosureDocumentDto): Promise<void> {
        const file = await firstValueFrom(this.ewalletClosureDocumentService.downloadEwalletClosureDocument(document.id));
        fileSaver.saveAs(file.data, file.fileName);
    }

}

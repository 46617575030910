import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {CalendarOptions, EventClickArg, EventContentArg, EventDropArg, EventInput, ViewApi} from '@fullcalendar/core';
import {
    BookingLightDto,
    BookingService,
    BookingServiceConfigDto,
    ItemToBookDto,
    ReservationType
} from '../../../../_services/configuration-services';
import {TranslateUtils} from '../../../../_shared/translate-utils';
import {EMPTY, firstValueFrom, merge, Observable, Subject, Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {RolesService} from '../../../../_shared/roles-service';
import {TranslateService} from '@ngx-translate/core';
import {FormUtils} from '../../../../_shared/form-utils';
import {CalendarUtils} from '../../../../_shared/calendar-utils';
import {DateUtils} from '../../../../_shared/date-utils';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {DateClickArg} from '@fullcalendar/interaction';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {PlatformUtils} from '../../../../_shared/platform-utils';
import {MountArg} from '@fullcalendar/core/internal';
import {BookingUtils} from '../../../../_shared/booking-utils';

@Component({
    selector: 'app-free-booking-calendar',
    templateUrl: './free-booking-calendar.component.html'
})
export class FreeBookingCalendarComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() userId: string | null = null;
    @Input() serviceId: string = null!;
    @Input() bookingServiceConfig: BookingServiceConfigDto = null!;
    @Input() hasAdminRoleForBooking = false;

    _items: ItemToBookDto[] = [];
    get items(): ItemToBookDto[] {
        return this._items;
    }
    @Input()
    set items(items: ItemToBookDto[]) {
        this._items = items;
        this.updateResourcesInCalendar();
    }

    @ViewChild('searchUser') searchUserInput: ElementRef = null!;
    @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent = null!;
    @ViewChild('eventPopover', { static: true }) eventPopoverRef: TemplateRef<any> = null!;
    @ViewChild('itemPopover', { static: true }) itemPopoverRef: TemplateRef<any> = null!;

    @Output() selectDate: EventEmitter<Date> = new EventEmitter();
    @Output() selectItemId: EventEmitter<string> = new EventEmitter();
    @Output() selectBooking: EventEmitter<BookingLightDto> = new EventEmitter();

    ReservationType = ReservationType;
    textInputPattern = FormUtils.textInputPattern;

    allObjectsBookings: BookingLightDto[];

    itemsToDisplayInCalendar = new Array<ItemToBookDto>();

    calendarOptions: CalendarOptions = null!;
    private colorsMap = new Map<string, string>();

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription = null!;

    private searchActive = false;
    private lastViewBeforeSearch: string | null = null;
    private lastDateBeforeSearch = new Date();

    searchSubject$ = new Subject<string>();
    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(300),
            distinctUntilChanged());

        return merge(debouncedText$, this.searchSubject$).pipe(
            switchMap(text => {

                if (text.length < 1) {
                    // The user has cleared the search -> Reset the calendar to the old view and date
                    CalendarUtils.setCalendarViewWithDate(this.fullcalendar, this.lastViewBeforeSearch, this.lastDateBeforeSearch);
                    this.searchActive = false;
                }

                else if (text.length >= 2) {
                    this.bookingService.searchBookingsForService(this.serviceId, text).pipe().subscribe(bookings => {
                        if (!this.searchActive) {
                            // The search has just started, save the current view and date for later use
                            this.lastViewBeforeSearch = CalendarUtils.getCalendarView(this.fullcalendar);
                            this.lastDateBeforeSearch = CalendarUtils.getDate(this.fullcalendar);
                            this.searchActive = true;
                        }
                        CalendarUtils.setCalendarView(this.fullcalendar, 'listYear');

                        this.refreshEventsInCalendar(bookings);
                    });
                }

                return EMPTY;
            }));
    }

    constructor(
        private readonly datePipe: DatePipe,
        private readonly calendarUtils: CalendarUtils,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly rolesService: RolesService,
        private readonly translateService: TranslateService,
        private readonly notificationsService: NotificationsService,
        private readonly bookingService: BookingService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                }
            });

        this.itemsToDisplayInCalendar = this.items;

        this.initCalendar();
    }

    async ngAfterViewInit(): Promise<void> {
        await this.fetchBookings();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    selectItemToDisplayInCalendar(item: ItemToBookDto): void {
        if (item) {
            this.itemsToDisplayInCalendar = this.items.filter(i => i.id === item.id);
            if (this.itemsToDisplayInCalendar.length === 1) {
                this.selectItemId.emit(this.itemsToDisplayInCalendar[0].id);
            }
        }
        else {
            this.itemsToDisplayInCalendar = this.items;
        }

        this.updateResourcesInCalendar();
    }

    async fetchBookings(): Promise<void> {
        const startDate = CalendarUtils.getStartDate(this.fullcalendar);
        const endDate = CalendarUtils.getEndDate(this.fullcalendar);

        if (!startDate || !endDate) {
            return;
        }

        if (this.hasAdminRoleForBooking) {
            this.allObjectsBookings = await firstValueFrom(this.bookingService.getBookingsForService(this.serviceId, startDate, endDate));

        } else if (this.rolesService.hasRoleUser()) {
            this.allObjectsBookings = await firstValueFrom(this.bookingService.getMyBookingsForService(this.serviceId, startDate, endDate));

        } else {
            this.allObjectsBookings = await firstValueFrom(this.bookingService.getBookingsForServiceWithoutUserData(this.serviceId, startDate, endDate));
        }

        this.refreshEventsInCalendar(this.allObjectsBookings);
    }

    private initCalendar(): void {
        let initialView = this.calendarUtils.getFullCalendarMode(this.bookingServiceConfig.defaultCalendarDisplayMode, this.items.length > 1);
        let maxStartDate = null;
        let items = null;

        if (this.bookingServiceConfig.maximumBookingAdvanceTimeInDay && !this.rolesService.hasRoleAdmin()) {
            maxStartDate = (new Date()).setDate(new Date().getDate() + this.bookingServiceConfig.maximumBookingAdvanceTimeInDay + 1);
        }

        if (this.itemsToDisplayInCalendar.length > 1) {
            items = this.createItemsForCalendar();
        }

        this.calendarOptions = this.calendarUtils.getDefaultCalendarOptions(this.fullcalendar,
            initialView, this.crtLang,
            maxStartDate ? this.datePipe.transform(maxStartDate, 'yyyy-MM-dd') : null,
            this.itemsToDisplayInCalendar.length > 1);

        this.calendarOptions.resources = items;

        this.calendarOptions.eventDidMount = this.renderEventTooltip.bind(this);
        this.calendarOptions.resourceLabelDidMount = this.calendarUtils.getRenderTooltipHandlerForResourceLabel(this.itemPopoverRef, this.viewContainerRef);

        this.calendarOptions.dateClick = this.handleCalendarDateClick.bind(this);
        this.calendarOptions.eventClick = this.handleCalendarEventClick.bind(this);

        this.calendarOptions.eventDrop = this.handleEventDrop.bind(this);

        this.calendarOptions.datesSet = this.onDatesChange.bind(this);
    }

    private updateResourcesInCalendar():void {
        if (!this.calendarOptions) {
            return;
        }

        if (this.itemsToDisplayInCalendar.length === 1) {
            this.calendarOptions.resources = [];
            CalendarUtils.changeCalendarViewToCalendarView(this.fullcalendar);

        } else if (this.itemsToDisplayInCalendar.length > 1) {
            this.calendarOptions.resources = this.createItemsForCalendar();
            CalendarUtils.changeCalendarViewToTimelineView(this.fullcalendar);
        }

        this.refreshEventsInCalendar(this.allObjectsBookings);
    }

    private renderEventTooltip(event: MountArg<EventContentArg>) {
        if (CalendarUtils.shouldDisplayEventPopover(this.fullcalendar)) {
            this.calendarUtils.getRenderTooltipHandler(this.eventPopoverRef)(event);
        }
    }

    private handleCalendarDateClick(arg: DateClickArg): void {
        if (DateUtils.isInPastDays(arg.date)) {
            this.notificationsService.warning({ title: 'bookings.notifications.cannotBookingInThePast' });
            return;
        }

        if (arg.resource?.id) {
            this.selectItemId.emit(arg.resource.id);
        }

        this.selectDate.emit(arg.date);
    }

    private handleCalendarEventClick(arg: EventClickArg): void {
        if (!this.userId && !this.hasAdminRoleForBooking) {
            return;
        }

        const targetBooking = arg.event.extendedProps['booking'] as BookingLightDto;

        if (!this.hasAdminRoleForBooking && this.userId != targetBooking.userId) {
            if (PlatformUtils.isIosNavigator()) {
                // Display the popover for iOS device because the hover event doesn't work
                this.calendarUtils.showPopover(arg, true);
            }
            return;
        }

        this.selectBooking.emit(targetBooking);
    }

    private handleEventDrop(arg: EventDropArg): void {
        const event = arg.event;

        if (DateUtils.isInPast(event.start)) {
            this.notificationsService.warning({ title: 'bookings.notifications.cannotBookingInThePast' });

            arg.revert();
            return;
        }

        if (!BookingUtils.canEditOrCancelBooking(arg.event.extendedProps['booking'], this.bookingServiceConfig, this.hasAdminRoleForBooking)) {
            this.notificationsService.warning({ title: 'bookings.notifications.cannotUpdatePastOrLockedBooking' });

            arg.revert();
            return;
        }

        const booking = arg.event.extendedProps['booking'] as BookingLightDto;
        if (booking.recurring) { // Avoid moving a recurring event
            arg.revert();
            return;
        }

        booking.startDate = event.start;
        booking.endDate = event.end;
        booking.localStartTime = `${event.start.getHours()}:${event.start.getMinutes()}:00`;
        booking.localEndTime = `${event.end.getHours()}:${event.end.getMinutes()}:00`;

        this.selectBooking.emit(booking);
    }

    private async onDatesChange(arg: { view: ViewApi, el: HTMLElement }): Promise<void> {
        if (this.searchActive) {
            if (CalendarUtils.getCalendarView(this.fullcalendar) === 'listYear') {
                return; // No need to reload Data during searching

            } else {
                // Reset the search if the user navigate in Calendar to avoid misunderstanding
                this.searchUserInput.nativeElement.value = '';
                this.searchActive = false
            }
        }

        await this.fetchBookings();
    }

    private createItemsForCalendar(): Array<EventInput> {
        const resources = new Array<EventInput>();

        this.itemsToDisplayInCalendar.forEach(it => {
            resources.push({
                id: it.id,
                title: it['name' + this.crtLang.toUpperCase()],
                description: it['description' + this.crtLang.toUpperCase()],
                displayPopover: !!it['description' + this.crtLang.toUpperCase()]
            });
        });

        return resources;
    }

    private refreshEventsInCalendar(bookings: BookingLightDto[]): void {
        let events: EventInput[] = [];

        // Filter bookings for the selected item if necessary
        if (this.itemsToDisplayInCalendar.length === 1) {
            bookings = bookings.filter(b => b.itemToBook.id == this.itemsToDisplayInCalendar[0].id);
        }

        // Define events colors for Items
        const bookingIds = Array.from(new Set(bookings.map(b => b.itemToBook.id)));
        for (let i = 0; i < bookingIds.length; i++) {
            this.colorsMap.set(bookingIds[i], CalendarUtils.colors[i % CalendarUtils.colors.length]);
        }

        bookings.forEach(b => events.push(this.eventForBookingLightDto(b)));

        this.calendarOptions.events = [...events];
    }

    private eventForBookingLightDto(booking: BookingLightDto): EventInput {
        const ownedByCrtUser = !!booking.userId && booking.userId === this.userId;
        const isBlockedRange = booking.reservationType === ReservationType.Blocking;

        const startDate = this.datePipe.transform(booking.startDate, 'yyyy-MM-dd') + 'T' + booking.localStartTime;
        const endDate = this.datePipe.transform(booking.endDate, 'yyyy-MM-dd') + 'T' + booking.localEndTime;

        // Define Event Color
        let eventColor = this.colorsMap.get(booking.itemToBook.id);
        if (this.hasAdminRoleForBooking) {
            eventColor = eventColor.replace('OPACITY', isBlockedRange ? '0.3' : '1');

        } else {
            eventColor = eventColor.replace('OPACITY', (isBlockedRange || !ownedByCrtUser) ? '0.3' : '1');
        }

        // TODO VNSG-2865 Try to improve that !
        if (booking.recurring) {
            return {
                allDay: false,
                booking: booking,
                color: eventColor,
                daysOfWeek: booking.days.map(d => CalendarUtils.getDayNumber(d)),
                durationEditable: false,
                editable: false,
                endRecur: new Date(booking.endDate).setHours(23, 59, 59),
                endTime: booking.localEndTime,
                id: booking.id,
                item: booking.itemToBook["name" + this.crtLang.toUpperCase()],
                itemToBookId: booking.itemToBook.id,
                resourceEditable: this.hasAdminRoleForBooking,
                resourceId: this.itemsToDisplayInCalendar.length > 1 ? booking.itemToBook.id : null,
                startEditable: false,
                startRecur: new Date(booking.startDate),
                startTime: booking.localStartTime,
                textColor: 'black',
                title: this.getBookingDisplayName(booking)
            } as EventInput;

        } else {
            return {
                allDay: DateUtils.isFullDay(new Date(startDate), new Date(endDate)),
                booking: booking,
                color: eventColor,
                durationEditable: false,
                editable: this.hasAdminRoleForBooking || ownedByCrtUser,
                end: endDate,
                id: booking.id,
                item: booking.itemToBook["name" + this.crtLang.toUpperCase()],
                itemToBookId: booking.itemToBook.id,
                resourceEditable: this.hasAdminRoleForBooking,
                resourceId: this.itemsToDisplayInCalendar.length > 1 ? booking.itemToBook.id : null,
                start: startDate,
                textColor: 'black',
                title: this.getBookingDisplayName(booking)
            } as EventInput;
        }
    }

    private getBookingDisplayName(booking: BookingLightDto): string {
        let title = this.translateService.instant('bookings.blocked');

        if (booking.reservationType === ReservationType.Booking) {

            if (this.hasAdminRoleForBooking || (booking.userId && booking.userId === this.userId)) {
                title = booking.visitorContact.firstName + ' ' + booking.visitorContact.lastName;
            }
        }

        if (CalendarUtils.isCalendarListView(this.fullcalendar)) {
            // Add Item name for Planning View
            title += ' - ' + booking.itemToBook["name" + this.crtLang.toUpperCase()];
        }

        return title;
    }
}

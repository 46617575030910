import {BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication} from '@azure/msal-browser';
import {MsalGuardConfiguration, MsalInterceptorConfiguration} from '@azure/msal-angular';
import {environment} from '../environments/environment';

// this checks if the app is running on IE
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel, message): void {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.b2cWebClientClientId,
            authority: 'https://' + environment.b2cDomainName + '.b2clogin.com/' + environment.b2cDomainName
                + '.onmicrosoft.com/' + environment.b2cSignInSignUpPolicy,
            redirectUri: environment.authConfiguration.redirectUri,
            postLogoutRedirectUri: environment.authConfiguration.postLogoutRedirectUri,
            knownAuthorities: [environment.b2cDomainName + '.b2clogin.com']
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Warning,
                piiLoggingEnabled: false
            }
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('./assets/i18n/*.json', null);
    protectedResourceMap.set(environment.configurationServiceBaseUrl + '/api/v1/*/public/*', null);
    protectedResourceMap.set(environment.configurationServiceBaseUrl + '/api/v1/*/public', null);
    protectedResourceMap.set(environment.configurationServiceBaseUrl + '/api/v1/*',
        [
            'https://' + environment.b2cDomainName + '.onmicrosoft.com/' + environment.b2cConfigServiceClientId + '/user_impersonation'
        ]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['https://' + environment.b2cDomainName + '.onmicrosoft.com/' + environment.b2cConfigServiceClientId + '/user_impersonation'],
        }
    };
}

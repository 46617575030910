import {Component} from '@angular/core';
import {AppRoles} from '../app.constants';

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html'
})
export class TransactionsComponent {

    AppRoles = AppRoles;

    constructor() {
    }

}


<!-- Filters -->
<div class="row">

    <div class="col-lg-4">
        <label class="form-label" for="searchMeasuringPoint" translate="services.energy.cockpit.filter.label.measuringPoint"></label>
        <app-search-measuring-point id="searchMeasuringPoint"
                                    [serviceId]="serviceId"
                                    [sort]="adminFirstSort"
                                    (selectedMeasuringPointEvent)="measuringPointSelected($event)"></app-search-measuring-point>
    </div>

    <div class="col-lg-4">
        <label class="form-label" for="dateRange" translate="services.energy.cockpit.filter.label.dateRange"></label>
        <app-date-range-picker id="dateRange"
                               (dateRangeChange)="dateRangeChange($event)"></app-date-range-picker>
    </div>

</div>

<div *ngIf="!measuringPoint" class="text-center">
    <em translate="services.energy.cockpit.message.selectMeasuringPoint"></em>
</div>

<div *ngIf="measuringPoint">
    <h4 class="text-center" translate="services.energy.cockpit.consumption.title"></h4>
    <h4 class="text-center">{{'services.energy.cockpit.value.fromTo'| translate:{
        from: formatDate(dateRange.start), to: formatDate(dateRange.end)
    } }}</h4>

    <h5 class="text-center">{{ measuringPoint['name' + crtLang.toUpperCase()] }} ({{ measuringPoint.hardwareName }})</h5>

    <div *ngIf="cockpitData" class="mt-5">

        <div class="cockpit-data-container">

            <div class="d-flex flex-row justify-content-between gap-3">
                <p class="title-label" translate="services.energy.cockpit.label.totalConsumption"></p>

                <p>{{ 'services.energy.cockpit.value.consumption_kWh' | translate: {
                    value: this.cockpitData.totalEnergyConsumption.toFixed(2)
                } }}</p>
            </div>

            <div class="d-flex flex-row justify-content-between gap-3">
                <p class="title-label" translate="services.energy.cockpit.label.maxDayConsumption"></p>

                <ng-container *ngIf="cockpitData.maximumDailyEnergyConsumption">
                    <p class="text-end">
                        {{ 'services.energy.cockpit.value.consumption_kWh_date' | translate: {
                            value: cockpitData.maximumDailyEnergyConsumption.value.toFixed(2),
                            date: cockpitData.maximumDailyEnergyConsumption.dateTime | date:dateFormat
                        } }}
                    </p>
                </ng-container>
                <ng-container *ngIf="!cockpitData.maximumDailyEnergyConsumption">
                    <p class="text-end">
                        {{ 'services.energy.cockpit.value.consumption_kWh' | translate: {
                            value: '--'
                        } }}
                    </p>
                </ng-container>
            </div>

        </div>

        <div class="mt-3 mb-3">
            <app-consumption-bar-chart [consumptionData]="consumptionData"></app-consumption-bar-chart>
        </div>

    </div>

    <div *ngIf="!cockpitData" class="text-center">
        <p translate="services.energy.cockpit.message.noDataAvailable"></p>
    </div>

</div>

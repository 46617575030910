
<div class="content-container bg-white">

    <h1 translate="communicationChannel.survey.title.manage"></h1>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary" type="button"
                (click)="back()"
                translate="services.actions.back">
        </button>

        <button class="btn btn-outline-secondary" type="button" *ngIf="surveys.length > 1"
                (click)="orderSurveys()"
                translate="common.sort">
        </button>

        <button class="btn btn-primary" type="button"
                [routerLink]="['create']"
                translate="communicationChannel.survey.btn.addSurvey">
        </button>
    </div>

    <ngx-datatable
        class="material"
        [columnMode]="'force'"
        [rows]="surveys"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'100%'"
        [count]="surveys.length"
        [sorts]="[{prop: 'order', dir: 'asc'}]"
        [messages]="{ totalMessage: 'common.datatable.total' | translate }"
        [reorderable]="false"
        [scrollbarH]="true">

        <!-- title -->
        <ngx-datatable-column [width]="130" prop="title{{crtLang.toUpperCase()}}" sortable="true"
                              name="{{'communicationChannel.survey.table.title' | translate}}">
        </ngx-datatable-column>

        <!-- nbTargetUsers -->
        <ngx-datatable-column [width]="120" prop="nbTargetUsers" sortable="true"
                              name="{{'communicationChannel.survey.table.nbTargetUsers' | translate}}">
        </ngx-datatable-column>

        <!-- userReadCount -->
        <ngx-datatable-column [width]="50" prop="userReadCount" sortable="true"
                              name="{{'communicationChannel.survey.table.userReadCount' | translate}}">
        </ngx-datatable-column>

        <!-- userReadPercent -->
        <ngx-datatable-column [width]="80" prop="userReadPercent" sortable="true"
                              name="{{'communicationChannel.survey.table.userReadPercent' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.userReadPercent < 0 ? '-' : ((row.userReadPercent * 100 | number: '1.0-0') + '%')}}
            </ng-template>
        </ngx-datatable-column>

        <!-- surveyState -->
        <ngx-datatable-column [width]="90" prop="surveyState" sortable="true"
                              name="{{'communicationChannel.survey.table.surveyState' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ 'communicationChannel.enums.informationState.' + row.surveyState.toLowerCase() | translate }}
            </ng-template>
        </ngx-datatable-column>

        <!-- resultState -->
        <ngx-datatable-column [width]="80" prop="resultState" sortable="true"
                              name="{{'communicationChannel.survey.table.resultState' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ 'communicationChannel.enums.informationState.' + row.resultState.toLowerCase() | translate }}
            </ng-template>
        </ngx-datatable-column>

        <!-- actions -->
        <ngx-datatable-column [width]="75" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <button class="btn btn-link btn-sm"
                        [routerLink]="[row.id]"
                        title="{{ 'common.tooltip.edit' | translate }}">
                    <fa-icon [icon]="icons.pen"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm"
                        (click)="deleteSurvey(row.id)"
                        title="{{ 'common.tooltip.delete' | translate }}">
                    <fa-icon [icon]="icons.trash"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>

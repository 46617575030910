<div [formGroup]="periodControl">
    <label class="form-label" for="from">{{ inputLabel | translate }}<span *ngIf="required">*</span></label>

    <div class="input-group mb-2">
        <span class="input-group-text" translate="common.period.from"> <span *ngIf="isFromRequired">*</span></span>
        <input class="form-control" id="from" name="from"
               formControlName="from"
               placeholder="{{ 'common.placeholders.dateFormat' | translate}}"
               ngbDatepicker #dpDateFrom="ngbDatepicker"
               [minDate]="fromMinDate"
               [maxDate]="!!to.value ? to.value : fromMaxDate"
               (ngModelChange)="dateFromSelected.emit($event)">

        <span class="input-group-text">
            <button class="btn btn-link btn-sm" (click)="dpDateFrom.toggle()">
                <fa-icon [icon]="icons.calendar"></fa-icon>
            </button>
            <button class="btn btn-link btn-sm" *ngIf="!!from.value"
                    (click)="resetFromDate()">
                <fa-icon [icon]="icons.times"></fa-icon>
            </button>
        </span>
    </div>
    <div class="mb-2">
        <app-form-error [control]="from"></app-form-error>
    </div>

    <div class="input-group mb-2">
        <span class="input-group-text" translate="common.period.to"></span>
        <input class="form-control" id="to" name="to"
               formControlName="to"
               placeholder="{{ 'common.placeholders.dateFormat' | translate}}"
               ngbDatepicker #dpDateTo="ngbDatepicker"
               [minDate]="!!from.value ? from.value : toMinDate"
               [maxDate]="toMaxDate"
               (ngModelChange)="dateToSelected.emit($event)">

        <span class="input-group-text">
            <button class="btn btn-link btn-sm" (click)="dpDateTo.toggle()">
                <fa-icon [icon]="icons.calendar"></fa-icon>
            </button>
            <button class="btn btn-link btn-sm" *ngIf="!!to.value"
                    (click)="resetToDate()">
                <fa-icon [icon]="icons.times"></fa-icon>
            </button>
        </span>
    </div>
    <app-form-error [control]="to"></app-form-error>
    <app-form-error [control]="periodControl"></app-form-error>

</div>


<div class="content-container bg-white" [appHasAnyRole]="[AppRoles.admin]">

    <h1 translate="communicationChannel.information.title.manage"></h1>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary" type="button"
                (click)="back()"
                translate="services.actions.back">
        </button>

        <button class="btn btn-primary" [routerLink]="['create']"
                translate="communicationChannel.information.btn.addInformation">
        </button>
    </div>

    <ngx-datatable
        class="material"
        [columnMode]="'force'"
        [rows]="informations"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'100%'"
        [count]="informations.length"
        [sorts]="[{prop: 'publicationDate', dir: 'desc'}]"
        [messages]="{ totalMessage: 'common.datatable.total' | translate }"
        [reorderable]="false"
        [scrollbarH]="true">

        <!-- title -->
        <ngx-datatable-column [width]="150" prop="title{{crtLang.toUpperCase()}}" sortable="true"
                              name="{{'communicationChannel.information.table.title' | translate}}">
        </ngx-datatable-column>

        <!-- nbTargetUsers -->
        <ngx-datatable-column [width]="150" prop="nbTargetUsers" sortable="true"
                              name="{{'communicationChannel.information.table.nbTargetUser' | translate}}">
        </ngx-datatable-column>

        <!-- nbReads -->
        <ngx-datatable-column [width]="110" prop="nbReads" sortable="true"
                              name="{{'communicationChannel.information.table.nbReads' | translate}}">
        </ngx-datatable-column>

        <!-- userReadPercent -->
        <ngx-datatable-column [width]="110" prop="userReadPercent" sortable="true"
                              name="{{'communicationChannel.information.table.userReadPercent' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.userReadPercent < 0 ? '-' : ((row.userReadPercent * 100 | number: '1.0-0') + '%')}}
            </ng-template>
        </ngx-datatable-column>

        <!-- publicationDate -->
        <ngx-datatable-column [width]="110" prop="publicationDate" sortable="true"
                              name="{{'communicationChannel.information.table.publicationDate' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{row.publicationDate  | date: formats.date }}
            </ng-template>
        </ngx-datatable-column>

        <!-- informationState -->
        <ngx-datatable-column [width]="75" prop="informationState" sortable="true"
                              name="{{'communicationChannel.information.table.informationState' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ 'communicationChannel.enums.informationState.' + row.informationState.toLowerCase() | translate }}
            </ng-template>
        </ngx-datatable-column>

        <!-- action -->
        <ngx-datatable-column [width]="75" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <button class="btn btn-link btn-sm"
                        [routerLink]="[row.id]"
                        title="{{ 'common.tooltip.edit' | translate }}">
                    <fa-icon [icon]="icons.pen"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm"
                        (click)="deleteInformation(row)"
                        title="{{ 'common.tooltip.delete' | translate }}">
                    <fa-icon [icon]="icons.trash"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>

<div class="nav-menu dark">
    <ul class="navbar-nav">
        <li class="nav-item" *ngIf="customerConfigService.isAlarmsEnabled()">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['office-hour-management']"
                   routerLinkActive
                   #businessHourManagementLink="routerLinkActive"
                   [ngClass]='{"selected": businessHourManagementLink.isActive}'>
                    <span translate="menu.navigation.infrastructure.businessHourManagement"></span>
                </a>
            </div>
        </li>
        <li class="nav-item" *ngIf="customerConfigService.isInfrastructureEnabled()">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['maintenance']"
                   routerLinkActive
                   #maintenanceLink="routerLinkActive"
                   [ngClass]='{"selected": maintenanceLink.isActive}'>
                    <span translate="menu.navigation.infrastructure.maintenance"></span>
                </a>
            </div>
        </li>
        <li class="nav-item" *ngIf="customerConfigService.isInfrastructureEnabled()">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['interventions']"
                   routerLinkActive
                   #interventionsLink="routerLinkActive"
                   [ngClass]='{"selected": interventionsLink.isActive}'>
                    <span translate="menu.navigation.infrastructure.interventions"></span>
                </a>
            </div>
        </li>
    </ul>
</div>

<router-outlet></router-outlet>


<div class="modal-header">
  <h4 class="modal-title" translate="users.rfid.addTitle"></h4>

    <button class="btn-close" aria-label="Close"
          (click)="activeModal.dismiss('Cross click')">
  </button>
</div>

<div class="modal-body">
  <form name="itemForm" #rfidForm="ngForm">
      <div class="form-group">
          <input id="typeahead-http" type="text" class="form-control"
                 pattern="{{textInputPattern}}"
                 [class.is-invalid]="searchFailed"
                 [(ngModel)]="rfidCardResult"
                 [ngbTypeahead]="searchRfidCards"
                 [inputFormatter]="formatter"
                 [resultFormatter]="formatter"
                 [resultTemplate]="rtRfidCard"
                 [ngModelOptions]="{standalone: true}"
                 placeholder="{{ 'rfidCards.searchPlaceholder' | translate }}"/>
          <div class="invalid-feedback" *ngIf="searchFailed" translate="users.findUser.searchFailed"></div>
      </div>
    <small class="text-danger font-italic" *ngIf="alreadyAssigned" translate="users.rfid.cardAlreadyAssign"></small>
    <small class="text-danger font-italic" *ngIf="selfAssigned" translate="users.rfid.selfAssigned"></small>
  </form>
</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-outline-secondary"
          (click)="activeModal.dismiss()"
          translate="common.cancel">
  </button>
  <button type="button" class="btn btn-primary"
          [disabled]="!(rfidCardResult && rfidCardResult.cardNumber)"
          (click)="addOrUpdateRfidCard()"
          translate="rfidCards.actions.assign">
  </button>
</div>

<ng-template #rtRfidCard let-rfidCard="result" let-t="term">
    <ngb-highlight [result]="printFormattedCardIdentifier(rfidCard)" [term]="t"></ngb-highlight>
</ng-template>

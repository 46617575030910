import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    Injectable,
    Injector,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import {BookingCalendarMode, BookingSlotState, CraningBookingSlotState, Day} from '../_services/configuration-services';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {
    CalendarEventPopoverComponent
} from './_components/calendar/event-popover/calendar-event-popover.component';
import {
    CalendarOptions,
    DidMountHandler,
    EventContentArg,
    EventHoveringArg,
    WillUnmountHandler
} from '@fullcalendar/core';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {PlatformUtils} from "./platform-utils";
import {MountArg} from '@fullcalendar/core/internal';
import {ResourceLabelContentArg, ResourceLabelMountArg} from '@fullcalendar/resource';
import frLocal from '@fullcalendar/core/locales/fr';
import deLocale from '@fullcalendar/core/locales/de';
import itLocale from '@fullcalendar/core/locales/it';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CalendarUtils {

    public static black = 'black';
    public static white = 'white';

    public static blue = 'rgba(112, 167, 255, 1)';
    public static gray = 'rgba(197, 197, 197, 1)';
    public static green = 'rgba(175, 200, 48, 1)';
    public static yellow = 'rgba(242, 232, 105, 1)';
    public static orange = 'rgba(255, 189, 0, 1)';

    public static readonly colors: Array<string> = [
        'rgba(168, 230, 207, OPACITY)',
        'rgba(118, 180, 189, OPACITY)',
        'rgba(255, 211, 182, OPACITY)',
        'rgba(255, 170, 165, OPACITY)',
        'rgba(255, 139, 149, OPACITY)',
        'rgba(118, 180, 189, OPACITY)'];

    private readonly popoversMap;
    private readonly popoverFactory;

    icons = {
        info: faInfoCircle
    };

    constructor(
        private readonly resolver: ComponentFactoryResolver,
        private readonly injector: Injector,
        private readonly appRef: ApplicationRef
    ) {
        this.popoverFactory = this.resolver.resolveComponentFactory(CalendarEventPopoverComponent);
        this.popoversMap = new Map<any, ComponentRef<CalendarEventPopoverComponent>>();
    }

    public static getDayNumber(day: Day): string {
        switch (day) {
            case Day.Sunday:
                return '0';
            case Day.Monday:
                return '1';
            case Day.Tuesday:
                return '2';
            case Day.Wednesday:
                return '3';
            case Day.Thursday:
                return '4';
            case Day.Friday:
                return '5';
            case Day.Saturday:
                return '6';
        }
    }

    public static isCalendarMonthView(calendar: FullCalendarComponent): boolean {
        const viewType = calendar?.getApi().view.type;
        return viewType === 'dayGridMonth';
    }

    public static isCalendarWeekView(calendar: FullCalendarComponent): boolean {
        const viewType = calendar?.getApi().view.type;
        return viewType === 'timeGridWeek';
    }

    public static isCalendarDayView(calendar: FullCalendarComponent): boolean {
        const viewType = calendar?.getApi().view.type;
        return viewType === 'timeGridDay';
    }

    public static isCalendarListView(calendar: FullCalendarComponent): boolean {
        const viewType = calendar?.getApi().view.type;
        return viewType === 'listWeek' || viewType === 'listYear';
    }

    public static getStartDate(calendar: FullCalendarComponent): Date | null {
        return calendar?.getApi().view.currentStart;
    }

    public static getEndDate(calendar: FullCalendarComponent): Date | null {
        const date = calendar?.getApi().view.currentEnd;
        if (!date) {
            return null;
        }
        // currentEnd is exclusive: remove a day
        date.setDate(date.getDate() - 1);
        return date;
    }

    public static getDate(calendar: FullCalendarComponent): Date | null {
        return calendar?.getApi().getDate();
    }

    public static getCalendarView(calendar: FullCalendarComponent): string {
        return calendar?.getApi().view.type;
    }

    public static setCalendarView(calendar: FullCalendarComponent, view: string): void {
        calendar?.getApi().changeView(view);
    }

    public static setCalendarViewWithDate(calendar: FullCalendarComponent, view: string, date: Date): void {
        calendar?.getApi().changeView(view, date);
    }

    public static changeCalendarViewToTimelineView(calendar: FullCalendarComponent): void {
        calendar?.getApi().changeView(CalendarUtils.getTimelineViewForCorrespondingCalendarView(calendar.getApi().view.type));

        this.updateCalendarModeViewsButtons(calendar, true);
    }

    public static changeCalendarViewToCalendarView(calendar: FullCalendarComponent): void {
        calendar?.getApi().changeView(CalendarUtils.getCalendarViewForCorrespondingTimelineView(calendar.getApi().view.type));

        this.updateCalendarModeViewsButtons(calendar, false);
    }

    public static getTimelineViewForCorrespondingCalendarView(currentView: string): string {
        switch (currentView) {
            case 'dayGridMonth':
                return 'resourceTimelineMonth';
            case 'timeGridWeek':
                return 'resourceTimelineWeek';
            case 'timeGridDay':
                return 'resourceTimelineDay';
            case 'listWeek':
                return 'listWeek';
            default:
                return currentView;
        }
    }

    public static getCalendarViewForCorrespondingTimelineView(currentView: string): string {
        switch (currentView) {
            case 'resourceTimelineMonth':
                return 'dayGridMonth';
            case 'resourceTimelineWeek':
                return 'timeGridWeek';
            case 'resourceTimelineDay':
                return 'timeGridDay';
            case 'listWeek':
                return 'listWeek';
            default:
                return currentView;
        }
    }

    public static goToDate(calendar: FullCalendarComponent, date: Date): void {
        calendar?.getApi().gotoDate(date);
    }

    public static shouldDisplayEventPopover(calendar: FullCalendarComponent): boolean {
        return !CalendarUtils.isCalendarListView(calendar);
    }

    public static getCalendarModeViews(timelineMode: boolean): string {
        if (timelineMode) {
            return 'resourceTimelineMonth,resourceTimelineWeek,resourceTimelineDay,listWeek';
        }
        return 'dayGridMonth,timeGridWeek,timeGridDay,listWeek';
    }

    public static updateCalendarModeViewsButtons(calendar: FullCalendarComponent, timelineMode: boolean): void {
        const calendarModeViews = CalendarUtils.getCalendarModeViews(timelineMode);

        calendar?.getApi().setOption('headerToolbar', {
            left: 'prev,next today',
            center: 'title',
            right: calendarModeViews
        });
    }

    public getDefaultCalendarOptions(calendar: FullCalendarComponent,
                                     initialView: string,
                                     language: string,
                                     maxStartDate: string | null = null,
                                     timelineMode = false) : CalendarOptions {

        const calendarModeViews = CalendarUtils.getCalendarModeViews(timelineMode);

        return {
            allDaySlot: false,
            dayHeaderFormat: {
                weekday: 'short'
            },
            dayMaxEvents: 5,
            editable: true,
            eventTimeFormat: {
                hour: '2-digit',
                minute: '2-digit',
            },
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: calendarModeViews
            },
            initialView,
            locales: [frLocal, deLocale, itLocale],
            locale: language,
            nowIndicator: true,
            plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, resourceTimelinePlugin],
            resourceOrder: 'title',
            resourceAreaWidth: '35%',
            schedulerLicenseKey: environment.fullCalendarLicenceKey,
            selectMirror: true,
            validRange: {
                end: maxStartDate
            },
            eventWillUnmount: this.getDestroyTooltipHandler(calendar),
            eventMouseEnter: this.getShowPopoverHandler(calendar),
            eventMouseLeave: this.getHidePopoverHandler(calendar),
            resourceLabelWillUnmount: this.getDestroyTooltipHandlerForResourceLabel()
        };

    }

    // Tooltips
    public getRenderTooltipHandler(popoverTmpl: TemplateRef<any>): DidMountHandler<MountArg<EventContentArg>> {
        const self = this;

        return event => {
            const projectableNodes = Array.from(event.el.childNodes);
            const compRef = self.popoverFactory.create(self.injector, [projectableNodes], event.el);
            compRef.instance.template = popoverTmpl;
            self.appRef.attachView(compRef.hostView);
            self.popoversMap.set(event.el, compRef);
        };
    }

    public getDestroyTooltipHandler(calendar: FullCalendarComponent): WillUnmountHandler<MountArg<EventContentArg>> | null {
        if (CalendarUtils.shouldDisplayEventPopover(calendar)) {
            const self = this;

            return event => {
                const popover = self.popoversMap.get(event.el);
                if (popover) {
                    self.appRef.detachView(popover.hostView);
                    popover.destroy();
                    self.popoversMap.delete(event.el);
                }
            };
        }

        return null;
    }

    public getShowPopoverHandler(calendar: FullCalendarComponent): (event: EventHoveringArg) => void {
        if (CalendarUtils.shouldDisplayEventPopover(calendar)) {
            return (event) => {
                this.showPopover(event, false);
            };
        }

        return null;
    }

    public showPopover(event: EventHoveringArg, force: boolean): void {
        if (!force && PlatformUtils.isIosNavigator()) {
            // Ignore event if it is an iOS device because the popover does not work in iOS !
            return;
        }

        const popover = this.popoversMap.get(event.el);
        if (popover) {
            popover.instance.popover.open({event: event.event, openDelay: 1000});
        }
    }

    public getHidePopoverHandler(calendar: FullCalendarComponent): (arg: EventHoveringArg) => void {
        if (CalendarUtils.shouldDisplayEventPopover(calendar)) {
            const self = this;

            return event => {
                if (PlatformUtils.isIosNavigator()) {
                    // Ignore event if it is an iOS device because the popover does not work in iOS !
                    return;
                }

                const popover = self.popoversMap.get(event.el);
                if (popover) {
                    popover.instance.popover.close();
                }
            };
        }

        return null;
    }

    public getRenderTooltipHandlerForResourceLabel(popoverTmpl: TemplateRef<any>, viewContainerRef: ViewContainerRef): DidMountHandler<MountArg<ResourceLabelContentArg>> {
        const self = this;

        return info => {
            if (!info.resource.extendedProps['displayPopover']) {
                return;
            }
            setTimeout(() => {

                const icon = viewContainerRef.createComponent<FaIconComponent>(FaIconComponent);
                // @ts-ignore
                icon.instance.icon = self.icons.info;
                icon.instance.render();

                const selector = info.el.querySelector('.fc-datagrid-cell-main');
                selector.appendChild(icon.location.nativeElement);

                const projectableNodes = Array.from(info.el.childNodes);
                const compRef = self.popoverFactory.create(self.injector, [projectableNodes], info.el);
                compRef.instance.template = popoverTmpl;
                compRef.instance.popover.placement = ['right', 'top-right', 'bottom-right'];
                compRef.instance.popover._elementRef = icon.location;
                self.appRef.attachView(compRef.hostView);
                self.popoversMap.set(info.el, compRef);


                if (PlatformUtils.isIosNavigator()) {
                    icon.location.nativeElement.onclick = () => {
                        const popover = self.popoversMap.get(info.el);
                        if (popover) {
                            popover.instance.popover.open({resource: info.resource, openDelay: 1000});
                        }
                    };
                }
                else
                {
                    icon.location.nativeElement.onmouseenter = () => {
                        const popover = self.popoversMap.get(info.el);
                        if (popover) {
                            popover.instance.popover.open({resource: info.resource, openDelay: 1000});
                        }
                    };
                    icon.location.nativeElement.onmouseleave = () => {
                        const popover = self.popoversMap.get(info.el);
                        if (popover) {
                            popover.instance.popover.close();
                        }
                    };
                }
            });
        };
    }

    public getDestroyTooltipHandlerForResourceLabel(): WillUnmountHandler<ResourceLabelMountArg> {
        const self = this;
        return info => {
            const popover = self.popoversMap.get(info.el);
            if (popover) {
                self.appRef.detachView(popover.hostView);
                popover.destroy();
                self.popoversMap.delete(info.el);
            }
        };
    }

    public getFullCalendarMode(mode: BookingCalendarMode, isTimelineMode: boolean): string {
        if (isTimelineMode) {
            switch (mode) {
                case BookingCalendarMode.Month:
                    return 'resourceTimelineMonth';
                case BookingCalendarMode.Week:
                    return 'resourceTimelineWeek';
                case BookingCalendarMode.Day:
                    return 'resourceTimelineDay';
                case BookingCalendarMode.Planning:
                    return 'listWeek';
                default:
                    return 'resourceTimelineMonth';
            }
        }

        switch (mode) {
            case BookingCalendarMode.Month:
                return 'dayGridMonth';
            case BookingCalendarMode.Week:
                return 'timeGridWeek';
            case BookingCalendarMode.Day:
                return 'timeGridDay';
            case BookingCalendarMode.Planning:
                return 'listWeek';
            default:
                return 'dayGridMonth';
        }
    }

    public static getColorBasedOnBookingSlotState(state: BookingSlotState | CraningBookingSlotState): string {
        switch (state) {
            case BookingSlotState.Blocked:
            case CraningBookingSlotState.Blocked:
                return CalendarUtils.gray;

            case BookingSlotState.Booked:
            case CraningBookingSlotState.Booked:
            case BookingSlotState.PreBooked:
            case CraningBookingSlotState.PreBooked:
                return CalendarUtils.yellow

            case BookingSlotState.Free:
            case CraningBookingSlotState.Free:
                return CalendarUtils.blue;

            case BookingSlotState.Paid:
            case CraningBookingSlotState.Paid:
                return CalendarUtils.green;
        }
    }

    public static getTextColorBasedOnBookingSlotState(state: BookingSlotState | CraningBookingSlotState): string {
        switch (state) {
            case BookingSlotState.Free:
            case CraningBookingSlotState.Free:
                return CalendarUtils.white;
            default:
                return CalendarUtils.black;
        }
    }

}

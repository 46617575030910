<ng-container *ngIf="overlappingBookings.length > 0">
    <div class="modal-header">
        <h4 class="modal-title" translate="bookings.modal.overlappingBookingsModal.title"></h4>

        <button class="btn-close" aria-label="Close"
                (click)="cancel('Cross click')">
        </button>
    </div>

    <div class="modal-body">
        <p translate="bookings.modal.overlappingBookingsModal.description"></p>

        <p><strong translate="bookings.modal.overlappingBookingsModal.overlappingBookings.title"></strong></p>

        <div style="overflow-y:scroll; max-height:400px;">
            <ul>
                <li *ngFor="let overlappingBooking of overlappingBookings">
                    <span><strong>{{ printBookingDate(overlappingBooking) }}</strong></span>
                </li>
            </ul>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-primary"
                (click)="confirm('confirm')"
                translate="common.ok">
        </button>
    </div>
</ng-container>

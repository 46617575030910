<div class="row mt-4" *ngIf="userCards.length > 0">
    <fieldset class="col-12">
        <legend translate="common.debtorCardsDetails"></legend>

        <div class="col-sm-6">
            <table class="table table-borderless no-padding"
                   [attr.aria-label]="'common.debtorCardsDetails' | translate">
                <thead>
                    <tr>
                        <th scope="col" translate="rfidCards.table.cardNumber"></th>
                        <th scope="col" translate="rfidCards.table.uid"></th>
                    </tr>
                </thead>
                <tbody>
                <tr *ngFor="let card of userCards">
                    <td> {{ card.cardNumber }}</td>
                    <td> {{ card.uid }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </fieldset>
</div>

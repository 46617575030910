<div class="content-container bg-white" [appHasAnyRole]="[AppRoles.admin, AppRoles.alarmManager]">

    <h1 translate="alerts.history.title"></h1>

    <form [formGroup]="filter.formGroup"
          (keyup.enter)="applyFilters()">

        <div class="row">

            <!-- IotRegister -->
            <div class="default-filter-form-field">
                <label class="form-label" for="iotRegister" translate="alerts.history.filter.label.iotRegister"></label>
                <app-search-iot-register id="iotRegister" #iotRegisterSearch
                                         (selectedRegisterEvent)="iotRegister.setValue($event)"
                                         [registerTypes]="[RegisterType.CurrentUsage, RegisterType.CurrentUsage]"
                                         [measuringPointType]="MeasuringPointType.Admin"
                                         [disabled]="false"></app-search-iot-register>
            </div>

            <!-- Measuring Point -->
            <div class="default-filter-form-field">
                <label class="form-label" for="measuringPoint" translate="alerts.history.filter.label.measuringPoint"></label>
                <app-search-measuring-point id="measuringPoint" #measuringPointSearch
                                            (selectedMeasuringPointEvent)="measuringPoint.setValue($event)"
                                            [measuringPointTypes]="[MeasuringPointType.Admin]"></app-search-measuring-point>
            </div>

            <!-- Released User -->
            <div class="default-filter-form-field">
                <label class="form-label" for="user" translate="alerts.history.filter.label.releasedUser"></label>
                <app-search-user id="user" #userSearch
                                 (selectedUserEvent)="user.setValue($event)"></app-search-user>
            </div>

            <!-- Appeared Date -->
            <div class="default-filter-form-field">
                <app-date-period-picker inputLabel="alerts.history.filter.label.appearedDate"
                                        [periodControl]="appearedPeriod"></app-date-period-picker>
            </div>

            <!-- Released Date -->
            <div class="default-filter-form-field">
                <app-date-period-picker inputLabel="alerts.history.filter.label.releasedDate"
                                        [periodControl]="releasedPeriod"></app-date-period-picker>
            </div>

            <!-- Comparison symbol -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="alerts.history.filter.label.comparisonSymbol"></label>
                <select class="form-control form-select" id="comparisonSymbol" name="comparisonSymbol"
                        formControlName="comparisonSymbol">
                    <option [ngValue]="null">-</option>
                    <option *ngFor="let cs of filter.comparisonSymbolsDropdownList"
                            [ngValue]="cs">{{'common.comparisonSymbol.' + cs | translate}}</option>
                </select>
            </div>

            <!-- Threshold -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="alerts.history.filter.label.threshold"></label>
                <input class="form-control" id="threshold" type="number" name="threshold"
                       placeholder="{{'alerts.history.filter.label.threshold' | translate}}"
                       formControlName="threshold"/>
            </div>

        </div>

        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   (click)="clearFilter()">

            <input class="btn btn-primary" type="button"
                   [value]="'common.filter' | translate"
                   (click)="applyFilters()"
                   [disabled]="filter.formGroup.invalid">
        </div>
    </form>

    <!-- Table -->
    <ngx-datatable #devicesTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="alerts.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   rowHeight="50"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="alerts.rowCount"
                   [sorts]="[{prop: 'appearedDateTime', dir: 'desc'}]"
                   [messages]="{
                       totalMessage: 'common.datatable.total' | translate
                   }"
                   [offset]="crtPage"
                   [limit]="alerts.pageSize"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [scrollbarH]="true">



        <ngx-datatable-column [width]="150" prop="IotRegister.Label{{crtLang.toUpperCase()}}" sortable="true"
                              name="{{'alerts.history.table.designation' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row['designation' + crtLang.toUpperCase()] }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="MeasuringPoint.Name{{crtLang.toUpperCase()}}" sortable="true"
                              name="{{'alerts.history.table.equipment' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row['equipment' + crtLang.toUpperCase()] }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="125" prop="comparisonSymbol" sortable="true"
                              name="{{'alerts.history.table.comparisonSymbol' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span>{{'common.comparisonSymbol.' + row.comparisonSymbol | translate}} {{row.threshold}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="125" prop="appearedDateTime" sortable="true"
                              name="{{'alerts.history.table.appearedDate' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.appearedDateTime | date:dateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="125" prop="releasedDateTime" sortable="true"
                              name="{{'alerts.history.table.releasedDate' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.releasedDateTime | date:dateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="releasedUser" sortable="true"
                              name="{{'alerts.history.table.user' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.releasedUser || 'alerts.history.table.autoReleased' | translate }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div class="page-count">
                    {{ rowCount }} {{ 'common.datatable.total' | translate }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="devicesTable.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {KocoService} from '../../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {TranslateUtils} from '../../../../_shared/translate-utils';

@Component({
    selector: 'app-activate-koco-dumpster-modal',
    templateUrl: './activate-koco-dumpster-modal.component.html'
})
export class ActivateKocoDumpsterModalComponent implements OnInit {

    @Input() deviceIdentifierToActivate!: string;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(public activeModal: NgbActiveModal,
                private readonly kocoService: KocoService,
                private readonly notificationsService: NotificationsService) {
    }


    ngOnInit(): void {
        this.kocoService.canUserActivateKocoDevice(this.deviceIdentifierToActivate).pipe().subscribe({
            error: error => this.activeModal.close(error)
        });
    }

    activateService(): void {
        this.kocoService.activateKocoDevice(this.deviceIdentifierToActivate).pipe().subscribe(() => {
            this.notificationsService.success({
                title: 'services.kocoDumpster.notifications.openSuccess',
            });

            this.activeModal.close();
        });
    }
}

<h4>{{ contractTitle | translate }}</h4>

<div class="btn-bar" *ngIf="!creating && !contract?.id">
    <button class="btn btn-primary"
            (click)="createNewContract()" translate="equipments.technical.contract.create">
    </button>
</div>

<div class="row" *ngIf="!creating && !contract?.id">

    <div class="col-12 col-md-6">
        <label class="form-label" for="contractList">{{ 'equipments.technical.contract.contractList' | translate }}</label>
        <select class="form-control form-select" id="contractList" name="contractList"
                [formControl]="contractsList">
            <option *ngFor="let item of contracts;"
                    [ngValue]="item.id">{{item['enterprise']}}</option>
        </select>
    </div>

    <div class="btn-bar">
        <button class="btn btn-primary"
                [disabled]="!contractsList.value"
                (click)="linkContract()" translate="equipments.technical.contract.link">
        </button>
    </div>

</div>


<form [formGroup]="contractForm" *ngIf="!!contract?.id || creating">

    <div class="btn-bar" *ngIf="!!contract?.id">
        <button class="btn btn-outline-danger"
                (click)="unlinkContract()" translate="equipments.technical.contract.unlink">
        </button>
    </div>

    <div class="row">

        <div class="col-12 col-md-6">
            <label class="form-label" for="contractEnterprise">{{ 'equipments.technical.form.contract.enterprise' | translate }}*</label>
            <input class="form-control" id="contractEnterprise" type="text"
                   [formControl]="contractEnterprise"
                   placeholder="{{ 'equipments.technical.placeholder.contract.enterprise' | translate }}">
            <app-form-error [control]="contractEnterprise"></app-form-error>
        </div>

        <div class="col-12 col-md-6">
            <label class="form-label" for="contactName">{{ 'equipments.technical.form.contract.contactName' | translate }}*</label>
            <input class="form-control" id="contactName" type="text"
                   [formControl]="contractContactName"
                   placeholder="{{ 'equipments.technical.placeholder.contract.contactName' | translate }}">
            <app-form-error [control]="contractContactName"></app-form-error>
        </div>

        <div class="col-12 col-md-6">
            <label class="form-label" for="contractPhone1">{{ 'equipments.technical.form.contract.phone1' | translate }}*</label>
            <input class="form-control" id="contractPhone1" type="text"
                   [formControl]="contractPhone1"
                   placeholder="{{ 'common.placeholders.phone' | translate }}">
            <app-form-error [control]="contractPhone1"></app-form-error>
        </div>

        <div class="col-12 col-md-6">
            <label class="form-label" for="contractPhone2">{{ 'equipments.technical.form.contract.phone2' | translate }}</label>
            <input class="form-control" id="contractPhone2" type="text"
                   [formControl]="contractPhone2"
                   placeholder="{{ 'common.placeholders.phone' | translate }}">
            <app-form-error [control]="contractPhone2"></app-form-error>
        </div>

        <div class="col-12 col-md-6">
            <label class="form-label" for="contractEmail">{{ 'equipments.technical.form.contract.email' | translate }}*</label>
            <input class="form-control" id="contractEmail" type="text"
                   [formControl]="contractEmail"
                   placeholder="{{ 'equipments.technical.placeholder.contract.email' | translate }}">
            <app-form-error [control]="contractEmail"></app-form-error>
        </div>
    </div>

    <div class="btn-bar">
        <button type="submit" class="btn btn-outline-secondary" *ngIf="creating"
                (click)="creating = false"
                translate="common.cancel">
        </button>
        <button type="submit" class="btn btn-primary"
                [disabled]="contractForm.invalid || contractForm.pristine"
                (click)="addOrUpdateContract()">{{ (!creating ? 'common.save' : 'common.create') | translate }}
        </button>
    </div>

    <document-management-component #equipmentDocumentListComponent *ngIf="contract.id"
                                   [id]="contract.id"
                                   [useH5Title]="true"
                                   [documentManagementType]="documentManagementTypeEnum.Contract"
                                   (fileListChangeEvent)="fileListChangeEvent()"></document-management-component>

</form>

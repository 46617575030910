<div>
    <div class="modal-header">
        <h4 class="modal-title" [translate]="webcam ? 'services.webcam.modal.modifyTitle' : 'services.webcam.modal.addTitle'"></h4>

        <button class="btn-close" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')">
        </button>
    </div>

    <div class="modal-body">
        <form name="webcamForm" [formGroup]="webcamForm">

            <div class="row">

                <!-- Location -->
                <div class="col-12 col-md-6">
                    <label class="form-label" for="locationFR">{{ 'services.webcam.modal.locationFR' | translate }}*</label>
                    <input class="form-control" id="locationFR" type="text" name="location" maxlength="50"
                           formControlName="locationFR"
                           placeholder="{{ 'services.webcam.modal.locationFR' | translate }}"
                           required>
                    <app-form-error [control]="locationFR"></app-form-error>
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                    <label class="form-label" for="locationDE">{{ 'services.webcam.modal.locationDE' | translate }}</label>
                    <input class="form-control" id="locationDE" type="text" name="location" maxlength="50"
                           formControlName="locationDE"
                           placeholder="{{ 'services.webcam.modal.locationDE' | translate }}">
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                    <label class="form-label" for="locationIT">{{ 'services.webcam.modal.locationIT' | translate }}</label>
                    <input class="form-control" id="locationIT" type="text" name="location" maxlength="50"
                           formControlName="locationIT"
                           placeholder="{{ 'services.webcam.modal.locationIT' | translate }}">
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                    <label class="form-label" for="locationEN">{{ 'services.webcam.modal.locationEN' | translate }}</label>
                    <input class="form-control" id="locationEN" type="text" name="location" maxlength="50"
                           formControlName="locationEN"
                           placeholder="{{ 'services.webcam.modal.locationEN' | translate }}">
                </div>

                <!-- IMAGE URL -->
                <div class="col-12">
                    <label class="form-label" for="imageUrl">{{ 'services.webcam.modal.imageUrl' | translate }}*</label>
                    <input class="form-control" id="imageUrl" type="text" name="imageUrl" maxlength="1000"
                           formControlName="imageUrl"
                           placeholder="{{ 'services.webcam.modal.imageUrl' | translate }}"
                           required>
                    <app-form-error [control]="imageUrl"></app-form-error>
                </div>

                <div class="col-12 mt-2 text-center" *ngIf="imageUrl.value">
                    <img class="rounded w-50 mw-100" [src]="imageUrl.value"
                         alt="{{ 'services.webcam.modal.waiting' | translate }}"/>
                </div>

                <!-- Description -->
                <div class="col-12">
                    <label class="form-label">{{ 'services.webcam.modal.descriptionFR' | translate }}</label>
                    <textarea class="form-control" id="descriptionFR"
                              placeholder="{{ 'services.webcam.modal.descriptionFR' | translate }}"
                              maxLength="500" rows="3" formControlName="descriptionFR"></textarea>
                </div>

                <div class="col-12 " *ngIf="customerConfigService.isLanguageAvailable('de')">
                    <label class="form-label">{{ 'services.webcam.modal.descriptionDE' | translate }}</label>
                    <textarea class="form-control" id="descriptionDE"
                              placeholder="{{ 'services.webcam.modal.descriptionDE' | translate }}"
                              maxLength="500" rows="3" formControlName="descriptionDE"></textarea>
                </div>

                <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('it')">
                    <label class="form-label">{{ 'services.webcam.modal.descriptionIT' | translate }}</label>
                    <textarea class="form-control" id="descriptionIT"
                              placeholder="{{ 'services.webcam.modal.descriptionIT' | translate }}"
                              maxLength="500" rows="3" formControlName="descriptionIT"></textarea>
                </div>

                <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('en')">
                    <label class="form-label">{{ 'services.webcam.modal.descriptionEN' | translate }}</label>
                    <textarea class="form-control" id="descriptionEN"
                              placeholder="{{ 'services.webcam.modal.descriptionEN' | translate }}"
                              maxLength="500" rows="3" formControlName="descriptionEN"></textarea>
                </div>

                <div class="col-12">
                    <label class="form-label">{{ 'services.webcam.modal.visible' | translate }}</label>
                    <div class="form-check form-switch">
                        <input class="form-check-input" id="active" type="checkbox" name="active"
                               formControlName="active"/>
                        <label class="form-check-label" for="active">
                            {{ (this.active.value ? 'services.webcam.modal.active' : 'services.webcam.modal.inactive') | translate }}
                        </label>
                    </div>
                </div>

            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-secondary"
                (click)="activeModal.close()"
                translate="common.cancel">
        </button>

        <button class="btn btn-primary" type="submit"
                [disabled]="webcamForm.invalid"
                (click)="addOrUpdateWebcam()"
                translate="common.save">
        </button>
    </div>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    ItemToBookDto,
} from "../../../_services/configuration-services";
import {TranslateUtils} from "../../translate-utils";

@Component({
  selector: 'app-item-to-book-selection',
  templateUrl: './item-to-book-selection.component.html'
})
export class ItemToBookSelectionComponent implements OnInit {

    @Input() items = new Array<ItemToBookDto>();
    @Input() crtLang: string = TranslateUtils.defaultLanguage;

    @Output() selectItem: EventEmitter<ItemToBookDto> = new EventEmitter();

    selectedItem: ItemToBookDto | null = null;

    ngOnInit(): void {
        if (this.items.length == 1) {
            this.selectedItem = this.items[0];
        }
    }

    onItemSelected(event: Event): void {
        const itemId = event.target['value'];

        if (!itemId || itemId === 'null') {
            this.selectedItem = null;
        } else {
            this.selectedItem = this.items.filter(i => i.id === itemId)[0];
        }

        this.selectItem.emit(this.selectedItem);
    }
}

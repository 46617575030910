<div class="row" *ngIf="datatransTransactionStatus !== ''">
    <div class="col-12">
        <div class="alert alert-success" role="alert" *ngIf="datatransTransactionStatus === 'Success'"
             translate="epayment.success">
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="datatransTransactionStatus === 'Error'"
             translate="epayment.error">
        </div>

        <div class="alert alert-info" role="alert" *ngIf="datatransTransactionStatus === 'Cancel'"
             translate="epayment.cancel">
        </div>
    </div>
</div>

<!-- Ewallet informations -->
<div class="d-flex bg-blue ewallet-info-container" *ngIf="ewallet">

    <div class="d-flex flex-row justify-content-between w-100 bg-white ewallet-info-content">
        <div>
            <h1>
                <img class="wallet-icon" src="../assets/icons/wallet.svg" alt="icon" height="34px" width="41px"/>
                {{ "ewallet.title" | translate }}
            </h1>
            <h5>{{ userName }}</h5>
            <p class="account-balance"><strong>{{ ewallet.balance | currencyFormat }}</strong></p>
            <h5>{{ "ewallet.acountBalanceText" | translate }}</h5>
        </div>

        <div class="transaction-button-container">
            <button class="btn btn-primary" type="button" *ngIf="!userId && customerConfigService.isEPaymentEnabled()"
                    (click)="reloadWallet()">
                <fa-icon class="arrow-right" [icon]="icons.arrowRight"></fa-icon>
                {{ "ewallet.topUp" | translate }}
            </button>

            <button  type="button" class="btn btn-primary" *ngIf="userId"
                     [appHasAnyRole]="[AppRoles.cashier]"
                     [routerLink]="['cashier-operation']"
                     [queryParams]="{username: userName}">
                <fa-icon class="arrow-right" [icon]="icons.arrowRight" ></fa-icon>
                {{ "ewallet.cashierOperation" | translate }}
            </button>
        </div>

    </div>
</div>

<!-- FILTERS -->
<div class="content-container bg-white" *ngIf="transactions"
     [formGroup]="filterForm">

    <div class="row" id="filter">

        <!-- Date Filter -->
        <div class="default-filter-form-field" *ngIf="!!validityPeriod">
            <app-date-period-picker [periodControl]="validityPeriod"
                                    inputLabel="transactions.filter.date"></app-date-period-picker>
        </div>

        <!-- Operation Filter -->
        <div class="default-filter-form-field" *ngIf="!!amountRange && !!amountFrom && !!amountTo"
             [formGroup]="amountRange">
            <div [formGroup]="amountRange">
                <label class="form-label" translate="ewallet.table.operation"></label>

                <div class="input-group mb-2">
                    <span class="input-group-text" translate="transactions.filter.amountFrom"></span>
                    <input class="form-control" id="amountFrom" name="amountFrom"
                           type="number"
                           (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                           [formControl]="amountFrom"
                           placeholder="{{ 'transactions.filter.amountFrom' | translate }}">
                    <span class="input-group-text">CHF</span>
                </div>
                <app-form-error [control]="amountFrom"></app-form-error>

                <div class="input-group mb-2">
                    <span class="input-group-text" translate="transactions.filter.amountTo"></span>
                    <input class="form-control" id="amountTo" name="amountTo"
                           type="number"
                           (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                           [formControl]="amountTo"
                           placeholder="{{ 'transactions.filter.amountTo' | translate }}">
                    <span class="input-group-text">CHF</span>
                </div>
                <app-form-error [control]="amountTo"></app-form-error>
                <app-form-error [control]="amountRange"></app-form-error>
            </div>
        </div>

        <!-- Balance Filter -->
        <div class="default-filter-form-field" *ngIf="!!eWalletBalanceRange && !!eWalletBalanceFrom && !!eWalletBalanceTo"
             [formGroup]="eWalletBalanceRange">
            <label class="form-label" translate="ewallet.table.balance"></label>
            <div class="input-group mb-2">
                <span class="input-group-text" translate="transactions.filter.amountFrom"></span>
                <input class="form-control" id="eWalletBalanceFrom" name="eWalletBalanceFrom"
                       type="number"
                       (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                       [formControl]="eWalletBalanceFrom"
                       placeholder="{{ 'transactions.filter.amountFrom' | translate }}">
                <span class="input-group-text">CHF</span>
            </div>
            <app-form-error [control]="eWalletBalanceFrom"></app-form-error>

            <div class="input-group mb-2">
                <span class="input-group-text" translate="transactions.filter.amountTo"></span>
                <input class="form-control" id="eWalletBalanceTo"
                       name="eWalletBalanceTo"
                       type="number"
                       (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                       [formControl]="eWalletBalanceTo"
                       placeholder="{{ 'transactions.filter.amountTo' | translate }}">
                <span class="input-group-text">CHF</span>
            </div>
            <app-form-error [control]="eWalletBalanceTo"></app-form-error>
            <app-form-error [control]="eWalletBalanceRange"></app-form-error>
        </div>

        <!-- Service Filter -->
        <div class="default-filter-form-field" *ngIf="!!transactionSource">
            <label class="form-label" translate="ewallet.table.service"></label>
            <ng-multiselect-dropdown
                placeholder="{{'common.multiselect.singlePlaceholder' | translate}}"
                [formControl]="service"
                [settings]="dropdownSettings"
                [data]="servicesDropdown">
            </ng-multiselect-dropdown>
        </div>

        <!-- Transaction Source Filter -->
        <div class="default-filter-form-field" *ngIf="!!transactionSource">
            <label class="form-label" translate="transactions.filter.transactionSource"></label>
            <ng-multiselect-dropdown
                placeholder="{{'common.multiselect.singlePlaceholder' | translate}}"
                [settings]="dropdownSettings"
                [data]="transactionSourcesDropdown"
                [formControl]="transactionSource">
            </ng-multiselect-dropdown>
        </div>

    </div>

    <!-- Erase filters button -->
    <div class="btn-bar">
        <button class="btn btn-outline-secondary" type="button"
                (click)="clearFilter()"
                translate="common.clearFilter">
        </button>

        <button class="btn btn-primary" type="button"
                (click)="filterTransaction()"
                [disabled]="filterForm.invalid"
                translate="ewallet.filter.filter">
        </button>
    </div>

    <!-- Transaction Informations -->
    <ngx-datatable
        class="material"
        [columnMode]="'force'"
        [rows]="transactions.results"
        [headerHeight]="50"
        [footerHeight]="50"
        [reorderable]="false"
        rowHeight="auto"
        [externalPaging]="true"
        [externalSorting]="true"
        [count]="transactions.rowCount"
        [messages]="{
                    totalMessage: 'common.datatable.total' | translate
                    }"
        [offset]="crtPage"
        [limit]="transactions.pageSize"
        (page)="setPage($event)"
        (sort)="onSort($event)"
        [scrollbarH]="true">

        <!-- Date -->
        <ngx-datatable-column [width]="215" name="{{ 'ewallet.table.date' | translate}}" prop="dateTime"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.dateTime | date:dateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <!-- Service -->
        <ngx-datatable-column [width]="215" name="{{ 'ewallet.table.service' | translate}}" sortable="true"
                              prop="serviceName{{crtLang.toUpperCase()}}">
            <ng-template ngx-datatable-cell-template let-transaction="row">
                {{transaction['serviceName' + crtLang.toUpperCase()]}}
            </ng-template>
        </ngx-datatable-column>

        <!-- Transaction Source -->
        <ngx-datatable-column [width]="195" prop="transactionSource" name="{{'ewallet.table.source' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ ('transactions.enums.sources.' + row.transactionSource).toLowerCase() | translate}}
            </ng-template>
        </ngx-datatable-column>

        <!-- Operation -->
        <ngx-datatable-column [width]="150" name="{{ 'ewallet.table.operation' | translate}}" prop="amount"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                <fa-icon [icon]="icons.arrowCircleUp" *ngIf="row.amount > 0" class="arrow-circle text-primary"></fa-icon>
                <fa-icon [icon]="icons.arrowCircleDown" *ngIf="row.amount < 0" class="arrow-circle text-danger"></fa-icon>
                {{ row.amount > 0 ? '+' : '' }}{{ row.amount | currencyFormat }}
            </ng-template>
        </ngx-datatable-column>

        <!-- Amount -->
        <ngx-datatable-column [width]="125" name="{{ 'ewallet.table.balance' | translate}}" prop="ewalletBalanceAfter"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.ewalletBalanceAfter | currencyFormat }}
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary" type="button"
                (click)="back()"
                translate="common.back">
        </button>
    </div>
</div>

import {Component} from '@angular/core';
import {AppRoles} from '../app.constants';

@Component({
    selector: 'app-ewallets',
    templateUrl: './ewallets.component.html'
})
export class EwalletsComponent {

    protected readonly AppRoles = AppRoles;
}

<div class="modal-header">
    <h4 class="modal-title"
        translate="services.publicLighting.light.assign">
    </h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form name="lightsForm" [formGroup]="lightsForm">

        <div class="table-responsive mt-4 table-wrapper-scroll-y">
            <table class="table table-striped"
                   [attr.aria-label]="'services.publicLighting.light.table.title' | translate">
                <thead>
                <tr>
                    <th scope="col" style="min-width: 150px"
                        translate="services.publicLighting.light.table.code">
                    </th>
                    <th scope="col" style="min-width: 300px"
                        translate="services.publicLighting.light.table.description">
                    </th>
                    <th scope="col" style="min-width: 100px"
                        translate="services.publicLighting.light.table.assignedToZone">
                    </th>
                </tr>
                </thead>

                <tbody>

                <ng-container formArrayName="isAssignedToZoneArray">
                    <tr *ngFor="let light of lights; index as i">
                        <ng-container [formGroupName]="i">
                            <td>{{ light.code }}</td>

                            <td>{{ light['description' + currentLang.toUpperCase()] }}</td>

                            <td class="text-center">
                                <input formControlName="isAssignedToZone" type="checkbox">
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>
    <button class="btn btn-primary" type="submit"
            (click)="saveLightsAssignation()"
            translate="common.save">
    </button>
</div>


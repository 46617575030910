import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    InformationDto,
    InformationLightDto,
    InformationService
} from '../../../_services/configuration-services';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {DocumentManagementTypeEnum} from '../../../_shared/_components/document-management-component/document-management.component';
import {DocumentViewComponent} from '../../../_shared/_components/document-management-component/document-view/document-view.component';
import {RolesService} from '../../../_shared/roles-service';
import {RouterService} from '../../../_shared/router-service';
import {AppRoles} from '../../../app.constants';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import {CommunicationChannelConfigService} from '../../../_shared/communication-channel-config.service';

@Component({
    selector: 'app-view-info',
    templateUrl: './view-info.component.html'
})
export class ViewInfoComponent implements OnInit, OnDestroy {

    @ViewChild('documentListComponent') documentListComponent: DocumentViewComponent;

    DocumentManagementTypeEnum = DocumentManagementTypeEnum;

    infoId: string | null = null;
    info!: InformationDto;
    infoIndex!: number;
    infoArray: InformationLightDto[] = [];

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    AppRoles = AppRoles;

    icons = {
        pen: faPen
    };

    constructor(private readonly route: ActivatedRoute,
                private readonly router: Router,
                private readonly routerService: RouterService,
                private readonly rolesService: RolesService,
                private readonly translateService: TranslateService,
                private readonly informationService: InformationService,
                private readonly communicationChannelConfigService: CommunicationChannelConfigService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.route.params.subscribe(params => {
            this.infoId = params['id'] ?? null;
            this.infoArray =  this.routerService.getObjectFromRouteState('infoArray') ?? [];

            if (this.infoArray.length === 0) {
                this.fetchInformationList();

            } else {
                this.fetchInformation(this.infoId);
            }
        });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    hasPreviousInformation(): boolean {
        return this.infoIndex > 0;
    }

    hasNextInformation(): boolean {
        return (this.infoIndex + 1) < this.infoArray.length;
    }

    nextInformation(): void {
        if (this.hasNextInformation()) {
            this.router.navigate(['../' + this.infoArray[this.infoIndex + 1].id], {
                relativeTo: this.route,
                state: { infoArray: this.infoArray }
            });
        }
    }

    previousInformation(): void {
        if (this.hasPreviousInformation()) {
            this.router.navigate(['../' + this.infoArray[this.infoIndex - 1].id], {
                relativeTo: this.route,
                state: { infoArray: this.infoArray }
            });
        }
    }

    back(): void {
        this.router.navigate(['..'], {
            relativeTo: this.route
        })
    }

    private fetchInformation(infoId: string): void {
        this.informationService.getInformation(infoId).pipe().subscribe(info => {
            this.info = info;
            this.infoIndex = this.infoArray.findIndex(i => i.id === infoId);
            this.informationService.addReadStatus(infoId).pipe().subscribe(() => {
                this.communicationChannelConfigService.fetchHasUnreadMessage();
            });

            if(this.documentListComponent) {
                this.documentListComponent.id = infoId;
                this.documentListComponent.fetchData();
            }
        }, _ => {
            this.router.navigate(['..'], {
                relativeTo: this.route
            });
        });
    }

    private fetchInformationList(): void {
        if (this.rolesService.hasRoleAdmin()) {
            this.informationService.getAll().pipe().subscribe(infoArray => {
                this.fetchInformation(this.infoId);
                this.infoArray = infoArray;
            });

        } else {
            this.informationService.getAllActive().pipe().subscribe(infoArray => {
                this.fetchInformation(this.infoId);
                this.infoArray = infoArray;
            });
        }
    }

}

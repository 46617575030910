import {Component, Input, OnInit} from '@angular/core';
import {FormUtils} from '../../form-utils';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../notifications.service';
import {
    ExternalLightDto,
    LightDto,
    LightInputDto,
    LightZoneDto,
    PublicLightingExternalLightService,
    PublicLightingLightService,
    PublicLightingProvider,
    PublicLightingZoneService
} from '../../../_services/configuration-services';
import {firstValueFrom} from 'rxjs';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {
    LinkLightToExternalServiceModalComponent, LinkLightToProvider
} from '../../../services/manage-service/public-lighting/link-light-to-external-service-modal/link-light-to-external-service-modal.component';
import {MapContent} from '../../../services/manage-service/manage-map-content/manage-map-content.component';
import { ConfirmModalService } from '../confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-light-view-modal',
    templateUrl: './light-view-modal.component.html'
})
export class LightViewModalComponent implements OnInit {

    @Input() serviceId: string;
    @Input() mapContent: MapContent;
    @Input() lightDto: LightDto | null;

    lightForm: FormGroup<LightInputForm>;

    FormUtils = FormUtils;
    PublicLightingProvider = PublicLightingProvider;

    textInputPattern = FormUtils.textInputPattern;

    lightZones: LightZoneDto[] = [];
    initialExternalLight: ExternalLightDto | null = null;

    editionMode: boolean = false;

    icons = {
        edit: faEdit
    }

    constructor(readonly activeModal: NgbActiveModal,
                private readonly modalService: NgbModal,
                private readonly notificationsService: NotificationsService,
                private readonly lightZoneService: PublicLightingZoneService,
                private readonly lightService: PublicLightingLightService,
                private readonly externalLightService: PublicLightingExternalLightService,
                private readonly confirmService: ConfirmModalService) {

        this.lightForm = new FormGroup<LightInputForm>({
            code: new FormControl(null, [Validators.required, Validators.maxLength(5)]),
            descriptionFR: new FormControl(null, Validators.maxLength(255)),
            descriptionDE: new FormControl(null, Validators.maxLength(255)),
            descriptionIT: new FormControl(null, Validators.maxLength(255)),
            descriptionEN: new FormControl(null, Validators.maxLength(255)),
            latitude: new FormControl(null, [Validators.required, FormUtils.coordinatesPatternValidator]),
            longitude: new FormControl(null, [Validators.required, FormUtils.coordinatesPatternValidator]),
            lightZone: new FormControl(null),
        })
    }

    async ngOnInit(): Promise<void> {
        this.lightForm.patchValue(this.lightDto);
        this.latitude.patchValue(this.mapContent.latitude);
        this.longitude.patchValue(this.mapContent.longitude);

        if (this.lightDto?.lightProvider) {
            this.initialExternalLight = await firstValueFrom(this.externalLightService.getExternalLightById(this.lightDto.lightProvider, this.lightDto.externalId));
            this.addProviderFormControls(new LinkLightToProvider(this.lightDto.lightProvider, this.initialExternalLight));
        }

        this.lightZones = await firstValueFrom(this.lightZoneService.getLightZonesByServiceId(this.serviceId));

        if (this.lightDto) {
            this.lightZone.setValue(this.lightZones.find(lightZone => lightZone.id === this.lightDto.lightZoneId));
        }

        this.updateModalMode();
    }

    async save(): Promise<void> {
        const light = new LightInputDto(this.lightForm.value);
        light.serviceId = this.serviceId;
        light.lightZoneId = this.lightZone.value?.id ?? null;
        light.lightProvider = this.lightProvider?.value ?? null;
        light.externalId = this.externalLight?.value.externalId ?? null;

        if (this.lightDto) {
            await firstValueFrom(this.lightService.updateLight(this.lightDto.id, light));
            this.notificationsService.success({ title: 'services.publicLighting.light.notifications.updateSuccess' });

        } else {
            await firstValueFrom(this.lightService.createLight(light));
            this.notificationsService.success({ title: 'services.publicLighting.light.notifications.success' });
        }

        this.activeModal.close('success');
    }

    delete(): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(async result => {
            if (result === ConfirmModalService.yes) {
                await firstValueFrom(this.lightService.deleteLight(this.lightDto.id));

                this.notificationsService.success({title: 'services.publicLighting.light.notifications.deleteSuccess'});
                this.activeModal.close('success');
            }
        });
    }

    resetToInitialValue(): void {
        if (this.initialExternalLight) {
            this.externalLight.setValue(this.initialExternalLight);
        } else {
            this.lightForm.removeControl('lightProvider');
            this.lightForm.removeControl('externalLight');
        }
    }

    openConfigureProviderModal(): void {
        const modal = this.modalService.open(LinkLightToExternalServiceModalComponent, { centered: true });
        modal.componentInstance.light = this.lightDto;
        modal.componentInstance.inputLightProvider = this.lightProvider?.value ?? null;

        modal.result
            .then(result => {
                if (result instanceof LinkLightToProvider) {
                    this.addProviderFormControls(result);
                }
            });
    }

    private updateModalMode(): void {
        this.editionMode = !!this.lightDto;
    }

    private addProviderFormControls(data: LinkLightToProvider): void {
        if (!this.lightProvider) {
            this.lightForm.addControl('lightProvider', new FormControl({ value: PublicLightingProvider[data.lightProvider], disabled: true}));
            this.lightForm.addControl('externalLight', new FormControl({ value: data.externalLight, disabled: true}));
        }
        else {
            this.lightProvider.setValue(PublicLightingProvider[data.lightProvider]);
            this.externalLight.setValue(data.externalLight);
        }
    }

    get code(): FormControl<string> {
        return this.lightForm.get('code') as FormControl<string>;
    }

    get descriptionFR(): FormControl<string | null> {
        return this.lightForm.get('descriptionFR') as FormControl<string | null>;
    }

    get descriptionDE(): FormControl<string | null> {
        return this.lightForm.get('descriptionDE') as FormControl<string | null>;
    }

    get descriptionIT(): FormControl<string | null> {
        return this.lightForm.get('descriptionIT') as FormControl<string | null>;
    }

    get descriptionEN(): FormControl<string | null> {
        return this.lightForm.get('descriptionEN') as FormControl<string | null>;
    }

    get latitude(): FormControl<number> {
        return this.lightForm.get('latitude') as FormControl<number>;
    }

    get longitude(): FormControl<number> {
        return this.lightForm.get('longitude') as FormControl<number>;
    }

    get lightZone(): FormControl<LightZoneDto> {
        return this.lightForm.get('lightZone') as FormControl<LightZoneDto>;
    }

    get lightProvider(): FormControl<PublicLightingProvider> | null {
        return this.lightForm.get('lightProvider') as FormControl<PublicLightingProvider>;
    }

    get externalLight(): FormControl<ExternalLightDto | null> | null {
        return this.lightForm.get('externalLight') as FormControl<ExternalLightDto | null>;
    }
}

interface LightInputForm {
    code: FormControl<string>
    descriptionFR: FormControl<string | null>,
    descriptionDE: FormControl<string | null>,
    descriptionIT: FormControl<string | null>,
    descriptionEN: FormControl<string | null>,
    latitude: FormControl<number>,
    longitude: FormControl<number>,
    lightZone: FormControl<LightZoneDto | null>,
    lightProvider?: FormControl<PublicLightingProvider>,
    externalLight?: FormControl<ExternalLightDto | null>
}

<div class="modal-header">
    <h4 class="modal-title" translate>publicLighting.lightZone.manageMode.modal.title</h4>

    <button class="btn-close" aria-label="Close" (click)="manageLightZoneModeModal.dismiss('Cross click')">

    </button>
</div>

<div class="modal-body">
    <form [formGroup]="manageLightZoneModeForm">


        <div class="row">

            <!-- mode-->
            <div class="col-12">
                <label class="form-label" for="mode">{{ 'publicLighting.lightZone.manageMode.modal.form.mode' | translate }}*</label>
                <div>
                    <app-light-zone-mode-selector id="mode"
                                                  (selectedMode)="selectedMode($event)">
                    </app-light-zone-mode-selector>
                </div>

                <app-form-error [control]="mode"></app-form-error>
            </div>

            <!-- Duration -->
            <div class="col-12" *ngIf="mode.value !== PublicLightingControlMode.Automatic">
                <label class="form-label" for="duration">{{ 'publicLighting.lightZone.manageMode.modal.form.duration' | translate }}*</label>
                <div>
                    <input id="duration"
                           class="form-control"
                           [formControl]="duration"
                           type="number"
                           step="0.01"
                           min="0"
                           max="12">
                </div>
                <app-form-error [control]="duration"></app-form-error>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">

    <button type="submit" class="btn btn-outline-secondary" (click)="manageLightZoneModeModal.close()"
            translate>
        common.close
    </button>

    <button class="btn btn-primary" type="submit"
            [disabled]="manageLightZoneModeForm.invalid"
            (click)="sendChangeModeOrder()" translate>
        common.save
    </button>

</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {
    CguDto,
    ContactConfigurationService,
    ContactMailDto,
    ContactService
} from "../../_services/configuration-services";
import {NotificationsService} from "../../_shared/notifications.service";
import {AppRoles} from "../../app.constants";
import {TranslateUtils} from "../../_shared/translate-utils";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {faPen} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit, OnDestroy {

    AppRoles = AppRoles;

    message: UntypedFormControl;
    config: CguDto;

    // Icons
    icons = {
        pen: faPen
    };

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription;

    constructor(private readonly contactService: ContactService,
                private readonly contactConfigurationService: ContactConfigurationService,
                private readonly notificationsService: NotificationsService,
                private readonly translateService: TranslateService) {

        this.message = new UntypedFormControl(null, [Validators.required])
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                this.crtLang = this.translateService.currentLang;
            });

        this.contactConfigurationService.getContactCgu().pipe().subscribe(
            config => this.config = config,
            () => this.notificationsService.warning({ title : 'communicationChannel.contact.notification.noConfiguration'}));
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    sendMessage(): void {
        if (!this.message.value) {
            return;
        }

        const mailDto = new ContactMailDto({
            message: this.message.value
        });

        this.contactService.sendContactMail(mailDto).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'contact.notifications.sendSuccess'});
                this.message.reset();
            },
            _ => {
                this.notificationsService.error({title: 'contact.notifications.sendError'});
            })
    }

}

<div class="modal-header">
    <h4 class="modal-title"  translate="services.craning.booking.form.modal.boat.title"></h4>
    <button class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">

    </button>
</div>

<div class="modal-body">
    <form [formGroup]="boatForm">
        <div class="row">
            <div class="form-group col-12">

                <label class="form-label" for="boatWeightInKg">{{'mooringPlace.modal.boatWeight' | translate}}*</label>

                <input class="form-control" id="boatWeightInKg" name="boatWeightInKg" formControlName="boatWeightInKg"
                       pattern="{{FormUtils.textInputPattern}}" type="number"
                       placeholder="{{'mooringPlace.modal.boatWeight' | translate}}"
                       (keydown)="FormUtils.validateInputNumberLength($event, 10)">
                <app-form-error [control]="boatWeightInKg"></app-form-error>

            </div>
        </div>
        <div class="row">
            <!-- boat width -->
            <div class="form-group col-12">
                <label class="form-label" for="boatWidthInMeter">{{'mooringPlace.modal.boatWidth' | translate}}*</label>

                <input class="form-control" id="boatWidthInMeter" name="boatWidthInMeter" formControlName="boatWidthInMeter"
                       pattern="{{FormUtils.textInputPattern}}" type="number" step="0.01"
                       placeholder="{{'mooringPlace.modal.boatWidth' | translate}}"
                       (keydown)="FormUtils.validateInputNumberLength($event, 10)">
                <app-form-error [control]="boatWidthInMeter"></app-form-error>
            </div>
        </div>

        <div class="row">
            <!-- boat width -->
            <div class="form-group col-12">

                <label class="form-label" for="boatLengthInMeter">{{'mooringPlace.modal.boatlength' | translate}}*</label>

                <input class="form-control" id="boatLengthInMeter" name="boatLengthInMeter" formControlName="boatLengthInMeter"
                       pattern="{{FormUtils.textInputPattern}}" type="number" step="0.01"
                       placeholder="{{'mooringPlace.modal.boatlength' | translate}}"
                       (keydown)="FormUtils.validateInputNumberLength($event, 10)">
                <app-form-error [control]="boatLengthInMeter"></app-form-error>

            </div>
        </div>
    </form>
</div>


<div class="modal-footer" >
    <button type="button" class="btn btn-outline-secondary"
            (click)="activeModal.close()" translate>
        common.close
    </button>

    <button type="submit" class="btn btn-primary"
            (click)="boatForm.touched ? saveBoat() : activeModal.close()"
            [disabled]="boatForm.invalid">
        {{ 'common.save'  | translate }}
    </button>
</div>

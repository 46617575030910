import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CommunicationChannelConfigService} from '../_shared/communication-channel-config.service';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-communication-channel',
    templateUrl: './communication-channel.component.html',
    styleUrls: ['./communication-channel.component.scss']
})
export class CommunicationChannelComponent implements OnInit, OnDestroy {
    private routeSubscription: Subscription;

    constructor(public readonly communicationChannelConfigService: CommunicationChannelConfigService,
                private readonly router: Router,
                private readonly route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.routeSubscription = this.router.events.pipe(
            filter((e): e is NavigationEnd => e instanceof NavigationEnd),
            filter(event => event.url.endsWith(this.route.snapshot.url.toString()))
        )
            .subscribe(_ => this.navigate());
        this.navigate();
    }

    private navigate() {
        this.route.params.subscribe(
            _ => {
                if (this.router.url.endsWith('/communication-channel')) {
                    if (this.communicationChannelConfigService.isCommunicationChannelInformationEnabled()) {
                        this.router.navigate(['information'], {relativeTo: this.route});

                    } else if (this.communicationChannelConfigService.isCommunicationChannelSurveyEnabled()) {
                        this.router.navigate(['surveys'], {relativeTo: this.route});

                    } else if (this.communicationChannelConfigService.isCommunicationChannelContactEnabled()) {
                        this.router.navigate(['contact'], {relativeTo: this.route});

                    } else {
                        this.router.navigate(['']);
                    }
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
    }
}


<!-- Services to bill -->
<div *ngIf="formMode === CraningBookingFormMode.Hidden" class="mt-4">
    <h4 translate>services.craning.billing.subtitle</h4>

    <ngx-datatable class="material"
                   [columnMode]="'force'"
                   [rows]="bookings"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   rowHeight="50"
                   [count]="bookings.length"
                   [sorts]="[{prop: 'bookingDateTime', dir: 'asc'}]"
                   [messages]="{
                       totalMessage: 'common.datatable.total' | translate
                   }"
                   [scrollbarH]="true">

        <ngx-datatable-column [width]="150" prop="bookingDateTime" sortable="true"
                              name="{{'services.craning.billing.table.bookingDate' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.bookingDateTime | date:bookingDateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="customerName" sortable="true"
                              name="{{'services.craning.billing.table.user' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="boatRegistrationNumber" sortable="true"
                              name="{{'services.craning.billing.table.boatRegistrationNumber' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="25" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <button class="btn btn-link btn-sm"
                        title="{{'common.tooltip.bill' | translate}}"
                        (click)="displayBookingToBill(row.craningBookingSlotId)">
                    <fa-icon [icon]="icons.open"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>

<div class="btn-bar">

    <button class="btn btn-outline-secondary" type="button" *ngIf="formMode === CraningBookingFormMode.BillingReservation"
            (click)="hideForm()"
            translate>common.cancel
    </button>

    <button class="btn btn-outline-secondary" type="button" *ngIf="formMode === CraningBookingFormMode.DisplayReservation"
            (click)="hideForm()"
            translate>common.back
    </button>

    <button class="btn btn-primary" type="button" *ngIf="formMode === CraningBookingFormMode.DisplayReservation"
            (click)="downloadBillReport()"
            translate>services.craning.booking.actions.download
    </button>
</div>

<!-- Billing Form -->
<app-craning-booking-form [service]="service"
                          [selectedSlot]="selectedSlot"
                          [selectedBooking]="selectedBooking"
                          [mode]="formMode"
                          [crtLang]="crtLang"
                          (bookingBilled)="craningBookingBilled($event)"
                          (bookingSaved)="craningBookingSaved($event)"></app-craning-booking-form>

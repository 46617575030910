<div>
    <div class="modal-header">
        <h4 class="modal-title">
            {{ (!equipmentEventId ? 'equipments.events.modal.addTitle' : 'equipments.events.modal.modifyTitle') | translate }}</h4>

        <button class="btn-close" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')">
        </button>
    </div>

    <div class="modal-body">

        <form name="equipmentEventForm" [formGroup]="equipmentEventForm">

            <div class="row">

                <div class="col-12">
                    <label class="form-label" for="description">{{'equipments.events.form.description' | translate}}*</label>
                    <input class="form-control" id="description" type="text"
                           [formControl]="description"
                           placeholder="{{ 'equipments.events.placeholder.description' | translate }}">
                    <app-form-error [control]="description"></app-form-error>
                </div>

                <!-- Liste d'équipment-->
                <div class="col-12">
                    <label class="form-label" for="equipmentSearch">{{'equipments.events.form.equipment' | translate}}*</label>
                    <app-search-equipment id="equipmentSearch" #searchEquipmentComponent
                                          (selectedEquipmentEvent)="onEquipmentChange($event)"
                                          [defaultEquipment]="equipmentLight"
                                          [disabled]="false"></app-search-equipment>
                    <app-form-error [control]="equipmentId"></app-form-error>
                </div>

                <div class="col-12">
                    <label class="form-label" for="internalContactName">{{ 'equipments.events.form.internalContactName' | translate }}*</label>
                    <input class="form-control" id="internalContactName" type="text"
                           [formControl]="internalContactName"
                           placeholder="{{ 'equipments.events.placeholder.internalContactName' | translate }}">
                    <app-form-error [control]="internalContactName"></app-form-error>
                </div>

                <div class="col-12">
                    <label class="form-label" for="internalContactEmail">{{'equipments.events.form.internalContactEmail'|translate}}*</label>
                    <input class="form-control" id="internalContactEmail" type="text"
                           [formControl]="internalContactEmail"
                           placeholder="{{ 'equipments.events.placeholder.internalContactEmail' | translate }}">
                    <app-form-error [control]="internalContactEmail"></app-form-error>
                </div>

                <div class="col-12">
                    <app-date-time-period-picker [dateTimeForm]="dateTimeForm"
                                                 [baseStartDate]="initialDateStruct"
                                                 [baseStartTime]="initialTimeStruct"
                                                 [canStartInPast]="true">
                    </app-date-time-period-picker>
                </div>


                <div class="col-12 col-sm-6">
                    <label class="form-label" for="reminder" translate="equipments.events.form.reminder"></label>
                    <input class="form-control" id="reminder" type="number" step="1"
                           [formControl]="reminder"
                           placeholder="{{ 'equipments.events.placeholder.reminder' | translate }}">
                    <app-form-error [control]="reminder"></app-form-error>
                </div>

                <ng-container *ngIf="!equipmentEventId">
                    <div class="col-12 col-sm-6">
                        <label class="form-label" for="occurrence" translate="equipments.events.form.occurrence"></label>
                        <input class="form-control" id="occurrence" type="number" step="1"
                               [formControl]="occurrence"
                               placeholder="{{ 'equipments.events.placeholder.occurrence' | translate }}">
                        <app-form-error [control]="occurrence"></app-form-error>
                    </div>

                    <div class="col-12 col-sm-6">
                        <label class="form-label" for="recurrence" translate="equipments.events.form.recurrence"></label>
                        <select class="form-control form-select" id="recurrence" name="recurrence"
                                [formControl]="recurrence">
                            <option *ngFor="let item of equipmentEventRecurrences;"
                                    [ngValue]="item.id">{{item.value}}</option>
                        </select>
                    </div>
                </ng-container>

                <div class="col-12">
                    <label class="form-label" for="comment">{{ 'equipments.events.form.comment' | translate }}</label>
                    <textarea class="form-control" id="comment" type="textarea"
                              maxlength="1000" rows="4"
                              name="comment"
                              [formControl]="comment"
                              placeholder="{{ 'equipments.events.placeholder.comment' | translate }}"></textarea>
                </div>

            </div>

        </form>
    </div>

    <div class="modal-footer">
        <button class="btn btn-danger" *ngIf="!!equipmentEventId"
                (click)="cancelEquipmentEvent()"
                translate="common.delete">
        </button>
        <button class="btn btn-outline-secondary"
                (click)="activeModal.close()"
                translate="common.cancel">
        </button>
        <button type="submit" class="btn btn-primary"
                [disabled]="equipmentEventForm.invalid || equipmentEventForm.pristine"
                (click)="addOrUpdateEquipmentEvent()"
                translate="common.save">
        </button>
    </div>
</div>

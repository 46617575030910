import { Pipe, PipeTransform } from '@angular/core';
import {RfidCardDto} from '../../../_services/configuration-services';

@Pipe({
  name: 'rfidCardsToInlineText'
})
export class RfidCardsToInlineTextPipe implements PipeTransform {

  transform(cards: Array<RfidCardDto>, sep = ' - '): string {
      return cards.map(card => card.cardNumber).join(sep);
  }

}


<div class="content-container bg-white-no-shadow" *ngIf="userMeasuringPoints.length || hasRole(appRoles.admin)">

    <!-- Cockpit Selector -->
    <div [appHasAnyRole]="[appRoles.admin]">
        <div class="btn-group" role="group">
            <input class="btn-check" type="radio"
                   [value]="CockpitView.State">
            <label class="btn btn-toggle btn-toggle-left h-auto"
                   [ngClass]="{ active: cockpitView === CockpitView.State }"
                   translate="services.energy.cockpit.selector.state"
                   (click)="changeCockpitView(CockpitView.State)"
                   (keyup)="changeCockpitView(CockpitView.State)"></label>

            <input class="btn-check" type="radio"
                   [ngClass]="{ active: cockpitView === CockpitView.Consumption }"
                   [value]="CockpitView.Consumption">
            <label class="btn btn-toggle btn-toggle-middle h-auto"
                   [ngClass]="{ active: cockpitView === CockpitView.Consumption }"
                   translate="services.energy.cockpit.selector.consumption"
                   (click)="changeCockpitView(CockpitView.Consumption)"
                   (keyup)="changeCockpitView(CockpitView.Consumption)"></label>

            <input class="btn-check" type="radio"
                   [ngClass]="{ active: cockpitView === CockpitView.User }"
                   [value]="CockpitView.User">
            <label class="btn btn-toggle btn-toggle-right h-auto"
                   [ngClass]="{ active: cockpitView === CockpitView.User }"
                   translate="services.energy.cockpit.selector.user"
                   (click)="changeCockpitView(CockpitView.User)"
                   (keyup)="changeCockpitView(CockpitView.User)"></label>
        </div>
    </div>

    <div *ngIf="cockpitView === CockpitView.State">
        <app-cockpit-energy-state [serviceId]="service.serviceId"></app-cockpit-energy-state>
    </div>

    <div *ngIf="cockpitView === CockpitView.Consumption">
        <app-cockpit-energy-consumption [serviceId]="service.serviceId"></app-cockpit-energy-consumption>
    </div>

    <div *ngIf="cockpitView === CockpitView.User">
        <app-user-energy-cockpit
            [userId]="userId"
            [serviceId]="service.serviceId"
            [tariffs]="service.tariffs"
            [isAdminMode]="hasRole(appRoles.admin)"
            [measuringPoint]="userMeasuringPoints[0]">
        </app-user-energy-cockpit>
    </div>
</div>


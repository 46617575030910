import {Component, Input, OnInit} from '@angular/core';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
    CraningBookingSlotDto,
    CraningBookingSlotInputDto,
    CraningBookingSlotService,
    CraningBookingSlotState
} from '../../../../../_services/configuration-services';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {FormUtils} from '../../../../../_shared/form-utils';
import {DateUtils} from '../../../../../_shared/date-utils';
import {ConfirmModalService} from '../../../../../_shared/_components/confirm-modal/confirm-modal.component';
import {CraningBookingUtils} from '../craning-booking-utils';

@Component({
    selector: 'app-craning-booking-slot-management-modal',
    templateUrl: './craning-booking-slot-management-modal.component.html'
})
export class CraningBookingSlotManagementModalComponent implements OnInit {

    @Input() selectedSlot: CraningBookingSlotDto | null = null;

    craningBookingSlotManagementForm: UntypedFormGroup;

    authorizedCraningBookingSlotStates: CraningBookingSlotState[];

    constructor(public craningBookingSlotManagementModal: NgbActiveModal,
                private readonly craningBookingSlotService: CraningBookingSlotService,
                private readonly notificationsService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                private readonly craningBookingUtils: CraningBookingUtils) {

        this.craningBookingSlotManagementForm = new UntypedFormGroup({
            timePeriod: new UntypedFormGroup({
                from: new UntypedFormControl('', Validators.required),
                duration: new UntypedFormControl('', Validators.required),
            }, [FormUtils.timeDurationInsideSameDayValidator]),
            state: new FormControl<CraningBookingSlotState>(null , Validators.required)
        }, {updateOn: 'change'});
    }

    ngOnInit(): void {
        const startTime = DateUtils.stringToNgbTimeStruct(this.selectedSlot.startTime);
        this.startTime.setValue(startTime);
        this.duration.setValue(DateUtils.subtractNgbTimeStruct(DateUtils.stringToNgbTimeStruct(this.selectedSlot.endTime), startTime));
        this.state.setValue(this.selectedSlot.state);

        switch (this.selectedSlot?.state) {
            case CraningBookingSlotState.Free:
            case CraningBookingSlotState.Blocked:
                this.authorizedCraningBookingSlotStates = [CraningBookingSlotState.Free, CraningBookingSlotState.Blocked];
                break;
            default:
                this.authorizedCraningBookingSlotStates = [this.selectedSlot?.state];
        }
    }

    updateSlot(): void {
        if (this.craningBookingSlotManagementForm.invalid) {
            return;
        }
        this.craningBookingSlotService.updateCraningBookingSlot(this.selectedSlot.id, this.getFormResult()).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'services.craning.notifications.updateBookingSlotSuccess'});
            this.craningBookingSlotManagementModal.close();
        });
    }

    deleteSlot(): void {
        if (!this.canDeleteSlot()) {
            return;
        }
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.craningBookingSlotService.deleteCraningBookingSlot(this.selectedSlot.id).pipe().subscribe(_ => {
                    this.notificationsService.success({title: 'services.craning.notifications.deleteBookingSlotSuccess'});
                    this.craningBookingSlotManagementModal.close();
                });
            }
        });
    }

    onBookingClick(action: string): void {
        this.craningBookingSlotManagementModal.close({
            action,
            selectedSlot: this.selectedSlot
        });
    }

    get timePeriod(): UntypedFormGroup {
        return this.craningBookingSlotManagementForm.get('timePeriod') as UntypedFormGroup;
    }

    get startTime(): UntypedFormControl {
        return this.timePeriod.get('from') as UntypedFormControl;
    }

    get duration(): UntypedFormControl {
        return this.timePeriod.get('duration') as UntypedFormControl;
    }

    get state(): FormControl<CraningBookingSlotState> {
        return this.craningBookingSlotManagementForm.get('state') as FormControl<CraningBookingSlotState>;
    }

    public canDisplayBooking(): boolean {
        return this.selectedSlot.state !== CraningBookingSlotState.Free && this.selectedSlot.state !== CraningBookingSlotState.Blocked;
    }

    public canCreateBooking(): boolean {
        return this.craningBookingUtils.isSlotValidToEdit(this.selectedSlot) &&
            this.selectedSlot.state === CraningBookingSlotState.Free;
    }

    public canEditBooking(): boolean {
        return this.craningBookingUtils.isSlotValidToEdit(this.selectedSlot) &&
            this.selectedSlot.state === CraningBookingSlotState.Booked;
    }

    public canDeleteSlot(): boolean {
        return !!this.selectedSlot
            && (this.selectedSlot.state === CraningBookingSlotState.Blocked
                || this.selectedSlot.state === CraningBookingSlotState.Free);
    }

    private getFormResult(): CraningBookingSlotInputDto {
        const startTime = DateUtils.ngbTimeStructToString(this.startTime.value);

        const endTime = DateUtils.ngbTimeStructToString(DateUtils.addNgbTimeStruct(this.startTime.value, this.duration.value));

        return new CraningBookingSlotInputDto({
            startTime,
            endTime,
            state: this.state.value,
        });
    }
}

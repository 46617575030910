import {Component, Input, OnInit} from '@angular/core';
import {
    BookingServiceConfigDto,
    BookingSlotDto,
    BookingSlotInputDto,
    BookingSlotService,
    BookingSlotState
} from '../../../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalService} from '../../../../../_shared/_components/confirm-modal/confirm-modal.component';
import {BookingSlotUtils} from '../../../../../_shared/booking-slot-utils';
import {NotificationsService} from "../../../../../_shared/notifications.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormUtils} from "../../../../../_shared/form-utils";
import {DateUtils} from "../../../../../_shared/date-utils";
import {NgbTimeStruct} from "@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct";

@Component({
  selector: 'app-booking-slot-management-modal',
  templateUrl: './booking-slot-management-modal.component.html'
})
export class BookingSlotManagementModalComponent implements OnInit {

    @Input() selectedSlot: BookingSlotDto = null!;
    @Input() bookingConfig: BookingServiceConfigDto = null!;
    @Input() hasAdminRoleForBooking = false;

    BookingSlotState = BookingSlotState;
    BookingSlotUtils = BookingSlotUtils;

    bookingSlotManagementForm: FormGroup<BookingSlotManagementForm>;

    isSlotBooked = true;

    constructor(public readonly bookingSlotManagementModal: NgbActiveModal,
                private readonly confirmService: ConfirmModalService,
                private readonly bookingSlotService: BookingSlotService,
                private readonly notificationsService: NotificationsService) {

        this.bookingSlotManagementForm = new FormGroup<BookingSlotManagementForm>({
            timePeriod: new FormGroup<TimePeriodForm>({
                from: new FormControl<NgbTimeStruct>(null, Validators.required),
                duration: new FormControl<NgbTimeStruct>(null, Validators.required),
            }, [FormUtils.timeDurationInsideSameDayValidator]),
            state: new FormControl<BookingSlotState>(null , Validators.required)
        }, {updateOn: 'change'});
    }

    ngOnInit(): void {
        const startTime = DateUtils.stringToNgbTimeStruct(this.selectedSlot.startTime);
        this.startTime.setValue(startTime);
        this.duration.setValue(DateUtils.subtractNgbTimeStruct(DateUtils.stringToNgbTimeStruct(this.selectedSlot.endTime), startTime));
        this.state.setValue(this.selectedSlot.state);

        if (this.selectedSlot.state === BookingSlotState.Free || this.selectedSlot.state === BookingSlotState.Blocked) {
            this.isSlotBooked = false;
        }
    }

    openBookingForm(): void {
        this.bookingSlotManagementModal.close({
            action: 'openForm',
            selectedSlot: this.selectedSlot
        });
    }

    displayBooking(): void {
        this.bookingSlotManagementModal.close({
            action: 'display',
            selectedSlot: this.selectedSlot
        });
    }

    saveSlot(): void {
        if (this.bookingSlotManagementForm.invalid) {
            return;
        }

        this.bookingSlotService.updateBookingSlot(this.selectedSlot.id, this.getFormResult()).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'services.craning.notifications.updateBookingSlotSuccess'});
            this.bookingSlotManagementModal.close();
        });
    }

    deleteSlot(): void {
        if (this.isSlotBooked) {
            return;
        }

        this.confirmService.confirm({ titleKey: 'common.confirmModal.title.delete' }).then(result => {
            if (result === ConfirmModalService.yes) {
                this.bookingSlotService.deleteBookingSlot(this.selectedSlot.id).pipe().subscribe(_ => {
                    this.notificationsService.success({title: 'bookings.notifications.deleteBookingSlotSuccess'});
                    this.bookingSlotManagementModal.close();
                });
            }
        });
    }

    get timePeriod(): FormGroup<TimePeriodForm> {
        return this.bookingSlotManagementForm.get('timePeriod') as FormGroup<TimePeriodForm>;
    }

    get startTime(): FormControl<NgbTimeStruct> {
        return this.timePeriod.get('from') as FormControl<NgbTimeStruct>;
    }

    get duration(): FormControl<NgbTimeStruct> {
        return this.timePeriod.get('duration') as FormControl<NgbTimeStruct>;
    }

    get state(): FormControl<BookingSlotState> {
        return this.bookingSlotManagementForm.get('state') as FormControl<BookingSlotState>;
    }

    private getFormResult(): BookingSlotInputDto {
        const startTime = DateUtils.ngbTimeStructToString(this.startTime.value);
        const endTime = DateUtils.ngbTimeStructToString(DateUtils.addNgbTimeStruct(this.startTime.value, this.duration.value));

        return new BookingSlotInputDto({
            startTime,
            endTime,
            state: this.state.value,
        });
    }
}

interface BookingSlotManagementForm {
    timePeriod: FormGroup<TimePeriodForm>,
    state: FormControl<BookingSlotState>
}

interface TimePeriodForm {
    from: FormControl<NgbTimeStruct>,
    duration: FormControl<NgbTimeStruct>
}

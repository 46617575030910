<div class="content-container bg-white">
    <h1 translate="ewallet.closures.title"></h1>


    <div [formGroup]="periodicClosureFormGroup" class="row mb-4" *ngIf="modes.length > 0">

        <h4 translate="ewallet.closures.periodicScheduleActivationTitle"></h4>

        <ng-container formArrayName="modes" *ngFor="let mode of modes.controls">

            <div class="default-filter-form-field" [formGroup]="mode">

                <label class="form-label" translate="{{ 'ewallet.closures.enums.EwalletClosureScheduleMode.' + mode.value.mode }}"></label>
                <div class="form-check form-switch">
                    <input class="form-check-input" id="state-{{mode.value.mode}}" type="checkbox"
                           [formControl]="mode.controls.enabled"
                           (change)="setPeriodicClosureMode(mode.value.mode, mode.value.enabled)">
                    <label class="form-check-label" for="state-{{mode.value.mode}}">
                        {{ (mode.value.enabled ? 'common.enabled' : 'common.disabled') | translate }}
                    </label>
                </div>

            </div>

        </ng-container>

    </div>

    <h4 translate="ewallet.closures.scheduledClosuresTitle"></h4>

    <div class="btn-bar">
        <button class="btn btn-primary"
                (click)="openClosureScheduleModal()"
                translate="ewallet.closures.add">
        </button>
    </div>

    <div class="table-responsive mb-5">
        <table class="table table-striped" *ngIf="scheduledClosures"
               [attr.aria-label]="'ewallet.closures.title' | translate">

            <thead>
            <tr>
                <th scope="col" style="min-width:250px" translate="ewallet.closures.nextExecution"></th>
                <th scope="col" style="min-width:325px" translate="ewallet.closures.type"></th>
                <th scope="col" style="min-width:100px"></th>
            </tr>
            </thead>

            <tbody>
            <tr *ngIf="scheduledClosures.length === 0">
                <td>-</td>
                <td>-</td>
                <td></td>
            </tr>
            <tr *ngFor="let item of scheduledClosures;">
                <td class="align-middle">{{ item.scheduledDateTime | date: DateUtils.dateTimeWithoutSecondFormat }}</td>
                <td class="align-middle" translate="{{ 'ewallet.closures.enums.EwalletClosureScheduleMode.' + item.mode }}"></td>
                <td class="align-middle text-end">
                    <ng-container *ngIf="item.mode === EwalletClosureScheduleMode.Scheduled">
                        <button class="btn btn-link btn-sm"
                                (click)="openClosureScheduleModal(item)"
                                title="{{'common.tooltip.edit' | translate}}">
                            <fa-icon [icon]="icons.edit"></fa-icon>
                        </button>
                        <button class="btn btn-link btn-sm"
                                (click)="deleteItem(item)"
                                title="{{'common.tooltip.delete' | translate}}">
                            <fa-icon [icon]="icons.delete"></fa-icon>
                        </button>
                    </ng-container>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <app-ewallet-closure-documents></app-ewallet-closure-documents>
</div>



import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateUtils} from "../../../_shared/translate-utils";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-qr-code-view',
    templateUrl: './qr-code-view.component.html'
})
export class QrCodeViewComponent implements OnInit {

    @Input() qrRawData!: string;
    @Input() qrName!: string;

    @Input() serviceId!: string;

    public qrData = '';

    crtLang = TranslateUtils.defaultLanguage;

    constructor(
        public activeModal: NgbActiveModal,
        public readonly translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.qrData = window.location.origin + '/#/services/' + this.serviceId + '/activate/' + this.qrRawData;
    }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../../_shared/translate-utils';
import {
    ISortCriteriaDto,
    PageableRequestDtoOfTransactionFullSearchInputCriteriaDto,
    PagedResultDtoOfTransactionFullSearchOutputDto,
    ServiceService,
    SortCriteriaDto,
    SortDirection,
    TransactionFullSearchCriteriaDto,
    TransactionFullSearchInputCriteriaDto,
    TransactionService
} from '../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormUtils} from '../../_shared/form-utils';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {DateUtils} from '../../_shared/date-utils';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {faArrowCircleDown, faArrowCircleUp} from '@fortawesome/free-solid-svg-icons';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from '../../_shared/custom-date-formatter';
import {CssConstants} from "../../app.constants";

@Component({
    selector: 'app-transaction-full-search',
    templateUrl: './transaction-full-search.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class TransactionFullSearchComponent implements OnInit, OnDestroy {
    crtPage = 0;
    crtLang = TranslateUtils.defaultLanguage;
    currentSort = new SortCriteriaDto({
        direction: SortDirection.Desc,
        property: 'dateTime'
    });

    transactions: PagedResultDtoOfTransactionFullSearchOutputDto = new PagedResultDtoOfTransactionFullSearchOutputDto();

    servicesDropdownList = [];
    dropdownSettings: IDropdownSettings = {};

    FormUtils = FormUtils;
    dateTimeFormat = DateUtils.dateTimeFormat;

    readonly pageSize = 50;

    private searchTermsSubscription: Subscription;
    private langChangeSubscription: Subscription;

    private pageRequest: PageableRequestDtoOfTransactionFullSearchInputCriteriaDto;

    searchTermsFormGroup = new UntypedFormGroup({
        searchTerms: new UntypedFormControl(null)
    });

    filterFormGroup = new UntypedFormGroup({
        validityPeriod: new UntypedFormGroup({
            from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
            to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
        }, [FormUtils.periodValidator]),
        service: new UntypedFormControl([]),
        amountRange: new UntypedFormGroup({
            min: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
            max: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
        }, [FormUtils.rangeValidator])
    });

    // Icons
    icons = {
        arrowCircleUp: faArrowCircleUp,
        arrowCircleDown: faArrowCircleDown
    }

    constructor(private readonly transactionService: TransactionService,
                private readonly translateService: TranslateService,
                private readonly serviceService: ServiceService) {

        this.pageRequest = new PageableRequestDtoOfTransactionFullSearchInputCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new TransactionFullSearchInputCriteriaDto({
            searchCriteriaDto: new TransactionFullSearchCriteriaDto({
                searchTerms: null,
                from: null,
                to: null,
                amountFrom: null,
                amountTo: null,
                serviceIds: []
            }),
            sortCriteriaDto: this.currentSort
        });
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;

        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
            this.clearFilter();
            this.loadServiceDropDown();
        });

        this.searchTermsSubscription = this.searchTerms.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(500)
            )
            .subscribe(() => this.searchCriteriaChanged());


        this.loadServiceDropDown();

        this.fetchTransactions(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.searchTermsSubscription.unsubscribe();
    }

    fetchTransactions(pageableRequest: PageableRequestDtoOfTransactionFullSearchInputCriteriaDto): void {
        this.transactionService.fullSearchTransactions(pageableRequest).pipe().subscribe(value => {
            this.transactions = value;
        });
    }

    clearFilter(): void {
        // clear search
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new TransactionFullSearchInputCriteriaDto({
            searchCriteriaDto: new TransactionFullSearchCriteriaDto({
                from: null,
                to: null,
                serviceIds: [],
                amountFrom: null,
                amountTo: null,
                searchTerms: null,
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            })
        });

        this.searchTermsFormGroup.reset();
        this.filterFormGroup.reset();
        this.goToFirstPage();
        this.fetchTransactions(this.pageRequest);
    }

    searchCriteriaChanged(): void {
        this.goToFirstPage();
        this.filterTransaction();
    }

    filterTransaction(): void {
        if (this.filterFormGroup.invalid || this.searchTerms.invalid) {
            return;
        }

        this.crtPage = 0;

        const dateFrom = DateUtils.ngbDateStructToDate(this.dateFrom.value);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(this.dateTo.value);
        dateTo?.setHours(23, 59, 59, 59);

        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new TransactionFullSearchInputCriteriaDto({
            searchCriteriaDto: new TransactionFullSearchCriteriaDto({
                from: dateFrom,
                to: dateTo,
                serviceIds: this.service.value?.map(it => it.itemId),
                amountFrom: !!this.amountFrom.value || this.amountFrom.value === 0 ? Number(this.amountFrom.value) : null,
                amountTo: !!this.amountTo.value || this.amountTo.value === 0 ? Number(this.amountTo.value) : null,
                searchTerms: this.searchTerms.value
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            })
        });

        this.fetchTransactions(this.pageRequest);
    }

    isFiltering(): boolean {
        const searchCriteriaDto = this.pageRequest.criteria.searchCriteriaDto;

        return (searchCriteriaDto.searchTerms !== null && searchCriteriaDto.searchTerms !== undefined && searchCriteriaDto.searchTerms.trim().length > 0) ||
            (searchCriteriaDto.amountFrom !== null && searchCriteriaDto.amountFrom !== undefined) ||
            (searchCriteriaDto.amountTo !== null && searchCriteriaDto.amountTo !== undefined) ||
            (searchCriteriaDto.from !== null && searchCriteriaDto.from !== undefined) ||
            (searchCriteriaDto.to !== null && searchCriteriaDto.to !== undefined) ||
            (!!searchCriteriaDto.serviceIds && searchCriteriaDto.serviceIds.length > 0);
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;
        this.fetchTransactions(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.goToFirstPage();
        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = sortCriteriaDto as SortCriteriaDto;
        this.fetchTransactions(this.pageRequest);
    }

    getRowClass(): string {
        return CssConstants.unlimitedRowHeightClass;
    }

    private loadServiceDropDown(): void {
        this.serviceService.getServiceNames().pipe().subscribe(res => {
            this.servicesDropdownList = [];
            const serviceNames = res.sort((a, b) => a['name' + this.crtLang.toUpperCase()].localeCompare(b['name' + this.crtLang.toUpperCase()]));
            serviceNames.forEach(r => this.servicesDropdownList.push({
                itemId: r.serviceId,
                itemVal: r['name' + this.crtLang.toUpperCase()]
            }));
        });

        // Dropdown
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'itemId',
            textField: 'itemVal',
            selectAllText: this.translateService.instant('common.multiselect.selectAll'),
            unSelectAllText: this.translateService.instant('common.multiselect.unselectAll'),
            searchPlaceholderText: this.translateService.instant('common.multiselect.search'),
            noDataAvailablePlaceholderText: this.translateService.instant('common.multiselect.noResults'),
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }

    get validityPeriod(): UntypedFormGroup {
        return this.filterFormGroup.get('validityPeriod') as UntypedFormGroup;
    }

    get dateFrom(): UntypedFormControl {
        return this.validityPeriod.get('from') as UntypedFormControl;
    }

    get dateTo(): UntypedFormControl {
        return this.validityPeriod.get('to') as UntypedFormControl;
    }

    get service(): UntypedFormControl {
        return this.filterFormGroup.get('service') as UntypedFormControl;
    }

    get amountRange(): UntypedFormGroup {
        return this.filterFormGroup.get('amountRange') as UntypedFormGroup;
    }

    get amountFrom(): UntypedFormControl {
        return this.amountRange.get('min') as UntypedFormControl;
    }

    get amountTo(): UntypedFormControl {
        return this.amountRange.get('max') as UntypedFormControl;
    }

    get searchTerms(): UntypedFormControl {
        return this.searchTermsFormGroup.get('searchTerms') as UntypedFormControl;
    }

}

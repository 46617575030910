import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    LightZoneWithLightCountAndModeDto,
    PublicLightingZoneService
} from '../../../../_services/configuration-services';
import {firstValueFrom, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {faCircleDot} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ManageLightZoneModeModalComponent} from './manage-light-zone-mode-modal/manage-light-zone-mode-modal.component';

@Component({
  selector: 'app-view-light-zones',
  templateUrl: './view-light-zones.component.html'
})
export class ViewLightZonesComponent implements OnInit, OnDestroy {

    @Input() serviceId: string;

    lightZones: LightZoneWithLightCountAndModeDto[] = [];

    currentLang: string;
    languageSubscription: Subscription;

    icons = {
        manageMode: faCircleDot
    }

    constructor(private readonly lightZoneService: PublicLightingZoneService,
                private readonly translate: TranslateService,
                private readonly modalService: NgbModal) {
    }

    async ngOnInit(): Promise<void> {
        this.currentLang = this.translate.currentLang;
        this.languageSubscription = this.translate.onLangChange.subscribe(langChangeEvent =>
            this.currentLang = langChangeEvent.lang);

        await this.fetchLightZones();
    }

    ngOnDestroy(): void {
        this.languageSubscription.unsubscribe();
    }

    openManageModeModal(lightZone: LightZoneWithLightCountAndModeDto) : void {
        const modal = this.modalService.open(ManageLightZoneModeModalComponent, {centered: true});
        modal.componentInstance.lightZoneId = lightZone.id;

        modal.result
            .then(async () => {
                await this.fetchLightZones();
            })
            .catch(() => { /* catch the rejection */
            });
    }

    private async fetchLightZones(): Promise<void> {
        this.lightZones = await firstValueFrom(this.lightZoneService.getLightZonesWithLightCountAndModeByServiceId(
            this.serviceId));
    }
}

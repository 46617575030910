<div class="modal-header">
    <h4 class="modal-title"
        [translate]="chargingStation ? 'services.chargingStations.modal.modifyTitle' : 'services.chargingStations.modal.addTitle'">
    </h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form name="chargingStationForm" [formGroup]="chargingStationForm">

        <div class="row">

            <!-- eaazeId -->
            <div class="col-12">
                <label class="form-label" for="externalId">{{ 'services.chargingStations.modal.externalId' | translate }}*</label>
                <input class="form-control" id="externalId" type="text" name="id" maxlength="50"
                       [formControl]="externalId"
                       pattern="{{ FormUtils.textInputPattern }}"
                       placeholder="{{ 'services.chargingStations.modal.externalId' | translate }}"
                       required>
                <app-form-error [control]="externalId"></app-form-error>
            </div>

            <!-- Name -->
            <div class="col-12 col-md-6">
                <label class="form-label" for="nameFR">{{ 'services.chargingStations.modal.nameFR' | translate }}*</label>
                <input class="form-control" id="nameFR" type="text" name="nameFR" maxlength="50"
                       [formControl]="nameFR"
                       pattern="{{ FormUtils.textInputPattern }}"
                       placeholder="{{ 'services.chargingStations.modal.nameFR' | translate }}"
                       required>
                <app-form-error [control]="nameFR"></app-form-error>
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <label class="form-label" for="nameDE">{{ 'services.chargingStations.modal.nameDE' | translate }}</label>
                <input class="form-control" id="nameDE" type="text" name="nameDE" maxlength="50"
                       [formControl]="nameDE"
                       pattern="{{ FormUtils.textInputPattern }}"
                       placeholder="{{ 'services.chargingStations.modal.nameDE' | translate }}">
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <label class="form-label" for="nameIT">{{ 'services.chargingStations.modal.nameIT' | translate }}</label>
                <input class="form-control" id="nameIT" type="text" name="nameIT" maxlength="50"
                       [formControl]="nameIT"
                       pattern="{{ FormUtils.textInputPattern }}"
                       placeholder="{{ 'services.chargingStations.modal.nameIT' | translate }}">
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <label class="form-label" for="nameEN">{{ 'services.chargingStations.modal.nameEN' | translate }}</label>
                <input class="form-control" id="nameEN" type="text" name="nameEN" maxlength="50"
                       [formControl]="nameEN"
                       pattern="{{ FormUtils.textInputPattern }}"
                       placeholder="{{ 'services.chargingStations.modal.nameEN' | translate }}">
            </div>

            <!-- Description -->
            <div class="col-12 col-md-6">
                <label class="form-label">{{ 'services.chargingStations.modal.descriptionFR' | translate }}</label>
                <textarea class="form-control" id="descriptionFR"
                          placeholder="{{ 'services.chargingStations.modal.descriptionFR' | translate }}"
                          maxLength="255" rows="3"
                          [formControl]="descriptionFR"
                          pattern="{{ FormUtils.textInputPattern }}"></textarea>
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <label class="form-label">{{ 'services.chargingStations.modal.descriptionDE' | translate }}</label>
                <textarea class="form-control" id="descriptionDE"
                          placeholder="{{ 'services.chargingStations.modal.descriptionDE' | translate }}"
                          maxLength="255" rows="3"
                          [formControl]="descriptionDE"
                          pattern="{{ FormUtils.textInputPattern }}"></textarea>
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <label class="form-label">{{ 'services.chargingStations.modal.descriptionIT' | translate }}</label>
                <textarea class="form-control" id="descriptionIT"
                          placeholder="{{ 'services.chargingStations.modal.descriptionIT' | translate }}"
                          maxLength="255" rows="3"
                          [formControl]="descriptionIT"
                          pattern="{{ FormUtils.textInputPattern }}"></textarea>
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <label class="form-label">{{ 'services.chargingStations.modal.descriptionEN' | translate }}</label>
                <textarea class="form-control" id="descriptionEN"
                          placeholder="{{ 'services.chargingStations.modal.descriptionEN' | translate }}"
                          maxLength="255" rows="3"
                          [formControl]="descriptionEN"
                          pattern="{{ FormUtils.textInputPattern }}"></textarea>
            </div>

        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>
    <button class="btn btn-primary" type="submit"
            [disabled]="chargingStationForm.invalid"
            (click)="addOrUpdateChargingStation()"
            translate="common.save">
    </button>
</div>

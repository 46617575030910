import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
    PaymentTypes
} from '../../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-payment-type-chooser',
    templateUrl: './payment-type-chooser.component.html'
})
export class PaymentTypeChooserComponent {

    @Input() availablePaymentTypes: PaymentTypes[];
    @Output() selectedPaymentType = new EventEmitter<PaymentTypes>();

    paymentTypeForm: UntypedFormGroup;

    constructor() {
        this.paymentTypeForm = new UntypedFormGroup({
            paymentType: new UntypedFormControl(null, Validators.required)
        }, {updateOn: 'change'});
    }

    reset(): void {
        this.paymentType.reset();
    }

    updatePaymentType(value: string): void {
        this.paymentType.setValue(value);
        this.selectedPaymentType.emit(PaymentTypes[value]);
    }

    get paymentType(): UntypedFormControl {
        return this.paymentTypeForm.get('paymentType') as UntypedFormControl;
    }
}

import {Component, Input} from '@angular/core';
import {
    ChargingStationDto,
    ChargingStationInputDto,
    ChargingStationService
} from '../../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {CustomerConfigService} from '../../../../_shared/customer-config-service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../../_shared/form-utils';

@Component({
    selector: 'app-charging-station-management-modal',
    templateUrl: './charging-station-management-modal.component.html'
})
export class ChargingStationManagementModalComponent {

    @Input() serviceId: string;
    @Input() chargingStation: ChargingStationDto | null;

    FormUtils = FormUtils;

    chargingStationForm: FormGroup<ChargingStationForm>;

    constructor(public activeModal: NgbActiveModal,
                private readonly chargingStationService: ChargingStationService,
                private readonly notificationService: NotificationsService,
                public readonly customerConfigService: CustomerConfigService) {

        this.chargingStationForm = new FormGroup<ChargingStationForm>({
                externalId: new FormControl<string>(null, [Validators.required, Validators.maxLength(50)]),
                nameFR: new FormControl<string>(null, [Validators.required, Validators.maxLength(50)]),
                nameDE: new FormControl<string>(null, Validators.maxLength(50)),
                nameIT: new FormControl<string>(null, Validators.maxLength(50)),
                nameEN: new FormControl<string>(null, Validators.maxLength(50)),
                descriptionFR: new FormControl<string>(null, Validators.maxLength(255)),
                descriptionDE: new FormControl<string>(null, Validators.maxLength(255)),
                descriptionIT: new FormControl<string>(null, Validators.maxLength(255)),
                descriptionEN: new FormControl<string>(null, Validators.maxLength(255)),
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        if (this.chargingStation) {
            this.externalId.setValue(this.chargingStation.externalId);
            this.nameFR.setValue(this.chargingStation.nameFR);
            this.nameDE.setValue(this.chargingStation.nameDE);
            this.nameIT.setValue(this.chargingStation.nameIT);
            this.nameEN.setValue(this.chargingStation.nameEN);
            this.descriptionFR.setValue(this.chargingStation.descriptionFR);
            this.descriptionDE.setValue(this.chargingStation.descriptionDE);
            this.descriptionIT.setValue(this.chargingStation.descriptionIT);
            this.descriptionEN.setValue(this.chargingStation.descriptionEN);
        }
    }

    public addOrUpdateChargingStation(): void {
        if (this.chargingStationForm.invalid) {
            return;
        }

        const chargingStationToSave = new ChargingStationInputDto({
            externalId: this.externalId.value,
            serviceId: this.serviceId,
            nameFR: this.nameFR.value,
            nameDE: this.nameDE.value,
            nameIT: this.nameIT.value,
            nameEN: this.nameEN.value,
            descriptionFR: this.descriptionFR.value,
            descriptionDE: this.descriptionDE.value,
            descriptionIT: this.descriptionIT.value,
            descriptionEN: this.descriptionEN.value,
        });

        if (this.chargingStation) {
            this.chargingStationService.updateChargingStation(this.chargingStation.id, chargingStationToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.chargingStations.notifications.updateSuccess'});
                this.activeModal.close('success');
            });
        }
        else {
            this.chargingStationService.createChargingStation(chargingStationToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.chargingStations.notifications.addSuccess'});
                this.activeModal.close('success');
            });
        }
    }

    get externalId(): FormControl<string> {
        return this.chargingStationForm.get('externalId') as FormControl<string>;
    }

    get nameFR(): FormControl<string> {
        return this.chargingStationForm.get('nameFR') as FormControl<string>;
    }

    get nameDE(): FormControl<string> {
        return (this.chargingStationForm.get('nameDE') as FormControl<string>);
    }

    get nameIT(): FormControl<string> {
        return this.chargingStationForm.get('nameIT') as FormControl<string>;
    }

    get nameEN(): FormControl<string> {
        return this.chargingStationForm.get('nameEN') as FormControl<string>;
    }

    get descriptionFR(): FormControl<string> {
        return this.chargingStationForm.get('descriptionFR') as FormControl<string>;
    }

    get descriptionDE(): FormControl<string> {
        return this.chargingStationForm.get('descriptionDE') as FormControl<string>;
    }

    get descriptionIT(): FormControl<string> {
        return this.chargingStationForm.get('descriptionIT') as FormControl<string>;
    }

    get descriptionEN(): FormControl<string> {
        return this.chargingStationForm.get('descriptionEN') as FormControl<string>;
    }
}

interface ChargingStationForm {
    externalId: FormControl<string>,
    nameFR: FormControl<string>,
    nameDE: FormControl<string>,
    nameIT: FormControl<string>,
    nameEN: FormControl<string>,
    descriptionFR: FormControl<string>,
    descriptionDE: FormControl<string>,
    descriptionIT: FormControl<string>,
    descriptionEN: FormControl<string>,
}

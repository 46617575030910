import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PublicLightingControlMode, PublicLightingControlService} from '../../../../../_services/configuration-services';
import {firstValueFrom, Subscription} from 'rxjs';
import {DateUtils} from '../../../../../_shared/date-utils';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime} from 'rxjs/operators';
import {round} from 'lodash';

@Component({
    selector: 'app-manage-light-zone-mode-modal',
    templateUrl: './manage-light-zone-mode-modal.component.html'
})
export class ManageLightZoneModeModalComponent implements OnInit, OnDestroy {

    @Input() lightZoneId: string;

    manageLightZoneModeForm: FormGroup<ManageLightZoneModeForm>;

    PublicLightingControlMode = PublicLightingControlMode;


    private durationSubscription : Subscription | null = null;

    constructor(public manageLightZoneModeModal: NgbActiveModal,
                private readonly publicLightingControlService: PublicLightingControlService,
                private readonly notificationsService: NotificationsService,
                private readonly translateService: TranslateService) {

        this.manageLightZoneModeForm = new FormGroup<ManageLightZoneModeForm>({
            mode: new FormControl(null, [Validators.required]),
            duration: new FormControl(null,
                [
                    Validators.required,
                    Validators.max(12),
                    Validators.min(0)
                ]
            ),
        });
    }

    ngOnInit(): void {
        this.durationSubscription = this.duration.valueChanges.pipe(debounceTime(500)).subscribe(value => {
                if (!value || isNaN(value)) {
                    return;
                }

                const roundValue = round(value, 2);
                if (roundValue !== value) {
                    this.duration.setValue(roundValue)
                }
            }
        );
    }

    ngOnDestroy(): void {
       this.durationSubscription?.unsubscribe();
    }

    get mode(): FormControl<PublicLightingControlMode | null> {
        return this.manageLightZoneModeForm.controls.mode;
    }

    get duration(): FormControl<number | null> {
        return this.manageLightZoneModeForm.controls.duration;
    }

    selectedMode($event: PublicLightingControlMode | null): void {
        this.mode.setValue($event);

        if ($event === PublicLightingControlMode.Automatic) {
            this.duration.removeValidators(Validators.required);
        } else {
            this.duration.addValidators(Validators.required);
        }

        this.duration.updateValueAndValidity();
    }

    async sendChangeModeOrder(): Promise<void> {
        if (this.manageLightZoneModeForm.valid) {

            let successMessageKey: string;


            switch (this.mode.value) {

                case PublicLightingControlMode.Automatic:
                    await firstValueFrom(this.publicLightingControlService.applyAutomaticModeForZoneId(this.lightZoneId));

                    successMessageKey = 'publicLighting.lightZone.manageMode.modal.notifications.setMode.withoutDurationSuccess';
                    break;

                case PublicLightingControlMode.ForceOff:
                    await firstValueFrom(this.publicLightingControlService.forceOffModeForZoneId(this.lightZoneId, DateUtils.convertHoursToSeconds(this.duration.value)));

                    successMessageKey = 'publicLighting.lightZone.manageMode.modal.notifications.setMode.withDurationSuccess';
                    break;

                case PublicLightingControlMode.ForceOn:
                    await firstValueFrom(this.publicLightingControlService.forceOnModeForZoneId(this.lightZoneId, DateUtils.convertHoursToSeconds(this.duration.value)));

                    successMessageKey = 'publicLighting.lightZone.manageMode.modal.notifications.setMode.withDurationSuccess';
                    break;

                default:
                    return;
            }

            const modeTranslation = this.translateService.instant('publicLighting.lightZone.enums.PublicLightingControlMode.' + this.mode.value);

            this.notificationsService.success({
                title: successMessageKey,
                interpolateParams: {
                    'mode': modeTranslation,
                    'duration': this.duration.value
                }
            });

            this.manageLightZoneModeModal.close();
        }
    }
}

interface ManageLightZoneModeForm {
    mode: FormControl<PublicLightingControlMode | null>,
    duration: FormControl<number | null>
}

<div *ngIf="chargingStations.length > 0">

    <h5 translate="services.chargingStations.cockpitTitle"></h5>

    <div class="d-table w-100">
        <div class="d-table-row" *ngFor="let station of chargingStations">
            <div class="d-table-cell">
                <p><strong>{{ station['name' + crtLang.toUpperCase()] }}</strong></p>
            </div>

            <div class="d-table-cell">
                <p *ngIf="station.numberOfEvse > 0">
                    <span translate="services.chargingStations.numberOfEvse"
                          [translateParams]="{ nbFree: station.numberOfFreeEvse, nbEvse: station.numberOfEvse }">
                    </span>
                    <fa-icon class="ms-2"
                             [icon]="icons.info"
                             [ngbPopover]="popoverTemplate" data-bs-trigger="hover focus"></fa-icon>
                </p>
                <p *ngIf="station.numberOfEvse === 0" translate="common.unknown"></p>

                <ng-template #popoverTemplate>
                    <p *ngFor="let status of station.evseStatuses | keyvalue">
                        <span class="dot gray-border"
                              [ngClass]="{
                                'green': status.value === ChargingStationEvseStatus.Free,
                                'red': status.value === ChargingStationEvseStatus.Error,
                                'yellow': status.value === ChargingStationEvseStatus.Occupied,
                                'gray': status.value === ChargingStationEvseStatus.Unknown }">
                        </span>
                        {{ 'services.chargingStations.plug' | translate }}
                        {{ ' ' + status.key + ': ' }}
                        {{ 'common.evseStatus.' + status.value | translate }}
                    </p>
                </ng-template>
            </div>

            <div class="d-table-cell" *ngIf="rolesService.hasRoleAdmin()">
                <div ngbDropdown>
                    <button class="btn btn-outline-secondary" translate="services.chargingStations.liberatePlug"
                            type="button"
                            ngbDropdownToggle></button>

                    <div ngbDropdownMenu>
                        <div *ngFor="let status of station.evseStatuses | keyvalue">
                            <button ngbDropdownItem
                                (click)="freePlug(station.id, status.key)">
                                {{ status.key }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="chargingStations.length === 0">
        <p translate="services.chargingStations.noDataAvailable"></p>
    </div>
</div>




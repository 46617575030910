
<!-- Filters -->
<div class="row">

    <div class="col-lg-4">
        <label class="form-label" for="searchMeasuringPoint" translate="services.energy.cockpit.filter.label.measuringPoint"></label>
        <app-search-measuring-point id="searchMeasuringPoint"
                                    [serviceId]="serviceId"
                                    (selectedMeasuringPointEvent)="measuringPointSelected($event)"
                                    [measuringPointTypes]="[measuringPointTypeAdmin]"
                                    [defaultMeasuringPoint]="measuringPoint"></app-search-measuring-point>
    </div>

</div>

<div *ngIf="!measuringPoint" class="text-center">
    <em translate="services.energy.cockpit.message.selectMeasuringPoint"></em>
</div>

<div *ngIf="measuringPoint">

    <h4 class="text-center" translate="services.energy.cockpit.state.title"></h4>
    <h5 class="text-center">{{ measuringPoint['name' + crtLang.toUpperCase()] }} ({{ measuringPoint.hardwareName }})</h5>

    <div *ngIf="cockpitData" class="mt-5">

        <div class="d-flex flex-column flex-md-row justify-content-center">

            <app-cockpit-battery [chargeLimit]="batteryChargeLimit"
                                 [currentValue]="batteryCurrentValue"
                                 [valueMax]="batteryValueMax"></app-cockpit-battery>

            <div class="d-flex flex-column">

                <div class="d-flex flex-row justify-content-between gap-3">
                    <p class="title-label" translate="services.energy.cockpit.label.setupIntensity"></p>

                    <p>{{ 'services.energy.cockpit.value.intensity_A' | translate: {
                        value: configuredIntensityLimit
                    } }}</p>
                </div>

                <div class="d-flex flex-row justify-content-between gap-3">
                    <p class="title-label" translate="services.energy.cockpit.label.actualIntensity"></p>

                    <p>{{ 'services.energy.cockpit.value.intensity_A' | translate: {
                        value: actualIntensity
                    } }}</p>
                </div>

                <div class="d-flex flex-row justify-content-between gap-3">
                    <p class="title-label" translate="services.energy.cockpit.label.actualPower"></p>
                    <p>{{ 'services.energy.cockpit.value.power_kW' | translate: {
                        value: actualPower
                    } }}</p>
                </div>

                <div class="d-flex flex-row justify-content-between gap-3">

                    <p class="title-label" translate="services.energy.cockpit.label.maxIntensity15Min"></p>

                    <p>{{ 'services.energy.cockpit.value.intensity_A' | translate: {
                        value: intensityMax15Min
                    } }}</p>
                </div>

                <div class="d-flex flex-row justify-content-between gap-3">
                    <p class="more-info" *ngIf="cockpitData.maxIntensity15Min?.lastResetDateTime">
                        <span translate="services.energy.cockpit.message.lastReset"></span>
                        <br />
                        <span>{{'services.energy.cockpit.value.date' | translate: { date: formatDate(cockpitData.maxIntensity15Min.lastResetDateTime) } }}</span>
                    </p>
                </div>

                <div class="d-flex flex-row justify-content-end gap-3">
                    <button class="btn btn-primary"
                            (click)="resetMaxIntensity()"
                            translate="services.energy.cockpit.state.resetBtn"></button>
                </div>

            </div>

        </div>

    </div>

    <div *ngIf="!cockpitData" class="text-center">
        <p translate="services.energy.cockpit.message.noDataAvailable"></p>
    </div>
</div>


<div class="content-container bg-white">

    <h1 translate="communicationChannel.information.title.view"></h1>

    <div class="btn-bar" [appHasAnyRole]="[AppRoles.admin]">
        <button class="btn btn-primary" [routerLink]="['manage']">
            <span translate="communicationChannel.information.btn.manageInformations"></span>
        </button>
    </div>

    <h5 *ngIf="informationArray.length === 0" translate="communicationChannel.information.empty"></h5>

    <!-- Information List -->
    <div class="communication-channel-list-container" *ngIf="informationArray.length > 0">

        <div class="list-item-container" *ngFor="let information of informationArray"
             (click)="readInformation(information.id)">

            <div class="icon-container">
                <img class="icon" src="../assets/icons/communication_channel_information.svg" alt="icon" height="60px" width="auto">
            </div>

            <div class="content-container">
                <h3 translate="">{{ information['title' + crtLang.toUpperCase()] }}</h3>
                <h6>{{ information.publicationDate | date }}</h6>
            </div>

        </div>
    </div>

</div>


<form [formGroup]="searchEquipmentEventForm">
    <div class="row">

        <div class="col-12">
            <label class="form-label" for="searchEquipmentEventTerms" translate="equipments.events.search.label"></label>
            <input class="form-control col-sm-9" id="searchEquipmentEventTerms" name="searchEquipmentEventTerms"
                   formControlName="searchTerms" type="text"
                   pattern="{{textInputPattern}}"
                   placeholder="{{ 'equipments.events.search.placeholder' | translate }}">
        </div>

    </div>
</form>

<full-calendar class="mt-4" #fullcalendar *ngIf="calendarOptions"
    [options]="calendarOptions"></full-calendar>


<ng-template #popoverTmpl let-event="event">
    <h6>{{event.extendedProps.equipmentEvent.equipmentName }}</h6>
    <p>{{ event.start  | date: 'HH:mm' }} - {{ event.end  | date: 'HH:mm' }} </p>
    <p>
        <span>{{ event.extendedProps.equipmentEvent.description }}</span>
    </p>
</ng-template>

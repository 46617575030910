<div class="content-container bg-white">
    <h1>{{ (!serviceId ? 'services.manage.createServiceTitle' : 'services.manage.editServiceTitle') | translate }}</h1>

    <ng-container *ngIf="!!serviceConfig">

        <div [appHasAnyRole]="[AppRoles.admin]">
            <div class="btn-bar">
                <button class="btn btn-outline-secondary"
                        (click)="backToServiceList()" translate="services.actions.back">
                </button>

                <button class="btn btn-outline-danger" *ngIf="!!serviceId"
                        (click)="deleteService()"
                        translate="services.actions.delete">
                </button>
            </div>
        </div>

        <form [formGroup]="serviceForm" name="serviceForm" [appHasAnyRole]="[AppRoles.admin]">

            <div class="row">

                <!-- serviceId -->
                <div class="col-12 col-md-6" *ngIf="service?.serviceId">
                    <label class="form-label" for="serviceId">{{ 'services.manage.serviceId' | translate }}</label>
                    <input class="form-control " id="serviceId" type="text"
                           pattern="{{ FormUtils.textInputPattern }}"
                           placeholder="{{ service.serviceId }}"
                           formControlName="serviceId">
                </div>

                <!-- serviceState -->
                <div class="col-12 col-md-3">
                    <label class="form-label" translate="services.view.state"></label>
                    <div class="form-check form-switch">
                        <input class="form-check-input" id="state" type="checkbox" name="state"
                               formControlName="serviceState">
                        <label class="form-check-label" for="state">
                            {{ (serviceState.value ? 'services.view.available' : 'services.view.unavailable') | translate }}
                        </label>
                    </div>
                </div>

                <!-- Bookable -->
                <div class="col-12 col-md-3" *ngIf="canConfigureBookable()">
                    <label class="form-label" translate="services.bookable"></label>
                    <div class="form-check form-switch">
                        <input class="form-check-input" id="bookable" type="checkbox" name="bookable"
                               formControlName="bookable">
                        <label class="form-check-label" for="bookable">
                            {{ (bookable.value ? 'common.yes' : 'common.no') | translate }}
                        </label>
                    </div>
                </div>

                <!-- Category -->
                <div class="col-12 col-md-6">
                    <label class="form-label" for="category">{{ 'services.manage.category' | translate }}*</label>
                    <select class="form-control form-select" id="category" name="category" formControlName="category">
                        <option *ngFor="let cat of categories;"
                                [value]="cat.id">{{cat['name' + this.crtLang.toLocaleUpperCase()]}}</option>
                    </select>
                    <app-form-error [control]="category"></app-form-error>
                </div>

                <!-- Code -->
                <div class="col-12 col-md-6">
                    <label class="form-label" for="code">{{ 'services.manage.code' | translate }}*</label>
                    <input class="form-control" id="code" name="code" type="text"
                           pattern="{{FormUtils.textInputPattern}}"
                           maxlength="3"
                           placeholder="{{ 'services.placeholder.code' | translate }}"
                           onkeyup="this.value = this.value.toUpperCase();"
                           formControlName="code">
                    <app-form-error [control]="code"></app-form-error>
                </div>

            </div>
            <hr class="mt-4 mb-4">

            <!-- Names -->
            <div class="row">

                <div class="col-12 col-md-6">
                    <label class="form-label" for="nameFR">{{ 'services.manage.nameFR' | translate }}*</label>
                    <input class="form-control" id="nameFR" name="nameFR" type="text"
                           pattern="{{ FormUtils.textInputPattern }}"
                           maxlength="25"
                           placeholder="{{ 'services.placeholder.nameFR' | translate }}"
                           formControlName="nameFR"/>
                    <app-form-error [control]="nameFR"></app-form-error>
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                    <label class="form-label" for="nameDE">{{ 'services.manage.nameDE' | translate }}</label>
                    <input id="nameDE" type="text" class="form-control" name="nameDE"
                           pattern="{{ FormUtils.textInputPattern }}"
                           maxlength="25"
                           placeholder="{{ 'services.placeholder.nameDE' | translate }}"
                           formControlName="nameDE"/>
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                    <label class="form-label" for="nameIT">{{ 'services.manage.nameIT' | translate }}</label>
                    <input id="nameIT" type="text" class="form-control" name="nameIT"
                           pattern="{{ FormUtils.textInputPattern }}" maxlength="25"
                           placeholder="{{ 'services.placeholder.nameIT' | translate }}"
                           formControlName="nameIT"/>
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                    <label class="form-label" for="nameEN">{{ 'services.manage.nameEN' | translate }}</label>
                    <input id="nameEN" type="text" class="form-control" name="nameEN"
                           pattern="{{ FormUtils.textInputPattern }}"
                           maxlength="25"
                           placeholder="{{ 'services.placeholder.nameEN' | translate }}"
                           formControlName="nameEN"/>
                </div>

            </div>
            <hr class="mt-4 mb-4">

            <!-- Description -->
            <div class="row">
                <div class="col-12 col-md-6">
                    <label class="form-label" for="descriptionFR">{{ 'services.manage.descriptionFR' | translate }}</label>
                    <textarea id="descriptionFR" type="textarea" class="form-control"
                              name="descriptionFR"
                              maxlength="1000"
                              rows="3"
                              placeholder="{{ 'services.placeholder.descriptionFR' | translate }}"
                              formControlName="descriptionFR"></textarea>
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                    <label class="form-label" for="descriptionDE">{{ 'services.manage.descriptionDE' | translate }}</label>
                    <textarea id="descriptionDE" type="text" class="form-control"
                              pattern="{{FormUtils.textInputPattern}}"
                              name="descriptionDE"
                              maxlength="1000"
                              rows="3"
                              placeholder="{{ 'services.placeholder.descriptionDE' | translate }}"
                              formControlName="descriptionDE"></textarea>
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                    <label class="form-label" for="descriptionIT">{{ 'services.manage.descriptionIT' | translate }}</label>
                    <textarea id="descriptionIT" type="text" class="form-control"
                              name="descriptionIT"
                              pattern="{{FormUtils.textInputPattern}}"
                              maxlength="1000"
                              rows="3"
                              placeholder="{{ 'services.placeholder.descriptionIT' | translate }}"
                              formControlName="descriptionIT"></textarea>
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                    <label class="form-label" for="descriptionEN">{{ 'services.manage.descriptionEN' | translate }}</label>
                    <textarea id="descriptionEN" type="text" class="form-control"
                              pattern="{{FormUtils.textInputPattern}}"
                              name="descriptionEN"
                              maxlength="1000"
                              rows="3"
                              placeholder="{{ 'services.placeholder.descriptionEN' | translate }}"
                              formControlName="descriptionEN"></textarea>
                </div>

            </div>
            <hr class="mt-4 mb-4">

            <!-- Instruction -->
            <div class="row">
                <div class="col-12 col-md-6">
                    <label class="form-label" for="instructionFR">{{ 'services.manage.instructionFR' | translate }}</label>
                    <textarea id="instructionFR" type="text" class="form-control"
                              name="instructionFR"
                              pattern="{{FormUtils.textInputPattern}}"
                              maxlength="1000"
                              rows="3"
                              placeholder="{{ 'services.placeholder.instructionFR' | translate }}"
                              formControlName="instructionFR"></textarea>
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                    <label class="form-label" for="instructionDE">{{ 'services.manage.instructionDE' | translate }}</label>
                    <textarea id="instructionDE" type="text" class="form-control"
                              name="instructionDE"
                              maxlength="1000"
                              rows="3"
                              placeholder="{{ 'services.placeholder.instructionDE' | translate }}"
                              formControlName="instructionDE"></textarea>
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                    <label class="form-label" for="instructionIT">{{ 'services.manage.instructionIT' | translate }}</label>
                    <textarea id="instructionIT" type="text" class="form-control"
                              name="instructionIT"
                              maxlength="1000"
                              rows="3"
                              placeholder="{{ 'services.placeholder.instructionIT' | translate }}"
                              formControlName="instructionIT"></textarea>
                </div>

                <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                    <label class="form-label" for="instructionEN">{{ 'services.manage.instructionEN' | translate }}</label>
                    <textarea id="instructionEN" type="text" class="form-control"
                              name="instructionEN"
                              maxlength="1000"
                              rows="3"
                              placeholder="{{ 'services.placeholder.instructionEN' | translate }}"
                              formControlName="instructionEN"></textarea>
                </div>

            </div>

            <!-- Activation fields -->
            <div *ngIf="canConfigureActivation()">
                <hr class="mt-4 mb-4">
                <h4 translate="services.manage.activationSubtitle"></h4>

                <div class="row">

                    <!-- Authentication Method -->
                    <div class="col-12 col-md-6">
                        <label class="form-label" for="authenticationMethod">{{ 'services.manage.authenticationMethod' | translate }}</label>
                        <ng-multiselect-dropdown id="authenticationMethod" style="width: 100%;"
                                                 placeholder="{{ 'common.multiselect.placeholder' | translate }}"
                                                 [formControl]="authenticationMethod"
                                                 [settings]="authenticationMethodDropdownSettings"
                                                 [data]="authenticationMethodDropdownSources">
                        </ng-multiselect-dropdown>
                    </div>

                    <!-- Minimal Wallet Balance -->
                    <div class="col-12 col-md-6" *ngIf="canConfigureMinimalWalletBalance()">
                        <label class="form-label" for="minimalWalletBalanceRequired">{{ 'services.manage.minimalWalletBalanceRequired' | translate }}*</label>
                        <div class="input-group">
                            <span class="input-group-text">CHF</span>
                            <input class="form-control" id="minimalWalletBalanceRequired"
                                   name="minimalWalletBalanceRequired"
                                   type="number"
                                   (keydown)="FormUtils.validateInputNumberLength($event, 10)"
                                   placeholder="{{ 'services.placeholder.minimalWalletBalanceRequired' | translate }}"
                                   formControlName="minimalWalletBalanceRequired">
                        </div>
                        <app-form-error [control]="minimalWalletBalanceRequired"></app-form-error>
                    </div>

                    <!-- Amount to reserve for the consumption of a service -->
                    <div class="col-12 col-md-6" *ngIf="canConfigureAmountToReserveForServiceConsumption()">
                        <label class="form-label" for="amountToReserveForServiceConsumption">{{ 'services.manage.amountToReserveForServiceConsumption' | translate }}*</label>
                        <div class="input-group">
                            <span class="input-group-text">CHF</span>
                            <input class="form-control" id="amountToReserveForServiceConsumption"
                                   name="amountToReserveForServiceConsumption"
                                   type="number"
                                   (keydown)="FormUtils.validateInputNumberLength($event, 10)"
                                   placeholder="{{ 'services.placeholder.amountToReserveForServiceConsumption' | translate }}"
                                   formControlName="amountToReserveForServiceConsumption">
                        </div>
                        <app-form-error [control]="amountToReserveForServiceConsumption"></app-form-error>
                    </div>

                    <!-- Impulse duration -->
                    <ng-container *ngIf="canConfigureImpulseDuration()">

                        <div class="col-12 col-md-6">
                            <label class="form-label" for="impulseDuration">{{ 'services.impulseDuration' | translate }}<span *ngIf="serviceConfig.serviceType === ServiceType.TimeLimited">*</span></label>
                            <input class="form-control"
                                   id="impulseDuration" type="number"
                                   pattern="^[0-9]*$"
                                   (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                                   name="impulseDuration" min="1" max="{{ maxImpulseDuration?.value }}"
                                   formControlName="impulseDuration">

                            <app-form-error [control]="impulseDuration"></app-form-error>
                        </div>

                        <div class="col-12 col-md-6">
                            <label class="form-label" for="impulseDurationUnit">{{ 'services.impulseDurationUnit' | translate }}<span *ngIf="serviceConfig.serviceType === ServiceType.TimeLimited">*</span></label>
                            <select class="form-control form-select" name="impulseDurationUnit" id="impulseDurationUnit"
                                    formControlName="impulseDurationUnit">
                                <option *ngFor="let impulseDurationUnit of impulseDurationUnits;"
                                        [ngValue]="impulseDurationUnit">{{ 'common.impulseDurationUnit.' + impulseDurationUnit | translate }}</option>
                            </select>
                        </div>

                        <div class="col-12 col-md-6" *ngIf="serviceConfig.serviceType === ServiceType.TimeLimited">
                            <label class="form-label" for="maxImpulseDuration">{{ 'services.maxImpulseDuration' | translate }}*</label>
                            <input class="form-control"
                                   id="maxImpulseDuration" type="number"
                                   pattern="^[0-9]*$"
                                   (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                                   name="maxImpulseDuration" min="{{ impulseDuration.value }}"
                                   formControlName="maxImpulseDuration">
                            <app-form-error [control]="maxImpulseDuration"></app-form-error>
                        </div>


                    </ng-container>

                </div>
            </div>

            <!-- ROLES -->
            <div *ngIf="canConfigureRoles()">
                <hr class="mt-4 mb-4">
                <h4 translate="services.roles.role"></h4>

                <app-role-management
                    [enablePublicAccess]="canConfigurePublicRole()"
                    [enableBaseRoles]="true"
                    [enableCustomRoles]="true"
                    [hideCustomIfUserSelected]="true"
                    [userOnly]="serviceConfig.serviceType === ServiceType.UserAlarm"
                    [roleForm]="rolesFormArray"></app-role-management>
            </div>

            <div class="btn-bar">
                <button class="btn btn-outline-secondary"
                        (click)="backToServiceList()"
                        translate="services.actions.back">
                </button>
                <button class="btn btn-primary" type="submit"
                        [disabled]="!(serviceForm.valid && isFormValid())"
                        (click)="createOrEditService()"
                        translate="common.save">
                </button>
            </div>
        </form>

    </ng-container>

    <ng-container *ngIf="service">

        <div #serviceChildrenSection>

            <ng-container *ngIf="rolesService.hasRoleAdmin()">

                <!-- Image -->
                <hr class="mt-4 mb-4">
                <div id="sectionImage">
                    <h4 translate="services.manage.image"></h4>

                    <p [translateParams]="{ImageMaxSize: imageMaxSizeInKB}"
                       translate="common.imageMaxSize"></p>

                    <div class="row">
                        <div class="col-12">
                            <input type="file" accept="{{supportedImage}}"
                                   (change)="fileSelected($event.target['files'])"
                                   [disabled]="service === null || service.serviceId === null">
                            <p class="text-danger font-italic" *ngIf="!imageValid"
                               [translateParams]="{ImageMaxSize: imageMaxSizeInKB}"
                               translate="services.manage.imageToBig"></p>
                        </div>
                    </div>

                    <div class="btn-bar">
                        <button class="btn btn-primary"
                                [disabled]="!imageToUpload || (service === null || service.serviceId === null)"
                                (click)="uploadImage()"
                                translate="common.saveIllustration">
                        </button>
                    </div>

                    <div class="row" *ngIf="imageUrl">
                        <div class="col-6">
                            <img class="img-thumbnail" src="{{imageUrl}}" alt="Illustration">
                        </div>
                    </div>

                </div>

                <!-- TARIFF -->
                <ng-container *ngIf="canConfigureTariffs()">
                    <hr class="mt-4 mb-4">
                    <app-tariff-management [serviceConfig]="serviceConfig"
                                           [serviceId]="serviceId">
                    </app-tariff-management>
                </ng-container>

                <!-- Availability Range -->
                <ng-container *ngIf="canConfigureAvailabilityRanges()">
                    <hr class="mt-4 mb-4">
                    <app-availability-range [serviceId]="serviceId"></app-availability-range>
                </ng-container>

                <!-- Booking Configuration -->
                <ng-container *ngIf="service.bookable && bookable.value">
                    <!-- check "service.bookable" to ensure we save the service before when we activate bookable feature -->

                    <hr class="mt-4 mb-4">
                    <h4 translate="services.bookingSubtitle"></h4>

                    <app-booking-service-configuration [serviceId]="serviceId"></app-booking-service-configuration>

                </ng-container>

                <!-- ITEMS -->
                <ng-container *ngIf="service.bookable && bookable.value">
                    <hr class="mt-4 mb-4">
                    <app-item-management [serviceId]="serviceId"></app-item-management>
                </ng-container>

                <!-- HYDROWASH -->
                <ng-container *ngIf="serviceConfig.serviceType === serviceType.Hydrowash">
                    <hr class="mt-4 mb-4">
                    <app-hydrowash-management></app-hydrowash-management>
                </ng-container>

                <!-- WIFI -->
                <ng-container *ngIf="serviceConfig.serviceType === serviceType.Wifi">
                    <hr class="mt-4 mb-4">
                    <app-wifi-management [service]="service"></app-wifi-management>
                </ng-container>

                <!-- Door Groups -->
                <ng-container  *ngIf="isAccessControlServiceType()">
                    <hr class="mt-4 mb-4">
                    <app-door-group [serviceId]="serviceId"></app-door-group>
                </ng-container>

                <!-- Map -->
                <ng-container *ngIf="canConfigureMap()">
                    <hr class="mt-4 mb-4">
                    <app-manage-map [serviceId]="serviceId"></app-manage-map>
                </ng-container>

                <!-- Mooring Places -->
                <ng-container *ngIf="serviceConfig.serviceType === serviceType.MooringPlace">
                    <hr class="mt-4 mb-4">
                    <app-edit-mooring-place [serviceId]="serviceId"></app-edit-mooring-place>
                </ng-container>

                <!-- Public Lighting -->
                <ng-container *ngIf="serviceConfig.serviceType === serviceType.PublicLighting">
                    <hr class="mt-4 mb-4">
                    <app-public-lighting-management [serviceId]="serviceId"></app-public-lighting-management>
                </ng-container>

                <!-- MEASURING POINTS - USER -->
                <div [appHasAnyRole]="[AppRoles.admin]" *ngIf="canConfigureUserMeasuringPoint()">
                    <hr class="mt-4 mb-4">
                    <h4 translate="measuringPoints.userPointTitle"></h4>

                    <div class="btn-bar">
                        <button class="btn btn-primary"
                                (click)="assignNewMeasuringPoint(userMeasuringPointType)"
                                translate="measuringPoints.actions.assignToService">
                        </button>
                    </div>

                    <p class="text-center" *ngIf="userMeasuringPoints.length > 0"
                       [translateParams]="{MaxItemCount: 20}"
                       translate="measuringPoints.table.itemLimit"></p>

                    <div class="row" *ngIf="keepDisplayUserMeasuringPointTable || userMeasuringPoints.length > 0">
                        <div class="col-12 col-md-6">
                            <label class="form-label" for="searchUserMeasuringPoint" translate="measuringPoints.table.filter.measuringPoint"></label>
                            <app-search-measuring-point id="searchUserMeasuringPoint"
                                                        (selectedMeasuringPointEvent)="searchByUserMeasuringPointId($event)"
                                                        [measuringPointTypes]="[userMeasuringPointType]"
                                                        [serviceId]="service.serviceId"></app-search-measuring-point>
                        </div>

                        <div class="col-12 col-md-6">
                            <label class="form-label" for="searchUser" translate="measuringPoints.table.filter.user"></label>
                            <app-search-user id="searchUser"
                                             (selectedUserEvent)="searchByUserId($event)"></app-search-user>
                        </div>
                    </div>

                    <div class="table-responsive mt-4">
                        <table class="table table-striped"
                               [attr.aria-label]="'measuringPoints.userPointTitle' | translate">
                            <thead>
                            <tr>
                                <th scope="col" style="min-width: 300px"
                                    translate="measuringPoints.table.name"></th>
                                <th scope="col" style="min-width: 300px" *ngIf="hasRoleAdmin()"
                                    translate="measuringPoints.table.user">
                                </th>
                                <th scope="col" style="min-width: 150px"
                                    translate="measuringPoints.table.state">
                                </th>
                                <th scope="col" style="min-width:125px"></th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr *ngIf="userMeasuringPoints.length === 0">
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td></td>
                            </tr>

                            <tr *ngFor="let measuringPoint of userMeasuringPoints">
                                <td>{{ printMeasuringPointName(measuringPoint) }}</td>

                                <td *ngIf="hasRoleAdmin()">{{ printMeasuringPointUser(measuringPoint.user) }}</td>

                                <td>{{ 'common.measuringPointUsageState.' + measuringPoint.measuringPointUsageState | translate }}</td>

                                <td class="text-end">
                                    <button class="btn btn-link btn-sm" [appHasAnyRole]="[AppRoles.admin]"
                                            (click)="editMeasuringPointUserAssignation(measuringPoint)"
                                            title="{{ (measuringPoint.user ? 'measuringPoints.tooltip.reassignUser' : 'measuringPoints.tooltip.assignUser') | translate }}">
                                        <fa-icon *ngIf="measuringPoint.user" [icon]="icons.userEdit"></fa-icon>
                                        <fa-icon *ngIf="!measuringPoint.user" [icon]="icons.userPlus"></fa-icon>
                                    </button>

                                    <button class="btn btn-link btn-sm" [appHasAnyRole]="[AppRoles.admin]"
                                            [disabled]="!measuringPoint.user"
                                            (click)="unassignMeasuringPointFromUser(measuringPoint)"
                                            title="{{'measuringPoints.tooltip.unassignUser' | translate}}">
                                        <fa-icon [icon]="icons.userSlash"></fa-icon>
                                    </button>

                                    <button class="btn btn-link btn-sm"
                                            [disabled]="measuringPoint.user"
                                            (click)="unassignMeasuringPointFromService(measuringPoint)"
                                            title="{{'measuringPoints.tooltip.unassign' | translate}}">
                                        <fa-icon [icon]="icons.trash"></fa-icon>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- CRANING - Craning services -->
                <ng-container *ngIf="serviceConfig.serviceType === serviceType.CraningAndWinterizing">
                    <hr class="mt-4 mb-4">
                    <app-craning-service-management [serviceId]="serviceId"></app-craning-service-management>
                </ng-container>

            </ng-container>

            <!-- MEASURING POINTS - ADMIN -->
            <ng-container *ngIf="rolesService.hasRoleConfigurator() && canConfigureAdminMeasuringPoint()">
                <hr class="mt-4 mb-4">
                <h4 translate="measuringPoints.adminPointTitle"></h4>
                <h5 ngSwitch="{{crtLang}}">
                    <span *ngSwitchCase="'fr'">{{ service.nameFR }}</span>
                    <span *ngSwitchCase="'de'">{{ service.nameDE }}</span>
                    <span *ngSwitchCase="'it'">{{ service.nameIT }}</span>
                    <span *ngSwitchCase="'en'">{{ service.nameEN }}</span>
                </h5>

                <div class="btn-bar">
                    <button class="btn btn-primary"
                            (click)="assignNewMeasuringPoint(adminMeasuringPointType)"
                            translate="measuringPoints.actions.assignToService">
                    </button>
                </div>

                <p class="text-center" *ngIf="adminMeasuringPoints.length > 0"
                   [translateParams]="{MaxItemCount: 20}"
                   translate="measuringPoints.table.itemLimit"></p>

                <div class="row" *ngIf="adminMeasuringPoints.length > 0">
                    <div class="col-12 col-md-6">
                        <label class="form-label" for="searchMeasuringPoint" translate="measuringPoints.table.filter.measuringPoint"></label>
                        <app-search-measuring-point id="searchMeasuringPoint"
                                                    (selectedMeasuringPointEvent)="searchByAdminMeasuringPointId($event)"
                                                    [measuringPointTypes]="[adminMeasuringPointType]"
                                                    [serviceId]="service.serviceId"></app-search-measuring-point>
                    </div>
                </div>

                <div class="table-responsive mt-4">
                    <table class="table table-striped"
                           [attr.aria-label]="'measuringPoints.adminPointTitle' | translate">
                        <thead>
                        <tr>
                            <th scope="col" style="min-width: 400px" translate="measuringPoints.table.name"></th>
                            <th scope="col" style="min-width: 400px" translate="measuringPoints.table.state"></th>
                            <th scope="col" style="min-width:125px"></th>
                        </tr>
                        </thead>

                        <tbody>

                        <tr *ngIf="adminMeasuringPoints.length === 0">
                            <td>-</td>
                            <td>-</td>
                            <td></td>
                        </tr>

                        <tr *ngFor="let adminMeasuringPoint of adminMeasuringPoints">
                            <td>{{ printMeasuringPointName(adminMeasuringPoint) }}</td>

                            <td>{{ 'common.measuringPointUsageState.' + adminMeasuringPoint.measuringPointUsageState | translate }}</td>

                            <td class="text-end">
                                <button class="btn btn-link btn-sm"
                                        (click)="openQrCode(adminMeasuringPoint)"
                                        title="{{ 'common.tooltip.displayQrCode' | translate }}">
                                    <fa-icon [icon]="icons.qrcode"></fa-icon>
                                </button>
                                <button class="btn btn-link btn-sm"
                                        (click)="unassignMeasuringPointFromService(adminMeasuringPoint)"
                                        title="{{ 'measuringPoints.tooltip.unassign' | translate }}">
                                    <fa-icon [icon]="icons.trash"></fa-icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </ng-container>

            <!-- WEBCAM -->
            <ng-container *ngIf="serviceConfig.serviceType === serviceType.Webcam">
                <hr class="mt-4 mb-4">
                <app-webcam-management [serviceId]="serviceId"></app-webcam-management>
            </ng-container>

            <!-- Point Of Sale -->
            <ng-container *ngIf="serviceConfig.serviceType === serviceType.PointOfSale">
                <hr class="mt-4 mb-4">
                <app-point-of-sale-management [serviceId]="serviceId"></app-point-of-sale-management>
            </ng-container>

            <!-- Koco Dumpster -->
            <ng-container *ngIf="serviceConfig.serviceType === serviceType.KocoDumpster || serviceConfig.serviceType === serviceType.FalconicDumpster">
                <hr class="mt-4 mb-4">
                <app-koco-dumpster-management [serviceId]="serviceId"></app-koco-dumpster-management>
            </ng-container>

            <!-- Charging Station -->
            <ng-container *ngIf="serviceConfig.serviceType === serviceType.ChargingStations">
                <hr class="mt-4 mb-4">
                <app-charging-station-management [serviceId]="serviceId"></app-charging-station-management>
            </ng-container>

            <div class="btn-bar">
                <button class="btn btn-outline-secondary"
                        (click)="backToServiceList()"
                        translate="services.actions.back">
                </button>
            </div>
        </div>
    </ng-container>
</div>

import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    ISortCriteriaDto,
    LightCriteriaDto,
    LightSearchCriteriaDto,
    LightWithStateDto,
    MapDto, MapProjectionDto,
    MapService,
    PageableRequestDtoOfLightCriteriaDto,
    PagedResultDtoOfLightTableDto,
    PublicLightingExternalState,
    PublicLightingLightService,
    SortCriteriaDto,
    SortDirection
} from '../../../../_services/configuration-services';
import {FormControl, UntypedFormGroup} from '@angular/forms';
import {FormUtils} from '../../../../_shared/form-utils';
import {firstValueFrom, from, merge, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewLightModalComponent} from './view-light-modal/view-light-modal.component';
import {faEye} from '@fortawesome/free-solid-svg-icons';
import * as d3 from 'd3';
import {MapUtils} from '../../../../_shared/map-utils';

@Component({
  selector: 'app-view-lights',
  templateUrl: './view-lights.component.html'
})
export class ViewLightsComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() serviceId: string;

    map: MapDto | null = null;
    private mainContainer: any;
    private projection: MapProjectionDto;

    lightsWithState: LightWithStateDto[] = [];
    lightsForTable: PagedResultDtoOfLightTableDto = new PagedResultDtoOfLightTableDto();

    filterForm: UntypedFormGroup;
    filterNotEmpty = false;
    mapIsLoading = false;

    pageRequest: PageableRequestDtoOfLightCriteriaDto;

    crtPage= 0;
    readonly pageSize = 10;
    readonly defaultSortProperty = 'code';

    readonly faEye = faEye;
    textInputPattern = FormUtils.textInputPattern;

    private searchTermsSubscription: Subscription;

    crtLang: string;
    private languageSubscription: Subscription;

    constructor(private readonly lightService: PublicLightingLightService,
                private readonly mapService: MapService,
                private readonly translateService: TranslateService,
                private readonly modalService: NgbModal) {

        this.pageRequest = this.setDefaultLightPageCriteria();
        this.pageRequest.criteria = this.setDefaultLightCriteria();

        this.filterForm = new UntypedFormGroup({
            code: new FormControl<string>(null),
            description: new FormControl<string>(null),
            lightZoneCode: new FormControl<string>(null)
        });
    }

    async ngOnInit(): Promise<void> {
        this.crtLang = this.translateService.currentLang;
        this.languageSubscription = this.translateService.onLangChange.subscribe(langChangeEvent => {
            this.crtLang = langChangeEvent.lang;
            this.clearFilter();
        });

        this.searchTermsSubscription = (
            merge(
                this.code.valueChanges,
                this.description.valueChanges,
                this.lightZoneCode.valueChanges
            ))
            .pipe(
                distinctUntilChanged(),
                debounceTime(500),
                switchMap(() => from(this.searchCriteriaChanged()))
            )
            .subscribe();

        await this.searchLights();
    }

    async ngAfterViewInit(): Promise<void> {
        this.map = await firstValueFrom(this.mapService.getMap(this.serviceId));

        if (this.map) {
            await this.setupMap();
            this.refreshMapData().then(/* Nothing to do & no need to wait the result */);
        }
    }

    ngOnDestroy(): void {
        this.languageSubscription.unsubscribe();
        this.searchTermsSubscription.unsubscribe();
    }

    async refreshMapData(): Promise<void> {
        this.mapIsLoading = true;
        this.lightsWithState = await firstValueFrom(this.lightService.getLightsWithStateByServiceId(this.serviceId));

        this.populateMapWithLights();
        this.mapIsLoading = false;
    }

    showLightDetails(lightId: string): void {
        const modal = this.modalService.open(ViewLightModalComponent, {centered: true});
        modal.componentInstance.lightId = lightId;
    }

    async searchCriteriaChanged(): Promise<void> {
        this.goToFirstPage();

        const searchCriteriaDto = this.pageRequest.criteria.searchCriteriaDto;
        searchCriteriaDto.code = this.code.value;
        searchCriteriaDto['description' + this.crtLang.toUpperCase()] = this.description.value;
        searchCriteriaDto.lightZoneCode = this.lightZoneCode.value;

        this.filterNotEmpty = !!searchCriteriaDto.code ||
                !!searchCriteriaDto['description' + this.crtLang.toUpperCase()] ||
                !!searchCriteriaDto.lightZoneCode;

        await this.searchLights();
    }

    async clearFilter(): Promise<void> {
        this.code.setValue(null);
        this.description.setValue(null);
        this.lightZoneCode.setValue(null);

        this.pageRequest = this.setDefaultLightPageCriteria();
        this.pageRequest.criteria = this.setDefaultLightCriteria();

        this.goToFirstPage();
        await this.searchLights();
    }

    async setPage(page: any): Promise<void> {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;
        await this.searchLights();
    }

    async onSort(sortingValues: any): Promise<void> {
        this.goToFirstPage();
        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortingValues.sorts[0].dir,
                property: sortingValues.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteria = new SortCriteriaDto(sortCriteriaDto);
        await this.searchLights();
    }

    private async setupMap(): Promise<void> {
        this.mainContainer = d3.select('#d3_map');
        const g = this.mainContainer.append('g');
        const mapData = await d3.xml(this.map.mapUrl);
        g.node().appendChild(mapData.documentElement);
        const d3Map = g.select('svg');
        const w = d3Map.attr('width');
        const h = d3Map.attr('height');

        this.projection = await firstValueFrom(this.mapService.computeProjectionMn95ToSvg(w, h));

        const zoom = d3.zoom()
            .scaleExtent([0.1, 30])
            .on('zoom', (event) => {
                g.attr('transform', event.transform);
            });

        zoom.scaleTo(this.mainContainer, 0.2);
        zoom.translateTo(this.mainContainer, w / 2, h / 2);

        d3.select('#zoom-in').on('click', () => {
            zoom.scaleBy(this.mainContainer.transition().duration(750), 1.5);
        });

        d3.select('#zoom-out').on('click', () => {
            zoom.scaleBy(this.mainContainer.transition().duration(750), 1 / 1.5);
        });

        this.mainContainer.call(zoom);
    }

    private populateMapWithLights(): void {
        const p = this.projection;
        this.mainContainer.select('g').select('svg').selectAll('.coord').remove();
        this.mainContainer.select('g').select('svg').selectAll('.coord')
            .data(this.lightsWithState)
            .enter()
            .append('text')
            .attr('x', (l) => MapUtils.xPositionForMapContent(l, p))
            .attr('y', (l) => MapUtils.yPositionForMapContent(l, p))
            .attr('dy', '0.5em') // Center the label vertically
            .attr('lat', (l) => l.latitude)
            .attr('lon', (l) => l.longitude)
            .attr('class', (l) => this.getCSSClassesBasedOnState(l.externalState))
            .text((l) => l.code)
            .on('click', async (event, d) => {
                event.stopPropagation();
                this.showLightDetails(d.id);
            });
    }

    private getCSSClassesBasedOnState(state: PublicLightingExternalState): string | null {
        let cssClasses = 'coord';

        switch (state) {
            case PublicLightingExternalState.Alarm:
                return cssClasses + ' bold red';
            case PublicLightingExternalState.On:
                return cssClasses + ' bold orange';
            case PublicLightingExternalState.Off:
                return cssClasses + ' bold grey';
            default:
                return cssClasses;
        }
    }

    private async searchLights(): Promise<void> {
        this.lightsForTable = await firstValueFrom(this.lightService.searchLightsByServiceId(this.serviceId, this.pageRequest));
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }

    private setDefaultLightPageCriteria(): PageableRequestDtoOfLightCriteriaDto {
        return new PageableRequestDtoOfLightCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
    }

    private setDefaultLightCriteria(): LightCriteriaDto {
        return new LightCriteriaDto({
            searchCriteriaDto: new LightSearchCriteriaDto({
                code: null,
                descriptionFR: null,
                descriptionDE: null,
                descriptionIT: null,
                descriptionEN: null,
                lightZoneCode: null,
            }),
            sortCriteria: new SortCriteriaDto({
                direction: SortDirection.Asc,
                property: this.defaultSortProperty
            })
        });
    }

    get code(): FormControl<string> {
        return this.filterForm.get('code') as FormControl<string>;
    }

    get description(): FormControl<string> {
        return this.filterForm.get('description') as FormControl<string>;
    }

    get lightZoneCode(): FormControl<string> {
        return this.filterForm.get('lightZoneCode') as FormControl<string>;
    }
}

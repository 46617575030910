<div>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'bookings.slots.generate' | translate }}</h4>

        <button class="btn-close" aria-label="Close"
                (click)="bookingSlotsGenerationModal.dismiss('Cross click')">
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="bookingSlotsGenerationForm">

            <div class="row">

                <!-- date period -->
                <div class="col-12">
                    <app-date-period-picker [required]="true"
                                            [fromMinDate]="minDate"
                                            [toMinDate]="minDate"
                                            [periodControl]="datePeriod"></app-date-period-picker>
                </div>

                <!-- time period -->
                <div class="row" formGroupName="timePeriod">
                    <div class="col-12 col-sm-6">
                        <label class="form-label" for="startTime">{{ 'bookings.slots.form.generate.startTime' | translate }}*</label>

                        <ngb-timepicker id="startTime" formControlName="from"></ngb-timepicker>
                        <app-form-error [control]="startTime"></app-form-error>
                    </div>

                    <div class="col-12 col-sm-6">
                        <label class="form-label" for="lastStartTimeOfTheDay">{{ 'bookings.slots.form.generate.lastStartTimeOfTheDay' | translate }}*</label>

                        <ngb-timepicker id="lastStartTimeOfTheDay" formControlName="to"></ngb-timepicker>
                        <app-form-error [control]="lastStartTimeOfTheDay"></app-form-error>
                        <app-form-error [control]="timePeriod"></app-form-error>
                    </div>
                </div>

                <!-- slotDuration -->
                <div class="col-12 col-sm-6">
                    <label class="form-label" for="slotDuration">{{ 'bookings.slots.form.generate.slotDuration' | translate }}*</label>

                    <ngb-timepicker id="slotDuration" formControlName="slotDuration"></ngb-timepicker>
                    <app-form-error [control]="slotDuration"></app-form-error>
                </div>

                <!-- state -->
                <div class="col-12 col-sm-6">
                    <label class="form-label">{{ 'bookings.slots.form.generate.state' | translate}}*</label>

                    <div>
                        <div class="btn-group" role="group">
                            <input class="btn-check" type="radio"
                                   [formControl]="state"
                                   [value]="BookingSlotState.Free">
                            <label class="btn btn-toggle btn-toggle-left h-auto"
                                   [ngClass]="{ active: state.value === BookingSlotState.Free }"
                                   (click)="state.setValue(BookingSlotState.Free);"
                                   (keyup)="state.setValue(BookingSlotState.Free);">
                                {{ 'bookings.slots.stateEnum.' + BookingSlotState.Free | translate }}
                            </label>

                            <input class="btn-check" type="radio"
                                   [formControl]="state"
                                   [value]="BookingSlotState.Blocked">
                            <label class="btn btn-toggle btn-toggle-right h-auto"
                                   [ngClass]="{ active: state.value === BookingSlotState.Blocked }"
                                   (click)="state.setValue(BookingSlotState.Blocked);"
                                   (keyup)="state.setValue(BookingSlotState.Blocked);">
                                {{ 'bookings.slots.stateEnum.' + BookingSlotState.Blocked | translate }}
                            </label>
                        </div>
                    </div>

                </div>

                <!-- days -->
                <div class="col-12 col-sm-6">
                    <label class="form-label" translate="bookings.slots.form.generate.days"></label>

                    <div class="form-check form-switch" *ngFor="let day of daysOfWeek; let i = index">
                        <input type="checkbox" class="form-check-input" id="day-{{ day }}"
                               formArrayName="days"
                               [formControl]="days.controls[i]"
                               [value]="days.value[i]">
                        <label class="form-check-label" for="day-{{ day }}">{{ 'common.day.' + day | translate }}</label>
                    </div>
                </div>

            </div>

        </form>
    </div>

    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-secondary"
                (click)="bookingSlotsGenerationModal.close()"
                translate="common.close">
        </button>

        <button class="btn btn-primary" type="submit"
                [disabled]="bookingSlotsGenerationForm.invalid"
                (click)="generateBookingSlots()"
                translate="common.generate">
        </button>
    </div>
</div>


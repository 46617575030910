<div class="modal-header">
    <h4 class="modal-title" translate="mooringPlace.modal.title"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="this.activeModal.close('close')">
    </button>
</div>

<div class="modal-body custom-layout">
    <form [formGroup]="mooringPlaceForm" class="m-0">

        <h6 translate="mooringPlace.modal.placeInformationTitle"></h6>

        <!-- Mooring place information-->
        <div class="row">
            <div class="col-6">
                <label class="form-label" for="placeId">{{'mooringPlace.modal.placeId' | translate}}</label>
                <span *ngIf="creationMode">*</span>
            </div>
            <div class="col-6 text-end">
                <input class="form-control" id="placeId" type="text" *ngIf="creationMode"
                       pattern="{{textInputPattern}}"
                       formControlName="placeId" required>
                <p class="form-label" *ngIf="!creationMode">{{ mooringPlace.placeId }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label" for="pontoon">{{'mooringPlace.modal.pontoon' | translate}}</label>
                <span *ngIf="onlyPlaceInfo">*</span>
            </div>
            <div class="col-6 text-end">
                <input class="form-control" id="pontoon" type="text" *ngIf="onlyPlaceInfo"
                       pattern="{{textInputPattern}}"
                       formControlName="pontoon" required>
                <p class="form-label" *ngIf="!onlyPlaceInfo">{{ mooringPlaceForm.value.pontoon }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label" for="placeLabel" translate="mooringPlace.modal.placeNum"></label>
                <span *ngIf="onlyPlaceInfo">*</span>
            </div>
            <div class="col-6 text-end">
                <input class="form-control" id="placeLabel" type="text" *ngIf="onlyPlaceInfo"
                       pattern="{{textInputPattern}}"
                       formControlName="placeNum" required>
                <p class="form-label" *ngIf="!onlyPlaceInfo">{{ mooringPlaceForm.value.placeNum }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label" for="placeType" translate="mooringPlace.modal.placeType"></label>
                <span *ngIf="onlyPlaceInfo">*</span>
            </div>
            <div class="col-6 text-end">
                <input class="form-control" id="placeType" type="text" *ngIf="onlyPlaceInfo"
                       pattern="{{textInputPattern}}"
                       formControlName="placeType" required>
                <p class="form-label" *ngIf="!onlyPlaceInfo">{{ mooringPlaceForm.value.placeType }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label" for="placeWidth" translate="mooringPlace.modal.placeWidth"></label>
                <span *ngIf="onlyPlaceInfo">*</span>
            </div>
            <div class="col-6 text-end">
                <input class="form-control" id="placeWidth" type="number" *ngIf="onlyPlaceInfo"
                       step="0.01"
                       formControlName="placeWidthInMeter" required>
                <p class="form-label" *ngIf="!onlyPlaceInfo">{{ mooringPlaceForm.value.placeWidthInMeter }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label" for="placeLength" translate="mooringPlace.modal.placeLength"></label>
                <span *ngIf="onlyPlaceInfo">*</span>
            </div>
            <div class="col-6 text-end">
                <input class="form-control" id="placeLength" type="number" *ngIf="onlyPlaceInfo"
                       step="0.01"
                       formControlName="placeLengthInMeter" required>
                <p class="form-label" *ngIf="!onlyPlaceInfo">{{ mooringPlaceForm.value.placeLengthInMeter }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label" for="coordinateLatitude" translate="services.manage.coordinates.latitude"></label>
                <span *ngIf="onlyPlaceInfo">*</span>
            </div>
            <div class="col-6 text-end">
                <input class="form-control" type="text" id="coordinateLatitude" *ngIf="onlyPlaceInfo"
                       pattern="{{textInputPattern}}"
                       formControlName="latitude" required>
                <p class="form-label" *ngIf="!onlyPlaceInfo">{{ mooringPlaceForm.value.latitude }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label" for="coordinateLongitude" translate="services.manage.coordinates.longitude"></label>
                <span *ngIf="onlyPlaceInfo">*</span>
            </div>
            <div class="col-6 text-end">
                <input class="form-control" id="coordinateLongitude" type="text" *ngIf="onlyPlaceInfo"
                       pattern="{{textInputPattern}}"
                       formControlName="longitude" required>
                <p class="form-label" *ngIf="!onlyPlaceInfo">{{ mooringPlaceForm.value.longitude }}</p>
            </div>
        </div>

        <div class="row" *ngIf="!onlyPlaceInfo">
            <div class="col-6">
                <label class="form-label" translate="mooringPlace.modal.sublet"></label>
                <span *ngIf="!previewMode">*</span>
            </div>
            <div class="col-6 text-end">
                <div class="btn-group" role="group" *ngIf="!previewMode">
                    <input class="btn-check" type="radio"
                           [formControl]="sublet"
                           [value]="false">
                    <label class="btn btn-toggle btn-toggle-left h-auto"
                           [ngClass]="{ active: sublet.value }"
                           translate="common.yes"
                           (click)="sublet.setValue(true)"
                           (keyup)="sublet.setValue(true)"></label>

                    <input class="btn-check" type="radio"
                           [formControl]="sublet"
                           [value]="true">
                    <label class="btn btn-toggle btn-toggle-right h-auto"
                           [ngClass]="{ active: !sublet.value }"
                           translate="common.no"
                           (click)="sublet.setValue(false)"
                           (keyup)="sublet.setValue(false)"></label>
                </div>
                <p class="form-label" *ngIf="previewMode">{{ (mooringPlace.sublet ? 'common.yes' : 'common.no') | translate }}</p>
            </div>
        </div>

        <!--Boat information-->
        <ng-container *ngIf="!onlyPlaceInfo">

            <h6 translate="mooringPlace.modal.boatInformationTitle"></h6>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" for="boatRegistrationNumber" translate="mooringPlace.modal.boatRegistrationNumber"></label>
                </div>
                <div class="col-6 text-end">
                    <input class="form-control" type="text" id="boatRegistrationNumber" *ngIf="!previewMode"
                           pattern="{{textInputPattern}}"
                           formControlName="boatRegistrationNumber">
                    <p class="form-label" *ngIf="previewMode">{{ mooringPlaceForm.value.boatRegistrationNumber }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" for="boatWidth" translate="mooringPlace.modal.boatWidth"></label>
                </div>
                <div class="col-6 text-end">
                    <input class="form-control" id="boatWidth" type="number" *ngIf="!previewMode"
                           pattern="{{textInputPattern}}"
                           step="0.01"
                           formControlName="boatWidthInMeter">
                    <p class="form-label" *ngIf="previewMode">{{ mooringPlaceForm.value.boatWidthInMeter }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" for="boatlength" translate="mooringPlace.modal.boatlength"></label>
                </div>
                <div class="col-6 text-end">
                    <input class="form-control" id="boatLength" type="number" *ngIf="!previewMode"
                           pattern="{{textInputPattern}}"
                           step="0.01"
                           formControlName="boatLengthInMeter">
                    <p class="form-label" *ngIf="previewMode">{{ mooringPlaceForm.value.boatLengthInMeter }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" for="boatWeight" translate="mooringPlace.modal.boatWeight"></label>
                </div>
                <div class="col-6 text-end">
                    <input class="form-control" type="number" id="boatWeight" *ngIf="!previewMode"
                           pattern="{{textInputPattern}}"
                           formControlName="boatWeightInKg">
                    <p class="form-label" *ngIf="previewMode">{{ mooringPlaceForm.value.boatWeightInKg }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <label class="form-label" translate="mooringPlace.modal.boatType.label"></label>
                </div>
                <div class="col-8 text-end">
                    <div *ngIf="!previewMode">
                        <div class="btn-group" role="group" *ngIf="!previewMode">
                            <input class="btn-check" type="radio"
                                   [formControl]="boatType"
                                   [value]="BoatType.Motor">
                            <label class="btn btn-toggle btn-toggle-left h-auto"
                                   [ngClass]="{ active: boatType.value === BoatType.Motor }"
                                   translate="mooringPlace.modal.boatType.Motor"
                                   (click)="boatType.setValue(BoatType.Motor)"
                                   (keyup)="boatType.setValue(BoatType.Motor)"></label>

                            <input class="btn-check" type="radio"
                                   [formControl]="boatType"
                                   [value]="BoatType.Sailing">
                            <label class="btn btn-toggle btn-toggle-left h-auto"
                                   [ngClass]="{ active: boatType.value === BoatType.Sailing }"
                                   translate="mooringPlace.modal.boatType.Sailing"
                                   (click)="boatType.setValue(BoatType.Sailing)"
                                   (keyup)="boatType.setValue(BoatType.Sailing)"></label>

                            <input class="btn-check" type="radio"
                                   [formControl]="boatType"
                                   [value]="true">
                            <label class="btn btn-toggle btn-toggle-right h-auto"
                                   [ngClass]="{ active: boatType.value === BoatType.Unknown }"
                                   translate="mooringPlace.modal.boatType.Unknown"
                                   (click)="boatType.setValue(BoatType.Unknown)"
                                   (keyup)="boatType.setValue(BoatType.Unknown)"></label>
                        </div>
                    </div>
                    <p class="form-label" *ngIf="previewMode && mooringPlaceForm.get('boatType').value">{{'mooringPlace.modal.boatType.'+ mooringPlaceForm.get('boatType').value | translate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" for="draft" translate="mooringPlace.modal.draft"></label>
                </div>
                <div class="col-6 text-end">
                    <input class="form-control" type="text" id="draft" *ngIf="!previewMode"
                           pattern="{{textInputPattern}}"
                           formControlName="draft">
                    <p class="form-label" *ngIf="previewMode">{{ mooringPlaceForm.value.draft }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" for="remark" translate="mooringPlace.modal.remark"></label>
                </div>
                <div class="col-6 text-end">
                    <input class="form-control" type="text" id="remark"  *ngIf="!previewMode"
                           pattern="{{textInputPattern}}"
                           formControlName="remark">
                    <p class="form-label" *ngIf="previewMode">{{ mooringPlaceForm.value.remark }}</p>
                </div>
            </div>

            <div class="row" *ngIf="!previewMode">
                <div class="col-6">
                    <label class="form-label" translate="mooringPlace.modal.owner"></label>
                </div>
                <div class="col-6 text-end">
                    <app-search-user (selectedUserEvent)="selectUser($event)"
                                     [userContact]="selectedUser"></app-search-user>
                </div>
            </div>

        </ng-container>

        <!--Owner information-->
        <ng-container *ngIf="previewMode && user">
            <h6 translate="mooringPlace.modal.boatOwnerInformationTitle"></h6>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" translate="users.form.title.label"></label>
                </div>
                <div class="col-6 text-end">
                    <p class="form-label">{{ 'users.form.title.'+user.title | lowercase | translate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <label class="form-label" translate="users.form.lastname"></label>
                </div>
                <div class="col-8 text-end">
                    <p class="form-label">{{ user.lastName | translate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <label class="form-label" translate="users.form.firstname"></label>
                </div>
                <div class="col-8 text-end">
                    <p class="form-label">{{ user.firstName | translate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <label class="form-label" translate="users.form.email"></label>
                </div>
                <div class="col-8 text-end">
                    <p class="form-label">{{ user.email | translate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" translate="users.form.street"></label>
                </div>
                <div class="col-6 text-end">
                    <p class="form-label">{{ user.street | translate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" translate="users.form.postalCode"></label>
                </div>
                <div class="col-6 text-end">
                    <p class="form-label">{{ user.postalCode | translate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" translate="users.form.city"></label>
                </div>
                <div class="col-6 text-end">
                    <p class="form-label">{{ user.city | translate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" translate="users.form.country"></label>
                </div>
                <div class="col-6 text-end">
                    <p class="form-label">{{ user.country | translate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label"translate="users.form.phone"></label>
                </div>
                <div class="col-6 text-end">
                    <p class="form-label">{{ user.phonePrivate | translate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" translate="users.form.mobilePhone"></label>
                </div>
                <div class="col-6 text-end">
                    <p class="form-label">{{ user.phoneMobile | translate }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label" translate="users.form.businessPhone"></label>
                </div>
                <div class="col-6 text-end">
                    <p class="form-label">{{ user.phoneProfessional  }}</p>
                </div>
            </div>

        </ng-container>
    </form>
</div>

<div class="modal-footer space-between">
    <button class="btn btn-outline-danger" *ngIf="!creationMode && !previewMode && onlyPlaceInfo"
            (click)="delete()"
            translate="services.actions.delete">
    </button>

    <div>
        <button class="btn btn-outline-secondary" *ngIf="creationMode || !previewMode"
                (click)="this.activeModal.close('close')"
                translate="common.cancel">
        </button>
        <button class="btn btn-primary" *ngIf="creationMode || !previewMode" [disabled]="mooringPlaceForm.invalid"
                (click)="save()"
                translate="common.save">
        </button>
        <button class="btn btn-primary" *ngIf="previewMode"
                (click)="toggleEdit()"
                translate="common.tooltip.edit">
        </button>
    </div>

</div>

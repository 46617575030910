import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    WifiWithSubscriptionCountDto,
    WifiService,
    ServiceDto,
} from '../../../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {WifiModalComponent} from './wifi-modal/wifi-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateUtils} from '../../../../_shared/translate-utils';

@Component({
    selector: 'app-wifi-management',
    templateUrl: './wifi-management.component.html'
})
export class WifiManagementComponent implements OnInit, OnDestroy {
    private langChangeSubscription: Subscription;

    assignedWifi: WifiWithSubscriptionCountDto;

    crtLang = TranslateUtils.defaultLanguage;

    @Input() service: ServiceDto;

    constructor(
        private readonly wifiService: WifiService,
        private readonly translateService: TranslateService,
        private readonly modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.getAssignedWifi();

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                }
            });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription?.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    getAssignedWifi(): void {
        this.wifiService.getWifiForService(this.service.serviceId).pipe().subscribe(wifi => {
            this.assignedWifi = wifi;
        });
    }

    getWifiName(wifi: WifiWithSubscriptionCountDto): string {
        return wifi['name' + this.crtLang.toUpperCase()];
    }

    openWifiModal(): void {
        const modal = this.modalService.open(WifiModalComponent, {centered: true});
        modal.componentInstance.service = this.service;
        modal.componentInstance.crtLang = this.crtLang;
        modal.componentInstance.isUpdateModal = !!this.assignedWifi;
        modal.result.then(() => {
            this.getAssignedWifi();
        }, () => { /* catch the rejection */ });
    }
}

<div class="content-container bg-white">
    <h1 translate="officeHours.opening.title"></h1>

    <ngx-datatable
        #officeHourTable
        class="material"
        [columnMode]="'force'"
        [rows]="[officeHours.officeHour]"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="50"
        [externalPaging]="false"
        [externalSorting]="false"
        [count]="1"
        [scrollbarH]="true"
        [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                }">

        <ngx-datatable-column [width]="100" prop="startHour" [name]="'officeHours.startHour' | translate"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ DateUtils.formatTime(row.startHour) }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="100" prop="endHour" [name]="'officeHours.endHour' | translate"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ DateUtils.formatTime(row.endHour) }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="300" prop="user" [name]="'officeHours.days' | translate"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ formatSelectedDays(getSelectedDays(row)) }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="50" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <button class="btn btn-link btn-sm" type="button"
                        (click)="updateOfficeHour(row)"
                        [title]="'common.tooltip.edit' | translate">
                    <fa-icon [icon]="icons.pen"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer></ngx-datatable-footer>
    </ngx-datatable>

    <h4 class="mt-4" translate="officeHours.exceptions.title"></h4>

    <div class="btn-bar">
        <button class="btn btn-primary" type="button" (click)="createExceptionHour()"
                translate="officeHours.exceptions.action.create">
        </button>
    </div>

    <ngx-datatable
        #exceptionsTable
        class="material"
        [columnMode]="'force'"
        [rows]="officeHours.exceptions"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="50"
        [externalPaging]="false"
        [externalSorting]="false"
        [scrollbarH]="true"
        [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                }">

        <ngx-datatable-column [width]="100" prop="startDate" [name]="'officeHours.exceptions.startDate' | translate"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.startDate | date:"dd.MM.yy" }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="100" prop="endDate" [name]="'officeHours.exceptions.endDate' | translate"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.endDate | date:"dd.MM.yy" }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="75" prop="startHour" [name]="'officeHours.startHour' | translate"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ DateUtils.formatTime(row.startHour) }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="75" prop="endHour" [name]="'officeHours.endHour' | translate"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ DateUtils.formatTime(row.endHour) }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="75" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <button class="btn btn-link btn-sm" type="button"
                        (click)="updateExceptionHour(row)"
                        [title]="'common.tooltip.edit' | translate">
                    <fa-icon [icon]="icons.pen"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm"
                        title="{{'common.tooltip.delete' | translate}}"
                        (click)="deleteExceptionHour(row)">
                    <fa-icon [icon]="icons.trash"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div class="page-count">
                    {{ rowCount }} {{ 'common.datatable.total' | translate }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="exceptionsTable.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

</div>


<!-- Title for visitor -->
<h3 *ngIf="isVisitor && mode === CraningBookingFormMode.NewReservation" translate>services.craning.booking.form.titles.mine.new</h3>

<!-- Title for user -->
<ng-container [ngSwitch]="mode" *ngIf="!isVisitor && !craningBookingUtils.hasAdminView()">
    <h3 *ngSwitchCase="CraningBookingFormMode.NewReservation" translate>services.craning.booking.form.titles.mine.new</h3>
    <h3 *ngSwitchCase="CraningBookingFormMode.UpdateReservation" translate>services.craning.booking.form.titles.mine.update</h3>
    <h3 *ngSwitchCase="CraningBookingFormMode.DisplayReservation">
        <span *ngIf="selectedSlot?.state === CraningBookingSlotState.Paid" translate>services.craning.booking.form.titles.mine.paid</span>
        <span *ngIf="selectedSlot?.state !== CraningBookingSlotState.Paid" translate>services.craning.booking.form.titles.mine.display</span>
    </h3>
</ng-container>

<!-- Title for Admin-->
<ng-container [ngSwitch]="mode" *ngIf="craningBookingUtils.hasAdminView()">
    <h3 *ngSwitchCase="CraningBookingFormMode.NewReservation" translate>services.craning.booking.form.titles.other.new</h3>
    <h3 *ngSwitchCase="CraningBookingFormMode.UpdateReservation" translate>services.craning.booking.form.titles.other.update</h3>
    <h3 *ngSwitchCase="CraningBookingFormMode.DisplayReservation">
        <span *ngIf="selectedSlot?.state === CraningBookingSlotState.Paid" translate>services.craning.booking.form.titles.other.paid</span>
        <span *ngIf="selectedSlot?.state !== CraningBookingSlotState.Paid" translate>services.craning.booking.form.titles.other.display</span>
    </h3>
    <h3 *ngSwitchCase="CraningBookingFormMode.BillingReservation" translate>services.craning.booking.form.titles.other.billing</h3>
</ng-container>

<form [formGroup]="craningBookingForm" *ngIf="mode !== CraningBookingFormMode.Hidden">

    <!-- Selected Booking -->
    <div class="row">
        <fieldset class="col-12">
            <legend>
                <small class="text-danger" *ngIf="!selectedSlot" translate>services.craning.booking.form.selectedSlot.none</small>
                <small class="font-weight-bold" *ngIf="!!selectedSlot"
                       [translateParams]="{
                            date: this.selectedSlot.date | date : formats.date,
                            startTime: DateUtils.formatTime(this.selectedSlot.startTime),
                            endTime: DateUtils.formatTime(this.selectedSlot.endTime)
                       }" translate>services.craning.booking.form.selectedSlot.date
                </small>
                <small class="text-muted" *ngIf="!!defaultSelectedSlot && selectedSlot !== defaultSelectedSlot && mode === CraningBookingFormMode.UpdateReservation"
                       [translateParams]="{
                           date: this.defaultSelectedSlot.date | date : formats.date,
                           startTime: DateUtils.formatTime(this.defaultSelectedSlot.startTime),
                           endTime: DateUtils.formatTime(this.defaultSelectedSlot.endTime)
                       }" translate>services.craning.booking.form.selectedSlot.oldDate
                </small>
            </legend>
        </fieldset>
    </div>

    <!-- User / Visitor chooser -->
    <div class="row mb-2">
        <div class="col-12" *ngIf="!!debtorType && isCreationMode()">
            <label class="form-label">{{'services.craning.booking.form.debtorType' | translate}}*</label>

            <div>
                <div class="btn-group" role="group">
                    <input class="btn-check" type="radio"
                           [formControl]="debtorType"
                           [value]="debtorTypes.user">
                    <label class="btn btn-toggle btn-toggle-left h-auto"
                           [ngClass]="{ active: debtorType.value === debtorTypes.user }"
                           translate="services.craning.booking.form.debtorTypes.user"
                           (click)="debtorType.setValue(debtorTypes.user)"
                           (keyup)="debtorType.setValue(debtorTypes.user)"></label>

                    <input class="btn-check" type="radio"
                           [formControl]="debtorType"
                           [value]="debtorTypes.visitor">
                    <label class="btn btn-toggle btn-toggle-right h-auto"
                           [ngClass]="{ active: debtorType.value === debtorTypes.visitor }"
                           translate="services.craning.booking.form.debtorTypes.visitor"
                           (click)="debtorType.setValue(debtorTypes.visitor)"
                           (keyup)="debtorType.setValue(debtorTypes.visitor)"></label>
                </div>
            </div>

            <app-form-error [control]="debtorType"></app-form-error>
        </div>
    </div>

    <!-- User Search field -->
    <div class="row" *ngIf="!!selectUser && isCreationMode()">
        <div class="col-12">
            <app-search-user #searchUserComponent formControlName="searchUserComponent"
                             (selectedUserEvent)="setSearchedUser($event)"
                             [userContact]="userContact"
                             ngDefaultControl></app-search-user>
            <app-form-error [control]="searchUserComponent"></app-form-error>
        </div>
    </div>

    <!-- Boat Select field -->
    <div class="row" *ngIf="!!selectUserBoat && !isDisplayMode() && !isBillingMode()">
        <div class="col-12">
            <label class="form-label" for="selectUserBoat">{{ 'services.craning.booking.form.selectBoat' | translate }}*</label>

            <select class="form-control form-select" id="selectUserBoat" formControlName="selectUserBoat">
                <option *ngFor="let boat of mooringPlaceBoats;"
                        [ngValue]="boat"
                        [selected]="selectUserBoat.value === boat">{{ craningBookingUtils.getBoatLabel(boat) }}</option>
            </select>

            <app-form-error [control]="selectUserBoat"></app-form-error>
        </div>
    </div>

    <!-- User/Visitor summary -->
    <div class="row mt-4" *ngIf="!!userContact">
        <fieldset class="col-12">
            <legend [translate]="!craningBookingUtils.hasAdminView() ? 'services.craning.booking.form.debtor.mine' : 'services.craning.booking.form.debtor.other'"></legend>

            <div class="col-sm-6">
                <table class="table table-borderless no-padding"
                       [attr.aria-label]="(!craningBookingUtils.hasAdminView() ? 'services.craning.booking.form.debtor.mine' : 'services.craning.booking.form.debtor.other') | translate"
                       aria-hidden="true">
                    <tbody>
                    <tr>
                        <td translate>services.craning.booking.form.debtor.name</td>
                        <td>{{this.userContact.firstName}} {{this.userContact.lastName}}</td>
                    </tr>
                    <tr>
                        <td translate>bookings.modal.user.street</td>
                        <td>{{this.userContact.street}}</td>
                    </tr>
                    <tr>
                        <td translate>bookings.modal.user.city</td>
                        <td>{{this.userContact.postalCode}} {{this.userContact.city}}</td>
                    </tr>
                    <tr>
                        <td translate>bookings.modal.user.country</td>
                        <td>{{this.userContact.country}}</td>
                    </tr>
                    <tr>
                        <td translate>bookings.modal.user.email</td>
                        <td>{{this.userContact.email}}</td>
                    </tr>
                    <tr>
                        <td translate>bookings.modal.user.phoneNumber</td>
                        <td>{{this.userContact.phone}}</td>
                    </tr>

                    <tr *ngIf="visitorBoatFormGroup?.disabled && mooringPlaceBoats.length === 0">
                        <td class="text-warning" colspan="2" translate>
                            services.craning.booking.form.warnings.userWithoutBoat
                        </td>
                    </tr>

                    <ng-container *ngIf="!!selectedMooringPlaceBoat">
                        <tr>
                            <td class="pt-2"><strong translate>services.craning.booking.form.boatTitle</strong></td>
                        </tr>
                        <tr>
                            <td translate>mooringPlace.modal.boatRegistrationNumber</td>
                            <td>{{craningBookingUtils.getBoatLabel(selectedMooringPlaceBoat)}}</td>
                        </tr>
                        <tr>
                            <td translate>mooringPlace.modal.boatWeight</td>
                            <td> {{selectedMooringPlaceBoat.boatWeightInKg | numberFormat: GesConstants.defaultNumeralFormat}} kg</td>
                        </tr>
                        <tr>
                            <td translate>services.craning.booking.form.boatDimensions</td>
                            <td>{{selectedMooringPlaceBoat.boatWidthInMeter | numberFormat: GesConstants.defaultNumeralFormat}} m
                                x {{selectedMooringPlaceBoat.boatLengthInMeter | numberFormat: GesConstants.defaultNumeralFormat}} m</td>
                        </tr>
                    </ng-container>

                    </tbody>
                </table>
            </div>
            <div class="btn-bar" *ngIf="craningBookingUtils.hasAdminView() && !!selectedMooringPlaceBoat && (selectedSlot === null || selectedSlot?.state !== CraningBookingSlotState.Paid)">
                <button class="btn btn-primary" (click)="editMooringPlace()" translate>
                services.craning.booking.form.editBoat
                </button>
            </div>
        </fieldset>
    </div>

    <!-- Visitor Form -->
    <div class="row mt-4" *ngIf="!!visitorInfoFormGroup">
        <fieldset class="col-12" [formGroupName]="enableableFormControlNames.visitorInfo">
            <legend translate="services.craning.booking.form.debtor.visitor"></legend>

            <div class="row">
                <!-- firstname -->
                <div class="col-sm-6">

                    <label class="form-label" for="visitorFirstname">{{'bookings.modal.user.firstname' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="visitorFirstname" name="visitorFirstname" formControlName="firstname"
                               pattern="{{FormUtils.textInputPattern}}" type="text"
                               placeholder="{{'bookings.modal.user.firstname' | translate}}" maxlength="50">
                        <app-form-error [control]="visitorFirstname"></app-form-error>
                    </ng-container>

                    <ng-container class="col-12" *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorFirstname.value}}</span>
                    </ng-container>
                </div>

                <!-- lastname -->
                <div class="col-sm-6">

                    <label class="form-label" for="visitorLastname">{{'bookings.modal.user.lastname' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="visitorLastname" name="visitorLastname" formControlName="lastname"
                               pattern="{{FormUtils.textInputPattern}}" type="text"
                               placeholder="{{'bookings.modal.user.lastname' | translate}}" maxlength="50">
                        <app-form-error [control]="visitorLastname"></app-form-error>
                    </ng-container>

                    <ng-container *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorLastname.value}}</span>
                    </ng-container>
                </div>
            </div>

            <div class="row">
                <!-- email -->
                <div class="col-sm-6">

                    <label class="form-label" for="visitorEmail">{{'bookings.modal.user.email' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="visitorEmail" name="visitorEmail" formControlName="email"
                               pattern="{{FormUtils.textInputPattern}}" type="text"
                               placeholder="{{'bookings.modal.user.email' | translate}}" maxlength="75">
                        <app-form-error [control]="visitorEmail"></app-form-error>
                    </ng-container>

                    <ng-container class="col-12" *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorEmail.value}}</span>
                    </ng-container>

                </div>

                <!-- Phone -->
                <div class="col-sm-6">

                    <label class="form-label" for="visitorPhoneNumber">{{'bookings.modal.user.phoneNumber' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="visitorPhoneNumber" name="visitorPhoneNumber" formControlName="phoneNumber"
                               pattern="{{FormUtils.textInputPattern}}" type="text"
                               placeholder="{{ 'common.placeholders.phone' | translate}}" maxlength="15">
                        <app-form-error [control]="visitorPhoneNumber"></app-form-error>
                    </ng-container>

                    <ng-container *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorPhoneNumber.value}}</span>
                    </ng-container>
                </div>
            </div>

            <div class="row">
                <!-- street -->
                <div class="col-sm-6">

                    <label class="form-label" for="visitorStreet">{{'users.form.street' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="visitorStreet" name="visitorStreet" formControlName="street"
                               pattern="{{FormUtils.textInputPattern}}" type="text"
                               placeholder="{{'users.form.street' | translate}}" maxlength="50">
                        <app-form-error [control]="visitorStreet"></app-form-error>
                    </ng-container>

                    <ng-container class="col-12" *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorStreet.value}}</span>
                    </ng-container>
                </div>

                <!-- postal code -->
                <div class="col-sm-6">

                    <label class="form-label" for="visitorPostalCode">{{'users.form.postalCode' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="visitorPostalCode" name="visitorPostalCode" formControlName="postalCode"
                               pattern="{{FormUtils.textInputPattern}}" type="text"
                               placeholder="{{'users.form.postalCode' | translate}}" maxlength="10">
                        <app-form-error [control]="visitorPostalCode"></app-form-error>
                    </ng-container>

                    <ng-container *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorPostalCode.value}}</span>
                    </ng-container>
                </div>
            </div>

            <div class="row">
                <!-- city -->
                <div class="col-sm-6">

                    <label class="form-label" for="visitorCity">{{'users.form.city' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="visitorCity" name="visitorCity" formControlName="city"
                               pattern="{{FormUtils.textInputPattern}}" type="text"
                               placeholder="{{'users.form.city' | translate}}" maxlength="25">
                        <app-form-error [control]="visitorCity"></app-form-error>
                    </ng-container>

                    <ng-container *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorCity.value}}</span>
                    </ng-container>
                </div>

                <!-- country -->
                <div class="col-sm-6">

                    <label class="form-label" for="visitorCountry">{{'users.form.country' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="visitorCountry" name="visitorCountry" formControlName="country"
                               pattern="{{FormUtils.textInputPattern}}" type="text"
                               placeholder="{{'users.form.country' | translate}}" maxlength="25">
                        <app-form-error [control]="visitorCountry"></app-form-error>
                    </ng-container>

                    <ng-container *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorCountry.value}}</span>
                    </ng-container>
                </div>
            </div>
        </fieldset>
    </div>

    <!-- Boat Form-->
    <div class="row mt-4" *ngIf="!!visitorBoatFormGroup">
        <fieldset class="col-12" [formGroupName]="enableableFormControlNames.visitorBoat">
            <legend translate="mooringPlace.modal.boatInformationTitle"></legend>

            <div class="row">
                <!-- registration number -->
                <div class="col-sm-6">

                    <label class="form-label" for="boatRegistrationNumber">{{'mooringPlace.modal.boatRegistrationNumber' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="boatRegistrationNumber" name="boatRegistrationNumber" formControlName="registrationNumber"
                               pattern="{{FormUtils.textInputPattern}}" type="text"
                               placeholder="{{'mooringPlace.modal.boatRegistrationNumber' | translate}}" maxlength="50" required>
                        <app-form-error [control]="visitorBoatRegistrationNumber"></app-form-error>
                    </ng-container>

                    <ng-container *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorBoatRegistrationNumber.value}}</span>
                    </ng-container>
                </div>

                <!-- boat weight -->
                <div class="col-sm-6">

                    <label class="form-label" for="boatWeight">{{'mooringPlace.modal.boatWeight' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="boatWeight" name="boatWeight" formControlName="weightInKg"
                               pattern="{{FormUtils.textInputPattern}}" type="number"
                               placeholder="{{'mooringPlace.modal.boatWeight' | translate}}"
                               (keydown)="FormUtils.validateInputNumberLength($event, 10)">
                        <app-form-error [control]="visitorBoatWeightInKg"></app-form-error>
                    </ng-container>

                    <ng-container *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorBoatWeightInKg.value}}</span>
                    </ng-container>
                </div>
            </div>

            <div class="row">
                <!-- boat width -->
                <div class="col-sm-6">

                    <label class="form-label" for="boatWidth">{{'mooringPlace.modal.boatWidth' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="boatWidth" name="boatWidth" formControlName="widthInMeter"
                               pattern="{{FormUtils.textInputPattern}}" type="number" step="0.01"
                               placeholder="{{'mooringPlace.modal.boatWidth' | translate}}"
                               (keydown)="FormUtils.validateInputNumberLength($event, 10)">
                        <app-form-error [control]="visitorBoatWidthInMeter"></app-form-error>
                    </ng-container>

                    <ng-container *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorBoatWidthInMeter.value}}</span>
                    </ng-container>
                </div>

                <!-- boat length -->
                <div class="col-sm-6">

                    <label class="form-label" for="boatLength">{{'mooringPlace.modal.boatlength' | translate}}*</label>

                    <ng-container *ngIf="!isDisplayMode()">
                        <input class="form-control" id="boatLength" name="boatLength" formControlName="lengthInMeter"
                               pattern="{{FormUtils.textInputPattern}}" type="number" step="0.01"
                               placeholder="{{'mooringPlace.modal.boatlength' | translate}}"
                               (keydown)="FormUtils.validateInputNumberLength($event, 10)">
                        <app-form-error [control]="visitorBoatLengthInMeter"></app-form-error>
                    </ng-container>

                    <ng-container *ngIf="isDisplayMode()">
                        <span class="form-control">{{this.visitorBoatLengthInMeter.value}}</span>
                    </ng-container>
                </div>
            </div>
        </fieldset>
    </div>

    <!-- Services Form -->
    <div class="row mt-4" *ngIf="selectedSlot?.state !== CraningBookingSlotState.Paid">
        <fieldset class="col-12">

            <legend>{{'services.craning.booking.form.services' | translate}} <span class="small">({{ 'services.craning.booking.form.servicesInfo' | translate }})</span></legend>

            <div class="table-responsive">
                <table class="table table-striped"
                       [attr.aria-label]="'services.craning.booking.form.services' | translate">

                    <thead>
                        <tr>
                            <th scope="col" style="min-width: 85%" translate>services.craning.booking.form.service</th>
                            <th scope="col" style="width: 150px" class="text-center" translate>services.craning.booking.form.serviceChoice</th>
                            <th scope="col" style="width: 175px;" class="text-nowrap text-end" translate>services.craning.booking.form.price</th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr *ngFor="let s of services.controls; let i=index" formArrayName="services">

                            <td>
                                <span class="service">{{ getCraningServiceLabel(craningServices[i]) }}</span>
                                <span class="pricing" *ngIf="getServicePricing(i) !== null && s.value === true" [ngClass]="{
                                    'text-warning': getServicePricing(i) === false
                                }">{{ getCraningServicePricingCategoryName(getServicePricing(i), i) }}</span>
                            </td>

                            <td class="text-center">
                                <input id="service-{{ i }}" type="checkbox" [formControlName]="i" *ngIf="!isDisplayMode()">
                                <input type="checkbox" [disabled]="true" [checked]="s.value" *ngIf="isDisplayMode()">
                            </td>

                            <td class="text-end">
                                <span *ngIf="s.value === true">{{ getServicePrice(i) | currencyFormat }}</span>
                                <span *ngIf="!s.value || getServicePricing(i) === false">{{ '-.-- CHF' }}</span>
                            </td>

                        </tr>

                        <!-- Miscellaneous Billing Item -->
                        <tr *ngIf="isBillingMode()">
                            <td>
                                <div class="input-group m-0">
                                    <span class="justify-content-center align-self-center pe-2">
                                        {{ 'services.craning.booking.form.miscellaneousServiceLabel' | translate }} &#58;
                                    </span>

                                    <textarea class="form-control border" [formControl]="miscellaneousServiceLabel"
                                              placeholder="{{ 'services.craning.booking.form.miscellaneousServicePlaceholder' | translate }}"
                                              rows="1"
                                              maxlength="255"></textarea>
                                </div>

                                <app-form-error [control]="miscellaneousServiceForm"></app-form-error>

                                <p class="text-warning my-1" *ngIf="!craningBookingForm.pristine && !miscellaneousServiceForm.pristine"
                                   translate="services.craning.booking.form.miscellaneousServiceWontBeSavedMessageWarning"></p>
                            </td>

                            <td class="text-center">
                            </td>

                            <td class="text-end">
                                <div class="btn-bar m-0">
                                    <input class="form-control border" type="number" [formControl]="miscellaneousServiceAmount">
                                    <span class="justify-content-center align-self-center">CHF</span>
                                </div>
                                <app-form-error [control]="miscellaneousServiceAmount"></app-form-error>
                            </td>

                        </tr>

                    </tbody>

                    <tfoot>
                        <tr>
                            <td translate>services.craning.booking.form.footer.bookingTotalPrice</td>
                            <td></td>
                            <td [ngClass]="{
                                'text-end': true,
                                'text-warning': getTotalPrice().hasMissingValue
                                }">{{getTotalPrice().price | currencyFormat}}</td>
                        </tr>
                        <tr *ngIf="!selectedBooking?.id">
                            <td translate>services.craning.booking.form.footer.bookingDepositPrice</td>
                            <td></td>
                            <td class="text-end">{{getDepositPrice() | currencyFormat}}</td>
                        </tr>
                        <tr *ngIf="!!selectedBooking?.id">
                            <td translate>services.craning.booking.form.footer.billingDepositPrice</td>
                            <td></td>
                            <td class="text-end">{{(getDepositPrice() * -1) | currencyFormat}}</td>
                        </tr>
                        <tr *ngIf="!!selectedBooking?.id">
                            <td translate>services.craning.booking.form.footer.billingBalanceDue</td>
                            <td></td>
                            <td class="text-end">{{getBillingBalanceDue() | currencyFormat}}</td>
                        </tr>
                    </tfoot>

                </table>
            </div>
        </fieldset>
    </div>

    <!-- Bill details -->
    <div class="row mt-4" *ngIf="selectedSlot?.state === CraningBookingSlotState.Paid">
        <app-craning-booking-form-bill-details-component [crtLang]="crtLang"
                                                         [slotId]="selectedSlot.id"></app-craning-booking-form-bill-details-component>
    </div>

    <!-- Questions Form -->
    <div class="row mt-4" *ngIf="!isBillingMode() && selectedSlot?.state !== CraningBookingSlotState.Paid">
        <fieldset class="col-12 questions">
            <legend>{{'services.craning.booking.form.questions' | translate}} <span class="small">({{ 'services.craning.booking.form.questionsInfo' | translate }})</span></legend>
            <div *ngIf="!hasActiveQuestions()">-</div>

            <ng-container formArrayName="questions" *ngIf="hasActiveQuestions()">
                <ng-container *ngFor="let s of services.controls; let craningServiceIndex=index">
                    <ng-container *ngIf="getServiceQuestionsForServiceFormArray(craningServiceIndex).controls.length > 0
                                            && services.get(craningServiceIndex.toString()).value">

                        <div class="row" formArrayName="{{ craningServiceIndex }}">
                            <div class="col-12"><strong>{{ getCraningServiceLabel(craningServices[craningServiceIndex]) }}</strong></div>

                            <div class="col-12 col-sm-6" *ngFor="let q of getServiceQuestionsForServiceFormArray(craningServiceIndex).controls; let questionIndex=index;">
                                <label class="form-label" for="{{ 'question-' + craningServiceIndex + '-' + questionIndex }}">
                                    {{ getCraningServiceQuestionLabel(craningServices[craningServiceIndex].questions[questionIndex]) }}
                                </label>

                                <div>
                                    <div class="btn-group" role="group"
                                         *ngIf="craningServices[craningServiceIndex].questions[questionIndex].answerType === CraningServiceAnswerType.Boolean">
                                        <input class="btn-check" type="radio"
                                               formControlName="{{ questionIndex }}"
                                               [value]="false"
                                               [attr.disabled]="isDisplayMode() ? '' : null">
                                        <label class="btn btn-toggle btn-toggle-left h-auto"
                                               [ngClass]="{ active: q.value }"
                                               translate="common.yes"
                                               (click)="q.setValue(true); q.markAsDirty();"
                                               (keyup)="q.setValue(true); q.markAsDirty();"></label>

                                        <input class="btn-check" type="radio"
                                               formControlName="{{ questionIndex }}"
                                               [value]="true"
                                               [attr.disabled]="isDisplayMode() ? '' : null">
                                        <label class="btn btn-toggle btn-toggle-right h-auto"
                                               [ngClass]="{ active: q.value === false }"
                                               translate="common.no"
                                               (click)="q.setValue(false); q.markAsDirty();"
                                               (keyup)="q.setValue(false); q.markAsDirty();"></label>
                                    </div>

                                    <input *ngIf="craningServices[craningServiceIndex].questions[questionIndex].answerType === CraningServiceAnswerType.Number && !isDisplayMode()"
                                           class="form-control" id="{{ 'question-' + craningServiceIndex + '-' + questionIndex }}"
                                           formControlName="{{ questionIndex }}"
                                           pattern="{{FormUtils.textInputPattern}}" type="number" step="1"
                                           placeholder="{{ getCraningServiceQuestionLabel(craningServices[craningServiceIndex].questions[questionIndex]) }}"
                                           (keydown)="FormUtils.validateInputNumberLength($event, 10)">

                                    <input *ngIf="craningServices[craningServiceIndex].questions[questionIndex].answerType === CraningServiceAnswerType.Text && !isDisplayMode()"
                                           class="form-control" id="{{ 'question-' + craningServiceIndex + '-' + questionIndex }}"
                                           formControlName="{{ questionIndex }}"
                                           placeholder="{{ getCraningServiceQuestionLabel(craningServices[craningServiceIndex].questions[questionIndex]) }}"
                                           pattern="{{ FormUtils.textInputPattern }}" type="text" maxlength="50">

                                    <span class="form-control" *ngIf="craningServices[craningServiceIndex].questions[questionIndex].answerType !== CraningServiceAnswerType.Boolean && isDisplayMode()">{{q.value}}</span>

                                    <app-form-error [control]="questions.get(craningServiceIndex.toString()).get(questionIndex.toString())"></app-form-error>
                                </div>

                            </div>
                        </div>

                    </ng-container>
                </ng-container>
            </ng-container>

        </fieldset>
    </div>

    <!-- Payment Form-->
    <div class="row mt-4" *ngIf="!!paymentTypeFormGroup && availablePaymentTypes.length > 1">
        <fieldset class="col-12" [formGroupName]="enableableFormControlNames.paymentType">
            <legend translate>services.craning.booking.form.billing.title</legend>

            <div class="row">
                <div class="col-sm-6">
                    <div class="col-12">
                        <app-payment-type-chooser [availablePaymentTypes]="availablePaymentTypes"
                                                  (selectedPaymentType)="selectPaymentType($event)"></app-payment-type-chooser>
                        <app-form-error [control]="paymentType"></app-form-error>
                    </div>
                </div>

            </div>
        </fieldset>
    </div>

    <!-- Action Buttons -->
    <div class="btn-bar">
        <button type="submit" class="btn btn-outline-secondary" *ngIf="!craningBookingForm.pristine && !isCreationMode()"
                (click)="populateForm(selectedBooking)"
                translate>common.cancelChanges
        </button>
        <button type="submit" class="btn btn-primary" *ngIf="canSaveBooking()"
                [disabled]="craningBookingForm.invalid || craningBookingForm.pristine"
                (click)="openSaveBookingConfirmModal()"
                translate>common.save
        </button>
        <button type="submit" class="btn btn-primary" *ngIf="isBillingMode()"
                [disabled]="craningBookingForm.invalid || !craningBookingForm.pristine || miscellaneousServiceForm.invalid"
                (click)="billBooking()"
                translate>common.bill
        </button>
    </div>

</form>

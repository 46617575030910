<div>
    <div class="modal-header">
        <h4 class="modal-title" translate="services.craning.pricing.modal.title"></h4>

        <button class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">

        </button>
    </div>

    <div class="modal-body">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeForm" (navChange)="onActiveFormChange($event.nextId)"
            class="nav-tabs">
            <li ngbNavItem="craningFlatPricingFormTab" *ngIf="isFormTabVisible(PricingType.Flat)">
                <a ngbNavLink translate="services.craning.pricing.modal.tabs.flatPricing"></a>
                <ng-template ngbNavContent>
                    <form [formGroup]="craningFlatPricingForm">

                        <!-- CATEGORY NAMES -->
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <label class="form-label"
                                       for="categoryNameFR">{{ 'services.craning.pricing.categoryNameFR' | translate }}
                                    *</label>
                                <input class="form-control" name="categoryNameFR" id="categoryNameFR" type="text"
                                       maxlength="30"
                                       formControlName="categoryNameFR" pattern="{{FormUtils.textInputPattern}}">
                                <app-form-error [control]="categoryNameFR(craningFlatPricingForm)"></app-form-error>
                            </div>

                            <div class="col-12 col-sm-6"
                                 *ngIf="customerConfigService.isLanguageAvailable('de')">
                                <label class="form-label" for="categoryNameDE"
                                       translate="services.craning.pricing.categoryNameDE"></label>
                                <input class="form-control" name="categoryNameDE" id="categoryNameDE" type="text"
                                       maxlength="30"
                                       formControlName="categoryNameDE" pattern="{{FormUtils.textInputPattern}}">
                            </div>

                            <div class="col-12 col-sm-6"
                                 *ngIf="customerConfigService.isLanguageAvailable('it')">
                                <label class="form-label" for="categoryNameIT"
                                       translate="services.craning.pricing.categoryNameIT"></label>
                                <input class="form-control" name="categoryNameIT" id="categoryNameIT" type="text"
                                       maxlength="30"
                                       formControlName="categoryNameIT" pattern="{{FormUtils.textInputPattern}}">
                            </div>

                            <div class="col-12 col-sm-6"
                                 *ngIf="customerConfigService.isLanguageAvailable('en')">
                                <label class="form-label" for="categoryNameEN"
                                       translate="services.craning.pricing.categoryNameEN"></label>
                                <input class="form-control" name="categoryNameEN" id="categoryNameEN" type="text"
                                       maxlength="30"
                                       formControlName="categoryNameEN" pattern="{{FormUtils.textInputPattern}}">
                            </div>
                        </div>

                        <!-- PRICE -->
                        <div class="row col-12 col-sm-6">
                            <label class="form-label" for="price">
                                {{ 'services.craning.pricing.price' | translate}}*</label>

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">CHF</span>
                                </div>
                                <input class="form-control" name="price" id="price" type="number"
                                       formControlName="price"
                                       min="0"
                                       (keydown)="FormUtils.validateInputNumberLength($event, 10)"/>
                            </div>
                            <div class="col-12">
                                <app-form-error [control]="price(craningFlatPricingForm)"></app-form-error>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </li>

            <li ngbNavItem="craningWeightRangePricingFormTab" *ngIf="isFormTabVisible(PricingType.Weight)">
                <a ngbNavLink translate="services.craning.pricing.modal.tabs.weightRangePricing"></a>
                <ng-template ngbNavContent>
                    <ng-container *ngTemplateOutlet="intervalContentTemplate"></ng-container>
                </ng-template>
            </li>

            <li ngbNavItem="craningLengthRangePricingFormTab" *ngIf="isFormTabVisible(PricingType.Length)">
                <a ngbNavLink translate="services.craning.pricing.modal.tabs.lengthRangePricing"></a>

                <ng-template ngbNavContent>
                    <ng-container *ngTemplateOutlet="intervalContentTemplate"></ng-container>
                </ng-template>
            </li>

            <li ngbNavItem="craningWidthRangePricingFormTab" *ngIf="isFormTabVisible(PricingType.Width)">
                <a ngbNavLink translate="services.craning.pricing.modal.tabs.widthRangePricing"></a>
                <ng-template ngbNavContent>
                    <ng-container *ngTemplateOutlet="intervalContentTemplate"></ng-container>
                </ng-template>
            </li>

            <li ngbNavItem="craningAreaRangePricingFormTab" *ngIf="isFormTabVisible(PricingType.Area)">
                <a ngbNavLink translate="services.craning.pricing.modal.tabs.areaRangePricing"></a>
                <ng-template ngbNavContent>
                    <ng-container *ngTemplateOutlet="intervalContentTemplate"></ng-container>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>

    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-secondary" *ngIf="!selectedRangePricingToEdit"
                (click)="activeModal.close()"
                translate="common.close">
        </button>

        <button class="btn btn-danger" type="submit"
                *ngIf="activeForm === 'craningFlatPricingFormTab' && !!flatPricing.id"
                (click)="deleteCraningServicePricing(flatPricing.id, true)" translate="common.delete">
        </button>

        <button class="btn btn-primary" type="submit" *ngIf="activeForm === 'craningFlatPricingFormTab'"
                [disabled]="craningFlatPricingForm.invalid"
                (click)="addOrUpdateCraningPricing(craningFlatPricingForm, flatPricing.id)">
            {{ (!flatPricing.id ? 'common.add' : 'common.save') | translate }}
        </button>

        <button type="submit" class="btn btn-outline-secondary" *ngIf="!!selectedRangePricingToEdit"
                (click)="cancelCraningPricing()"
                [translate]="craningPricings.length <= 0 ? 'common.close' : 'common.cancel'">
        </button>

        <button class="btn btn-primary" type="submit"
                *ngIf="activeForm !== 'craningFlatPricingFormTab' && !!selectedRangePricingToEdit"
                [disabled]="craningRangePricingForm.invalid"
                (click)="addOrUpdateCraningPricing(craningRangePricingForm, selectedRangePricingToEdit?.id)">
            {{ (!selectedRangePricingToEdit?.id ? 'common.add' : 'common.save') | translate }}
        </button>
    </div>
</div>

<ng-template #intervalContentTemplate>
    <form [formGroup]="craningRangePricingForm" *ngIf="!!selectedRangePricingToEdit">

        <!-- CATEGORY NAMES -->
        <div class="row">
            <div class="col-12 col-sm-6">
                <label class="form-label" for="wrCategoryNameFR">
                    {{ 'services.craning.pricing.categoryNameFR' | translate }}*</label>
                <input class="form-control" name="categoryNameFR" id="wrCategoryNameFR" type="text" maxlength="30"
                       formControlName="categoryNameFR" pattern="{{FormUtils.textInputPattern}}">
                <app-form-error [control]="categoryNameFR(craningRangePricingForm)"></app-form-error>
            </div>

            <div class="col-12 col-sm-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <label class="form-label" for="wrCategoryNameDE"
                       translate="services.craning.pricing.categoryNameDE"></label>
                <input class="form-control" name="categoryNameDE" id="wrCategoryNameDE" type="text" maxlength="30"
                       formControlName="categoryNameDE" pattern="{{FormUtils.textInputPattern}}">
            </div>

            <div class="col-12 col-sm-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <label class="form-label" for="wrCategoryNameIT"
                       translate="services.craning.pricing.categoryNameIT"></label>
                <input class="form-control" name="categoryNameIT" id="wrCategoryNameIT" type="text" maxlength="30"
                       formControlName="categoryNameIT" pattern="{{FormUtils.textInputPattern}}">
            </div>

            <div class="col-12 col-sm-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <label class="form-label" for="wrCategoryNameEN"
                       translate="services.craning.pricing.categoryNameEN"></label>
                <input class="form-control" name="categoryNameEN" id="wrCategoryNameEN" type="text" maxlength="30"
                       formControlName="categoryNameEN" pattern="{{FormUtils.textInputPattern}}">
            </div>
        </div>

        <div class="row">

            <!-- PRICE -->
            <div class="col-12 col-sm-4">
                <label class="form-label" for="wrPrice">{{ 'services.craning.pricing.price' | translate}}*</label>
                <div class="input-group">
                    <span class="input-group-text">CHF</span>
                    <input class="form-control" name="price" id="wrPrice" type="number"
                           formControlName="price"
                           min="0"
                           (keydown)="FormUtils.validateInputNumberLength($event, 10)"/>
                </div>
                <app-form-error [control]="price(craningRangePricingForm)"></app-form-error>
            </div>

            <!-- RANGE -->
            <ng-container [formGroup]="range">
                <div class="col-12 col-sm-4">
                    <label class="form-label" for="min">
                        {{ 'services.craning.pricing.min' | translate }}*</label>
                    <div class="input-group">
                        <span class="input-group-text">{{getRangeUnit()}}</span>
                        <input class="form-control" name="min" id="min" type="number"
                               formControlName="min"
                               min="0"
                               (keydown)="FormUtils.validateInputNumberLength($event, 10)"/>
                    </div>
                    <app-form-error [control]="min"></app-form-error>
                    <p class="col-12" *ngIf="!isRangeValid()">
                        <small class="text-danger" translate="error.network.businessException.1013"></small>
                    </p>
                </div>

                <div class="col-12 col-sm-4">
                    <label class="form-label" for="max">
                        {{ 'services.craning.pricing.max' | translate }}*</label>
                    <div class="input-group">
                        <span class="input-group-text">{{getRangeUnit()}}</span>
                        <input class="form-control" name="max" id="max" type="number"
                               formControlName="max"
                               min="0"
                               (keydown)="FormUtils.validateInputNumberLength($event, 10)"/>
                    </div>
                    <app-form-error [control]="max"></app-form-error>
                </div>
            </ng-container>
        </div>
    </form>

    <div *ngIf="!selectedRangePricingToEdit">
        <div class="btn-bar">
            <button class="btn btn-primary" type="button"
                    (click)="createCraningPricing()"
                    translate="services.craning.pricing.actions.add">
            </button>
        </div>

        <div class="table-responsive">
            <table class="table table-striped" *ngIf="rangePricings.length > 0"
                   [attr.aria-label]="'services.craning.service.pricings' | translate">

                <thead>
                <tr>
                    <th scope="col" style="min-width: 30%" translate="services.craning.pricing.categoryName"></th>
                    <th scope="col" style="min-width: 20%" translate="services.craning.pricing.min"></th>
                    <th scope="col" style="min-width: 20%" translate="services.craning.pricing.max"></th>
                    <th scope="col" style="min-width: 20%" translate="services.craning.pricing.price"></th>
                    <th scope="col" style="min-width:115px"></th>
                </tr>
                </thead>

                <tbody>
                <tr *ngFor="let pricing of rangePricings;">

                    <td>{{ getCraningPricingCategoryName(pricing) }}</td>
                    <td>
                        {{pricing.min | numberFormat: GesConstants.defaultNumeralFormat }} {{getRangeUnit()}}
                    </td>
                    <td>
                        {{pricing.max | numberFormat: GesConstants.defaultNumeralFormat }} {{getRangeUnit()}}
                    </td>
                    <td>
                        {{pricing.price | currencyFormat}}
                    </td>

                    <td class="text-end">
                        <button class="btn btn-link btn-sm" type="button"
                                (click)="updateCraningPricing(pricing.id)"
                                title="{{'common.tooltip.edit' | translate}}">
                            <fa-icon [icon]="icons.edit"></fa-icon>
                        </button>

                        <button class="btn btn-link btn-sm" type="button"
                                (click)="deleteCraningServicePricing(pricing.id, false)"
                                title="{{'common.tooltip.delete' | translate}}">
                            <fa-icon [icon]="icons.delete"></fa-icon>
                        </button>
                    </td>

                </tr>
                </tbody>

            </table>
        </div>
    </div>
</ng-template>

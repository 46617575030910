import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AvailabilityRangeModalComponent} from './availability-range-modal/availability-range-modal.component';
import {
    AvailabilityRangeDto,
    AvailabilityRangeService
} from '../../../_services/configuration-services';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../_shared/notifications.service';
import {DateUtils} from '../../../_shared/date-utils';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-availability-range',
    templateUrl: './availability-range.component.html'
})
export class AvailabilityRangeComponent implements OnInit, OnDestroy {

    @Input() serviceId: string;

    availabilityRanges: AvailabilityRangeDto[] = [];

    dateFormat = DateUtils.dateFormat;
    icons = {
        edit: faPen,
        delete: faTrash
    };

    constructor(private readonly availabilityRangeService: AvailabilityRangeService,
                private readonly notificationsService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                private readonly modalService: NgbModal) { }

    ngOnInit(): void {
        this.fetchRanges();
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll('destroy');
    }

    createOrEditAvailabilityRange(): void {
        const modal = this.modalService.open(AvailabilityRangeModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.result
            .then(async value => {
                if (value === 'createOrUpdate') {
                    this.notificationsService.success({title: 'services.availabilityRange.notifications.addSuccess'});
                    this.fetchRanges();
                }
            }, () => { /* catch the rejection */ });
    }

    updateAvailabilityRange(availabilityRange: AvailabilityRangeDto): void {
        const modal = this.modalService.open(AvailabilityRangeModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.availabilityRange = availabilityRange;
        modal.result
            .then(async value => {
                if (value === 'createOrUpdate') {
                    this.notificationsService.success({title: 'services.availabilityRange.notifications.updateSuccess'});
                    this.fetchRanges();
                }
            }, () => { /* catch the rejection */ });
    }

    deleteAvailabilityRange(availabilityRange: AvailabilityRangeDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.availabilityRangeService.deleteAvailabilityRange(availabilityRange.id).pipe().subscribe(_ => {
                    this.fetchRanges();
                    this.notificationsService.success({title: 'services.availabilityRange.notifications.deleteSuccess'});
                });
            }
        });
    }

    sortAvailabilityRange(): void {
        this.availabilityRanges.sort((a, b) => {
            if (a.startDate.getDate() === b.startDate.getDate()){
                // Sort by EndDate
                if (!a.endDate){
                    return 1;

                } else if (!b.endDate){
                    return -1;
                }
                return a.endDate.getDate() - b.endDate.getDate();

            } else {
                // Sort by StartDate
                return a.startDate.getDate() - b.startDate.getDate();
            }
        });
    }

    formatTimeStringToDisplay(time: string): string {
        return DateUtils.formatTime(time, 'h');
    }

    private fetchRanges(): void {
        this.availabilityRangeService.getAvailabilityRangesForService(this.serviceId).pipe().subscribe(availabilityRanges => {
            this.availabilityRanges = availabilityRanges;
            this.sortAvailabilityRange();
        });
    }

}

<div *ngIf="kocoDevices">
    <h5 translate="services.kocoDumpster.stateTitle"></h5>

    <div class="row" *ngFor="let kocoDevice of kocoDevices">
        <h6 class="col-6"><strong>{{ kocoDevice['name' + crtLang.toUpperCase()] }}</strong></h6>

        <h6 class="col-6">
            <span class="dot gray-border red" *ngIf="kocoDevice.deviceState === DeviceStateEnum.Error"></span>
            <span class="dot gray-border green" *ngIf="kocoDevice.deviceState === DeviceStateEnum.Ready"></span>
            <span class="dot gray-border gray"
                  *ngIf="kocoDevice.deviceState === DeviceStateEnum.Unknown"></span>
            {{ 'services.kocoDumpster.enum.' + kocoDevice.deviceState | translate }}
        </h6>

        <div class="col-12 text-muted small">{{ 'services.kocoDumpster.message.state' | translate }}
            : {{ (kocoDevice.lastStateChanged | date : format) ?? '-' }}</div>

        <div class="col-12 mb-4">{{ kocoDevice['description' + crtLang.toUpperCase()] }}</div>
    </div>

    <div *ngIf="kocoDevices.length < 1">
        <p translate="services.kocoDumpster.message.noDataAvailable"></p>
    </div>
</div>

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {
    MeasuringPointLightDto,
    MeasuringPointService,
    WeatherDataDto,
    WeatherService,
    WeatherTrend
} from '../../../_services/configuration-services';
import {faArrowCircleDown, faArrowCircleUp} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-weather-cockpit',
    templateUrl: './weather-cockpit.component.html',
    styleUrls: ['./weather-cockpit.component.scss']
})
export class WeatherCockpitComponent implements OnInit, OnDestroy {

    @Input() serviceId!: string;

    WeatherTrend = WeatherTrend;

    measuringPoints: MeasuringPointLightDto[] = [];
    selectedMeasuringPoint: MeasuringPointLightDto | null = null;
    weatherData: WeatherDataDto | null = null;

    icons = {
        up: faArrowCircleUp,
        down: faArrowCircleDown
    }

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(private readonly translateService: TranslateService,
                private readonly measuringPointService: MeasuringPointService,
                private readonly weatherService: WeatherService) { }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.fetchWeatherStations();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    fetchWeatherStations(): void {
        this.measuringPointService.getAdminMeasuringPointsForService(this.serviceId, this.crtLang).pipe().subscribe(measuringPoints => {
            this.measuringPoints = measuringPoints;

            if (this.measuringPoints.length >= 1) {
                this.selectedMeasuringPoint = this.measuringPoints[0];
                this.fetchWeatherData();
            }
        });
    }

    fetchWeatherData(): void {
        if (!!this.selectedMeasuringPoint) {
            this.weatherService.getWeatherData(this.selectedMeasuringPoint.id).pipe().subscribe(weatherData => {
                this.weatherData = weatherData;
            });
        }
    }

}

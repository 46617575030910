<div class="content-container bg-white" *ngIf="craningBookingUtils.hasAdminView()">
    <h2 *ngIf="!craningBookingId">
        {{'services.craning.booking.print.period' | translate :{
        from: from | date : formats.date,
        to: to | date : formats.date
    }
        }}</h2>
    <ng-container *ngIf="!!craningBookingId">
        <div class="booking single" *ngFor="let booking of craningBookings">
            <div class="row">
                <div class="col-sm-6">
                    <h3 translate>services.craning.booking.print.slot.title</h3>
                    <table class="table table-borderless slot"
                           aria-hidden="true">
                        <tbody>
                        <tr>
                            <td translate>services.craning.booking.print.slot.date</td>
                            <td translate>services.craning.booking.print.slot.time</td>
                            <td translate>services.craning.booking.print.slot.duration</td>
                        </tr>
                        <tr>
                            <td>{{booking.craningBookingSlot.date | date : formats.date}}</td>
                            <td>{{DateUtils.formatTime(booking.craningBookingSlot.startTime)}}</td>
                            <td>{{DateUtils.formatTime(craningBookingUtils.calculateCraningBookingDuration(booking.craningBookingSlot))}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row">

                <div class="col-sm-6">
                    <h3 translate>services.craning.booking.form.debtor.other</h3>
                    <table class="table table-borderless no-padding"
                           aria-hidden="true">
                        <tbody>
                        <tr>
                            <td translate>services.craning.booking.form.debtor.name</td>
                            <td>{{booking.user?.firstName || booking.visitorFirstName}} {{booking.user?.lastName || booking.visitorLastName}}</td>
                        </tr>
                        <tr>
                            <td translate>bookings.modal.user.street</td>
                            <td>{{booking.user?.street || booking.visitorStreet}}</td>
                        </tr>
                        <tr>
                            <td translate>bookings.modal.user.city</td>
                            <td>{{booking.user?.postalCode || booking.visitorPostalCode}} {{booking.user?.city || booking.visitorCity}}</td>
                        </tr>
                        <tr>
                            <td translate>bookings.modal.user.country</td>
                            <td>{{booking.user?.country || booking.visitorCountry}}</td>
                        </tr>
                        <tr>
                            <td translate>bookings.modal.user.email</td>
                            <td>{{booking.user?.email || booking.visitorEmail}}</td>
                        </tr>
                        <tr>
                            <td translate>bookings.modal.user.phoneNumber</td>
                            <td>{{booking.user?.phone || booking.visitorMobilePhone}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-sm-6">
                    <h3 translate>mooringPlace.modal.boatInformationTitle</h3>
                    <table class="table table-borderless boat"
                           aria-hidden="true">
                        <tbody>
                        <tr>
                            <td translate>mooringPlace.modal.boatRegistrationNumber</td>
                            <td>{{craningBookingUtils.getBoatLabel(booking.mooringPlace) || booking.visitorBoatRegistrationNumber}}</td>
                        </tr>
                        <tr>
                            <td translate>mooringPlace.modal.boatWeight</td>
                            <td> {{(booking.mooringPlace?.boatWeightInKg || booking.visitorBoatWeightInKg) | numberFormat: GesConstants.defaultNumeralFormat}} kg</td>
                        </tr>
                        <tr>
                            <td translate>services.craning.booking.form.boatDimensions</td>
                            <td>{{(booking.mooringPlace?.boatWidthInMeter || booking.visitorBoatWidthInMeter) | numberFormat: GesConstants.defaultNumeralFormat}} m
                                x {{(booking.mooringPlace?.boatLengthInMeter || booking.visitorBoatLengthInMeter) | numberFormat: GesConstants.defaultNumeralFormat}} m</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <h3 translate>services.craning.booking.form.services</h3>
                    <table class="table table-borderless craning-services"
                           aria-hidden="true">
                        <tbody>
                        <ng-container *ngFor="let craningService of craningServices">
                            <ng-container *ngIf="isCraningServiceChosen(craningService, booking)">
                                <tr class="craning-service">
                                    <td colspan="2">{{ getCraningServiceLabel(craningService) }}</td>
                                </tr>
                                <tr class="question" *ngFor="let question of craningService.questions">
                                    <td class="question">{{ getCraningServiceQuestionLabel(question) }}</td>
                                    <td class="answer">
                                        <div
                                            *ngIf="!!getCraningServiceQuestionAnswer(craningService, question, booking); else hasAnswerElseBlock">
                                            <div
                                                *ngIf="question.answerType === CraningServiceAnswerType.Boolean; else isBooleanElseBlock">
                                                <ng-container
                                                    *ngIf="getCraningServiceQuestionAnswer(craningService, question, booking) === 'true'; else isAnswerTrueElseBlock">
                                                    {{ 'common.yes' | translate }}
                                                </ng-container>
                                                <ng-template #isAnswerTrueElseBlock>
                                                    {{ 'common.no' | translate }}
                                                </ng-template>
                                            </div>
                                            <ng-template #isBooleanElseBlock>
                                                {{getCraningServiceQuestionAnswer(craningService, question, booking)}}
                                            </ng-template>
                                        </div>
                                        <ng-template #hasAnswerElseBlock>
                                            -
                                        </ng-template>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!craningBookingId && craningBookings.length > 0">
        <ul class="booking multiple">
            <li *ngFor="let booking of craningBookings">
                <div class="customer">
                    <span class="time">
                        {{DateUtils.formatTime(booking.craningBookingSlot.startTime)}}
                    </span>
                    <span class="date">
                        {{booking.craningBookingSlot.date | date : formats.date}}
                    </span>
                    <span>
                        {{booking.user?.firstName || booking.visitorFirstName}} {{booking.user?.lastName || booking.visitorLastName}}
                    </span>
                    <span>
                        {{booking.user?.phone || booking.visitorMobilePhone}}
                    </span>
                </div>
                <div class="boat">
                    <span>
                        {{booking.mooringPlace?.boatRegistrationNumber || booking.visitorBoatRegistrationNumber}}
                    </span>
                    <span>
                        {{(booking.mooringPlace?.boatWeightInKg || booking.visitorBoatWeightInKg) | numberFormat: GesConstants.defaultNumeralFormat}} kg
                    </span>
                </div>
                <ul class="services">
                    <ng-container *ngFor="let craningService of craningServices">
                        <ng-container *ngIf="isCraningServiceChosen(craningService, booking)">
                            <li>
                                <span class="service">
                                    {{ getCraningServiceLabel(craningService) }}
                                </span>
                                <ng-container *ngIf="craningService.questions.length > 0">
                                    <ng-container *ngFor="let question of craningService.questions">

                                        <ng-container
                                            *ngIf="!!getCraningServiceQuestionAnswer(craningService, question, booking)">
                                            <span class="question">
                                                <span class="label">
                                                    {{ getCraningServiceQuestionLabel(question) }}
                                                </span>
                                                <span class="answer">
                                                    <ng-container [ngSwitch]="question.answerType">
                                                        <ng-container *ngSwitchCase="CraningServiceAnswerType.Boolean">
                                                            <ng-container *ngIf="getCraningServiceQuestionAnswer(craningService, question, booking) === 'true'; else falseAnswerBlock">
                                                                {{'common.yes' | translate | uppercase}}
                                                            </ng-container>
                                                            <ng-template #falseAnswerBlock>
                                                                {{'common.no' | translate | uppercase}}
                                                            </ng-template>
                                                        </ng-container>
                                                        <ng-container *ngSwitchDefault>
                                                            {{getCraningServiceQuestionAnswer(craningService, question, booking)}}
                                                        </ng-container>
                                                    </ng-container>
                                                </span>
                                            </span>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>
            </li>
        </ul>
    </ng-container>
</div>

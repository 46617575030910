import {Component, Input, OnInit} from '@angular/core';
import {ManageMapModalComponent} from './manage-map-modal/manage-map-modal.component';
import {MapDto, MapService} from '../../../_services/configuration-services';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-manage-map',
  templateUrl: './manage-map.component.html'
})
export class ManageMapComponent implements OnInit {

    @Input() serviceId: string;

    mapDto: MapDto | null = null;

    constructor(private readonly mapService: MapService,
                private readonly modalService: NgbModal) {
    }

    async ngOnInit(): Promise<void> {
        this.mapDto = await firstValueFrom(this.mapService.getMap(this.serviceId));
    }

    openManageMapModal(): void {
        const modal = this.modalService.open(ManageMapModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.mapDto = this.mapDto;

        modal.result
            .finally(() => {
                this.mapService.getMap(this.serviceId).pipe().subscribe(value => this.mapDto = value);
                // Do not reload the page here because it will not works when creating a new service
            });
    }

}

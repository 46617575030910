<div>
    <div class="modal-header">
        <h4 class="modal-title" translate="services.pottinger.statistic.export.modal.title"></h4>

        <button class="btn-close" aria-label="Close"
                (click)="exportPottingerStatisticModal.dismiss('Cross click')">
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="exportPottingerStatisticForm">

            <!-- date period -->
            <div class="row">

                <div class="col-12">
                    <app-date-period-picker [required]="false"
                                            [fromMaxDate]="maxDate"
                                            [toMaxDate]="maxDate"
                                            inputLabel="services.pottinger.statistic.export.modal.period"
                                            [periodControl]="datePeriod"></app-date-period-picker>
                </div>
            </div>

        </form>
    </div>

    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-secondary"
                (click)="exportPottingerStatisticModal.close()"
                translate="common.close">
        </button>

        <button class="btn btn-primary" type="submit"
                [disabled]="exportPottingerStatisticForm.invalid"
                (click)="exportPottingerStatistics()"
                translate="common.export">
        </button>
    </div>
</div>


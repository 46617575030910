import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {faEye, faTrash} from '@fortawesome/free-solid-svg-icons';

import {
    ISortCriteriaDto,
    MooringPlaceCriteriaDto,
    MooringPlaceLightDto,
    MooringPlaceSearchCriteriaDto,
    MooringPlaceService,
    PageableRequestDtoOfMooringPlaceCriteriaDto,
    PagedResultDtoOfMooringPlaceLightDto,
    SearchUserDto,
    SortCriteriaDto,
    SortDirection,
    UserLightDto,
    UserService,
} from '../../../_services/configuration-services';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MooringPlaceViewModalComponent} from '../../../_shared/_components/mooring-place-view-modal/mooring-place-view-modal.component';
import {NotificationsService} from '../../../_shared/notifications.service';
import {SearchUserComponent} from '../../../_shared/_components/search-user/search-user.component';
import {FormUtils} from '../../../_shared/form-utils';
import {firstValueFrom} from 'rxjs';

@Component({
    selector: 'app-mooring-place',
    templateUrl: './mooring-place.component.html'
})
export class MooringPlaceComponent implements OnInit {

    @Input() serviceId: string;

    @ViewChild('searchUserComponent') searchUser: SearchUserComponent;
    @ViewChild('mooringPlaceTable') table: any;

    mooringPlaces: PagedResultDtoOfMooringPlaceLightDto = new PagedResultDtoOfMooringPlaceLightDto();
    crtPage = 0;
    readonly pageSize = 10;
    readonly defaultSortProperty = 'placeId';


    faEye = faEye;
    faTrash = faTrash;
    textInputPattern = FormUtils.textInputPattern;

    public pageRequest: PageableRequestDtoOfMooringPlaceCriteriaDto;

    constructor(
        private readonly mooringPlacesService: MooringPlaceService,
        private readonly userService: UserService,
        private readonly notificationsService: NotificationsService,
        private readonly modalService: NgbModal) {

        this.pageRequest = new PageableRequestDtoOfMooringPlaceCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new MooringPlaceCriteriaDto({
            searchCriteriaDto: new MooringPlaceSearchCriteriaDto({
                placeId: null,
                placeNum: null,
                pontoon: null,
                boatRegistrationNumber: null,
                userId: null,
            }),
            sortCriteria: new SortCriteriaDto({
                direction: SortDirection.Asc,
                property: this.defaultSortProperty
            })
        });
    }

    ngOnInit(): void {
        this.searchMooringPlaces();
    }

    private searchMooringPlaces(): void {
        this.mooringPlacesService.searchMooringPlaces(this.pageRequest).pipe().subscribe(mooringPlaces => {
            this.mooringPlaces = mooringPlaces;
        });
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;
        this.searchMooringPlaces();
    }

    onSort(sortingValues: any): void {
        this.goToFirstPage();
        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortingValues.sorts[0].dir,
                property: sortingValues.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteria = new SortCriteriaDto(sortCriteriaDto);
        this.searchMooringPlaces();
    }

    getRowHeight(): number {
        return 50;
    }

    userSelected(user: SearchUserDto): void {
        this.pageRequest.criteria.searchCriteriaDto.userId = user?.id ?? null;
        this.searchCriteriaChanged();
    }

    searchCriteriaChanged(): void {
        this.goToFirstPage();
        this.searchMooringPlaces();
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }

    getRowClass = (user: UserLightDto) => {
        return {
            'row-color': !user.accountEnabled,
        };
    }

    clearFilter(): void {
        this.searchUser.clearInput();

        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new MooringPlaceCriteriaDto({
            searchCriteriaDto: new MooringPlaceSearchCriteriaDto({
                placeId: null,
                placeNum: null,
                pontoon: null,
                boatRegistrationNumber: null,
                userId: null,
            }),
            sortCriteria: new SortCriteriaDto({
                direction: SortDirection.Asc,
                property: this.defaultSortProperty
            })
        });

        this.goToFirstPage();
        this.searchMooringPlaces();
    }

    isFiltering(): boolean {
        const searchCriteriaDto = this.pageRequest.criteria.searchCriteriaDto;
        return !!searchCriteriaDto.placeId ||
            !!searchCriteriaDto.placeNum ||
            !!searchCriteriaDto.pontoon ||
            !!searchCriteriaDto.boatRegistrationNumber ||
            !!searchCriteriaDto.userId;
    }

    async showDetails(mpl: MooringPlaceLightDto, event: any): Promise<void> {
        event.target.closest('datatable-body-cell').blur();

        const mp = await firstValueFrom(this.mooringPlacesService.getMooringPlace(mpl.placeId));
        let user = null;
        if (mpl.userId) {
            user = await firstValueFrom(this.userService.getUser(mpl.userId));
        }
        const modal = this.modalService.open(MooringPlaceViewModalComponent, {centered: true});
        modal.componentInstance.mooringPlace = mp;
        modal.componentInstance.user = user;

        modal.result.then(res => {
            if (res === 'success') {
                this.searchMooringPlaces();
            }
        });
    }
}

<div class="content-container bg-white-no-shadow">

    <h4 translate="services.wifi.subscriptions.title"></h4>

    <form [formGroup]="filter.formGroup">

        <!-- Filters -->
        <div class="row">

            <!-- Service Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="services.wifi.subscriptions.filter.service"></label>
                <ng-multiselect-dropdown id="serviceIds"
                                         formControlName="serviceIds"
                                         placeholder="{{'common.multiselect.placeholder' | translate}}"
                                         [data]="filter.servicesDropdownList.items"
                                         [settings]="filter.servicesDropdownList.settings">
                </ng-multiselect-dropdown>
            </div>

            <!-- User Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="services.wifi.subscriptions.filter.user"></label>
                <app-search-user id="userId" #userSearch
                                 formControlName="userId"
                                 ngDefaultControl
                                 (selectedUserEvent)="selectUser($event)"></app-search-user>
            </div>

            <!-- States Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="services.wifi.subscriptions.filter.state"></label>
                <select class="form-control form-select" id="state"
                        formControlName="state">
                    <option [ngValue]="null">{{'services.wifi.subscriptions.state.empty' | translate}}</option>
                    <option *ngFor="let state of filter.statesDropdownList.items"
                            [value]="state">{{'services.wifi.subscriptions.state.' + state | translate}}</option>
                </select>
            </div>

            <!-- From / To-->
            <div class="default-filter-form-field" formGroupName="period">
                <app-date-period-picker [periodControl]="getPeriodFromGroup()"
                                        inputLabel="services.wifi.subscriptions.filter.date"></app-date-period-picker>
            </div>

        </div>

        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   (click)="clearFilter()">

            <input class="btn btn-primary" type="button" (click)="applyWifiSubscriptionFilters()"
                   [value]="'services.wifi.subscriptions.filter.filter' | translate" [disabled]="!(filter.formGroup.valid)">
        </div>

    </form>

    <ngx-datatable #subscriptionTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="wifiSubscriptions.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   rowHeight="50"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="wifiSubscriptions.rowCount"
                   [sorts]="[{prop: 'StartDateTime', dir: 'desc'}]"
                   [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                }"
                   [offset]="crtPage"
                   [limit]="wifiSubscriptions.pageSize"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [scrollbarH]="true">

        <ngx-datatable-column [width]="150" sortable="true" prop="serviceName{{crtLang.toUpperCase()}}"
                              name="{{'services.wifi.subscriptions.table.service' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="startDateTime" name="{{'services.wifi.subscriptions.table.startDateTime' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.startDateTime | date:dateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="endDateTime" name="{{'services.wifi.subscriptions.table.endDateTime' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.endDateTime | date:dateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="210" prop="user" name="{{'services.wifi.subscriptions.table.user' | translate}}"
                              sortable="true">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="state" name="{{'services.wifi.subscriptions.table.state' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{'services.wifi.subscriptions.state.' + row.state | translate}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="75" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <button *ngIf="canBeDisabled(row)" class="btn btn-link btn-sm"
                        title="{{'common.tooltip.disable' | translate}}"
                        (click)="disableSubscription(row)">
                    <fa-icon [icon]="icons.disable"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div class="page-count">
                    {{ rowCount }} {{ 'common.datatable.total' | translate }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="table.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

</div>


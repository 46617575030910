<div class="content-container bg-white-no-shadow">

    <!-- Cockpit Selector -->
    <div [appHasAnyRole]="[AppRoles.admin, AppRoles.harbourmaster]">
        <div class="btn-group" role="group">
            <input class="btn-check" type="radio"
                   [value]="CraningView.BookingView">
            <label class="btn btn-toggle btn-toggle-left h-auto"
                   [ngClass]="{ active: craningView === CraningView.BookingView }"
                   translate="services.craning.viewSelector.booking"
                   (click)="changeCraningView(CraningView.BookingView)"
                   (keyup)="changeCraningView(CraningView.BookingView)"></label>

            <input class="btn-check" type="radio"
                   [value]="CraningView.BillingView">
            <label class="btn btn-toggle btn-toggle-right h-auto"
                   [ngClass]="{ active: craningView === CraningView.BillingView }"
                   translate="services.craning.viewSelector.billing"
                   (click)="changeCraningView(CraningView.BillingView)"
                   (keyup)="changeCraningView(CraningView.BillingView)"></label>
        </div>
    </div>

    <app-craning-booking *ngIf="craningView === CraningView.BookingView"
                         [service]="service"></app-craning-booking>

    <app-craning-billing *ngIf="craningView === CraningView.BillingView && craningBookingUtils.hasAdminView()"
                         [service]="service"></app-craning-billing>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {
    ItemToBookAnswerDto,
    ItemToBookAnswerInputDto,
    ItemToBookAnswerType,
    ItemToBookDto,
    ItemToBookQuestionDto,
    ItemToBookQuestionService,
    ReservationType
} from '../../../../../_services/configuration-services';
import {FormUtils} from '../../../../../_shared/form-utils';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateUtils} from '../../../../../_shared/translate-utils';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-item-with-questions-answers-form',
  templateUrl: './item-with-questions-answers-form.component.html'
})
export class ItemWithQuestionsAnswersFormComponent implements OnInit {

    @Input() availableItems = new Array<ItemToBookDto>();
    @Input() itemWithAnswerForm: FormGroup<ItemWithAnswerForm> = null!;
    @Input() crtLang = TranslateUtils.defaultLanguage;
    @Input() disableItemToBook = false;

    @Input()
    set answers(answers: ItemToBookAnswerDto[]) {
        this._answers = answers ?? [];
    }
    private _answers: ItemToBookAnswerDto[] = [];

    @Input()
    set selectedItemId(itemId: string | null) {
        this._selectedItemId = itemId;
        if (this.itemWithAnswerForm) {
            this.selectItem(itemId);
        }
    }
    private _selectedItemId: string | null = null;

    @Input()
    get reservationType(): ReservationType {
        return this._reservationType;
    }
    set reservationType(reservationType: ReservationType) {
        this._reservationType = reservationType;
        if (this.itemWithAnswerForm) {
            this.onReservationTypeChanged();
        }
    }
    private _reservationType = ReservationType.Booking

    ItemToBookAnswerType = ItemToBookAnswerType;
    FormUtils = FormUtils;

    questions: ItemToBookQuestionDto[] = [];

    constructor(private readonly itemToBookQuestionService: ItemToBookQuestionService) {

    }

    async ngOnInit(): Promise<void> {
        const selectedItem = this.availableItems.find(i => i.id === (this._selectedItemId ?? this.availableItems[0].id));

        this.itemWithAnswerForm.addControl('itemToBook',
            new FormControl({ value: selectedItem, disabled: this.disableItemToBook }, Validators.required));
        this.itemWithAnswerForm.addControl('answers',
            new FormArray<FormControl<string>>([]));

        await this.fetchQuestionsForItem();
    }

    async fetchQuestionsForItem(): Promise<void> {
        this.answersForm.clear();

        if (this.itemToBook.value) {
            const questions = await firstValueFrom(this.itemToBookQuestionService.getAll(this.itemToBook.value.id));
            this.questions = questions;

            for (const question of questions) {
                if (question.answerType === ItemToBookAnswerType.Number) {
                    this.answersForm.push(new FormControl<string>(
                        null, [Validators.required, FormUtils.numberDecimalAllowingNegativePatternValidator])
                    );

                } else if (question.answerType === ItemToBookAnswerType.Text) {
                    this.answersForm.push(new FormControl<string>(
                        null, [Validators.required, Validators.maxLength(50)]
                    ));

                } else {
                    this.answersForm.push(new FormControl<string>(null, Validators.required))
                }
            }

            if (this.reservationType === ReservationType.Blocking) {
                this.answersForm.disable()
            }

            this.populateAnswerForm();
        }
    }

    getAnswers(): ItemToBookAnswerInputDto[] {
        const answers: ItemToBookAnswerInputDto[] = [];

        if (this.answersForm.enabled) {
            for (let questionIndex = 0; questionIndex < this.questions.length; questionIndex++) {
                answers.push(new ItemToBookAnswerInputDto({
                    itemQuestionId: this.questions[questionIndex].id,
                    answer: this.answersForm.controls[questionIndex].value.toString()
                }))
            }
        }
        return answers;
    }

    private populateAnswerForm(): void {
        for (let i = 0; i < this.questions.length; i++) {
            const answer = this._answers?.find(a => a.itemQuestionId === this.questions[i].id);
            if (answer) {
                this.answerControls[i].setValue(answer.answer);
            }
        }
    }

    private async selectItem(itemId: string | null): Promise<void> {
        if (itemId && itemId !== this.itemToBook.value.id) {
            this.itemToBook.setValue(this.availableItems.find(i => i.id === itemId));
            await this.fetchQuestionsForItem();
        }
    }

    private onReservationTypeChanged(): void {
        if (this._reservationType === ReservationType.Blocking) {
            this.answersForm.disable();

        } else {
            this.answersForm.enable();
        }
    }

    get itemToBook(): FormControl<ItemToBookDto> {
        return this.itemWithAnswerForm.get('itemToBook') as FormControl<ItemToBookDto>;
    }

    get answersForm(): FormArray<FormControl<string>> {
        return this.itemWithAnswerForm.get('answers') as FormArray<FormControl<string>>;
    }

    get answerControls(): FormControl<string>[] {
        return this.answersForm.controls;
    }
}

export interface ItemWithAnswerForm {
    itemToBook: FormControl<ItemToBookDto>,
    answers: FormArray<FormControl<string>>,
}

import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {RolesService} from './roles-service';

export const RoleGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> => {

    const rolesService = inject(RolesService);

    const allowedRoles = route.data['allowedRoles'];
    if (allowedRoles !== undefined) {
        return rolesService.hasAnyRole(allowedRoles);
    }
    return true;
}

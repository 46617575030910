import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup, ValidationErrors, ValidatorFn,
    Validators
} from '@angular/forms';
import {FormUtils} from '../../../../../_shared/form-utils';
import {RolesService} from '../../../../../_shared/roles-service';
import {Observable, Subscription} from 'rxjs';
import {
    CraningBillingInputDto, CraningBillingItemDto,
    CraningBillingService,
    CraningBookingDto,
    CraningBookingInputDto,
    CraningBookingService,
    CraningBookingSlotDto,
    CraningBookingSlotState,
    CraningSelectedServiceDto,
    CraningServiceAnswerDto,
    CraningServiceAnswerType,
    CraningServiceDto,
    CraningServicePricingDto,
    CraningServicePricingType,
    CraningServiceQuestionDto,
    CraningServiceService,
    MooringPlaceBoatDto,
    MooringPlaceService,
    PaymentTypes,
    ServiceDto,
    TariffDto,
    TariffService,
    UserContactDto,
    UserService
} from '../../../../../_services/configuration-services';
import {SearchUserComponent} from '../../../../../_shared/_components/search-user/search-user.component';
import {MsalService} from '@azure/msal-angular';
import {TranslateService} from '@ngx-translate/core';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {CraningBillingEvent, CraningBookingFormMode, CraningBookingUtils} from '../craning-booking-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    CraningBookingFormConfirmationModalComponent
} from './craning-booking-form-confirmation-modal/craning-booking-form-confirmation-modal.component';
import {InternalDatatransService} from '../../../../../_services/internal-datatrans.service';
import {DateUtils} from '../../../../../_shared/date-utils';
import {
    CraningBookingFormBoatEditModalComponent
} from './craning-booking-form-boat-edit-modal/craning-booking-form-boat-edit-modal.component';
import {TranslateUtils} from '../../../../../_shared/translate-utils';
import {GesConstants} from '../../../../../app.constants';

@Component({
    selector: 'app-craning-booking-form',
    templateUrl: './craning-booking-form.component.html',
    styleUrls: ['./craning-booking-form.component.scss']
})
export class CraningBookingFormComponent implements OnInit, OnDestroy, OnChanges {

    @Input() service: ServiceDto;
    @Input() selectedSlot: CraningBookingSlotDto | null = null;
    @Input() selectedBooking: CraningBookingDto | null = null;

    @Input() crtLang = TranslateUtils.defaultLanguage;

    _mode = CraningBookingFormMode.Hidden;
    get mode(): CraningBookingFormMode {
        return this._mode;
    }

    @Input() set mode(value: CraningBookingFormMode) {
        this._mode = value;

        if (value === CraningBookingFormMode.Hidden) {
            this.miscellaneousServiceForm.reset();
        }
    }

    @Output() bookingSaved = new EventEmitter<CraningBookingSlotDto>();
    @Output() bookingBilled = new EventEmitter<CraningBillingEvent>();

    @ViewChild('searchUserComponent') searchUser: SearchUserComponent;

    defaultSelectedSlot: CraningBookingSlotDto | null = null; // keep the first selected slot

    craningBookingForm: UntypedFormGroup;
    miscellaneousServiceForm: FormGroup<MiscellaneousServiceForm>;

    GesConstants = GesConstants;

    readonly debtorTypes = {
        visitor: 'visitor',
        user: 'user'
    };

    readonly enableableFormControlNames = {
        visitorBoat: 'boatFormGroup',
        visitorInfo: 'visitorFormGroup',
        user: 'searchUserComponent',
        userBoat: 'selectUserBoat',
        debtorType: 'debtorType',
        craningServices: 'services',
        craningServiceQuestions: 'questions',
        paymentType: 'paymentType',
    };

    FormUtils = FormUtils;
    DateUtils = DateUtils;
    CraningServiceAnswerType = CraningServiceAnswerType;
    CraningBookingFormMode = CraningBookingFormMode;
    CraningBookingSlotState = CraningBookingSlotState;

    allAvailablePaymentTypes = [PaymentTypes.Cash, PaymentTypes.Billing, PaymentTypes.Wallet];
    availablePaymentTypes = this.allAvailablePaymentTypes;

    craningServices: CraningServiceDto[] = [];

    formats = {
        date: DateUtils.dateFormat,
    };

    isVisitor = true;

    // Tariff containing the deposit price for the reservation
    tariffDeposit: TariffDto | null;

    // Registered user that books a craning slot
    userContact: UserContactDto | null = null;

    // Boat selection for registered users
    mooringPlaceBoats: MooringPlaceBoatDto[] = [];
    selectedMooringPlaceBoat: MooringPlaceBoatDto | null = null;

    // Subscriptions on changes in control value
    private debtorTypeSubscription: Subscription;
    private userBoatSubscription: Subscription;
    private servicesSubscription: Subscription;

    constructor(
        private readonly rolesService: RolesService,
        private readonly craningServiceService: CraningServiceService,
        private readonly craningBookingService: CraningBookingService,
        private readonly craningBillingService: CraningBillingService,
        private readonly mooringPlaceService: MooringPlaceService,
        private readonly tariffService: TariffService,
        private readonly authService: MsalService,
        private readonly translateService: TranslateService,
        private readonly notificationsService: NotificationsService,
        public readonly craningBookingUtils: CraningBookingUtils,
        private readonly userService: UserService,
        private readonly internalDatatransService: InternalDatatransService,
        private readonly modalService: NgbModal
    ) {
        this.craningBookingForm = new UntypedFormGroup({
            slotSelected: new UntypedFormControl(null, Validators.requiredTrue),
            services: new UntypedFormArray([], [FormUtils.atLeastOneSelectedValidator]),
            questions: new UntypedFormArray([])
        }, {updateOn: 'change'});

        this.miscellaneousServiceForm = new FormGroup<MiscellaneousServiceForm>({
            label: new FormControl<string>(null, [Validators.maxLength(50)]),
            amount: new FormControl<number>(null, [this.invalidNegativeTotalAmountValidator.bind(this)])
        },{validators: [FormUtils.allFilledOrNoneValidator]});
    }

    ngOnInit(): void {
        this.isVisitor = this.rolesService.isVisitor();
        this.initForm();
        this.fetchCraningServices();
        this.fetchLowestTariff();
    }

    ngOnDestroy(): void {
        this.debtorTypeSubscription?.unsubscribe();
        this.userBoatSubscription?.unsubscribe();
        this.servicesSubscription?.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                const change = changes[propName];
                switch (propName) {
                    case 'selectedBooking': {
                        // Reinit the defaultSelectedSlot for the new selected booking
                        if (change.previousValue !== change.currentValue) {
                            this.defaultSelectedSlot = this.selectedSlot;
                        }
                        this.populateForm(change.currentValue);
                        break;
                    }
                    case 'mode': {
                        if (change.currentValue === CraningBookingFormMode.Hidden) {
                            this.resetForm();
                        }
                        this.populateForm(this.selectedBooking);
                        break;
                    }
                    case 'selectedSlot': {
                        if (!this.defaultSelectedSlot) {
                            this.defaultSelectedSlot = this.selectedSlot;
                        }
                        this.slotSelected.setValue(!!change.currentValue);
                        if (this.mode !== CraningBookingFormMode.DisplayReservation) {
                            this.slotSelected.markAsDirty();
                        }
                        break;
                    }
                }
            }
        }
    }

    private invalidNegativeTotalAmountValidator(control: AbstractControl): ValidationErrors | null {
        if (this.getTotalPrice().price < 0) {
            return {
                invalidNegativeTotalAmount: true
            };
        }

        return null;
    }

    get slotSelected(): UntypedFormControl {
        return this.craningBookingForm.get('slotSelected') as UntypedFormControl;
    }

    get visitorBoatFormGroup(): UntypedFormGroup | null {
        return this.craningBookingForm.get(this.enableableFormControlNames.visitorBoat) as UntypedFormGroup;
    }

    get visitorBoatRegistrationNumber(): UntypedFormControl | null {
        return this.visitorBoatFormGroup?.get('registrationNumber') as UntypedFormControl;
    }

    get visitorBoatLengthInMeter(): UntypedFormControl | null {
        return this.visitorBoatFormGroup?.get('lengthInMeter') as UntypedFormControl;
    }

    get visitorBoatWidthInMeter(): UntypedFormControl | null {
        return this.visitorBoatFormGroup?.get('widthInMeter') as UntypedFormControl;
    }

    get visitorBoatWeightInKg(): UntypedFormControl | null {
        return this.visitorBoatFormGroup?.get('weightInKg') as UntypedFormControl;
    }

    get visitorInfoFormGroup(): UntypedFormGroup | null {
        return this.craningBookingForm.get(this.enableableFormControlNames.visitorInfo) as UntypedFormGroup;
    }

    get visitorFirstname(): UntypedFormControl | null {
        return this.visitorInfoFormGroup?.get('firstname') as UntypedFormControl;
    }

    get visitorLastname(): UntypedFormControl | null {
        return this.visitorInfoFormGroup?.get('lastname') as UntypedFormControl;
    }

    get visitorEmail(): UntypedFormControl | null {
        return this.visitorInfoFormGroup?.get('email') as UntypedFormControl;
    }

    get visitorPhoneNumber(): UntypedFormControl | null {
        return this.visitorInfoFormGroup?.get('phoneNumber') as UntypedFormControl;
    }

    get visitorStreet(): UntypedFormControl | null {
        return this.visitorInfoFormGroup?.get('street') as UntypedFormControl;
    }

    get visitorPostalCode(): UntypedFormControl | null {
        return this.visitorInfoFormGroup?.get('postalCode') as UntypedFormControl;
    }

    get visitorCity(): UntypedFormControl | null {
        return this.visitorInfoFormGroup?.get('city') as UntypedFormControl;
    }

    get visitorCountry(): UntypedFormControl | null {
        return this.visitorInfoFormGroup?.get('country') as UntypedFormControl;
    }

    get debtorType(): UntypedFormControl | null {
        return this.craningBookingForm.get('debtorType') as UntypedFormControl;
    }

    get selectUser(): UntypedFormControl | null {
        return this.craningBookingForm.get(this.enableableFormControlNames.user) as UntypedFormControl;
    }

    get selectUserBoat(): UntypedFormControl | null {
        return this.craningBookingForm.get(this.enableableFormControlNames.userBoat) as UntypedFormControl;
    }

    get services(): UntypedFormArray | null {
        return this.craningBookingForm.get(this.enableableFormControlNames.craningServices) as UntypedFormArray;
    }

    get questions(): UntypedFormArray | null {
        return this.craningBookingForm.get(this.enableableFormControlNames.craningServiceQuestions) as UntypedFormArray;
    }

    get paymentTypeFormGroup(): UntypedFormGroup | null {
        return this.craningBookingForm.get(this.enableableFormControlNames.paymentType) as UntypedFormGroup;
    }

    get paymentType(): UntypedFormControl | null {
        return this.paymentTypeFormGroup?.get('paymentType') as UntypedFormControl;
    }

    get miscellaneousServiceLabel(): FormControl<string> {
        return this.miscellaneousServiceForm?.get('label') as FormControl<string>;
    }

    get miscellaneousServiceAmount(): FormControl<number> {
        return this.miscellaneousServiceForm?.get('amount') as FormControl<number>;
    }

    getServiceQuestionsForServiceFormArray(serviceIndex: number): UntypedFormArray | null {
        return this.questions.get(serviceIndex.toString()) as UntypedFormArray;
    }

    getCraningServiceLabel(craningService: CraningServiceDto): string {
        return craningService['label' + this.crtLang.toUpperCase()];
    }

    getCraningServicePricingCategoryName(craningServicePricing: CraningServicePricingDto | false | null, serviceIndex: number): string {
        if (craningServicePricing === null) {
            return null;
        }

        if (craningServicePricing === false) {
            const pricings = this.craningServices[serviceIndex].pricings;
            switch (pricings[0].pricingType) {
                case CraningServicePricingType.Weight:
                    return this.translateService.instant('services.craning.booking.form.warnings.noPricingForBoatWeight');
                case CraningServicePricingType.Length:
                    return this.translateService.instant('services.craning.booking.form.warnings.noPricingForBoatLength');
                case CraningServicePricingType.Width:
                    return this.translateService.instant('services.craning.booking.form.warnings.noPricingForBoatWidth');
                case CraningServicePricingType.Area:
                    return this.translateService.instant('services.craning.booking.form.warnings.noPricingForBoatArea');
            }
        }

        return craningServicePricing['categoryName' + this.crtLang.toUpperCase()];
    }

    getCraningServiceQuestionLabel(craningServiceQuestion: CraningServiceQuestionDto): string {
        return craningServiceQuestion['question' + this.crtLang.toUpperCase()];
    }

    getServicePrice(serviceIndex: number): number | null {
        if (!this.isServiceSelected(serviceIndex)) {
            return 0;
        }
        const pricing = this.getServicePricing(serviceIndex);

        if (pricing === false) {
            return null;
        }

        if (!pricing) {
            return 0;
        }
        return pricing.price;
    }

    isServiceSelected(serviceIndex: number): boolean {
        return this.services.get(serviceIndex.toString()).value ?? false;
    }

    /**
     * Returns the compatible service pricing according to a flat price or the boat weight.
     *
     * @param serviceIndex - The service index in the {@craningServices} collection
     * @returns The compatible service pricing
     *              OR {@false} if no pricing for the weight of the boat was found
     *              OR {@null} if no pricing exists for the service
     */
    getServicePricing(serviceIndex: number): CraningServicePricingDto | false | null {
        const pricings = this.craningServices[serviceIndex].pricings;

        if (pricings.length <= 0) {
            return null;
        }

        if (pricings[0].pricingType === CraningServicePricingType.Flat) {
            return pricings[0];
        }

        let boatValue = null;
        switch (pricings[0].pricingType) {

            case CraningServicePricingType.Weight:
                boatValue = this.getBoatWeight();
                break;

            case CraningServicePricingType.Length:
                boatValue = this.getBoatLength();
                break;

            case CraningServicePricingType.Width:
                boatValue = this.getBoatWidth();
                break;

            case CraningServicePricingType.Area:
                if (this.getBoatLength() === null || this.getBoatWidth() === null) {
                    boatValue = null;
                } else {
                    boatValue = this.getBoatLength() * this.getBoatWidth();
                }
                break;
        }

        if (boatValue === null) {
            return false;
        }
        return pricings.find(p => p.min <= boatValue && p.max >= boatValue) ?? false;
    }

    getTotalPrice(): { price: number, hasMissingValue: boolean } {
        let total = 0;
        let hasMissingValues = false;

        for (let i = 0; i < this.craningServices.length; i++) {
            const servicePrice = this.getServicePrice(i);
            if (servicePrice === null) {
                hasMissingValues = true;
            } else {
                total += servicePrice;
            }
        }

        // Add the miscellaneous service price if filled
        if (this.miscellaneousServiceLabel?.value && this.miscellaneousServiceAmount?.value) {
            total += this.miscellaneousServiceAmount.value;
        }

        return {hasMissingValue: hasMissingValues, price: total};
    }

    getDepositPrice(): number {
        if (this.selectedBooking?.depositPrice) {
            return this.selectedBooking?.depositPrice;
        }
        return this.tariffDeposit?.tariffPrices[0].priceByUnit ?? 0;
    }

    getBillingBalanceDue(): number {
        return Math.max(this.getTotalPrice().price - this.getDepositPrice(), 0);
    }

    setSearchedUser(user: UserContactDto): void {
        this.userContact = user;
        this.fetchUserBoats();
        this.fetchLowestTariff();
    }

    hasActiveQuestions(): boolean {
        for (let indexService = 0; indexService < this.services.controls.length; indexService++) {
            const serviceQuestions = this.questions.get(indexService.toString()) as UntypedFormArray;
            if (serviceQuestions.enabled && serviceQuestions.controls.length > 0) {
                return true;
            }
        }
        return false;
    }

    openSaveBookingConfirmModal(): void {
        if (!this.craningBookingForm.valid) {
            return;
        }

        const modal = this.modalService.open(CraningBookingFormConfirmationModalComponent, {
            centered: true
        });
        modal.componentInstance.selectedSlot = this.selectedSlot;
        modal.componentInstance.tariffDeposit = this.tariffDeposit;
        modal.componentInstance.totalPrice = this.getTotalPrice().price;
        modal.componentInstance.mode = this.mode;
        modal.componentInstance.isVisitorCraningBooking = !this.userContact?.id;

        modal.result
            .then(result => {
                if (result?.action === 'confirm') {

                    if (this.mode === CraningBookingFormMode.NewReservation) {
                        switch (result.depositPaymentType) {
                            case PaymentTypes.Ebanking:
                                this.internalDatatransService.payCraningBooking(
                                    this.selectedSlot.id, this.getFormResult(result.depositPaymentType), this.getDepositPrice() * 100);
                                break;

                            default :
                                this.createBooking(result.depositPaymentType);
                                break;
                        }

                    } else {
                        this.updateBooking();
                    }
                }
            }, () => { /* catch the rejection */ });
    }

    billBooking(): void {
        let miscellanousService: CraningBillingItemDto;

        if (this.miscellaneousServiceLabel.value && this.miscellaneousServiceAmount.value) {
            miscellanousService = new CraningBillingItemDto({
                craningItemLabelFR: this.miscellaneousServiceLabel.value,
                craningItemLabelDE: this.miscellaneousServiceLabel.value,
                craningItemLabelIT: this.miscellaneousServiceLabel.value,
                craningItemLabelEN: this.miscellaneousServiceLabel.value,
                craningItemAmount: this.miscellaneousServiceAmount.value
            });
        }

        const input = new CraningBillingInputDto({
            craningBookingId: this.selectedBooking.id,
            serviceId: this.service.serviceId,
            paymentType: this.paymentType.value,
            miscellaneousService: miscellanousService
        });

        this.craningBillingService.createCraningBilling(input).pipe().subscribe(billingDto => {
            this.notificationsService.success({title: 'services.craning.notifications.billBookingSuccess'});
            this.bookingBilled.emit({craningBillingDto: billingDto, slotId: this.selectedBooking.craningBookingSlotId});
        });
    }

    isCreationMode(): boolean {
        return this.mode === CraningBookingFormMode.NewReservation;
    }

    isDisplayMode(): boolean {
        return this.mode === CraningBookingFormMode.DisplayReservation;
    }

    isBillingMode(): boolean {
        return this.mode === CraningBookingFormMode.BillingReservation;
    }

    canSaveBooking(): boolean {
        switch (this.mode) {
            case CraningBookingFormMode.NewReservation:
            case CraningBookingFormMode.UpdateReservation:
            case CraningBookingFormMode.BillingReservation:
                return true;

            default:
                return false;
        }
    }

    selectPaymentType(paymentType: PaymentTypes): void {
        this.paymentType.setValue(paymentType);
    }

    populateForm(booking: CraningBookingDto | null): void {
        this.resetForm();

        this.slotSelected.setValue(!!this.selectedSlot);

        if (this.isBillingMode()) {
            this.availablePaymentTypes = booking.user != null ?
                this.allAvailablePaymentTypes : this.allAvailablePaymentTypes.filter(t => t !== PaymentTypes.Wallet);
            this.addPaymentTypeFormGroup();

        } else {
            this.removePaymentTypeFormGroup();
        }

        // Fill the form
        if (booking) {

            this.debtorType?.setValue(!booking.user ? this.debtorTypes.visitor : this.debtorTypes.user);

            this.selectUser?.setValue(booking.user);
            this.searchUser?.setSelectedUser(booking.user);
            this.userContact = booking?.user;
            if (this.userContact) {
                this.fetchUserBoats();
                this.fetchLowestTariff();
            }

            this.selectedMooringPlaceBoat = booking.mooringPlace;

            this.visitorFirstname?.setValue(booking.visitorFirstName);
            this.visitorLastname?.setValue(booking.visitorLastName);
            this.visitorEmail?.setValue(booking.visitorEmail);
            this.visitorPhoneNumber?.setValue(booking.visitorMobilePhone);
            this.visitorStreet?.setValue(booking.visitorStreet);
            this.visitorPostalCode?.setValue(booking.visitorPostalCode);
            this.visitorCity?.setValue(booking.visitorCity);
            this.visitorCountry?.setValue(booking.visitorCountry);

            this.populateVisitorBoatForm(booking);

            for (let serviceIndex = 0; serviceIndex < this.services.controls.length; serviceIndex++) {
                const craningService = this.craningServices[serviceIndex];

                // Check/Uncheck service and enable/disable corresponding answers.
                const selectedCraningService = booking.craningSelectedServices.find(s => craningService.id === s.craningServiceId);
                if (!selectedCraningService) {
                    this.services.get(serviceIndex.toString()).setValue(false);
                    this.questions.get(serviceIndex.toString()).disable();

                    continue;
                }
                this.services.get(serviceIndex.toString()).setValue(true);

                if (!this.isBillingMode()) {
                    this.questions.get(serviceIndex.toString()).enable();

                    const questions = this.getServiceQuestionsForServiceFormArray(serviceIndex);

                    // Fill in answer fields.
                    for (let questionIndex = 0; questionIndex < questions.controls.length; questionIndex++) {
                        const question = craningService.questions[questionIndex];
                        const questionControl = questions.get(questionIndex.toString());

                        const answer = selectedCraningService.answers.find(a => question.id === a.craningServiceQuestionId);
                        if (!answer) {
                            questionControl.setValue(null);
                        } else {
                            let answerValue: any;
                            switch (question.answerType) {
                                case CraningServiceAnswerType.Boolean:
                                    if (answer.answer === 'true') {
                                        answerValue = true;
                                    } else if (answer.answer === 'false') {
                                        answerValue = false;
                                    } else {
                                        answerValue = null;
                                    }
                                    break;
                                default:
                                    answerValue = answer.answer;
                                    break;
                            }
                            questionControl.setValue(answerValue);
                        }
                    }
                }
            }

            if (this.isBillingMode()) {
                this.questions.disable();
            }

        } else {
            this.fetchUserBoats();
            this.fetchLowestTariff();
        }

        this.craningBookingForm.markAsPristine();
    }

    editMooringPlace(): void {
        const modal = this.modalService.open(CraningBookingFormBoatEditModalComponent, {centered: true, size: 'md'});
        const mooringPlaceId = this.selectedMooringPlaceBoat.placeId;
        modal.componentInstance.mooringPlaceId = mooringPlaceId;
        modal.result
            .then(value => {
                if (value === 'success') {
                    this.notificationsService.success({title: 'services.craning.notifications.updateBoatSuccess'});
                    this.mooringPlaceService.getMooringPlaceBoatsForUser(this.userContact.id).pipe().subscribe(mooringPlaceBoats => {
                        this.mooringPlaceBoats = mooringPlaceBoats;
                        this.selectedMooringPlaceBoat = this.mooringPlaceBoats.find(mp => mp.placeId === mooringPlaceId);
                        if (this.selectUserBoat) {
                            this.selectUserBoat.setValue(this.selectedMooringPlaceBoat);
                        }
                    });
                }
            }, () => { /* catch the rejection */ });
    }

    private initForm(): void {
        if (this.craningBookingUtils.hasAdminView()) { // Admin / harbour master
            this.addDebtorType();

        } else if (this.isVisitor) { // Visitor
            this.removeDebtorType();
            this.removeSelectUser();

            this.addVisitorInfoFormGroup();
            this.addVisitorBoatFormGroup();

        } else { // User
            this.removeDebtorType();
            this.removeSelectUser();
            this.removeVisitorInfoFormGroup();
            this.removeVisitorBoatFormGroup();

            this.userService.getMe().pipe().subscribe(user => {
                this.userContact = UserContactDto.fromJS({
                    id: user.id,
                    email: user.email,
                    phone: user.phoneMobile ?? user.phonePrivate ?? user.phoneProfessional,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    city: user.city,
                    country: user.country,
                    street: user.street,
                    postalCode: user.postalCode
                });

                this.fetchUserBoats();
            });
        }

        this.servicesSubscription = this.services.valueChanges.subscribe(changes => {
            if (!this.isBillingMode()) {
                for (let i = 0; i < changes.length; i++) {
                    if (!!changes[i]) {
                        this.getServiceQuestionsForServiceFormArray(i).enable();
                    } else {
                        this.getServiceQuestionsForServiceFormArray(i).disable();
                    }
                }
            }
        });
    }

    private resetForm(): void {

        for (let serviceIndex = 0; serviceIndex < this.services.controls.length; serviceIndex++) {
            if (!this.isBillingMode()) {
                const questions = this.getServiceQuestionsForServiceFormArray(serviceIndex);
                for (const question of questions.controls) {
                    question.reset();
                }
                questions.disable();
            }
            this.services.get(serviceIndex.toString()).setValue(null);
        }

        this.selectedSlot = this.defaultSelectedSlot;
        this.slotSelected.reset();

        this.visitorFirstname?.reset();
        this.visitorLastname?.reset();
        this.visitorEmail?.reset();
        this.visitorPhoneNumber?.reset();
        this.visitorStreet?.reset();
        this.visitorPostalCode?.reset();
        this.visitorCity?.reset();
        this.visitorCountry?.reset();

        this.visitorBoatLengthInMeter?.reset();
        this.visitorBoatWidthInMeter?.reset();
        this.visitorBoatWeightInKg?.reset();
        this.visitorBoatRegistrationNumber?.reset();

        this.selectUserBoat?.reset();
        this.selectedMooringPlaceBoat = null;
        this.mooringPlaceBoats = [];

        this.selectUser?.reset();
        this.searchUser?.setSelectedUser(null);

        if (this.craningBookingUtils.hasAdminView()) {
            this.userContact = null;
        }

        this.debtorType?.setValue(this.debtorTypes.user);

        this.craningBookingForm.markAsPristine();
    }

    private populateVisitorBoatForm(booking: CraningBookingDto | null): void {
        this.visitorBoatLengthInMeter?.setValue(booking.visitorBoatLengthInMeter);
        this.visitorBoatWidthInMeter?.setValue(booking.visitorBoatWidthInMeter);
        this.visitorBoatWeightInKg?.setValue(booking.visitorBoatWeightInKg);
        this.visitorBoatRegistrationNumber?.setValue(booking.visitorBoatRegistrationNumber);
    }

    private addVisitorBoatFormGroup(): void {
        if (!!this.visitorBoatFormGroup) {
            return;
        }
        this.craningBookingForm.addControl(this.enableableFormControlNames.visitorBoat, new UntypedFormGroup({
            registrationNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
            lengthInMeter: new UntypedFormControl(null, [Validators.required, FormUtils.numberDecimalPatternValidator]),
            widthInMeter: new UntypedFormControl(null, [Validators.required, FormUtils.numberDecimalPatternValidator]),
            weightInKg: new UntypedFormControl(null, [Validators.required, FormUtils.integerPatternValidator]),
        }));
    }

    private removeVisitorBoatFormGroup(): void {
        if (!this.visitorBoatFormGroup) {
            return;
        }
        this.craningBookingForm.removeControl(this.enableableFormControlNames.visitorBoat);
    }

    private addVisitorInfoFormGroup(): void {
        if (!!this.visitorInfoFormGroup) {
            return;
        }
        this.craningBookingForm.addControl(this.enableableFormControlNames.visitorInfo, new UntypedFormGroup({
            firstname: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
            lastname: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
            email: new UntypedFormControl(null, [Validators.required, FormUtils.emailValidator, Validators.maxLength(75)]),
            phoneNumber: new UntypedFormControl(null, [Validators.required, FormUtils.phoneNumberPatternValidator, Validators.maxLength(15)]),
            street: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
            postalCode: new UntypedFormControl(null, [Validators.required, Validators.maxLength(10)]),
            city: new UntypedFormControl(null, [Validators.required, Validators.maxLength(25)]),
            country: new UntypedFormControl(null, [Validators.required, Validators.maxLength(25)])
        }));
    }

    private removeVisitorInfoFormGroup(): void {
        if (!this.visitorInfoFormGroup) {
            return;
        }
        this.craningBookingForm.removeControl(this.enableableFormControlNames.visitorInfo);
    }

    private addSelectUser(): void {
        if (!!this.selectUser) {
            return;
        }
        this.craningBookingForm.addControl(this.enableableFormControlNames.user,
            new UntypedFormControl(null, [Validators.required]));
    }

    private removeSelectUser(): void {
        if (!this.selectUser) {
            return;
        }
        this.craningBookingForm.removeControl(this.enableableFormControlNames.user);
    }

    private addSelectUserBoat(): void {
        if (!!this.selectUserBoat) {
            this.removeSelectUserBoat();
        }
        this.selectedMooringPlaceBoat = null;
        this.craningBookingForm.addControl(this.enableableFormControlNames.userBoat,
            new UntypedFormControl(null, [Validators.required]));

        this.userBoatSubscription = this.selectUserBoat.valueChanges.subscribe(selectedMooringPlaceBoat => {
            this.selectedMooringPlaceBoat = selectedMooringPlaceBoat;
        });
    }

    private removeSelectUserBoat(): void {
        this.userBoatSubscription?.unsubscribe();
        this.userBoatSubscription = null;
        this.selectedMooringPlaceBoat = null;

        if (!this.selectUserBoat) {
            return;
        }
        this.craningBookingForm.removeControl(this.enableableFormControlNames.userBoat);
    }

    private addDebtorType(): void {
        if (!!this.debtorType) {
            return;
        }
        this.craningBookingForm.addControl(
            this.enableableFormControlNames.debtorType,
            new UntypedFormControl(this.debtorTypes.user, [Validators.required]));
        this.debtorTypeSubscription?.unsubscribe();

        this.addSelectUser();

        this.debtorTypeSubscription = this.debtorType.valueChanges.subscribe(val => {
            this.userContact = null;
            this.mooringPlaceBoats = [];
            this.selectedMooringPlaceBoat = null;

            if (val === this.debtorTypes.user) {
                this.removeVisitorInfoFormGroup();
                this.removeVisitorBoatFormGroup();
                this.addSelectUser();

            } else {
                this.addVisitorInfoFormGroup();
                this.addVisitorBoatFormGroup();
                this.removeSelectUser();
                this.removeSelectUserBoat();
            }
        });
    }

    private removeDebtorType(): void {
        this.debtorTypeSubscription?.unsubscribe();
        this.debtorTypeSubscription = null;
        if (!this.debtorType) {
            return;
        }

        this.craningBookingForm.removeControl(this.enableableFormControlNames.debtorType);
    }

    private addPaymentTypeFormGroup(): void {
        if (!!this.paymentTypeFormGroup) {
            return;
        }
        this.craningBookingForm.addControl(this.enableableFormControlNames.paymentType, new UntypedFormGroup({
            paymentType: new UntypedFormControl(null, Validators.required),
        }));

        if (this.availablePaymentTypes.length === 1) {
            this.selectPaymentType(this.availablePaymentTypes[0]);
        }
    }

    private removePaymentTypeFormGroup(): void {
        if (!this.paymentTypeFormGroup) {
            return;
        }
        this.craningBookingForm.removeControl(this.enableableFormControlNames.paymentType);
    }

    private createBooking(depositPaymentType: PaymentTypes): void {
        if (this.craningBookingUtils.hasAdminView()) {
            this.craningBookingService.createForAdmin(this.getFormResult(depositPaymentType)).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.craning.notifications.addBookingSuccess'});
                this.bookingSaved.emit(this.selectedSlot);
            });

        } else if (this.isVisitor) {
            this.craningBookingService.createForVisitor(this.getFormResult(depositPaymentType)).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.craning.notifications.addBookingSuccess'});
                this.bookingSaved.emit(this.selectedSlot);
            });

        } else {
            this.craningBookingService.create(this.getFormResult(depositPaymentType)).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.craning.notifications.addBookingSuccess'});
                this.bookingSaved.emit(this.selectedSlot);
            });
        }
    }

    private updateBooking(): void {
        if (this.craningBookingUtils.hasAdminView()) {
            this.craningBookingService.updateForAdmin(this.selectedBooking.id, this.isBillingMode(), this.getFormResult()).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.craning.notifications.updateBookingSuccess'});
                this.bookingSaved.emit(this.selectedSlot);
            });

        } else {
            this.craningBookingService.update(this.selectedBooking.id, this.getFormResult()).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.craning.notifications.updateBookingSuccess'});
                this.bookingSaved.emit(this.selectedSlot);
            });
        }
    }

    private fetchCraningServices(): void {
        this.craningServiceService.getAll(this.service.serviceId).pipe().subscribe(craningServices => {
            this.craningServices = craningServices;

            this.services.clear();
            this.questions.clear();

            for (const service of craningServices) {
                const questionArray = new UntypedFormArray([]);
                for (const question of service.questions) {
                    const validators = [Validators.required];

                    if (question.answerType === CraningServiceAnswerType.Number) {
                        validators.push(FormUtils.numberDecimalAllowingNegativePatternValidator);
                    } else if (question.answerType === CraningServiceAnswerType.Text) {
                        validators.push(Validators.maxLength(50));
                    }

                    questionArray.push(new UntypedFormControl(null, validators));
                }

                questionArray.disable();
                this.questions.push(questionArray);

                this.services.push(new UntypedFormControl(null, []));
            }
        });
    }

    private fetchUserBoats(): void {
        this.removeSelectUserBoat();
        if (!this.userContact) {
            return;
        }

        let observable: Observable<MooringPlaceBoatDto[]>;

        if (this.craningBookingUtils.hasAdminView()) {
            if (!this.userContact) {
                this.mooringPlaceBoats = [];
                this.selectedMooringPlaceBoat = null;
                return;
            }
            observable = this.mooringPlaceService.getMooringPlaceBoatsForUser(this.userContact.id);
        } else {
            observable = this.mooringPlaceService.getMooringPlaceBoats();
        }

        observable.pipe().subscribe(mooringPlaceBoats => {
            this.removeVisitorBoatFormGroup();
            this.mooringPlaceBoats = mooringPlaceBoats;

            if (this.mooringPlaceBoats.length === 1) {
                this.removeSelectUserBoat();
                this.selectedMooringPlaceBoat = this.mooringPlaceBoats[0];

            } else if (this.mooringPlaceBoats.length > 1) {
                this.addSelectUserBoat();
                if (!!this.selectedBooking?.mooringPlace) {
                    this.selectedMooringPlaceBoat = this.selectedBooking.mooringPlace;
                    this.selectUserBoat.setValue(
                        this.mooringPlaceBoats.find(mp => mp.placeId === this.selectedBooking.mooringPlace.placeId));
                } else {
                    this.selectedMooringPlaceBoat = this.mooringPlaceBoats[0];
                    this.selectUserBoat.setValue(this.mooringPlaceBoats[0]);
                }
            } else {
                this.addVisitorBoatFormGroup();
                if (!!this.selectedBooking) {
                    this.populateVisitorBoatForm(this.selectedBooking);
                }
            }
        });
    }

    private fetchLowestTariff(): void {
        this.tariffDeposit = this.service.tariffs.find(t => t.defaultTariff);

        let userId: string;
        if (this.craningBookingUtils.hasAdminView() && !!this.userContact) {
            userId = this.userContact.id;
        } else {
            userId = this.authService.instance.getActiveAccount()?.localAccountId;
        }
        if (userId) {
            this.tariffService.getTariffForServiceAndUser(this.service.serviceId, userId).pipe().subscribe(tariff => {
                this.tariffDeposit = tariff;
            });
        }
    }

    private getBoatWeight(): number | null {
        if (!!this.visitorBoatFormGroup) {
            return this.visitorBoatWeightInKg.value;

        } else if (!!this.selectedMooringPlaceBoat) {
            return this.selectedMooringPlaceBoat.boatWeightInKg;
        }
        return null;
    }

    private getBoatLength(): number | null {
        if (!!this.visitorBoatFormGroup) {
            return this.visitorBoatLengthInMeter.value;

        } else if (!!this.selectedMooringPlaceBoat) {
            return this.selectedMooringPlaceBoat.boatLengthInMeter;
        }
        return null;
    }

    private getBoatWidth(): number | null {
        if (!!this.visitorBoatFormGroup) {
            return this.visitorBoatWidthInMeter.value;

        } else if (!!this.selectedMooringPlaceBoat) {
            return this.selectedMooringPlaceBoat.boatWidthInMeter;
        }
        return null;
    }

    private getFormResult(depositPaymentType?: PaymentTypes): CraningBookingInputDto {
        const craningSelectedServices: CraningSelectedServiceDto[] = [];
        for (let serviceIndex = 0; serviceIndex < this.services.controls.length; serviceIndex++) {
            const isServiceSelected: boolean = this.services.get(serviceIndex.toString()).value;
            if (isServiceSelected === null || !isServiceSelected) {
                continue;
            }

            const answers: CraningServiceAnswerDto[] = [];
            const questions = this.questions.get(serviceIndex.toString()) as UntypedFormArray;
            if (questions.enabled) {
                for (let questionIndex = 0; questionIndex < questions.controls.length; questionIndex++) {
                    const question = questions.get(questionIndex.toString());
                    answers.push(new CraningServiceAnswerDto({
                        craningServiceQuestionId: this.craningServices[serviceIndex].questions[questionIndex].id,
                        answer: question.value.toString()
                    }));
                }
            }

            craningSelectedServices.push(new CraningSelectedServiceDto({
                craningServiceId: this.craningServices[serviceIndex].id,
                answers
            }));
        }

        return new CraningBookingInputDto({
            craningBookingSlotId: this.selectedSlot.id,
            userId: this.userContact?.id,
            visitorFirstName: this.visitorFirstname?.value,
            visitorLastName: this.visitorLastname?.value,
            visitorEmail: this.visitorEmail?.value,
            visitorMobilePhone: this.visitorPhoneNumber?.value,
            visitorStreet: this.visitorStreet?.value,
            visitorPostalCode: this.visitorPostalCode?.value,
            visitorCity: this.visitorCity?.value,
            visitorCountry: this.visitorCountry?.value,
            mooringPlaceId: this.selectedMooringPlaceBoat?.placeId,
            visitorBoatLengthInMeter: this.visitorBoatLengthInMeter?.value,
            visitorBoatWidthInMeter: this.visitorBoatWidthInMeter?.value,
            visitorBoatWeightInKg: this.visitorBoatWeightInKg?.value,
            visitorBoatRegistrationNumber: this.visitorBoatRegistrationNumber?.value,
            craningSelectedServices,
            paymentType: depositPaymentType,
            isByAdmin: false,
            isForBilling: false
        });
    }
}

interface MiscellaneousServiceForm {
    label: FormControl<string>;
    amount: FormControl<number>;
}

<div class="content-container bg-white-no-shadow">

    <h4 translate="activations.history"></h4>

    <form [formGroup]="filterFormGroup">

        <!-- Filters -->
        <div class="row">

            <!-- From / To-->
            <div class="default-filter-form-field">
                <app-date-period-picker [periodControl]="period"></app-date-period-picker>
            </div>

            <!-- User Filter -->
            <div class="default-filter-form-field" *ngIf="rolesService.hasRoleAdmin()">
                <label class="form-label" translate="common.user"></label>
                <app-search-user #userSearch
                                 formControlName="userId"
                                 ngDefaultControl
                                 (selectedUserEvent)="selectUser($event)"></app-search-user>
            </div>

            <!-- Measuring Point Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="common.measuringPoint"></label>
                <app-search-measuring-point #measuringPointSearch
                                            formControlName="measuringPointId"
                                            ngDefaultControl
                                            [serviceId]="serviceId"
                                            [measuringPointTypes]="[MeasuringPointType.Admin]"
                                            [allowDropdownEmptySelection]="true"
                                            (selectedMeasuringPointEvent)="selectMeasuringPoint($event)"></app-search-measuring-point>
            </div>

            <!-- State Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="activations.state"></label>
                <select class="form-control form-select" id="state" formControlName="state">
                    <option [ngValue]="null">-</option>
                    <option [ngValue]="true">{{ 'common.inProgress' | translate }}</option>
                    <option [ngValue]="false">{{ 'common.terminated' | translate }}</option>
                </select>
            </div>

        </div>

        <div class="btn-bar">
            <button class="btn btn-outline-secondary"
                    (click)="clearFilter()"
                    translate="common.clearFilter"></button>

            <button class="btn btn-primary"
                    [disabled]="filterFormGroup.invalid"
                    (click)="applyFilter()"
                    translate="common.filter"></button>
        </div>

    </form>

    <ngx-datatable #activationTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="activations.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   rowHeight="50"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="activations.rowCount"
                   [sorts]="[{ prop: 'firstActivationDateTime', dir: 'desc' }]"
                   [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                }"
                   [offset]="crtPage"
                   [limit]="activations.pageSize"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [scrollbarH]="true">

        <ngx-datatable-column prop="firstActivationDateTime" name="{{ 'common.startDate' | translate }}"
                              [width]="150"
                              [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.firstActivationDateTime | date:dateTimeFormat }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="endActivationDateTime" name="{{ 'common.endDate' | translate }}"
                              [width]="150"
                              [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.endActivationDateTime | date:dateTimeFormat }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="measuringPointName{{ crtLang.toUpperCase() }}" name="{{ 'common.measuringPoint' | translate }}"
                              [width]="250"
                              [sortable]="true">
       </ngx-datatable-column>

        <ngx-datatable-column prop="user" name="{{ 'common.user' | translate }}" *ngIf="rolesService.hasRoleAdmin()"
                              [width]="250"
                              [sortable]="false">
        </ngx-datatable-column>

        <ngx-datatable-column prop="activationDuration" name="{{ 'common.duration' | translate }}"
                              [width]="100"
                              [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.activationDuration | timeFormat }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="activationConsumption" name="{{ 'common.consumption' | translate }}"
                              [width]="125"
                              [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span> {{ row.activationConsumption ?
                    (row.activationConsumption.toFixed(2) + ' ' + ('common.units.EnergyConsumptionUnitType.Kwh' | translate)) : '-' }}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{ 'activations.state' | translate }}"
                              [width]="150"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ (row.endActivationDateTime ? 'common.terminated' : 'common.inProgress') | translate }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="75"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">

                <ng-container *ngIf="!row.endActivationDateTime">
                    <div class="d-flex flex-row justify-content-end">
                        <button class="btn btn-link"
                                (click)="extendActivation(row.id)"
                                title="{{ 'common.extend' | translate }}">
                            <fa-icon [icon]="icons.extend"></fa-icon>
                        </button>
                        <button class="btn btn-link"
                                (click)="terminateActivation(row.id)"
                                title="{{ 'common.terminate' | translate }}">
                            <fa-icon [icon]="icons.terminate"></fa-icon>
                        </button>
                    </div>

                </ng-container>

            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-curPage="curPage">
                <div class="page-count">
                    {{ rowCount }} {{ 'common.datatable.total' | translate }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="table.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

</div>

import {Pipe, PipeTransform} from '@angular/core';
import {GesConstants} from '../../../app.constants';
import {NumeralService} from '../../numeral.service';

@Pipe({name: 'currencyFormat'})
export class CurrencyFormat implements PipeTransform {

    constructor(private readonly numeral: NumeralService) {
    }

    transform(value: number | null): string {
        if (value == null) {
            return '';
        }
        
        return this.numeral.format(value, GesConstants.defaultCurrencyFormat);
    }
}

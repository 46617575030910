import { Component } from '@angular/core';
import {CustomerConfigService} from '../_shared/customer-config-service';

@Component({
  selector: 'app-infrastructure',
  templateUrl: './infrastructure.component.html'
})
export class InfrastructureComponent {

  constructor(public readonly customerConfigService: CustomerConfigService) { }

}

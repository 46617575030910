<div class="btn-group btn-group-toggle" role="group">
    <ng-container *ngFor="let method of availablePaymentTypes; let first = first; let last = last">
        <input class="btn-check" type="radio"
               [formControl]="paymentType"
               [value]="method">
        <label class="btn btn-toggle h-auto"
               (click)="updatePaymentType(method)"
               (keyup)="updatePaymentType(method)"
               [ngClass]='{
               "btn-toggle-left": first,
               "btn-toggle-right": last,
               "active": paymentType.value === method
               }'>{{ 'common.paymentType.' + method | translate }}</label>

    </ng-container>
</div>

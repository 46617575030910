import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EMPTY, merge, Observable, of, Subject} from 'rxjs';
import {
    EquipmentCriteriaDto,
    EquipmentLightDto, EquipmentSearchCriteriaDto, EquipmentService,
    PageableRequestDtoOfEquipmentCriteriaDto
} from "../../../_services/configuration-services";
import {FormUtils} from "../../form-utils";
import {faEraser} from "@fortawesome/free-solid-svg-icons";
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from "rxjs/operators";
import {NgbTypeaheadSelectItemEvent} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-search-equipment',
    templateUrl: './search-equipment.component.html'
})
export class SearchEquipmentComponent implements OnInit {

    @Input() defaultEquipment: EquipmentLightDto | null;
    @Input() disabled = false;

    @Output() selectedEquipmentEvent = new EventEmitter<EquipmentLightDto>();

    searchSubject$ = new Subject<string>();

    searchText: string | null = null;
    searchCriteriaDto: PageableRequestDtoOfEquipmentCriteriaDto;
    selectedEquipment: EquipmentLightDto | null;
    printedSelectedEquipment: string | null = null;

    textInputPattern = FormUtils.textInputPattern;

    searchFailed = false;

    icons = {
        erase: faEraser
    };

    formatter = (equipment: EquipmentLightDto | null) => equipment ? equipment.name : '';

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged());

        return merge(debouncedText$, this.searchSubject$).pipe(
            switchMap(term => {
                if (term.length < 1) {
                    this.selectEquipment(null);
                }

                if (term === this.printedSelectedEquipment) {
                    // Ignore the term if it is the same as the selected MeasuringPoint.
                    return EMPTY;
                }

                this.searchCriteriaDto.criteria.searchCriteriaDto.name = term;
                return this.equipmentService.searchEquipments(this.searchCriteriaDto)
                    .pipe(map(res => res.results), tap(res => {
                            if (res == null || res.length === 0) {
                                this.searchFailed = true;
                                this.selectEquipment(null);
                                return of([]);
                            }
                            this.searchFailed = false;
                            return of([]);
                        }),
                        catchError(() => {
                            this.searchFailed = true;
                            this.selectEquipment(null);
                            return of([]);
                        }));
            }));
    }

    constructor(private readonly equipmentService: EquipmentService) {}

    ngOnInit(): void {
        if (this.defaultEquipment) {
            this.selectEquipment(this.defaultEquipment);
        }

        this.searchCriteriaDto = new PageableRequestDtoOfEquipmentCriteriaDto({
            pageSize: 10,
            page: 1,
            criteria: new EquipmentCriteriaDto(
                {
                    searchCriteriaDto: new EquipmentSearchCriteriaDto(
                        {
                            name: this.searchText
                        }
                    )
                }
            )
        });
    }

    selectItem(selectedItemEvent: NgbTypeaheadSelectItemEvent<EquipmentLightDto>): void {
        this.selectEquipment(selectedItemEvent.item);
    }

    clearInput(): void {
        this.searchFailed = false;
        this.selectEquipment(null, true);
    }

    setEquipment(id: string): void {
        this.equipmentService.getEquipmentById(id).pipe().subscribe(equipment => this.selectEquipment(equipment));
    }

    private selectEquipment(equipment: EquipmentLightDto | null, force = false): void {
        if (equipment || force) {
            this.selectedEquipment = equipment;
            this.printedSelectedEquipment = equipment ? this.formatter(equipment) : null;
            this.selectedEquipmentEvent.emit(equipment);

        } else {
            this.selectedEquipmentEvent.emit(null);
        }

    }
}

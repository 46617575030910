import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {faCalendar, faTimes} from '@fortawesome/free-solid-svg-icons';
import {
    PaymentTypes,
    PurchaseInputDto,
    PurchaseService,
    SearchUserDto,
    ServiceDto,
    TariffConfigUnitDto,
    TariffDto
} from '../../../_services/configuration-services';
import {DateUtils} from '../../../_shared/date-utils';
import {CustomDateFormatter} from '../../../_shared/custom-date-formatter';
import {RolesService} from '../../../_shared/roles-service';
import {InternalDatatransService} from '../../../_services/internal-datatrans.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../../_shared/notifications.service';
import {FormUtils} from '../../../_shared/form-utils';

@Component({
    selector: 'app-purchase-service-modal',
    templateUrl: './purchase-service-modal.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class PurchaseServiceModalComponent implements OnInit {

    @Input() tariff: TariffDto;
    @Input() service: ServiceDto;

    FormUtils = FormUtils;

    purchaseForm: UntypedFormGroup;

    calendar = faCalendar;
    times = faTimes;

    minDate = DateUtils.dateToNgbDateStruct(new Date());
    textInputPattern = FormUtils.textInputPattern;

    hoursOfDay: string[];
    startDate: NgbDateStruct;
    startTime = '00:00';

    availablePaymentTypes: PaymentTypes[] = [];
    availableTariffUnits: TariffConfigUnitDto[] = [];

    constructor(public activeModal: NgbActiveModal,
                private readonly rolesService: RolesService,
                private readonly notificationsService: NotificationsService,
                private readonly internalDatatransService: InternalDatatransService,
                private readonly purchaseService: PurchaseService) {

        this.purchaseForm = new UntypedFormGroup({
                date: new UntypedFormControl(null, [Validators.required, FormUtils.datePatternValidator]),
                time: new UntypedFormControl(null, Validators.required),
                paymentType: new UntypedFormControl('', Validators.required),
                quantity: new UntypedFormControl(1, [Validators.required, Validators.min(1), FormUtils.integerPatternValidator]),
                unit : new UntypedFormControl(null, Validators.required)
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        this.hoursOfDay = DateUtils.generateHoursOfDayBy30Minutes();
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        this.startDate = DateUtils.dateToNgbDateStruct(tomorrow);
        this.date.setValue(this.startDate);
        this.time.setValue(this.startTime);

        this.configurePaymentTypes(this.tariff);

        this.configurePaymentUnit(this.tariff);

        if (this.rolesService.hasRoleAdmin()) {
            this.purchaseForm.addControl('user', new UntypedFormControl('', Validators.required));
        }
    }

    configurePaymentUnit(tariff: TariffDto): void {
        if (this.tariff.tariffPrices.length < 1) {
            return;
        }

        tariff.tariffPrices.forEach(tp => this.availableTariffUnits.push(tp.tariffConfigUnit));
        const selectedUnit = this.availableTariffUnits.find(u => u.id === tariff?.tariffPrices[0]?.tariffConfigUnitId);

        this.unit.setValue(selectedUnit ?? this.availableTariffUnits[0]);
    }

    configurePaymentTypes(tariff: TariffDto): void {

        this.availablePaymentTypes = tariff.paymentTypes;

        // Remove Ebanking if it is not a user
        if (this.rolesService.hasRoleAdmin()) {
            const idx = this.availablePaymentTypes.indexOf(PaymentTypes.Ebanking);
            if (idx > -1) {
                this.availablePaymentTypes.splice(idx, 1);
            }
            //Remove Cash if not admin
        } else {
            const idx = this.availablePaymentTypes.indexOf(PaymentTypes.Cash);
            if (idx > -1) {
                this.availablePaymentTypes.splice(idx, 1);
            }
        }

        if (this.availablePaymentTypes.length === 0) {
            this.notificationsService.warning({
                title: 'epayment.notifications.warningPurchaseTitle',
                message: 'epayment.notifications.noPaymentType'
            });

        } else if (this.availablePaymentTypes.length < 2) {
            this.paymentType.setValue(this.availablePaymentTypes[0]);
        }
    }

    purchase(): void {
        const reservation = DateUtils.ngbDateStructToDate(this.date.value);
        reservation.setHours(+this.time.value.slice(0, 2), +this.time.value.slice(-2));

        const purchaseInputDto = new PurchaseInputDto({
            serviceId: this.service.serviceId,
            reservationTime: reservation,
            tariffId: this.tariff.id,
            qty: this.quantity.value,
            tariffConfigUnitId: this.unit.value?.id,
            paymentType: this.paymentType.value
        });

        if (this.paymentType.value === PaymentTypes.Ebanking) {
            const amountInCent = this.getPriceByUnit() * this.quantity.value * 100;
            this.internalDatatransService.payWifi(purchaseInputDto, amountInCent);

        } else {
            if (this.rolesService.hasRoleAdmin()) {
                this.purchaseService.purchaseService(this.user.value.id, purchaseInputDto).pipe().subscribe(_ => {
                    this.onPurchaseSuccessAction();
                });

            } else {
                this.purchaseService.purchaseServiceForUser(purchaseInputDto).pipe().subscribe(_ => {
                    this.onPurchaseSuccessAction();
                });
            }

        }
    }

    private onPurchaseSuccessAction(): void {
        this.notificationsService.success({
            title: 'services.wifi.notifications.subscription.purchaseTitle',
            message: 'services.wifi.notifications.subscription.purchaseSuccess'
        });
        this.activeModal.close();
    }

    getPriceByUnit() {
        return this.tariff.tariffPrices.find(tp => tp.tariffConfigUnitId === this.unit.value.id).priceByUnit;
    }

    timeChange(selectedTime: string): void {
        const datetime = DateUtils.ngbDateStructToDate(this.startDate);
        datetime.setHours(+selectedTime.slice(0, 2), +selectedTime.slice(-2));

        if (datetime > new Date()) {
            this.time.setValue(datetime);
        }
    }

    dateChange(selectedDate: NgbDateStruct): void {
        if (selectedDate) {

            const datetime = DateUtils.ngbDateStructToDate(selectedDate);
            datetime.setHours(+this.startTime.slice(0, 2), +this.startTime.slice(-2));

            if (datetime > new Date()) {
                this.date.setValue(datetime);
            }
        }
    }

    selectUser(user: SearchUserDto): void {
        this.user.setValue(user);
    }

    selectPaymentType(paymentType: PaymentTypes): void {
        this.paymentType.setValue(paymentType);
    }

    get date(): UntypedFormControl {
        return this.purchaseForm.get('date') as UntypedFormControl;
    }

    get time(): UntypedFormControl {
        return this.purchaseForm.get('time') as UntypedFormControl;
    }

    get paymentType(): UntypedFormControl {
        return this.purchaseForm.get('paymentType') as UntypedFormControl;
    }

    get user(): UntypedFormControl {
        return this.purchaseForm.get('user') as UntypedFormControl;
    }

    get unit(): UntypedFormControl | null {
        return this.purchaseForm.get('unit') as UntypedFormControl;
    }

    get quantity(): UntypedFormControl | null {
        return this.purchaseForm.get('quantity') as UntypedFormControl;
    }
}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {
    UserContactDto,
    UserService,
    VisitorContactDto
} from '../../../_services/configuration-services';
import {ContactForm} from '../user-data-form/user-data-form.component';
import {SearchUserComponent} from '../search-user/search-user.component';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-user-visitor-selector',
  templateUrl: './user-visitor-selector.component.html'
})
export class UserVisitorSelectorComponent implements OnInit {

    @Input() debtorType:  FormControl<DebtorType>;
    @Input() userIdControl: FormControl<string>;
    @Input() visitorContactForm: FormGroup<ContactForm> = null!;

    @Input() hasAdminRole = false; // Depending on context (and not only the roles assigned to the user)

    @Input() userId: string | null = null;
    @Input() visitor: VisitorContactDto | null = null;

    @Output() debtorTypeChange = new EventEmitter<DebtorType>();

    @ViewChild('searchUserComponent') searchUser: SearchUserComponent;

    DebtorType = DebtorType;

    userContact: UserContactDto | null = null;

    constructor(private readonly userService: UserService) {

    }

    async ngOnInit(): Promise<void> {
        if (this.userId) {
            this.debtorType.setValue(DebtorType.User);
            await this.fetchUser(this.userId);

        } else if (this.hasAdminRole && !this.visitor) {
            // Default selection for Admin is User
            this.debtorType.setValue(DebtorType.User);

        } else {
            this.debtorType.setValue(DebtorType.Visitor);
        }
    }

    changeDebtorType(newDebtorType: DebtorType): void {
        this.debtorType.setValue(newDebtorType);

        if (newDebtorType == DebtorType.User) {
            this.userIdControl.enable();
            this.visitorContactForm.disable();

        } else {
            this.visitorContactForm.enable();
            this.userIdControl.disable();
        }

        this.debtorTypeChange.emit(newDebtorType);
    }

    async fetchUser(userId: string): Promise<void> {
        const user = this.hasAdminRole ?
            await firstValueFrom(this.userService.getUser(userId)) : await firstValueFrom(this.userService.getMe());

        this.userContact = UserContactDto.fromJS({
            id: user.id,
            email: user.email,
            phone: user.phoneMobile ?? user.phonePrivate ?? user.phoneProfessional ?? '', // Phone is not required for user
            firstName: user.firstName,
            lastName: user.lastName,
            city: user.city,
            country: user.country,
            street: user.street,
            postalCode: user.postalCode
        });
        this.userIdControl.setValue(user.id);
    }

    getSelectedUser(): UserContactDto {
        return this.userContact;
    }

    setSelectedUser(user: UserContactDto): void {
        if (user !== null) {
            this.userContact = user;
            this.userContact.phone = this.userContact.phone ?? ''; // Phone is not required for user
            this.userIdControl.setValue(user.id);
        }
    }
}

export enum DebtorType {
    User = 'User',
    Visitor = 'Visitor'
}

<div class="content-container bg-white">
    <h1 translate="users.title"></h1>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary"
                [appHasAnyRole]="[appRoles.admin]"
                (click)="openExportModal()"
                translate="users.exportUsers"
                type="submit">
        </button>
        <button class="btn btn-primary"
                [appHasAnyRole]="[appRoles.admin]"
                [routerLink]="['/users/create']"
                translate="users.addUser"
                type="submit">
        </button>
    </div>

    <div class="row mb-4" [formGroup]="searchTermsFormGroup">

        <div class="default-filter-form-field w-100">
            <input class="form-control" [placeholder]="'common.search.label' | translate" [formControl]="searchTerms"/>
        </div>

    </div>

    <div ngbAccordion>
        <div ngbAccordionItem>
            <ng-container ngbAccordionHeader>
                <button ngbAccordionButton>
                    <span translate="common.search.advanced"></span>
                </button>
            </ng-container>

            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <div class="row" [formGroup]="filterFormGroup">

                            <div class="default-filter-form-field">
                                <label class="form-label" for="baseRoleKeys" translate="users.table.baseRoles"></label>
                                <ng-multiselect-dropdown id="baseRoleKeys" style="width: 100%;"
                                                         placeholder="{{ 'common.multiselect.placeholder' | translate }}"
                                                         [formControl]="baseRoleKeys"
                                                         [settings]="roleMultiSelectSettings"
                                                         [data]="baseRoleMultiSelectSource"
                                                         (ngModelChange)="searchCriteriaChanged()">
                                </ng-multiselect-dropdown>
                            </div>

                            <div class="default-filter-form-field">
                                <label class="form-label" for="customRoleKeys"
                                       translate="users.table.customRoles"></label>
                                <ng-multiselect-dropdown id="customRoleKeys" style="width: 100%;"
                                                         placeholder="{{ 'common.multiselect.placeholder' | translate }}"
                                                         [formControl]="customRoleKeys"
                                                         [settings]="roleMultiSelectSettings"
                                                         [data]="customRoleMultiSelectSource"
                                                         (ngModelChange)="searchCriteriaChanged()">
                                </ng-multiselect-dropdown>
                            </div>

                            <div class="default-filter-form-field">
                                <label class="form-label" for="accountEnabled"
                                       translate="users.table.accountEnabled"></label>
                                <select class="form-control form-select" id="accountEnabled" name="accountEnabled"
                                        [formControl]="accountEnabled"
                                        (ngModelChange)="searchCriteriaChanged()">
                                    <option [ngValue]="null">-</option>
                                    <option [ngValue]="true"
                                            translate="user-creation.form.accountEnabled.true"></option>
                                    <option [ngValue]="false"
                                            translate="user-creation.form.accountEnabled.false"></option>
                                </select>
                            </div>

                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary" type="button"
                [disabled]="!isFiltering()"
                (click)="clearFilter()"
                translate="common.clearFilter">
        </button>
    </div>

    <ngx-datatable
        class="material"
        (page)="setPage($event)"
        (sort)="onSort($event)"
        [columnMode]="'force'"
        [count]="users.rowCount"
        [externalPaging]="true"
        [externalSorting]="true"
        [footerHeight]="50"
        [headerHeight]="50"
        [limit]="users.pageSize"
        [messages]="{
                  totalMessage: 'common.datatable.total' | translate
                  }"
        [sorts]="[{prop: defaultSortProperty, dir: 'asc'}]"
        [offset]="crtPage"
        [reorderable]="false"
        rowHeight="75"
        [rows]="users.results"
        [scrollbarH]="true">

        <ngx-datatable-column [width]="250" name="{{'users.table.roles' | translate}}" prop="roles"
                              cellClass="text-wrap">
            <ng-template ngx-datatable-cell-template let-user="row">
                <div class="multi-values-cell">
                    {{ user.roles | arrayMap:('label' + crtLang.toUpperCase()) | join}}
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="250" name="{{'users.table.user' | translate}}" prop="customerNumber"
                              cellClass="text-wrap">
            <ng-template ngx-datatable-cell-template let-user="row">
                <div class="multi-values-cell">
                    {{user.customerNumber}} {{user.firstName}} {{user.lastName}}
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="250" name="{{'users.table.contact' | translate}}" prop="" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-user="row">
                <ng-container *ngIf="user.email">{{user.email}}<br/></ng-container>
                <ng-container *ngIf="user.phone">{{user.phone}}</ng-container>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="225" name="{{'users.table.address' | translate}}" prop="street">
            <ng-template ngx-datatable-cell-template let-user="row">
                {{user.street}}<br/>
                {{user.postalCode}} {{user.city}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="50" [sortable]="false" name="{{'users.table.state' | translate}}">
            <ng-template let-row="row" ngx-datatable-cell-template>

                <button *ngIf="row.accountEnabled" class="btn btn-link btn-sm text-success"
                        title="{{'common.tooltip.deactivate' | translate}}"
                        (click)="deactivateUser(row.id)">
                    <fa-icon [icon]="icons.toggleOn"></fa-icon>
                </button>
                <button *ngIf="!row.accountEnabled" class="btn btn-link btn-sm text-danger"
                        title="{{'common.tooltip.activate' | translate}}"
                        (click)="activateUser(row.id)">
                    <fa-icon [icon]="icons.toggleOff"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="50" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <button [routerLink]="['/users', row.id]" class="btn btn-link btn-sm"
                        title="{{'common.tooltip.edit' | translate}}">
                    <fa-icon [icon]="icons.edit"></fa-icon>
                </button>

            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>



<div>
    <div class="modal-header">
        <h4 class="modal-title" translate>
            {{ craningService ? 'services.craning.service.modal.modifyTitle' : 'services.craning.service.modal.addTitle' }}</h4>

        <button class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">

        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="craningServiceForm">

            <!-- LABELS -->
            <div class="form-group form-row">
                <div class="input-group col-12">
                    <label class="col-12" for="labelFR">{{ 'services.craning.service.labelFR' | translate }}*</label>
                    <div class="input-group col-12">
                        <input class="form-control" name="labelFR" id="labelFR" type="text" maxlength="255"
                               formControlName="labelFR" pattern="{{textInputPattern}}">
                    </div>
                    <div class="col-12">
                        <app-form-error [control]="labelFR"></app-form-error>
                    </div>
                </div>
            </div>

            <div class="form-group form-row" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <div class="input-group col-12">
                    <label class="col-12" for="labelDE" translate>services.craning.service.labelDE</label>
                    <div class="input-group col-12">
                        <input class="form-control" name="labelDE" id="labelDE" type="text" maxlength="255"
                               formControlName="labelDE" pattern="{{textInputPattern}}">
                    </div>
                </div>
            </div>

            <div class="form-group form-row" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <div class="input-group col-12">
                    <label class="col-12" for="labelIT" translate>services.craning.service.labelIT</label>
                    <div class="input-group col-12">
                        <input class="form-control" name="labelIT" id="labelIT" type="text" maxlength="255"
                               formControlName="labelIT" pattern="{{textInputPattern}}">
                    </div>
                </div>
            </div>

            <div class="form-group form-row" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <div class="input-group col-12">
                    <label class="col-12" for="labelEN" translate>services.craning.service.labelEN</label>
                    <div class="input-group col-12">
                        <input class="form-control" name="labelEN" id="labelEN" type="text" maxlength="255"
                               formControlName="labelEN" pattern="{{textInputPattern}}">
                    </div>
                </div>

            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-secondary" (click)="activeModal.close()" translate>
            services.items.modal.cancel
        </button>
        <button class="btn btn-primary" type="submit"
                [disabled]="craningServiceForm.invalid"
                (click)="addOrUpdateCraningService()" translate>
            services.craning.service.modal.save
        </button>
    </div>
</div>


<div class="content-container bg-white">
    <h1 translate="rfidCards.title"></h1>

    <h4 translate="rfidCards.import"></h4>

    <!-- Import -->
    <div class="row" id="rfidCardsImport">
        <div class="btn-bar justify-content-between">
            <input type="file" accept="xls" (change)="rfidCardsFileSelected($event.target['files'])">

            <button class="btn btn-primary" type="submit"
                    [disabled]="!rfidCardsFileToUpload" (click)="importRfidCardsFile()"
                    translate="rfidCards.actions.import">
            </button>
        </div>
    </div>

    <hr class="mt-4 mb-4">


    <div class="btn-bar">
        <button class="btn btn-primary"
                (click)="create()"
                translate="rfidCards.actions.add"></button>
    </div>

    <!-- Filters -->
    <div class="row">

        <div class="default-filter-form-field">
            <label class="form-label" for="cardNumber" translate="rfidCards.table.cardNumber"></label>
            <input class="form-control" id="cardNumber" (keyup)="searchCriteriaChanged()"
                   placeholder="{{'rfidCards.table.cardNumber' | translate}}"
                   [formControl]="cardNumber"/>
        </div>
        <div class="default-filter-form-field">
            <label class="form-label" translate="rfidCards.table.uid"></label>
            <input class="form-control" (keyup)="searchCriteriaChanged()"
                   placeholder="{{'rfidCards.table.uid' | translate}}"
                   [formControl]="cardUid"/>
        </div>
        <div class="default-filter-form-field">
            <label class="form-label" for="rfidCardsStatus" translate="rfidCards.table.status"></label>
            <ng-multiselect-dropdown id="rfidCardsStatus" style="width: 100%;"
                                     placeholder="{{ 'common.multiselect.placeholder' | translate }}"
                                     [formControl]="cardState"
                                     [settings]="rfidCardStatusSettings"
                                     [data]="rfidCardStatusSource"
                                     (ngModelChange)="searchCriteriaChanged()">
            </ng-multiselect-dropdown>
        </div>

        <div class="default-filter-form-field">
            <label class="form-label" translate="rfidCards.table.user"></label>
            <app-search-user (selectedUserEvent)="userSelected($event)"></app-search-user>
        </div>

        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   [disabled]="!isFiltering()"
                   (click)="clearFilter()">
        </div>
    </div>

    <ngx-datatable
        class="material"
        [columnMode]="'force'"
        [rows]="rfidCards.results"
        [headerHeight]="50"
        [footerHeight]="50"
        [reorderable]="false"
        rowHeight="50"
        [externalPaging]="true"
        [externalSorting]="true"
        [count]="rfidCards.rowCount"
        [messages]="{
                    totalMessage: 'common.datatable.total' | translate
                    }"
        [offset]="crtPage"
        [limit]="rfidCards.pageSize"
        (page)="setPage($event)"
        (sort)="onSort($event)"
        [sorts]="[{prop: 'cardNumber', dir: 'asc'}]"
        [scrollbarH]="true">


        <ngx-datatable-column [width]="225" sortable="true" prop="cardNumber"
                              name="{{'rfidCards.table.cardNumber' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="225" prop="uid" name="{{'rfidCards.table.uid' | translate}}" sortable="true">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="cardState" name="{{'rfidCards.table.status' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-rfidCard="row">
                {{"rfidCards.status." + rfidCard.cardState.toLowerCase() | translate}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" name="{{'rfidCards.table.user' | translate}}" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-rfidCard="row">
                {{rfidCard.userId ? (rfidCard.userClientNumber + ' ' + rfidCard.userFirstName + ' ' + rfidCard.userLastName) : ''}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="100" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-rfidCard="row">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-link btn-sm" [disabled]="!rfidCard.userId"
                            title="{{'common.tooltip.viewUser' | translate}}"
                            [routerLink]="['/users/', rfidCard.userId]">
                        <fa-icon [icon]="faUserPen"></fa-icon>
                    </button>
                    <button class="btn btn-link btn-sm" title="{{'common.tooltip.edit' | translate}}"
                            (click)="update(rfidCard)">
                        <fa-icon [icon]="faPen"></fa-icon>
                    </button>
                    <button class="btn btn-link btn-sm" title="{{'common.tooltip.delete' | translate}}"
                            (click)="delete(rfidCard)" [disabled]="rfidCard.userId !== null">
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>

<h4 translate="services.hydrowash.title"></h4>

<div class="btn-bar">
    <button class="btn btn-primary"
            (click)="openHydrowashModal()"
            translate="services.hydrowash.action.create">
    </button>
</div>

<div class="table-responsive">
    <table class="table table-striped"
           [attr.aria-label]="'services.hydrowash.title' | translate">
        <thead>
        <tr>
            <th scope="col" style="min-width:100px" translate="services.hydrowash.trackId"></th>
            <th scope="col" style="min-width:225px" translate="services.hydrowash.trackName"></th>
            <th scope="col" style="min-width:325px" translate="services.hydrowash.trackDescription"></th>
            <th scope="col" style="min-width:125px" translate="services.hydrowash.trackAvailability"></th>
            <th scope="col" style="min-width:100px"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="!hydrowashTracks?.length">
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td></td>
        </tr>

        <tr *ngFor="let track of hydrowashTracks; let i = index" [attr.data-index]="i">
            <td>{{ track.id }}</td>

            <td>{{ track.name }}</td>

            <td>{{ track.description }}</td>

            <td translate="{{ track.isError ? 'services.hydrowash.error' : track.isFree ?
                'services.hydrowash.available' : 'services.hydrowash.unavailable' }}"></td>

            <td class="text-end">
                <button class="btn btn-link btn-sm"
                        (click)="openHydrowashModal(track)"
                        title="{{'common.tooltip.edit' | translate}}">
                    <fa-icon [icon]="faPen"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm"
                        [disabled]="i !== hydrowashTracks.length -1"
                        (click)="deleteHydrowashTrack(track.id)"
                        title="{{'common.tooltip.delete' | translate}}">
                    <fa-icon [icon]="faTrash"></fa-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>

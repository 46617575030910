<div class="modal-header">
    <h4 class="modal-title" translate="services.activation.title"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">

    <form [formGroup]="activateForm">

        <div class="row">

            <ng-container *ngIf="measuringPoint">
                <div class="col-12 col-lg-6">
                    <label class="form-label">{{ 'services.activation.form.designation' | translate }}</label>
                    <p>{{ measuringPoint['name' + crtLang.toUpperCase()] }}</p>
                </div>

                <div class="col-12 col-lg-6">
                    <label class="form-label">{{ 'services.activation.form.state' | translate }}</label>
                    <p>{{ 'common.measuringPointUsageState.' + measuringPoint.measuringPointUsageState | translate }}</p>
                    <app-form-error *ngIf="measuringPoint.measuringPointUsageState !== MeasuringPointUsageState.Free"
                                    invalidKey="services.activation.form.invalidState"></app-form-error>
                </div>
            </ng-container>

            <ng-container *ngIf="userId || hasVisitorAccess">

                <div class="col-12 col-lg-6">
                    <label class="form-label">{{ 'services.activation.form.tariff' | translate }}</label>
                    <p>{{ displayedTariff.tariffConfig['label' + crtLang.toUpperCase()] }}</p>
                </div>

                <div class="col-12 col-lg-6">
                    <label class="form-label">{{ 'services.activation.form.price' | translate }}</label>

                    <ng-container *ngIf="displayedTariff.customUnitFR">
                        <p class="mb-0" *ngFor="let price of displayedTariff.tariffPrices">
                            {{ price.priceByUnit | currencyFormat }} / {{ displayedTariff['customUnit' + crtLang.toUpperCase()] }}
                        </p>
                    </ng-container>
                    <ng-container *ngIf="!displayedTariff.customUnitFR">
                        <p class="mb-0" *ngFor="let price of displayedTariff.tariffPrices">
                            {{ price.priceByUnit | currencyFormat }} / {{ 'common.units.' + price.tariffConfigUnit.unitEnumName + '.' + price.tariffConfigUnit.unitEnumValue | translate }}
                        </p>
                    </ng-container>
                </div>

                <div class="col-12 col-lg-6">
                    <label class="form-label">{{ 'services.impulseDuration' | translate }}<span *ngIf="isAdmin()">*</span></label>

                    <ng-container *ngIf="isAdmin(); else userDuration">
                        <input class="form-control" id="impulseDuration" type="number"
                               pattern="^[0-9]*$"
                               (keypress)="FormUtils.validateInputNumberLength($event, 8)"
                               name="impulseDuration" min="1"
                               formControlName="impulseDuration">
                        <app-form-error [control]="impulseDuration"></app-form-error>
                    </ng-container>

                    <ng-template #userDuration>
                        <p>{{ activationDuration }}</p>
                    </ng-template>
                </div>

                <div class="col-12 col-lg-6">
                    <label class="form-label">{{ 'services.activation.form.unit' | translate }}</label>
                    <p>{{ 'common.impulseDurationUnit.' + activationUnit | translate }}</p>
                </div>

                <!-- Visitor Form -->
                <div *ngIf="!loggedIn">
                    <app-user-data-form #userDataFormComponent
                                        [contactForm]="visitorForm"></app-user-data-form>
                </div>

                <div class="col-12" *ngIf="isAdmin()">
                    <label class="form-label">{{ 'services.activation.form.user' | translate }}*</label>

                    <app-search-user
                        (selectedUserEvent)="userSelected($event)"></app-search-user>
                </div>

                <div class="col-12" *ngIf="availablePaymentTypes.length > 1">
                    <label class="form-label">{{ 'common.terms.paymentType' | translate }}*</label>

                    <div>
                        <app-payment-type-chooser #paymentTypeChooserComponent
                            [availablePaymentTypes]="availablePaymentTypes"
                            (selectedPaymentType)="selectPaymentType($event)"></app-payment-type-chooser>
                    </div>
                    <app-form-error [control]="paymentType"></app-form-error>
                </div>

                <app-show-cgv [formGroup]="activateForm">
                </app-show-cgv>

            </ng-container>

            <ng-container *ngIf="!loggedIn">
                <div class="row" *ngIf="!hasVisitorAccess">
                    <p translate="services.activation.message.loggedOnly"></p>
                </div>

                <div class="btn-bar">
                    <button class="btn btn-primary"
                            (click)="login()"
                            translate="menu.login"></button>
                </div>
            </ng-container>

        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>
    <button type="submit" class="btn btn-primary"
            (click)="activateService()"
            [disabled]="activateForm.invalid || measuringPoint?.measuringPointUsageState !== MeasuringPointUsageState.Free"
            translate="common.activate">
    </button>
</div>





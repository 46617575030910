<div class="content-container bg-white">
    <h1 translate="statistics.title"></h1>

    <form [formGroup]="filter.formGroup">

        <div class="row">

            <!-- Service Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="statistics.service"></label>
                <select class="form-control form-select" id="service" name="service"
                        (change)="loadServiceStatisticsData()"
                        [formControl]="service">
                    <option [ngValue]="null">-</option>
                    <option *ngFor="let service of filter.servicesDropdownList"
                            [ngValue]="service.serviceId">{{ service['name' + crtLang.toUpperCase()] }}</option>
                </select>
            </div>

            <!-- Period Filter -->
            <div class="default-filter-form-field">
                <app-date-period-picker [periodControl]="period"
                                        (dateFromSelected)="loadServiceStatisticsData()"
                                        (dateToSelected)="loadServiceStatisticsData()"
                                        (dateFromReset)="loadServiceStatisticsData()"
                                        (dateToReset)="loadServiceStatisticsData()"></app-date-period-picker>
            </div>
        </div>

    </form>

    <h3 translate="statistics.hourlyUsageTitle"></h3>

    <div *ngIf="filter.formGroup.invalid">
        <p class="text-center" *ngIf="service.invalid" translate="statistics.noServiceSelected"></p>
        <p class="text-center" *ngIf="period.invalid" translate="statistics.invalidPeriodSelected"></p>
    </div>

    <div *ngIf="filter.formGroup.valid && statisticsLoaded">
        <canvas baseChart
                [data]="barChartData"
                [legend]="true"
                [type]="'bar'">
        </canvas>
    </div>

</div>



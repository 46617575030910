import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    EquipmentCategoryDto,
    EquipmentCategoryService,
    EquipmentCriteriaDto,
    EquipmentSearchCriteriaDto, EquipmentService, ISortCriteriaDto,
    PageableRequestDtoOfEquipmentCriteriaDto,
    PagedResultDtoOfEquipmentLightDto,
    SortCriteriaDto, SortDirection,
} from "../../_services/configuration-services";
import {Subscription} from "rxjs";
import {TranslateUtils} from "../../_shared/translate-utils";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {Location} from '@angular/common';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {FormUtils} from "../../_shared/form-utils";
import {DateUtils} from "../../_shared/date-utils";
import {NgbDateParserFormatter, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CustomDateFormatter} from "../../_shared/custom-date-formatter";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {EquipmentEventModalComponent} from "./equipment-event-modal/equipment-event-modal.component";
import {NotificationsService} from "../../_shared/notifications.service";
import {MaintenanceCalendarComponent} from "./maintenance-calendar/maintenance-calendar.component";

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class MaintenanceComponent implements OnInit, OnDestroy {

    @ViewChild('eventCalendar') maintenanceCalendarComponentComponent: MaintenanceCalendarComponent;

    filterForm: UntypedFormGroup;

    equipmentCategories: EquipmentCategoryDto[];
    equipments: PagedResultDtoOfEquipmentLightDto = new PagedResultDtoOfEquipmentLightDto();

    readonly pageSize = 10;
    crtPage = 0;

    icons = {
        edit: faPen
    }

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Asc,
        property: 'Name'
    });

    private pageRequest!: PageableRequestDtoOfEquipmentCriteriaDto;

    constructor(
        private readonly translateService: TranslateService,
        private readonly equipmentService: EquipmentService,
        private readonly equipmentCategoryService: EquipmentCategoryService,
        private readonly notificationService: NotificationsService,
        private readonly modalService: NgbModal,
        private readonly location: Location) {

        this.initSearchRequest();

        this.filterForm = new UntypedFormGroup({
            nextEventPeriod: new UntypedFormGroup({
                from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]),
            name: new UntypedFormControl(null),
            equipmentLocation: new UntypedFormControl(null),
            equipmentCategory: new UntypedFormControl(null)
        });
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
            if (this.pageRequest.criteria) {
                this.pageRequest.criteria.language = this.crtLang.toUpperCase();
            }
        });

        this.loadCategoryDropDown();

        this.fetchEquipments(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    fetchEquipments(pageRequest: PageableRequestDtoOfEquipmentCriteriaDto) {
        this.equipmentService.searchEquipments(pageRequest).pipe().subscribe(
            value => this.equipments = value
        );
    }

    openEventModal(arg: {startDate: Date, eventId: string} | null): void {
        let modal = this.modalService.open(EquipmentEventModalComponent, {centered: true});
        modal.componentInstance.initialDate = arg?.startDate ?? new Date();
        modal.componentInstance.equipmentEventId = arg?.eventId ?? null;

        modal.result.then(async value => {
            if (value === 'success') {
                this.maintenanceCalendarComponentComponent.refreshCalendar();
                this.fetchEquipments(this.pageRequest);
            }
        }, () => { /* catch the rejection */ });
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;
        this.fetchEquipments(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.goToFirstPage();
        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = sortCriteriaDto as SortCriteriaDto;
        this.fetchEquipments(this.pageRequest);
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }

    back(): void {
        this.location.back();
    }

    filterBillings(): void {
        this.crtPage = 0;

        const dateFrom = DateUtils.ngbDateStructToDate(this.dateFrom.value);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(this.dateTo.value);
        dateTo?.setHours(23, 59, 59, 59);

        const criteria = new EquipmentCriteriaDto({
            searchCriteriaDto: new EquipmentSearchCriteriaDto({
                from: dateFrom,
                to: dateTo,
                equipmentCategoryId: this.equipmentCategory.value,
                name: this.name.value,
                location: this.equipmentLocation.value
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            }),
            language: this.crtLang.toUpperCase()
        });

        this.pageRequest = new PageableRequestDtoOfEquipmentCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize,
            criteria: criteria
        });
        this.pageRequest.criteria = criteria;
        this.fetchEquipments(this.pageRequest);
    }

    clearFilter(): void {
        // Clear inputs
        this.dateFrom.setValue(null);
        this.dateTo.setValue(null);
        this.name.setValue(null);
        this.equipmentLocation.setValue(null);
        this.equipmentCategory.setValue(null);
        this.crtPage = 0;

        // clear search
        this.initSearchRequest();

        this.fetchEquipments(this.pageRequest);
    }

    private loadCategoryDropDown(): void {
        this.equipmentCategoryService.getAllEquipmentCategories().pipe().subscribe(res => {
            this.equipmentCategories = res.sort((a, b) => a['label' + this.crtLang.toUpperCase()].localeCompare(b['label' + this.crtLang.toUpperCase()]));
        });
    }

    private initSearchRequest(): void {
        const equipmentCriteria = new EquipmentCriteriaDto({
            searchCriteriaDto: new EquipmentSearchCriteriaDto({
                from: null,
                to: null,
                equipmentCategoryId: null,
                name: null,
                location: null
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            }),
            language: this.crtLang.toUpperCase()
        });

        this.pageRequest = new PageableRequestDtoOfEquipmentCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize,
            criteria: equipmentCriteria
        });

        //this.pageRequest.criteria = equipmentCriteria;
    }

    get nextEventPeriod(): UntypedFormGroup {
        return this.filterForm.get('nextEventPeriod') as UntypedFormGroup;
    }

    get dateFrom(): UntypedFormControl {
        return this.nextEventPeriod.get('from') as UntypedFormControl;
    }

    get dateTo(): UntypedFormControl {
        return this.nextEventPeriod.get('to') as UntypedFormControl;
    }

    get name(): UntypedFormControl {
        return this.filterForm.get('name') as UntypedFormControl;
    }

    get equipmentLocation(): UntypedFormControl {
        return this.filterForm.get('equipmentLocation') as UntypedFormControl;
    }

    get equipmentCategory(): UntypedFormControl {
        return this.filterForm.get('equipmentCategory') as UntypedFormControl;
    }
}

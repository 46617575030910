import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {faPen, faQrcode, faTrash} from '@fortawesome/free-solid-svg-icons';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {Subscription} from 'rxjs';
import {KocoDeviceDto, KocoService} from '../../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';
import {AppRoles} from '../../../app.constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    KocoDumpsterManagementModalComponent
} from './koco-dumpster-management-modal/koco-dumpster-management-modal.component';
import {NotificationsService} from "../../../_shared/notifications.service";
import {ConfirmModalService} from "../../../_shared/_components/confirm-modal/confirm-modal.component";
import {QrCodeViewComponent} from "../qr-code-view/qr-code-view.component";

@Component({
    selector: 'app-koco-dumpster-management',
    templateUrl: './koco-dumpster-management.component.html'
})
export class KocoDumpsterManagementComponent implements OnInit, OnDestroy {
    @Input() serviceId: string;

    kocoDevices: KocoDeviceDto[] = [];

    AppRoles = AppRoles;

    // Icons
    icons = {
        edit: faPen,
        delete: faTrash,
        qrcode: faQrcode
    };

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription$: Subscription;

    constructor(private readonly kocoService: KocoService,
                private readonly translateService: TranslateService,
                private readonly notificationService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                private readonly modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.fetchKocoDevices();

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription$ = this.translateService.onLangChange
            .subscribe(_ => {
                this.crtLang = this.translateService.currentLang;
            });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$.unsubscribe();
    }

    private fetchKocoDevices(): void {
        this.kocoService.getKocoDevicesByServiceId(this.serviceId).pipe().subscribe(device => {
            this.kocoDevices = device;
        });
    }

    createKocoDevice(): void {
        const modal = this.modalService.open(KocoDumpsterManagementModalComponent, {centered: true, size: 'lg'});
        modal.componentInstance.serviceId = this.serviceId;
        modal.result
            .then(value => {
                    if (value === 'success') {
                        this.fetchKocoDevices();
                    }
                },
                () => { /* catch the rejection */
                }
            );
    }

    updateKocoDevice(deviceDto: KocoDeviceDto): void {

        const modal = this.modalService.open(KocoDumpsterManagementModalComponent, {centered: true, size: 'lg'});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.kocoDevice = deviceDto;
        modal.result
            .then(value => {
                    if (value === 'success') {
                        this.fetchKocoDevices();
                    }
                },
                () => { /* catch the rejection */ }
            );
    }

    deleteKocoDevice(deviceDto: KocoDeviceDto): void {

        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.kocoService.deleteKocoDevice(deviceDto.deviceId).pipe().subscribe(_ => {
                    this.notificationService.success({title: 'services.kocoDumpster.notifications.deleteSuccess'});
                    this.fetchKocoDevices()
                });
            }
        });

    }

    openQrCode(deviceDto: KocoDeviceDto): void {
        const modal = this.modalService.open(QrCodeViewComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.qrRawData = deviceDto.deviceId;
        modal.componentInstance.qrName = deviceDto['name' + this.crtLang.toUpperCase()];
    }
}

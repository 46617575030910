import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VisitorContactDto} from '../../../_services/configuration-services';
import {FormUtils} from '../../form-utils';

@Component({
    selector: 'app-user-data-form',
    templateUrl: './user-data-form.component.html'
})
export class UserDataFormComponent implements OnInit {

    @Input() contactForm: FormGroup<ContactForm> = null!;

    @Input() visitorContactDto: VisitorContactDto | null;

    ngOnInit(): void {
        this.contactForm.addControl('firstname',
            new FormControl(this.visitorContactDto?.firstName, [Validators.required, Validators.maxLength(50)]));
        this.contactForm.addControl('lastname',
            new FormControl(this.visitorContactDto?.lastName, [Validators.required, Validators.maxLength(50)]));
        this.contactForm.addControl('email',
            new FormControl(this.visitorContactDto?.email, [Validators.required, Validators.maxLength(75), FormUtils.emailValidator]));
        this.contactForm.addControl('phone',
            new FormControl(this.visitorContactDto?.phone, [Validators.required, Validators.maxLength(25), FormUtils.phoneNumberPatternValidator]));
        this.contactForm.addControl('street',
            new FormControl(this.visitorContactDto?.street, [Validators.required, Validators.maxLength(50)]));
        this.contactForm.addControl('postalCode',
            new FormControl(this.visitorContactDto?.postalCode, [Validators.required, Validators.maxLength(10)]));
        this.contactForm.addControl('city',
            new FormControl(this.visitorContactDto?.city, [Validators.required, Validators.maxLength(25)]));
        this.contactForm.addControl('country',
            new FormControl(this.visitorContactDto?.country, [Validators.required, Validators.maxLength(25)]));
    }

    get visitorContact(): VisitorContactDto | null {
        if (this.contactForm.valid) {
            return new VisitorContactDto({
                firstName: this.contactForm.get('firstname').value,
                lastName: this.contactForm.get('lastname').value,
                email: this.contactForm.get('email').value,
                phone: this.contactForm.get('phone').value,
                street: this.contactForm.get('street').value,
                postalCode: this.contactForm.get('postalCode').value,
                city: this.contactForm.get('city').value,
                country: this.contactForm.get('country').value,
            });

        } else {
            return null;
        }
    }

    get firstname(): FormControl<string> {
        return this.contactForm.get('firstname') as FormControl<string>;
    }

    get lastname(): FormControl<string> {
        return this.contactForm.get('lastname') as FormControl<string>;
    }

    get email(): FormControl<string> {
        return this.contactForm.get('email') as FormControl<string>;
    }

    get phone(): FormControl<string> {
        return this.contactForm.get('phone') as FormControl<string>;
    }

    get street(): FormControl<string> {
        return this.contactForm.get('street') as FormControl<string>;
    }

    get postalCode(): FormControl<string> {
        return this.contactForm.get('postalCode') as FormControl<string>;
    }

    get city(): FormControl<string> {
        return this.contactForm.get('city') as FormControl<string>;
    }

    get country(): FormControl<string> {
        return this.contactForm.get('country') as FormControl<string>;
    }
}

export interface ContactForm {
    firstname: FormControl<string>,
    lastname: FormControl<string>,
    email: FormControl<string>,
    phone: FormControl<string>,
    street: FormControl<string>,
    postalCode: FormControl<string>,
    city: FormControl<string>,
    country: FormControl<string>
}

<div class="nav-menu dark" *ngIf="communicationChannelConfigService.isCommunicationChannelEnabled()">
    <ul class="navbar-nav">
        <li class="nav-item" *ngIf="communicationChannelConfigService.isCommunicationChannelInformationEnabled()">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['information']"
                   routerLinkActive
                   #informationLink="routerLinkActive"
                   [ngClass]='{"selected": informationLink.isActive}'>
                    <span translate>menu.navigation.communicationChannel.information</span>
                </a>
            </div>
        </li>
        <li class="nav-item" *ngIf="communicationChannelConfigService.isCommunicationChannelSurveyEnabled()">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['surveys']"
                   routerLinkActive
                   #surveyLink="routerLinkActive"
                   [ngClass]='{"selected": surveyLink.isActive}'>
                    <span translate>menu.navigation.communicationChannel.surveys</span>
                </a>
            </div>
        </li>
        <li class="nav-item" *ngIf="communicationChannelConfigService.isCommunicationChannelContactEnabled()">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['contact']"
                   routerLinkActive
                   #contactLink="routerLinkActive"
                   [ngClass]='{"selected": contactLink.isActive}'>
                    <span translate>menu.navigation.communicationChannel.contact</span>
                </a>
            </div>
        </li>
    </ul>
</div>

<router-outlet></router-outlet>

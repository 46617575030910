import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    ChargingStationEvseStateDto, ChargingStationEvseStatus,
    ChargingStationService
} from '../../../_services/configuration-services';
import {firstValueFrom, Subscription} from 'rxjs';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {TranslateService} from '@ngx-translate/core';
import {faCircleInfo} from '@fortawesome/free-solid-svg-icons';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {NotificationsService} from '../../../_shared/notifications.service';
import {RolesService} from '../../../_shared/roles-service';

@Component({
  selector: 'app-charging-stations-cockpit',
  templateUrl: './charging-stations-cockpit.component.html'
})
export class ChargingStationsCockpitComponent implements OnInit, OnDestroy {

    @Input() serviceId: string = null!;

    chargingStations: ChargingStationEvseStateDto[] = [];

    ChargingStationEvseStatus = ChargingStationEvseStatus;

    icons = {
        info: faCircleInfo
    }

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription;

    constructor(private readonly chargingStationService: ChargingStationService,
                private readonly translateService: TranslateService,
                private readonly confirmService: ConfirmModalService,
                private readonly notificationService: NotificationsService,
                public readonly rolesService: RolesService) {

    }

    async ngOnInit(): Promise<void> {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                this.crtLang = this.translateService.currentLang;
            });

        this.chargingStations = await firstValueFrom(this.chargingStationService.getChargingStationEvseStatesForService(this.serviceId));
    }

    ngOnDestroy (){
        this.langChangeSubscription?.unsubscribe()
    }

    async freePlug(chargingStationId: string, evseIndex: string): Promise<void> {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.free'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.chargingStationService.unlockEvse(chargingStationId, parseInt(evseIndex)).pipe().subscribe(async _ => {
                    this.notificationService.success({title: 'services.chargingStations.notifications.freeSuccess'});
                });
            }
        });
    }
}

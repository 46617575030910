<div class="btn-group btn-group-toggle" role="group" [formGroup]="modeSelectorForm">
    <ng-container *ngFor="let availableMode of availableModes; let first = first; let last = last">

        <input class="btn-check" type="radio"
               [formControl]="mode"
               [value]="availableMode">

        <label class="btn btn-toggle h-auto"
               (click)="updateMode(availableMode)"
               (keyup)="updateMode(availableMode)"
               [ngClass]='{
                    "btn-toggle-left": first,
                    "btn-toggle-right": last,
                    "active": mode.value === availableMode
               }'>{{ 'publicLighting.lightZone.enums.PublicLightingControlMode.' + availableMode | translate }}</label>

    </ng-container>
</div>


<div class="btn-bar" #buttonBar>

    <button class="btn btn-outline-secondary" type="button" *ngIf="canDisplayCloseFormButton()"
            (click)="hideForm()"
            translate>common.close
    </button>

    <button type="submit" class="btn btn-outline-danger" *ngIf="canDisplayCancelBookingButton()"
            (click)="cancelBooking()"
            translate>services.craning.booking.actions.cancelBooking
    </button>

    <button type="submit" class="btn btn-outline-secondary" *ngIf="canDisplayPrintBookingButton()"
            (click)="printBooking()"
            translate>services.craning.booking.actions.printBooking
    </button>

    <button class="btn btn-primary" type="button" *ngIf="canDisplayModifyButton()"
            (click)="openUpdateMode()"
            translate>common.modify
    </button>

    <button class="btn btn-primary" type="button" *ngIf="canDisplayBillButton()"
            (click)="openBillingMode()"
            translate>common.bill
    </button>

    <button class="btn btn-primary" type="button" *ngIf="canDisplayDownloadButton()"
            (click)="downloadBillReport()"
            translate>services.craning.booking.actions.download
    </button>

    <button class="btn btn-primary" type="button" *ngIf="formMode === CraningBookingFormMode.Hidden"
            (click)="openCreateMode()"
            translate>services.craning.booking.actions.addBooking
    </button>
</div>

<app-craning-booking-form [service]="service"
                          [selectedSlot]="selectedSlot"
                          [selectedBooking]="selectedBooking"
                          [crtLang]="crtLang"
                          [mode]="formMode"
                          (bookingBilled)="craningBookingBilled($event)"
                          (bookingSaved)="craningBookingSaved($event)"></app-craning-booking-form>

<app-craning-booking-calendar #calendar *ngIf="canDisplayCalendar()"
                              [crtLang]="crtLang"
                              [serviceId]="service.serviceId"
                              (selectedSlot)="selectSlot($event)"></app-craning-booking-calendar>

<div class="btn-bar" *ngIf="craningBookingUtils.hasAdminView() && formMode === CraningBookingFormMode.Hidden">
    <button class="btn btn-primary" type="button"
            (click)="openSlotGenerationModal()"
            translate>services.craning.slots.actions.generate
    </button>
    <button class="btn btn-primary" type="button"
            (click)="openSlotDeletionModal()"
            translate>services.craning.slots.actions.delete
    </button>
</div>

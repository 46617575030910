<form name="pricesForm" [formGroup]="pricesForm" *ngIf="tariffConfig">

    <div class="row" *ngIf="!canUserChooseUnit">

        <!-- First PriceByUnit -->
        <div class="col-12 col-md-6">
            <label class="form-label" for="priceByUnit">{{ 'services.tariff.modal.priceByUnit' | translate }}*</label>
            <div class="input-group">
                <span class="input-group-text">CHF</span>
                <input class="form-control" id="priceByUnit" type="number" name="priceByUnit"
                       [formControl]="priceByUnit"
                       (keydown)="FormUtils.validateInputNumberLength($event, 10)" required>
            </div>
            <app-form-error [control]="priceByUnit"></app-form-error>
        </div>

        <!-- First Unit -->
        <div class="col-12 col-md-6" *ngIf="tariffConfig.tariffConfigUnits.length > 0 && availableConfigUnits.length > 0">
            <label class="form-label" for="unit">{{ 'services.tariff.modal.unit' | translate }}*</label>
            <select class="form-control form-select" id="unit" name="unit"
                    [formControl]="unit">
                <option *ngFor="let tariffConfigUnit of availableConfigUnits"
                        [ngValue]="tariffConfigUnit">{{ 'common.units.' + tariffConfigUnit.unitEnumName + '.' + tariffConfigUnit.unitEnumValue | translate }}</option>
            </select>
        </div>

    </div>

    <!-- Custom Units -->
    <div class="row" *ngIf="tariffConfig.tariffConfigUnits.length === 0 || (availableConfigUnits.length === 0 && !canUserChooseUnit)">

        <div class="col-12 col-md-6">
            <label class="form-label" for="customUnitFR">{{ 'services.tariff.modal.unit' | translate }} (FR)*</label>
            <input class="form-control" id="customUnitFR" name="customUnitFR" type="text"
                   [formControl]="customUnitFR"
                   pattern="{{ FormUtils.textInputPattern }}" maxlength="25">
            <app-form-error [control]="customUnitFR"></app-form-error>
        </div>

        <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
            <label class="form-label" for="customUnitDE">{{ 'services.tariff.modal.unit' | translate }} (DE)</label>
            <input class="form-control" id="customUnitDE" name="customUnitDE" type="text"
                   [formControl]="customUnitDE"
                   pattern="{{ FormUtils.textInputPattern }}" maxlength="25">
            <app-form-error [control]="customUnitDE"></app-form-error>
        </div>

        <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
            <label class="form-label" for="customUnitIT">{{ 'services.tariff.modal.unit' | translate }} (IT)</label>
            <input class="form-control" id="customUnitIT" name="customUnitIT" type="text"
                   [formControl]="customUnitIT"
                   pattern="{{ FormUtils.textInputPattern }}" maxlength="25">
            <app-form-error [control]="customUnitIT"></app-form-error>
        </div>

        <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
            <label class="form-label" for="customUnitEN">{{ 'services.tariff.modal.unit' | translate }} (EN)</label>
            <input class="form-control" id="customUnitEN" name="customUnitEN" type="text"
                   [formControl]="customUnitEN"
                   pattern="{{ FormUtils.textInputPattern }}" maxlength="25">
            <app-form-error [control]="customUnitEN"></app-form-error>
        </div>

    </div>

    <!-- units fields -->
    <ng-container *ngIf="canUserChooseUnit">
        <div class="row" *ngFor="let unit of units.controls; let i = index">

            <!-- PriceByUnit -->
            <div class="col-12 col-md-6">
                <label class="form-label" for="priceByUnit-{{i}}" *ngIf="i === 0">{{ 'services.tariff.modal.priceByUnit' | translate }}*</label>
                <div class="input-group mb-3">
                    <span class="input-group-text">CHF</span>
                    <input class="form-control" id="priceByUnit-{{i}}" type="number" name="priceByUnit-{{i}}"
                           formArrayName="prices"
                           [formControl]="prices.controls[i]"
                           [value]="prices.value[i]"
                           (keydown)="FormUtils.validateInputNumberLength($event, 10)" required>
                </div>
            </div>

            <!-- Unit -->
            <div class="col-12 col-md-6">
                <label class="form-label d-none d-sm-block" *ngIf="i === 0">{{ 'services.tariff.modal.units' | translate }}</label>
                <input class="form-control" id="unit-{{i}}" type="text" name="unit-{{i}}"
                       [defaultValue]="'common.units.' + units.value[i].unitEnumName + '.' + units.value[i].unitEnumValue | translate" disabled>
            </div>
        </div>
        <app-form-error [control]="prices"></app-form-error>
    </ng-container>

    <!-- Second Unit Fields -->
    <div class="row" *ngIf="tariffConfig.tariffType === TariffType.ConsumptionAndDuration ||
                            tariffConfig.tariffType === TariffType.FlatAndConsumption ||
                            tariffConfig.tariffType === TariffType.FlatAndDuration ||
                            tariffConfig.tariffType === TariffType.FlatAndConsumptionAndDuration">

        <!-- Second PriceByUnit -->
        <div class="col-12 col-md-6">
            <label class="form-label" for="secondPriceByUnit">{{ 'services.tariff.modal.priceByUnit' | translate }}*</label>
            <div class="input-group">
                <span class="input-group-text">CHF</span>
                <input class="form-control" id="secondPriceByUnit" type="number" name="secondPriceByUnit"
                       [formControl]="secondPriceByUnit"
                       (keydown)="FormUtils.validateInputNumberLength($event, 10)" required>
            </div>
            <app-form-error [control]="secondPriceByUnit"></app-form-error>
        </div>

        <!-- Second Unit -->
        <div class="col-12 col-md-6">
            <label class="form-label" for="secondUnit">{{ 'services.tariff.modal.unit' | translate }}*</label>
            <select class="form-control form-select" id="secondUnit" name="secondUnit"
                    [formControl]="secondUnit">
                <option *ngFor="let tariffConfigUnit of availableSecondConfigUnits"
                        [ngValue]="tariffConfigUnit">{{ 'common.units.' + tariffConfigUnit.unitEnumName + '.' + tariffConfigUnit.unitEnumValue | translate }}</option>
            </select>
        </div>

    </div>

    <!-- Third Unit Fields -->
    <div class="row" *ngIf="tariffConfig.tariffType === TariffType.FlatAndConsumptionAndDuration">

        <!-- Thid PriceByUnit -->
        <div class="col-12 col-md-6">
            <label class="form-label" for="thirdPriceByUnit">{{ 'services.tariff.modal.priceByUnit' | translate }}*</label>
            <div class="input-group">
                <span class="input-group-text">CHF</span>
                <input class="form-control" id="thirdPriceByUnit" type="number" name="thirdPriceByUnit"
                       [formControl]="thirdPriceByUnit"
                       (keydown)="FormUtils.validateInputNumberLength($event, 10)" required>
            </div>
            <app-form-error [control]="thirdPriceByUnit"></app-form-error>
        </div>

        <!-- Third Unit -->
        <div class="col-12 col-md-6">
            <label class="form-label" for="thirdUnit">{{ 'services.tariff.modal.unit' | translate }}*</label>
            <select class="form-control form-select" id="thirdUnit" name="thirdUnit"
                    [formControl]="thirdUnit">
                <option *ngFor="let tariffConfigUnit of availableThirdConfigUnits"
                        [ngValue]="tariffConfigUnit">{{ 'common.units.' + tariffConfigUnit.unitEnumName + '.' + tariffConfigUnit.unitEnumValue | translate }}</option>
            </select>
        </div>

    </div>

</form>

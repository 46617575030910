import {Component} from '@angular/core';
import {
    EwalletClosurePeriodicModeDto,
    EwalletClosureScheduleDto,
    EwalletClosureScheduleMode,
    EwalletClosureScheduleService
} from '../../_services/configuration-services';
import {firstValueFrom} from 'rxjs';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {DateUtils} from '../../_shared/date-utils';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {
    ScheduleEwalletClosureModalComponent
} from './schedule-ewallet-closure-modal/schedule-ewallet-closure-modal.component';
import {ConfirmModalService} from '../../_shared/_components/confirm-modal/confirm-modal.component';
import {NotificationsService} from '../../_shared/notifications.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-ewallet-closure-schedule',
    templateUrl: './ewallet-closure-schedule.component.html'
})
export class EwalletClosureScheduleComponent {

    scheduledClosures: EwalletClosureScheduleDto[] = [];
    periodicClosureModes: EwalletClosurePeriodicModeDto[] = [];

    periodicClosureFormGroup: FormGroup<PeriodicClosureForm>;

    DateUtils = DateUtils;
    EwalletClosureScheduleMode = EwalletClosureScheduleMode;

    icons = {
        edit: faPen,
        delete: faTrash
    }

    constructor(private readonly ewalletClosureScheduleService: EwalletClosureScheduleService,
                private readonly modalService: NgbModal,
                private readonly confirmService: ConfirmModalService,
                private readonly notificationsService: NotificationsService) {

        this.periodicClosureFormGroup = new FormGroup<PeriodicClosureForm>({
            modes: new FormArray<FormGroup<PeriodicClosureFormArrayGroup>>([])
        });
    }

    async ngOnInit(): Promise<void> {
        await this.loadData();
    }

    async setPeriodicClosureMode(mode: EwalletClosureScheduleMode, enabled: boolean): Promise<void> {
        if (enabled) {
            await firstValueFrom(this.ewalletClosureScheduleService.enablePeriodicScheduleMode(mode));
        } else {
            await firstValueFrom(this.ewalletClosureScheduleService.disablePeriodicScheduleMode(mode));
        }
        await this.loadData();
    }

    openClosureScheduleModal(ewalletClosureSchedule: EwalletClosureScheduleDto | null = null): void {
        const modal = this.modalService.open(ScheduleEwalletClosureModalComponent, {centered: true});

        if (ewalletClosureSchedule !== null) {
            modal.componentInstance.ewalletClosureSchedule = ewalletClosureSchedule;
        }

        modal.result.then(async result => {
                if (result === 'success') {
                    await this.fetchScheduledClosures();
                }},
            () => { /* catch the rejection */ });
    }

    deleteItem(ewalletClosureSchedule: EwalletClosureScheduleDto): void {
        this.confirmService.confirm({ titleKey: 'common.confirmModal.title.delete' })
            .then(async result => {
                if (result === ConfirmModalService.yes) {
                    await firstValueFrom(this.ewalletClosureScheduleService.deleteScheduledClosure(ewalletClosureSchedule.id));

                    this.notificationsService.success({title: 'ewallet.closures.notifications.deleteSuccess'});
                    await this.fetchScheduledClosures();
                }
            });
    }

    private setModesFormArray(modes: EwalletClosurePeriodicModeDto[]) {
        this.modes.clear();

        for (const mode of modes) {
            this.modes.push(new FormGroup<PeriodicClosureFormArrayGroup>(
                {
                    enabled: new FormControl<boolean>(mode.enabled),
                    mode: new FormControl<EwalletClosureScheduleMode>(mode.mode)

                }
            ));
        }
    }

    private async loadData(): Promise<void> {
        this.periodicClosureModes = await firstValueFrom(this.ewalletClosureScheduleService.getPeriodicModes());
        this.setModesFormArray(this.periodicClosureModes);

        await this.fetchScheduledClosures();
    }

    private async fetchScheduledClosures(): Promise<void> {
        this.scheduledClosures = await firstValueFrom(this.ewalletClosureScheduleService.getAllFutureScheduledClosures());
    }

    get modes(): FormArray<FormGroup<PeriodicClosureFormArrayGroup>> {
        return this.periodicClosureFormGroup.controls.modes;
    }
}

interface PeriodicClosureForm {
    modes: FormArray<FormGroup<PeriodicClosureFormArrayGroup>>
}

interface PeriodicClosureFormArrayGroup {
    mode: FormControl<EwalletClosureScheduleMode>,
    enabled: FormControl<boolean>
}

<div class="battery-container">
    <div class="battery">
        <progress class="{{getClass()}}" max='100' value="{{currentValue}}" ></progress>
        <div style="text-align: center;">{{ currentValue | number : '1.0-2' }} %</div>
    </div>
</div>

<div class="label-container">
    <p translate="services.energy.cockpit.battery.scale"></p>
</div>


<div class="my-service-container" *ngIf="myServices?.length > 0">
    <h1 class="title" translate>services.myServiceTitle</h1>

    <div class="d-flex justify-content-around my-service-row gap-3" *ngFor="let service of myServices"
         [routerLink]="['/services/' + service.serviceId]">

        <div class="icon-container flex-shrink-0">
            <img class="icon" src="{{ buildIconUrl(service.serviceConfig.iconUrl, 'white') }}" alt="icon" height="60px"
                 width="auto">
        </div>

        <div class="text-word-overflow-wrap flex-grow-1">
            <div class="d-flex flex-column justify-content-center h-100">
                <h3 translate>{{ service['name' + crtLang.toUpperCase()] }}</h3>

                <div class="d-flex flex-row">
                    <fa-icon [icon]="faCircle" class="circle" [ngStyle]="{ 'color': service.active ? 'green' : 'orange' }"></fa-icon>
                    <h6 translate>{{ service.active ? 'services.view.available' : 'services.view.unavailable' }}</h6>
                </div>
            </div>

        </div>

        <!-- Next Booking / Subscription -->
        <div class="d-flex flex-column justify-content-center" *ngIf="service.nextEntry">
            <p class="bold m-0" translate>{{ service.nextEntry.entryType === MyServicesNextEntryType.Booking ? 'services.myNextBooking' : 'services.myNextSubscription' }}</p>
            <p class="bold m-0">{{ getNextEntryLabel(service.nextEntry) }}</p>
        </div>

    </div>
</div>

<div class="content-container">
    <h1 translate>services.title</h1>

    <div class="btn-bar">
        <button class="btn btn-primary" *ngIf="isLoggedIn"
                [appHasAnyRole]="[AppRoles.admin]"
                (click)="createNewService(content)"
                translate>services.manage.createNew
        </button>
    </div>

    <div class="row">
        <div class="col-12 col-lg-6 col-xl-4" *ngFor="let category of servicesByCategory">
            <div class="card">
                <div class="card-header">
                    <img class="card-img-top" src="{{ category.imageUrl }}" alt="Card image cap">
                    <h3 translate>{{ category['name' + crtLang.toUpperCase()]}}</h3>
                </div>
                <div class="card-body" *ngFor="let service of category.services" [routerLink]="['/services/' + service.serviceId]">
                    <img class="service-icon" src="{{ buildIconUrl(service.iconUrl, 'dark_gray') }}" alt="icon">
                    <h6 translate>{{ service['name' + crtLang.toUpperCase()] }}</h6>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Create service modal -->
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title"  translate>services.chooseServiceTypeModal.title</h4>
        <button class="btn-close" aria-label="Close" (click)="d('Cross click')">

        </button>
    </div>
    <div class="modal-body">
        <div class="col-12">
            <label translate>services.chooseServiceTypeModal.chooseServiceType</label>
        </div>
        <div class="col-12">
            <select class="form-control form-select" name="serviceType" [(ngModel)]="serviceConfig">
                <option *ngFor="let configuredService of serviceConfigsMap"
                        [ngValue]="configuredService[1]">{{ configuredService[0] }}</option>
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="c('cancel')" translate>
            common.no
        </button>
        <button type="button" class="btn btn-primary" (click)="c('confirm')" translate>
            common.yes
        </button>
    </div>
</ng-template>

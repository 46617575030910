import * as numeral from 'numeral';
import {Injectable} from '@angular/core';
import {GesConstants} from '../app.constants';

/**
 * Wrapper of numeral.js for Angular
 *
 * See numeral.js for more info about formatting
 * http://numeraljs.com/
 */
@Injectable({
  providedIn: 'root'
})
export class NumeralService {


  constructor() {
    // load a locale
    numeral.register('locale', 'ges', {
      delimiters: {
        thousands: ' ',
        decimal: '.'
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
      },
      ordinal: function (number) {
        return number === 1 ? 'er' : 'ème';
      },
      currency: {
        symbol: 'CHF'
      }
    });

    numeral.locale('ges');

  }


  format(value: unknown, format: string | undefined | null): string | null {
    if (value === null || value === undefined) {
      return null;
    }
    const defaultFormat = GesConstants.defaultNumeralFormat;
    return numeral(value).format(format ? format : defaultFormat);
  }
}


<div class="content-container bg-white-no-shadow" *ngIf="documents.length > 0">

    <div class="table-responsive">
        <h5 translate="common.document.viewTitle"></h5>

        <table class="table table-striped" [attr.aria-label]="'common.document.viewTitle' | translate">
            <thead>
            <tr>
                <th scope="col" translate="common.document.table.title"></th>
                <th scope="col"></th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let doc of documents;">
                <td class="align-middle">{{ doc.title }}</td>
                <td class="text-end">
                    <button class="btn btn-link btn-sm" type="button"
                            (click)="downloadItem(doc)"
                            title="{{'common.tooltip.download' | translate}}">
                        <fa-icon [icon]="icons.download"></fa-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

    </div>
</div>

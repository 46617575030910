import {Component, OnInit} from '@angular/core';
import {AppRoles} from '../../../app.constants';
import {InformationLightDto, InformationService} from '../../../_services/configuration-services';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {DateUtils} from '../../../_shared/date-utils';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {NotificationsService} from '../../../_shared/notifications.service';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {Location} from '@angular/common';

@Component({
    selector: 'app-informations-management',
    templateUrl: './informations-management.component.html'
})
export class InformationsManagementComponent implements OnInit {

    AppRoles = AppRoles;

    informations: InformationLightDto[] = [];

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    // Formats
    formats = {
        date: DateUtils.dateFormat,
    };

    // Icons
    icons = {
        trash: faTrash,
        pen: faPen
    };

    constructor(private readonly informationService: InformationService,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly location: Location,
                private readonly confirmService: ConfirmModalService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });
        this.fetchInformations();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    private fetchInformations(): void {
        this.informationService.getAll().pipe().subscribe(informations => this.informations = informations);
    }

    deleteInformation(info: InformationLightDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.informationService.deleteInformation(info.id).pipe().subscribe(res => {
                    this.notificationsService.success({title: 'communicationChannel.information.notification.deleteSuccess'});
                    this.fetchInformations();
                });
            }
        });
    }

    back(): void {
        this.location.back();
    }
}

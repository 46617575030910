import {Component, Input, OnInit} from '@angular/core';
import {
    EwalletClosureScheduleDto,
    EwalletClosureScheduleInputDto,
    EwalletClosureScheduleService,
} from '../../../_services/configuration-services';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';
import {NotificationsService} from '../../../_shared/notifications.service';
import {DateUtils} from '../../../_shared/date-utils';
import {firstValueFrom} from 'rxjs';
import {faCalendar, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FormUtils} from '../../../_shared/form-utils';
import {CustomDateFormatter} from '../../../_shared/custom-date-formatter';

@Component({
    selector: 'app-schedule-ewallet-closure-modal',
    templateUrl: './schedule-ewallet-closure-modal.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class ScheduleEwalletClosureModalComponent implements OnInit {

    @Input() ewalletClosureSchedule: EwalletClosureScheduleDto | null = null;

    scheduleEwalletClosureForm: FormGroup<ScheduleEwalletClosureInputForm>;

    today: NgbDateStruct = DateUtils.dateToNgbDateStruct(new Date());

    icons = {
        calendar: faCalendar,
        times: faTimes
    };

    constructor(protected activeModal: NgbActiveModal,
                private notificationService: NotificationsService,
                private ewalletClosureScheduleService: EwalletClosureScheduleService) {

        this.scheduleEwalletClosureForm = new FormGroup<ScheduleEwalletClosureInputForm>({
                date: new FormControl<NgbDateStruct>(null, Validators.required),
                time: new FormControl<NgbTimeStruct>(null, Validators.required)
            }, FormUtils.dateTimeAfterNowValidator
        );
    }

    ngOnInit(): void {
        if (this.ewalletClosureSchedule !== null) {
            this.date.setValue(DateUtils.dateToNgbDateStruct(this.ewalletClosureSchedule.scheduledDateTime));
            this.time.setValue(DateUtils.dateToNgbTimeStruct(this.ewalletClosureSchedule.scheduledDateTime));
        }
    }

    async saveScheduleEwalletClosure(): Promise<void> {
        const scheduleInput: EwalletClosureScheduleInputDto = new EwalletClosureScheduleInputDto({
                scheduledDateTime: DateUtils.ngbDateStructToDateTime(
                    this.date.value,
                    this.time.value
                )
        });

        if (this.ewalletClosureSchedule !== null) {
            await firstValueFrom(this.ewalletClosureScheduleService.updateScheduledClosure(this.ewalletClosureSchedule.id,
                scheduleInput));
            this.notificationService.success({title: 'ewallet.closures.notifications.updateSuccess'});

        } else {
            await firstValueFrom(this.ewalletClosureScheduleService.createScheduledClosure(scheduleInput));
            this.notificationService.success({title: 'ewallet.closures.notifications.addSuccess'});

        }

        this.activeModal.close('success');
    }

    resetDate(): void {
        this.date.setValue(null);
        this.date.markAsDirty();
    }

    get date(): FormControl<NgbDateStruct> {
        return this.scheduleEwalletClosureForm.controls.date;
    }

    get time(): FormControl<NgbTimeStruct> {
        return this.scheduleEwalletClosureForm.controls.time;
    }
}


interface ScheduleEwalletClosureInputForm {
    date: FormControl<NgbDateStruct>,
    time: FormControl<NgbTimeStruct>
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-show-cgv',
  templateUrl: './show-cgv.component.html'
})
export class ShowCgvComponent implements OnInit, OnDestroy {

    @Input() formGroup: UntypedFormGroup;

    ngOnInit(): void {
        this.formGroup.addControl('acceptCGV', new FormControl(false, Validators.requiredTrue));
    }
    
    ngOnDestroy(): void {
        this.formGroup.removeControl("acceptCGV");
    }
    
    get acceptCGV(): FormControl<boolean> {
        return this.formGroup.get('acceptCGV') as FormControl;
    }

}

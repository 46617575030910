import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private readonly ids = new Set<string>();
  private loading = new BehaviorSubject<boolean>(false);

  public start(id: string): void {
    this.ids.add(id);
    this.checkState();
  }

  public stop(id: string): void {
    this.ids.delete(id);
    this.checkState();
  }

  public getLoading(): Subject<boolean> {
    return this.loading;
  }

  private checkState(): void {
    let loading = false;
    if (this.ids.size > 0) {
      loading = true;
    }
    if (loading !== this.loading.getValue()) {
      this.loading.next(loading);
    }
  }
}

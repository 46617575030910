<div class="modal-header">
    <h4 class="modal-title"
        [translate]="item.id ? 'services.items.modal.modifyTitle' : 'services.items.modal.addTitle'"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form name="itemForm" [formGroup]="itemForm">
        <div class="row">

            <div class="col-12 col-md-6">
                <label class="form-label" for="nameFR">{{ 'services.items.modal.nameFR' | translate }}*</label>
                <input class="form-control" id="nameFR" type="text" name="nameFR" maxlength="25"
                       pattern="{{textInputPattern}}"
                       formControlName="itemNameFR"
                       placeholder="{{ 'services.items.modal.nameFR' | translate }}"
                       (keyup)="validateItemName(itemNameFR)">
                <app-form-error [control]="itemNameFR"></app-form-error>
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <label class="form-label" for="nameDE">{{ 'services.items.modal.nameDE' | translate }}</label>
                <input class="form-control" id="nameDE" type="text" name="nameDE" maxlength="25"
                       pattern="{{textInputPattern}}"
                       formControlName="itemNameDE"
                       placeholder="{{ 'services.items.modal.nameDE' | translate }}"
                       (keyup)="validateItemName(itemNameDE)">
                <app-form-error [control]="itemNameDE"></app-form-error>
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <label class="form-label" for="nameIT">{{ 'services.items.modal.nameIT' | translate }}</label>
                <input class="form-control" id="nameIT" type="text" name="nameIT" maxlength="25"
                       pattern="{{textInputPattern}}"
                       formControlName="itemNameIT"
                       placeholder="{{ 'services.items.placeholder.nameIT' | translate }}"
                       (keyup)="validateItemName(itemNameIT)">
                <app-form-error [control]="itemNameIT"></app-form-error>
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <label class="form-label" for="nameEN">{{ 'services.items.modal.nameEN' | translate }}</label>
                <input class="form-control" id="nameEN" type="text" name="nameEN" maxlength="25"
                       pattern="{{textInputPattern}}"
                       formControlName="itemNameEN"
                       placeholder="{{ 'services.items.modal.nameEN' | translate }}"
                       (keyup)="validateItemName(itemNameEN)">
                <app-form-error [control]="itemNameEN"></app-form-error>
            </div>

        </div>


        <div class="form-group">
            <div class="col-12">
                <label class="form-label" for="descriptionFR" translate="services.items.modal.descriptionFR"></label>
                <textarea class="form-control" id="descriptionFR" type="text" name="descriptionFR"
                          maxlength="500" formControlName="itemDescriptionFR"
                          placeholder="{{ 'services.items.modal.descriptionFR' | translate }}"></textarea>
            </div>

            <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <label class="form-label" for="descriptionDE" translate="services.items.modal.descriptionDE"></label>
                <textarea class="form-control" id="descriptionDE" type="text" name="descriptionDE"
                          maxlength="500" formControlName="itemDescriptionDE"
                          placeholder="{{ 'services.items.modal.descriptionDE' | translate }}"></textarea>
            </div>

            <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <label class="form-label" for="descriptionIT" translate="services.items.modal.descriptionIT"></label>
                <textarea class="form-control" id="descriptionIT" type="text" name="descriptionIT"
                          maxlength="500" formControlName="itemDescriptionIT"
                          placeholder="{{ 'services.items.modal.descriptionIT' | translate }}"></textarea>
            </div>

            <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <label class="form-label" for="descriptionEN" translate="services.items.modal.descriptionEN"></label>
                <textarea class="form-control" id="descriptionEN" type="text" name="descriptionEN"
                          maxlength="500" formControlName="itemDescriptionEN"
                          placeholder="{{ 'services.items.modal.descriptionEN' | translate }}"></textarea>
            </div>

            <div class="col-12">
                <label class="form-label" for="searchMeasuringPoint" translate="measuringPoints.table.filter.measuringPoint"></label>
                <app-search-measuring-point #searchMeasuringPointComponent id="searchMeasuringPoint"
                                            (selectedMeasuringPointEvent)="measuringPointSelected($event)"
                                            [defaultMeasuringPoint]="item.measuringPoint"
                                            [serviceId]="serviceId"
                                            [measuringPointTypes]="[adminMeasurePointType]"
                                            [itemToBookUnassignedOnly]="true"
                                            [serviceUnassignedOnly]="false"></app-search-measuring-point>
            </div>

        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()" translate="services.items.modal.cancel">
    </button>
    <button class="btn btn-primary"
            [disabled]="itemForm.invalid"
            (click)="addOrUpdateItem()"
            [translate]="item.id ? 'services.items.modal.save' : 'services.items.modal.create'">
    </button>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {FormUtils} from '../../../../_shared/form-utils';
import {
    BookingCalendarMode,
    BookingMode,
    BookingServiceConfigDto,
    BookingServiceConfigInputDto,
    BookingServiceConfigService,
    BookingTimeUnit
} from '../../../../_services/configuration-services';
import {firstValueFrom} from 'rxjs';
import {NotificationsService} from '../../../../_shared/notifications.service';

@Component({
  selector: 'app-booking-service-configuration',
  templateUrl: './booking-service-configuration.component.html'
})
export class BookingServiceConfigurationComponent implements OnInit {

    @Input() serviceId: string;

    BookingMode = BookingMode;
    FormUtils = FormUtils;

    bookingForm: FormGroup<BookingServiceConfigForm>;

    bookingTimeUnits = Object.keys(BookingTimeUnit);
    bookingTimeUnitsExceptMonthAndYear = Object.keys(BookingTimeUnit).filter(tu => tu !== BookingTimeUnit.Month && tu !== BookingTimeUnit.Year);
    bookingCalendarModes = Object.keys(BookingCalendarMode);

    bookingConfigAlreadyExist = false;

    constructor(private bookingServiceConfigService: BookingServiceConfigService,
                private notificationsService: NotificationsService) {

        this.bookingForm = new FormGroup<BookingServiceConfigForm>({
            bookingMode: new FormControl<BookingMode>(BookingMode.Free, [Validators.required]),
            duration: new FormGroup({
                initialBookingDuration: new FormControl<number>(null, [Validators.required, Validators.min(1)]),
                maxBookingDuration: new FormControl<number>(null, [Validators.required, Validators.min(1)]),
            }, [this.BookingDurationsValidator]),
            bookingTimeUnit: new FormControl<BookingTimeUnit>(BookingTimeUnit.Hour, [Validators.required]),
            maximumBookingAdvanceTimeInDay: new FormControl<number | null>(null, [Validators.min(0)]),
            maxNumberOfSimultaneousBookingByUser: new FormControl<number>(null, [Validators.required, Validators.min(1)]),
            maxNumberOfPlannedBookingByUser: new FormControl<number>(null, [Validators.required, Validators.min(1)]),
            deadlineForUpdatingBeforeBookingPeriod: new FormControl<number>(null, [Validators.required, Validators.min(0)]),
            deadlineForUpdatingBeforeBookingPeriodTimeUnit: new FormControl<BookingTimeUnit>(BookingTimeUnit.Hour, [Validators.required]),
            recurrentBookingAvailable: new FormControl<boolean>(false, [Validators.required]),
            defaultCalendarDisplayMode: new FormControl<BookingCalendarMode>(BookingCalendarMode.Day, [Validators.required]),
            displayUserCards: new FormControl<boolean>(false, [Validators.required]),
            displayUserBoats: new FormControl<boolean>(false, [Validators.required])
        });
    }

    ngOnInit(): void {
        this.fetchBookingServiceConfig();
    }

    async saveBookingConfig(): Promise<void> {
        if (this.bookingForm.invalid) {
            return;
        }

        const bookingConfig = new BookingServiceConfigInputDto({
            bookingMode: this.bookingMode.value,
            initialBookingDuration: this.initialBookingDuration.value ?? 0,
            maxBookingDuration: this.maxBookingDuration.value ?? 0,
            bookingTimeUnit: this.bookingTimeUnit.value,
            maximumBookingAdvanceTimeInDay: this.maximumBookingAdvanceTimeInDay.value,
            maxNumberOfSimultaneousBookingByUser: this.maxNumberOfSimultaneousBookingByUser.value ?? 0,
            maxNumberOfPlannedBookingByUser: this.maxNumberOfPlannedBookingByUser.value,
            deadlineForUpdatingBeforeBookingPeriod: this.deadlineForUpdatingBeforeBookingPeriod.value,
            deadlineForUpdatingBeforeBookingPeriodTimeUnit: this.deadlineForUpdatingBeforeBookingPeriodTimeUnit.value,
            recurrentBookingAvailable: this.recurrentBookingAvailable.value,
            defaultCalendarDisplayMode: this.defaultCalendarDisplayMode.value,
            displayUserCards: this.displayUserCards.value,
            displayUserBoats: this.displayUserBoats.value
        });

        let savedBookingConfig: BookingServiceConfigDto;
        if (this.bookingConfigAlreadyExist) {
            savedBookingConfig = await firstValueFrom(this.bookingServiceConfigService.updateBookingServiceConfigForService(this.serviceId, bookingConfig));
            this.notificationsService.success({ title: 'services.booking.updateSuccess' });

        } else {
            savedBookingConfig = await firstValueFrom(this.bookingServiceConfigService.createBookingServiceConfigForService(this.serviceId, bookingConfig));
            this.bookingConfigAlreadyExist = true;
            this.notificationsService.success({ title: 'services.booking.createSuccess' });
        }

        this.setupForm(savedBookingConfig);
    }

    adaptFormDependingOnBookingMode(bookingMode: BookingMode): void {
        this.bookingMode.setValue(bookingMode);
        this.bookingMode.markAsDirty();

        // Enable and disable fields depending on the booking mode
        if (bookingMode === BookingMode.Free) {
            this.initialBookingDuration.enable();
            this.maxBookingDuration.enable();
            this.maxNumberOfSimultaneousBookingByUser.enable();
            this.maximumBookingAdvanceTimeInDay.enable();
        }
        else if (bookingMode === BookingMode.Slots) {
            this.initialBookingDuration.disable();
            this.maxBookingDuration.disable();
            this.maxNumberOfSimultaneousBookingByUser.disable();
            this.maximumBookingAdvanceTimeInDay.disable();
        }
    }

    private fetchBookingServiceConfig(): void {
        this.bookingServiceConfigService.getBookingServiceConfigForService(this.serviceId).pipe()
            .subscribe({
                next: bookingConfig => {
                    this.bookingConfigAlreadyExist = true;
                    this.setupForm(bookingConfig);
                },
                error: _ => {
                    // Catch the error to avoid error message (404 error can be normal)
                    this.bookingConfigAlreadyExist = false;
                }
            });
    }

    private setupForm(bookingConfig: BookingServiceConfigDto): void {
        this.bookingMode.setValue(bookingConfig.bookingMode);
        this.initialBookingDuration.setValue(bookingConfig.initialBookingDuration);
        this.maxBookingDuration.setValue(bookingConfig.maxBookingDuration);
        this.bookingTimeUnit.setValue(bookingConfig.bookingTimeUnit);
        this.maximumBookingAdvanceTimeInDay.setValue(bookingConfig.maximumBookingAdvanceTimeInDay);
        this.maxNumberOfSimultaneousBookingByUser.setValue(bookingConfig.maxNumberOfSimultaneousBookingByUser);
        this.maxNumberOfPlannedBookingByUser.setValue(bookingConfig.maxNumberOfPlannedBookingByUser);
        this.deadlineForUpdatingBeforeBookingPeriod.setValue(bookingConfig.deadlineForUpdatingBeforeBookingPeriod);
        this.deadlineForUpdatingBeforeBookingPeriodTimeUnit.setValue(bookingConfig.deadlineForUpdatingBeforeBookingPeriodTimeUnit);
        this.recurrentBookingAvailable.setValue(bookingConfig.recurrentBookingAvailable);
        this.defaultCalendarDisplayMode.setValue(bookingConfig.defaultCalendarDisplayMode);
        this.displayUserCards.setValue(bookingConfig.displayUserCards);
        this.displayUserBoats.setValue(bookingConfig.displayUserBoats);

        if (this.bookingConfigAlreadyExist) {
            // Disable changing mode to avoid side effects
            this.bookingMode.disable();

            this.adaptFormDependingOnBookingMode(bookingConfig.bookingMode);
        }
    }

    get bookingMode(): FormControl<BookingMode> {
        return this.bookingForm.get('bookingMode') as FormControl<BookingMode>;
    }

    get duration(): FormGroup<BookingDurationForm> {
        return this.bookingForm.get('duration') as FormGroup<BookingDurationForm>;
    }

    get initialBookingDuration(): FormControl<number> {
        return this.bookingForm.get('duration').get('initialBookingDuration') as FormControl<number>;
    }

    get maxBookingDuration(): FormControl<number> {
        return this.bookingForm.get('duration').get('maxBookingDuration') as FormControl<number>;
    }

    get bookingTimeUnit(): FormControl<BookingTimeUnit> {
        return this.bookingForm.get('bookingTimeUnit') as FormControl<BookingTimeUnit>;
    }

    get maxNumberOfSimultaneousBookingByUser(): FormControl<number> {
        return this.bookingForm.get('maxNumberOfSimultaneousBookingByUser') as FormControl<number>;
    }

    get maxNumberOfPlannedBookingByUser(): FormControl<number> {
        return this.bookingForm.get('maxNumberOfPlannedBookingByUser') as FormControl<number>;
    }

    get deadlineForUpdatingBeforeBookingPeriod(): FormControl<number> {
        return this.bookingForm.get('deadlineForUpdatingBeforeBookingPeriod') as FormControl<number>;
    }

    get deadlineForUpdatingBeforeBookingPeriodTimeUnit(): FormControl<BookingTimeUnit> {
        return this.bookingForm.get('deadlineForUpdatingBeforeBookingPeriodTimeUnit') as FormControl<BookingTimeUnit>;
    }

    get maximumBookingAdvanceTimeInDay(): FormControl<number | null> {
        return this.bookingForm.get('maximumBookingAdvanceTimeInDay') as FormControl<number | null>;
    }

    get recurrentBookingAvailable(): FormControl<boolean> {
        return this.bookingForm.get('recurrentBookingAvailable') as FormControl<boolean>;
    }

    get defaultCalendarDisplayMode(): FormControl<BookingCalendarMode> {
        return this.bookingForm.get('defaultCalendarDisplayMode') as FormControl<BookingCalendarMode>;
    }

    get displayUserCards(): FormControl<boolean> {
        return this.bookingForm.get('displayUserCards') as FormControl<boolean>;
    }

    get displayUserBoats(): FormControl<boolean> {
        return this.bookingForm.get('displayUserBoats') as FormControl<boolean>;
    }

    private BookingDurationsValidator = (group: AbstractControl): ValidationErrors | null => {
        const initialBookingDuration = group.get('initialBookingDuration').value;
        const maxBookingDuration = group.get('maxBookingDuration').value;

        if (initialBookingDuration > maxBookingDuration) {
            return { initialBookingDurationGreaterThanMaxBookingDuration: true };
        }
        return null;
    }

}

interface BookingServiceConfigForm {
    bookingMode: FormControl<BookingMode>,
    duration: FormGroup<BookingDurationForm>,
    bookingTimeUnit: FormControl<BookingTimeUnit>,
    maxNumberOfSimultaneousBookingByUser: FormControl<number>,
    maxNumberOfPlannedBookingByUser: FormControl<number>,
    deadlineForUpdatingBeforeBookingPeriod: FormControl<number>,
    deadlineForUpdatingBeforeBookingPeriodTimeUnit: FormControl<BookingTimeUnit>,
    maximumBookingAdvanceTimeInDay: FormControl<number | null>,
    recurrentBookingAvailable: FormControl<boolean>,
    defaultCalendarDisplayMode: FormControl<BookingCalendarMode>,
    displayUserCards: FormControl<boolean>,
    displayUserBoats: FormControl<boolean>,
}

interface BookingDurationForm {
    initialBookingDuration: FormControl<number>,
    maxBookingDuration: FormControl<number>,
}

<h4 translate="services.kocoDumpster.title"></h4>

<div class="btn-bar">
    <button class="btn btn-primary" [appHasAnyRole]="[AppRoles.configurator]"
            (click)="createKocoDevice()"
            translate="services.kocoDumpster.actions.add">
    </button>
</div>

<div class="table-responsive">
    <table class="table table-striped"
           [attr.aria-label]="'services.kocoDumpster.title' | translate">
        <thead>
        <tr>
            <th scope="col" style="min-width:150px" translate="services.kocoDumpster.table.deviceId"></th>
            <th scope="col" style="min-width:225px" translate="services.kocoDumpster.table.name"></th>
            <th scope="col" style="min-width:300px" translate="services.kocoDumpster.table.description"></th>
            <th scope="col" style="min-width:125px" [appHasAnyRole]="[AppRoles.configurator]"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="kocoDevices.length === 0">
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td></td>
        </tr>

        <tr *ngFor="let kocoDevice of kocoDevices;">
            <td>{{kocoDevice.deviceId}}</td>
            <td>{{kocoDevice['name' + crtLang.toUpperCase()]}}</td>
            <td>{{kocoDevice['description' + crtLang.toUpperCase()]}}</td>

            <td class="text-end" [appHasAnyRole]="[AppRoles.configurator]">
                <button class="btn btn-link btn-sm"
                        (click)="openQrCode(kocoDevice)"
                        title="{{ 'common.tooltip.displayQrCode' | translate }}">
                    <fa-icon [icon]="icons.qrcode"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm" type="button"
                        (click)="updateKocoDevice(kocoDevice)"
                        title="{{'common.tooltip.edit' | translate}}">
                    <fa-icon [icon]="icons.edit"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm" type="button" [appHasAnyRole]="[AppRoles.configurator]"
                        (click)="deleteKocoDevice(kocoDevice)"
                        title="{{'common.tooltip.delete' | translate}}">
                    <fa-icon [icon]="icons.delete"></fa-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="content-container bg-white">
    <h1 translate="equipments.interventions.title"></h1>

    <div class="btn-bar">
        <button class="btn btn-primary"
                [routerLink]="['create']"
                translate="equipments.interventions.add">
        </button>
    </div>

    <!-- Filters -->
    <div [formGroup]="filterForm">
        <div class="row">

            <!-- Equipment Name Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="equipments.interventions.filter.equipmentName"></label>
                <input class="form-control " id="equipmentName"
                       type="text" maxlength="100"
                       placeholder="{{ 'equipments.interventions.filter.equipmentName' | translate }}"
                       [formControl]="equipmentName">
            </div>

            <!-- Person in Charge Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="equipments.interventions.filter.personInCharge"></label>
                <input class="form-control " id="personInCharge"
                       type="text" maxlength="100"
                       placeholder="{{ 'equipments.interventions.filter.personInCharge' | translate }}"
                       [formControl]="personInCharge">
            </div>

            <!-- Monitoring Person Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="equipments.interventions.filter.monitoringPerson"></label>
                <input class="form-control " id="monitoringPerson"
                       type="text" maxlength="100"
                       placeholder="{{ 'equipments.interventions.filter.monitoringPerson' | translate }}"
                       [formControl]="monitoringPerson">
            </div>

            <!-- Date -->
            <div class="default-filter-form-field">
                <app-date-period-picker [periodControl]="date"></app-date-period-picker>
            </div>


        </div>

        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   (click)="clearFilter()">
            <input class="btn btn-primary" type="button" (click)="filterInterventions()"
                   [value]="'common.filter' | translate"
                   [disabled]="filterForm.invalid">
        </div>
    </div>

    <!-- Datatable -->
    <ngx-datatable #myTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="equipmentInterventions.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   [rowHeight]="'100%'"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="equipmentInterventions.rowCount"
                   [sorts]="[{prop: 'equipmentName', dir: 'Desc'}]"
                   [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                    }"
                   [offset]="crtPage"
                   [limit]="equipmentInterventions.pageSize"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [scrollbarH]="true">

        <!-- Equipment Name -->
        <ngx-datatable-column [width]="175" prop="equipmentName"
                              [name]="'equipments.interventions.table.equipmentName' | translate"
                              [sortable]="true">
        </ngx-datatable-column>

        <!-- Date-->
        <ngx-datatable-column [width]="150" prop="Date"
                              [name]="'equipments.interventions.table.date' | translate"
                              [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span>{{row.date | date: 'dd.MM.yyyy'}}</span>
            </ng-template>
        </ngx-datatable-column>

        <!-- Person In Charge -->
        <ngx-datatable-column [width]="150" prop="personInCharge"
                              [name]="'equipments.interventions.table.personInCharge' | translate"
                              [sortable]="true">
        </ngx-datatable-column>

        <!-- Monitoring Person -->
        <ngx-datatable-column [width]="150" prop="monitoringPerson"
                              [name]="'equipments.interventions.table.monitoringPerson' | translate"
                              [sortable]="true">
        </ngx-datatable-column>


        <ngx-datatable-column [width]="50" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <button class="btn btn-link btn-sm" [routerLink]="[row.id]"
                        [title]="'common.tooltip.edit' | translate">
                    <fa-icon [icon]="icons.edit"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm"
                        (click)="deleteIntervention(row.id)"
                        [title]="'common.tooltip.delete' | translate">
                    <fa-icon [icon]="icons.delete"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-curPage="curPage">
                <div class="page-count">
                    {{ 'equipments.interventions.table.results' | translate:{InterventionsCount: rowCount} }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="myTable.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</div>

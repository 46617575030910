<div>
    <div class="modal-header">
        <h4 class="modal-title" translate>services.common.question.modal.title</h4>

        <button class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">

        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="craningServiceQuestionForm" *ngIf="!!selectedCraningServiceQuestionToEdit">

            <!-- QUESTION LABELS -->
            <div class="form-group form-row">

                <div class="input-group col-12">
                    <label class="col-12" for="questionFR">{{ 'services.common.question.questionFR' | translate }}*</label>
                    <div class="input-group col-12">
                        <input class="form-control" name="questionFR" id="questionFR" type="text" maxlength="255"
                               formControlName="questionFR" pattern="{{textInputPattern}}" >
                    </div>
                    <div class="col-12">
                        <app-form-error [control]="questionFR"></app-form-error>
                    </div>
                </div>
            </div>

            <div class="form-group form-row" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <div class="input-group col-12">
                    <label class="col-12" for="questionDE" translate>services.common.question.questionDE</label>
                    <div class="input-group col-12">
                        <input class="form-control" name="questionDE" id="questionDE" type="text" maxlength="255"
                               formControlName="questionDE" pattern="{{textInputPattern}}" >
                    </div>
                </div>
            </div>

            <div class="form-group form-row" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <div class="input-group col-12">
                    <label class="col-12" for="questionIT" translate>services.common.question.questionIT</label>
                    <div class="input-group col-12">
                        <input class="form-control" name="questionIT" id="questionIT" type="text" maxlength="255"
                               formControlName="questionIT" pattern="{{textInputPattern}}" >
                    </div>
                </div>
            </div>

            <div class="form-group form-row" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <div class="input-group col-12">
                    <label class="col-12" for="questionEN" translate>services.common.question.questionEN</label>
                    <div class="input-group col-12">
                        <input class="form-control" name="questionEN" id="questionEN" type="text" maxlength="255"
                               formControlName="questionEN" pattern="{{textInputPattern}}" >
                    </div>
                </div>
            </div>

            <!-- ANSWER TYPE -->

            <div class="form-group form-row">
                <div class="input-group col-12">
                    <label class="col-12">{{ 'services.common.question.answerType' | translate }}*</label>

                    <div class="col-12">
                        <div class="btn-group" role="group">
                            <ng-container *ngFor="let answerTypeValue of answerTypes; let last = last; let first = first;">
                                <input class="btn-check" type="radio"
                                       [formControl]="answerType"
                                       [value]="answerTypeValue">

                                <label class="btn btn-toggle h-auto"
                                       [ngClass]='{
                                       "active": answerTypeValue === answerType.value,
                                       "btn-toggle-left": first,
                                       "btn-toggle-right": last }'
                                       (click)="answerType.setValue(answerTypeValue)"
                                       (keyup)="answerType.setValue(answerTypeValue)">
                                    {{ 'services.common.question.answerTypeEnum.' + answerTypeValue | translate }}
                                </label>
                            </ng-container>
                        </div>
                    </div>

                    <div class="col-12">
                        <app-form-error [control]="answerType"></app-form-error>
                    </div>
                </div>
            </div>
        </form>

        <div *ngIf="!selectedCraningServiceQuestionToEdit">
            <div class="btn-bar">
                <button class="btn btn-primary" type="button"
                        (click)="createCraningServiceQuestion()"
                        translate>services.common.question.actions.add
                </button>
            </div>

            <div class="table-responsive">
                <table class="table table-striped" *ngIf="craningServiceQuestions.length > 0"
                       [attr.aria-label]="'common.questions' | translate">
                    <thead>
                    <tr>
                        <th scope="col" style="min-width: 70%" translate>services.common.question.question</th>
                        <th scope="col" style="min-width: 20%" translate>services.common.question.answerType</th>
                        <th scope="col" style="min-width:115px"></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr *ngFor="let question of craningServiceQuestions;">

                        <td>{{ getCraningServiceQuestionLabel(question) }}</td>

                        <td>
                            {{ 'services.common.question.answerTypeEnum.' + question.answerType | translate }}
                        </td>

                        <td class="text-end">
                            <button class="btn btn-link btn-sm" type="button"
                                    (click)="updateCraningServiceQuestion(question.id)"
                                    title="{{ 'common.tooltip.edit' | translate }}">
                                <fa-icon [icon]="icons.edit"></fa-icon>
                            </button>

                            <button class="btn btn-link btn-sm" type="button"
                                    (click)="deleteCraningServiceQuestion(question.id)"
                                    title="{{ 'common.tooltip.delete' | translate }}">
                                <fa-icon [icon]="icons.delete"></fa-icon>
                            </button>
                        </td>

                    </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div>

    <div class="modal-footer">

        <button type="submit" class="btn btn-outline-secondary" (click)="activeModal.close()" translate *ngIf="!selectedCraningServiceQuestionToEdit">
            common.close
        </button>

        <button type="submit" class="btn btn-outline-secondary" (click)="cancelCraningServiceQuestion()" translate *ngIf="!!selectedCraningServiceQuestionToEdit">
            common.cancel
        </button>

        <button class="btn btn-primary" type="submit"
                *ngIf="!!selectedCraningServiceQuestionToEdit"
                [disabled]="craningServiceQuestionForm.invalid"
                (click)="addOrUpdateCraningService()">
            {{ (!selectedCraningServiceQuestionToEdit?.id ? 'common.add' : 'common.save') | translate }}
        </button>

    </div>
</div>


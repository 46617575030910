import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from '../../../../_shared/custom-date-formatter';
import {FormUtils} from '../../../../_shared/form-utils';
import {DateUtils} from '../../../../_shared/date-utils';
import {
    PottingerService,
    PottingerStatisticExportCriteriaDto
} from '../../../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as fileSaver from 'file-saver';

@Component({
    selector: 'app-export-pottinger-statistic-modal',
    templateUrl: './export-pottinger-statistic-modal.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class ExportPottingerStatisticModalComponent implements OnInit {

    maxDate: NgbDateStruct;
    DateUtils = DateUtils;

    exportPottingerStatisticForm: UntypedFormGroup;

    constructor(public exportPottingerStatisticModal: NgbActiveModal,
                private readonly pottingerService: PottingerService) {
    }

    ngOnInit(): void {
        this.exportPottingerStatisticForm = new UntypedFormGroup({
            datePeriod: new UntypedFormGroup({
                from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator])
        }, {updateOn: 'change'});

        this.maxDate = DateUtils.dateToNgbDateStruct(new Date());
    }

    get datePeriod(): UntypedFormGroup {
        return this.exportPottingerStatisticForm.get('datePeriod') as UntypedFormGroup;
    }

    get from(): UntypedFormControl {
        return this.datePeriod.get('from') as UntypedFormControl;
    }

    get to(): UntypedFormControl {
        return this.datePeriod.get('to') as UntypedFormControl;
    }

    exportPottingerStatistics(): void {
        if (!this.exportPottingerStatisticForm.valid) {
            return;
        }

        this.pottingerService.exportStatistics(this.getFormResult()).pipe().subscribe(
            response => {
                this.exportPottingerStatisticModal.close();
                fileSaver.saveAs(response.data, response.fileName);
            }
        );
    }

    private getFormResult(): PottingerStatisticExportCriteriaDto {

        const from = DateUtils.ngbDateStructToDate(this.from.value);
        from?.setHours(0, 0, 0, 0);
        const to = DateUtils.ngbDateStructToDate(this.to.value);
        to?.setHours(23, 59, 59, 59);

        return new PottingerStatisticExportCriteriaDto({
            from: from,
            to: to
        });
    }

}

<div class="content-container bg-white">

    <h1 translate>{{ editingMyProfile ? 'users.form.actions.titleModifyMyProfile' :
        !!userId ? 'users.form.actions.titleModify' : 'users.form.actions.titleCreate' }}</h1>

    <div [appHasAnyRole]="[AppRoles.admin]" *ngIf="!editingMyProfile">
        <div class="btn-bar">
            <button class="btn btn-outline-secondary" type="button"
                    (click)="back()"
                    translate="common.back">
            </button>

            <button class="btn btn-outline-danger" *ngIf="!!userId"
                    (click)="deleteUser()"
                    translate="common.delete">
            </button>
        </div>
    </div>

    <form [formGroup]="userForm" class="mt-4">

        <div *ngIf="!!userId">
            <p><em translate="users.form.reallocation-warning"></em></p>
        </div>

        <!-- ETIQUETTE -->
        <div class="row">
            <div class="col-12">
                <label class="form-label">{{ 'users.form.title.label' | translate }}*</label>

                <div class="form-check">
                    <input class="form-check-input" type="radio" id="Madam" name="title"
                           value="Madam"
                           [formControl]="title">
                    <label class="form-check-label" for="Madam"
                           translate="users.form.title.madam"></label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" id="Mister" name="title"
                           value="Mister"
                           [formControl]="title">
                    <label class="form-check-label" for="Mister"
                           translate="users.form.title.mister"></label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" id="notSpecified" name="title"
                           value="NotSpecified"
                           [formControl]="title">
                    <label class="form-check-label" for="notSpecified"
                           translate="users.form.title.notspecified"></label>
                </div>
                <app-form-error [control]="title"></app-form-error>
            </div>


            <!-- UserId -->
            <div class="col-12 col-lg-6" *ngIf="!!userId">
                <label class="form-label" for="userId">{{ 'users.form.userId' | translate }}</label>
                <input class="form-control" id="userId" type="text"
                       [formControl]="id" disabled>
            </div>

            <!-- AccountEnabled -->
            <div class="col-12 col-lg-6" *ngIf="!editingMyProfile">
                <label class="form-label">{{ 'users.form.accountEnabled.label' | translate }}*</label>
                <div class="form-check form-switch">
                    <input class="form-check-input" id="accountEnabled" type="checkbox" name="accountEnabled"
                           [formControl]="accountEnabled">
                    <label class="form-check-label" for="accountEnabled">
                        {{ (accountEnabled.value ? 'users.form.accountEnabled.true' : 'users.form.accountEnabled.false') | translate }}
                    </label>
                </div>
                <app-form-error [control]="accountEnabled"></app-form-error>
            </div>


            <!-- firstname -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="firstname">{{ 'users.form.firstname' | translate }}*</label>
                <input class="form-control " id="firstname" type="text" maxlength="50"
                       placeholder="{{ 'users.form.firstname' | translate }}"
                       [formControl]="firstname"
                       pattern="{{textInputPattern}}">
                <app-form-error [control]="firstname"></app-form-error>
            </div>

            <!-- lastname -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="lastname">{{ 'users.form.lastname' | translate }}*</label>
                <input class="form-control " id="lastname" type="text" maxlength="50"
                       placeholder="{{ 'users.form.lastname' | translate }}"
                       [formControl]="lastname"
                       pattern="{{textInputPattern}}">
                <app-form-error [control]="lastname"></app-form-error>
            </div>

            <!-- birthDate -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="birthDate" translate="users.form.birthDate"></label>
                <div class="input-group">
                    <input class="form-control" placeholder="{{ 'common.placeholders.dateFormat' | translate}}"
                           id="birthDate"
                           [formControl]="birthDate"
                           ngbDatepicker
                           #dpBirthDate="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-link btn-sm" (click)="dpBirthDate.toggle()">
                            <fa-icon [icon]="icons.calendar"></fa-icon>
                        </button>
                        <button class="btn btn-link btn-sm" *ngIf="!!birthDate.value"
                                (click)="birthDate.setValue(null)">
                            <fa-icon [icon]="icons.times"></fa-icon>
                        </button>
                    </div>
                </div>
                <app-form-error [control]="birthDate"></app-form-error>
            </div>

            <!-- email -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="email">{{ 'users.form.email' | translate }}*</label>
                <input class="form-control " id="email" type="text" maxlength="75"
                       placeholder="{{ 'users.form.email' | translate }}"
                       [formControl]="email"
                       pattern="{{textInputPattern}}">
                <app-form-error [control]="email"></app-form-error>
            </div>

            <ng-container *ngIf="!editingMyProfile">

                <!-- CustomerNumber -->
                <div class="col-12 col-lg-6">
                    <label class="form-label"
                           for="customerNumber">{{ 'users.form.customerNumber' | translate }}*</label>
                    <input class="form-control" id="customerNumber" type="text" maxlength="25"
                           placeholder="{{ 'users.form.customerNumber' | translate }}"
                           [formControl]="customerNumber"
                           pattern="{{textInputPattern}}">
                    <app-form-error [control]="customerNumber"></app-form-error>
                </div>

                <!-- AttentionTo -->
                <div class="col-12 col-lg-6">
                    <label class="form-label" for="attentionTo">{{ 'users.form.attentionTo' | translate }}</label>
                    <input class="form-control" id="attentionTo" type="text" maxlength="50"
                           placeholder="{{ 'users.form.attentionTo' | translate }}"
                           [formControl]="attentionTo"
                           pattern="{{textInputPattern}}">
                    <app-form-error [control]="attentionTo"></app-form-error>
                </div>
            </ng-container>

            <!-- street -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="street">{{ 'users.form.street' | translate }}*</label>
                <input class="form-control " id="street" type="text" maxlength="50"
                       placeholder="{{ 'users.form.street' | translate }}"
                       [formControl]="street"
                       pattern="{{textInputPattern}}">
                <app-form-error [control]="street"></app-form-error>
            </div>

            <!-- postalCode -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="postalCode">{{ 'users.form.postalCode' | translate }}*</label>
                <input class="form-control " id="postalCode" type="text" maxlength="10"
                       placeholder="{{ 'users.form.postalCode' | translate }}"
                       [formControl]="postalCode"
                       pattern="{{textInputPattern}}">
                <app-form-error [control]="postalCode"></app-form-error>
            </div>
            <!-- city -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="city">{{ 'users.form.city' | translate }}*</label>
                <input class="form-control " id="city" type="text" maxlength="25"
                       placeholder="{{ 'users.form.city' | translate }}"
                       [formControl]="city"
                       pattern="{{textInputPattern}}">
                <app-form-error [control]="city"></app-form-error>
            </div>

            <!-- country -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="country">{{ 'users.form.country' | translate }}*</label>
                <input class="form-control " id="country" type="text" maxlength="25"
                       placeholder="{{ 'users.form.country' | translate }}"
                       [formControl]="country"
                       pattern="{{textInputPattern}}">
                <app-form-error [control]="country"></app-form-error>
            </div>

            <!-- phoneNumbers -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="phone" translate="users.form.phone"></label>
                <input class="form-control " id="phone" type="tel" maxlength="15"
                       placeholder="{{ 'common.placeholders.phone' | translate }}"
                       [formControl]="phone">
            </div>
            <div class="col-12 col-lg-6">
                <label class="form-label" for="mobilePhone" translate="users.form.mobilePhone"></label>
                <input class="form-control " id="mobilePhone" type="tel" maxlength="15"
                       placeholder="{{ 'common.placeholders.phone' | translate }}"
                       [formControl]="mobilePhone">
            </div>
            <div class="col-12 col-lg-6">
                <label class="form-label" for="businessPhone" translate="users.form.businessPhone"></label>
                <input class="form-control " id="businessPhone" type="tel" maxlength="15"
                       placeholder="{{ 'common.placeholders.phone' | translate }}"
                       [formControl]="businessPhone">
            </div>

            <!-- RFID CARDS ON VIEW CURRENT USER PROFILE -->
            <div class="col-12 col-lg-6" *ngIf="editingMyProfile">
                <label translate="users.rfid.rfidCards"></label>
                <p *ngIf="rfidCards.length === 0" translate="users.rfid.noAssignedCard"></p>
                <p *ngFor="let rfidCard of rfidCards">
                    <span>- {{ rfidCard.cardNumber }}</span>
                </p>
            </div>

        </div>

        <app-role-management *ngIf="!editingMyProfile"
                             [enablePublicAccess]="false"
                             [enableBaseRoles]="true"
                             [enableCustomRoles]="true"
                             [userOnly]="false"
                             [roleForm]="rolesFormArray"></app-role-management>

        <div *ngIf="!editingMyProfile">

            <h4 class="mt-4" translate="users.rfid.rfidCards"></h4>

            <!-- RFID Cards -->
            <div class="btn-bar">
                <button class="btn btn-primary" type="button"
                        (click)="addRfidCard()"
                        translate="users.rfid.add"></button>
            </div>

            <table class="table table-striped mb-5"
                   aria-label="{{ 'users.rfid.rfidCards' | translate }}">
                <thead>
                <tr>
                    <th scope="col" translate="rfidCards.table.cardNumber"></th>
                    <th scope="col" translate="rfidCards.table.uid"></th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="rfidCards.length === 0">
                    <td>-</td>
                    <td>-</td>
                    <td></td>
                </tr>
                <tr *ngFor="let rfid of rfidCards">
                    <td> {{ rfid.cardNumber }}</td>
                    <td> {{ rfid.uid }}</td>
                    <td class="text-end">
                        <button class="btn btn-link btn-sm" type="button" (click)="unassignRfidCard(rfid)"
                                title="{{'common.tooltip.unassignRfidCard' | translate}}">
                            <fa-icon [icon]="icons.userSlash"></fa-icon>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="text-center" *ngIf="!editingMyProfile">
            <p><em translate="user-creation.form.message.rgpd_warning"></em></p>
        </div>

        <div class="row" *ngIf="!editingMyProfile">

            <!-- Comment -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="comment" translate="users.form.comment"></label>
                <textarea class="form-control" id="comment" name="comment"
                          maxlength="255"
                          [formControl]="comment"
                          placeholder="{{ 'users.form.comment' | translate }}"></textarea>
            </div>

            <!-- internalField1 -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="internalField1" translate="users.form.internalField1"></label>
                <textarea id="internalField1" name="internalField1" class="form-control"
                          maxlength="255"
                          [formControl]="internalField1"
                          placeholder="{{ 'users.form.internalField1' | translate }}"></textarea>
            </div>

            <ng-container *ngIf="!editingMyProfile">

                <!-- internalField2 -->
                <div class="col-12 col-lg-6">
                    <label class="form-label" for="internalField2" translate="users.form.internalField2"></label>

                    <textarea id="internalField2" name="internalField2" class="form-control"
                              maxlength="255"
                              [formControl]="internalField2"
                              placeholder="{{ 'users.form.internalField2' | translate }}"></textarea>
                </div>

                <!-- internalField3 -->
                <div class="col-12 col-lg-6">
                    <label class="form-label" for="internalField3" translate="users.form.internalField3"></label>
                    <textarea id="internalField3" name="internalField3" class="form-control"
                              maxlength="255"
                              [formControl]="internalField3"
                              placeholder="{{ 'users.form.internalField3' | translate }}"></textarea>
                </div>
            </ng-container>

            <ng-container *ngIf="!editingMyProfile">

                <!-- internalField4 -->
                <div class="col-12 col-lg-6">
                    <label class="form-label" for="internalField4" translate="users.form.internalField4"></label>
                    <textarea id="internalField4" name="internalField4" class="form-control"
                              maxlength="255"
                              [formControl]="internalField4"
                              placeholder="{{ 'users.form.internalField4' | translate }}"></textarea>
                </div>

                <!-- internalField5 -->
                <div class="col-12 col-lg-6">
                    <label class="form-label" for="internalField5" translate="users.form.internalField5"></label>
                    <textarea id="internalField5" name="internalField5" class="form-control"
                              maxlength="255"
                              [formControl]="internalField5"
                              placeholder="{{ 'users.form.internalField5' | translate }}"></textarea>
                </div>
            </ng-container>

        </div>

        <div ngbAccordion [appHasAnyRole]="[AppRoles.admin]" *ngIf="userId"
             class="mt-4"
             (show)="fetchAudits()">
            <div ngbAccordionItem>
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton translate="users.history.title"></button>
                </h2>

                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template>
                            <ngx-datatable *ngIf="!!pagedAudits"
                                           class="material"
                                           [columnMode]="'force'"
                                           [rows]="pagedAudits.results"
                                           [headerHeight]="50"
                                           [footerHeight]="50"
                                           [rowHeight]="'auto'"
                                           [externalPaging]="true"
                                           [externalSorting]="true"
                                           [count]="pagedAudits.rowCount"
                                           [messages]="{
                                            totalMessage: 'common.datatable.total' | translate
                                        }"
                                           [offset]="auditCurrentPage"
                                           [limit]="auditPageSize"
                                           (page)="setAuditsPage($event)"
                                           [scrollbarH]="true"
                                           [rowClass]="getRowClass">

                                <!-- Date -->
                                <ngx-datatable-column [width]="150" name="{{ 'common.date.date' | translate }}">
                                    <ng-template ngx-datatable-cell-template let-audit="row">
                                        {{ audit.modificationDate | date:dateTimeFormat}}
                                    </ng-template>
                                </ngx-datatable-column>

                                <!-- Modification user -->
                                <ngx-datatable-column [width]="300" name="{{ 'users.history.author' | translate }}">
                                    <ng-template ngx-datatable-cell-template let-audit="row">
                                        {{ audit.modificationUser }}
                                    </ng-template>
                                </ngx-datatable-column>

                                <!-- Modifications -->
                                <ngx-datatable-column [width]="600" name="{{ 'users.history.modifications' | translate }}">
                                    <ng-template ngx-datatable-cell-template let-audit="row">

                                        <!-- Basic entity properties change -->
                                        <div *ngIf="audit.changeType === UserAuditEntryPropertyChangeType.PropertyChange">
                                            <div *ngFor="let change of audit.properties">

                                                <ng-container *ngIf="change.propertyName === 'Title'; else accountEnabledLabel">
                                                    <strong>{{ 'users.history.fields.' + change.propertyName | translate }}</strong>
                                                    :
                                                    {{ 'users.form.title.' + change.oldValue.toLowerCase() | translate }}
                                                    ->
                                                    {{ 'users.form.title.' + change.newValue.toLowerCase() | translate }}
                                                </ng-container>

                                                <ng-template #accountEnabledLabel>
                                                    <ng-container *ngIf="change.propertyName === 'AccountEnabled'; else default">
                                                        <div *ngIf="change.newValue === 'True'">
                                                            {{ 'users.history.accountActivation' | translate }}
                                                        </div>
                                                        <div *ngIf="change.newValue === 'False'">
                                                            {{ 'users.history.accountDeactivation' | translate }}
                                                        </div>
                                                    </ng-container>
                                                </ng-template>

                                                <ng-template #default>
                                                    <strong>{{ 'users.history.fields.' + change.propertyName | translate }}</strong>
                                                    :
                                                    {{ change.oldValue }}
                                                    ->
                                                    {{ change.newValue }}
                                                </ng-template>
                                            </div>
                                        </div>

                                        <div *ngIf="audit.changeType === UserAuditEntryPropertyChangeType.UserCreation">
                                            {{ 'users.history.userCreation' | translate }}
                                        </div>

                                        <div *ngIf="audit.changeType === UserAuditEntryPropertyChangeType.RoleAssignment">
                                            {{ 'users.history.roleAssignment' | translate }}
                                            <i>{{ audit['roleLabel' + crtLang.toUpperCase()] }}</i>
                                        </div>

                                        <div *ngIf="audit.changeType === UserAuditEntryPropertyChangeType.RoleUnassignment">
                                            {{ 'users.history.roleUnassignment' | translate }}
                                            <i>{{ audit['roleLabel' + crtLang.toUpperCase()] }}</i>
                                        </div>

                                        <div *ngIf="audit.changeType === UserAuditEntryPropertyChangeType.RfidCardAssignment">
                                            {{ 'users.history.rfidCardAssignment' | translate }}
                                            <i>{{ audit.rfidCardUid }}</i>
                                        </div>

                                        <div *ngIf="audit.changeType === UserAuditEntryPropertyChangeType.RfidCardUnassignment">
                                            {{ 'users.history.rfidCardUnassignment' | translate }}
                                            <i>{{ audit.rfidCardUid }}</i>
                                        </div>

                                        <div *ngIf="audit.changeType === UserAuditEntryPropertyChangeType.RfidCardStateChange">
                                            {{ 'users.history.rfidCardStateChange' | translate }}
                                            <i>{{ audit.rfidCardUid }}</i> : {{ 'rfidCards.status.' +  audit.properties[0].oldValue.toLowerCase() | translate }} -> {{ 'rfidCards.status.' + audit.properties[0].newValue.toLowerCase() | translate }}
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>

        <div class="btn-bar">
            <button class="btn btn-outline-secondary" type="button"
                    (click)="back()"
                    translate="common.back">
            </button>
            <button class="btn btn-outline-secondary" type="button" *ngIf="editingMyProfile"
                    (click)="resetPassword()" translate="users.form.actions.changePassword">
            </button>
            <button type="submit" class="btn btn-primary"
                    [disabled]="userForm.invalid"
                    (click)="save()">
                {{ ((!!userId || editingMyProfile) ? 'common.save' : 'common.create') | translate }}
            </button>
        </div>

    </form>

</div>

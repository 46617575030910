import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, skipWhile} from 'rxjs/operators';
import {CommunicationChannelConfigService} from '../../_shared/communication-channel-config.service';

@Injectable({
    providedIn: 'root'
})
export class CommunicationChannelGuardService {

    constructor(private router: Router,
                private readonly communicationChannelConfigService: CommunicationChannelConfigService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (!CommunicationChannelConfigService.isConfigLoaded()) {

            return this.communicationChannelConfigService.configurationLoadedMessage.pipe(
                skipWhile(e => !e),
                map(e => {
                    if (!e) {
                        return false;
                    }

                    return this.hasAccessToChannel(route);

                }), catchError(() => {
                    return of(false);
                }));

        } else {
            return this.hasAccessToChannel(route);
        }
    }

    private hasAccessToChannel(route: ActivatedRouteSnapshot): boolean {
        const communicationChannelType = route.data['communicationChannelType'];

        let hasAccess = false;
        switch (communicationChannelType) {
            case 'information':
                hasAccess = this.communicationChannelConfigService.isCommunicationChannelInformationEnabled();
                break;
            case 'contact':
                hasAccess = this.communicationChannelConfigService.isCommunicationChannelContactEnabled();
                break;
            case 'survey':
                hasAccess = this.communicationChannelConfigService.isCommunicationChannelSurveyEnabled();
                break;
            case 'general':
                hasAccess = this.communicationChannelConfigService.isCommunicationChannelEnabled();
                break;
        }

        if (!hasAccess) {
            // Redirect to the home page
            this.router.navigate(['/services']);
        }

        return hasAccess;
    }
}

export const CommunicationChannelGuard: CanActivateFn =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    return inject(CommunicationChannelGuardService).canActivate(route, state);
}

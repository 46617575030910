import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    BillingRecurrence,
    PaymentTypes,
    RoleService,
    ServiceConfigDto,
    ServiceType,
    TariffConfigDto,
    TariffDto,
    TariffInputDto,
    TariffService,
    TariffType,
    TimeOfPayment
} from '../../../../_services/configuration-services';
import {NgbActiveModal, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {DateUtils} from '../../../../_shared/date-utils';
import {CustomDateFormatter} from '../../../../_shared/custom-date-formatter';
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomerConfigService} from '../../../../_shared/customer-config-service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {FormUtils} from '../../../../_shared/form-utils';
import {TranslateUtils} from '../../../../_shared/translate-utils';
import {TariffUtils} from '../../../../_shared/tariff-utils';
import {firstValueFrom} from 'rxjs';
import {
    TariffPricesForm,
    TariffPricesManagementComponent
} from '../tariff-prices-management/tariff-prices-management.component';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';

@Component({
    selector: 'app-tariff-modal',
    templateUrl: './tariff-modal.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class TariffModalComponent implements OnInit {

    @Input() serviceConfig!: ServiceConfigDto;
    @Input() serviceId!: string;
    @Input() defaultTariff: TariffDto | null = null;
    @Input() tariff: TariffDto | null;
    @Input() containsMultipleTariffs = false;

    @ViewChild('tariffPricesManagementComponent') tariffPricesManagementComponent: TariffPricesManagementComponent;
    @ViewChild('nightTariffPricesManagementComponent') nightTariffPricesManagementComponent: TariffPricesManagementComponent;

    isDefaultTariff = true;

    DateUtils = DateUtils;
    FormUtils = FormUtils;
    TariffType = TariffType;

    tariffForm: UntypedFormGroup;

    availablePaymentTypes = Object.keys(PaymentTypes);
    availableTimesOfPayment = Object.keys(TimeOfPayment);
    availableBillingRecurrence = Object.keys(BillingRecurrence);

    minDate = DateUtils.dateToNgbDateStruct(new Date());

    // Mutli-select components
    paymentTypeSelect;
    customRoleSelect;

    crtLang = TranslateUtils.defaultLanguage;

    constructor(public activeModal: NgbActiveModal,
                public readonly customerConfigService: CustomerConfigService,
                private readonly tariffService: TariffService,
                private readonly roleService: RoleService,
                private readonly notificationService: NotificationsService,
                private readonly translateService: TranslateService) {

        this.tariffForm = new UntypedFormGroup({
                tariffConfig: new FormControl<TariffConfigDto>(null, [Validators.required]),
                nightRate: new FormControl<boolean>(false),
                nightRateStart: new FormControl<NgbTimeStruct|null>(null),
                nightRateEnd: new FormControl<NgbTimeStruct|null>(null),
                selectedPaymentTypes: new FormControl<PaymentTypes[]>([], FormUtils.requiredOneSelectedValidator),
                selectedTimeOfPayment: new FormControl<TimeOfPayment>(null, Validators.required),
                selectedBillingRecurrence: new FormControl<BillingRecurrence>({ value: null, disabled: true })
            },
            {updateOn: 'change'}
        );
    }

    async ngOnInit(): Promise<void> {
        this.crtLang = this.translateService.currentLang;
        this.isDefaultTariff = this.defaultTariff === null || this.defaultTariff.id === this.tariff?.id;

        if (!this.isDefaultTariff || this.containsMultipleTariffs) {
            this.tariffConfig.disable();
            this.selectedTimeOfPayment.disable();
            this.selectedBillingRecurrence.disable();

            this.nightRate.disable();
            this.nightRateStart.disable();
            this.nightRateEnd.disable();

        } else if (!this.serviceConfig.dayAndNightTariff) {
            this.nightRate.disable();
            this.nightRateStart.disable();
            this.nightRateEnd.disable();
        }

        this.initSelectComponents();

        // Add specific controls to allow overriding the default tariff
        if (!this.isDefaultTariff) {
            this.tariffForm.addControl('selectedCustomRoles', new UntypedFormControl([], FormUtils.requiredOneSelectedValidator));

            this.tariffForm.addControl('validityPeriod', new UntypedFormGroup({
                from: new UntypedFormControl(DateUtils.dateToNgbDateStruct(this.tariff?.validityFrom), [FormUtils.datePatternValidator]),
                to: new UntypedFormControl(DateUtils.dateToNgbDateStruct(this.tariff?.validityTo), [FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]));

            // Modify the minDate to ensure we don't display an error if the dates are in past
            const from = DateUtils.dateToNgbDateStruct(this.tariff?.validityFrom);
            const to = DateUtils.dateToNgbDateStruct(this.tariff?.validityTo);
            if (DateUtils.before(from, this.minDate)) {
                this.minDate = from;
            } else if (DateUtils.before(to, this.minDate)) {
                this.minDate = to;
            }

            const roles = await firstValueFrom(this.roleService.getAllRoles(false));
            this.customRoleSelect.dropdownList = [];
            roles.filter(r => !r.isBase)
                .forEach(r => {
                    this.customRoleSelect.dropdownList.push({
                        itemId: r.roleKey,
                        itemVal: r['label' + this.crtLang.toUpperCase()]
                    });
                });
        }

        // Select the tariff configuration
        let selectedTariffConfig = this.serviceConfig.tariffConfigs[0];
        if (this.tariff) {
            selectedTariffConfig = this.serviceConfig.tariffConfigs.filter(c => c.id === this.tariff.tariffConfig.id)[0];

        } else if (this.defaultTariff) {
            selectedTariffConfig = this.serviceConfig.tariffConfigs.filter(c => c.id === this.defaultTariff.tariffConfig.id)[0];
        }

        this.tariffConfig.patchValue(selectedTariffConfig);
        this.adaptFormDependingTariffConfig();

        this.fillFormWithDefaultValues(this.tariff ?? this.defaultTariff ?? null);
    }

    fillFormWithDefaultValues(tariff: TariffDto | null): void {
        if (tariff) { // Fill the form with the tariff to edit

            this.nightRate.patchValue(tariff.nightRate);
            this.nightRateStart.patchValue(DateUtils.stringToNgbTimeStruct(tariff.nightRateFrom));
            this.nightRateEnd.patchValue(DateUtils.stringToNgbTimeStruct(tariff.nightRateTo));

            this.selectedTimeOfPayment.patchValue(tariff.timeOfPayment);
            this.selectedBillingRecurrence?.patchValue(tariff.billingRecurrence);

            const selectedPaymentTypes = [];
            tariff.paymentTypes.forEach(r => selectedPaymentTypes.push({
                itemId: r,
                itemVal: this.translateService.instant('common.paymentType.' + r)
            }));
            this.selectedPaymentTypes.patchValue(selectedPaymentTypes);

            if (!this.isDefaultTariff) {
                this.selectedCustomRoles.patchValue(tariff.customRoles?.map(roleKey => {
                    return this.customRoleSelect.dropdownList.find(r => r.itemId === roleKey);
                }).filter(t => !!t) ?? []);
            }
        }
    }

    addOrUpdateTariff(): void {
        const tariffPrices = this.tariffPricesManagementComponent.getTariffPricesFromPricesForm();

        if (this.nightRate.value) {
            tariffPrices.push(...this.nightTariffPricesManagementComponent.getTariffPricesFromPricesForm())
        }

        const tariff = new TariffInputDto({
            tariffConfigId: this.tariffConfig.value.id,
            serviceId: this.serviceId,
            defaultTariff: this.defaultTariff === null || this.defaultTariff.id === this.tariff?.id,
            nightRate: this.nightRate.value,
            nightRateFrom: DateUtils.ngbTimeStructToString(this.nightRateStart.value),
            nightRateTo: DateUtils.ngbTimeStructToString(this.nightRateEnd.value),
            tariffPrices: tariffPrices,
            paymentTypes: this.selectedPaymentTypes.value.map(item => item['itemId']),
            timeOfPayment: this.selectedTimeOfPayment.value,
            billingRecurrence: this.selectedBillingRecurrence?.value,
            customRoles: this.selectedCustomRoles?.value.map(item => item.itemId) ?? [],
            validityFrom: DateUtils.ngbDateStructToDate(this.validityPeriod?.value.from),
            validityTo: DateUtils.ngbDateStructToDate(this.validityPeriod?.value.to)
        });

        if (this.tariffPricesManagementComponent.customUnitFR.enabled) {
            tariff.customUnitFR = this.tariffPricesManagementComponent.customUnitFR.value;
            tariff.customUnitDE = this.tariffPricesManagementComponent.customUnitDE.value;
            tariff.customUnitEN = this.tariffPricesManagementComponent.customUnitEN.value;
            tariff.customUnitIT = this.tariffPricesManagementComponent.customUnitIT.value;
        }

        if (this.tariff) {
            this.tariffService.updateTariff(this.tariff.id, tariff).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.tariff.notifications.updateSuccess'});
                this.activeModal.close('success');
            });

        } else {
            this.tariffService.createTariff(tariff).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.tariff.notifications.addSuccess'});
                this.activeModal.close('success');
            });
        }
    }

    adaptFormDependingTariffConfig(): void {
        const selectedTariffConfig = this.tariffConfig.value;

        // Init the form fields
        this.availableTimesOfPayment = TariffUtils.initTimeOfPayment(this.serviceConfig.serviceType, selectedTariffConfig);
        this.selectedTimeOfPayment.patchValue(this.availableTimesOfPayment[0] as TimeOfPayment);

        this.availableBillingRecurrence = TariffUtils.initBillingRecurrence(this.serviceConfig.serviceType);
        this.selectedBillingRecurrence.patchValue(this.availableBillingRecurrence[0] as BillingRecurrence);

        this.initPaymentTypes();
    }

    initPaymentTypes(): void {
        this.availablePaymentTypes = TariffUtils.initPaymentTypes(this.serviceConfig.serviceType, this.selectedTimeOfPayment.value);

        if (!this.customerConfigService.isEwalletEnabled()) {
            this.availablePaymentTypes = this.availablePaymentTypes.filter(t => t !== PaymentTypes.Wallet);
        }
        if (!this.customerConfigService.isEPaymentEnabled()) {
            this.availablePaymentTypes = this.availablePaymentTypes.filter(t => t !== PaymentTypes.Ebanking);
        }
        if (!this.customerConfigService.isExternalBillingEnabled()) {
            this.availablePaymentTypes = this.availablePaymentTypes.filter(t => t !== PaymentTypes.Billing);
        }

        // Init the multiselect content
        this.paymentTypeSelect.settings.singleSelection = this.serviceConfig.serviceType === ServiceType.Energy;
        this.paymentTypeSelect.dropdownList = [];
        this.availablePaymentTypes.forEach(r => this.paymentTypeSelect.dropdownList.push({
            itemId: r,
            itemVal: this.translateService.instant('common.paymentType.' + r)
        }));
        this.selectedPaymentTypes.patchValue(this.selectedPaymentTypes.value.filter(t => this.availablePaymentTypes.indexOf(t['itemId']) !== -1));
    }

    private initSelectComponents(): void {
        this.paymentTypeSelect = {
            dropdownList: [], // List of available items in multi-select
            settings: {
                idField: 'itemId',
                textField: 'itemVal',
                singleSelection: false,
                selectAllText: this.translateService.instant('common.multiselect.selectAll'),
                unSelectAllText: this.translateService.instant('common.multiselect.unselectAll'),
                searchPlaceholderText: this.translateService.instant('common.multiselect.search'),
                noDataAvailablePlaceholderText: this.translateService.instant('common.multiselect.noResults'),
                itemsShowLimit: 3,
                allowSearchFilter: false
            }
        };

        // CustomRole Mutli-select
        this.customRoleSelect = {
            dropdownList: [], // List of available items in multi-select
            settings: {
                idField: 'itemId',
                textField: 'itemVal',
                singleSelection: false,
                selectAllText: this.translateService.instant('common.multiselect.selectAll'),
                unSelectAllText: this.translateService.instant('common.multiselect.unselectAll'),
                searchPlaceholderText: this.translateService.instant('common.multiselect.search'),
                noDataAvailablePlaceholderText: this.translateService.instant('common.multiselect.noResults'),
                itemsShowLimit: 5,
                allowSearchFilter: false
            }
        };
    }

    get tariffConfig(): FormControl<TariffConfigDto> {
        return this.tariffForm.get('tariffConfig') as FormControl<TariffConfigDto>;
    }

    get nightRate(): FormControl<boolean> {
        return this.tariffForm.get('nightRate') as FormControl<boolean>;
    }

    get nightRateStart(): FormControl<NgbTimeStruct|null> {
        return this.tariffForm.get('nightRateStart') as FormControl<NgbTimeStruct|null>;
    }

    get nightRateEnd(): FormControl<NgbTimeStruct|null> {
        return this.tariffForm.get('nightRateEnd') as FormControl<NgbTimeStruct|null>;
    }

    get tariffPrices(): FormGroup<TariffPricesForm> | null {
        return this.tariffForm.get('prices') as FormGroup<TariffPricesForm> | null;
    }

    get selectedPaymentTypes(): FormControl<PaymentTypes[]> {
        return this.tariffForm.get('selectedPaymentTypes') as FormControl<PaymentTypes[]>;
    }

    get selectedTimeOfPayment(): FormControl<TimeOfPayment> {
        return this.tariffForm.get('selectedTimeOfPayment') as FormControl<TimeOfPayment>;
    }

    get selectedBillingRecurrence(): FormControl<BillingRecurrence> {
        return this.tariffForm.get('selectedBillingRecurrence') as FormControl<BillingRecurrence>;
    }

    get selectedCustomRoles(): UntypedFormControl {
        return this.tariffForm.get('selectedCustomRoles') as UntypedFormControl;
    }

    get validityPeriod(): UntypedFormGroup {
        return this.tariffForm.get('validityPeriod') as UntypedFormGroup;
    }

    get validityFrom(): UntypedFormControl {
        return this.validityPeriod.get('from') as UntypedFormControl;
    }

    get validityTo(): UntypedFormControl {
        return this.validityPeriod.get('to') as UntypedFormControl;
    }

}


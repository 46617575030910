import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    AlarmClassification,
    AlarmDeviceDto,
    AlarmDeviceInputDto, AlarmDeviceReceiverDto,
    AlarmDeviceReceiverInputDto,
    AlarmDevicesService,
    IotRegisterDto,
    MeasuringPointType,
    RegisterType
} from '../../../_services/configuration-services';
import {Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {NotificationsService} from '../../../_shared/notifications.service';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {SearchIotRegisterComponent} from '../../../_shared/_components/search-iot-register/search-iot-register.component';
import {FormUtils} from '../../../_shared/form-utils';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {TranslateUtils} from '../../../_shared/translate-utils';

@Component({
    selector: 'app-alarm-device-modal',
    templateUrl: './alarm-device-modal.component.html'
})
export class AlarmDeviceModalComponent implements OnInit, OnDestroy {

    @ViewChild('searchIotRegisterComponent') searchIotRegisterComponent: SearchIotRegisterComponent;

    @Input() alarmDeviceId: string | null;

    MeasuringPointType = MeasuringPointType;
    RegisterType = RegisterType;

    deviceForm: UntypedFormGroup;
    receiverForm: UntypedFormGroup;

    availableClassifications = Object.keys(AlarmClassification).filter(c => c !== AlarmClassification.Unknown);

    isDeviceEditMode = false;
    displayReceiverForm = false;

    alarmDevice: AlarmDeviceDto = null;
    currentEditingReceiver: AlarmDeviceReceiverDto = null;

    textInputPattern = FormUtils.textInputPattern;

    icons = {
        edit: faPen,
        delete: faTrash
    };

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(public activeModal: NgbActiveModal,
                private readonly modalService: NgbModal,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                private readonly alarmDevicesService: AlarmDevicesService) {

        this.deviceForm = new UntypedFormGroup({
                classification: new UntypedFormControl('', Validators.required),
                iotRegister: new UntypedFormControl('', Validators.required),
                location: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
                deviceState: new UntypedFormControl(true),
                comment: new UntypedFormControl('', Validators.maxLength(255)),
                notificationDelay: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(60)]),
                notificationLoop: new UntypedFormControl(true)
            },
            {updateOn: 'change'}
        );

        this.receiverForm = new UntypedFormGroup({
                contactName: new UntypedFormControl('', Validators.required),
                email: new UntypedFormControl('', [Validators.required,
                    FormUtils.emailValidator]),
                receiverState: new UntypedFormControl(true),
                officeHour: new UntypedFormArray([
                    new UntypedFormControl(true), // During office hour
                    new UntypedFormControl(true), // Outside office hour
                ], FormUtils.requiredOneTrueValidator),
                transmissionMode: new UntypedFormArray([
                    new UntypedFormControl(true), // Mail
                    new UntypedFormControl(true) // Push
                ], FormUtils.requiredOneTrueValidator),
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        if (this.alarmDeviceId) {
            this.isDeviceEditMode = true;
            this.fetchDevice(this.alarmDeviceId);
        }
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    fetchDevice(deviceId: string): void {
        this.alarmDevicesService.getAlarmDevice(deviceId).pipe().subscribe(device => {
            this.alarmDevice = device;
            this.searchIotRegisterComponent.selectedRegister = device.iotRegister;

            this.classification.setValue(this.alarmDevice.classification);
            this.iotRegister.setValue(this.alarmDevice.iotRegister);
            this.location.setValue(this.alarmDevice.location);
            this.deviceState.setValue(this.alarmDevice.active);
            this.comment.setValue(this.alarmDevice.comment);
            this.notificationDelay.setValue(this.alarmDevice.delayBetweenNotificationsInMinutes);
            this.notificationLoop.setValue(this.alarmDevice.notificationLoop);
        });
    }

    saveDevice(): void {
        const alarm = new AlarmDeviceInputDto({
            classification: this.classification.value,
            iotRegisterId: this.iotRegister.value.id,
            location: this.location.value,
            active: this.deviceState.value,
            delayBetweenNotificationsInMinutes: this.notificationDelay.value,
            notificationLoop: this.notificationDelay.value > 0 ? this.notificationLoop.value : false,
            comment: this.comment.value
        });

        if (this.alarmDevice) {
            this.alarmDevicesService.updateAlarmDevice(this.alarmDevice.id, alarm).pipe().subscribe(alarmDevice => {
                this.notificationsService.success({title: 'alarms.device.notification.updateDeviceSuccess'});
                this.activeModal.close('success');
            });

        } else {
            this.alarmDevicesService.createAlarmDevice(alarm).pipe().subscribe(alarmDevice => {
                this.notificationsService.success({title: 'alarms.device.notification.addDeviceSuccess'});
                this.alarmDevice = alarmDevice;
                this.isDeviceEditMode = true;
            });
        }
    }

    saveReceiver(): void {
        const nextReceiverPosition = this.currentEditingReceiver != null ? this.currentEditingReceiver.receiverPosition :
            Math.max(...this.alarmDevice.alarmDeviceReceivers.map(o => o.receiverPosition), 0);

        const receiverToSave = new AlarmDeviceReceiverInputDto({
            alarmDeviceId: this.alarmDevice.id,
            contactName: this.contactName.value,
            email: this.email.value,
            active: !!this.receiverState.value,
            officeHourEnabled: !!this.officeHour.value[0],
            outsideOfficeHourEnabled: !!this.officeHour.value[1],
            mailNotificationEnabled: !!this.transmissionMode.value[0],
            pushNotificationEnabled: !!this.transmissionMode.value[1],
            receiverPosition: nextReceiverPosition
        });

        if (this.currentEditingReceiver != null) {
            this.alarmDevicesService.updateAlarmDeviceReceiver(this.currentEditingReceiver.id, receiverToSave).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'alarms.device.notification.updateReceiverSuccess'});
                this.fetchDevice(this.alarmDevice.id);
                this.toggleReceiverForm();
            });

        } else {
            this.alarmDevicesService.createAlarmDeviceReceiver(receiverToSave).pipe().subscribe(receiver => {
                this.notificationsService.success({title: 'alarms.device.notification.addReceiverSuccess'});
                this.alarmDevice.alarmDeviceReceivers.push(receiver);
                this.toggleReceiverForm();
            });
        }
    }

    deleteReceiver(receiver: AlarmDeviceReceiverDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.alarmDevicesService.deleteAlarmDeviceReceiver(receiver.id).pipe().subscribe(_ => {
                    this.notificationsService.success({title: 'alarms.device.notification.deleteDeviceReceiverSuccess'});
                    this.fetchDevice(this.alarmDeviceId);
                });
            }
        });
    }

    toggleReceiverForm(receiver?: AlarmDeviceReceiverDto): void {
        this.receiverForm.reset();
        this.currentEditingReceiver = receiver;

        if (receiver) {
            // Fill the form
            this.contactName.setValue(receiver.contactName);
            this.email.setValue(receiver.email);
            this.receiverState.setValue(receiver.active);
            this.officeHour.get('0').setValue(receiver.officeHourEnabled);
            this.officeHour.get('1').setValue(receiver.outsideOfficeHourEnabled);
            this.transmissionMode.get('0').setValue(receiver.mailNotificationEnabled);
            this.transmissionMode.get('1').setValue(receiver.pushNotificationEnabled);
        }

        this.displayReceiverForm = !this.displayReceiverForm;
    }

    getIotRegisterLabel(iotRegister: IotRegisterDto): string {
        return `${iotRegister['label' + this.crtLang.toUpperCase()]}`;
    }

    getIotRegisterEquipment(iotRegister: IotRegisterDto): string {
        return `${iotRegister.measuringPoint['name' + this.crtLang.toUpperCase()]}`;
    }

    get classification(): UntypedFormControl {
        return this.deviceForm.get('classification') as UntypedFormControl;
    }

    get iotRegister(): UntypedFormControl {
        return this.deviceForm.get('iotRegister') as UntypedFormControl;
    }

    get location(): UntypedFormControl {
        return this.deviceForm.get('location') as UntypedFormControl;
    }

    get deviceState(): UntypedFormControl {
        return this.deviceForm.get('deviceState') as UntypedFormControl;
    }

    get comment(): UntypedFormControl {
        return this.deviceForm.get('comment') as UntypedFormControl;
    }

    get notificationDelay(): UntypedFormControl{
        return this.deviceForm.get('notificationDelay') as UntypedFormControl;
    }

    get notificationLoop(): UntypedFormControl{
        return this.deviceForm.get('notificationLoop') as UntypedFormControl;
    }

    get contactName(): UntypedFormControl{
        return this.receiverForm.get('contactName') as UntypedFormControl;
    }

    get email(): UntypedFormControl{
        return this.receiverForm.get('email') as UntypedFormControl;
    }

    get receiverState(): UntypedFormControl{
        return this.receiverForm.get('receiverState') as UntypedFormControl;
    }

    get officeHour(): UntypedFormArray {
        return this.receiverForm.get('officeHour') as UntypedFormArray;
    }

    get transmissionMode(): UntypedFormArray {
        return this.receiverForm.get('transmissionMode') as UntypedFormArray;
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {
    AvailabilityRangeDto,
    AvailabilityRangeService,
    Day
} from '../../../../_services/configuration-services';
import {NgbActiveModal, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {DateUtils} from '../../../../_shared/date-utils';
import {CustomDateFormatter} from '../../../../_shared/custom-date-formatter';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../../_shared/form-utils';

@Component({
    selector: 'app-availability-range-modal',
    templateUrl: './availability-range-modal.component.html',
    styleUrls: ['./availability-range-modal.component.scss'],
    providers: [ {provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class AvailabilityRangeModalComponent implements OnInit {

    @Input() serviceId!: string;
    @Input() availabilityRange: AvailabilityRangeDto | null = null;

    availabilityRangeForm: UntypedFormGroup;

    daysOfWeek = Object.keys(Day);

    constructor(public activeModal: NgbActiveModal,
                private readonly availabilityRangeService: AvailabilityRangeService) {

        const dayFormArray = [];
        for (const day of this.daysOfWeek) {
            dayFormArray.push(new UntypedFormControl(true));
        }

        this.availabilityRangeForm = new UntypedFormGroup({
                dateRange: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [Validators.required, FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator])
                }, [FormUtils.dateTimePeriodValidator]),
                days: new UntypedFormArray(dayFormArray, [FormUtils.requiredOneTrueValidator]),
                timeRange: new UntypedFormGroup({
                    from: new UntypedFormControl(null, Validators.required),
                    to: new UntypedFormControl(null, Validators.required),
                }, [FormUtils.timePeriodValidator])
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {

        if (this.availabilityRange) {
            this.dateFrom.setValue(DateUtils.dateToNgbDateStruct(this.availabilityRange.startDate));
            this.dateTo.setValue(DateUtils.dateToNgbDateStruct(this.availabilityRange.endDate));

            let selectedDays = [];
            for (const [index, day] of this.daysOfWeek.entries()) {
                selectedDays.push(this.availabilityRange.days.indexOf(day as Day) > -1);
            }
            this.days.setValue(selectedDays);

            this.startTime.setValue(DateUtils.stringToNgbTimeStruct(this.availabilityRange.startTime));
            this.endTime.setValue(DateUtils.stringToNgbTimeStruct(this.availabilityRange.endTime));
        }
    }

    async addOrUpdateAvailabilityRange(): Promise<void> {

        const availabilityRange = new AvailabilityRangeDto({
            id: this.availabilityRange?.id ?? null,
            serviceId: this.serviceId,
            startDate: DateUtils.ngbDateStructToDate(this.dateFrom.value),
            endDate: DateUtils.ngbDateStructToDate(this.dateTo.value),
            days: [],
            startTime: this.startTime.value ? DateUtils.ngbTimeStructToString(this.startTime.value) : '00:00:00',
            endTime: this.endTime.value ? DateUtils.ngbTimeStructToString(this.endTime.value) : '23:59:59'
        });

        for (let i = 0; i < this.days.value.length; i++) {
            if (this.days.value[i]) {
                availabilityRange.days.push(AvailabilityRangeModalComponent.getDayByPosition(i));
            }
        }

        if (availabilityRange.id) {
            this.availabilityRangeService.updateAvailabilityRange(availabilityRange.id, availabilityRange).pipe().subscribe(_ => {
                this.activeModal.close('createOrUpdate');
            });

        } else {
            this.availabilityRangeService.createAvailabilityRange(availabilityRange).pipe().subscribe(_ => {
                this.activeModal.close('createOrUpdate');
            });
        }
    }

    isDaysOfWeekChecked(day: Day): boolean {
        return this.availabilityRange.days.indexOf(day) > -1;
    }

    daysOfWeekChecked(day: Day): void {
        const index = this.availabilityRange.days.indexOf(day);
        if (index > -1) {
            this.availabilityRange.days.splice(index, 1);
        } else {
            this.availabilityRange.days.push(day);
        }
    }

    get dateRange(): UntypedFormGroup {
        return this.availabilityRangeForm.get('dateRange') as UntypedFormGroup;
    }

    get dateFrom(): UntypedFormControl {
        return this.dateRange.get('from') as UntypedFormControl;
    }

    get dateTo(): UntypedFormControl {
        return this.dateRange.get('to') as UntypedFormControl;
    }

    get days(): UntypedFormArray {
        return this.availabilityRangeForm.get('days') as UntypedFormArray;
    }

    get timeRange(): UntypedFormGroup {
        return this.availabilityRangeForm.get('timeRange') as UntypedFormGroup;
    }

    get startTime(): UntypedFormControl {
        return this.timeRange.get('from') as UntypedFormControl;
    }

    get endTime(): UntypedFormControl {
        return this.timeRange.get('to') as UntypedFormControl;
    }

    private static getDayByPosition(i: number): Day {
        switch (i) {
            case 0:
                return Day.Monday;
            case 1:
                return Day.Tuesday;
            case 2:
                return Day.Wednesday;
            case 3:
                return Day.Thursday;
            case 4:
                return Day.Friday;
            case 5:
                return Day.Saturday;
            case 6:
                return Day.Sunday;
            default:
                return null;
        }
    }
}

import {Component, OnInit} from '@angular/core';
import {AppRoles} from '../../app.constants';
import {SurveyLightDto, SurveyService} from "../../_services/configuration-services";
import {Subscription} from "rxjs";
import {TranslateUtils} from "../../_shared/translate-utils";
import {DateUtils} from "../../_shared/date-utils";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {RolesService} from "../../_shared/roles-service";
import {Location} from '@angular/common';


@Component({
    selector: 'app-surveys',
    templateUrl: './surveys.component.html',
    styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit {

    AppRoles = AppRoles;

    surveys: SurveyLightDto[] = [];

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    // Formats
    formats = {
        date: DateUtils.dateFormat,
    };

    // Icons
    icons = {
        pen: faPen
    };

    constructor(private readonly surveyService: SurveyService,
                private readonly translateService: TranslateService,
                private readonly rolesService: RolesService,
                private readonly location: Location) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });
        this.fetchSurveys();
    }

    private fetchSurveys(): void {
        if (this.rolesService.hasRoleAdmin()) {
            this.surveyService.getAll().pipe().subscribe(surveys => this.surveys = surveys,
                () => this.surveys = []);
        } else {
            this.surveyService.getAllActive().pipe().subscribe(surveys => this.surveys = surveys.sort((a, b) => a.order - b.order),
                () => this.surveys = []);
        }
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    back(): void {
        this.location.back();
    }
}

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {
    BookingServiceConfigDto,
    BookingSlotDto,
    BookingSlotService,
    BookingSlotState,
    ItemToBookDto,
    ReservationType
} from '../../../../_services/configuration-services';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {FormUtils} from '../../../../_shared/form-utils';
import {CalendarOptions, EventClickArg, EventInput} from '@fullcalendar/core';
import {TranslateUtils} from '../../../../_shared/translate-utils';
import {firstValueFrom, Subscription} from 'rxjs';
import {CalendarUtils} from '../../../../_shared/calendar-utils';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    BookingSlotsGenerationModalComponent
} from './booking-slots-generation-modal/booking-slots-generation-modal.component';
import {DatePipe} from '@angular/common';
import { BookingSlotsDeletionModalComponent } from './booking-slots-deletion-modal-component/booking-slots-deletion-modal.component';

@Component({
  selector: 'app-slots-calendar',
  templateUrl: './slots-calendar.component.html'
})
export class SlotsCalendarComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() userId: string | null = null;
    @Input() bookingServiceConfig: BookingServiceConfigDto = null!;
    @Input() hasAdminRoleForBooking = false;
    @Input() serviceId: string = null!;

    _items: ItemToBookDto[] = [];
    get items(): ItemToBookDto[] {
        return this._items;
    }
    @Input()
    set items(items: ItemToBookDto[]) {
        this._items = items;
        this.updateResourcesInCalendar();
    }

    @Output() selectSlot: EventEmitter<BookingSlotDto> = new EventEmitter();
    @Output() selectItemId: EventEmitter<string> = new EventEmitter();

    @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent = null!;
    @ViewChild('eventPopover', { static: true }) eventPopoverRef: TemplateRef<any> = null!;
    @ViewChild('itemPopover', { static: true }) itemPopoverRef: TemplateRef<any> = null!;

    ReservationType = ReservationType;
    textInputPattern = FormUtils.textInputPattern;

    calendarOptions: CalendarOptions = null!;

    allBookingSlots: BookingSlotDto[];

    itemsToDisplayInCalendar = new Array<ItemToBookDto>();

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription = null!;

    constructor(
        private readonly calendarUtils: CalendarUtils,
        private readonly modalService: NgbModal,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly translateService: TranslateService,
        private readonly bookingSlotsService: BookingSlotService) {
    }

    async ngOnInit(): Promise<void> {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                }
            });

        this.itemsToDisplayInCalendar = this.items;

        this.initCalendar();
    }

    async ngAfterViewInit(): Promise<void> {
        await this.fetchBookingSlots();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    selectItemToDisplayInCalendar(item: ItemToBookDto): void {
        if (item) {
            this.itemsToDisplayInCalendar = this.items.filter(i => i.id === item.id);
            if (this.itemsToDisplayInCalendar.length === 1) {
                this.selectItemId.emit(this.itemsToDisplayInCalendar[0].id);
            }
        }
        else {
            this.itemsToDisplayInCalendar = this.items;
        }

        this.updateResourcesInCalendar();
    }

    openSlotGenerationModal(): void {
        const modal = this.modalService.open(BookingSlotsGenerationModalComponent, {
            centered: true
        });
        modal.componentInstance.serviceId = this.serviceId;

        modal.result.then(async result => {
            if (result === 'success') {
                await this.fetchBookingSlots();
            }
        }, () => { /* catch the rejection */});
    }

    openSlotDeletionModal(): void {
        const modal = this.modalService.open(BookingSlotsDeletionModalComponent, {
            centered: true
        });
        modal.componentInstance.serviceId = this.serviceId;

        modal.result.then(async result => {
            if (result === 'success') {
                await this.fetchBookingSlots();
            }
        })
    }

    async fetchBookingSlots(): Promise<void> {
        const startDate = CalendarUtils.getStartDate(this.fullcalendar);
        const endDate = CalendarUtils.getEndDate(this.fullcalendar);

        if (!startDate || !endDate) {
            return;
        }

        if (this.hasAdminRoleForBooking) {
            this.allBookingSlots = await firstValueFrom(this.bookingSlotsService.getBookingSlotsForAdminAndHarbourmaster(this.serviceId, startDate, endDate));
        }
        else if (this.userId) {
            this.allBookingSlots = await firstValueFrom(this.bookingSlotsService.getBookingSlotsForUser(this.serviceId, startDate, endDate));

        } else {
            this.allBookingSlots = await firstValueFrom(this.bookingSlotsService.getBookingSlotsForVisitor(this.serviceId, startDate, endDate));
        }

        this.refreshEventsInCalendar(this.allBookingSlots);
    }

    private handleCalendarEventClick(arg: EventClickArg): void {
        const slot = arg.event.extendedProps['slot'] as BookingSlotDto;

        switch (slot.state) {
            case BookingSlotState.Free:
                this.selectSlot.emit(slot);
                break;
            case BookingSlotState.Booked:
                if (this.hasAdminRoleForBooking) {
                    this.selectSlot.emit(slot);
                }
                else if (slot.booking?.userId === this.userId) {
                    this.selectSlot.emit(slot);
                }
                break;

            case BookingSlotState.Blocked:
                if (this.hasAdminRoleForBooking) {
                    this.selectSlot.emit(slot);
                }
                break;
        }
    }

    private async onDatesChange(): Promise<void> {
        await this.fetchBookingSlots();
    }

    private eventForBookingSlotSto(slot: BookingSlotDto): EventInput {
        const startDate = new DatePipe('en-US').transform(slot.date, 'yyyy-MM-dd') + 'T' + slot.startTime;
        const endDate = new DatePipe('en-US').transform(slot.date, 'yyyy-MM-dd') + 'T' + slot.endTime;

        let eventColor = CalendarUtils.getColorBasedOnBookingSlotState(slot.state);
        const textColor = CalendarUtils.getTextColorBasedOnBookingSlotState(slot.state);

        if (this.userId && slot.booking?.userId === this.userId) {
            eventColor = CalendarUtils.orange;
        }

        return {
            allDay: false,
            color: eventColor,
            textColor: textColor,
            editable: false,
            end: endDate,
            id: slot.id.toString(),
            resourceId: this.itemsToDisplayInCalendar.length > 1 ? slot.itemToBookId : null,
            resourceEditable: false,
            start: startDate,
            title: this.getBookingSlotDisplayName(slot),
            slot: slot
        } as EventInput;
    }

    private refreshEventsInCalendar(bookingSlots: BookingSlotDto[]): void {
        let events: EventInput[] = [];

        // Filter bookings for the selected item if necessary
        if (this.itemsToDisplayInCalendar.length === 1) {
            bookingSlots = bookingSlots.filter(b => b.itemToBookId == this.itemsToDisplayInCalendar[0].id);
        }

        bookingSlots.forEach(b => events.push(this.eventForBookingSlotSto(b)));

        this.calendarOptions.events = [...events];
    }

    private initCalendar(): void {
        let initialView = this.calendarUtils.getFullCalendarMode(this.bookingServiceConfig.defaultCalendarDisplayMode, this.items.length > 1);
        let items = null;

        if (this.itemsToDisplayInCalendar.length > 1) {
            items = this.createItemsForCalendar();
        }

        this.calendarOptions = this.calendarUtils.getDefaultCalendarOptions(this.fullcalendar,
            initialView, this.crtLang,
            null,
            this.itemsToDisplayInCalendar.length > 1);

        this.calendarOptions.resources = items;

        this.calendarOptions.resourceLabelDidMount = this.calendarUtils.getRenderTooltipHandlerForResourceLabel(this.itemPopoverRef, this.viewContainerRef);
        this.calendarOptions.eventClick = this.handleCalendarEventClick.bind(this);
        this.calendarOptions.datesSet = this.onDatesChange.bind(this);
    }

    private updateResourcesInCalendar():void {
        if (!this.calendarOptions) {
            return;
        }

        if (this.itemsToDisplayInCalendar.length === 1) {
            this.calendarOptions.resources = [];
            CalendarUtils.changeCalendarViewToCalendarView(this.fullcalendar);

        } else if (this.itemsToDisplayInCalendar.length > 1) {
            this.calendarOptions.resources = this.createItemsForCalendar();
            CalendarUtils.changeCalendarViewToTimelineView(this.fullcalendar);
        }
    }

    private createItemsForCalendar(): Array<EventInput> {
        const resources = new Array<EventInput>();

        this.itemsToDisplayInCalendar.forEach(it => {
            resources.push({
                id: it.id,
                title: it['name' + this.crtLang.toUpperCase()],
                description: it['description' + this.crtLang.toUpperCase()],
                displayPopover: !!it['description' + this.crtLang.toUpperCase()]
            });
        });

        return resources;
    }

    private getBookingSlotDisplayName(slot: BookingSlotDto): string {
        if (slot.state === BookingSlotState.Free || slot.state === BookingSlotState.Blocked) {
            return this.translateService.instant('services.craning.slots.stateEnum.' + slot.state);
        }
        else if (slot.booking) {
            return slot.booking.visitorContact.firstName + ' ' + slot.booking.visitorContact.lastName;
        }

        return this.translateService.instant('services.craning.slots.stateEnum.Booked');
    }
}

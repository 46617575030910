import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../../../../_shared/form-utils';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MooringPlaceDto, MooringPlaceService} from '../../../../../../_services/configuration-services';

@Component({
    selector: 'app-craning-booking-form-boat-edit-modal',
    templateUrl: './craning-booking-form-boat-edit-modal.component.html'
})
export class CraningBookingFormBoatEditModalComponent implements OnInit {

    @Input() mooringPlaceId: string | null;
    boatForm: UntypedFormGroup;
    FormUtils = FormUtils;
    private mooringPlace: MooringPlaceDto;

    constructor(public activeModal: NgbActiveModal, private readonly mooringPlaceService: MooringPlaceService) {
        this.boatForm = new UntypedFormGroup({
            boatWeightInKg: new UntypedFormControl(null, [Validators.required, FormUtils.integerPatternValidator]),
            boatLengthInMeter: new UntypedFormControl(null, [Validators.required, FormUtils.numberDecimalPatternValidator]),
            boatWidthInMeter: new UntypedFormControl(null, [Validators.required, FormUtils.numberDecimalPatternValidator]),
        });
    }

    ngOnInit(): void {
        this.mooringPlaceService.getMooringPlace(this.mooringPlaceId).pipe().subscribe(
            mp => {
                this.mooringPlace = mp;
                this.boatWeightInKg.setValue(mp.boatWeightInKg);
                this.boatWidthInMeter.setValue(mp.boatWidthInMeter);
                this.boatLengthInMeter.setValue(mp.boatLengthInMeter);
            }
        );
    }

    saveBoat(): void {
        this.mooringPlace.boatWeightInKg = +this.boatWeightInKg.value;
        this.mooringPlace.boatWidthInMeter = +this.boatWidthInMeter.value;
        this.mooringPlace.boatLengthInMeter = +this.boatLengthInMeter.value;
        this.mooringPlaceService.updateMooringPlace(this.mooringPlaceId, this.mooringPlace).pipe()
            .subscribe(_ => this.activeModal.close('success'));
    }

    get boatWeightInKg(): UntypedFormControl {
        return this.boatForm.get('boatWeightInKg') as UntypedFormControl;
    }

    get boatLengthInMeter(): UntypedFormControl {
        return this.boatForm.get('boatLengthInMeter') as UntypedFormControl;
    }

    get boatWidthInMeter(): UntypedFormControl {
        return this.boatForm.get('boatWidthInMeter') as UntypedFormControl;
    }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CustomerConfigService} from "../../../_shared/customer-config-service";
import {RoleManagementComponent} from "../../../_shared/_components/role-management/role-management.component";
import {
    ContactConfigurationDto,
    ContactConfigurationService,
    RoleDto,
    RoleService
} from "../../../_services/configuration-services";
import {FormUtils} from "../../../_shared/form-utils";
import {Location} from '@angular/common';
import {NotificationsService} from '../../../_shared/notifications.service';

@Component({
    selector: 'app-contact-management',
    templateUrl: './contact-management.component.html'
})
export class ContactManagementComponent implements OnInit {

    @ViewChild(RoleManagementComponent) roleComponent: RoleManagementComponent;

    contactConfigForm: UntypedFormGroup;

    customRoles: RoleDto[] = [];

    constructor(public readonly customerConfigService: CustomerConfigService,
                private readonly location: Location,
                private readonly notificationService: NotificationsService,
                private readonly contactConfigurationService: ContactConfigurationService,
                private readonly roleService: RoleService) {

        this.contactConfigForm = new UntypedFormGroup({
            email: new UntypedFormControl(null, [Validators.required, FormUtils.emailValidator]),
            rolesForm: new UntypedFormGroup({
                publicAccess: new UntypedFormControl(false, { updateOn: 'change' }),
                baseRolesForm: new UntypedFormArray([]),
                customRolesForm: new UntypedFormArray([])
            }, { updateOn: 'change' }),
            cguFR: new UntypedFormControl(null, [Validators.required, Validators.maxLength(2500)]),
            cguDE: new UntypedFormControl(null, Validators.maxLength(2500)),
            cguIT: new UntypedFormControl(null, Validators.maxLength(2500)),
            cguEN: new UntypedFormControl(null, Validators.maxLength(2500)),
        });
    }

    ngOnInit(): void {
        this.roleService.getAllRoles(false).pipe().subscribe(value => {
            this.customRoles = value.filter(r => !r.isBase).sort((a, b) => a.roleKey.localeCompare(b.roleKey));

            this.fetchConfiguration();
        });
    }

    saveConfiguration(): void {
        const contactConfiguration = new ContactConfigurationDto({
            roles: this.roleComponent.getSelectedRoles(),
            cguFR: this.cguFR.value,
            cguDE: this.cguDE.value,
            cguIT: this.cguIT.value,
            cguEN: this.cguEN.value,
            email: this.email.value
        })

        this.contactConfigurationService.saveContactConfiguration(contactConfiguration).pipe().subscribe(() => {
            this.notificationService.success({ title: 'communicationChannel.contact.notification.configurationSavedSuccess'});
            this.contactConfigForm.markAsPristine();
        });
    }

    back(): void {
        this.location.back();
    }

    private fetchConfiguration(): void {
        this.contactConfigurationService.getContactConfiguration().pipe().subscribe(contactConfiguration => {
            this.email.setValue(contactConfiguration.email);

            this.cguFR.setValue(contactConfiguration.cguFR);
            this.cguDE.setValue(contactConfiguration.cguDE);
            this.cguIT.setValue(contactConfiguration.cguIT);
            this.cguEN.setValue(contactConfiguration.cguEN);

            this.customRoles.forEach(role => this.customRolesForm.push(
                new UntypedFormControl(!!contactConfiguration.roles.find(value => value.roleKey === role.roleKey))
            ));

        }, () => {
            this.customRoles.forEach(() => this.customRolesForm.push(new UntypedFormControl(null)));
        });
    }

    get email(): UntypedFormControl {
        return this.contactConfigForm.get('email') as UntypedFormControl;
    }

    get cguFR(): UntypedFormControl {
        return this.contactConfigForm.get('cguFR') as UntypedFormControl;
    }

    get cguDE(): UntypedFormControl {
        return this.contactConfigForm.get('cguDE') as UntypedFormControl;
    }

    get cguIT(): UntypedFormControl {
        return this.contactConfigForm.get('cguIT') as UntypedFormControl;
    }

    get cguEN(): UntypedFormControl {
        return this.contactConfigForm.get('cguEN') as UntypedFormControl;
    }

    get rolesForm(): UntypedFormGroup {
        return this.contactConfigForm.get('rolesForm') as UntypedFormGroup;
    }

    get publicRolesForm(): UntypedFormControl {
        return this.rolesForm.controls['publicAccess'] as UntypedFormControl;
    }

    get basicRolesForm(): UntypedFormArray {
        return this.rolesForm.get('basicRolesForm') as UntypedFormArray;
    }

    get customRolesForm(): UntypedFormArray {
        return this.rolesForm.get('customRolesForm') as UntypedFormArray;
    }

}

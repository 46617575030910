<div class="content-container bg-white">

    <div *ngIf="crtLang === 'fr'">

        <h1>Plateforme Gestinergie 4.0</h1>
        <h2>Conditions générales d’utilisation</h2>

        <h3>1. Introduction</h3>

        <p>
            Nous vous invitons à lire attentivement les présentes conditions d’utilisation qui régissent la navigation
            sur la plateforme informatique Gestinergie 4.0 - ci-après également la « Plateforme » ou le « site ».
        </p>
        <p>
            En continuant la navigation sur la Plateforme, l’utilisateur accepte sans réserve de se conformer aux
            présentes conditions d’utilisation, qui s’imposent à tout utilisateur.
        </p>
        <p>
            La Plateforme peut faire en tout temps l’objet de mise à jour de nature informative ou technologique. Le cas
            échéant pour ces raisons ou pour tout autre raison pertinente, nous nous réservons le droit de modifier les
            présentes conditions d’utilisation. Les mises à jour font l’objet d’une notification directement sur la
            Plateforme.
        </p>

        <h3>2. Responsabilités</h3>

        <p><strong>Webmaster</strong></p>
        <p>
            Le service Gestinergie 4.0, y compris les solutions informatiques associées exploitées pour la mise à
            disposition de la Plateforme est la propriété exclusive de
            <strong>Groupe E Connect SA, IDE : CHE-110.047.096</strong> – ci-après également le « Webmaster » -
            et est hébergée sous sa responsabilité.
        </p>
        <p>
            Le Webmaster n’assume aucune responsabilité vis-à-vis de l’utilisateur quant à l’exécution des prestations
            souscrites auprès du Prestataire.
        </p>


        <p><strong>Prestataire(s) de service</strong></p>
        <p>
            Les services utilisant cette Plateforme sont fournis par un ou plusieurs prestataires tiers (ex :
            collectivité publique ou acteur privé) – ci-après le « Prestataire »
        </p>
        <p>
            Par conséquent, sont définies et relèvent de la responsabilité exclusive de ce(s) Prestataire(s) :
        </p>
        <ul>
            <li>
                les droits et obligations afférents à la relation contractuelle entre l’utilisateur en qualité de
                bénéficiaire de ces prestations et le Prestataire ;
            </li>
            <li>
                les conditions d’exécution et de paiements de ces prestations (prix, délais, etc. ) ; Les transactions
                financières entre l’utilisateur et le Prestataire sont possibles via un porte-monnaie virtuel accessible
                depuis la Plateforme.
            </li>
            <li>
                les conditions d’accès à cette Plateforme et en particulier aux espaces communs ou exclusifs réservés
                aux bénéficiaires de prestation(s) ;
            </li>
            <li>
                la responsabilité du traitement en cas de traitement de données personnelles collectées dans le cadre de
                la prestation fournie ainsi que les questions ou réclamations y afférentes.
            </li>
        </ul>
        <p>
            Ces conditions et les informations relatives à la prestation peuvent être diffusées sur la Plateforme. Le
            contenu diffusé, le moment de la diffusion ainsi que les implications de ces informations pour l’utilisateur
            relèvent de la responsabilité exclusive du Prestataire.
        </p>
        <p>
            Toute question relative aux présentes conditions d’utilisation ainsi qu’aux services du Prestataire est à
            adresser à : <span [innerHTML]="clientContacts | join | urlTextToLink"></span>
        </p>

        <h3>3. Règles d’accès et d’utilisation, disponibilité de la plateforme</h3>

        <p>
            Tout accès à la Plateforme de même que chaque utilisation par les utilisateurs sont strictement réservés à
            un usage personnel et non commercial uniquement pour les prestations fournies par le Prestataire. Les
            conditions applicables à la prestation doivent en plus être respectées. L’utilisateur doit donc pouvoir
            justifier, à tout moment lors de son utilisation, de sa qualité de bénéficiaire légitime et dûment autorisé
            pour la prestation concernée, faute de quoi il pourra être déconnecté de la Plateforme.
        </p>
        <p>
            Groupe E Connect ne peut garantir l’accessibilité et la disponibilité de la Plateforme sans interruption,
            notamment dans la mesure où des problèmes d’ordre technique ne peuvent être totalement exclus.
        </p>
        <p>
            Chaque utilisateur s’engage à ne pas envoyer de données inutiles et inexactes sur le site ; envoyer de spams
            ni introduire de virus informatiques ou autres programmes malveillants sur le site ou encore à ne pas
            diffuser de tels données et programmes par le biais du site.
        </p>
        <p>
            En cas de non-respect des présentes conditions d’utilisation, Groupe E Connect peut prendre en tout temps et
            sans avertissement des mesures immédiates, telles que la déconnexion et l’exclusion de l’utilisateur ainsi
            que la suppression de toutes les informations, données et contenus que l’utilisateur a publiés sur le site.
            L’utilisateur sera en outre dénoncé au Prestataire et pourra faire l’objet de poursuites judiciaires.
        </p>
        <p>
            Sans préjudice de ce qui précède, en cas de dommage subi du fait d’une utilisation du site non-conforme aux
            présentes conditions, Groupe E se réserve le droit de faire valoir des dommages-intérêts à l’encontre de
            l’utilisateur.
        </p>
        <p>
            Dans les limites autorisées par la loi, Groupe E Connect exclut toute responsabilité pour des dommages subis
            par les utilisateurs ou des tiers à la suite de l’utilisation, de la consultation, de l’exploration ou
            encore d’un défaut de disponibilité ou d’une disponibilité limitée du site internet de Groupe E Connect, des
            éléments de la Plateforme. Cette exclusion de responsabilité comprend également les dommages liés à un
            dysfonctionnement d’Internet ou des infrastructures techniques utilisées par l’utilisateur ou Groupe E
            Connect (perte de données, virus, etc.) ainsi que les abus commis par des tiers (copies, accès aux données,
            modification et suppression des données, etc.).
        </p>

        <h3>4. Sécurité et protection des données</h3>

        <p>
            La protection des données est aussi une question de confiance. Groupe E Connect s’engage à respecter la
            législation en vigueur en Suisse en matière de protection et de sécurité des données. Groupe E Connect
            veille à mettre en œuvre les meilleures pratiques notamment les mesures techniques et organisationnelles
            recommandées par la législation ainsi que par les règles de l’art et le cas échéant à les adapter, afin de
            protéger les données des utilisateurs.
        </p>
        <p>
            Le Webmaster effectue un traitement minimum de données à des fins ne se rapportant pas à des personnes. À
            chaque consultation du site, certaines données sont transmises, pour des raisons techniques, à Groupe E
            Connect ou ses mandataires techniques par le navigateur internet des utilisateurs et sauvegardées dans des
            fichiers journal (fichiers log). Il s’agit des données d’utilisation suivantes : date et heure de la
            consultation, nom du (des) site(s)/fichier(s) consulté(s), adresse IP de l’ordinateur ou de l’appareil
            mobile de l’utilisateur, adresse de la page internet à partir de laquelle l’utilisateur a accédé au site de
            Groupe E, quantité de données transférées, nom et version du navigateur, etc.
        </p>
        <p>
            Le Webmaster ne peut traiter de données personnelles à d’autres fins qu’avec l’accord du Prestataire et dans
            les conditions autorisées pour ce dernier. En cas de traitement de données personnelles, Groupe E Connect
            prend toutes les mesures adéquates, avec le responsable du traitement des données (en règle générale le
            Prestataire), afin de les protéger contre les pertes et les accès non autorisés et empêcher les abus quant à
            leur diffusion, modification ou destruction.
        </p>
        <p>
            Dans le cadre du traitement de données personnelles et malgré les mesures prises, Groupe E Connect ne peut
            toutefois pas garantir qu’aucune donnée ne sera jamais égarée ni ne fera l’objet d’un accès ou d’une
            utilisation frauduleuse, d’un abus ou d’une modification non autorisée.
        </p>
        <p>
            Groupe E Connect s’efforce de sélectionner avec le plus grand soin les informations qu'elle diffuse sur ce
            site et, dans la mesure du possible, d’assurer que les informations techniques et commerciales figurant sur
            le site sont correctes et actuelles. Groupe E Connect ne garantit ni n’assure l’exactitude, l’exhaustivité
            ou l’actualité des informations. Groupe E Connect est en droit de les modifier en tout temps et sans
            préavis. Toute décision prise sur la base de ces informations est donc prise aux seuls risques de
            l’utilisateur.
        </p>
        <p>
            Le site peut contenir des liens vers des pages internet de tiers. Ces pages ne sont ni exploitées, ni
            surveillées par Groupe E Connect qui décline toute responsabilité pour le contenu et le respect des lois, en
            particulier des dispositions sur la protection des données, par les exploitants des pages internet mises en
            lien.
        </p>
        <p>
            Internet est un réseau ouvert, accessible à tous qui ne peut dès lors être considéré comme un environnement
            totalement sûr. Par ailleurs, il est possible, qu’au moment de leur transfert, les données transitent par
            l’étranger et, le cas échéant, par un pays présentant un niveau de protection des données inférieur à celui
            de la Suisse, quand bien même l’expéditeur et le destinataire se trouvent en Suisse. Groupe E Connect
            décline toute responsabilité pour la sécurité des données durant leur transfert sur Internet.
        </p>

        <h3>5. Propriété intellectuelle</h3>

        <p>
            La Plateforme et tous les éléments qui y figurent (tels que marques, logos, titres, graphiques, modèles,
            images, textes, vidéos, etc.) sont protégés en tant que propriété intellectuelle de Groupe E Connect, du
            Prestataire ou d’autres partenaires contractuels. La consultation, le téléchargement ou la copie du site ou
            de ses éléments ne confère aucun droit (droits d’utilisation, droits immatériels, etc.). Toute utilisation,
            reproduction, représentation, copie, transmission (par voie électronique ou tout autre moyen), modification
            ou mise en lien du site (en tout ou partie) ou des éléments qui le composent, sur quelque support que ce
            soit, à des fins autres qu’un usage personnel ou non commercial est interdite.
        </p>
        <p>
            Tout lien hypertexte menant vers la Plateforme, depuis un autre site doit faire l’objet d’une autorisation
            préalable de Groupe E Connect.
        </p>

        <h3>6. Droit applicable et for</h3>

        <p>
            Le droit suisse s’applique à toutes les relations juridiques avec Groupe E Connect en rapport avec
            l’utilisation de la Plateforme.
        </p>
        <p>
            Le for en cas de litige est à Granges-Paccot (Fribourg).
        </p>
        <p>
            Les présentes conditions s’appliquent de plein droit à tout utilisateur de la Plateforme et font foi dès
            leur publication (la version en ligne sur la Plateforme fait foi) ou dans les délais précisés dans la
            publication. Elles annulent et remplacent toutes les versions précédentes.
        </p>

        <h3>7. Contact</h3>

        <p>
            Pour toutes informations supplémentaires en lien avec la Plateforme merci de contacter le Prestataire à l’adresse suivante:
        </p>

        <p> <strong>{{cgu.mandant}}</strong> <br/>
            {{cgu.address.street}} {{cgu.address.number}} <br/>
            {{cgu.address.extra}} <br *ngIf="!!cgu.address.extra"/>
            {{cgu.address.zip}} {{cgu.address.locality}} <br/>
            {{cgu.phone}}<br *ngIf="!!cgu.phone"/>
            <a href="mailto:{{cgu.email}}">{{cgu.email}} </a>
        </p>

        <div *ngFor="let tvaItem of cgu.tva;">{{ tvaItem }}</div>
    </div>

    <div *ngIf="crtLang === 'de'">

        <h1>Plattform Gestinergie 4.0</h1>
        <h2>Allgemeine Nutzungsbedingungen</h2>

        <h3>1. Einführung</h3>

        <p>
            Wir laden Sie ein, diese Nutzungsbedingungen für die Navigation auf der Gestinergie 4.0 IT-Plattform - im
            Folgenden auch «Plattform» oder der «Website» genannt - sorgfältig zu lesen.
        </p>
        <p>
            Durch das weitere Navigieren auf der Plattform erklärt sich der Benutzer vorbehaltlos damit einverstanden,
            diese Nutzungsbedingungen einzuhalten, die für alle Benutzer gelten.
        </p>
        <p>
            Diese Plattform kann jederzeit informativer oder technologischer Art aktualisiert werden. Falls aus diesen
            Gründen oder aus einem anderen relevanten Grund erforderlich, behalten wir uns das Recht vor, diese
            Nutzungsbedingungen zu ändern. Updates werden direkt auf der Plattform angezeigt.
        </p>

        <h3>2. Verantwortlichkeiten</h3>

        <p><strong>Webmaster</strong></p>
        <p>
            Der Dienst Gestinergie 4.0, einschließlich der zugehörigen IT-Lösungen, die für die Bereitstellung der
            Plattform betrieben werden, ist das ausschließliche Eigentum von <strong>Groupe E Connect SA, IDE:
            CHE-110.047.096</strong> – folglich auch der «Webmaster» - und wird unter seiner Verantwortung gehostet.
        </p>
        <p>
            Der Webmaster übernimmt gegenüber dem Benutzer keine Verantwortung für die Leistung der mit dem Anbieter
            abonnierten Dienste.
        </p>

        <p><strong>Dienstleister</strong></p>
        <p>
            Die Dienste, die diese Plattform nutzen, werden von einem oder mehreren Drittanbietern (z. B. Behörden oder
            privaten Akteuren) bereitgestellt - im Folgenden »Dienstleister».
        </p>
        <p>
            Folglich sind definiert und fallen in die ausschließliche Verantwortung dieses Anbieters (dieser Anbieter):
        </p>
        <ul>
            <li>
                die Rechte und Pflichten im Zusammenhang mit dem Vertragsverhältnis zwischen dem Nutzer als Nutznießer
                dieser Dienste und dem Dienstleister;
            </li>
            <li>
                die Bedingungen für die Ausführung und Zahlung dieser Dienstleistungen (Preis, Fristen usw.);
                Finanztransaktionen zwischen dem Benutzer und dem Dienstanbieter sind über eine virtuelle Geldbörse
                möglich, auf die über die Plattform zugegriffen werden kann.
            </li>
            <li>
                die Bedingungen für den Zugang zu dieser Plattform und insbesondere zu gemeinsamen oder exklusiven
                Bereichen, die den Leistungsempfängern vorbehalten sind;
            </li>
            <li>
                die Verantwortung für die Verarbeitung im Falle der Verarbeitung personendaten, die im Rahmen der
                erbrachten Dienstleistung erhoben werden, sowie die diesbezüglichen Fragen oder Beschwerden.
            </li>
        </ul>
        <p>
            Diese Bedingungen und Informationen in Bezug auf den Dienst können auf der Plattform veröffentlicht werden.
            Der verbreitete Inhalt, der Zeitpunkt der Verbreitung sowie die Auswirkungen dieser Informationen über den
            Benutzer liegen in der alleinigen Verantwortung des Dienstleisters.
        </p>
        <p>
            Bei Fragen zu diesen Nutzungsbedingungen, sowie Fragen zu den Diensten des Anbieters richten Sie sich bitte
            an: <span [innerHTML]="clientContacts | join | urlTextToLink"></span>
        </p>

        <h3>3. Zugriffs- und Nutzungsbestimmungen, Verfügbarkeit der Plattform</h3>

        <p>
            Jeder Zugriff auf die Plattform sowie jede Nutzung durch Benutzer ist ausschließlich für den persönlichen
            und nicht kommerziellen Gebrauch reserviert, ausschliesslich für die vom Dienstanbieter bereitgestellten
            Dienste. Die für den Service geltenden Bedingungen müssen ebenfalls beachtet werden. Der Benutzer muss daher
            jederzeit in der Lage sein, seine Fähigkeit als legitimer und ordnungsgemäß autorisierter Begünstigter für
            den betreffenden Dienst zu rechtfertigen, andernfalls kann er von der Plattform getrennt werden.
        </p>
        <p>
            Groupe E Connect kann die ununterbrochene Zugänglichkeit und Verfügbarkeit der Plattform nicht garantieren,
            insbesondere in dem Maße, in dem technische Probleme nicht vollständig ausgeschlossen werden können.
        </p>
        <p>
            Jeder Benutzer verpflichtet sich, keine unnötigen und / oder ungenauen Daten an die Site zu senden; keine
            Spams senden oder Computerviren oder andere schädliche Programme auf der Website einzuführen oder solche
            Daten und Programme über die Website zu verbreiten.
        </p>
        <p>
            Im Falle der Nichteinhaltung dieser Nutzungsbedingungen kann Groupe E Connect jederzeit und ohne Vorwarnung
            sofortige Maßnahmen ergreifen, z. B. das Trennen und Ausschließen des Benutzers sowie das Löschen aller
            Informationen, Daten und Inhalte, die der Benutzer hat auf der Website gepostet hat. Der Benutzer wird auch
            dem Dienstleister angezeigt und kann vor Gericht gestellt werden.
        </p>
        <p>
            Ohne Vorurteil gegenüber dem oben Genannten behält sich Groupe E Connect das Recht vor, im Falle von
            Schäden, die durch die Nutzung der Website entstehen, die nicht diesen Bedingungen entsprechen,
            Schadensersatz vom Benutzer zu verlangen.
        </p>
        <p>
            Innerhalb der gesetzlich zulässigen Grenzen schließt Groupe E Connect jegliche Haftung für Schäden auf
            Elementen der Plattform aus, die Benutzern oder Dritten aufgrund von Nutzung, Beratung, Erkundung oder sogar
            mangelnder Verfügbarkeit oder eingeschränkter Verfügbarkeit der Website der E Connect Group entstehen.
            Dieser Haftungsausschluss umfasst auch Schäden im Zusammenhang mit einer Fehlfunktion des Internets oder der
            vom Benutzer oder der Groupe E Connect genutzten technischen Infrastruktur (Datenverlust, Viren usw.) sowie
            von Dritten begangene Missbräuche (Kopien, Zugangsdaten), Änderung und Löschung von Daten usw.).
        </p>

        <h3>4. Sicherheit und Datenschutz</h3>

        <p>
            Datenschutz ist auch Vertrauenssache. Groupe E Connect verpflichtet sich, die in der Schweiz geltenden
            Sicherheit und datenschutzrechtlichen. Groupe E Connect stellt sicher, dass Best Practices umgesetzt werden,
            insbesondere die vom Gesetz sowie den Regeln der Technik empfohlenen technischen und organisatorischen
            Massnahmen zum Schutz der Benutzerdaten.
        </p>
        <p>
            Der Webmaster führt eine Mindestverarbeitung der Daten für Zwecke durch, die sich nicht auf Einzelpersonen
            beziehen. Jedes Mal, wenn die Site konsultiert wird, werden bestimmte Daten aus technischen Gründen vom
            Internetbrowser der Benutzer an Groupe E Connect oder ihre technischen Vertreter übertragen und in
            Protokolldateien (log-dateien) gespeichert). Dies sind die folgenden Nutzungsdaten: Datum und Uhrzeit der
            Konsultation, Name der konsultierten Site / Datei (n), IP-Adresse des Computers oder Mobilgeräts des
            Benutzers, Adresse der Internetseite, von der aus der Benutzer auf die Site der Gruppe E zugegriffen hat,
            übertragene Datenmenge, Name und Version des Browsers usw.
        </p>
        <p>
            Der Webmaster kann keine personenbezogenen Daten zu anderen Zwecken verarbeiten, außer mit Zustimmung des
            Dienstanbieters und unter den dafür autorisierten Bedingungen. Im Falle der Verarbeitung personendaten
            ergreift Groupe E Connect mit dem für die Datenverarbeitung Verantwortlichen (in der Regel dem
            Dienstleister) alle geeigneten Maßnahmen, um diese vor Verlust und unbefugtem Zugriff zu schützen und
            Missbrauch sowie deren Verbreitung, Änderung oder Zerstörung zu verhindern.
        </p>
        <p>
            Im Rahmen der Verarbeitung personendaten und trotz der getroffenen Maßnahmen kann Groupe E Connect jedoch
            nicht garantieren, dass niemals Daten verloren gehen vorbehaltlich eines betrügerischen Zugriffs, einer
            widerrechtlichen Verwendung, missbraucht oder unbefugt geändert werden.
        </p>
        <p>
            Groupe E Connect ist bestrebt, die auf dieser Website verbreiteten Informationen mit größter Sorgfalt
            auszuwählen und so weit wie möglich sicherzustellen, dass die technischen und kommerziellen Informationen
            auf der Website korrekt und aktuell sind. Groupe E Connect übernimmt keine Garantie oder Gewährleistung für
            die Richtigkeit, Vollständigkeit und Aktualität der Informationen. Groupe E Connect ist berechtigt, diese
            jederzeit und ohne vorherige Ankündigung zu ändern. Jede auf der Grundlage dieser Informationen getroffene
            Entscheidung erfolgt daher auf alleiniges Risiko des Nutzers.
        </p>
        <p>
            Die Website kann Links zu Internetseiten Dritter enthalten. Diese Seiten werden von der Gruppe E Connect
            weder betrieben noch überwacht, die jegliche Verantwortung für den Inhalt und die Einhaltung der Gesetze,
            insbesondere der Datenschutzbestimmungen, durch die Betreiber der verlinkten Internetseiten ablehnt.
        </p>
        <p>
            Das Internet ist ein offenes Netzwerk, das für alle zugänglich ist und daher nicht als absolut sichere
            Umgebung angesehen werden kann. Darüber hinaus ist es möglich, dass zum Zeitpunkt ihrer Übermittlung die
            Daten ins Ausland und gegebenenfalls durch ein Land mit einem niedrigeren Datenschutzniveau als das der
            Schweiz übertragen werden, selbst wenn sich Absender und Empfänger in der Schweiz befinden. Groupe E Connect
            lehnt jede Verantwortung für die Sicherheit von Daten während ihrer Übertragung über das Internet ab.
        </p>

        <h3>5. Geistiges Eigentum</h3>

        <p>
            Die Plattform und alle darauf angezeigten Elemente (wie Marken, Logos, Titel, Grafiken, Modelle, Bilder,
            Texte, Videos usw.) sind als geistiges Eigentum der Groupe E Connect, des Dienstleisters oder anderer
            Vertragspartner geschützt. Das Abrufen, Herunterladen oder Kopieren der Website oder ihrer Elemente gewährt
            keine Rechte (Nutzungsrechte, immaterielle Rechte usw.). Jede Verwendung, Vervielfältigung, Darstellung,
            Kopie, Übermittlung (sei es auf elektronischem oder anderem Wege), Änderung oder Verknüpfung der Website
            (ganz oder teilweise) oder der Elemente, aus denen sie besteht, auf einem beliebigen Medium für andere
            Zwecke als zum persönlichen oder nicht kommerziellen Gebrauch ist verboten.
        </p>
        <p>
            Jeder Hypertext-Link, der von einer anderen Site zur Plattform führt, muss zuvor von Groupe E Connect
            genehmigt werden.
        </p>

        <h3>6. Anwendbares Recht und Gerichtsstand</h3>

        <p>
            Für alle Rechtsbeziehungen mit der Groupe E Connect im Zusammenhang mit der Nutzung der Plattform gilt
            schweizerisches Recht.
        </p>
        <p>
            Gerichtsstand im Streitfall ist Granges-Paccot (Freiburg).
        </p>
        <p>
            Diese vorliegenden Bedingungen gelten automatisch für jeden Benutzer der Plattform und sind bei ihrer
            Veröffentlichung (die Online-Version auf der Plattform ist authentisch) oder innerhalb der in der
            Veröffentlichung angegebenen Fristen verbindlich. Sie stornieren und ersetzen alle früheren Versionen.
        </p>

        <h3>7. Kontakt</h3>

        <p>
            Für alle weiteren Informationen in Bezug auf die Plattform wenden Sie sich bitte an den Anbieter unter der folgenden Adresse:
        </p>

        <p> <strong>{{cgu.mandant}}</strong> <br/>
            {{cgu.address.street}} {{cgu.address.number}} <br/>
            {{cgu.address.extra}} <br *ngIf="!!cgu.address.extra"/>
            {{cgu.address.zip}} {{cgu.address.locality}} <br/>
            {{cgu.phone}}<br *ngIf="!!cgu.phone"/>
            <a href="mailto:{{cgu.email}}">{{cgu.email}} </a>
        </p>

        <div *ngFor="let tvaItem of cgu.tva;">{{ tvaItem }}</div>

        <p class="info-cgu"><strong>Die allgemeinen Nutzungsbedingungen in französischer Sprache sind
            verbindlich.</strong></p>
    </div>

    <div *ngIf="crtLang === 'en'">

        <h1>Gestinergie 4.0 Platform</h1>
        <h2>General Conditions of use</h2>

        <h3>1. Introduction</h3>

        <p>
            We invite you to carefully read these terms of use which govern navigation on the Gestinergie 4.0 computer
            platform - hereinafter also the "Platform" or the "Site".
        </p>
        <p>
            By continuing to browse the Platform, the user unreservedly agrees to comply with these terms of use, which
            are binding on all users.
        </p>
        <p>
            The Platform may be updated at any time for information or technological reasons. If necessary for these or
            any other relevant reasons, we reserve the right to modify these terms of use. Updates will be notified
            directly on the Platform.
        </p>

        <h3>2. Responsibilities</h3>

        <p><strong>Webmaster</strong></p>
        <p>
            The Gestinergie 4.0 service, including the associated IT solutions used to provide the Platform is the
            exclusive property of <strong>Groupe E Connect SA, IDE : CHE-110.047.096</strong> - hereinafter also the
            "Webmaster" - and is hosted under its responsibility.
        </p>
        <p>
            The Webmaster does not assume any responsibility towards the user as for the execution of the services
            subscribed with the Provider.
        </p>

        <p><strong>Service provider(s)</strong></p>
        <p>
            The services using this Platform are provided by one or more third party service providers (e.g. public
            authority or private actor) - hereinafter the "Provider".
        </p>
        <p>
            Therefore, the following are defined and are the exclusive responsibility of the Provider(s):
        </p>
        <ul>
            <li>
                the rights and obligations relating to the contractual relationship between the User as beneficiary of
                these services and the Provider;
            </li>
            <li>
                the conditions of execution and payment of these services (prices, deadlines, etc.); financial
                transactions between the user and the Provider are possible via a virtual wallet accessible from the
                Platform.
            </li>
            <li>
                the conditions of access to this Platform and in particular to the common or exclusive spaces reserved
                for the beneficiaries of the service(s);
            </li>
            <li>
                the responsibility for the processing of personal data collected in the context of the service provided
                as well as any questions or complaints relating thereto.
            </li>
        </ul>
        <p>
            These terms and conditions and information relating to the service may be published on the Platform. The
            content published, the time of publication and the implications of this information for the user are the
            sole responsibility of the Provider.
        </p>
        <p>
            Any questions regarding these Terms of Use and the services of the Provider should be addressed to: <span
            [innerHTML]="clientContacts | join | urlTextToLink"></span>
        </p>

        <h3>3. Rules of access and use, availability of the platform</h3>

        <p>
            Any access to the Platform as well as any use by users is strictly reserved for personal and non-commercial
            use only for the services provided by the Provider. The conditions applicable to the service must also be
            respected. The User must therefore be able to prove, at any time of use, that he/she is a legitimate and
            duly authorized beneficiary of the service in question, failing which he/she may be disconnected from the
            Platform.
        </p>
        <p>
            Groupe E Connect cannot guarantee that the Platform will be accessible and available without interruption,
            particularly as technical problems cannot be completely excluded.
        </p>
        <p>
            Each user undertakes not to send unnecessary and inaccurate data to the site, send spam or introduce
            computer viruses or other malicious programs to the site or disseminate such data and programs through the
            site.
        </p>
        <p>
            In the event of non-compliance with these Terms of Use, Groupe E Connect may take immediate action at any
            time and without notice, such as disconnection and exclusion of the user and deletion of all information,
            data and content that the user has published on the site. The user will also be reported to the Provider and
            may be subject to legal action.
        </p>
        <p>
            Without prejudice to the foregoing, in the event of damage suffered as a result of use of the site that does
            not comply with these conditions, Groupe E Connect reserves the right to claim damages against the user.
        </p>
        <p>
            To the extent permitted by law, Groupe E Connect excludes all liability for damage suffered by users or
            third parties as a result of the use, consultation, exploration or lack of availability of the Groupe E
            Connect website or the elements of the Platform. This exclusion of liability also includes damage related to
            a malfunction of the Internet or the technical infrastructure used by the user or Groupe E Connect (loss of
            data, viruses, etc.) as well as abuse committed by third parties (copying, accessing data, modifying and
            deleting data, etc.).
        </p>

        <h3>4. Security and data protection</h3>

        <p>
            Data protection is also a matter of trust. Groupe E Connect is committed to complying with Swiss data
            protection and security legislation. Groupe E Connect ensures that it implements best practices, in
            particular the technical and organizational measures recommended by legislation and best practices, and
            adapts them where necessary, to protect user data.
        </p>
        <p>
            The Webmaster carries out minimal data processing for purposes that do not relate to individuals. For
            technical reasons, certain data is transmitted to Groupe E Connect or its technical agents by the user's
            Internet browser and saved in log files whenever the website is visited. This includes the following usage
            data: date and time of the visit, name of the website(s)/file(s) visited, IP address of the user's computer
            or mobile device, address of the web page from which the user accessed the Groupe E website, amount of data
            transferred, name and version of the browser, etc.
        </p>
        <p>
            The Webmaster may only process personal data for other purposes with the consent of the Service Provider and
            under the conditions permitted to the Service Provider. When processing personal data, Groupe E Connect
            takes all appropriate measures, together with the person responsible for processing the data (as a rule the
            Service Provider), to protect it against loss and unauthorized access and to prevent abuse in terms of its
            distribution, modification or destruction.
        </p>
        <p>
            In the context of the processing of personal data and despite the measures taken, Groupe E Connect cannot
            however guarantee that no data will ever be lost or will be subject to fraudulent access or use, abuse or
            unauthorized modification.
        </p>
        <p>
            Groupe E Connect makes every effort to select the information it publishes on this site with the utmost care
            and, as far as possible, to ensure that the technical and commercial information on the site is correct and
            up-to-date. Groupe E Connect does not guarantee or warrant the accuracy, completeness or timeliness of the
            information. Groupe E Connect is entitled to change the information at any time without prior notice. Any
            decision taken on the basis of this information is therefore taken at the sole risk of the user.
        </p>
        <p>
            The website may contain links to third-party websites. These pages are neither operated nor monitored by
            Groupe E Connect. Groupe E Connect accepts no responsibility for the content or compliance with laws, in
            particular data protection regulations, by the operators of the linked websites.
        </p>
        <p>
            The Internet is an open network, accessible to all, which cannot therefore be considered as a totally secure
            environment. Furthermore, it is possible that at the time of transfer, data may be transferred via a foreign
            country and, where applicable, via a country with a lower level of data protection than Switzerland, even if
            the sender and the recipient are in Switzerland. Groupe E Connect accepts no responsibility for the security
            of the data during its transfer over the Internet.
        </p>

        <h3>5. Intellectual property</h3>

        <p>
            The Platform and all the elements that appear on it (such as brands, logos, titles, graphics, models,
            images, texts, videos, etc.) are protected as the intellectual property of Groupe E Connect, the Service
            Provider or other partners. contractual. Consultation, downloading or copying of the site or its elements
            does not confer any rights (rights of use, immaterial rights, etc.). Any use, reproduction, representation,
            copy, transmission (by electronic means or any other means), modification or linking of the site (in whole
            or in part) or the elements that compose it, on any medium whatsoever, for other than personal or
            non-commercial use is prohibited.
        </p>
        <p>
            Any hypertext link leading to the Platform from another site must be subject to prior authorization from
            Groupe E Connect.
        </p>

        <h3>6. Applicable law and jurisdiction</h3>

        <p>
            All legal relations with Groupe E Connect in connection with the use of the Platform shall be governed by
            Swiss law.
        </p>
        <p>
            The place of jurisdiction in case of dispute is Granges-Paccot (Fribourg).
        </p>
        <p>
            These terms and conditions apply automatically to all users of the Platform and are valid as soon as they
            are published (the online version on the Platform is valid) or within the time limits specified in the
            publication. They cancel and replace all previous versions.
        </p>

        <h3>7. Contact</h3>

        <p>
            For any additional information related to the Platform please contact the Provider at the following address:
        </p>

        <p> <strong>{{cgu.mandant}}</strong> <br/>
            {{cgu.address.street}} {{cgu.address.number}} <br/>
            {{cgu.address.extra}} <br *ngIf="!!cgu.address.extra"/>
            {{cgu.address.zip}} {{cgu.address.locality}} <br/>
            {{cgu.phone}}<br *ngIf="!!cgu.phone"/>
            <a href="mailto:{{cgu.email}}">{{cgu.email}} </a>
        </p>

        <div *ngFor="let tvaItem of cgu.tva;">{{ tvaItem }}</div>

        <p class="info-cgu"><strong>The general terms and conditions of use in French are binding.</strong></p>
    </div>

    <div *ngIf="crtLang === 'it'">

        <h1>Piattaforma Gestinergie 4.0</h1>
        <h2>Condizioni generali di utilizzo</h2>

        <h3>1. Introduzione</h3>

        <p>
            Vi invitiamo a leggere attentamente le presenti condizioni d’utilizzo che regolano la navigazione della
            piattaforma informatica Gestinergie 4.0 – qui di seguito ugualmente indicata come “Piattaforma” o “sito”.
        </p>
        <p>
            Continuando la navigazione della Piattaforma, l’utilizzatore accetta senza riserve di conformarsi alle
            presenti condizioni di utilizzo che si impongono ad ogni utilizzatore.
        </p>
        <p>
            La Piattaforma ad ogni momento può fare l’oggetto di un aggiornamento di natura informativa o tecnologica.
            Nei casi previsti per questi motivi o per altri pertinenti, ci riserviamo il diritto di modificare le
            presenti condizioni di utilizzo. Gli aggiornamenti faranno l’oggetto di una notifica direttamente sulla
            Piattaforma.
        </p>

        <h3>2. Responsabilità</h3>

        <p><strong>Webmaster</strong></p>
        <p>
            Il servizio Gestinergie 4.0, comprese le soluzioni informatiche associate utilizzate per la messa a
            disposizione della Piattaforma, è di proprietà esclusiva di <strong>Groupe E Connect SA, IDE :
            CHE-110.047.096</strong> - qui di seguito ugualmente indicato “Webmaster”- ed è ospitato sotto la sua
            responsabilità.
        </p>
        <p>
            Il Webmaster non assume alcuna responsabilità nei confronti dell’utilizzatore relativamente all’esecuzione
            delle prestazioni sottoscritte con il Prestatore.
        </p>

        <p><strong>Prestatore(i) di servizio</strong></p>
        <p>
            I servizi utilizzanti questa Piattaforma sono forniti da uno o più prestatari terzi (ex: collettività
            pubblica o attore privato) – qui di seguito il “Prestatore”.
        </p>
        <p>
            Di conseguenza, sono definiti e di responsabilità esclusiva di questo(i) Prestatore(i):
        </p>
        <ul>
            <li>
                i diritti e le obbligazioni riguardanti la relazione contrattuale tra l’utilizzatore, in qualità di
                beneficiario di queste prestazioni, ed il Prestatore;
            </li>
            <li>
                le condizioni di esecuzione e dei pagamenti di queste prestazioni (prezzo, scadenza, etc.). Le
                transazioni finanziarie tra l’utilizzatore ed il Prestatore sono possibili via un porta-monete virtuale
                accessibile dalla Piattaforma.
            </li>
            <li>
                le condizioni di accesso a questa Piattaforma ed in particolare agli spazi comuni od esclusivi riservati
                ai beneficiari della(e) prestazione(i);
            </li>
            <li>
                la responsabilità del trattamento in caso di trattamento dei dati personali raccolti nel quadro della
                prestazione fornita nonchè le domande o reclamazioni relative.
            </li>
        </ul>
        <p>
            Queste condizioni e le informazioni relative alla prestazione possono essere diffuse sulla Piattaforma. Il
            contenuto diffuso, il momento della diffusione e le implicazioni delle informazioni per l’utilzzatore
            rimangono di responsabilità esclusiva del Prestatore.
        </p>
        <p>
            Ogni domanda relativa alle presenti condizioni d'utilizzo ed ai servizi del Prestatore è da inviare a :
            <span [innerHTML]="clientContacts | join | urlTextToLink"></span>
        </p>

        <h3>3. Regole di accesso e di utilizzo, disponibilità della piattaforma</h3>

        <p>
            Qualunque accesso alla Piattaforma così come qualunque impiego degli utilizzatori sono strettamente
            riservati ad uso personale et non commerciale unicamente per le prestazioni fornite dal Prestatore. Inoltre,
            le condizioni applicabili alla prestazione devono essere rispettate. L’utilizzatore deve quindi poter
            giustificare, a qualunque momento dell’impiego, la propria qualità di beneficiario legittimo et
            opportunamente autorizzato alla prestazione interessata; nel caso contrario, potrà essere scollegato dalla
            Piattaforma.
        </p>
        <p>
            Groupe e Connect non può garantire l’accesso e la disponibilità della Piattaforma senza interruzione, ivi
            compreso i problemi di ordine tecnico che non possono essere totalmente esclusi.
        </p>
        <p>
            Ogni utilizzatore si impegna a non inviare dati inutili e inesatti sul sito né ad inviare spam o introdurre
            dei virus informatici o altri programmi dannosi per il sito o ancora non diffondere tali informazioni e
            programmi attraverso il sito.
        </p>
        <p>
            In caso di non-rispetto delle presenti condizioni di utilizzo, Groupe e Connect può in qualunque momento e
            senza preavviso prendere delle misure immediate tali quali la disconnessione e l’esclusione
            dell’utilizzatore e la soppressione di tutte le informazioni, dati e contenuti che l’utilizzatore ha
            pubblicato sul sito. Inoltre, l’utilizzatore sarà denunciato al Prestatore epotrà essere soggetto a
            procedimenti giudiziari.
        </p>
        <p>
            Senza pregiudizio di quanto precede, in caso di danno subito in seguito ad un uso non conforme delle
            presenti condizioni, Groupe E Connect si riserva il diritto di far valere i danni-interessi nei confronti
            dell’utilizzatore.
        </p>
        <p>
            Nei limiti autorizzati dalla legge, Groupe e Connect esclude tutta responsabilità per danni subiti dagli
            utilizzatori o da terzi in seguito all’uso, alla consultazione, alla navigazione o ancora ad un difetto di
            disponibilità o di una disponibilità limitata del sito internet di Groupe E Connect o degli elementi della
            Piattaforma. Questa esclusione di responsabilità comprende ugualmente i danni legati ad un disfunzionamento
            di Internet o delle infrastrutture tecniche utilzzate dall’utilizzatore o Groupe E Connect (perdita di dati,
            virus, etc.), come anche gli abusi commessi da terzi (copie, accesso ai dati, modifica e cancellazione di
            dati, etc.).
        </p>

        <h3>4. Sicurezza e protezione dati</h3>

        <p>
            La protezione dei dati è anche una questione di fiducia. Groupe E Connect s’impegna a rispettare la
            legislazione in vigore in Svizzera in materia di protezione e sicurezza dei dati. Groupe E Connect
            garantisce l’attuazione delle migliori pratiche incluse le misure techniche e organizzative raccomandate
            dalla legislazione e dalla prassi corretta e, in caso contrario, si impegna ad adattarle per proteggere i
            dati degli utilizzatori.
        </p>
        <p>
            Il Webmaster effettua un trattamento di dati minimo a dei fini non legati alle persone. Ad ogni
            consultazione del sito, certi dati sono trasmessi a fini tecnici a Groupe e Connect o ai suoi mandatari
            tecnici per la navigazione internet degli utilizzatori, e sono salvati in documenti di registro (file log).
            Si tratta di dati d’utilzzazione tali quali la data e l’ora della consultazione, il nome del(i) sito(i)
            visitato(i), l’indirizzo IP del computer o dell’apparecchio mobile dell’utilizzatore, l’indirizzo della
            pagina internet a partire dalla quale l’utilizzatore ha visitato il sito se Groupe E, la quantità di dati
            trasferiti, nome e versione del navigatore, etc.
        </p>
        <p>
            Il Webmaster non può trattare i dati personali ad altri fini se non previo accordo del Prestatore e alle
            condizioni autorizzate per quest’ultimo. In caso di trattamento di dati personali, Groupe E Connect prende
            tutte le misure adeguate con il responsabile del trattamento dati (generalmente il Prestatore), per
            proteggerli contro la perdita e l’accesso non autorizzato e al fine di impedire gli abusi di diffusione ,
            modifica o distruzione.
        </p>
        <p>
            Nel contesto del trattamento dati personali et malgrado le misure prese, Groupe E Connect non può tuttavia
            garantire che nessun dato sarà mai perso o farà l’oggetto di un accesso o utilizzo fraudolento, di un abuso
            o di una modifica non autorizzata.
        </p>
        <p>
            Groupe E Connect si sforza di selezionare con la più grande cura le informazioni che diffonde sul sito e,
            nella misura del possbile, d’assicurare che le informazioni tecniche e commerciali presenti sul sito siano
            correctte ed attuali. Groupe E Connect non garantisce né assicura la precisione, l’esaustività o l’attualità
            delle informazioni. Groupe E Connect ha il diritto di modificarle in ogni momento e senza preavviso. Ogni
            decisione presa sulla base di queste informazioni est quindi presa ai soli rischi dell’utilizzatore.
        </p>
        <p>
            Il sito può contenire dei collegamenti verso pagine internet di terzi. Queste pagine non sono né sfruttate
            né sorvegliate da Groupe E Connect che declina tutta responsabilità per il contenuto ed il rispetto delle
            leggi, in particolare delle disposizioni per la protezione dei dati da parte degli operatori dei siti web
            collegati.
        </p>
        <p>
            Internet é una rete aperta, accessibile a tutti, che dunque non può essere considerata come un ambiente
            totalmente sicuro. D’altronde è possibile che, al momento del trasferimento, i dati transitino all’estero ed
            eventualmente in un Paese con un livello di protezione dati inferiore a quello svizzero, anche se entrambi,
            mittente e destinatario, si trovassero in Svizzera. Groupe E Connect declina ogni responsabilità per la
            sicurezza dei dati durante il loro trasferimento su internet.
        </p>

        <h3>5. Proprietà intellettuale</h3>

        <p>
            La Piattaforma e tutti gli elementi che vi figurano (tali marche, loghi, titoli, grafici, modelli, immagini,
            testi, video, etc.) sono protetti in quanto proprietà intellettuale di Groupe E Connect, del Prestatore o di
            altri soci contrattuali. La consultazione, lo scaricamento o la copia del sito o dei suoi elementi non
            conferisce alcun diritto (diritto di utilizzo, diritti immateriali, etc.). È vietato qualunque uso,
            riproduzione, rappresentazione, copia, trasmissione (per via elettronica o qualunque altro mezzo), modifica
            o collegamento al sito (integralmente o parzialmente) o degli elementi che lo compongono, su qualunque
            supporto, per scopi diversi che l’uso personale o non commerciale.
        </p>
        <p>
            Qualunque collegamento alla Piattaforma da un altro sito deve fare l’oggetto di previa autorizzazione di
            Groupe E Connect.
        </p>

        <h3>6. Diritto applicabile e foro</h3>

        <p>
            Il diritto svizzero si applica a tutte le relazioni giuridiche riguardanti Groupe E Connect relative
            all’utilizzo della Piattaforma.
        </p>
        <p>
            Il foro in caso di litigio é a Granges-Paccot (Friburgo).
        </p>
        <p>
            Le presenti condizioni si applicano a pieno diritto a qualunque utilizzatore della Piattaforma e fanno fede
            a partire dalla loro publicazione (la versione in linea sulla Piattaforma fa fede) o nei limiti precisati
            nella pubblicazione. Le condizioni descritte annullano e rimpiazzano tutte le versioni precedenti.
        </p>

        <h3>7. Contatto</h3>

        <p>
            Per ulteriori informazioni legate alla Piattaforma si prega di contattare il Prestatario / Fornitore all'indirizzo seguente:
        </p>

        <p> <strong>{{cgu.mandant}}</strong> <br/>
            {{cgu.address.street}} {{cgu.address.number}} <br/>
            {{cgu.address.extra}} <br *ngIf="cgu.address.extra"/>
            {{cgu.address.zip}} {{cgu.address.locality}} <br/>
            {{cgu.phone}}<br *ngIf="cgu.phone"/>
            <a href="mailto:{{cgu.email}}">{{cgu.email}} </a>
        </p>

        <div *ngFor="let tvaItem of cgu.tva;">{{ tvaItem }}</div>

        <p class="info-cgu"><strong>Le condizioni generali di utilizzo in francese sono vincolanti.</strong></p>
    </div>

</div>

<ng-container *ngIf="map !== null">

    <h4 translate="services.publicLighting.light.title"></h4>

    <app-manage-map-content [serviceId]="serviceId"
                            [map]="map"
                            [items]="lightsMap"
                            (selectedItemEvent)="openLightModal($event)"></app-manage-map-content>
    <hr class="mt-4 mb-4">
</ng-container>

<app-light-zone-management [serviceId]="serviceId"></app-light-zone-management>

<div class="content-container bg-white-no-shadow">

    <h4 translate="services.chargingStations.chargingProcesses.title"></h4>

    <form [formGroup]="filterFormGroup">

        <!-- Filters -->
        <div class="row">

            <!-- From / To-->
            <div class="default-filter-form-field">
                <app-date-period-picker [periodControl]="period"></app-date-period-picker>
            </div>

            <!-- User Filter -->
            <div class="default-filter-form-field" *ngIf="rolesService.hasRoleAdmin()">
                <label class="form-label" translate="common.user"></label>
                <app-search-user #userSearch
                                 formControlName="userId"
                                 ngDefaultControl
                                 (selectedUserEvent)="selectUser($event)"></app-search-user>
            </div>

            <!-- Charging station Filter -->
            <div class="default-filter-form-field">
                <label class="form-label"
                       translate="services.chargingStations.chargingProcesses.chargingStation"></label>

                <select class="form-control form-select" formControlName="chargingStationId">

                    <option [ngValue]="null">-</option>

                    <option *ngFor="let chargingStation of chargingStations;"
                            [value]="chargingStation.id">
                        {{ chargingStation['name' + crtLang.toUpperCase()] }}
                    </option>

                </select>
            </div>

            <!-- Status Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="activations.state"></label>
                <select class="form-control form-select" id="status" formControlName="status">

                    <option [ngValue]="null">-</option>

                    <option *ngFor="let status of availableStatus"
                            [value]="status"
                            translate="services.chargingStations.chargingProcesses.status.{{ status }}"></option>
                </select>
            </div>

        </div>

        <div class="btn-bar">
            <button class="btn btn-outline-secondary"
                    (click)="clearFilter()"
                    translate="common.clearFilter"></button>

            <button class="btn btn-primary"
                    [disabled]="filterFormGroup.invalid"
                    (click)="applyFilter()"
                    translate="common.filter"></button>
        </div>

    </form>

    <ngx-datatable #chargingProcessesTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="chargingProcesses.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   rowHeight="50"
                   [externalPaging]="true"
                   [count]="chargingProcesses.rowCount"
                   [sorts]="[{ prop: 'chargeStartDateTime', dir: 'desc' }]"
                   [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                    }"
                   [offset]="crtPage"
                   [limit]="chargingProcesses.pageSize"
                   (page)="setPage($event)"
                   [scrollbarH]="true">

        <ngx-datatable-column prop="chargeStartDateTime" name="{{ 'common.startDate' | translate }}"
                              [width]="150"
                              [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.chargeStartDateTime | date:dateTimeFormat }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="chargeEndDateTime" name="{{ 'common.endDate' | translate }}"
                              [width]="150"
                              [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.chargeEndDateTime | date:dateTimeFormat }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [prop]="'chargingStationName' + crtLang.toUpperCase()"
                              name="{{ 'services.chargingStations.chargingProcesses.chargingStation' | translate }}"
                              [width]="200"
                              [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row">

                <ng-container>
                    {{ row['chargingStationName' + crtLang.toUpperCase()] }}
                </ng-container>

            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="user" name="{{ 'common.user' | translate }}" *ngIf="rolesService.hasRoleAdmin()"
                              [width]="250"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.user }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="chargeDuration" name="{{ 'common.duration' | translate }}"
                              [width]="100"
                              [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.chargeDuration | timeFormat }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="chargeConsumptionKwh" name="{{ 'common.consumption' | translate }}"
                              [width]="125"
                              [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span> {{ row.chargeConsumptionKwh ?
                    (row.chargeConsumptionKwh.toFixed(2) + ' ' + ('common.units.EnergyConsumptionUnitType.Kwh' | translate)) : '-' }}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{ 'services.chargingStations.chargingProcesses.statusTitle' | translate }}"
                              [width]="150"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ ('services.chargingStations.chargingProcesses.status.' + row.chargingProcessStatus) | translate }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="75"
                              [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">

                <ng-container *ngIf="!row.chargeEndDateTime">
                    <div class="d-flex flex-row justify-content-end">
                        <button class="btn btn-link"
                                (click)="terminateChargingProcess(row.id)"
                                title="{{ 'common.terminate' | translate }}">
                            <fa-icon [icon]="icons.terminate"></fa-icon>
                        </button>
                    </div>

                </ng-container>

            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                    ngx-datatable-footer-template
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-curPage="curPage">
                <div class="page-count">
                    {{ rowCount }} {{ 'common.datatable.total' | translate }}
                </div>
                <datatable-pager
                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'"
                        [page]="curPage"
                        [size]="pageSize"
                        [count]="rowCount"
                        [hidden]="!((rowCount / pageSize) > 1)"
                        (change)="table.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

</div>

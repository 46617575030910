<ng-container *ngIf="!control && invalidKey">
    <p class="error-message"><small translate>{{ invalidKey }}</small></p>
</ng-container>

<ng-container *ngIf="control && control.invalid && (control.dirty || control.touched)">
    <p class="error-message" *ngIf="invalidKey"><small translate>{{ invalidKey }}</small></p>

    <ng-container *ngIf="!invalidKey">
        <p class="error-message" *ngFor="let error of control.errors | keyvalue"><small>{{
            'common.form.errors.' + (error.key) | translate: {
                min: error.value.min,
                max: error.value.max } }}</small></p>
    </ng-container>
</ng-container>



<div [formGroup]="dateTimeForm">
    <div class="row">

        <!-- StartDate -->
        <div class="col-12 col-sm-6">
            <label class="form-label">{{ 'services.availabilityRange.modal.startDate' | translate }}*</label>

            <div class="input-group">
                <input class="form-control"
                       placeholder="{{ 'common.placeholders.dateFormat' | translate}}"
                       name="startDate" [formControl]="startDate" [minDate]="minStartDate" [maxDate]="maxStartDate"
                       ngbDatepicker
                       #startDatePicker="ngbDatepicker">

                <span class="input-group-text">
                    <button class="btn btn-link btn-sm"
                            (click)="startDatePicker.toggle()">
                        <fa-icon [icon]="icons.calendar"></fa-icon>
                    </button>
                    <button class="btn btn-link btn-sm" *ngIf="!!startDate.value"
                            (click)="startDatePicker.writeValue(null); startDate.setValue(null);">
                        <fa-icon [icon]="icons.times"></fa-icon>
                    </button>
                </span>
            </div>

            <app-form-error [control]="startDate"></app-form-error>
        </div>

        <!-- EndDate -->
        <div class="col-12 col-sm-6">
            <label class="form-label">{{ 'services.availabilityRange.modal.endDate' | translate }}*</label>

            <div class="input-group">
                <input class="form-control" [formControl]="endDate"
                       placeholder="{{ 'common.placeholders.dateFormat' | translate}}"
                       name="dp" ngbDatepicker
                       #endDatePicker="ngbDatepicker" [disabled]="disableEndDateTime" required>

                <div class="input-group-text">
                    <button class="btn btn-link btn-sm"
                            (click)="endDatePicker.toggle()"
                            [disabled]="disableEndDateTime">
                        <fa-icon [icon]="icons.calendar"></fa-icon>
                    </button>
                    <button class="btn btn-link btn-sm" *ngIf="!!endDate.value"
                            [disabled]="disableEndDateTime"
                            (click)="endDatePicker.writeValue(null); endDate.setValue(null);">
                        <fa-icon [icon]="icons.times"></fa-icon>
                    </button>
                </div>
            </div>

            <app-form-error [control]="endDate"></app-form-error>
        </div>

        <!-- StartTime -->
        <div class="col-12 col-sm-6">
            <label class="form-label">{{'services.availabilityRange.modal.startTime' | translate }}*</label>
            <ngb-timepicker [formControl]="startTime"></ngb-timepicker>
            <app-form-error [control]="startTime"></app-form-error>
        </div>

        <!-- EndTime -->
        <div class="col-12 col-sm-6">
            <label class="form-label">{{'services.availabilityRange.modal.endTime' | translate }}*</label>
            <ngb-timepicker [formControl]="endTime"></ngb-timepicker>
            <app-form-error [control]="endTime"></app-form-error>
        </div>

        <div class="col-12">
            <app-form-error [control]="dateTime"></app-form-error>
        </div>

    </div>

</div>

<fieldset class="col-12">

    <legend translate="services.craning.booking.form.billed.services"></legend>

    <div class="table-responsive" *ngIf="craningBillingDto">
        <table class="table table-striped"
               [attr.aria-label]="'services.craning.booking.form.billed.services' | translate">

            <thead>
            <tr>
                <th scope="col" style="min-width: 70%" translate="services.craning.booking.form.service"></th>
                <th scope="col" style="min-width: 30%" class="text-end text-nowrap" translate="services.craning.booking.form.price"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let c of craningBillingDto.craningItems; let i=index">
                <td>
                    <span class="service">{{ getCraningServiceLabel(c) }}</span>
                </td>
                <td class="text-nowrap text-end">
                    {{ c.craningItemAmount | currencyFormat }}
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td>
                    <span>{{ 'services.craning.booking.form.billed.total' | translate }}</span>
                </td>
                <td class="text-nowrap text-end">
                    <span>{{ craningBillingDto.serviceTotalAmount | currencyFormat }}</span>
                </td>
            </tr>
            </tfoot>
        </table>
    </div>
</fieldset>

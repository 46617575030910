import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../_shared/notifications.service';
import {CustomerConfigService} from '../../../_shared/customer-config-service';
import {DateUtils} from '../../../_shared/date-utils';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {Router} from '@angular/router';
import {
    EquipmentEventDto,
    EquipmentEventInputDto,
    EquipmentEventRecurrence,
    EquipmentEventService,
    EquipmentLightDto
} from '../../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';
import {CustomDateFormatter} from '../../../_shared/custom-date-formatter';
import {SearchEquipmentComponent} from '../../../_shared/_components/search-equipment/search-equipment.component';
import {DateTimePeriodPickerComponent} from '../../../_shared/_components/date-time-period-picker/date-time-period-picker.component';
import {FormUtils} from '../../../_shared/form-utils';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';

@Component({
    selector: 'app-equipment-event-modal',
    templateUrl: './equipment-event-modal.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class EquipmentEventModalComponent implements OnInit {

    @ViewChild('searchEquipmentComponent') searchEquipmentComponent: SearchEquipmentComponent;
    @ViewChild('dateTimePeriodPickerComponent') dateTimePeriodPickerComponent: DateTimePeriodPickerComponent;

    @Input() equipmentEventId: string | null = null;
    @Input() equipmentLight: EquipmentLightDto | null = null;
    @Input() initialDate: Date | null = null;

    equipmentEventRecurrences: { id: string, value: string }[];
    equipmentEventForm: UntypedFormGroup;

    initialDateStruct: NgbDateStruct = null;
    initialTimeStruct: NgbTimeStruct = null;

    constructor(
        private readonly confirmService: ConfirmModalService,
        private readonly router: Router,
        private readonly notificationsService: NotificationsService,
        public activeModal: NgbActiveModal,
        private readonly translateService: TranslateService,
        private readonly equipmentEventService: EquipmentEventService,
        private readonly notificationService: NotificationsService,
        public readonly customerConfigService: CustomerConfigService
    ) {
        this.equipmentEventForm = new UntypedFormGroup({
            description: new UntypedFormControl(null, Validators.required),
            equipmentId: new UntypedFormControl(null, Validators.required),
            internalContactName: new UntypedFormControl(null, Validators.required),
            internalContactEmail: new UntypedFormControl(null, [Validators.required, FormUtils.emailValidator]),
            dateTimeForm: new UntypedFormGroup({
                startDate: new UntypedFormControl(null, Validators.required),
                endDate: new UntypedFormControl(null, Validators.required),
                startTime: new UntypedFormControl(null, Validators.required),
                endTime: new UntypedFormControl(null, Validators.required)
            }, FormUtils.dateTimePeriodValidator),
            reminder: new UntypedFormControl(null, [Validators.min(0), FormUtils.integerPatternValidator]),
            occurrence: new UntypedFormControl(0, [Validators.min(0), Validators.max(50), FormUtils.integerPatternValidator]),
            recurrence: new UntypedFormControl(EquipmentEventRecurrence.Weekly),
            comment: new UntypedFormControl(null),
        });
    }

    ngOnInit(): void {
        this.loadRecurrenceDropDown();

        if (!!this.equipmentLight) {
            this.equipmentId.setValue(this.equipmentLight.id);
        }

        if (!!this.equipmentEventId) {
            this.equipmentEventService.getEquipmentEventById(this.equipmentEventId).pipe().subscribe(equipmentEvent => {
                this.searchEquipmentComponent.setEquipment(equipmentEvent.equipmentId);
                this.fillEquipmentEventForm(equipmentEvent);
            });

        } else {
            if (!!this.initialDate) {
                this.initialDateStruct = DateUtils.dateToNgbDateStruct(this.initialDate);
                this.initialTimeStruct = DateUtils.dateToNgbTimeStruct(this.initialDate);
            }
        }
    }

    addOrUpdateEquipmentEvent(): void {
        if (!this.equipmentEventForm.valid) {
            return;
        }

        let startDateTime = DateUtils.ngbDateStructToDate(this.startDate.value);
        let endDateTime = DateUtils.ngbDateStructToDate(this.endDate.value);
        let startTime = this.startTime.value;
        let endTime = this.endTime.value;
        startDateTime.setHours(startTime.hour, startTime.minute);
        endDateTime.setHours(endTime.hour, endTime.minute);

        const equipmentEventToSave = new EquipmentEventInputDto({
            equipmentId: this.equipmentId.value,
            description: this.description.value,
            comment: this.comment.value,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            reminderInDays: this.reminder.value,
            internalContactName: this.internalContactName.value,
            internalContactEmail: this.internalContactEmail.value
        });

        if (!this.equipmentEventId) {
            equipmentEventToSave.eventRecurrence = this.recurrence.value;
            equipmentEventToSave.numberOfRecurrence = this.occurrence.value;

            this.equipmentEventService.createEquipmentEvent(equipmentEventToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'equipments.events.notifications.addSuccess'});
                this.activeModal.close('success');
            });

        } else {
            this.equipmentEventService.updateEquipmentEvent(this.equipmentEventId, equipmentEventToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'equipments.events.notifications.updateSuccess'});
                this.activeModal.close('success');
            });
        }
    }

    cancelEquipmentEvent(): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.cancel'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.equipmentEventService.deleteEquipmentEvent(this.equipmentEventId).pipe().subscribe(_ => {
                        this.notificationService.success({title: 'equipments.events.notifications.deleteSuccess'});
                        this.activeModal.close('success')
                    },
                    _ => this.notificationsService.error({title: 'equipments.events.notifications.deleteError'}));
            }
        });
    }

    onEquipmentChange(equipment: EquipmentLightDto | null): void {
        const keepFormPristine = this.equipmentId.value === null;
        this.equipmentId.setValue(equipment?.id ?? null);

        if (!keepFormPristine) {
            this.equipmentId.markAsDirty();
        }
    }

    private loadRecurrenceDropDown(): void {
        this.equipmentEventRecurrences = [];
        const recurrence = Object.keys(EquipmentEventRecurrence);

        recurrence.forEach(value => {
            this.equipmentEventRecurrences.push({
                id: value,
                value: this.translateService.instant('equipments.events.enums.recurrence.' + value.toLowerCase())
            });
        });
    }

    private fillEquipmentEventForm(equipmentEvent: EquipmentEventDto): void {
        this.equipmentId.setValue(this.equipmentLight);

        this.description.setValue(equipmentEvent.description);
        this.comment.setValue(equipmentEvent.comment);
        this.internalContactName.setValue(equipmentEvent.internalContactName);
        this.internalContactEmail.setValue(equipmentEvent.internalContactEmail);

        this.startDate.setValue(DateUtils.dateToNgbDateStruct(equipmentEvent.startDateTime));
        this.endDate.setValue(DateUtils.dateToNgbDateStruct(equipmentEvent.endDateTime));
        this.startTime.setValue(DateUtils.dateToNgbTimeStruct(equipmentEvent.startDateTime));
        this.endTime.setValue(DateUtils.dateToNgbTimeStruct(equipmentEvent.endDateTime));

        this.reminder.setValue(equipmentEvent.reminderInDays);

        this.equipmentEventForm.markAsPristine();
    }

    get description(): UntypedFormControl {
        return this.equipmentEventForm.get('description') as UntypedFormControl;
    }

    get equipmentId(): UntypedFormControl {
        return this.equipmentEventForm.get('equipmentId') as UntypedFormControl;
    }

    get internalContactName(): UntypedFormControl {
        return this.equipmentEventForm.get('internalContactName') as UntypedFormControl;
    }

    get internalContactEmail(): UntypedFormControl {
        return this.equipmentEventForm.get('internalContactEmail') as UntypedFormControl;
    }

    get dateTimeForm(): UntypedFormGroup {
        return this.equipmentEventForm.get('dateTimeForm') as UntypedFormGroup;
    }

    get startDate(): UntypedFormControl {
        return this.dateTimeForm.get('startDate') as UntypedFormControl;
    }

    get endDate(): UntypedFormControl {
        return this.dateTimeForm.get('endDate') as UntypedFormControl;
    }

    get startTime(): UntypedFormControl {
        return this.dateTimeForm.get('startTime') as UntypedFormControl;
    }

    get endTime(): UntypedFormControl {
        return this.dateTimeForm.get('endTime') as UntypedFormControl;
    }

    get reminder(): UntypedFormControl {
        return this.equipmentEventForm.get('reminder') as UntypedFormControl;
    }

    get occurrence(): UntypedFormControl {
        return this.equipmentEventForm.get('occurrence') as UntypedFormControl;
    }

    get recurrence(): UntypedFormControl {
        return this.equipmentEventForm.get('recurrence') as UntypedFormControl;
    }

    get comment(): UntypedFormControl {
        return this.equipmentEventForm.get('comment') as UntypedFormControl;
    }

}

<div class="col-12" [formGroup]="form">
    <label class="form-label">{{ 'common.dayOfWeek' | translate }}*</label>

    <ng-container formArrayName="days">
        <div class="form-check form-switch" *ngFor="let day of daysOfWeek; let i = index">
            <input class="form-check-input" type="checkbox" id="{{ day }}"
                   [formControl]="days.get('' + i)"
                   [value]="days.value[i]">
            <label class="form-check-label" for="{{ day }}">{{ 'common.day.' + day | translate }}</label>
        </div>
    </ng-container>
</div>

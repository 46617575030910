import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {Subscription} from 'rxjs';
import {PointOfSaleDto, PointOfSaleService} from '../../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';
import {AppRoles} from '../../../app.constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    PointOfSaleManagementModalComponent
} from './point-of-sale-management-modal/point-of-sale-management-modal.component';
import {NotificationsService} from "../../../_shared/notifications.service";
import {ConfirmModalService} from "../../../_shared/_components/confirm-modal/confirm-modal.component";

@Component({
    selector: 'app-point-of-sale-management',
    templateUrl: './point-of-sale-management.component.html'
})
export class PointOfSaleManagementComponent implements OnInit, OnDestroy {
    @Input() serviceId: string;

    pointsOfSale: PointOfSaleDto[] = [];

    AppRoles = AppRoles;

    // Icons
    icons = {
        edit: faPen,
        delete: faTrash,
    };

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription$: Subscription;

    constructor(private readonly pointOfSaleService: PointOfSaleService,
                private readonly translateService: TranslateService,
                private readonly notificationService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                private readonly modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.fetchPointsOfSale();

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription$ = this.translateService.onLangChange
            .subscribe(_ => {
                this.crtLang = this.translateService.currentLang;
            });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$.unsubscribe();
    }

    private fetchPointsOfSale(): void {
        this.pointOfSaleService.getPointsOfSaleByServiceId(this.serviceId).pipe().subscribe(pos => {
            this.pointsOfSale = pos;
        });
    }

    createPointOfSale() {
        const modal = this.modalService.open(PointOfSaleManagementModalComponent, { centered: true, size: 'lg' });
        modal.componentInstance.serviceId = this.serviceId;
        modal.result
            .then(value => {
                    if (value === 'success') {
                        this.fetchPointsOfSale();
                    }
                },
                () => { /* catch the rejection */}
            );
    }

    updatePointOfSale(pointOfSale: PointOfSaleDto) {
        const modal = this.modalService.open(PointOfSaleManagementModalComponent, { centered: true, size: 'lg' });
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.pointOfSale = pointOfSale;
        modal.result
            .then(value => {
                    if (value === 'success') {
                        this.fetchPointsOfSale();
                    }
                },
                () => { /* catch the rejection */}
            );
    }

    deletePointOfSale(pointOfSale: PointOfSaleDto) {

        this.confirmService.confirm({ titleKey: 'common.confirmModal.title.delete'} ).then(result => {
            if (result === ConfirmModalService.yes) {
                this.pointOfSaleService.deletePointOfSale(pointOfSale.posId).pipe().subscribe(_ => {
                    this.notificationService.success({title: 'services.pointOfSale.notifications.deleteSuccess'});
                    this.fetchPointsOfSale()
                });
            }
        });

    }
}

import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SchedulePeriodModalComponent} from './schedule-period-modal/schedule-period-modal.component';
import {
    LightZoneScheduleDto,
    PublicLightingControlService
} from '../../../../_services/configuration-services';
import {firstValueFrom} from 'rxjs';
import {DateUtils} from '../../../../_shared/date-utils';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ConfirmModalService} from '../../../../_shared/_components/confirm-modal/confirm-modal.component';
import {NotificationsService} from '../../../../_shared/notifications.service';

@Component({
  selector: 'app-light-zones-schedule-control',
  templateUrl: './light-zones-schedule-control.component.html'
})
export class LightZonesScheduleControlComponent implements OnInit {

    @Input() serviceId: string;

    lightZonesSchedule: LightZoneScheduleDto[] = [];

    dateFormat = DateUtils.dateFormat;

    icons = {
        edit: faPen,
        delete: faTrash
    }

    constructor(private readonly modalService: NgbModal,
                private readonly publicLightingControlService: PublicLightingControlService,
                private readonly notificationsService: NotificationsService,
                private readonly confirmService: ConfirmModalService) {
    }

    async ngOnInit(): Promise<void> {
        await this.fetchLightZonesSchedule();
    }

    openSchedulePeriodModal(lightZoneSchedule: LightZoneScheduleDto | null = null): void {
        const modal = this.modalService.open(SchedulePeriodModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.lightZoneSchedule = lightZoneSchedule;

        modal.result.then(async result => {
            if (result === 'success') {
                await this.fetchLightZonesSchedule();
            }});
    }

    openDeleteSchedulePeriodModal(lightZoneSchedule: LightZoneScheduleDto): void {
        this.confirmService.confirm({
            titleKey: 'common.confirmModal.title.delete',
        }).then(async result => {
            if (result === ConfirmModalService.yes) {
                await firstValueFrom(this.publicLightingControlService.deleteLightZoneControlModeSchedule(lightZoneSchedule.id));

                this.notificationsService.success({title: 'publicLighting.lightZone.schedule.notifications.deleteSuccess'});
                await this.fetchLightZonesSchedule();
            }
        });
    }

    formatTimeStringToDisplay(time: string): string {
        return DateUtils.formatTime(time, 'h');
    }

    onSort(sortOrder: any): void {
        const direction = sortOrder.sorts[0].dir;
        const property = sortOrder.sorts[0].prop;

        const isDateProperty = this.lightZonesSchedule[0][property] instanceof Date;

        const compareFunction = isDateProperty
            ? (a: LightZoneScheduleDto, b: LightZoneScheduleDto) =>
                (direction === 'asc' ? a[property] - b[property] : b[property] - a[property])
            : (a: LightZoneScheduleDto, b: LightZoneScheduleDto) =>
                (direction === 'asc' ? a[property].localeCompare(b[property]) : b[property].localeCompare(a[property]));

        this.lightZonesSchedule.sort(compareFunction);
    }

    private async fetchLightZonesSchedule(): Promise<void> {
        this.lightZonesSchedule = await firstValueFrom(
            this.publicLightingControlService.getLightZonesScheduleForServiceId(this.serviceId));
    }

}

<div class="modal-header">
    <h4 class="modal-title" translate>common.confirmModal.title.delete</h4>
    <button class="btn-close" aria-label="Close" (click)="close()">

    </button>
</div>

<div class="modal-body">
    <ng-container *ngIf="!loading">
        <div *ngIf="reasons.length > 0">
            <p translate>users.deleteModal.notDeletableText</p>
            <ul>
                <li *ngFor="let reason of reasons; ">
                    {{'error.network.businessException.' + reason | translate}}
                </li>
            </ul>
        </div>
        <p *ngIf="reasons.length === 0" class="translated-paragraph" translate>users.deleteModal.confirmMessage</p>
    </ng-container>
</div>

<div class="modal-footer">
    <ng-container *ngIf="!loading">
        <button *ngIf="reasons.length === 0" type="button" class="btn btn-outline-secondary" (click)="no()" translate>
            common.no
        </button>
        <button *ngIf="reasons.length === 0" type="button" class="btn btn-primary" (click)="yes()" translate>
            common.yes
        </button>
        <button *ngIf="reasons.length > 0" type="button" class="btn btn-primary" (click)="close()" translate>
            common.ok
        </button>
    </ng-container>
</div>

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'arrayMap'
})
export class ArrayMapPipe implements PipeTransform {
    transform (input: any[], key: string): any {
        return input.map(value => value[key]);
    }
}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DaterangepickerDirective} from 'ngx-daterangepicker-material';
import {FormUtils} from '../../form-utils';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import {Dayjs} from 'dayjs';

export interface DateRange {
    start: Date;
    end: Date;
}

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html'
})
export class DateRangePickerComponent implements OnInit {

    @ViewChild(DaterangepickerDirective, { static: true }) dateRangePicker: DaterangepickerDirective;

    @Input() maxDate: Dayjs | null = null;

    @Input() minDate: Dayjs | null = null;

    picker: DaterangepickerDirective;
    selected: { startDate: Dayjs; endDate: Dayjs };
    alwaysShowCalendars: boolean;
    ranges: any = {};
    textInputPattern = FormUtils.textInputPattern;

    icons = {
        next: faChevronRight,
        previous: faChevronLeft
    };

    @Output() dateRangeChange = new EventEmitter<DateRange>();

    constructor() {
        dayjs.extend(utc);
        dayjs.extend(isoWeek);
        this.alwaysShowCalendars = true;
        this.selected = {
            startDate: dayjs().add(-1, 'M').startOf('month').startOf('day'),
            endDate: dayjs().add(-1, 'M').endOf('month').endOf('day')
        };
    }

    ngOnInit(): void {
        this.dateRangeChange.emit({
            start: this.selected.startDate.toDate(),
            end: this.selected.endDate.toDate(),
        });

        if (!this.maxDate) {
            this.maxDate = dayjs().endOf('day');
        }
    }

    change(event): void {
        if (event.startDate && event.endDate) {
            this.dateRangeChange.emit({
                start: event.startDate.toDate(),
                end: event.endDate.startOf('day').toDate(),
            });
        }
    }

    next(): void {
        const start = this.selected.startDate;
        const end = this.selected.endDate;
        if (this.checkEntireMonthSelected(start, end)) {
            this.selected = {
                startDate: this.selected.startDate.add(1, 'M').startOf('month'),
                endDate: this.selected.endDate.add(1, 'M').endOf('month')
            };
            if (this.selected.endDate.isAfter(this.maxDate.startOf('day'))) {
                this.selected = {
                    startDate: dayjs().startOf('month'),
                    endDate: this.maxDate.clone().endOf('day')
                };
            }
        } else if (this.checkEntireYearSelected(start, end)) {
            this.selected = {
                startDate: this.selected.startDate.add(1, 'year').startOf('year'),
                endDate: this.selected.endDate.add(1, 'year').endOf('year')
            };
        } else {
            const diff = this.selected.endDate.diff(this.selected.startDate);
            const newRange = {
                startDate: this.selected.startDate.clone().endOf('day').add(diff, 'milliseconds'),
                endDate: this.selected.endDate.clone().add(diff, 'milliseconds')
            };
            if (newRange.endDate.isAfter(this.maxDate.startOf('day'))) {
                newRange.startDate = this.maxDate.clone().startOf('day');
                newRange.endDate = this.maxDate.clone().endOf('day');
            }
            this.selected = newRange;
        }
        this.selected.startDate = this.selected.startDate.startOf('day');
        this.selected.endDate = this.selected.endDate.endOf('day');
        this.change(this.selected);
    }

    prev(): void {
        const start = this.selected.startDate;
        const end = this.selected.endDate;
        if (this.checkEntireMonthSelected(start, end)) {
            this.selected = {
                startDate: this.selected.startDate.add(-1, 'M').startOf('month'),
                endDate: this.selected.endDate.add(-1, 'M').endOf('month')
            };
        } else if (this.checkEntireYearSelected(start, end)) {
            this.selected = {
                startDate: this.selected.startDate.add(-1, 'year').startOf('year'),
                endDate: this.selected.endDate.add(-1, 'year').endOf('year')
            };
        } else {
            const diff = this.selected.endDate.diff(this.selected.startDate);
            const newRange = {
                startDate: this.selected.startDate.clone().subtract(diff, 'milliseconds'),
                endDate: this.selected.endDate.clone().startOf('day').subtract(diff, 'milliseconds')
            };
            this.selected = newRange;
        }
        this.selected.startDate = this.selected.startDate.startOf('day');
        this.selected.endDate = this.selected.endDate.endOf('day');
        this.change(this.selected);
    }

    checkEntireMonthSelected(s: Dayjs, e: Dayjs): boolean {
        const start = s.clone();
        const end = e.clone();
        return (start.date() === 1 && start.month() === end.month() && end.date() === start.endOf('month').date()
            && end.diff(start, 'days') < 32);
    }

    checkEntireYearSelected(s: Dayjs, e: Dayjs): boolean {
        const start = s.clone();
        const end = e.clone();
        return (start.date() === 1 && start.month() === 0 && end.date() === 31 && end.month() === 11);
    }

}

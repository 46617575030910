<ng-container [formGroup]="itemWithAnswerForm" *ngIf="itemWithAnswerForm">

    <!-- Item -->
    <div class="col-12">
        <label class="form-label">{{ 'bookings.modal.item' | translate }}*</label>
        <select class="form-control form-select" name="item"
                [formControl]="itemToBook"
                (change)="fetchQuestionsForItem()">
            <option *ngFor="let item of availableItems;"
                    [ngValue]="item">{{ item['name' + crtLang.toUpperCase()] }}</option>
        </select>

        <app-form-error [control]="itemToBook"></app-form-error>
    </div>

    <div formArrayName="answers" *ngIf="answersForm.enabled">

        <div class="row">
            <div class="col-12 col-sm-6" *ngFor="let answerControl of answerControls; let i = index">
                <label class="form-label">{{ questions[i]['question' + crtLang.toUpperCase()] }}*</label>

                <input *ngIf="questions[i].answerType === ItemToBookAnswerType.Number"
                       class="form-control"
                       [formControlName]="i"
                       placeholder="{{ questions[i]['question' + crtLang.toUpperCase()] }}"
                       pattern="{{ FormUtils.textInputPattern }}" type="number" step="1">

                <input *ngIf="questions[i].answerType === ItemToBookAnswerType.Text"
                       class="form-control"
                       [formControlName]="i"
                       placeholder="{{ questions[i]['question' + crtLang.toUpperCase()] }}"
                       pattern="{{ FormUtils.textInputPattern }}" type="text" maxlength="50">

                <div>
                    <div class="btn-group" role="group" *ngIf="questions[i].answerType === ItemToBookAnswerType.Boolean">
                        <input class="btn-check" type="radio"
                               [formGroupName]="i"
                               [value]="'false'">
                        <label class="btn btn-toggle btn-toggle-left h-auto"
                               [ngClass]="{ active: answerControl.value === 'true' }"
                               translate="common.yes"
                               (click)="answerControl.setValue('true'); answerControl.markAsDirty()"
                               (keyup)="answerControl.setValue('true'); answerControl.markAsDirty()">
                        </label>

                        <input class="btn-check" type="radio"
                               [formGroupName]="i"
                               [value]="'true'">
                        <label class="btn btn-toggle btn-toggle-right h-auto"
                               [ngClass]="{ active: answerControl.value === 'false' }"
                               translate="common.no"
                               (click)="answerControl.setValue('false'); answerControl.markAsDirty()"
                               (keyup)="answerControl.setValue('false'); answerControl.markAsDirty()">
                        </label>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-container>

import {Component, Input, OnInit} from '@angular/core';
import {
    FormArray,
    FormControl, FormGroup,
    Validators
} from '@angular/forms';
import {
    BookingSlotService,
    BookingSlotState,
    Day, GeneratingBookingSlotsInputDto
} from '../../../../../_services/configuration-services';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {FormUtils} from '../../../../../_shared/form-utils';
import {DateUtils} from '../../../../../_shared/date-utils';
import {CustomDateFormatter} from '../../../../../_shared/custom-date-formatter';
import {NgbTimeStruct} from "@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct";
import {DatePeriod} from '../../../../../_shared/_components/date-period-picker/date-period-picker.component';

@Component({
    selector: 'app-booking-slots-generation-modal',
    templateUrl: './booking-slots-generation-modal.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class BookingSlotsGenerationModalComponent implements OnInit {

    @Input() serviceId: string;

    bookingSlotsGenerationForm: FormGroup<BookingSlotsGenerationForm>;

    daysOfWeek = Object.keys(Day);

    BookingSlotState = BookingSlotState;

    minDate: NgbDateStruct;

    constructor(public bookingSlotsGenerationModal: NgbActiveModal,
                private readonly bookingSlotService: BookingSlotService,
                private readonly notificationsService: NotificationsService) {
    }

    ngOnInit(): void {
        const dayFormArray: FormControl<boolean>[] = [];
        this.daysOfWeek.forEach(d => dayFormArray.push(new FormControl<boolean>(true)));

        this.bookingSlotsGenerationForm = new FormGroup<BookingSlotsGenerationForm>({
            datePeriod: new FormGroup<DatePeriod>({
                from: new FormControl<NgbDateStruct>(null, [Validators.required, FormUtils.datePatternValidator]),
                to: new FormControl<NgbDateStruct>(null, [Validators.required, FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]),
            timePeriod: new FormGroup<TimePeriod>({
                from: new FormControl<NgbTimeStruct>(null, Validators.required),
                to: new FormControl<NgbTimeStruct>(null, Validators.required),
            }, [FormUtils.timePeriodValidator]),
            slotDuration: new FormControl<NgbTimeStruct>(null, Validators.required),
            state: new FormControl<BookingSlotState>(null, Validators.required),
            days: new FormArray(dayFormArray, FormUtils.atLeastOneSelectedValidator),
        }, {updateOn: 'change'});

        this.minDate = DateUtils.dateToNgbDateStruct(new Date());
    }

    generateBookingSlots(): void {
        if (!this.bookingSlotsGenerationForm.valid) {
            return;
        }

        this.bookingSlotService.generateBookingSlots(this.serviceId, this.getFormResult()).pipe().subscribe(result => {
            if (result.numberOfAbortedSlots === 0) {
                this.notificationsService.success({
                    title: 'bookings.notifications.generateBookingSlotsSuccess',
                    interpolateParams: { numberOfCreatedSlots: result.numberOfCreatedSlots }
                });
            }
            else
            {
                this.notificationsService.warning({
                    title: 'bookings.notifications.generateBookingSlotsWithConflicts',
                    interpolateParams: { numberOfConflicts: result.numberOfAbortedSlots }
                });
            }

            this.bookingSlotsGenerationModal.close('success');
        });
    }

    private getFormResult(): GeneratingBookingSlotsInputDto {
        const selectedDays = [];
        for (const [index, value] of this.daysOfWeek.entries()) {
            if (this.days.get(index.toString()).value) {
                selectedDays.push(value);
            }
        }

        return new GeneratingBookingSlotsInputDto({
            startDate: DateUtils.ngbDateStructToDate(this.startDate.value),
            endDate: DateUtils.ngbDateStructToDate(this.endDate.value),
            startTime: DateUtils.ngbTimeStructToString(this.startTime.value),
            lastStartTimeOfTheDay: DateUtils.ngbTimeStructToString(this.lastStartTimeOfTheDay.value),
            duration: DateUtils.ngbTimeStructToString(this.slotDuration.value),
            state: this.state.value,
            days: selectedDays
        });
    }

    get datePeriod(): FormGroup<DatePeriod> {
        return this.bookingSlotsGenerationForm.get('datePeriod') as FormGroup<DatePeriod>;
    }

    get startDate(): FormControl<NgbDateStruct> {
        return this.datePeriod.get('from') as FormControl<NgbDateStruct>;
    }

    get endDate(): FormControl<NgbDateStruct> {
        return this.datePeriod.get('to') as FormControl<NgbDateStruct>;
    }

    get timePeriod(): FormGroup<TimePeriod> {
        return this.bookingSlotsGenerationForm.get('timePeriod') as FormGroup<TimePeriod>;
    }

    get startTime(): FormControl<NgbTimeStruct> {
        return this.timePeriod.get('from') as FormControl<NgbTimeStruct>;
    }

    get lastStartTimeOfTheDay(): FormControl<NgbTimeStruct> {
        return this.timePeriod.get('to') as FormControl<NgbTimeStruct>;
    }

    get slotDuration(): FormControl<NgbTimeStruct> {
        return this.bookingSlotsGenerationForm.get('slotDuration') as FormControl<NgbTimeStruct>;
    }

    get state(): FormControl<BookingSlotState> {
        return this.bookingSlotsGenerationForm.get('state') as FormControl<BookingSlotState>;
    }

    get days(): FormArray<FormControl<boolean>> {
        return this.bookingSlotsGenerationForm.get('days') as FormArray<FormControl<boolean>>;
    }
}

interface BookingSlotsGenerationForm {
    datePeriod: FormGroup<DatePeriod>,
    timePeriod: FormGroup<TimePeriod>,
    slotDuration: FormControl<NgbTimeStruct>,
    state: FormControl<BookingSlotState>,
    days: FormArray<FormControl<boolean>>
}

interface TimePeriod {
    from: FormControl<NgbTimeStruct>,
    to: FormControl<NgbTimeStruct>
}

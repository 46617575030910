import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {LoaderService} from './loader-service';
import {finalize} from 'rxjs/operators';
import {environment} from '../../environments/environment';


export class UrlConfiguration {
    constructor(public readonly expression: RegExp,
                public readonly loader: boolean) {
    }
}

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    private readonly urlConfigurations: UrlConfiguration[] = [];
    private requestId = 0;

    constructor(private readonly loader: LoaderService) {
        this.urlConfigurations.push(
            new UrlConfiguration(
                new RegExp(environment.configurationServiceBaseUrl + '/api/v1/Alarm/has-active'),
                false));
        this.urlConfigurations.push(
            new UrlConfiguration(
                new RegExp(environment.configurationServiceBaseUrl + '/api/v1/Alert/has-active'),
                false));
        this.urlConfigurations.push(
            new UrlConfiguration(
                new RegExp(environment.configurationServiceBaseUrl + '/api/v1/PublicLightingLight/for-service/[a-zA-Z0-9-]+/with-state'),
                false));
    }

    /**
     * Use this method to add URL configuration
     * This could be used to disable loader on certain URLs
     */
    public addUrlConfiguration(urlConfiguration: UrlConfiguration): (() => void) {
        this.urlConfigurations.push(urlConfiguration);
        return (() => {
            const itemIndex = this.urlConfigurations.indexOf(urlConfiguration);
            this.urlConfigurations.splice(itemIndex, 1);
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requestId++;
        const reqId = 'http-' + this.requestId;
        const urlConfiguration = this.getUrlConfiguration(req.url);
        if (urlConfiguration.loader) {
            this.loader.start(reqId);
        }
        if (req.method === 'GET') {
            return next.handle(req).pipe(
                finalize(() => {
                    if (urlConfiguration.loader) {
                        this.loader.stop(reqId);
                    }
                }));
        }
        return next.handle(req).pipe(
            finalize(() => {
                if (urlConfiguration.loader) {
                    this.loader.stop(reqId);
                }
            })
        );
    }

    private getUrlConfiguration(url: string): (UrlConfiguration | null) {
        let urlConfig = new UrlConfiguration(null, true);

        const matchingConfigurations = this.urlConfigurations.filter((config) => {
            return url.match(config.expression);
        });

        if (matchingConfigurations.length > 0) {
            urlConfig = matchingConfigurations[matchingConfigurations.length - 1];
        }

        return urlConfig;
    }
}

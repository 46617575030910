<div class="content-container">

    <h4 translate="weather.title"></h4>

    <form name="filterForm" *ngIf="measuringPoints.length > 1">

        <div class="row">

            <!-- Weather Station -->
            <div class="col-12 col-lg-6">
                <label class="form-label" translate="weather.selectWeatherStation"></label>
                <select class="form-control form-select" id="station" name="station"
                        [(ngModel)]="selectedMeasuringPoint"
                        (change)="fetchWeatherData()">
                    <option *ngFor="let measuringPoint of measuringPoints"
                            [ngValue]="measuringPoint">{{ measuringPoint['name' + crtLang.toUpperCase()] | translate }}</option>
                </select>
            </div>

        </div>
    </form>
</div>

<div class="content-container">
    <h5 *ngIf="measuringPoints.length === 0" translate="weather.messages.noWeatherStation"></h5>

    <ng-container *ngIf="!!selectedMeasuringPoint">
        <h5>{{ selectedMeasuringPoint['name' + crtLang.toUpperCase()] | translate }}</h5>

        <h5 *ngIf="weatherData === null" translate="">weather.messages.noData</h5>

        <div class="row card-container" *ngIf="!!weatherData">

            <!-- Standard data -->
            <div class="card text-center" *ngFor="let measure of weatherData.weatherMeasures">
                <div class="card-header">
                    <span><strong>{{ measure['label' + crtLang.toUpperCase()] | translate}}</strong></span>
                </div>
                <div class="card-body">
                    <h4 class="card-title">{{ measure.value ?? '-' }} {{ measure.unit }}</h4>

                    <h6 class="card-subtitle">
                        <span translate="common.max"></span>
                        <span class="red"> : {{ measure.maxValue ?? '-' }} {{ measure.unit }}</span>
                    </h6>
                    <p class="card-text">{{ measure.maxDateTime | date: 'HH:mm' }}</p>

                    <h6 class="card-subtitle">
                        <span translate="common.min"></span>
                        <span class="blue"> : {{ measure.minValue ?? '-' }} {{ measure.unit }}</span>
                    </h6>
                    <p class="card-text">{{ measure.minDateTime | date: 'HH:mm' }}</p>
                </div>
            </div>

            <!-- Trend Data -->
            <div class="card text-center" *ngFor="let measure of weatherData.weatherTrendMeasures">
                <div class="card-header">
                    <span><strong>{{ measure['label' + crtLang.toUpperCase()] | translate}}</strong></span>
                </div>
                <div class="card-body">
                    <h6 class="card-subtitle mt-3">
                        <fa-icon class="fa-4x" [icon]="icons.up" *ngIf="measure.weatherTrend === WeatherTrend.Up"></fa-icon>
                        <fa-icon class="fa-4x" [icon]="icons.down" *ngIf="measure.weatherTrend === WeatherTrend.Down"></fa-icon>
                    </h6>
                </div>
            </div>

            <!-- Wind direction -->
            <div class="card text-center" *ngIf="!!weatherData.weatherWindDirectionMeasure">
                <div class="card-header">
                    <span><strong>{{ weatherData.weatherWindDirectionMeasure['label' + crtLang.toUpperCase()] | translate}}</strong></span>
                </div>
                <div class="card-body">
                    <h4 class="card-title mt-3">{{ weatherData.weatherWindDirectionMeasure.windDirectionDegree ?? '-' }} {{ weatherData.weatherWindDirectionMeasure.unit }}</h4>

                    <h6 class="card-subtitle mt-5">
                        <span translate="weather.windDirection"></span><span> : {{ weatherData.weatherWindDirectionMeasure.windDirection ?? '-' }}</span>
                    </h6>
                </div>
            </div>

            <!-- Swell -->
            <div class="card text-center" *ngIf="!!weatherData.weatherSwellMeasure">
                <div class="card-header">
                    <span><strong translate="weather.swell"></strong></span>
                </div>
                <div class="card-body">

                    <h4 class="card-title mt-3">
                        <span translate="weather.swellHeight"></span> : <span>{{ weatherData.weatherSwellMeasure.height }} {{ weatherData.weatherSwellMeasure.unitHeight }}</span>
                    </h4>
                    <h4 class="card-title mt-3">
                        <span translate="weather.swellPeriod"></span> : <span>{{ weatherData.weatherSwellMeasure.period }} {{ weatherData.weatherSwellMeasure.unitPeriod }}</span>
                    </h4>

                </div>
            </div>

        </div>

    </ng-container>

</div>

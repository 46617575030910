<div class="content-container bg-white">

    <h1>{{ (!!interventionId ? 'equipments.interventions.modifyTitle' : 'equipments.interventions.addTitle') | translate }}</h1>

    <div class="btn-bar">
        <button class="btn btn-outline-danger" *ngIf="!!interventionId"
                (click)="deleteEquipmentIntervention()"
                translate="common.delete">
        </button>
    </div>

    <form name="equipmentInterventionForm" [formGroup]="interventionForm">

        <div class="row">

            <!-- Search Equipment -->
            <div class="default-filter-form-field">
                <label class="form-label" for="equipmentSearch">{{ 'equipments.interventions.form.equipmentName' | translate }}*</label>
                <app-search-equipment id="equipmentSearch" #searchEquipmentComponent
                                      (selectedEquipmentEvent)="onEquipmentChange($event)"
                                      [disabled]="false"></app-search-equipment>
                <app-form-error [control]="equipment"></app-form-error>
            </div>

            <!-- Location -->
            <div class="default-filter-form-field" *ngIf="!!equipment.value">
                <label class="form-label" for="equipmentLocation">{{ 'equipments.interventions.form.equipmentLocation' | translate }}*</label>
                <input class="form-control" id="equipmentLocation" type="text"
                       [ngModel]="equipment.value.location"
                       [ngModelOptions]="{standalone: true}"
                       placeholder="{{ 'equipments.interventions.placeholder.equipmentLocation' | translate }}" disabled>
            </div>


            <!-- Date -->
            <div class="default-filter-form-field">
                <label class="form-label" for="date">{{ 'equipments.interventions.form.date' | translate }}*</label>
                <div class="input-group">
                    <input class="form-control" id="date" name="date"
                           [formControl]="date"
                           placeholder="{{ 'common.placeholders.dateFormat' | translate }}"
                           ngbDatepicker #d="ngbDatepicker">

                    <span class="input-group-text">
                        <button class="btn btn-link btn-sm" (click)="d.toggle()">
                            <fa-icon [icon]="icons.calendar"></fa-icon>
                        </button>
                        <button class="btn btn-link btn-sm" *ngIf="!!date.value"
                                (click)="date.setValue(null);date.markAsDirty();">
                            <fa-icon [icon]="icons.close"></fa-icon>
                        </button>
                    </span>
                </div>
                <app-form-error [control]="date"></app-form-error>
            </div>

            <!-- Person In Charge -->
            <div class="default-filter-form-field">
                <label class="form-label" for="personInCharge">{{ 'equipments.interventions.form.personInCharge' | translate }}*</label>
                <input class="form-control" id="personInCharge" type="text"
                       [formControl]="personInCharge"
                       placeholder="{{ 'equipments.interventions.placeholder.personInCharge' | translate }}">
                <app-form-error [control]="personInCharge"></app-form-error>
            </div>

            <!-- Monitoring Person -->
            <div class="default-filter-form-field">
                <label class="form-label" for="monitoringPerson">{{ 'equipments.interventions.form.monitoringPerson' | translate}}*</label>
                <input class="form-control" id="monitoringPerson" type="text"
                       [formControl]="monitoringPerson"
                       placeholder="{{ 'equipments.interventions.placeholder.monitoringPerson' | translate }}">
                <app-form-error [control]="monitoringPerson"></app-form-error>
            </div>

            <!-- Description -->
            <div class="default-filter-form-field">
                <label class="form-label" for="description">{{ 'equipments.interventions.form.description' | translate}}*</label>
                <textarea class="form-control" id="description" name="description"
                          [formControl]="description"
                          maxlength="1000" rows="3"
                          placeholder="{{ 'equipments.interventions.placeholder.description' | translate }}"></textarea>
                <app-form-error [control]="description"></app-form-error>
            </div>

        </div>

    </form>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary"
                (click)="back()" translate="common.back">
        </button>

        <button type="submit" class="btn btn-primary"
                (click)="saveIntervention()"
                [disabled]="interventionForm.invalid || interventionForm.pristine"
                translate="common.save">
        </button>
    </div>

    <!-- Annexes -->
    <ng-container *ngIf="!!interventionId">
        <hr class="mt-5">

        <document-management-component [id]="interventionId"
                                       [documentManagementType]="this.documentManagementTypeEnum.Intervention"></document-management-component>
    </ng-container>

</div>

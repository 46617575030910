import {Component, Input, OnInit} from '@angular/core';
import {
    BoatType,
    MooringPlaceDto,
    MooringPlaceService, UserContactDto,
    UserInputDto
} from '../../../_services/configuration-services';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../notifications.service';
import {FormUtils} from '../../form-utils';
import {ConfirmModalService} from '../confirm-modal/confirm-modal.component';
import { MapContent } from 'src/app/services/manage-service/manage-map-content/manage-map-content.component';

@Component({
    selector: 'app-mooring-place-view-modal',
    templateUrl: './mooring-place-view-modal.component.html'
})
export class MooringPlaceViewModalComponent implements OnInit {

    @Input() mooringPlace: MooringPlaceDto | null;
    @Input() mapContent: MapContent;
    @Input() user: UserInputDto | null;
    @Input() onlyPlaceInfo = false;

    mooringPlaceForm: UntypedFormGroup;
    selectedUser: UserContactDto | null = null;

    creationMode = false;
    previewMode = true;

    BoatType = BoatType;
    textInputPattern = FormUtils.textInputPattern;

    constructor(public activeModal: NgbActiveModal,
                private readonly notificationsService: NotificationsService,
                private readonly mooringPlaceService: MooringPlaceService,
                private readonly confirmService: ConfirmModalService) {

        this.mooringPlaceForm = new UntypedFormGroup({
            placeId: new UntypedFormControl('', [Validators.maxLength(20)]),
            pontoon: new UntypedFormControl('', [Validators.maxLength(20)]),
            placeNum: new UntypedFormControl('', [Validators.maxLength(20)]),
            placeType: new UntypedFormControl(''),
            placeWidthInMeter: new UntypedFormControl('', [FormUtils.numberDecimalPatternValidator]),
            placeLengthInMeter: new UntypedFormControl('', [FormUtils.numberDecimalPatternValidator]),
            latitude: new UntypedFormControl('', [FormUtils.coordinatesPatternValidator]),
            longitude: new UntypedFormControl('', [FormUtils.coordinatesPatternValidator]),
            boatRegistrationNumber: new UntypedFormControl(null, [Validators.maxLength(50)]),
            boatLengthInMeter: new UntypedFormControl('', [FormUtils.numberDecimalPatternValidator]),
            boatWidthInMeter: new UntypedFormControl('', [FormUtils.numberDecimalPatternValidator]),
            boatWeightInKg: new UntypedFormControl('', [FormUtils.integerPatternValidator]),
            draft: new UntypedFormControl('', [FormUtils.numberDecimalPatternValidator]),
            boatType: new FormControl<BoatType>(BoatType.Unknown, []),
            remark: new UntypedFormControl('', [Validators.maxLength(255)]),
            sublet: new FormControl<boolean>(false)
        });
    }

    ngOnInit(): void {
        if (this.user) {
            this.selectedUser = UserContactDto.fromJS({
                id: this.user.id,
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                email: this.user.email,
                customerNumber: this.user.customerNumber,
                phone: this.user.phoneMobile ?? this.user.phonePrivate ?? this.user.phoneProfessional,
            });
        }

        if (this.mooringPlace) {
            this.mooringPlaceForm.patchValue(this.mooringPlace);
        }

        if (this.mapContent) {
            this.mooringPlaceForm.patchValue({
                longitude: this.mapContent.longitude,
                latitude: this.mapContent.latitude
            });
        }

        this.creationMode = this.mooringPlace == null

        if (this.onlyPlaceInfo) {
            this.previewMode = false;
        }
    }

    toggleEdit(): void {
        this.previewMode = !this.previewMode;
        this.mooringPlaceForm.patchValue(this.mooringPlace);
    }

    selectUser(user: UserContactDto): void {
        this.selectedUser = user;
    }

    save(): void {
        if (!this.mooringPlaceForm.value.boatType) {
            this.mooringPlaceForm.value.boatType = BoatType.Unknown;
        }

        this.mooringPlaceForm.value.placeLengthInMeter = parseFloat(this.mooringPlaceForm.value.placeLengthInMeter);
        this.mooringPlaceForm.value.placeWidthInMeter = parseFloat(this.mooringPlaceForm.value.placeWidthInMeter);

        this.mooringPlaceForm.value.latitude = parseFloat(this.mooringPlaceForm.value.latitude);
        this.mooringPlaceForm.value.longitude = parseFloat(this.mooringPlaceForm.value.longitude);

        this.mooringPlaceForm.value.boatLengthInMeter = parseFloat(this.mooringPlaceForm.value.boatLengthInMeter) ?? null;
        this.mooringPlaceForm.value.boatWidthInMeter = parseFloat(this.mooringPlaceForm.value.boatWidthInMeter) ?? null;
        this.mooringPlaceForm.value.boatWeightInKg = parseInt(this.mooringPlaceForm.value.boatWeightInKg, 10) ?? null;

        this.mooringPlaceForm.value.draft = parseFloat(this.mooringPlaceForm.value.draft) ?? null;

        if (this.creationMode) {
            this.create();
        } else {
            this.update();
        }
    }

    private create(): void {
        const mp = new MooringPlaceDto(this.mooringPlaceForm.value);
        mp.userId = this.selectedUser?.id ?? null;
        this.mooringPlaceService.createMooringPlace(mp).pipe().subscribe({
            next: () => {
                this.notificationsService.success({title: 'mooringPlace.modal.result.success'});
                this.activeModal.close('success');
            }
        });
    }

    private update(): void {
        const mp = new MooringPlaceDto(this.mooringPlaceForm.value);

        if (this.onlyPlaceInfo) {
            mp.userId = this.mooringPlace.userId;

        } else {
            mp.userId = this.selectedUser?.id ?? null;
        }

        this.mooringPlaceService.updateMooringPlace(this.mooringPlace.placeId, mp).pipe().subscribe({
            next: () => {
                this.notificationsService.success({title: 'mooringPlace.modal.result.updateSuccess'});
                this.activeModal.close('success');

            }, error: () => {
                this.notificationsService.error({title: 'mooringPlace.modal.result.error'});
            }
        });
    }

    delete(): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.mooringPlaceService.deleteMooringPlace(this.mooringPlace.placeId).pipe().subscribe({
                    next: () => {
                        this.notificationsService.success({title: 'mooringPlace.modal.result.deleteSuccess'});
                        this.activeModal.close('success');
                    }
                });
            }
        });
    }

    get boatType(): FormControl<BoatType> {
        return this.mooringPlaceForm.get('boatType') as FormControl<BoatType>;
    }

    get sublet(): FormControl<boolean> {
        return this.mooringPlaceForm.get('sublet') as FormControl<boolean>;
    }

}


<div class="content-container bg-white">

    <h1 translate="contact.configuration.title"></h1>

    <div [formGroup]="contactConfigForm">

        <div class="row">

            <!-- email -->
            <div class="col-12 col-sm-6">
                <label class="form-label">{{ 'common.contact.email' | translate }}*</label>
                <input class="form-control" id="email" name="userEmail" [formControl]="email"
                       placeholder="{{'common.contact.email' | translate}}" maxlength="75">
                <app-form-error [control]="email"></app-form-error>
            </div>

            <!-- ROLES -->
            <div class="col-12 col-sm-6">
                <app-role-management [enablePublicAccess]="false"
                                     [enableBaseRoles]="false"
                                     [enableCustomRoles]="true"
                                     [roleForm]="rolesForm"></app-role-management>
            </div>

            <!-- CGUs -->
            <div class="col-12 col-sm-6">
                <label class="form-label" for="cguFR">{{ 'contact.configuration.cguFR' | translate }}*</label>
                <textarea id="cguFR" class="form-control" name="cguFR"
                          rows="4" maxlength="2500"
                          placeholder="{{ 'contact.configuration.cguFR' | translate }}"
                          [formControl]="cguFR"></textarea>
            </div>

            <div class="col-12 col-sm-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <label class="form-label" for="cguDE">{{ 'contact.configuration.cguDE' | translate }}</label>
                <textarea id="cguDE" class="form-control" name="cguDE"
                          rows="4" maxlength="2500"
                          placeholder="{{ 'contact.configuration.cguDE' | translate }}"
                          [formControl]="cguDE"></textarea>
            </div>

            <div class="col-12 col-sm-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <label class="form-label" for="cguIT">{{ 'contact.configuration.cguIT' | translate }}</label>
                <textarea id="cguIT" class="form-control" name="cguIT"
                          rows="4" maxlength="2500"
                          placeholder="{{ 'contact.configuration.cguIT' | translate }}"
                          [formControl]="cguIT"></textarea>
            </div>

            <div class="col-12 col-sm-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <label class="form-label" for="cguEN">{{ 'contact.configuration.cguEN' | translate }}</label>
                <textarea id="cguEN" class="form-control" name="cguEN"
                          rows="4" maxlength="2500"
                          placeholder="{{ 'contact.configuration.cguEN' | translate }}"
                          [formControl]="cguEN"></textarea>
            </div>

        </div>

        <div class="btn-bar">
            <button class="btn btn-outline-secondary" type="button"
                    (click)="back()"
                    translate="common.back">
            </button>

            <button type="submit" class="btn btn-primary"
                    (click)="saveConfiguration()"
                    [disabled]="contactConfigForm.invalid || contactConfigForm.pristine"
                    translate="common.save">
            </button>
        </div>

    </div>
</div>

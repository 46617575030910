
<div [formGroup]="bookingForm">

    <div class="row">
        <p class="text-error" *ngIf="!bookingConfigAlreadyExist"
           translate="error.configuration.noBookingConfiguration"></p>
    </div>

    <div class="row">

        <h5 translate="services.booking.baseConfigurationSubtitle"></h5>

        <!-- Booking Mode -->
        <div class="col-12">
            <label class="form-label" translate="services.booking.bookingMode"></label>

            <div>
                <div class="btn-group" role="group">
                    <input class="btn-check" type="radio"
                           [formControl]="bookingMode"
                           [value]="BookingMode.Free">
                    <label class="btn btn-toggle btn-toggle-left h-auto"
                           [ngClass]="{ active: bookingMode.value === BookingMode.Free }"
                           translate="common.bookingMode.Free"
                           (click)="adaptFormDependingOnBookingMode(BookingMode.Free)"
                           (keyup)="adaptFormDependingOnBookingMode(BookingMode.Free)"></label>

                    <input class="btn-check" type="radio"
                           [formControl]="bookingMode"
                           [value]="BookingMode.Slots">
                    <label class="btn btn-toggle btn-toggle-right h-auto"
                           [ngClass]="{ active: bookingMode.value === BookingMode.Slots }"
                           translate="common.bookingMode.Slots"
                           (click)="adaptFormDependingOnBookingMode(BookingMode.Slots)"
                           (keyup)="adaptFormDependingOnBookingMode(BookingMode.Slots)"></label>
                </div>
            </div>

            <app-form-error [control]="bookingMode"></app-form-error>
        </div>

        <!-- Initial duration -->
        <div class="col-12 col-md-4" *ngIf="bookingMode.value === BookingMode.Free">
            <label class="form-label" for="initialBookingDuration">{{ 'services.booking.initialBookingDuration' | translate }}*</label>

            <input class="form-control" id="initialBookingDuration" name="initialBookingDuration" type="number"
                   [formControl]="initialBookingDuration"
                   [min]="1"
                   (keydown)="FormUtils.validateInputNumberLength($event, 5)">
            <app-form-error [control]="duration"></app-form-error>
            <app-form-error [control]="initialBookingDuration"></app-form-error>
        </div>

        <!-- Max duration -->
        <div class="col-12 col-md-4" *ngIf="bookingMode.value === BookingMode.Free">
            <label class="form-label" for="maxBookingDuration">{{ 'services.booking.maxBookingDuration' | translate }}*</label>

            <input class="form-control" id="maxBookingDuration" name="maxBookingDuration" type="number"
                   [formControl]="maxBookingDuration"
                   [min]="1"
                   (keydown)="FormUtils.validateInputNumberLength($event, 5)">
            <app-form-error [control]="maxBookingDuration"></app-form-error>
        </div>

        <!-- Booking Duration Unit -->
        <div class="col-12 col-md-4" *ngIf="bookingMode.value === BookingMode.Free">
            <label class="form-label" for="bookingTimeUnit">{{ 'common.unit' | translate }}*</label>

            <select class="form-control form-select" name="bookingTimeUnit" id="bookingTimeUnit"
                    formControlName="bookingTimeUnit">
                <option *ngFor="let bookingTimeUnit of bookingTimeUnits;"
                        [ngValue]="bookingTimeUnit">{{ 'common.bookingTimeUnit.' + bookingTimeUnit | translate }}</option>
            </select>
            <app-form-error [control]="bookingTimeUnit"></app-form-error>
        </div>

        <div class="col-12 col-md-6" *ngIf="bookingMode.value === BookingMode.Free">
            <label class="form-label" for="maximumBookingAdvanceTimeInDay">{{ 'services.booking.maximumBookingAdvanceTimeInDay' | translate }}</label>

            <input class="form-control" id="maximumBookingAdvanceTimeInDay" name="maximumBookingAdvanceTimeInDay" type="number"
                   [formControl]="maximumBookingAdvanceTimeInDay"
                   (keydown)="FormUtils.validateInputNumberLength($event, 4)">
            <app-form-error [control]="maximumBookingAdvanceTimeInDay"></app-form-error>
        </div>

        <!-- Max simultaneous Booking by User -->
        <div class="col-12 col-md-6" *ngIf="bookingMode.value === BookingMode.Free">
            <label class="form-label" for="maxNumberOfSimultaneousBookingByUser">{{ 'services.booking.maxNumberOfSimultaneousBookingByUser' | translate }}*</label>

            <input class="form-control" id="maxNumberOfSimultaneousBookingByUser" name="maxNumberOfSimultaneousBookingByUser" type="number"
                   [formControl]="maxNumberOfSimultaneousBookingByUser"
                   (keydown)="FormUtils.validateInputNumberLength($event, 4)">
            <app-form-error [control]="maxNumberOfSimultaneousBookingByUser"></app-form-error>
        </div>

        <!-- Max planned Booking by User -->
        <div class="col-12 col-md-6">
            <label class="form-label" for="maxNumberOfPlannedBookingByUser">{{ 'services.booking.maxNumberOfPlannedBookingByUser' | translate }}*</label>

            <input class="form-control" id="maxNumberOfPlannedBookingByUser" name="maxNumberOfPlannedBookingByUser" type="number"
                   [formControl]="maxNumberOfPlannedBookingByUser"
                   (keydown)="FormUtils.validateInputNumberLength($event, 4)">
            <app-form-error [control]="maxNumberOfPlannedBookingByUser"></app-form-error>
        </div>

    </div>

    <div class="row">

        <!-- Cancellation/Modification delay -->
        <div class="col-12 col-md-6">
            <label class="form-label" for="deadlineForUpdatingBeforeBookingPeriod">{{ 'services.booking.deadlineForUpdatingBeforeBookingPeriod' | translate }}*</label>

            <input class="form-control" id="deadlineForUpdatingBeforeBookingPeriod" name="deadlineForUpdatingBeforeBookingPeriod" type="number"
                   [formControl]="deadlineForUpdatingBeforeBookingPeriod"
                   (keydown)="FormUtils.validateInputNumberLength($event, 5)">
            <app-form-error [control]="deadlineForUpdatingBeforeBookingPeriod"></app-form-error>
        </div>

        <div class="col-12 col-md-6">
            <label class="form-label" for="deadlineForUpdatingBeforeBookingPeriodTimeUnit">{{ 'common.unit' | translate }}*</label>

            <select class="form-control form-select" name="deadlineForUpdatingBeforeBookingPeriodTimeUnit" id="deadlineForUpdatingBeforeBookingPeriodTimeUnit"
                    formControlName="deadlineForUpdatingBeforeBookingPeriodTimeUnit">
                <option *ngFor="let bookingTimeUnit of bookingTimeUnitsExceptMonthAndYear;"
                        [ngValue]="bookingTimeUnit">{{ 'common.bookingTimeUnit.' + bookingTimeUnit | translate }}</option>
            </select>
            <app-form-error [control]="bookingTimeUnit"></app-form-error>
        </div>

        <div class="col-12" *ngIf="bookingMode.value === BookingMode.Free">
            <div class="form-check form-switch mt-4">
                <input class="form-check-input" id="recurrentBookingAvailable" type="checkbox" name="recurrentBookingAvailable"
                       formControlName="recurrentBookingAvailable">
                <label class="form-check-label" for="recurrentBookingAvailable" translate="services.booking.allowRecurrentBooking"></label>
            </div>
        </div>

    </div>

    <div class="row">

        <h5 class="my-4" translate="services.booking.displaySubtitle"></h5>

        <div class="col-12 col-md-6">
            <label class="form-label" for="defaultCalendarDisplayMode">
                {{ 'services.booking.calendarMode' | translate }}*
            </label>
            <select class="form-select" id="defaultCalendarDisplayMode" [formControl]="defaultCalendarDisplayMode">
                <option *ngFor="let bookingCalendarMode of bookingCalendarModes;"
                        [ngValue]="bookingCalendarMode">{{ 'common.bookingCalendarMode.' + bookingCalendarMode | translate }}</option>
            </select>
        </div>

        <div class="col-12 col-md-6">
            <label class="form-label" translate="services.booking.informationsToDisplayInBooking"></label>

            <div class="form-check form-switch">
                <input class="form-check-input" id="displayUserCards" type="checkbox" name="displayUserCards"
                       formControlName="displayUserCards">
                <label class="form-check-label" for="recurrentBookingAvailable" translate="services.booking.displayUserCards"></label>
            </div>

            <div class="form-check form-switch">
                <input class="form-check-input" id="displayUserBoats" type="checkbox" name="displayUserBoats"
                       formControlName="displayUserBoats">
                <label class="form-check-label" for="displayUserBoats" translate="services.booking.displayUserBoats"></label>
            </div>
        </div>

    </div>

    <div class="btn-bar">
        <button class="btn btn-primary" type="submit"
                [disabled]="bookingForm.invalid || bookingForm.pristine"
                (click)="saveBookingConfig()"
                translate="services.booking.saveBookingConfig">
        </button>
    </div>
</div>

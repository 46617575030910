import {AfterViewChecked, Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
    CraningBookingService,
    CraningBookingWithSlotDto,
    CraningServiceAnswerType,
    CraningServiceDto,
    CraningServiceQuestionDto,
    CraningServiceService
} from '../../../../../_services/configuration-services';
import {CraningBookingUtils} from '../craning-booking-utils';
import {DateUtils} from '../../../../../_shared/date-utils';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import {TranslateUtils} from '../../../../../_shared/translate-utils';

import {GesConstants} from '../../../../../app.constants';

@Component({
    selector: 'app-craning-booking-print',
    templateUrl: './craning-booking-print.component.html',
    styleUrls: ['./craning-booking-print.component.scss']
})
export class CraningBookingPrintComponent implements OnInit, OnDestroy, AfterViewChecked {

    GesConstants = GesConstants;

    serviceId: string;
    from: Date | null = null;
    to: Date | null = null;
    craningBookingId: number | null = null;

    craningBookings: CraningBookingWithSlotDto[] = [];
    craningServices: CraningServiceDto[] = [];

    formats = {
        date: DateUtils.dateFormat,
    };

    DateUtils = DateUtils;
    CraningServiceAnswerType = CraningServiceAnswerType;

    private isReadyToPrint = false;
    private crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly craningBookingService: CraningBookingService,
        private readonly craningServiceService: CraningServiceService,
        public readonly craningBookingUtils: CraningBookingUtils,
        private readonly translateService: TranslateService,
        private readonly renderer: Renderer2,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                }
            });

        this.serviceId = this.route.snapshot.params['serviceId'];

        this.from = this.route.snapshot.params['from'] ?? null;
        this.to = this.route.snapshot.params['to'] ?? null;
        this.craningBookingId = this.route.snapshot.params['craningBookingId'] ?? null;

        this.fetchCraningServices();
        this.renderer.addClass(this.document.body, 'no-header');
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.renderer.removeClass(this.document.body, 'no-header');
    }

    ngAfterViewChecked(): void {
        if (this.isReadyToPrint) {
            this.isReadyToPrint = false;
            window.print();
        }
    }

    public isCraningServiceChosen(craningService: CraningServiceDto, craningBooking: CraningBookingWithSlotDto): boolean {
        return !!craningBooking.craningSelectedServices.find(s => s.craningServiceId === craningService.id);
    }

    public getCraningServiceQuestionAnswer(
        craningService: CraningServiceDto,
        question: CraningServiceQuestionDto,
        craningBooking: CraningBookingWithSlotDto): string {

        const selectedCraningService = craningBooking.craningSelectedServices.find(s => s.craningServiceId === craningService.id);
        const answer = selectedCraningService?.answers.find(a => a.craningServiceQuestionId === question.id);

        if (!answer) {
            return null;
        }
        return answer.answer;
    }


    public getCraningServiceLabel(craningService: CraningServiceDto): string {
        return craningService['label' + this.crtLang.toUpperCase()];
    }

    public getCraningServiceQuestionLabel(craningServiceQuestion: CraningServiceQuestionDto): string {
        return craningServiceQuestion['question' + this.crtLang.toUpperCase()];
    }

    private fetchCraningServices(): Subscription {
        return this.craningServiceService.getAll(this.serviceId).pipe().subscribe(craningServices => {
            this.craningServices = craningServices;
            this.fetchCraningBookings();
        });
    }

    private fetchCraningBookings(): Subscription {
        if (!this.craningBookingId) {
            return this.craningBookingService.getCraningBookings(this.serviceId, new Date(this.from), new Date(this.to)).pipe()
                .subscribe(result => {
                        this.craningBookings = result;
                        this.isReadyToPrint = true;
                    }
                );
        } else {
            return this.craningBookingService.getForAdmin(this.craningBookingId).pipe()
                .subscribe(result => {
                        this.craningBookings = [result];
                        this.isReadyToPrint = true;
                    }
                );
        }
    }
}


<div class="nav-menu dark" [appHasAnyRole]="[AppRoles.cashier]">

    <ul class="navbar-nav">
        <li class="nav-item">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['transaction-consumption']"
                   #transactionConsumption="routerLinkActive"
                   [ngClass]='{"selected": transactionConsumption.isActive}' routerLinkActive>
                    <span translate>transactions.enums.types.serviceconsumption</span>
                </a>
            </div>
        </li>

        <li class="nav-item">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['transaction-credit']"
                   #transactionCredit="routerLinkActive"
                   [ngClass]='{"selected": transactionCredit.isActive}' routerLinkActive>
                    <span translate>transactions.enums.types.ewalletcredit</span>
                </a>
            </div>
        </li>

        <li class="nav-item">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['transaction-billing']"
                   #transactionBilling="routerLinkActive"
                   [ngClass]='{"selected": transactionBilling.isActive}' routerLinkActive>
                    <span translate>menu.navigation.transactions.transactionBilling</span>
                </a>
            </div>
        </li>

        <li class="nav-item" [appHasAnyRole]="[AppRoles.configurator]">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['search']"
                   #transactionSearch="routerLinkActive"
                   [ngClass]='{"selected": transactionSearch.isActive}' routerLinkActive>
                    <span translate>menu.navigation.transactions.transactionSearch</span>
                </a>
            </div>
        </li>

    </ul>
</div>

<router-outlet></router-outlet>

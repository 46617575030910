import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {RoleManagementComponent} from '../../../_shared/_components/role-management/role-management.component';
import {DocumentManagementTypeEnum} from '../../../_shared/_components/document-management-component/document-management.component';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    InformationState,
    RoleDto,
    RoleService,
    SurveyDto,
    SurveyInputDto, SurveyPublishInputDto,
    SurveyService
} from '../../../_services/configuration-services';
import {AppConstants, AppRoles} from '../../../app.constants';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {NotificationsService} from '../../../_shared/notifications.service';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {CustomerConfigService} from '../../../_shared/customer-config-service';
import {FormUtils} from '../../../_shared/form-utils';
import {DateUtils} from '../../../_shared/date-utils';
import {CustomDateFormatter} from '../../../_shared/custom-date-formatter';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
    selector: 'app-manage-survey',
    templateUrl: './manage-survey.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class ManageSurveyComponent implements OnInit {

    @ViewChild(RoleManagementComponent) roleComponent: RoleManagementComponent;
    @ViewChild('publicationSection', {static: false}) publicationSection: ElementRef;

    surveyId: string | null;

    documentManagementTypeEnum: typeof DocumentManagementTypeEnum = DocumentManagementTypeEnum;
    InformationState = InformationState;

    surveyForm: UntypedFormGroup;
    illustrationForm: UntypedFormGroup;
    publicationForm: UntypedFormGroup;

    customRoles: RoleDto[] = [];
    basicRoles: RoleDto[];

    private order = 0;
    surveyPublicationState: InformationState | null = null;
    usersAlreadyNotifiedForSurvey = false;
    usersAlreadyNotifiedForResults = false;

    supportedImage = AppConstants.supportedImageType;
    imageMaxSizeInKB = AppConstants.communicationChannelImageMaxSizeInKB;

    constructor(private readonly confirmService: ConfirmModalService,
                private readonly roleService: RoleService,
                private readonly notificationsService: NotificationsService,
                private readonly surveyService: SurveyService,
                private readonly route: ActivatedRoute,
                private readonly router: Router,
                private readonly location: Location,
                public readonly customerConfigService: CustomerConfigService) {

        this.surveyForm = new UntypedFormGroup({
            titleFR: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
            titleDE: new UntypedFormControl(null, Validators.maxLength(50)),
            titleIT: new UntypedFormControl(null, Validators.maxLength(50)),
            titleEN: new UntypedFormControl(null, Validators.maxLength(50)),

            descriptionFR: new UntypedFormControl(null, [Validators.required, Validators.maxLength(2500)]),
            descriptionDE: new UntypedFormControl(null, Validators.maxLength(2500)),
            descriptionIT: new UntypedFormControl(null, Validators.maxLength(2500)),
            descriptionEN: new UntypedFormControl(null, Validators.maxLength(2500)),

            resultsContentFR: new UntypedFormControl(null, Validators.maxLength(2500)),
            resultsContentDE: new UntypedFormControl(null, Validators.maxLength(2500)),
            resultsContentIT: new UntypedFormControl(null, Validators.maxLength(2500)),
            resultsContentEN: new UntypedFormControl(null, Validators.maxLength(2500)),

            surveyUrl: new UntypedFormControl(null, [Validators.required, FormUtils.urlValidator]),
            rolesForm: new UntypedFormGroup({
                baseRolesForm: new UntypedFormArray([]),
                customRolesForm: new UntypedFormArray([], {updateOn: 'change'})
            }),
        });

        this.illustrationForm = new UntypedFormGroup({
            file: new UntypedFormControl(null),
            fileData: new UntypedFormControl(null),
            illustration: new UntypedFormControl(null)
        });

        this.publicationForm = new UntypedFormGroup({
            period: new UntypedFormGroup({
                from: new UntypedFormControl(null, [FormUtils.datePatternValidator, Validators.required]),
                to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]),
            resultsPeriod: new UntypedFormGroup({
                from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]),
            mailNotificationEnabled: new UntypedFormControl(true),
        });
    }

    ngOnInit(): void {
        this.surveyId = this.route.snapshot.params['id'] ?? null;

        this.roleService.getAllRoles(false).pipe().subscribe(res => {
            this.customRoles = res.filter(r => !r.isBase);
            this.basicRoles = res.filter(r => r.roleKey === AppRoles.user);
            if (this.surveyId) {
                this.surveyService.getSurvey(this.surveyId).pipe().subscribe(res => this.fillForm(res));
            } else {
                this.initializeRolesForm();
            }
        });
    }

    private initializeRolesForm(): void {
        this.customRoles.forEach(role => this.customRolesFormArray.push(
            new UntypedFormControl(false)
        ));

        this.basicRoles.forEach(role => this.baseRolesFormArray.push(
            new UntypedFormControl(false)
        ));
    }

    saveSurvey(): void {
        const survey = new SurveyInputDto({
            order: this.order,

            titleFR: this.titleFR.value,
            titleDE: this.titleDE.value,
            titleIT: this.titleIT.value,
            titleEN: this.titleEN.value,

            descriptionFR: this.descriptionFR.value,
            descriptionDE: this.descriptionDE.value,
            descriptionIT: this.descriptionIT.value,
            descriptionEN: this.descriptionEN.value,

            resultContentFR: this.resultsContentFR.value,
            resultContentDE: this.resultsContentDE.value,
            resultContentIT: this.resultsContentIT.value,
            resultContentEN: this.resultsContentEN.value,

            surveyUrl: this.surveyUrl.value,

            roles: this.roleComponent.getSelectedRoles()
        });

        if (!this.surveyId) {
            this.surveyService.createSurvey(survey).pipe().subscribe(surveyId => {
                this.notificationsService.success({title: 'communicationChannel.survey.notification.addSuccess'});
                this.router.navigate(['../' + surveyId], {relativeTo: this.route, replaceUrl: true});
            });

        } else {
            this.surveyService.updateSurvey(this.surveyId, survey).pipe().subscribe(() => {
                this.notificationsService.success({title: 'communicationChannel.survey.notification.updateSuccess'});
                this.publicationForm.markAsPristine();
            });
        }
    }

    publishSurvey(): void {
        const from = DateUtils.ngbDateStructToDate(this.startDate.value);
        from?.setHours(0, 0, 0, 0);
        const to = DateUtils.ngbDateStructToDate(this.endDate.value);
        to?.setHours(23, 59, 59, 59);

        const resultsFrom = DateUtils.ngbDateStructToDate(this.resultsStartDate.value);
        resultsFrom?.setHours(0, 0, 0, 0);
        const resultsTo = DateUtils.ngbDateStructToDate(this.resultsEndDate.value);
        resultsTo?.setHours(23, 59, 59, 59);

        const publishSurvey = new SurveyPublishInputDto({
            publicationDate: from,
            endPublicationDate: to,
            resultsPublicationDate: resultsFrom,
            resultsEndPublicationDate: resultsTo,
            mailNotificationEnabled: this.mailNotificationEnabled.value
        });

        this.surveyService.publishSurvey(this.surveyId, publishSurvey).pipe().subscribe(survey => {
            this.notificationsService.success({title: 'communicationChannel.survey.notification.updateSuccess'});
            this.fillForm(survey);
            this.publicationForm.markAsPristine();
        });
    }

    deleteSurvey(): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.surveyService.deleteSurvey(this.surveyId).pipe().subscribe(() => {
                    this.notificationsService.success({title: 'communicationChannel.survey.notification.deleteSuccess'});
                    this.back();
                });
            }
        });
    }

    saveIllustration(): void {
        if (this.fileData.value) {
            const file = {
                data: this.fileData.value,
                fileName: this.fileData.value.name
            };
            this.illustrationForm.reset();
            this.surveyService.uploadIllustration(this.surveyId, file).pipe().subscribe(res => {
                this.notificationsService.success({title: 'communicationChannel.survey.notification.imageAddSuccess'});
                this.illustration.setValue(res.illustrationUrl);
            });
        }
    }

    fileSelected(files: FileList): void {
        if (files.length > 0) {
            const fileData = files[0];
            const size = fileData.size;
            if ((size / 1024) > this.imageMaxSizeInKB) {
                this.file.setErrors({overSizeLimitKb: {max: this.imageMaxSizeInKB}});
            } else {
                this.file.setErrors(null);
                this.fileData.patchValue(fileData);
            }
        }
    }

    private fillForm(survey: SurveyDto): void {
        this.surveyPublicationState = survey.surveyState;
        this.usersAlreadyNotifiedForSurvey = survey.publicationNotificationSent;
        this.usersAlreadyNotifiedForSurvey = survey.resultPublicationNotificationSent;
        this.order = survey.order;

        this.titleFR.setValue(survey.titleFR);
        this.titleDE.setValue(survey.titleDE);
        this.titleIT.setValue(survey.titleIT);
        this.titleEN.setValue(survey.titleEN);

        this.descriptionFR.setValue(survey.descriptionFR);
        this.descriptionDE.setValue(survey.descriptionDE);
        this.descriptionIT.setValue(survey.descriptionIT);
        this.descriptionEN.setValue(survey.descriptionEN);

        this.resultsContentFR.setValue(survey.resultContentFR);
        this.resultsContentDE.setValue(survey.resultContentDE);
        this.resultsContentIT.setValue(survey.resultContentIT);
        this.resultsContentEN.setValue(survey.resultContentEN);

        this.mailNotificationEnabled.setValue(survey.mailNotificationEnabled);

        this.surveyUrl.setValue(survey.surveyUrl);

        this.illustration.setValue(survey.resultIllustrationUrl);

        this.startDate.setValue(DateUtils.dateToNgbDateStruct(survey.publicationDate));
        if (survey.endPublicationDate) {
            this.endDate.setValue(DateUtils.dateToNgbDateStruct(survey.endPublicationDate));
        }
        if (survey.resultsPublicationDate) {
            this.resultsStartDate.setValue(DateUtils.dateToNgbDateStruct(survey.resultsPublicationDate));
        }
        if (survey.resultsEndPublicationDate) {
            this.resultsEndDate.setValue(DateUtils.dateToNgbDateStruct(survey.resultsEndPublicationDate));
        }

        this.customRolesFormArray.clear();
        this.customRoles.forEach(role => this.customRolesFormArray.push(
            new UntypedFormControl(survey.roles ? survey.roles.find(value => value.roleKey === role.roleKey) != null : false)
        ));
        this.baseRolesFormArray.clear();
        this.basicRoles.forEach(role => this.baseRolesFormArray.push(
            new UntypedFormControl(survey.roles ? survey.roles.find(value => value.roleKey === role.roleKey) != null : false)
        ));

        // Scroll down the page to display publication form
        if (survey.publicationDate === null) {
            setTimeout(() => this.publicationSection.nativeElement.scrollIntoView(), 0);
        }
    }

    back(): void {
        this.location.back();
    }

    get titleFR(): UntypedFormControl {
        return this.surveyForm.get('titleFR') as UntypedFormControl;
    }

    get titleDE(): UntypedFormControl {
        return this.surveyForm.get('titleDE') as UntypedFormControl;
    }

    get titleIT(): UntypedFormControl {
        return this.surveyForm.get('titleIT') as UntypedFormControl;
    }

    get titleEN(): UntypedFormControl {
        return this.surveyForm.get('titleEN') as UntypedFormControl;
    }

    get descriptionFR(): UntypedFormControl {
        return this.surveyForm.get('descriptionFR') as UntypedFormControl;
    }

    get descriptionDE(): UntypedFormControl {
        return this.surveyForm.get('descriptionDE') as UntypedFormControl;
    }

    get descriptionIT(): UntypedFormControl {
        return this.surveyForm.get('descriptionIT') as UntypedFormControl;
    }

    get descriptionEN(): UntypedFormControl {
        return this.surveyForm.get('descriptionEN') as UntypedFormControl;
    }

    get resultsContentFR(): UntypedFormControl {
        return this.surveyForm.get('resultsContentFR') as UntypedFormControl;
    }

    get resultsContentDE(): UntypedFormControl {
        return this.surveyForm.get('resultsContentDE') as UntypedFormControl;
    }

    get resultsContentIT(): UntypedFormControl {
        return this.surveyForm.get('resultsContentIT') as UntypedFormControl;
    }

    get resultsContentEN(): UntypedFormControl {
        return this.surveyForm.get('resultsContentEN') as UntypedFormControl;
    }

    get surveyUrl(): UntypedFormControl {
        return this.surveyForm.get('surveyUrl') as UntypedFormControl;
    }

    get rolesFormArray(): UntypedFormGroup {
        return this.surveyForm.get('rolesForm') as UntypedFormGroup;
    }

    get baseRolesFormArray(): UntypedFormArray {
        return this.rolesFormArray.get('baseRolesForm') as UntypedFormArray;
    }

    get customRolesFormArray(): UntypedFormArray {
        return this.rolesFormArray.get('customRolesForm') as UntypedFormArray;
    }

    get file(): UntypedFormControl {
        return this.illustrationForm.get('file') as UntypedFormControl;
    }

    get fileData(): UntypedFormControl {
        return this.illustrationForm.get('fileData') as UntypedFormControl;
    }

    get illustration(): UntypedFormControl {
        return this.illustrationForm.get('illustration') as UntypedFormControl;
    }

    get periodForm(): UntypedFormGroup {
        return this.publicationForm.get('period') as UntypedFormGroup;
    }

    get startDate(): UntypedFormControl {
        return this.periodForm.get('from') as UntypedFormControl;
    }

    get endDate(): UntypedFormControl {
        return this.periodForm.get('to') as UntypedFormControl;
    }

    get resultsPeriodForm(): UntypedFormGroup {
        return this.publicationForm.get('resultsPeriod') as UntypedFormGroup;
    }

    get resultsStartDate(): UntypedFormControl {
        return this.resultsPeriodForm.get('from') as UntypedFormControl;
    }

    get resultsEndDate(): UntypedFormControl {
        return this.resultsPeriodForm.get('to') as UntypedFormControl;
    }

    get mailNotificationEnabled(): UntypedFormControl {
        return this.publicationForm.get('mailNotificationEnabled') as UntypedFormControl;
    }

}

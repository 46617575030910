<div class="modal-header">
    <h4 class="modal-title" translate="users.exportModal.title"></h4>

    <button class="btn-close" type="button" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="exportForm">
        <div class="row">
            <app-role-management [enablePublicAccess]="false"
                                 [enableBaseRoles]="true"
                                 [enableCustomRoles]="true"
                                 [userOnly]="false"
                                 [hideCustomIfUserSelected]="false"
                                 [roleForm]="rolesFormArray"
                                 [requiredFields]="false"></app-role-management>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>

    <button type="submit" (click)="exportUsers()" class="btn btn-primary"
            [disabled]="exportForm.invalid" translate="users.exportModal.actions.export">
    </button>
</div>

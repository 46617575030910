import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as d3 from 'd3';
import {
    MapDto,
    MapProjectionDto,
    MapService
} from '../../../_services/configuration-services';
import {firstValueFrom} from 'rxjs';
import {MapUtils} from '../../../_shared/map-utils';

@Component({
    selector: 'app-manage-map-content',
    templateUrl: './manage-map-content.component.html'
})
export class ManageMapContentComponent implements OnInit {

    @Input() serviceId: string;
    @Input() map: MapDto;
    @Input() items: MapContent[] = [];

    @Output() selectedItemEvent: EventEmitter<MapContent> = new EventEmitter();

    selectedItem: MapContent | null = null;

    createMode = false;
    dragMode = false;

    private mainContainer;
    private projectionMn95ToSvg: MapProjectionDto;
    private projectionSvgToMn95: MapProjectionDto;

    constructor(private readonly mapService: MapService) {
    }

    async ngOnInit(): Promise<void> {
        await this.setupMap();
        await this.populateMapWithItems();
    }

    startDragMode(): void {
        this.dragMode = true;
    }

    startCreateMode(): void {
        this.createMode = true;
        this.setupMapClickListener();
    }

    async resetMapAndStopEditMode(items: MapContent[] | null = null): Promise<void> {
        this.createMode = false;
        this.dragMode = false;

        this.selectedItem = null;
        if (items) {
         this.items = items;
        }

        this.removeMapClickListener();
        await this.populateMapWithItems();
    }

    emitSelectedItem(): void {
        this.selectedItemEvent.emit(this.selectedItem);
        this.selectedItem = null;
    }

    private async setupMap(): Promise<void> {
        this.mainContainer = d3.select('#d3_map');
        const g = this.mainContainer.append('g');
        const mapData = await d3.xml(this.map.mapUrl);
        g.node().appendChild(mapData.documentElement);
        const d3Map = g.select('svg');
        const w = d3Map.attr('width');
        const h = d3Map.attr('height');

        this.projectionMn95ToSvg = await firstValueFrom(this.mapService.computeProjectionMn95ToSvg(w, h));
        this.projectionSvgToMn95 = await firstValueFrom(this.mapService.computeProjectionSvgToMn95(w, h));

        const zoom = d3.zoom()
            .scaleExtent([0.1, 30])
            .on('zoom', (event) => {
                g.attr('transform', event.transform);
            });

        this.mainContainer.call(zoom);

        zoom.scaleTo(this.mainContainer, 0.2);
        zoom.translateTo(this.mainContainer, w / 2, h / 2);

        d3.select('#zoom-in').on('click', () => {
            zoom.scaleBy(this.mainContainer.transition().duration(750), 1.5);
        });

        d3.select('#zoom-out').on('click', () => {
            zoom.scaleBy(this.mainContainer.transition().duration(750), 1 / 1.5);
        });
    }

    private async populateMapWithItems(): Promise<void> {
        const p = this.projectionMn95ToSvg;

        this.mainContainer.select('g').select('svg').selectAll('.coord').remove();
        this.mainContainer.select('g').select('svg').selectAll('.coord')
            .data(this.items)
            .enter()
            .append('text')
            .attr('x', (d) => MapUtils.xPositionForMapContent(d, p))
            .attr('y', (d) => MapUtils.yPositionForMapContent(d, p))
            .attr('dy', '0.5em') // Center the label vertically
            .attr('class', 'coord')
            .text((d) => d.displayName)
            .style('fill', 'black')
            .on('click', async (event, d) => {
                const item = d3.select(event.currentTarget);
                event.stopPropagation();

                // Ignore when clicking on another place
                if (this.selectedItem) {
                    return;
                }
                this.selectedItem = structuredClone(d);

                if (this.dragMode) { // Allow to drag the place
                    item.style('fill', 'red');

                    let deltaX: number;
                    let deltaY: number;

                    let currentSelection: d3.Selection<SVGTextElement, MapContent, SVGGElement, unknown>;

                    const dragHandler = d3.drag()
                        .on('start', (event) => {
                            currentSelection = d3.select(event.sourceEvent.target);

                            deltaX = currentSelection.raise().attr('x') as any - event.x;
                            deltaY = currentSelection.raise().attr('y') as any - event.y;

                        })
                        .on('drag', (event) => {
                            currentSelection.raise()
                                .attr('x', event.x + deltaX)
                                .attr('y', event.y + deltaY);

                            const projection = this.projectionSvgToMn95;
                            const point = {
                                longitude: (event.x + deltaX),
                                latitude: (event.y + deltaY)
                            };
                            const mn95X = MapUtils.xPositionForMapContent(point, projection);
                            const mn95Y = MapUtils.yPositionForMapContent(point, projection);

                            this.selectedItem.latitude = Math.round(mn95Y * 1e4) / 1e4;
                            this.selectedItem.longitude = Math.round(mn95X * 1e4) / 1e4;
                        });

                    dragHandler(item);

                } else {
                    this.emitSelectedItem();
                }
            });
    }

    private setupMapClickListener(): void {
        const map = this.mainContainer.select('g').select('svg');
        this.mainContainer.on('click', (event) => {
            const [x, y] = d3.pointer(event, map.node());
            const point = {
                longitude: x,
                latitude: y
            };

            const projection = this.projectionSvgToMn95;
            let mn95X = MapUtils.xPositionForMapContent(point, projection);
            let mn95Y = MapUtils.yPositionForMapContent(point, projection);
            mn95Y = Math.round(mn95Y * 1e4) / 1e4;
            mn95X = Math.round(mn95X * 1e4) / 1e4;

            this.selectedItem = {
                latitude: mn95Y,
                longitude: mn95X,
                displayName: null,
                id: null
            }
            this.emitSelectedItem();
        });
    }

    private removeMapClickListener(): void {
        this.mainContainer.on('click', null);
    }
}

export interface MapContent {
    longitude: number;
    latitude: number;
    displayName?: string;
    id?: string;
}

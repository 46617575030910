<div class="modal-header">
    <h4 class="modal-title" translate>
        {{(!officeHourException?.id ? 'officeHours.exceptions.modal.add.title' : 'officeHours.exceptions.modal.update.title') }}
    </h4>

    <button class="btn-close" aria-label="Close"
            (click)="officeHourExceptionModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="officeHourExceptionForm">
        <div class="row">

            <div class="col-12">
                <app-date-period-picker [required]="true"
                                        [fromMinDate]="today"
                                        [toMinDate]="today"
                                        [periodControl]="exceptionPeriod"></app-date-period-picker>
            </div>

            <div class="col-6">
                <label class="form-label"
                       for="fromHour">{{ 'officeHours.opening.modal.form.startHour' | translate }}*</label>
                <ngb-timepicker id="fromHour" [formControl]="startHour"></ngb-timepicker>
                <app-form-error [control]="startHour"></app-form-error>
            </div>
            <div class="col-6">
                <label class="form-label"
                       for="toHour">{{ 'officeHours.opening.modal.form.endHour' | translate }}*</label>
                <ngb-timepicker id="toHour" [formControl]="endHour"></ngb-timepicker>
                <app-form-error [control]="endHour"></app-form-error>
            </div>

            <div *ngIf="!isExceptionOpeningHourValid()">
                <small class="text-danger" translate="officeHours.opening.modal.form.errors.invalidHour"></small>
            </div>

        </div>
    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="officeHourExceptionModal.close()" translate="common.cancel">
    </button>
    <button type="submit" *ngIf="!!officeHourException?.id" (click)="updateOfficeHourException()"
            class="btn btn-primary"
            [disabled]="officeHourExceptionForm.invalid"
            translate="common.modify">
    </button>
    <button type="submit" *ngIf="!officeHourException?.id" (click)="createOfficeHourException()" class="btn btn-primary"
            [disabled]="officeHourExceptionForm.invalid"
            translate="common.add">
    </button>
</div>

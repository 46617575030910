import {AfterViewInit, Component, Input} from '@angular/core';
import {
    MapDto,
    MapProjectionDto,
    MapService,
    MooringPlaceDto,
    MooringPlaceService,
    UserService
} from '../../../../_services/configuration-services';
import * as d3 from 'd3';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MooringPlaceViewModalComponent} from '../../../../_shared/_components/mooring-place-view-modal/mooring-place-view-modal.component';
import {firstValueFrom} from 'rxjs';
import {MapUtils} from '../../../../_shared/map-utils';

@Component({
    selector: 'app-view-mooring-place',
    templateUrl: './view-mooring-place.component.html'
})
export class ViewMooringPlaceComponent implements AfterViewInit {

    @Input() serviceId: string;

    private map: MapDto;
    private mainContainer: any;
    private projection: MapProjectionDto;

    private mooringPlaces: MooringPlaceDto[];

    constructor(
        private readonly mooringPlaceService: MooringPlaceService,
        private readonly userService: UserService,
        private readonly mapService: MapService,
        private readonly modalService: NgbModal) {
    }

    async ngAfterViewInit(): Promise<void> {
        this.map = await firstValueFrom(this.mapService.getMap(this.serviceId));

        await this.setupMap();
        await this.refreshData();
    }

    async refreshData(): Promise<void> {
        this.mooringPlaces = await firstValueFrom(this.mooringPlaceService.getAllMooringPlaces());

        this.populateMapWithMooringPlaces();
    }

    private async showDetails(id): Promise<void> {
        const mp = await firstValueFrom(this.mooringPlaceService.getMooringPlace(id));
        let user = null;
        if (mp.userId) {
            user = await firstValueFrom(this.userService.getUser(mp.userId));
        }
        const modal = this.modalService.open(MooringPlaceViewModalComponent, {centered: true});
        modal.componentInstance.mooringPlace = mp;
        modal.componentInstance.user = user;

        modal.result.then(res => {
            if (res === 'success') {
                this.refreshData();
            }
        });
    }

    private async setupMap(): Promise<void> {
        this.mainContainer = d3.select('#d3_map');
        const g = this.mainContainer.append('g');
        const mapData = await d3.xml(this.map.mapUrl);
        g.node().appendChild(mapData.documentElement);
        const d3Map = g.select('svg');
        const w = d3Map.attr('width');
        const h = d3Map.attr('height');

        this.projection = await firstValueFrom(this.mapService.computeProjectionMn95ToSvg(w, h));

        const zoom = d3.zoom()
            .scaleExtent([0.1, 30])
            .on('zoom', (event) => {
                g.attr('transform', event.transform);
            });

        zoom.scaleTo(this.mainContainer, 0.2);
        zoom.translateTo(this.mainContainer, w / 2, h / 2);

        d3.select('#zoom-in').on('click', () => {
            zoom.scaleBy(this.mainContainer.transition().duration(750), 1.5);
        });

        d3.select('#zoom-out').on('click', () => {
            zoom.scaleBy(this.mainContainer.transition().duration(750), 1 / 1.5);
        });

        this.mainContainer.call(zoom);
    }

    private populateMapWithMooringPlaces(): void {
        const p = this.projection;
        this.mainContainer.select('g').select('svg').selectAll('.coord').remove();
        this.mainContainer.select('g').select('svg').selectAll('.coord')
            .data(this.mooringPlaces)
            .enter()
            .append('text')
            .attr('x', (d) => MapUtils.xPositionForMapContent(d, p))
            .attr('y', (d) => MapUtils.yPositionForMapContent(d, p))
            .attr('dy', '0.5em') // Center the label vertically
            .attr('lat', (d) => d.latitude)
            .attr('lon', (d) => d.longitude)
            .attr('class', (d) => d.sublet ? 'coord bold orange' : 'coord')
            .text((d) => d.placeNum)
            .on('click', async (event, d) => {
                event.stopPropagation();
                this.showDetails(d.placeId);
            });
    }
}


<div class="content-container bg-white-no-shadow">

    <h4 translate="services.userAlarm.subscriptions.title"></h4>

    <div class="btn-bar">
        <button class="btn btn-primary" *ngIf="serviceActive && !!tariff"
                (click)="addAlarmSubscription()"
                translate="services.userAlarm.subscriptions.buttons.add">
        </button>
    </div>

    <ngx-datatable #subscriptionTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="subscriptions"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   rowHeight="50"
                   [count]="subscriptions.length"
                   [sorts]="[{prop: 'StartDateTime', dir: 'desc'}]"
                   [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                }"
                   [scrollbarH]="true">

        <ngx-datatable-column [width]="225" prop="iotRegister.label{{crtLang.toUpperCase()}}" sortable="true"
                              name="{{'services.userAlarm.subscriptions.table.iotRegister' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="225" prop="designation" sortable="true"
                              name="{{'services.userAlarm.subscriptions.table.designation' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="100" prop="startDateTime" sortable="true"
                              name="{{'services.userAlarm.subscriptions.table.startDateTime' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.startDateTime | date:displayDateFormat}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="100" prop="endDateTime" sortable="true"
                              name="{{'services.userAlarm.subscriptions.table.endDateTime' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.endDateTime | date:displayDateFormat}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="50" prop="active" sortable="true"
                              name="{{'services.userAlarm.subscriptions.table.active' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                <button *ngIf="row.active" class="btn btn-link text-success"
                        title="{{'common.tooltip.deactivate' | translate}}"
                        (click)="deactivateSubscription(row)">
                    <fa-icon [icon]="icons.toggleOn"></fa-icon>
                </button>
                <button *ngIf="!row.active" class="btn btn-link text-danger"
                        title="{{'common.tooltip.activate' | translate}}"
                        (click)="activateSubscription(row)">
                    <fa-icon [icon]="icons.toggleOff"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="75" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <button class="btn btn-link btn-sm" *ngIf="!!tariff"
                        title="{{ 'common.tooltip.edit' | translate }}"
                        (click)="editSubscription(row)">
                    <fa-icon [icon]="icons.edit"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm" *ngIf="!row.endDateTime"
                        title="{{ 'common.tooltip.terminate' | translate }}"
                        (click)="terminateSubscription(row, terminateSubscriptionConfirmModal)">
                    <fa-icon [icon]="icons.delete"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>

    <app-user-alarm-logs [serviceId]="serviceId"></app-user-alarm-logs>

</div>


<!-- Terminate modal -->
<ng-template #terminateSubscriptionConfirmModal let-c="close" let-d="dismiss">

    <div class="modal-header">
        <h4 class="modal-title" translate="common.confirmModal.title.termination"></h4>

        <button class="btn-close" aria-label="Close"
                (click)="d('Cross click')">
        </button>
    </div>

    <div class="modal-body">
        <p [translateParams]="{ date: terminateModalContent.terminationDate | date:displayDateFormat }"
           translate="services.userAlarm.subscriptions.terminationDate"></p>
        <p translate="services.actions.terminateModal.confirmText"></p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary"
                (click)="c('cancel')"
                translate="common.no">
        </button>
        <button type="button" class="btn btn-primary"
                (click)="c('confirm')"
                translate="common.yes">
        </button>
    </div>

</ng-template>

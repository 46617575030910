import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    BookingDto,
    BookingLightDto,
    BookingMode,
    BookingService,
    BookingServiceConfigDto,
    BookingServiceConfigService,
    BookingSlotDto, BookingSlotState,
    ItemToBookDto,
    ReservationType,
    TariffDto
} from '../../../_services/configuration-services';
import {AppRoles} from '../../../app.constants';
import {firstValueFrom, Subscription} from 'rxjs';
import {RolesService} from '../../../_shared/roles-service';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {TranslateService} from '@ngx-translate/core';
import {FreeBookingCalendarComponent} from './free-booking-calendar/free-booking-calendar.component';
import {SlotsCalendarComponent} from './slots-calendar/slots-calendar.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    BookingSlotManagementModalComponent
} from './slots-calendar/booking-slot-management-modal/booking-slot-management-modal.component';
import {DateUtils} from '../../../_shared/date-utils';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {NotificationsService} from '../../../_shared/notifications.service';
import {
    BookingFormRecurringPartialCancelModalComponent
} from "./booking-form/booking-form-recurring-partial-cancel-modal/booking-form-recurring-partial-cancel-modal.component";
import {BookingUtils} from '../../../_shared/booking-utils';

@Component({
  selector: 'app-booking-cockpit',
  templateUrl: './booking-cockpit.component.html'
})
export class BookingCockpitComponent implements OnInit, OnDestroy {

    @Input() serviceId: string = null!;
    @Input() tariff: TariffDto = null!;
    @Input() items = new Array<ItemToBookDto>();

    @ViewChild('freeBookingCalendarComponent') freeBookingCalendarComponent!: FreeBookingCalendarComponent;
    @ViewChild('slotsCalendarComponent') slotsCalendarComponent!: SlotsCalendarComponent;
    @ViewChild('buttonBar') buttonBar: ElementRef; // Used to scroll to the top of the cockpit

    AppRoles = AppRoles;
    BookingMode = BookingMode;
    BookingUtils = BookingUtils;
    DateUtils = DateUtils;
    ReservationType = ReservationType;

    bookingServiceConfig: BookingServiceConfigDto = null!;

    userId: string | null = null; // null for visitors context (even if logged user is Admin or Harbourmaster)

    selectedDate: Date | null = null;
    selectedBooking: BookingDto | null = null;
    selectedSlot: BookingSlotDto | null = null;
    selectedItemId: string | null = null;

    hasAdminRoleForBooking = false;

    isFormOpen = false;

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription = null!;

    constructor(private readonly bookingServiceConfigService: BookingServiceConfigService,
                private readonly bookingService: BookingService,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly rolesService: RolesService,
                private readonly confirmService: ConfirmModalService,
                private readonly modalService: NgbModal) {

    }

    async ngOnInit(): Promise<void> {
        this.hasAdminRoleForBooking = this.rolesService.hasRoleHarbourMaster() || this.rolesService.hasRoleAdmin();
        this.userId = this.hasAdminRoleForBooking ? null : this.rolesService.getUserId();

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                }
            });

        await this.fetchBookingConfig();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    async confirmAndDeleteBooking(): Promise<void> {
        let modalTitleKey = 'common.confirmModal.title.cancel';
        let modalMessageKey = this.selectedBooking.reservationType === ReservationType.Booking ?
            'common.confirmModal.message.deleteBooking' : 'common.confirmModal.message.deleteBlocking';

        await this.confirmService.confirm({
            titleKey: modalTitleKey,
            messageKey: modalMessageKey
        }).then(async result => {
            if (result === ConfirmModalService.yes) {

                if (this.selectedBooking.reservationType === ReservationType.Blocking) {
                    await firstValueFrom(this.bookingService.deleteBlocking(this.selectedBooking.id));
                    this.notificationsService.success({title: 'bookings.notifications.cancelBlockingSuccess'});

                } else if (this.hasAdminRoleForBooking) {
                    await firstValueFrom(this.bookingService.cancelBooking(this.selectedBooking.id));
                    this.notificationsService.success({title: 'bookings.notifications.cancelBookingSuccess'});

                } else {
                    await firstValueFrom(this.bookingService.cancelBookingForUser(this.selectedBooking.id));
                    this.notificationsService.success({title: 'bookings.notifications.cancelBookingSuccess'});
                }

                await this.refreshCalendar();
                this.closeForm();
            }
        });
    }

    openPartialCancelFormModal(): void {
        if (!this.selectedBooking) {
            return;
        }

        const modal = this.modalService.open(BookingFormRecurringPartialCancelModalComponent, {
            centered: true
        });

        modal.componentInstance.booking = this.selectedBooking;
        modal.componentInstance.hasAdminRoleForBooking = this.hasAdminRoleForBooking;

        modal.result
            .then(async result => {
                if (result === 'confirm') {
                    await this.refreshCalendar();
                    this.closeForm();
                }
            }, () => { /* catch the rejection */
            });

    }

    initFormWithStartDate(date: Date = null): void {
        this.selectedBooking = null;
        this.selectedSlot = null;

        this.selectedDate = date ?? DateUtils.dateRoundedToNextThirtyMinutes(new Date());
        this.isFormOpen = true;
        this.scrollToTheTopOfComponent();
    }

    async refreshCalendar(): Promise<void> {
        if (this.bookingServiceConfig.bookingMode === BookingMode.Slots) {
            await this.slotsCalendarComponent.fetchBookingSlots();

        } else {
            await this.freeBookingCalendarComponent.fetchBookings();
        }
    }

    async selectSlot(slot: BookingSlotDto): Promise<void> {
        if (this.isFormOpen) {
            if (slot.state === BookingSlotState.Free) {
                // Update the selected slot of the booking in creation/modification
                this.selectedSlot = slot;
                this.scrollToTheTopOfComponent();
            }

        } else if (this.hasAdminRoleForBooking) {
            // Open the modal to view/edit slot
            const modal = this.modalService.open(BookingSlotManagementModalComponent, {
                centered: true
            });
            modal.componentInstance.selectedSlot = slot;
            modal.componentInstance.bookingConfig = this.bookingServiceConfig;
            modal.componentInstance.hasAdminRoleForBooking = this.hasAdminRoleForBooking;
            modal.result
                .then(async result => {
                    if (result) {
                        this.selectedSlot = result.selectedSlot;
                        if (result.selectedSlot.booking) {
                            this.selectedBooking = await this.fetchBooking(result.selectedSlot.booking.id);
                        }

                        if (result.action === 'openForm') {
                            this.isFormOpen = true;
                        }
                        this.scrollToTheTopOfComponent();

                    } else {
                        await this.refreshCalendar();
                    }

                }, () => { /* catch the rejection */ });

        } else if (slot.booking) {
            // Display the booking of the selected slot
            this.selectedBooking = await this.fetchBooking(slot.booking.id);
            this.selectedSlot = slot;
            this.scrollToTheTopOfComponent();

        } else {
            // Create a booking based on the selected slot
            this.selectedSlot = slot;
            this.selectedBooking = null;
            this.isFormOpen = true;
        }
    }

    async selectBooking(booking: BookingLightDto): Promise<void> {
        let shouldOpenForm = false;

        if (!this.isFormOpen) {
            let selectedBooking = await this.fetchBooking(booking.id);

            // Directly open the form in case of blocking period
            if (booking.reservationType === ReservationType.Blocking) {
                shouldOpenForm = true;
            }

            // Directly open the form in case of booking moved in free calendar
            if (!BookingUtils.areBookingStartingTogether(booking, selectedBooking)) {
                // Change the date and time of the selectedBooking
                selectedBooking.startDate = booking.startDate;
                selectedBooking.endDate = booking.endDate;
                selectedBooking.localStartTime = booking.localStartTime;
                selectedBooking.localEndTime = booking.localEndTime;

                shouldOpenForm = true

            } else {
                // We can't edit a booking from past
                if (!BookingUtils.canEditOrCancelBooking(selectedBooking, this.bookingServiceConfig, this.hasAdminRoleForBooking)) {
                    shouldOpenForm = false;

                    if (selectedBooking.reservationType === ReservationType.Blocking) {
                        // No need to open a blocking period from past
                        return;
                    }
                }
            }

            this.selectedBooking = selectedBooking;
            this.isFormOpen = shouldOpenForm;
            this.scrollToTheTopOfComponent();
        }
    }

    selectDate(date: Date): void {
        if (!this.isFormOpen) {
            this.initFormWithStartDate(date);
        }
    }

    selectItem(itemId: string | null):void {
        if (!this.isFormOpen) {
            this.selectedItemId = itemId;
        }
    }

    closeForm(): void {
        this.isFormOpen = false;

        this.selectedDate = null;
        this.selectedBooking = null;
        this.selectedSlot = null;
        this.selectedItemId = null;
    }

    private async fetchBookingConfig(): Promise<void> {
        this.bookingServiceConfig = await firstValueFrom(this.bookingServiceConfigService.getBookingServiceConfigForService(this.serviceId));
    }

    private async fetchBooking(bookingId: string): Promise<BookingDto> {
        if (this.hasAdminRoleForBooking) {
            return await firstValueFrom(this.bookingService.getBooking(bookingId));

        } else {
            return await firstValueFrom(this.bookingService.getMyBooking(bookingId));
        }
    }

    private scrollToTheTopOfComponent(): void {
        this.buttonBar.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
}

import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {
    CraningBillingService,
    CraningBookingDto,
    CraningBookingService,
    CraningBookingSlotDto,
    CraningBookingSlotItemDto,
    CraningBookingSlotState,
    ServiceDto
} from '../../../../_services/configuration-services';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CraningBookingSlotsGenerationModalComponent} from './craning-booking-slots-generation-modal/craning-booking-slots-generation-modal.component';
import {CraningBookingSlotsDeletionModalComponent} from './craning-booking-slots-deletion-modal/craning-booking-slots-deletion-modal.component';
import {AppRoles} from 'src/app/app.constants';
import {CraningBookingSlotManagementModalComponent} from './craning-booking-slot-management-modal/craning-booking-slot-management-modal.component';
import {RolesService} from '../../../../_shared/roles-service';
import {CraningBookingCalendarComponent} from './craning-booking-calendar/craning-booking-calendar.component';
import {CraningBillingEvent, CraningBookingFormMode, CraningBookingUtils} from './craning-booking-utils';
import * as fileSaver from 'file-saver';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {DateUtils} from '../../../../_shared/date-utils';
import {ConfirmModalService} from '../../../../_shared/_components/confirm-modal/confirm-modal.component';
import {TranslateUtils} from '../../../../_shared/translate-utils';

@Component({
    selector: 'app-craning-booking',
    templateUrl: './craning-booking.component.html'
})
export class CraningBookingComponent implements OnInit, OnDestroy {

    @Input() service: ServiceDto;

    @ViewChild('buttonBar') buttonBar: ElementRef;
    @ViewChild('calendar') calendar: CraningBookingCalendarComponent;

    crtLang = TranslateUtils.defaultLanguage;
    crtEditingSlotId: number | null = null; // Used to know the original slot when editing
    selectedSlot: CraningBookingSlotDto | null = null;
    selectedBooking: CraningBookingDto | null = null;

    AppRoles = AppRoles;
    CraningBookingSlotState = CraningBookingSlotState;
    CraningBookingFormMode = CraningBookingFormMode;

    formMode = CraningBookingFormMode.Hidden;

    isVisitor = true;

    private langChangeSubscription: Subscription;

    constructor(
        private readonly translateService: TranslateService,
        private readonly notificationService: NotificationsService,
        private readonly rolesService: RolesService,
        public readonly craningBookingUtils: CraningBookingUtils,
        private readonly craningBookingService: CraningBookingService,
        private readonly craningBillingService: CraningBillingService,
        private readonly confirmService: ConfirmModalService,
        private readonly modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.isVisitor = this.rolesService.isVisitor();

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                }
            });
        this.fetchMyNextCraningBookingSlot();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    cancelBooking(): void {
        this.confirmService.confirm({
            titleKey: 'common.confirmModal.title.cancel',
            messageKey: 'common.confirmModal.message.noDeposit'
        }).then(result => {
            if (result === ConfirmModalService.yes) {
                if (this.craningBookingUtils.hasAdminView()) {
                    this.craningBookingService.deleteForAdmin(this.selectedBooking.id).pipe().subscribe(_ => {
                        this.notificationService.success({title: 'services.craning.notifications.cancelBookingSuccess'});
                        this.calendar?.refetchCalendarEvents();
                        this.hideForm();
                    });

                } else {
                    this.craningBookingService.delete(this.selectedBooking.id).pipe().subscribe(_ => {
                        this.notificationService.success({title: 'services.craning.notifications.cancelBookingSuccess'});
                        this.calendar?.refetchCalendarEvents();
                        this.hideForm();
                    });
                }
            }
        });
    }

    printBooking(): void {
        this.craningBookingUtils.printCraningBooking(this.service.serviceId, this.selectedBooking.id);
    }

    downloadBillReport(): void {
        if (this.selectedBooking.craningBillingId) {
            this.craningBillingService.downloadCraningBillingReport(this.selectedBooking.craningBillingId)
                .pipe().subscribe(response => fileSaver.saveAs(response.data, response.fileName));
        }
    }

    craningBookingSaved(slot: CraningBookingSlotDto): void {
        this.calendar?.refetchCalendarEvents();
        if (this.craningBookingUtils.hasAdminView()) {
            let displayMode = CraningBookingFormMode.DisplayReservation;
            if (this.formMode === CraningBookingFormMode.BillingReservation) {
                displayMode = CraningBookingFormMode.BillingReservation;
            }
            this.fetchBookingForMode(slot.id, displayMode);

        } else {
            this.fetchMyNextCraningBookingSlot();
        }
    }

    craningBookingBilled(craningBillingEvent: CraningBillingEvent): void {
        this.calendar?.refetchCalendarEvents();
        this.selectedSlot.state = CraningBookingSlotState.Paid;
        this.fetchBookingForMode(craningBillingEvent.slotId, CraningBookingFormMode.DisplayReservation);
    }

    selectSlot(selectedSlot: CraningBookingSlotItemDto): void {
        if (this.craningBookingUtils.hasAdminView()) {
            this.selectSlotForAdmin(selectedSlot);

        } else {
            this.selectSlotForUserAndVisitor(selectedSlot);
        }
    }

    selectSlotForAdmin(selectedSlot: CraningBookingSlotItemDto): void {
        switch (this.formMode) {
            // Select a slot
            case CraningBookingFormMode.Hidden:
            case CraningBookingFormMode.DisplayReservation:
            case CraningBookingFormMode.BillingReservation:

                if (selectedSlot.state === CraningBookingSlotState.Free || selectedSlot.state === CraningBookingSlotState.Blocked ||
                    this.craningBookingUtils.isSlotValidToEdit(selectedSlot)) {

                    this.formMode = CraningBookingFormMode.Hidden;
                    this.openSlotManagementModal(selectedSlot);

                } else {
                    this.selectedSlot = selectedSlot;
                    this.fetchBookingForMode(selectedSlot.id, CraningBookingFormMode.DisplayReservation);
                }
                break;

            // Change the slot during creating/editing a new booking
            case CraningBookingFormMode.NewReservation:
                if (selectedSlot.state === CraningBookingSlotState.Free) {
                    this.selectedSlot = selectedSlot;
                    this.buttonBar.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});

                } else {
                    this.notificationService.warning({title: 'services.craning.notifications.slotNotAvailableToBook'});
                }
                break;

            case CraningBookingFormMode.UpdateReservation:
                if (selectedSlot.state === CraningBookingSlotState.Free ||
                    (selectedSlot.state === CraningBookingSlotState.Booked && selectedSlot.id === this.crtEditingSlotId)) {

                    this.selectedSlot = selectedSlot;
                    this.buttonBar.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});

                } else {
                    this.notificationService.warning({title: 'services.craning.notifications.slotNotAvailableToBook'});
                }
                break;

            default:
                break;
        }
    }

    selectSlotForUserAndVisitor(selectedSlot: CraningBookingSlotItemDto): void {
        switch (this.formMode) {
            case CraningBookingFormMode.Hidden:
            case CraningBookingFormMode.NewReservation:
            case CraningBookingFormMode.UpdateReservation:
                if (this.craningBookingUtils.isSlotAvailableToBook(selectedSlot)){
                    this.selectedSlot = selectedSlot;
                    this.buttonBar.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});

                    if (this.formMode === CraningBookingFormMode.Hidden) {
                        this.formMode = CraningBookingFormMode.NewReservation;
                    }

                } else {
                    this.notificationService.warning({title: 'services.craning.notifications.slotNotAvailableToBook'});
                }
                break;

            case CraningBookingFormMode.DisplayReservation:
                if (selectedSlot.isMine) {
                    this.selectedSlot = selectedSlot;
                    this.buttonBar.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});

                } else if (selectedSlot.state === CraningBookingSlotState.Free) {
                    this.notificationService.warning({title: 'services.craning.notifications.alreadyHaveBookingToCome'});

                } else {
                    this.notificationService.warning({title: 'services.craning.notifications.slotInfoNotAvailable'});
                }
                break;
            default:
                break;
        }
    }

    hideForm(): void {
        this.formMode = CraningBookingFormMode.Hidden;
        this.selectedSlot = null;
        this.selectedBooking = null;
        this.fetchMyNextCraningBookingSlot();
    }

    openCreateMode(): void {
        this.crtEditingSlotId = null;
        if (this.selectedSlot) {
            this.fetchBookingForMode(this.selectedSlot.id, CraningBookingFormMode.NewReservation);

        } else {
            this.formMode = CraningBookingFormMode.NewReservation;
        }
    }

    openUpdateMode(): void {
        this.crtEditingSlotId = this.selectedSlot.id;
        this.fetchBookingForMode(this.selectedSlot.id, CraningBookingFormMode.UpdateReservation);
    }

    openBillingMode(): void {
        this.crtEditingSlotId = null;
        this.fetchBookingForMode(this.selectedSlot.id, CraningBookingFormMode.BillingReservation);
    }

    openDisplayMode(): void {
        this.crtEditingSlotId = null;
        this.fetchBookingForMode(this.selectedSlot.id, CraningBookingFormMode.DisplayReservation);
    }

    canDisplayCloseFormButton(): boolean {
        return (this.formMode !== CraningBookingFormMode.Hidden && this.craningBookingUtils.hasAdminView()) ||
            this.formMode === CraningBookingFormMode.UpdateReservation || this.formMode === CraningBookingFormMode.NewReservation;
    }

    canDisplayCancelBookingButton(): boolean {
        return this.craningBookingUtils.isSlotValidToEdit(this.selectedSlot) && !!this.selectedBooking;
    }

    canDisplayPrintBookingButton(): boolean {
        return this.craningBookingUtils.hasAdminView() &&
            this.formMode === CraningBookingFormMode.DisplayReservation;
    }

    canDisplayModifyButton(): boolean {
        return this.formMode === CraningBookingFormMode.DisplayReservation &&
            this.craningBookingUtils.isSlotValidToEdit(this.selectedSlot);
    }

    canDisplayBillButton(): boolean {
        return this.formMode === CraningBookingFormMode.DisplayReservation &&
            this.selectedSlot.state === CraningBookingSlotState.Booked &&
            DateUtils.isInPast(this.selectedSlot.date);
    }

    canDisplayDownloadButton(): boolean {
        return this.craningBookingUtils.hasAdminView() &&
            this.formMode === CraningBookingFormMode.DisplayReservation &&
            this.selectedSlot.state === CraningBookingSlotState.Paid;
    }

    canDisplayCalendar(): boolean {
        if (this.formMode === CraningBookingFormMode.UpdateReservation && !this.craningBookingUtils.hasAdminView()) {
            return false;
        }
        return true;
    }

    openSlotGenerationModal(): void {
        const modal = this.modalService.open(CraningBookingSlotsGenerationModalComponent, {
            centered: true
        });
        modal.componentInstance.serviceId = this.service.serviceId;
        modal.result
            .then(_ => {
                this.calendar?.refetchCalendarEvents();
            }, () => { /* catch the rejection */ });
    }

    openSlotDeletionModal(): void {
        const modal = this.modalService.open(CraningBookingSlotsDeletionModalComponent, {
            centered: true
        });
        modal.componentInstance.serviceId = this.service.serviceId;
        modal.result
            .then(_ => {
                this.calendar?.refetchCalendarEvents();
            }, () => { /* catch the rejection */ });
    }

    private openSlotManagementModal(selectedSlot: CraningBookingSlotItemDto): void {
        this.selectedSlot = null;
        this.selectedBooking = null;

        const modal = this.modalService.open(CraningBookingSlotManagementModalComponent, {
            centered: true
        });
        modal.componentInstance.selectedSlot = selectedSlot;
        modal.result
            .then(result => {
                this.calendar?.refetchCalendarEvents();

                if (!!result && this.craningBookingUtils.hasAdminView()) {
                    this.selectedSlot = result.selectedSlot;

                    switch (result.action) {
                        case 'create':
                            this.openCreateMode();
                            break;
                        case 'edit':
                            this.openUpdateMode();
                            break;
                        case 'display':
                            this.openDisplayMode();
                            break;
                    }
                }
            }, () => { /* catch the rejection */ });
    }

    private fetchBookingForMode(slotId: number, mode: CraningBookingFormMode): void {
        let craningBookingSlotObservable: Observable<CraningBookingSlotDto>;
        if (this.craningBookingUtils.hasAdminView()) {
            craningBookingSlotObservable = this.craningBookingService.getForBookingSlotForAdmin(slotId);
        } else {
            craningBookingSlotObservable = this.craningBookingService.getForBookingSlot(slotId);
        }

        craningBookingSlotObservable.pipe().subscribe(booking => {
            this.selectedBooking = booking;
            this.formMode = mode;
            this.buttonBar.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
        });
    }

    private fetchMyNextCraningBookingSlot(): void {
        if (!this.craningBookingUtils.hasAdminView() && !this.isVisitor) {
            this.craningBookingService.getMyNextCraningBookingSlotForService(this.service.serviceId).pipe().subscribe(slot => {
                if (!!slot) {
                    this.selectedSlot = slot;
                    this.fetchBookingForMode(slot.id, CraningBookingFormMode.DisplayReservation);
                }
            });
        }
    }


}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    MeasuringPointLightDto,
    MeasuringPointService,
    MeasuringPointUsageState
} from '../../../_services/configuration-services';
import {TranslateService} from "@ngx-translate/core";
import {TranslateUtils} from "../../../_shared/translate-utils";
import {Subscription} from "rxjs";
import {RolesService} from '../../../_shared/roles-service';

@Component({
    selector: 'app-measuring-points-cockpit',
    templateUrl: './measuring-points-cockpit.component.html'
})
export class MeasuringPointsCockpitComponent implements OnInit, OnDestroy {

    @Input() serviceId!: string;

    @Output() activateMeasuringPoint = new EventEmitter<string>();

    public measuringPoints: MeasuringPointLightDto[];

    MeasuringPointUsageState = MeasuringPointUsageState;

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription;

    pagination = {
        page: 1,
        maxMeasuringPoints: 10
    }

    constructor(
        private readonly translateService: TranslateService,
        private readonly rolesService: RolesService,
        private readonly measuringPointService: MeasuringPointService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                this.crtLang = this.translateService.currentLang;
            });
        this.fetchMeasuringPoints();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    isAdmin(): boolean {
        return this.rolesService.hasRoleAdmin();
    }

    reloadData(): void {
        this.fetchMeasuringPoints();
    }

    private fetchMeasuringPoints(): void {
        this.measuringPointService.getAdminMeasuringPointsForService(this.serviceId, this.crtLang)
            .pipe().subscribe(value => this.measuringPoints = value);
    }

}

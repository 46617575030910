<h4 translate="services.chargingStations.title"></h4>

<div class="btn-bar">
    <button class="btn btn-primary" [appHasAnyRole]="[AppRoles.admin]"
            (click)="createChargingStation()"
            translate="services.chargingStations.actions.add">
    </button>
</div>


<div class="table-responsive" [appHasAnyRole]="[AppRoles.admin]">
    <table class="table table-striped"
           [attr.aria-label]="'services.chargingStations.title' | translate">
        <thead>

            <tr>
                <th scope="col" style="min-width:150px" translate="services.chargingStations.table.deviceId"></th>
                <th scope="col" style="min-width:225px" translate="services.chargingStations.table.name"></th>
                <th scope="col" style="min-width:300px" translate="services.chargingStations.table.description"></th>
                <th scope="col" style="min-width:100px"></th>
            </tr>

        </thead>

        <tbody>

            <tr *ngIf="chargingStations.length === 0">
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td></td>
            </tr>

            <tr *ngFor="let chargingStation of chargingStations;">
                <td>{{ chargingStation.externalId }}</td>
                <td>{{ chargingStation['name' + crtLang.toUpperCase()] }}</td>
                <td>{{ chargingStation['description' + crtLang.toUpperCase()] }}</td>

                <td class="text-end">
                    <button class="btn btn-link btn-sm" type="button"
                            (click)="updateChargingStation(chargingStation)"
                            title="{{ 'common.tooltip.edit' | translate }}">
                        <fa-icon [icon]="icons.edit"></fa-icon>
                    </button>

                    <button class="btn btn-link btn-sm" type="button"
                            (click)="deleteChargingStation(chargingStation)"
                            title="{{ 'common.tooltip.delete' | translate }}">
                        <fa-icon [icon]="icons.delete"></fa-icon>
                    </button>
                </td>
            </tr>

        </tbody>
    </table>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {
    ActivationService,
    ImpulseDurationUnit,
    ServiceService
} from '../../../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {FormUtils} from '../../../../_shared/form-utils';
import {RolesService} from '../../../../_shared/roles-service';

@Component({
    selector: 'app-extend-activation-modal',
    templateUrl: './extend-activation-modal.component.html'
})
export class ExtendActivationModalComponent implements OnInit {

    @Input() serviceId!: string;
    @Input() activationId: string;

    activationUnit: ImpulseDurationUnit;

    FormUtils = FormUtils;

    extendForm: UntypedFormGroup;

    constructor(public activeModal: NgbActiveModal,
                private readonly notificationsService: NotificationsService,
                private readonly serviceService: ServiceService,
                private readonly activationService: ActivationService,
                private readonly rolesService: RolesService) {

        this.extendForm = new UntypedFormGroup({
            impulseDuration: new UntypedFormControl({ value: null, disabled: true }),
            maxImpulseDuration: new UntypedFormControl({ value: null, disabled: true }),
            extendedImpulseDuration: new UntypedFormControl(null)
        }, {updateOn: 'change'});
    }

    ngOnInit(): void {
        this.serviceService.getService(this.serviceId).pipe().subscribe(service => {
            this.activationUnit = service.impulseDurationUnit;

            this.impulseDuration.setValue(service.impulseDuration);
            this.maxImpulseDuration.setValue(service.maxImpulseDuration);

            if (this.rolesService.hasRoleAdmin()) {
                this.extendedImpulseDuration.setValidators([Validators.required, Validators.min(service.impulseDuration)]);
            } else {
                this.extendedImpulseDuration.setValidators([Validators.required,
                    Validators.min(service.impulseDuration), Validators.max(service.maxImpulseDuration)]);
            }
        });
    }

    extendActivation(): void {
        if (this.extendForm.invalid){
            return;
        }

        this.activationService.extendActivation(this.activationId, this.extendedImpulseDuration.value).pipe().subscribe(() => {
            this.notificationsService.success({
                title: 'activations.extendedSuccessMessage',
            });
            this.activeModal.close();
        });
    }

    get impulseDuration(): UntypedFormControl {
        return this.extendForm.get('impulseDuration') as UntypedFormControl;
    }

    get maxImpulseDuration(): UntypedFormControl {
        return this.extendForm.get('maxImpulseDuration') as UntypedFormControl;
    }

    get extendedImpulseDuration(): UntypedFormControl {
        return this.extendForm.get('extendedImpulseDuration') as UntypedFormControl;
    }
}

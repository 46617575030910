import {Component, Input, OnInit} from '@angular/core';
import {AccessControlService, MorpheanDoorGroupDto} from '../../../_services/configuration-services';
import {DoorGroupModalComponent} from './door-group-modal/door-group-modal.component';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {NotificationsService} from '../../../_shared/notifications.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-door-group',
    templateUrl: './door-group.component.html'
})
export class DoorGroupComponent implements OnInit {

    @Input() serviceId: string;

    doorGroups: MorpheanDoorGroupDto[] = [];

    icons = {
        delete: faTrash
    };

    constructor(private readonly accessControlService: AccessControlService,
                private readonly notificationsService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                private readonly modalService: NgbModal) { }

    ngOnInit(): void {
        this.fetchGroups();
    }

    addDoorGroups(): void {
        const modal = this.modalService.open(DoorGroupModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.result.then(async res => {
            if (res === 'create') {
                this.notificationsService.success({title: 'services.doorGroups.notifications.addSuccess'});
                this.fetchGroups();
            }
        });
    }

    async deleteDoorGroups(doorGroup: MorpheanDoorGroupDto): Promise<void> {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.accessControlService.unassignDoorGroup(doorGroup.doorGroupId).pipe().subscribe(_ => {
                    this.notificationsService.success({title: 'services.doorGroups.notifications.deleteSuccess'});
                    this.fetchGroups();
                });
            }
        });
    }

    private fetchGroups(): void {
        this.accessControlService.getDoorGroupsForService(this.serviceId).pipe().subscribe(doorGroups => {
            this.doorGroups = doorGroups;
        });
    }

}

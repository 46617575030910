import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {TranslateUtils} from "../_shared/translate-utils";
import {environment} from "../../environments/environment";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-forget-me',
    templateUrl: './forget-me.component.html',
    styleUrls: ['./forget-me.component.scss']
})
export class ForgetMeComponent implements OnInit, OnDestroy {

    private langChangeSubscription: Subscription;

    crtLang = TranslateUtils.defaultLanguage;

    clientEmail = environment.cgu.email;

    constructor(private readonly translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;

        });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

}

import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    CraningBookingSlotService,
} from '../../../../../_services/configuration-services';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {FormUtils} from '../../../../../_shared/form-utils';
import {DateUtils} from '../../../../../_shared/date-utils';
import {CustomDateFormatter} from '../../../../../_shared/custom-date-formatter';

@Component({
    selector: 'app-craning-booking-slots-deletion-modal',
    templateUrl: './craning-booking-slots-deletion-modal.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class CraningBookingSlotsDeletionModalComponent implements OnInit {

    @Input() serviceId: string;

    craningBookingSlotsDeletionForm: FormGroup;

    minDate: NgbDateStruct;

    DateUtils = DateUtils;

    constructor(public craningBookingSlotsDeletionModal: NgbActiveModal,
                private readonly craningBookingSlotService: CraningBookingSlotService,
                private readonly notificationsService: NotificationsService) {
    }

    ngOnInit(): void {

        this.craningBookingSlotsDeletionForm = new UntypedFormGroup({
            datePeriod: new UntypedFormGroup({
                from: new UntypedFormControl(null, [Validators.required, FormUtils.datePatternValidator]),
                to: new UntypedFormControl(null, [Validators.required, FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator])
        }, {updateOn: 'change'});

        this.minDate = DateUtils.dateToNgbDateStruct(new Date());
    }

    get datePeriod(): UntypedFormGroup {
        return this.craningBookingSlotsDeletionForm.get('datePeriod') as UntypedFormGroup;
    }

    get startDate(): UntypedFormControl {
        return this.datePeriod.get('from') as UntypedFormControl;
    }

    get endDate(): UntypedFormControl {
        return this.datePeriod.get('to') as UntypedFormControl;
    }

    deleteCraningBookingSlots(): void {
        if (!this.craningBookingSlotsDeletionForm.valid) {
            return;
        }

        this.craningBookingSlotService.deleteCraningBookingSlotGroup(
            this.serviceId,
            DateUtils.ngbDateStructToDate(this.startDate.value),
            DateUtils.ngbDateStructToDate(this.endDate.value))
            .pipe()
            .subscribe(result => {
                this.notificationsService.success({
                    title: 'services.craning.notifications.deleteCraningBookingSlotsSuccess',
                    interpolateParams: {numberOfDeletedSlots: result}
                });
                this.craningBookingSlotsDeletionModal.close('success');
            });
    }
}

import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent{

    @Input() control: AbstractControl | null = null;
    @Input() invalidKey: string | null = null; // Can force the error message

  constructor() { }

}

import {Injectable} from '@angular/core';
import {MapProjectionDto} from '../_services/configuration-services';

@Injectable({
    providedIn: 'root'
})
export class MapUtils {

    public static xPositionForMapContent(point: any, projection: MapProjectionDto): number {
        return point.longitude * projection.x1 + point.latitude * projection.x2 + projection.x3;
    }

    public static yPositionForMapContent(point: any, projection: MapProjectionDto): number {
        return point.longitude * projection.x4 + point.latitude * projection.x5 + projection.x6;
    }

}

import {Component, Input, OnInit} from '@angular/core';
import {CraningBookingSlotDto, PaymentTypes, TariffDto} from '../../../../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CraningBookingFormMode, CraningBookingUtils} from '../../craning-booking-utils';
import {NotificationsService} from '../../../../../../_shared/notifications.service';
import {RolesService} from '../../../../../../_shared/roles-service';
import {DateUtils} from '../../../../../../_shared/date-utils';

@Component({
    selector: 'app-craning-booking-form-confirmation-modal',
    templateUrl: './craning-booking-form-confirmation-modal.component.html'
})
export class CraningBookingFormConfirmationModalComponent implements OnInit {

    @Input() selectedSlot: CraningBookingSlotDto;
    @Input() tariffDeposit: TariffDto;
    @Input() totalPrice: number;
    @Input() isVisitorCraningBooking: boolean;
    @Input() mode: CraningBookingFormMode;


    craningBookingFormConfirmationModalForm: UntypedFormGroup;

    availablePaymentTypes: PaymentTypes[] = [];

    DateUtils = DateUtils;
    CraningBookingFormMode = CraningBookingFormMode;
    confirmBillingViewEnable = false;

    constructor(
        public craningBookingFormConfirmationModal: NgbActiveModal,
        private readonly craningBookingUtils: CraningBookingUtils,
        private readonly rolesService: RolesService,
        private readonly notificationsService: NotificationsService) {

        this.craningBookingFormConfirmationModalForm = new UntypedFormGroup({
                paymentType: new UntypedFormControl('', Validators.required)
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        this.configurePaymentTypes();
    }

    configurePaymentTypes(): void {
        this.availablePaymentTypes = this.tariffDeposit.paymentTypes;

        if (this.craningBookingUtils.hasAdminView()) {
            // Remove E-Wallet for administrator
            this.removePaymentType(PaymentTypes.Ebanking);
            if ( this.isVisitorCraningBooking) {
                // Remove E-Wallet for visitor
                this.removePaymentType(PaymentTypes.Wallet);
            }

        } else if (!this.rolesService.getUserId()) {
            // Remove E-Wallet for visitor
            this.removePaymentType(PaymentTypes.Wallet);
        }

        if (!this.craningBookingUtils.hasAdminView()) {
            // Remove Cash for everyone else than admin
            this.removePaymentType(PaymentTypes.Cash);
        }

        if (this.availablePaymentTypes.length === 0) {
            this.notificationsService.warning({
                title: 'epayment.notifications.warningBookingTitle',
                message: 'epayment.notifications.noPaymentType'
            });
        }

        if (this.availablePaymentTypes.length === 1) {
            this.paymentType.setValue(this.availablePaymentTypes[0]);
        }

        if (this.mode !== CraningBookingFormMode.NewReservation) {
            this.paymentType.disable();
            this.paymentType.setValue(null);
        }
    }

    book(): void {
        if (this.craningBookingFormConfirmationModalForm.invalid) {
            return;
        }
        if (this.paymentType.value === PaymentTypes.Billing && !this.confirmBillingViewEnable) {
            this.toggleConfirmBillingView();
        } else {
            this.craningBookingFormConfirmationModal.close({
                action: 'confirm',
                depositPaymentType: this.paymentType.value
            });
        }
    }

    toggleConfirmBillingView(){
        this.confirmBillingViewEnable = !this.confirmBillingViewEnable;
    }

    selectPaymentType(paymentType: PaymentTypes): void {
        this.paymentType.setValue(paymentType);
    }

    get paymentType(): UntypedFormControl {
        return this.craningBookingFormConfirmationModalForm.get('paymentType') as UntypedFormControl;
    }

    private removePaymentType(paymentType: PaymentTypes): void {
        const idx = this.availablePaymentTypes.indexOf(paymentType);
        if (idx > -1) {
            this.availablePaymentTypes.splice(idx, 1);
        }
    }
}

<div class="content-container bg-white">

    <h1>
        <span *ngIf="TransactionType.ServiceConsumption === transactionType" translate="transactions.enums.types.serviceconsumption"></span>
        <span *ngIf="TransactionType.ServiceConsumption !== transactionType" translate="transactions.enums.types.ewalletcredit"></span>
    </h1>

    <div class="btn-bar">
        <button class="btn btn-primary" [appHasAnyRole]="[AppRoles.cashier]"
                (click)="importTransactions()"
                translate="transactions.filter.import">
        </button>

        <div ngbDropdown [appHasAnyRole]="[AppRoles.cashier]">
            <button class="btn btn-primary" ngbDropdownToggle
                    translate="transactions.filter.export">
            </button>
            <div ngbDropdownMenu>
                <button ngbDropdownItem
                        (click)="exportTransactions(FileFormat.Word)"
                        translate="common.toWordFormat"></button>
                <button ngbDropdownItem
                        (click)="exportTransactions(FileFormat.Excel)"
                        translate="common.toExcelFormat"></button>
            </div>
        </div>

    </div>

    <!-- Filters -->
    <div [formGroup]="filterForm">

        <div class="row">

            <!-- Date -->
            <div class="default-filter-form-field" *ngIf="!!validityPeriod">
                <app-date-period-picker [periodControl]="validityPeriod"
                                        inputLabel="transactions.filter.date"></app-date-period-picker>
            </div>

            <!-- Amount Filter -->
            <div class="default-filter-form-field" *ngIf="!!amountRange && !!amountFrom && !!amountTo">

                <div [formGroup]="amountRange">
                    <label class="form-label" translate="transactions.filter.amount"></label>
                    <div class="input-group mb-2">
                        <span class="input-group-text" translate="transactions.filter.amountFrom"></span>
                        <input class="form-control" id="amountFrom" name="amountFrom" type="number"
                               [formControl]="amountFrom"
                               (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                               placeholder="{{ 'transactions.filter.amountFrom' | translate }}">
                        <span class="input-group-text">CHF</span>
                    </div>
                    <app-form-error [control]="amountFrom"></app-form-error>

                    <div class="input-group mb-2">
                        <span class="input-group-text" translate="transactions.filter.amountTo"></span>
                        <input class="form-control" id="amountTo" name="amountTo" type="number"
                               [formControl]="amountTo"
                               (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                               placeholder="{{ 'transactions.filter.amountTo' | translate }}">
                        <span class="input-group-text">CHF</span>
                    </div>
                    <app-form-error [control]="amountTo"></app-form-error>
                    <app-form-error [control]="amountRange"></app-form-error>
                </div>
            </div>

            <!-- Transaction Source Filter -->
            <div class="default-filter-form-field" *ngIf="!!transactionSource">
                <label class="form-label" translate="transactions.filter.transactionSource"></label>
                <ng-multiselect-dropdown
                    placeholder="{{'common.multiselect.placeholder' | translate}}"
                    [settings]="dropdownSettings"
                    [data]="transactionSources"
                    [formControl]="transactionSource">
                </ng-multiselect-dropdown>
            </div>

            <!-- CashMachine Payment Methods -->
            <div class="default-filter-form-field" *ngIf="TransactionType.EWalletCredit === transactionType && !!cashMachinePaymentMethod && isCashMachinePayementMethodSelected()">
                <label class="form-label" translate="transactions.filter.paymentMethod"></label>
                <ng-multiselect-dropdown
                    placeholder="{{'common.multiselect.placeholder' | translate}}"
                    [settings]="dropdownSettings"
                    [data]="cashMachinePaymentMethods"
                    [formControl]="cashMachinePaymentMethod">
                </ng-multiselect-dropdown>
            </div>

            <!-- Consumption Type -->
            <div class="default-filter-form-field" *ngIf="TransactionType.ServiceConsumption === transactionType && !!consumptionType">
                <label class="form-label" translate="transactions.filter.consumptionType"></label>
                <ng-multiselect-dropdown
                    placeholder="{{'common.multiselect.placeholder' | translate}}"
                    [settings]="dropdownSettings"
                    [data]="consumptionTypes"
                    [formControl]="consumptionType">
                </ng-multiselect-dropdown>
            </div>

            <!-- Service Filter -->
            <div class="default-filter-form-field" *ngIf="TransactionType.ServiceConsumption === transactionType">
                <label class="form-label" translate="transactions.filter.service"></label>
                <ng-multiselect-dropdown
                    placeholder="{{'common.multiselect.placeholder' | translate}}"
                    [formControl]="service"
                    [settings]="dropdownSettings"
                    [data]="servicesDropdownList">
                </ng-multiselect-dropdown>
            </div>

            <!-- User Filter -->
            <div class="default-filter-form-field" [appHasAnyRole]="[AppRoles.cashier]">
                <label class="form-label" translate="transactions.filter.user"></label>
                <app-search-user #searchUserComponent
                                 (selectedUserEvent)="user.setValue($event)"></app-search-user>
            </div>

            <!-- ePayment Filter -->
            <div class="default-filter-form-field" *ngIf="isEpaymentSelected()">
                <label class="form-label" translate="transactions.filter.epayment"></label>
                <input class="form-control" type="text"
                       maxlength="50"
                       placeholder="{{ 'transactions.filter.epayment' | translate }}"
                       [formControl]="epayment">
            </div>

        </div>

        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   (click)="clearFilter()">

            <input class="btn btn-primary" type="button" (click)="filterTransaction()"
                   [value]="'transactions.filter.transactions' | translate"
                   [disabled]="filterForm.invalid">
        </div>

    </div>

    <ngx-datatable #myTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="transactions.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [columns]="columns"
                   [reorderable]="false"
                   [rowHeight]="'100%'"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="transactions.rowCount"
                   [sorts]="[{prop: 'dateTime', dir: 'desc'}]"
                   [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                }"
                   [offset]="crtPage"
                   [limit]="transactions.pageSize"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [scrollbarH]="true">

        <!-- Date -->
        <ngx-datatable-column [width]="140" prop="dateTime" name="{{'transactions.table.date' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.dateTime | date:dateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <!-- Transaction Source -->
        <ngx-datatable-column [width]="125" prop="transactionSource" name="{{'transactions.table.source' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ ('transactions.enums.sources.' + row.transactionSource).toLowerCase() | translate}}
            </ng-template>
        </ngx-datatable-column>

        <!-- Payment Method -->
        <ngx-datatable-column [width]="140" [prop]="'paymentType'"
                              name="{{'common.terms.paymentType' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{row.ePaymentMethod || ((!!row.paymentMethod) ? (('transactions.enums.payments.' + row.paymentMethod).toLowerCase() | translate) : "")}}
            </ng-template>
        </ngx-datatable-column>

        <!-- User -->
        <ngx-datatable-column [width]="160" prop="user" name="{{'transactions.table.user' | translate}}"
                              *ngIf="isCashier()"
                              sortable="true">
        </ngx-datatable-column>

        <!-- Service -->
        <ngx-datatable-column [width]="140" sortable="true" prop="serviceName{{ crtLang.toUpperCase() }}"
                              *ngIf="TransactionType.ServiceConsumption === transactionType"
                              name="{{'transactions.table.service' | translate}}">
        </ngx-datatable-column>

        <!-- Amount -->
        <ngx-datatable-column [width]="120" prop="amount" name="{{'transactions.table.amount' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                <fa-icon [icon]="icons.arrowCircleUp" *ngIf="row.amount > 0"
                         class="arrow-circle text-primary"></fa-icon>
                <fa-icon [icon]="icons.arrowCircleDown" *ngIf="row.amount < 0"
                         class="arrow-circle text-danger"></fa-icon>
                {{ row.amount > 0 ? '+' : '' }}{{ row.amount | currencyFormat }}
            </ng-template>
        </ngx-datatable-column>

        <!-- Bill Download -->
        <ngx-datatable-column [width]="30" [sortable]="false" *ngIf="rolesService.hasRoleCashier()">
            <ng-template ngx-datatable-cell-template let-row="row">
                <button class="btn btn-link btn-sm" [appHasAnyRole]="[AppRoles.cashier]"
                        (click)="downloadReceipt(row.id)"
                        title="{{ 'common.tooltip.receipt' | translate }}">
                    <fa-icon [icon]="icons.receipt"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div class="amount-container" *ngIf="rowCount !== 0">
                    <p>{{'transactions.table.total'| translate}}</p>
                    <strong>{{ transactions.totalAmount | currencyFormat }}</strong>
                </div>
                <div class="page-count">
                    {{ rowCount }} {{ 'common.datatable.total' | translate }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="table.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

    <div class="stat-container" [appHasAnyRole]="[AppRoles.cashier]">
        <h4 translate="ewallet.stats.title"></h4>

        <div class="d-lg-flex flex-row justify-content-end gap-1 mb-2 mb-lg-0">
            <p class="flex-grow-1" translate="ewallet.stats.totalTransactions"></p>
            <strong class="d-block text-nowrap flex-shrink-0 text-end">{{ totalCumulativeTransaction | currencyFormat }}</strong>
        </div>
    </div>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary" type="button"
                (click)="back()" translate="common.back">
        </button>
    </div>

</div>

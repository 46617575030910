<div class="row">

    <!-- firstname -->
    <div class="col-12 col-lg-6">
        <label class="form-label">{{ 'common.contact.firstname' | translate }}*</label>
        <input class="form-control" id="firstname" name="firstname"
               [formControl]="firstname"
               placeholder="{{'common.contact.firstname' | translate}}" maxlength="50">
        <app-form-error [control]="firstname"></app-form-error>
    </div>

    <!-- lastname -->
    <div class="col-12 col-lg-6">
        <label class="form-label">{{ 'common.contact.lastname' | translate }}*</label>
        <input class="form-control" id="lastname" name="lastname"
               [formControl]="lastname"
               placeholder="{{'common.contact.lastname' | translate}}" maxlength="50">
        <app-form-error [control]="lastname"></app-form-error>
    </div>

    <!-- email -->
    <div class="col-12 col-lg-6">
        <label class="form-label">{{ 'common.contact.email' | translate }}*</label>
        <input class="form-control" id="email" name="email"
               [formControl]="email"
               placeholder="{{'common.contact.email' | translate}}" maxlength="75">
        <app-form-error [control]="email"></app-form-error>
    </div>

    <!-- Phone -->
    <div class="col-12 col-lg-6">
        <label class="form-label">{{ 'common.contact.phone' | translate }}*</label>
        <input class="form-control" id="phone" name="phone"
               [formControl]="phone"
               placeholder="{{ 'common.placeholders.phone' | translate}}" maxlength="15">
        <app-form-error [control]="phone"></app-form-error>
    </div>

    <!-- Street -->
    <div class="col-12 col-lg-6">
        <label class="form-label">{{ 'common.contact.street' | translate }}*</label>
        <input class="form-control" id="street" name="street"
               [formControl]="street"
               placeholder="{{'common.contact.street' | translate}}" maxlength="50">
        <app-form-error [control]="street"></app-form-error>
    </div>

    <!-- Postal Code -->
    <div class="col-12 col-lg-6">
        <label class="form-label">{{ 'common.contact.postalCode' | translate }}*</label>
        <input class="form-control" id="postalCode" name="postalCode"
               [formControl]="postalCode"
               placeholder="{{'common.contact.postalCode' | translate}}" maxlength="10">
        <app-form-error [control]="postalCode"></app-form-error>
    </div>

    <!-- City -->
    <div class="col-12 col-lg-6">
        <label class="form-label">{{ 'common.contact.city' | translate }}*</label>
        <input class="form-control" id="city" name="city"
               [formControl]="city"
               placeholder="{{'common.contact.city' | translate}}" maxlength="25">
        <app-form-error [control]="city"></app-form-error>
    </div>

    <!-- Country -->
    <div class="col-12 col-lg-6">
        <label class="form-label">{{ 'common.contact.country' | translate }}*</label>
        <input class="form-control" id="country" name="country"
               [formControl]="country"
               placeholder="{{'common.contact.country' | translate}}" maxlength="25">
        <app-form-error [control]="country"></app-form-error>
    </div>

</div>

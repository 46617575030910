import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    CraningBillingService,
    CraningBookingDto,
    CraningBookingService,
    CraningBookingSlotDto,
    CraningBookingSlotService,
    CraningBookingSlotState,
    CraningBookingToBillDto,
    ServiceDto
} from '../../../../_services/configuration-services';
import {DateUtils} from '../../../../_shared/date-utils';
import {faDollarSign} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as fileSaver from 'file-saver';
import {CraningBillingEvent, CraningBookingFormMode} from '../craning-booking/craning-booking-utils';
import {TranslateUtils} from '../../../../_shared/translate-utils';

@Component({
    selector: 'app-craning-billing',
    templateUrl: './craning-billing.component.html'
})
export class CraningBillingComponent implements OnInit, OnDestroy {

    @Input() service: ServiceDto;

    // Table content
    bookings: CraningBookingToBillDto[] = [];

    selectedSlot: CraningBookingSlotDto | null = null;
    selectedBooking: CraningBookingDto | null = null;

    CraningBookingFormMode = CraningBookingFormMode;
    CraningBookingSlotState = CraningBookingSlotState;

    formMode = CraningBookingFormMode.Hidden;
    bookingDateTimeFormat = DateUtils.dateTimeWithoutSecondFormat;

    icons = {
        open: faDollarSign
    };

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription;

    constructor(private readonly craningBillingService: CraningBillingService,
                private readonly craningBookingSlotService: CraningBookingSlotService,
                private readonly craningBookingService: CraningBookingService,
                private readonly translateService: TranslateService) { }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                }
            });

        this.fetchBookingsToBill();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    displayBookingToBill(slotId: number): void {
        this.craningBookingSlotService.getCraningSlot(slotId).pipe().subscribe(slot => {
            this.selectedSlot = slot;
            this.craningBookingService.getForBookingSlotForAdmin(slot.id).pipe().subscribe(booking => {
                this.selectedBooking = booking;
                this.formMode = CraningBookingFormMode.BillingReservation;
            });
        });
    }

    hideForm(): void {
        this.fetchBookingsToBill();
    }

    fetchBookingsToBill(): void {
        this.craningBillingService.getAllToBillForService(this.service.serviceId).pipe().subscribe(bookings => {
            this.bookings = bookings;
            this.formMode = CraningBookingFormMode.Hidden;
        });
    }

    downloadBillReport(): void {
        if (this.selectedBooking.craningBillingId) {
            this.craningBillingService.downloadCraningBillingReport(this.selectedBooking.craningBillingId).pipe().subscribe(response => {
                fileSaver.saveAs(response.data, response.fileName);
            });
        }
    }

    craningBookingSaved(slot: CraningBookingSlotDto): void {
        this.displayBookingToBill(slot.id);
    }

    craningBookingBilled(billing: CraningBillingEvent): void {
        this.selectedSlot.state = CraningBookingSlotState.Paid;
        this.selectedBooking.craningBillingId = billing.craningBillingDto.id;
        this.formMode = CraningBookingFormMode.DisplayReservation;
    }
}

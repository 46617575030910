<h4 translate="services.doorGroups.title"></h4>

<div class="btn-bar">
    <button class="btn btn-primary"
            (click)="addDoorGroups()"
            translate="services.doorGroups.actions.add">
    </button>
</div>

<div class="table-responsive">
    <table class="table table-striped"
           [attr.aria-label]="'services.doorGroups.title' | translate">
        <thead>
        <tr>
            <th scope="col" style="min-width:800px" translate="services.doorGroups.title"></th>
            <th scope="col" style="min-width:100px"></th>
        </tr>
        </thead>

        <tbody>
        <tr class="mb-5" *ngIf="doorGroups.length === 0">
            <td>-</td>
            <td></td>
        </tr>

        <tr *ngFor="let doorGroup of doorGroups">
            <td>{{doorGroup.name}}</td>

            <td class="text-end">
                <button class="btn btn-link btn-sm"
                        (click)="deleteDoorGroups(doorGroup)"
                        title="{{'common.tooltip.delete' | translate}}">
                    <fa-icon [icon]="icons.delete"></fa-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>

import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
    ConsumptionType, FileFormat,
    ISortCriteriaDto,
    PageableRequestDtoOfTransactionCriteriaDto,
    PagedTransactionsResultDto, PaymentMethod,
    SearchUserDto,
    ServiceService,
    SortCriteriaDto,
    SortDirection,
    TransactionCriteriaDto,
    TransactionSearchCriteriaDto,
    TransactionService,
    TransactionSource,
    TransactionType
} from '../../_services/configuration-services';
import {SearchUserComponent} from '../../_shared/_components/search-user/search-user.component';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {DateUtils} from '../../_shared/date-utils';
import {faArrowCircleDown, faArrowCircleUp, faReceipt} from '@fortawesome/free-solid-svg-icons';
import {TranslateUtils} from '../../_shared/translate-utils';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from '../../_shared/custom-date-formatter';
import {TransactionsImportModalComponent} from '../transactions-import-modal/transactions-import-modal.component';
import {FormUtils} from '../../_shared/form-utils';
import * as fileSaver from 'file-saver';
import {ActivatedRoute} from '@angular/router';
import {AppRoles} from '../../app.constants';
import {RolesService} from '../../_shared/roles-service';

@Component({
    selector: 'app-transaction-common',
    templateUrl: './transaction-common.component.html',
    styleUrls: ['./transaction-common.component.scss'],
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class TransactionCommonComponent implements OnInit, OnDestroy {

    AppRoles = AppRoles;
    TransactionType = TransactionType;
    @Input() transactionType: TransactionType;

    filterForm: UntypedFormGroup;
    selectedUser: SearchUserDto;

    @ViewChild('searchUserComponent') searchUser: SearchUserComponent;
    @ViewChild('myTable') table;

    // DropDowns
    disableSources = [];
    transactionSources = [];
    consumptionTypes = [];
    cashMachinePaymentMethods = [];
    servicesDropdownList = [];
    dropdownSettings: IDropdownSettings = {};

    // DataTable
    columns = [
        // NOTE: cells for current page only !
        {name: 'Name', summaryFunc: cells => `${cells.length} total`},
        {name: 'Gender', summaryFunc: () => '589 CHF'},
        {name: 'Company', summaryFunc: () => null}
    ];
    readonly pageSize = 10;
    crtPage = 0;

    transactions: PagedTransactionsResultDto = new PagedTransactionsResultDto();
    totalCumulativeTransaction: number = 0;

    FormUtils = FormUtils;
    FileFormat = FileFormat;
    dateTimeFormat = DateUtils.dateTimeFormat;

    // Icons
    icons = {
        arrowCircleUp: faArrowCircleUp,
        arrowCircleDown: faArrowCircleDown,
        receipt: faReceipt
    }

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Desc,
        property: 'dateTime'
    });

    private pageRequest: PageableRequestDtoOfTransactionCriteriaDto;

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;


    constructor(private readonly transactionService: TransactionService,
                private readonly location: Location,
                private readonly translateService: TranslateService,
                private readonly serviceService: ServiceService,
                private readonly modalService: NgbModal,
                public readonly formatter: NgbDateParserFormatter,
                private readonly activatedRoute: ActivatedRoute,
                public readonly rolesService: RolesService) {

        this.pageRequest = new PageableRequestDtoOfTransactionCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new TransactionCriteriaDto({
            searchCriteriaDto: new TransactionSearchCriteriaDto({
                userId: null,
                from: null,
                to: null,
                transactionSources: [],
                consumptionTypes: [],
                cashMachinePaymentMethods: [],
                ePaymentMethod: null,
                serviceIds: [],
                amountFrom: null,
                amountTo: null,
                transactionType: null
            }),
            sortCriteriaDto: this.currentSort
        });

        this.filterForm = new UntypedFormGroup({
            validityPeriod: new UntypedFormGroup({
                from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]),
            transactionSource: new UntypedFormControl([]),
            epayment: new UntypedFormControl(),
            consumptionType: new UntypedFormControl([]),
            cashMachinePaymentMethod: new UntypedFormControl([]),
            service: new UntypedFormControl([]),
            user: new UntypedFormControl(),
            amountRange: new UntypedFormGroup({
                min: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
                max: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
            }, [FormUtils.rangeValidator])
        });
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(data => {
            this.transactionType = data['transactionType'];

            if (this.transactionType === TransactionType.EWalletCredit) {
                this.disableSources = [TransactionSource.Billing, TransactionSource.EWallet];
            } else {
                this.disableSources = [TransactionSource.CashMachine];
            }
        });

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
            this.clearFilter();
            this.loadServiceDropDown();
            this.populateEnumDropdowns();
        });

        this.loadServiceDropDown();
        this.populateEnumDropdowns();

        this.pageRequest.criteria.searchCriteriaDto.transactionType = this.transactionType;
        this.fetchTransactions(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    fetchTransactions(pageableRequest: PageableRequestDtoOfTransactionCriteriaDto): void {
        this.filterCheck(pageableRequest.criteria);

        this.transactionService.searchTransactions(pageableRequest).pipe().subscribe(value => {
            this.transactions = value;
        });

        if (this.isCashier()) {

            this.transactionService.getCumulativeTotalTransactions(pageableRequest.criteria.searchCriteriaDto).pipe().subscribe(value => {
                this.totalCumulativeTransaction = value;
            });
        }
    }

    clearFilter(): void {
        // Clear inputs
        this.dateFrom.setValue(null);
        this.dateTo.setValue(null);
        this.service.setValue([]);
        this.consumptionType.setValue([]);
        this.transactionSource.setValue([]);
        this.cashMachinePaymentMethod.setValue([]);
        this.epayment.setValue(null);
        this.amountFrom.setValue(null);
        this.amountTo.setValue(null);
        this.crtPage = 0;
        this.selectedUser = null;
        this.searchUser.clearInput();

        // clear search
        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
            this.pageRequest.criteria = new TransactionCriteriaDto({
            searchCriteriaDto: new TransactionSearchCriteriaDto({
                from: null,
                to: null,
                transactionSources: this.transactionSources.map(it => it.itemId),
                consumptionTypes: [],
                cashMachinePaymentMethods: [],
                ePaymentMethod: null,
                serviceIds: [],
                amountFrom: null,
                amountTo: null,
                userId: null,
                transactionType: this.transactionType
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            })
        });

        this.fetchTransactions(this.pageRequest);
    }

    filterTransaction(): void {
        this.crtPage = 0;

        const dateFrom = DateUtils.ngbDateStructToDate(this.dateFrom.value);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(this.dateTo.value);
        dateTo?.setHours(23, 59, 59, 59);

        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new TransactionCriteriaDto({
            searchCriteriaDto: new TransactionSearchCriteriaDto({
                from: dateFrom,
                to: dateTo,
                transactionSources: this.transactionSource?.value.map(it => it.itemId),
                consumptionTypes: this.consumptionType?.value.map(it => it.itemId),
                ePaymentMethod: this.epayment.value,
                cashMachinePaymentMethods: this.cashMachinePaymentMethod?.value.map(it => it.itemId),
                serviceIds: this.service?.value.map(it => it.itemId),
                amountFrom: !!this.amountFrom.value || this.amountFrom.value === 0 ? Number(this.amountFrom.value) : null,
                amountTo: !!this.amountTo.value || this.amountTo.value === 0 ? Number(this.amountTo.value) : null,
                userId: this.user?.value?.id,
                transactionType: this.transactionType
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            })
        });

        this.fetchTransactions(this.pageRequest);
    }

    importTransactions(): void {
        const modal = this.modalService.open(TransactionsImportModalComponent, {centered: true});
        modal.result.then(res => {
            if (res === 'imported') {
                this.fetchTransactions(this.pageRequest);
            }
        });
    }

    exportTransactions(format: FileFormat): void {

        const dateFrom = DateUtils.ngbDateStructToDate(this.dateFrom.value);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(this.dateTo.value);
        dateTo?.setHours(23, 59, 59, 59);

        const criteria = new TransactionCriteriaDto({
            searchCriteriaDto: new TransactionSearchCriteriaDto({
                from: dateFrom,
                to: dateTo,
                transactionSources: this.transactionSource?.value.map(it => it.itemId),
                consumptionTypes: this.consumptionType?.value.map(it => it.itemId),
                ePaymentMethod: this.epayment.value,
                cashMachinePaymentMethods: this.cashMachinePaymentMethod?.value.map(it => it.itemId),
                serviceIds: this.service?.value.map(it => it.itemId),
                amountFrom: !!this.amountFrom.value ? Number(this.amountFrom.value) : null,
                amountTo: !!this.amountTo.value ? Number(this.amountTo.value) : null,
                userId: this.user?.value?.id,
                transactionType: this.transactionType
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            })
        });
        this.filterCheck(criteria);

        this.transactionService.exportTransactions(this.transactionType, format, criteria).pipe().subscribe(response =>
            fileSaver.saveAs(response.data, response.fileName));
    }

    public downloadReceipt(id: string){
        this.transactionService.generateTransactionReceipt(id).pipe().subscribe(response =>
            fileSaver.saveAs(response.data, response.fileName));
    }

    private filterCheck(criteria: TransactionCriteriaDto): void{
        if (criteria.searchCriteriaDto.transactionSources.length < 1) {
            criteria.searchCriteriaDto.transactionSources = this.transactionSources.map(it => it.itemId)
        }
        if (!this.isEpaymentSelected()) {
            criteria.searchCriteriaDto.ePaymentMethod = null;
        }
        if (!this.isCashMachinePayementMethodSelected()) {
            criteria.searchCriteriaDto.cashMachinePaymentMethods = [];
        }
    }

    private populateEnumDropdowns(): void {
        this.transactionSources = [];
        this.consumptionTypes = [];
        this.cashMachinePaymentMethods = [];

        const transactions = Object.keys(TransactionSource).filter(s => s !== TransactionSource.Unknown);
        transactions.forEach(value => {
            if (!this.disableSources.includes(TransactionSource[value])) {
                this.transactionSources.push({
                    itemId: value,
                    itemVal: this.translateService.instant('transactions.enums.sources.' + value.toLowerCase())
                });
            }
        });

        const consumptions = Object.keys(ConsumptionType);
        consumptions.forEach(value => this.consumptionTypes.push({
            itemId: value,
            itemVal: this.translateService.instant('transactions.enums.consumptions.' + value.toLowerCase())
        }));

        const paymentMethods = Object.keys(PaymentMethod);
        paymentMethods.forEach(value => this.cashMachinePaymentMethods.push({
            itemId: value,
            itemVal: this.translateService.instant('transactions.enums.payments.' + value.toLowerCase())
        }));
    }

    private loadServiceDropDown(): void {
        this.serviceService.getServiceNames().pipe().subscribe(res => {
            this.servicesDropdownList = [];
            const serviceNames = res.sort((a, b) => a['name' + this.crtLang.toUpperCase()].localeCompare(b['name' + this.crtLang.toUpperCase()]));
            serviceNames.forEach(r => this.servicesDropdownList.push({
                itemId: r.serviceId,
                itemVal: r['name' + this.crtLang.toUpperCase()]
            }));
        });

        // Dropdown
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'itemId',
            textField: 'itemVal',
            selectAllText: this.translateService.instant('common.multiselect.selectAll'),
            unSelectAllText: this.translateService.instant('common.multiselect.unselectAll'),
            searchPlaceholderText: this.translateService.instant('common.multiselect.search'),
            noDataAvailablePlaceholderText: this.translateService.instant('common.multiselect.noResults'),
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;
        this.fetchTransactions(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.goToFirstPage();
        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = sortCriteriaDto as SortCriteriaDto;
        this.fetchTransactions(this.pageRequest);
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }

    back(): void {
        this.location.back();
    }

    isCashier(): boolean {
        return this.rolesService.hasRoleCashier();
    }

    isEpaymentSelected(): boolean {
        return !!this.transactionSource && !!this.transactionSource.value
            && (this.transactionSource.value.length < 1 || this.transactionSource.value.some(e => e.itemId === TransactionSource.Ebanking));
    }

    isCashMachinePayementMethodSelected(): boolean {
        return !!this.transactionSource && !!this.transactionSource.value &&
            (this.transactionSource.value.length < 1 || this.transactionSource.value.some(e => e.itemId === TransactionSource.CashMachine));
    }

    get validityPeriod(): UntypedFormGroup {
        return this.filterForm.get('validityPeriod') as UntypedFormGroup;
    }

    get dateFrom(): UntypedFormControl {
        return this.validityPeriod.get('from') as UntypedFormControl;
    }

    get dateTo(): UntypedFormControl {
        return this.validityPeriod.get('to') as UntypedFormControl;
    }

    get transactionSource(): UntypedFormControl {
        return this.filterForm.get('transactionSource') as UntypedFormControl;
    }

    get epayment(): UntypedFormControl {
        return this.filterForm.get('epayment') as UntypedFormControl;
    }

    get cashMachinePaymentMethod(): UntypedFormControl {
        return this.filterForm.get('cashMachinePaymentMethod') as UntypedFormControl;
    }

    get consumptionType(): UntypedFormControl {
        return this.filterForm.get('consumptionType') as UntypedFormControl;
    }

    get service(): UntypedFormControl {
        return this.filterForm.get('service') as UntypedFormControl;
    }

    get user(): UntypedFormControl {
        return this.filterForm.get('user') as UntypedFormControl;
    }

    get amountRange(): UntypedFormGroup {
        return this.filterForm.get('amountRange') as UntypedFormGroup;
    }

    get amountFrom(): UntypedFormControl {
        return this.amountRange.get('min') as UntypedFormControl;
    }

    get amountTo(): UntypedFormControl {
        return this.amountRange.get('max') as UntypedFormControl;
    }

}

<h4 class="mb-4" translate="ewallet.closures.availableClosuresTitle"></h4>

<div class="table-responsive">
    <table class="table table-striped" [attr.aria-label]="'ewallet.closures.title' | translate">

        <thead>
        <tr>
            <th scope="col" style="min-width:250px" translate="ewallet.closures.executionDate"></th>
            <th scope="col" style="min-width:325px" translate="ewallet.closures.type"></th>
            <th scope="col" style="min-width:100px"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="closureDocuments.length === 0">
            <td>-</td>
            <td>-</td>
            <td></td>
        </tr>
        <tr *ngFor="let item of closureDocuments;">
            <td>{{ item.date | date: DateUtils.dateTimeWithoutSecondFormat }}</td>
            <td translate="{{ 'ewallet.closures.enums.EwalletClosureScheduleMode.' + item.mode }}"></td>
            <td class="text-end">
                <button class="btn btn-link btn-sm"
                        (click)="downloadClosureDocument(item)"
                        title="{{'common.tooltip.download' | translate}}">
                    <fa-icon [icon]="icons.download"></fa-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {
    OfficeHourExceptionDto,
    OfficeHourExceptionInputDto,
    OfficeHourService
} from '../../../_services/configuration-services';
import {NotificationsService} from '../../../_shared/notifications.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../_shared/form-utils';
import {DateUtils} from '../../../_shared/date-utils';
import {CustomDateFormatter} from '../../../_shared/custom-date-formatter';

@Component({
    selector: 'app-office-hour-exception-modal',
    templateUrl: './office-hour-exception-modal.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class OfficeHourExceptionModalComponent implements OnInit {
    @Input() officeHourException: OfficeHourExceptionDto;

    officeHourExceptionForm: UntypedFormGroup;

    today: NgbDateStruct = DateUtils.dateToNgbDateStruct(new Date());

    constructor(public officeHourExceptionModal: NgbActiveModal,
                private readonly officeHourService: OfficeHourService,
                private readonly notificationsService: NotificationsService) {

        this.officeHourExceptionForm = new UntypedFormGroup({
            exceptionPeriod: new UntypedFormGroup({
                from: new UntypedFormControl(null, [Validators.required, FormUtils.datePatternValidator]),
                to: new UntypedFormControl(null, [Validators.required, FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]),
            openingHour: new UntypedFormGroup({
                from: new UntypedFormControl(null, [Validators.required]),
                to: new UntypedFormControl(null, [Validators.required]),
            }, [FormUtils.timePeriodValidator]),
        }, {updateOn: 'change'});
    }

    ngOnInit(): void {
        if (!!this.officeHourException.startDate) {
            this.startDate.setValue(DateUtils.dateToNgbDateStruct(this.officeHourException.startDate));
        }
        if (!!this.officeHourException.endDate) {
            this.endDate.setValue(DateUtils.dateToNgbDateStruct(this.officeHourException.endDate));
        }
        if (!!this.officeHourException.startHour) {
            this.startHour.setValue(DateUtils.stringToNgbTimeStruct(this.officeHourException.startHour));
        }
        if (!!this.officeHourException.endHour) {
            this.endHour.setValue(DateUtils.stringToNgbTimeStruct(this.officeHourException.endHour));
        }
    }

    updateOfficeHourException(): void {
        if (!this.officeHourExceptionForm.valid) {
            return;
        }
        this.officeHourService.updateOfficeHourException(this.officeHourException.id, this.getFormResult()).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'officeHours.notifications.updateOfficeHourExceptionSuccess'});
            this.officeHourExceptionModal.close('success');
        });
    }

    createOfficeHourException(): void {
        if (!this.officeHourExceptionForm.valid) {
            return;
        }
        this.officeHourService.createOfficeHourException(this.getFormResult()).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'officeHours.notifications.createOfficeHourExceptionSuccess'});
            this.officeHourExceptionModal.close('success');
        });
    }

    isExceptionOpeningHourValid(): boolean {
        return this.openingHour.valid || !this.startHour.value || !this.endHour.value;
    }

    get openingHour(): UntypedFormGroup {
        return this.officeHourExceptionForm.get('openingHour') as UntypedFormGroup;
    }

    get startHour(): UntypedFormControl {
        return this.openingHour.get('from') as UntypedFormControl;
    }

    get endHour(): UntypedFormControl {
        return this.openingHour.get('to') as UntypedFormControl;
    }

    get exceptionPeriod(): UntypedFormGroup {
        return this.officeHourExceptionForm.get('exceptionPeriod') as UntypedFormGroup;
    }

    get startDate(): UntypedFormControl {
        return this.exceptionPeriod.get('from') as UntypedFormControl;
    }

    get endDate(): UntypedFormControl {
        return this.exceptionPeriod.get('to') as UntypedFormControl;
    }

    private getFormResult(): OfficeHourExceptionInputDto {
        return new OfficeHourExceptionInputDto({
            startDate: DateUtils.ngbDateStructToDate(this.startDate.value),
            endDate: DateUtils.ngbDateStructToDate(this.endDate.value),
            startHour: DateUtils.ngbTimeStructToString(this.startHour.value),
            endHour: DateUtils.ngbTimeStructToString(this.endHour.value)
        });
    }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {CalendarOptions, EventClickArg, EventInput, ToolbarInput, ViewApi} from '@fullcalendar/core';
import {environment} from '../../../../../../environments/environment';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocal from '@fullcalendar/core/locales/fr';
import deLocale from '@fullcalendar/core/locales/de';
import {CalendarUtils} from '../../../../../_shared/calendar-utils';
import {
    CraningBookingSlotItemDto,
    CraningBookingSlotService,
    CraningBookingSlotState
} from '../../../../../_services/configuration-services';
import {RolesService} from '../../../../../_shared/roles-service';
import {Observable, Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {CraningBookingUtils} from '../craning-booking-utils';
import {FormUtils} from '../../../../../_shared/form-utils';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {TranslateUtils} from '../../../../../_shared/translate-utils';
import itLocale from '@fullcalendar/core/locales/it';

@Component({
    selector: 'app-craning-booking-calendar',
    templateUrl: './craning-booking-calendar.component.html'
})
export class CraningBookingCalendarComponent implements OnInit, OnDestroy {

    @Input() crtLang = TranslateUtils.defaultLanguage;
    @Input() serviceId: string;
    @Output() selectedSlot = new EventEmitter<CraningBookingSlotItemDto>();

    @ViewChild('calendar') calendar: FullCalendarComponent;
    @ViewChild('calendarPopoverTmpl', {static: true}) calendarPopoverTmpl: TemplateRef<any>;

    calendarOptions: CalendarOptions;

    textInputPattern = FormUtils.textInputPattern;

    CraningBookingSlotState = CraningBookingSlotState;

    private readonly initialCalendarView = 'dayGridMonth';

    // Search booking --------
    searchBookingForm: UntypedFormGroup;
    searchTerms: string | null = null;
    private beforeSearchBookingView: string;
    private beforeSearchBookingDate: Date;
    private inSearchMode = false;
    private searchBookingTermsSubscription: Subscription | null;

    // Calendar buttons --------
    private readonly calendarLeftButtons = 'prev,next today';
    private readonly calendarLeftButtonsWithPrint = this.calendarLeftButtons + ' print';
    private readonly calendarRightButtons = 'dayGridMonth,timeGridWeek,timeGridDay,listWeek';

    constructor(
        private readonly craningBookingSlotService: CraningBookingSlotService,
        private readonly rolesService: RolesService,
        private readonly translateService: TranslateService,
        private readonly calendarUtils: CalendarUtils,
        public readonly craningBookingUtils: CraningBookingUtils,
    ) {
        this.searchBookingForm = new UntypedFormGroup({
            searchTerms: new UntypedFormControl(null),
        }, {updateOn: 'change'});
    }

    ngOnInit(): void {
        this.buildCalendar();

        if (this.craningBookingUtils.hasAdminView()) {
            this.searchBookingTermsSubscription = this.reservationSearchTerms.valueChanges.pipe(
                debounceTime(300),
                distinctUntilChanged()
            ).subscribe((searchTerms: string) => this.searchBooking(searchTerms));
        } else {
            this.searchBookingTermsSubscription = null;
        }
    }

    ngOnDestroy(): void {
        this.searchBookingTermsSubscription?.unsubscribe();
    }

    get reservationSearchTerms(): UntypedFormControl {
        return this.searchBookingForm.get('searchTerms') as UntypedFormControl;
    }

    private buildCalendar(): void {
        const printButton = {
            text: this.translateService.instant('services.craning.booking.calendar.print'),
            click: () => {
                this.craningBookingUtils.printCraningBookingsFromDateRange(
                    this.serviceId,
                    CalendarUtils.getStartDate(this.calendar),
                    CalendarUtils.getEndDate(this.calendar));
            }
        };

        this.calendarOptions = {
            schedulerLicenseKey: environment.fullCalendarLicenceKey,
            plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
            editable: false,
            locales: [frLocal, deLocale, itLocale],
            locale: this.crtLang,
            customButtons: {
                print: printButton
            },
            headerToolbar: {
                left: this.calendarLeftButtons,
                center: 'title',
                right: this.calendarRightButtons
            },
            resourceOrder: 'title',
            initialView: this.initialCalendarView,
            nowIndicator: true,
            events: this.fetchEvents.bind(this),
            eventTimeFormat: {
                hour: '2-digit',
                minute: '2-digit',
            },
            dayHeaderFormat: {
                weekday: 'short'
            },
            selectMirror: true,
            dayMaxEvents: 10,
            allDaySlot: false,
            viewDidMount: this.onViewDidMount.bind(this),
            eventClick: this.onEventClick.bind(this),
        };
    }

    private onViewDidMount(arg: {view: ViewApi, el: HTMLElement}): void {
        const displayEndAndTime = !this.calendar
            ? true
            : CalendarUtils.isCalendarMonthView(this.calendar) || CalendarUtils.isCalendarListView(this.calendar);

        this.calendarOptions.displayEventEnd = displayEndAndTime;
        this.calendarOptions.displayEventTime = displayEndAndTime;

        const shouldDisplayEventPopover = !this.calendar
            ? true
            : CalendarUtils.shouldDisplayEventPopover(this.calendar);

        this.calendarOptions.eventDidMount = shouldDisplayEventPopover ?
            this.calendarUtils.getRenderTooltipHandler(this.calendarPopoverTmpl) :
            null;
        this.calendarOptions.eventWillUnmount = shouldDisplayEventPopover ? this.calendarUtils.getDestroyTooltipHandler(this.calendar) : null;
        this.calendarOptions.eventMouseEnter = shouldDisplayEventPopover ? this.calendarUtils.getShowPopoverHandler(this.calendar) : null;
        this.calendarOptions.eventMouseLeave = shouldDisplayEventPopover ? this.calendarUtils.getHidePopoverHandler(this.calendar) : null;

        const toolBar = this.calendarOptions.headerToolbar as ToolbarInput;
        toolBar.left =
            this.craningBookingUtils.hasAdminView()
            && (CalendarUtils.isCalendarDayView(this.calendar) || CalendarUtils.isCalendarWeekView(this.calendar)) ?
                this.calendarLeftButtonsWithPrint :
                this.calendarLeftButtons;

        this.refetchCalendarEvents();
    }

    private onEventClick(arg: EventClickArg): void {
        this.selectedSlot.emit(arg.event.extendedProps['slot']);
    }

    private fetchEvents(args: {
                            start: Date;
                            end: Date;
                            startStr: string;
                            endStr: string;
                            timeZone: string;
                        },
                        successCallback: (events: EventInput[]) => void,
                        failureCallback: (error) => void): void {
        const startDate = args.start;
        const endDate = args.end;

        const filter = CalendarUtils.isCalendarListView(this.calendar)
            ? [CraningBookingSlotState.Paid, CraningBookingSlotState.Booked]
            : [];

        let slotsObservable: Observable<CraningBookingSlotItemDto[]>;
        if (this.craningBookingUtils.hasAdminView()) {
            slotsObservable = this.craningBookingSlotService.getAdminCraningSlots(
                this.serviceId, startDate, endDate, filter, this.searchTerms);

        } else if (!this.rolesService.getUserId()) {
            slotsObservable = this.craningBookingSlotService.getPublicCraningSlots(this.serviceId, startDate, endDate, filter);

        } else {
            slotsObservable = this.craningBookingSlotService.getCraningSlots(this.serviceId, startDate, endDate, filter);
        }

        slotsObservable.pipe().subscribe(result => {
            successCallback(this.buildEvents(result));
        }, error => failureCallback(error));
    }

    private buildEvents(slots: CraningBookingSlotItemDto[]): EventInput[] {
        const events: EventInput[] = [];

        for (const slot of slots) {
            const startDate = new DatePipe('en-US').transform(slot.date, 'yyyy-MM-dd') + 'T' + slot.startTime;
            const endDate = new DatePipe('en-US').transform(slot.date, 'yyyy-MM-dd') + 'T' + slot.endTime;

            let eventColor = CalendarUtils.getColorBasedOnBookingSlotState(slot.state);
            const textColor = CalendarUtils.getTextColorBasedOnBookingSlotState(slot.state);

            if (slot.isMine) {
                eventColor = slot.state === CraningBookingSlotState.Paid ? CalendarUtils.green : CalendarUtils.orange;
            }

            events.push({
                color: eventColor,
                textColor: textColor,
                title: this.getTitle(slot),
                editable: false,
                start: startDate,
                end: endDate,
                allDay: false,
                id: slot.id.toString(),
                resourceId: null,
                resourceEditable: false,
                startEditable: false,
                durationEditable: false,
                display: null,
                slot
            });
        }
        return events;
    }

    public getTitle(slot: CraningBookingSlotItemDto): string {
        if (!slot) {
            return '';
        }
        if (!slot || slot.state === CraningBookingSlotState.Free
            || slot.state === CraningBookingSlotState.Blocked
            || (!slot.isMine && !this.craningBookingUtils.hasAdminView())) {
            return this.getStateName(slot);
        }

        return this.getUserFullName(slot);
    }

    public getStateName(slot: CraningBookingSlotItemDto): string {
        return this.translateService.instant('services.craning.slots.stateEnum.' + slot.state);
    }

    public getUserFullName(slot: CraningBookingSlotItemDto): string {
        if (!slot || slot.state === CraningBookingSlotState.Free
            || slot.state === CraningBookingSlotState.Blocked) {
            return '';
        }
        if (slot.isMine || this.craningBookingUtils.hasAdminView()) {
            return slot.firstName + ' ' + slot.lastName;

        }
        return this.translateService.instant('services.craning.booking.calendar.blocked');
    }

    public refetchCalendarEvents(): void {
        this.calendar?.getApi().refetchEvents();
    }

    private searchBooking(searchTerms: string): void {
        if (!this.craningBookingUtils.hasAdminView()) {
            return;
        }
        const trimmedSearchTerms = searchTerms.trim();
        const charactersNumberOfSearchTerms = trimmedSearchTerms.length;

        switch (charactersNumberOfSearchTerms) {
            case 0:
                if (this.inSearchMode) {
                    this.inSearchMode = false;
                    CalendarUtils.setCalendarView(this.calendar, this.beforeSearchBookingView);
                    CalendarUtils.goToDate(this.calendar, this.beforeSearchBookingDate);
                    const toolBar = this.calendarOptions.headerToolbar as ToolbarInput;
                    toolBar.right = this.calendarRightButtons;

                    this.calendarOptions.validRange = null;
                    this.searchTerms = null;
                    this.refetchCalendarEvents();
                }
                break;
            case 1:
                break;
            default:
                if (!this.inSearchMode) {
                    const today = new Date();

                    this.inSearchMode = true;
                    this.beforeSearchBookingView = this.calendar.getApi().view.type;
                    this.beforeSearchBookingDate = CalendarUtils.getDate(this.calendar);
                    CalendarUtils.setCalendarView(this.calendar, 'listYear');
                    CalendarUtils.goToDate(this.calendar, today);

                    const toolBar = this.calendarOptions.headerToolbar as ToolbarInput;
                    toolBar.right = null;

                    this.calendarOptions.validRange = {
                        start: new Date(today.getFullYear() - 2, today.getMonth(), today.getDate()),
                        end: new Date(today.getFullYear() + 2, today.getMonth(), today.getDate())
                    };
                }
                this.searchTerms = trimmedSearchTerms;
                this.refetchCalendarEvents();
                break;
        }
    }
}

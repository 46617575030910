import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CraningBillingDto, CraningBillingItemDto, CraningBillingService} from '../../../../../../_services/configuration-services';
import {TranslateUtils} from '../../../../../../_shared/translate-utils';

@Component({
    selector: 'app-craning-booking-form-bill-details-component',
    templateUrl: './craning-booking-form-bill-details-component.component.html',
    styleUrls: ['./craning-booking-form-bill-details-component.component.scss']
})
export class CraningBookingFormBillDetailsComponentComponent implements OnChanges {

    @Input() slotId: number;
    @Input() crtLang = TranslateUtils.defaultLanguage;

    craningBillingDto: CraningBillingDto;

    constructor(private readonly craningBillingService: CraningBillingService) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.fetchCraningBillingDto();
    }

    private fetchCraningBillingDto(): void {
        this.craningBillingService.getBillForSlotId(this.slotId)
            .pipe()
            .subscribe(craningBillingDto => {
                this.craningBillingDto = craningBillingDto;
            });
    }


    getCraningServiceLabel(craningBillingItemDto: CraningBillingItemDto): string {
        return craningBillingItemDto['craningItemLabel' + this.crtLang.toUpperCase()];
    }
}

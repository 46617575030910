import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchUserComponent} from '../../_shared/_components/search-user/search-user.component';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
    EwalletCriteriaDto, EwalletSearchCriteriaDto,
    EwalletService,
    PageableRequestDtoOfEwalletCriteriaDto,
    PagedResultDtoOfUserEwalletDto,
    SearchUserDto,
    SortCriteriaDto,
    SortDirection, TransactionService
} from '../../_services/configuration-services';
import {faWallet} from '@fortawesome/free-solid-svg-icons';
import {CssConstants} from '../../app.constants';
import {FormUtils} from '../../_shared/form-utils';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-ewallet-list',
  templateUrl: './ewallet-list.component.html'
})
export class EwalletListComponent implements OnInit {

    @ViewChild('searchUserComponent') searchUser: SearchUserComponent;

    FormUtils = FormUtils;

    filterForm: UntypedFormGroup;
    selectedUser: SearchUserDto;

    eWallets = new PagedResultDtoOfUserEwalletDto();
    totalEwallet: number = 0;

    // Icons
    icons = {
        wallet: faWallet
    }

    // DataTable
    readonly pageSize = 50;
    crtPage = 0;

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Asc,
        property: 'customerNumber'
    });

    private pageRequest: PageableRequestDtoOfEwalletCriteriaDto;

    constructor(private readonly eWalletService: EwalletService,
                private readonly transactionService: TransactionService) {

        this.pageRequest = new PageableRequestDtoOfEwalletCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new EwalletCriteriaDto({
            sortCriteria: this.currentSort,
            ewalletSearchCriteriaDto: new EwalletSearchCriteriaDto({})
        });

        this.filterForm = new UntypedFormGroup({
            user: new UntypedFormControl(),
            cardNumber: new UntypedFormControl(),
            amountRange: new UntypedFormGroup({
                min: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
                max: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
            }, [FormUtils.rangeValidator])
        });
    }

    ngOnInit(): void {
        this.fetchStatistics();
        this.fetchEwallets();
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;
        this.fetchEwallets();
    }

    onSort(sortOrder: any): void {
        this.goToFirstPage();
        this.currentSort = new SortCriteriaDto({
            direction: sortOrder.sorts[0].dir,
            property: sortOrder.sorts[0].prop
        });
        this.pageRequest.criteria.sortCriteria = this.currentSort;
        this.fetchEwallets();
    }

    getRowClass(row): string {
        return row.rfidCards.length > 20 ?
            CssConstants.max150pxRowHeightClass : CssConstants.unlimitedRowHeightClass;
    }

    export(): void {
        this.eWalletService.exportEwallets(this.pageRequest.criteria).pipe().subscribe(response => {
            fileSaver.saveAs(response.data, response.fileName);
        });
    }

    filterEwallets(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new EwalletCriteriaDto({
            sortCriteria: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            }),
            ewalletSearchCriteriaDto: new EwalletSearchCriteriaDto({
                amountFrom: !!this.amountFrom.value || this.amountFrom.value === 0 ? Number(this.amountFrom.value) : null,
                amountTo: !!this.amountTo.value || this.amountTo.value === 0 ? Number(this.amountTo.value) : null,
                userId: this.user?.value?.id,
                cardNumber: this.cardNumber?.value
            })
        });

        this.fetchEwallets();
    }

    clearFilter(): void {
        this.amountFrom.setValue(null);
        this.amountTo.setValue(null);
        this.cardNumber.setValue(null);

        this.crtPage = 0;
        this.selectedUser = null;
        this.searchUser.clearInput();

        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new EwalletCriteriaDto({
            sortCriteria: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            }),
            ewalletSearchCriteriaDto: new EwalletSearchCriteriaDto({})
        });

        this.fetchEwallets();
    }

    private fetchEwallets(): void {
        this.eWalletService.searchEwallet(this.pageRequest).pipe().subscribe(r => this.eWallets = r);
    }

    private fetchStatistics(): void {
        this.transactionService.getEwalletsCumulativeBalance().pipe().subscribe(value => {
            this.totalEwallet = value;
        });
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }

    get user(): UntypedFormControl {
        return this.filterForm.get('user') as UntypedFormControl;
    }

    get cardNumber(): UntypedFormControl {
        return this.filterForm.get('cardNumber') as UntypedFormControl;
    }

    get amountRange(): UntypedFormGroup {
        return this.filterForm.get('amountRange') as UntypedFormGroup;
    }

    get amountFrom(): UntypedFormControl {
        return this.amountRange.get('min') as UntypedFormControl;
    }

    get amountTo(): UntypedFormControl {
        return this.amountRange.get('max') as UntypedFormControl;
    }
}

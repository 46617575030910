<div class="modal-header">

    <h4 class="modal-title" translate="{{ ewalletClosureSchedule !== null ? 'ewallet.closures.edit' : 'ewallet.closures.add' }}"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="scheduleEwalletClosureForm">
        <div class="row">

            <!-- Date -->
            <div class="col-12">

                <label class="form-label"
                       for="date">
                    {{ 'common.date.date' | translate  }} *
                </label>

                <div class="input-group mb-2">
                    <input class="form-control" id="date"
                           [formControl]="date"
                           placeholder="{{ 'common.placeholders.dateFormat' | translate}}"
                           ngbDatepicker #dpDateFrom="ngbDatepicker"
                           [minDate]="today"/>

                    <span class="input-group-text">
                        <button class="btn btn-link btn-sm" (click)="dpDateFrom.toggle()">
                            <fa-icon [icon]="icons.calendar"></fa-icon>
                        </button>
                        <button class="btn btn-link btn-sm" *ngIf="date.value"
                                (click)="resetDate()">
                            <fa-icon [icon]="icons.times"></fa-icon>
                        </button>
                    </span>

                </div>

                <div class="mb-2">
                    <app-form-error [control]="date"></app-form-error>
                </div>

            </div>

            <!-- Hours -->
            <div class="col-12">

                <label class="form-label"
                       for="startTime">
                    {{ 'common.hour' | translate }} *
                </label>
                <ngb-timepicker id="startTime" [formControl]="time"></ngb-timepicker>


                <div class="mb-2">
                    <app-form-error [control]="time"></app-form-error>
                    <app-form-error [control]="scheduleEwalletClosureForm"></app-form-error>
                </div>
            </div>

        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="services.availabilityRange.modal.cancel">
    </button>
    <button class="btn btn-primary"
            (click)="saveScheduleEwalletClosure()"
            [disabled]="scheduleEwalletClosureForm.invalid || scheduleEwalletClosureForm.pristine"
            translate="common.save">
    </button>
</div>


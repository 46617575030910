import { Pipe, PipeTransform } from '@angular/core';
import {BookingLightDto} from '../../../_services/configuration-services';

@Pipe({
  name: 'firstAndLastname'
})
export class FirstAndLastnamePipe implements PipeTransform {

  transform(value: BookingLightDto | null): string {
      if (value?.visitorContact) {
          return value.visitorContact.firstName + ' ' + value.visitorContact.lastName;
      }
      return '';
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {
    ExternalLightDto,
    LightDto,
    PublicLightingExternalLightService,
    PublicLightingProvider,
    PublicLightingZoneService
} from '../../../../_services/configuration-services';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../../_shared/form-utils';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-link-light-to-external-service-modal',
  templateUrl: './link-light-to-external-service-modal.component.html'
})
export class LinkLightToExternalServiceModalComponent implements OnInit {

    @Input() lightDto: LightDto | null;

    @Input() inputLightProvider: PublicLightingProvider | null = null;

    FormUtils = FormUtils;

    PublicLightingProvider = PublicLightingProvider;

    linkLightForm: FormGroup<LinkLightForm>;

    externalLights: ExternalLightDto[] = [];

    constructor(public activeModal: NgbActiveModal,
                private readonly notificationsService: NotificationsService,
                private readonly lightZoneService: PublicLightingZoneService,
                private readonly externalLightService: PublicLightingExternalLightService) {

        this.linkLightForm = new FormGroup<LinkLightForm>({
            lightProvider: new FormControl<PublicLightingProvider>(null, Validators.required),
            externalLight: new FormControl<ExternalLightDto>(null, Validators.required)
        });
    }

    async ngOnInit(): Promise<void> {
        if (this.inputLightProvider) {
            this.lightProvider.setValue(this.inputLightProvider);
            await this.onProviderChange(this.inputLightProvider, false);
        }
    }

    async onProviderChange(provider: PublicLightingProvider, resetExternalId = true): Promise<void> {
        this.externalLights = [];
        this.externalLights = await firstValueFrom(this.externalLightService.getNotLinkedExternalLightsOfProvider(provider));

        if (resetExternalId) {
            this.externalLight.reset(null);
        }
    }

    save(): void {
        if (this.linkLightForm.valid) {
            this.activeModal.close(new LinkLightToProvider(this.lightProvider.value, this.externalLight.value));

        } else {
            this.activeModal.close(null);
        }
    }

    get lightProvider(): FormControl<PublicLightingProvider> {
        return this.linkLightForm.get('lightProvider') as FormControl<PublicLightingProvider>;
    }

    get externalLight(): FormControl<ExternalLightDto> {
        return this.linkLightForm.get('externalLight') as FormControl<ExternalLightDto>;
    }
}

interface LinkLightForm {
    lightProvider: FormControl<PublicLightingProvider>,
    externalLight: FormControl<ExternalLightDto>
}

export class LinkLightToProvider {
    lightProvider: PublicLightingProvider;
    externalLight: ExternalLightDto;

    constructor(lightProvider: PublicLightingProvider,
                externalLight: ExternalLightDto) {
        this.lightProvider = lightProvider;
        this.externalLight = externalLight;
    }
}

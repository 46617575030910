<div class="modal-header">
    <h4 class="modal-title" translate="services.publicLighting.light.configureProvider"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="this.activeModal.close('close')">
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="linkLightForm" class="m-0">

        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'services.publicLighting.light.modal.provider' | translate }}*</label>
            </div>
            <div class="col-6 text-end">
                <select class="form-control form-select"
                        [formControl]="lightProvider"
                        (change)="onProviderChange(lightProvider.value)">
                    <option value="{{ PublicLightingProvider.Exedra }}">{{ PublicLightingProvider.Exedra }}</option>
                    <option value="{{ PublicLightingProvider.InteractLighting }}">{{ PublicLightingProvider.InteractLighting }}</option>
                    <option value="{{ PublicLightingProvider.Novalight }}">{{ PublicLightingProvider.Novalight }}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'services.publicLighting.light.modal.providerLights' | translate }}*</label>
            </div>
            <div class="col-6 text-end">
                <select class="form-control form-select"
                        [formControl]="externalLight">
                    <option *ngFor="let externalLight of externalLights;"
                            [ngValue]="externalLight">{{ externalLight.name }}</option>
                </select>
            </div>
        </div>

    </form>
</div>


<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="this.activeModal.close('close')"
            translate="common.cancel">
    </button>

    <button class="btn btn-primary"
            [disabled]="linkLightForm.invalid"
            (click)="save()"
            translate="common.validate">
    </button>
</div>

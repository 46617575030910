<div class="input-group">
    <input type="text" class="form-control" pattern="{{textInputPattern}}"
           [class.is-invalid]="searchFailed"
           [ngbTypeahead]="search"
           [inputFormatter]="formatter"
           [resultFormatter]="formatter"
           [resultTemplate]="rtUser"
           [disabled]="disabled"
           [(ngModel)]="selectedUser"
           (selectItem)="selectItem($event)"
           (focus)="searchSubject$.next($event.target['value'])"
           (keyup.enter)="searchSubject$.next($event.target.value)"
           placeholder="{{ 'common.placeholders.search.user' | translate }}"/>

    <span class="input-group-text">
        <button class="btn btn-link btn-sm" *ngIf="!disabled && (selectedUser || searchText)"
                title="{{ 'common.tooltip.erase' | translate }}"
                (click)="clearInput()">
            <fa-icon [icon]="icons.erase"></fa-icon>
        </button>
    </span>

    <div class="invalid-feedback" *ngIf="searchFailed" translate="users.findUser.searchFailed"></div>

    <ng-template #rtUser let-user="result" let-t="term">
        <ngb-highlight [result]="formatter(user)" [term]="t"></ngb-highlight>
    </ng-template>
</div>

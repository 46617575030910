import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserAlarmModalComponent} from './user-alarm-modal/user-alarm-modal.component';
import {TariffDto, UserAlarmSubscriptionDto, UserAlarmSubscriptionService} from '../../../_services/configuration-services';
import {Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {faPen, faToggleOff, faToggleOn, faTrash} from '@fortawesome/free-solid-svg-icons';
import {NotificationsService} from '../../../_shared/notifications.service';
import {DateUtils} from '../../../_shared/date-utils';
import {TranslateUtils} from '../../../_shared/translate-utils';

@Component({
    selector: 'app-user-alarm-amanagement',
    templateUrl: './user-alarm-management.component.html'
})
export class UserAlarmManagementComponent implements OnInit, OnDestroy {

    @Input() serviceId: string;
    @Input() serviceActive: boolean;
    @Input() tariff: TariffDto | null;

    @ViewChild('subscriptionTable') table;

    subscriptions: UserAlarmSubscriptionDto[] = [];

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    displayDateFormat = DateUtils.dateFormat;

    // Icons
    icons = {
        edit: faPen,
        delete: faTrash,
        toggleOn: faToggleOn,
        toggleOff: faToggleOff
    };

    // Used to display expiration date in terminate modal
    terminateModalContent = {
        terminationDate: null
    };

    constructor(private readonly modalService: NgbModal,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly userAlarmSubscriptionService: UserAlarmSubscriptionService) { }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.fetchAlarmSubscriptions();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    fetchAlarmSubscriptions(): void {
        this.userAlarmSubscriptionService.getMySubscriptions().pipe().subscribe(subscriptions => {
            this.subscriptions = subscriptions;
        });
    }

    addAlarmSubscription(): void {
        const modal = this.modalService.open(UserAlarmModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.tariff = this.tariff;

        modal.result.then(_ => {
            this.fetchAlarmSubscriptions();
        }, () => { /* catch the rejection */ });
    }

    editSubscription(subscription: UserAlarmSubscriptionDto): void {
        const modal = this.modalService.open(UserAlarmModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.tariff = this.tariff;
        modal.componentInstance.userAlarmSubscription = subscription;

        modal.result.then(_ => {
            this.fetchAlarmSubscriptions();
        }, () => { /* catch the rejection */ });
    }

    activateSubscription(subscription: UserAlarmSubscriptionDto): void {
        this.userAlarmSubscriptionService.activateSubscription(subscription.id).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'services.userAlarm.subscriptions.notifications.activateSuccess'});
            this.fetchAlarmSubscriptions();
        });
    }

    deactivateSubscription(subscription: UserAlarmSubscriptionDto): void {
        this.userAlarmSubscriptionService.deactivateSubscription(subscription.id).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'services.userAlarm.subscriptions.notifications.deactivateSuccess'});
            this.fetchAlarmSubscriptions();
        });
    }

    terminateSubscription(subscription: UserAlarmSubscriptionDto, terminateSubscriptionConfirmModal): void {

        this.userAlarmSubscriptionService.getSubscriptionTerminationDate(subscription.id).pipe().subscribe(terminationDate => {
            this.terminateModalContent.terminationDate = terminationDate;

            const modal = this.modalService.open(terminateSubscriptionConfirmModal, {centered: true});
            modal.result
                .then(value => {
                    if (value === 'confirm') {
                        this.userAlarmSubscriptionService.terminateSubscription(subscription.id, subscription).pipe().subscribe(_ => {
                            this.notificationsService.success({title: 'services.userAlarm.subscriptions.notifications.terminateSuccess'});
                            this.fetchAlarmSubscriptions();
                        });
                    }
                }, () => { /* catch the rejection */ });
        });
    }
}


<div class="content-container" *ngIf="!!info">

    <h1>{{ info['title' + crtLang.toUpperCase()] }}</h1>
    <h6>{{ info.publicationDate | date }}</h6>

    <div class="btn-bar justify-content-between" [appHasAnyRole]="[AppRoles.admin]">

        <button class="btn btn-link" [routerLink]="['../manage/' + infoId]">
            <fa-icon class="me-2" [icon]="icons.pen"></fa-icon>
            <span translate="common.manageInfo"></span>
        </button>

        <div class="right-bar">
            <button class="btn btn-outline-secondary"
                    (click)="back()"
                    translate="common.back">
            </button>

            <button class="btn btn-primary" *ngIf="hasPreviousInformation()"
                    (click)="previousInformation()"
                    translate="common.previous">
            </button>

            <button class="btn btn-primary" *ngIf="hasNextInformation()"
                    (click)="nextInformation()"
                    translate="common.next">
            </button>
        </div>

    </div>

    <!-- Content -->
    <div class="row">

        <div class="col-12 col-lg-6">
            <div class="no-border-radius area-link"
                 [innerHTML]="info['content' + crtLang.toUpperCase()] | urlTextToLink">
            </div>
        </div>

        <div class="col-12 col-lg-6" *ngIf="!!info.illustrationUrl">
            <div class="text-center">
                <a href="{{ info.illustrationUrl }}" target="_blank" rel="noopener">
                    <img class="rounded mw-100" [src]="info.illustrationUrl"
                         alt="{{ 'communicationChannel.information.illustration' | translate }}"/>
                </a>
            </div>
        </div>

    </div>

</div>

<!-- Documents -->
<app-document-view #documentListComponent *ngIf="!!info"
                   [documentManagementType]="DocumentManagementTypeEnum.Information"
                   [id]="info.id"></app-document-view>

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AccessControlService, MorpheanDoorGroupDto} from '../../../../_services/configuration-services';

@Component({
    selector: 'app-door-group-modal',
    templateUrl: './door-group-modal.component.html'
})
export class DoorGroupModalComponent implements OnInit {

    public doorGroups: MorpheanDoorGroupDto[] = [];
    public selectedDoorGroup: MorpheanDoorGroupDto;

    @Input() serviceId!: string;


    constructor(
        private readonly modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private readonly accessControlService: AccessControlService) {
    }

    async ngOnInit(): Promise<void> {
        this.doorGroups = await this.accessControlService.getAllDoorsGroup().toPromise();
    }

    async assignDoorGroup(): Promise<void> {
        this.selectedDoorGroup.serviceId = this.serviceId;
        await this.accessControlService.assignDoorGroup(this.selectedDoorGroup).toPromise();
        this.activeModal.close('create');
    }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    PageableRequestDtoOfRfidCardCriteriaDto,
    PagedResultDtoOfRfidCardDto,
    RfidCardCriteriaDto,
    RfidCardDto,
    RfidCardSearchCriteriaDto,
    RfidCardService,
    RfidCardState,
    SearchUserDto,
    SortCriteriaDto,
    SortDirection
} from '../_services/configuration-services';
import {NotificationsService} from '../_shared/notifications.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RfidCardModalComponent} from './rfid-card-modal/rfid-card-modal.component';
import {faPen, faTrash, faUserPen} from '@fortawesome/free-solid-svg-icons';
import {ConfirmModalService} from '../_shared/_components/confirm-modal/confirm-modal.component';
import {IDropdownSettings} from "ng-multiselect-dropdown/multiselect.model";
import {TranslateService} from "@ngx-translate/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-rfid-cards',
    templateUrl: './rfid-cards.component.html'
})
export class RfidCardsComponent implements OnInit, OnDestroy {

    // Icons
    faTrash = faTrash;
    faPen = faPen;
    faUserPen = faUserPen;

    // Import
    rfidCardsFileToUpload: File;
    rfidCardsFileValid: boolean;

    // DataTable
    readonly pageSize = 50;
    crtPage = 0;
    rfidCards: PagedResultDtoOfRfidCardDto = new PagedResultDtoOfRfidCardDto();

    rfidCardStatusSettings: IDropdownSettings = {};
    rfidCardStatusSource = [];

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Asc,
        property: 'cardNumber'
    });

    pageRequest: PageableRequestDtoOfRfidCardCriteriaDto;

    rfidFormGroup = new UntypedFormGroup({
        cardState: new UntypedFormControl(),
        cardUid: new UntypedFormControl(),
        cardNumber: new UntypedFormControl(),
        user: new UntypedFormControl(),
    });

    private langChangeSubscription$: Subscription;

    constructor(private rfidCardService: RfidCardService,
                private notificationService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                private readonly translateService: TranslateService,
                private readonly modalService: NgbModal) {

        this.pageRequest = new PageableRequestDtoOfRfidCardCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new RfidCardCriteriaDto({
            rfidCardSearchCriteriaDto: new RfidCardSearchCriteriaDto({
                cardNumber: null,
                uid: null,
                assigned: null
            }),
            sortCriteriaDto: this.currentSort
        });
    }

    async ngOnInit(): Promise<void> {
        this.langChangeSubscription$ = this.translateService.onLangChange.subscribe(_ => {
            this.populateRfidStatusSelect();
        });
        this.populateRfidStatusSelect();
        await this.fetchRfidCards();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    rfidCardsFileSelected(files: any): void {
        if (files.length > 0) {
            this.rfidCardsFileToUpload = files[0];
            this.rfidCardsFileValid = true;
        }
    }

    create(): void {
        this.modalService.open(RfidCardModalComponent, {centered: true}).result
            .then(async value => {
                if (value === 'success') {
                    this.notificationService.success({title: 'rfidCards.notifications.createSuccess'});
                    await this.fetchRfidCards();
                }
            }, () => { /* catch the rejection */ });
    }

    update(rfidCard: RfidCardDto): void {
        const modal = this.modalService.open(RfidCardModalComponent, {centered: true});
        modal.componentInstance.rfidCard = rfidCard;
        modal.result
            .then(async value => {
                if (value === 'success') {
                    this.notificationService.success({title: 'rfidCards.notifications.updateSuccess'});
                    await this.fetchRfidCards();
                }
            }, () => { /* catch the rejection */ });
    }

    delete(rfidCard: RfidCardDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.rfidCardService.deleteRfidCard(rfidCard.cardNumber).toPromise().then(async () => {
                    this.notificationService.success({title: 'rfidCards.notifications.deleteSuccess'});
                    await this.fetchRfidCards();
                });
            }
        });
    }

    // region DataTableMethods
    async setPage(page: any): Promise<void> {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;
        await this.fetchRfidCards();
    }

    async onSort(sortOrder: any): Promise<void> {
        this.goToFirstPage();
        this.currentSort = new SortCriteriaDto({
            direction: sortOrder.sorts[0].dir,
            property: sortOrder.sorts[0].prop
        });
        this.pageRequest.criteria.sortCriteriaDto = this.currentSort;
        await this.fetchRfidCards();
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }

    async searchCriteriaChanged(): Promise<void> {
        this.goToFirstPage();
        await this.fetchRfidCards();
    }

    async fetchRfidCards(): Promise<void> {
        this.pageRequest.criteria.rfidCardSearchCriteriaDto.cardNumber = this.cardNumber.value;
        this.pageRequest.criteria.rfidCardSearchCriteriaDto.uid = this.cardUid.value;
        this.pageRequest.criteria.rfidCardSearchCriteriaDto.assigned = this.cardState.value?.map(item => item.itemId);
        this.pageRequest.criteria.rfidCardSearchCriteriaDto.userId = this.user.value;
        this.rfidCards = await this.rfidCardService.searchRfidCards(this.pageRequest).toPromise();
    }

    async clearFilter(): Promise<void> {
        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new RfidCardCriteriaDto({
            rfidCardSearchCriteriaDto: new RfidCardSearchCriteriaDto({
                cardNumber: null,
                uid: null,
                assigned: null
            }),
            sortCriteriaDto: this.currentSort
        });

        this.rfidFormGroup.reset();

        this.goToFirstPage();
        await this.fetchRfidCards();
    }

    isFiltering(): boolean {
        const rfidCardSearchCriteriaDto = this.pageRequest.criteria.rfidCardSearchCriteriaDto;
        return !!rfidCardSearchCriteriaDto.cardNumber ||
            !!rfidCardSearchCriteriaDto.uid ||
            rfidCardSearchCriteriaDto.assigned !== undefined;
    }

    // end region

    importRfidCardsFile(): void {
        this.rfidCardService.importRfidCardsFromFile({
            data: this.rfidCardsFileToUpload,
            fileName: this.rfidCardsFileToUpload.name
        }).toPromise()
            .then(nbRowsAdded => {
                this.notificationService.success({
                    message: 'rfidCards.fullyImported',
                    interpolateParams: {ImportedRfidCardsCount: nbRowsAdded}
                });
                this.fetchRfidCards();
            });
    }

    private populateRfidStatusSelect() {
        this.rfidCardStatusSource = [];
        Object.keys(RfidCardState)
            .forEach(r => {
                this.rfidCardStatusSource.push({
                    itemId: r,
                    itemVal: this.translateService.instant('rfidCards.status.' + r.toLowerCase())
                })
            });
        this.rfidCardStatusSettings = {
            idField: 'itemId',
            textField: 'itemVal',
            singleSelection: false,
            selectAllText: this.translateService.instant('common.multiselect.selectAll'),
            unSelectAllText: this.translateService.instant('common.multiselect.unselectAll'),
            searchPlaceholderText: this.translateService.instant('common.multiselect.search'),
            noDataAvailablePlaceholderText: this.translateService.instant('common.multiselect.noResults'),
            itemsShowLimit: 3,
            allowSearchFilter: false
        };
    }


    async userSelected($event: SearchUserDto): Promise<void> {
        this.user.setValue($event?.id);
        await this.searchCriteriaChanged();
    }

    get cardState(): UntypedFormControl {
        return this.rfidFormGroup.get('cardState') as UntypedFormControl;
    }

    get cardUid(): UntypedFormControl {
        return this.rfidFormGroup.get('cardUid') as UntypedFormControl;
    }

    get cardNumber(): UntypedFormControl {
        return this.rfidFormGroup.get('cardNumber') as UntypedFormControl;
    }

    get user(): UntypedFormControl {
        return this.rfidFormGroup.get('user') as UntypedFormControl;
    }

}

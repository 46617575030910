import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ServiceIconUrlBuilder {

    private static readonly PLACEHOLDER: string = '[STYLING_PLACEHOLDER]';

    public static buildIconUrl(baseIconUrl: string, theme: string): string {
        if (baseIconUrl.indexOf(ServiceIconUrlBuilder.PLACEHOLDER) > -1) {
            return baseIconUrl.replace(ServiceIconUrlBuilder.PLACEHOLDER, theme);
        }
        return baseIconUrl;
    }
}

<h4 *ngIf="!useH5Title" translate="common.document.title"></h4>
<h5 *ngIf="useH5Title" translate="common.document.title"></h5>

<div class="btn-bar">
    <button class="btn btn-primary" type="button"
            (click)="openAddDocumentToEquipmentModal()"
            translate="common.document.action.addDocument">
    </button>
</div>

<div class="table-responsive" *ngIf="documents.length > 0">

    <table class="table table-striped" [attr.aria-label]="'common.document.viewTitle' | translate">
        <thead>
        <tr>
            <th scope="col" translate="common.document.table.title"></th>
            <th scope="col"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let doc of documents;">
            <td class="align-middle">{{ doc.title }}</td>

            <td class="text-end">
                <button class="btn btn-link btn-sm" type="button"
                        (click)="downloadItem(doc)"
                        title="{{'common.tooltip.download' | translate}}">
                    <fa-icon [icon]="faDownload"></fa-icon>
                </button>

                <button class="btn btn-link btn-sm" type="button"
                        (click)="deleteItem(doc)"
                        title="{{'common.tooltip.delete' | translate}}">
                    <fa-icon [icon]="faTrash"></fa-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>


<div class="content-container bg-white">

    <h1 translate="cashierOperation.title"></h1>

    <h4>{{ userName }}</h4>

    <form [formGroup]="cashierTransactionForm" *ngIf="ewalletId">

        <div class="row">
            <div class="col-12 col-sm-6 col-lg-4 col-xl-2">
                <label class="form-label" for="amount">{{'cashierOperation.form.amount' | translate}}*</label>
                <div class="input-group">
                    <input class="form-control" id="amount" type="number"
                           [formControl]="amount"
                           (keydown)="FormUtils.validateInputNumberLength($event, 8)">
                    <span class="input-group-text">CHF</span>
                </div>
                <app-form-error [control]="amount"></app-form-error>
            </div>

            <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
                <label class="form-label" for="transactionType">
                    {{ 'transactions.filter.transactionType' | translate }}*
                </label>
                <select class="form-select" id="transactionType" [formControl]="transactionType">
                    <option *ngFor="let transactionType of transactionTypes" [value]="transactionType.itemId">
                        {{ transactionType.itemVal }}
                    </option>
                </select>
            </div>

            <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
                <label class="form-label" for="transactionSource">
                    {{ 'transactions.filter.transactionSource' | translate }}*
                </label>
                <select class="form-select" id="transactionSource" [formControl]="transactionSource">
                    <option *ngFor="let transactionSource of transactionSources" [value]="transactionSource.itemId">
                        {{ transactionSource.itemVal }}
                    </option>
                </select>
            </div>

            <div class="col-12 col-sm-6 col-lg-4 col-xl-3"
                 *ngIf="transactionType.value === TransactionType.ServiceConsumption">
                <label class="form-label" for="serviceId">
                    {{ 'ewallet.table.service' | translate }}*
                </label>
                <select class="form-select" id="serviceId" [formControl]="serviceId">
                    <option *ngFor="let service of services" [value]="service.serviceId">
                        {{ service['name' + crtLang.toUpperCase()] }}
                    </option>
                </select>
            </div>

            <div class="col-12 col-sm-6 col-lg-4 col-xl-3"
                 *ngIf="transactionType.value === TransactionType.EWalletCredit && transactionSource.value === TransactionSource.Cash">
                <label class="form-label" for="paymentMethod">
                    {{ 'cashierOperation.form.paymentMethod' | translate }}*
                </label>
                <select class="form-select" id="paymentMethod" [formControl]="paymentMethod">
                    <option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod.itemId">
                        {{ paymentMethod.itemVal }}
                    </option>
                </select>
            </div>

            <div class="col-12">
                <label for="comment">{{'cashierOperation.form.comment' | translate}}*</label>
                <textarea class="form-control" id="comment" type="text" maxLength="255" minlength="1"
                          [formControl]="comment" rows="3"></textarea>
                <app-form-error [control]="comment"></app-form-error>
            </div>
        </div>

        <div class="btn-bar">
            <button class="btn btn-outline-secondary" type="button"
                    (click)="cancel()"
                    translate="cashierOperation.form.cancel">
            </button>
            <button class="btn btn-primary" type="submit"
                    [disabled]="cashierTransactionForm.invalid"
                    (click)="open(confirm)"
                    translate="cashierOperation.form.submit">
            </button>
        </div>
    </form>


    <ng-template #confirm let-modal>
        <div class="modal-header">
            <h4 class="modal-title" translate="cashierOperation.modal.title"></h4>

            <button class="btn-close" aria-label="Close"
                    (click)="modal.dismiss()">
            </button>
        </div>

        <div class="modal-body">
            <span translate="cashierOperation.modal.message"></span>
        </div>

        <div class="modal-footer">
            <button class="btn btn-outline-secondary"
                    (click)="modal.dismiss()"
                    translate="cashierOperation.modal.dismiss">
            </button>
            <button class="btn btn-primary"
                    (click)="modal.close('confirm')"
                    translate="cashierOperation.modal.confirm">
            </button>
        </div>
    </ng-template>
</div>

import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class PlatformUtils {
    public static isIosNavigator(): boolean {
        return ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
    }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    ServiceService,
    WebcamDto, OrderDto,
    WebcamService
} from '../../../_services/configuration-services';
import {NotificationsService} from '../../../_shared/notifications.service';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WebcamManagementModalComponent} from './webcam-management-modal/webcam-management-modal.component';
import {faPen, faTrash, faToggleOff, faToggleOn} from '@fortawesome/free-solid-svg-icons';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {AppRoles} from '../../../app.constants';
import {Subscription} from 'rxjs';
import {
    SortableData,
    SortableListModalComponent
} from '../../../_shared/_components/sortable-list-modal/sortable-list-modal.component';

@Component({
    selector: 'app-webcam-management',
    templateUrl: './webcam-management.component.html'
})
export class WebcamManagementComponent implements OnInit, OnDestroy {
    @Input() serviceId: string;

    AppRoles = AppRoles;

    webcams: WebcamDto[] = [];

    // Icons
    icons = {
        edit: faPen,
        delete: faTrash,
        toggleOn: faToggleOn,
        toggleOff: faToggleOff
    };

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription$: Subscription;

    constructor(private readonly webcamService: WebcamService,
                private readonly serviceService: ServiceService,
                private readonly notificationsService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                private readonly translateService: TranslateService,
                private readonly modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.fetchWebcams();

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription$ = this.translateService.onLangChange
            .subscribe(_ => {
                this.crtLang = this.translateService.currentLang;
            });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    createOrEditWebcam(): void {
        const modal = this.modalService.open(WebcamManagementModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.result
            .then(value => {
                if (value === 'success') {
                    this.fetchWebcams();
                }
            }, () => { /* catch the rejection */ });
    }

    updateWebcam(webcam: WebcamDto): void {
        const modal = this.modalService.open(WebcamManagementModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.webcam = webcam;
        modal.result
            .then(value => {
                if (value === 'success') {
                    this.fetchWebcams();
                }
            }, () => { /* catch the rejection */ });
    }

    deleteWebcam(webcam: WebcamDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.webcamService.deleteWebcam(webcam.id).pipe().subscribe(_ => {
                    this.notificationsService.success({title: 'services.webcam.notifications.deleteSuccess'});
                    this.fetchWebcams();
                });
            }
        });
    }

    activateWebcam(webcam: WebcamDto): void {
        this.webcamService.activateWebcam(webcam.id).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'services.webcam.notifications.activateSuccess'});
            this.fetchWebcams();
        });
    }

    deactivateWebcam(webcam: WebcamDto): void {
        this.webcamService.deactivateWebcam(webcam.id).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'services.webcam.notifications.deactivateSuccess'});
            this.fetchWebcams();
        });
    }

    orderWebcams(): void {
        const data: SortableData[] = [];
        this.webcams.forEach(val => data.push({
            id: val.id,
            displayTextFR: val.locationFR,
            displayTextDE: val.locationDE,
            displayTextIT: val.locationIT,
            displayTextEN: val.locationEN
        }));

        const modal = this.modalService.open(SortableListModalComponent, {centered: true});
        modal.componentInstance.sortableData = data;
        modal.result
            .then(value => {
                if (value) {
                    this.saveWebcamOrder(value);
                }
            }, () => { /* catch the rejection */ });
    }

    private saveWebcamOrder(webcamsSort: SortableData[]): void {
        const webcamSortToSave = [];
        webcamsSort.forEach((value, index) => webcamSortToSave.push(new OrderDto({
            id: value.id,
            position: index
        })));

        this.webcamService.orderWebcams(this.serviceId, webcamSortToSave).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'services.webcam.notifications.orderChangeSuccess'});
            this.fetchWebcams();
        });
    }

    private fetchWebcams(): void {
        this.webcamService.getWebcamsByServiceId(this.serviceId).pipe().subscribe(webcams => {
            this.webcams = webcams;
            this.webcams.sort((a, b) => a.position - b.position);
        });
    }

}

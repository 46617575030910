<h3>{{ 'publicLighting.light.table.title' | translate }}</h3>

<div class="map-container overflow-hidden">
    <div class="btn-container">
        <div class="btn-group" role="group">
            <button id="zoom-in" type="button" class="btn btn-primary">+</button>
            <button id="zoom-out" type="button" class="btn btn-primary">-</button>
        </div>
    </div>

    <div class="spinner-container spinner-border text-secondary" *ngIf="mapIsLoading"
         role="status">
    </div>

    <svg class="map" id="d3_map" width="1240" height="500"></svg>
</div>


<!-- Filters -->
<div class="row" [formGroup]="filterForm">

    <div class="default-filter-form-field">
        <label class="form-label" for="code" translate="publicLighting.light.table.code"></label>
        <input class="form-control" type="text" id="code"
               pattern="{{textInputPattern}}"
               placeholder='{{"publicLighting.light.table.code" | translate}}'
               [formControl]="code">
    </div>

    <div class="default-filter-form-field">
        <label class="form-label" for="description" translate="publicLighting.light.table.description"></label>
        <input class="form-control" type="text" id="description"
               pattern="{{textInputPattern}}"
               placeholder='{{"publicLighting.light.table.description" | translate}}'
               [formControl]="description">
    </div>

    <div class="default-filter-form-field">
        <label class="form-label" for="zoneCode" translate="publicLighting.light.table.zoneCode"></label>
        <input class="form-control" type="text" id="zoneCode"
               pattern="{{textInputPattern}}"
               placeholder='{{"publicLighting.light.table.zoneCode" | translate}}'
               [formControl]="lightZoneCode">
    </div>

</div>

<div class="btn-bar">
    <button class="btn btn-outline-secondary"
            [disabled]="!filterNotEmpty"
            (click)="clearFilter()"
            translate="common.clearFilter">
    </button>
</div>

<ngx-datatable class="material"
               (page)="setPage($event)"
               (sort)="onSort($event)"
               [columnMode]="'force'"
               [count]="lightsForTable.rowCount"
               [externalPaging]="true"
               [externalSorting]="true"
               [footerHeight]="50"
               [headerHeight]="50"
               [rowHeight]="50"
               [reorderable]="false"
               [limit]="lightsForTable.pageSize"
               [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                    }"
               [offset]="crtPage"
               [rows]="lightsForTable.results"
               [scrollbarH]="true">

    <ngx-datatable-column [width]="100" name="{{'publicLighting.light.table.code' | translate}}" prop="code">
        <ng-template>
                <span [ngClass]="'padding-header-cell-filter'"
                      translate="publicLighting.light.table.code"></span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="250" name="{{'publicLighting.light.table.description' | translate}}"
                          prop="description{{crtLang.toUpperCase()}}">
        <ng-template>
                <span class="sort-btn" [ngClass]="'padding-header-cell-filter'"
                      translate="publicLighting.light.table.description"></span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="250" name="{{'publicLighting.light.table.zoneCode' | translate}}"
                          prop="lightZoneCode">
        <ng-template>
                <span class="sort-btn" [ngClass]="'padding-header-cell-filter'"
                      translate="publicLighting.light.table.zoneCode"></span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="50" name="" [sortable]="false">

        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="btn btn-link btn-sm"
                  (click)="showLightDetails(row.id)"
                  (keyup)="showLightDetails(row.id)"
                  title="{{'common.tooltip.details' | translate}}">
                <fa-icon [icon]="faEye"></fa-icon>
            </span>
        </ng-template>

    </ngx-datatable-column>

</ngx-datatable>

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    BookingDto, BookingServiceConfigDto, ItemToBookAnswerType,
    ItemToBookQuestionDto,
    ItemToBookQuestionService
} from '../../../../_services/configuration-services';
import {TranslateUtils} from '../../../../_shared/translate-utils';
import {firstValueFrom, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {DateUtils} from '../../../../_shared/date-utils';

@Component({
  selector: 'app-booking-viewer',
  templateUrl: './booking-viewer.component.html'
})
export class BookingViewerComponent implements OnInit, OnDestroy {

    @Input() booking: BookingDto = null!;
    @Input() bookingServiceConfig: BookingServiceConfigDto = null!;
    @Input() hasAdminRoleForBooking = false;
    @Input() userId: string | null = null;

    answers = new Map<string, string>();
    questions: ItemToBookQuestionDto[] = [];

    ItemToBookAnswerType = ItemToBookAnswerType;
    DateUtils = DateUtils;

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription = null!;

    constructor(private readonly itemToBookQuestionService: ItemToBookQuestionService,
                private readonly translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                }
            });

        this.booking.answers?.forEach(a => this.answers.set(a.itemQuestionId, a.answer));
        this.fetchQuestions(this.booking.itemToBook.id);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    async fetchQuestions(itemId: string): Promise<void> {
        this.questions = await firstValueFrom(this.itemToBookQuestionService.getAll(itemId));
    }

}

import {Pipe, PipeTransform} from '@angular/core';
import {NumeralService} from './numeral.service';

@Pipe({name: 'numberFormat'})
export class NumberFormatPipe implements PipeTransform {

    constructor(private readonly numeral: NumeralService) {
    }

    transform(value: number | null, format: string | undefined | null = null): string {
        return this.numeral.format(value, format);
    }
}

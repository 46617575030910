import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {faPen, faQrcode, faTrash} from '@fortawesome/free-solid-svg-icons';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {firstValueFrom, Subscription} from 'rxjs';
import {
    ChargingStationDto,
    ChargingStationService
} from '../../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';
import {AppRoles} from '../../../app.constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    ChargingStationManagementModalComponent
} from './charging-stations-management-modal/charging-station-management-modal.component';
import {ConfirmModalService} from "../../../_shared/_components/confirm-modal/confirm-modal.component";
import {NotificationsService} from "../../../_shared/notifications.service";

@Component({
    selector: 'app-charging-station-management',
    templateUrl: './charging-station-management.component.html'
})
export class ChargingStationManagementComponent implements OnInit, OnDestroy {

    @Input() serviceId: string;

    chargingStations: ChargingStationDto[] = [];

    AppRoles = AppRoles;

    // Icons
    icons = {
        edit: faPen,
        delete: faTrash,
        qrcode: faQrcode
    };

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription$: Subscription;

    constructor(private readonly chargingStationService: ChargingStationService,
                private readonly translateService: TranslateService,
                private readonly confirmService: ConfirmModalService,
                private readonly notificationService: NotificationsService,
                private readonly modalService: NgbModal) {
    }

    async ngOnInit(): Promise<void> {
        await this.fetchChargingStations();

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription$ = this.translateService.onLangChange
            .subscribe(_ => {
                this.crtLang = this.translateService.currentLang;
            });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$.unsubscribe();
    }

    private async fetchChargingStations(): Promise<void> {
        this.chargingStations = await firstValueFrom(this.chargingStationService.getChargingStationsForService(this.serviceId));
    }

    createChargingStation(): void {
        const modal = this.modalService.open(ChargingStationManagementModalComponent, {centered: true, size: 'lg'});

        modal.componentInstance.serviceId = this.serviceId;
        modal.result
            .then(async value => {
                    if (value === 'success') {
                        await this.fetchChargingStations();
                    }
                },
                () => { /* catch the rejection */
                }
            );
    }

    updateChargingStation(chargingStation: ChargingStationDto): void {
        const modal = this.modalService.open(ChargingStationManagementModalComponent, {centered: true, size: 'lg'});

        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.chargingStation = chargingStation;

        modal.result
            .then(async value => {
                    if (value === 'success') {
                        await this.fetchChargingStations();
                    }
                },
                () => { /* catch the rejection */ }
            );
    }

    deleteChargingStation(chargingStation: ChargingStationDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.chargingStationService.deleteChargingStation(chargingStation.id).pipe().subscribe(async _ => {
                    this.notificationService.success({title: 'services.chargingStations.notifications.deleteSuccess'});
                    await this.fetchChargingStations();
                });
            }
        });
    }
}

import { Component, Input, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
    HydrowashWashingTrackDto, HydrowashWashingTrackService
} from '../../../../_services/configuration-services';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {FormUtils} from '../../../../_shared/form-utils';

@Component({
  selector: 'app-hydrowash-modal',
  templateUrl: './hydrowash-modal.component.html'
})
export class HydrowashModalComponent implements OnInit {

    @Input() track?: HydrowashWashingTrackDto;
    @Input() lastTrackId?: string;

    trackForm: UntypedFormGroup;
    textInputPattern = FormUtils.textInputPattern;

    constructor(public activeModal: NgbActiveModal,
                private readonly notificationsService: NotificationsService,
                private readonly hydrowashWashingTrackService: HydrowashWashingTrackService) {

        this.trackForm = new UntypedFormGroup({
                trackId: new UntypedFormControl('', Validators.required),
                trackName: new UntypedFormControl('', Validators.required),
                trackDescription: new UntypedFormControl('')
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        if (this.track) {
            this.trackForm.get('trackId').setValue(this.track.id);
            this.trackForm.get('trackName').setValue(this.track.name);
            this.trackForm.get('trackDescription').setValue(this.track.description);
        }

        if (this.lastTrackId) {
            const lastNumber = parseInt(this.lastTrackId.slice('PISTE'.length), 10);
            this.trackForm.get('trackId').setValue('PISTE' + (lastNumber + 1));
        }
    }

    async createOrUpdateTrack(): Promise<void> {
        if (this.track) {
            // Update the track
            this.track.name = this.trackForm.value.trackName;
            this.track.description = this.trackForm.value.trackDescription;

            this.hydrowashWashingTrackService.updateTrack(this.track.id, this.track).pipe().subscribe(() => {
                this.notificationsService.success({title: 'services.hydrowash.notifications.updateSuccess'});
                this.activeModal.close();
            });

        } else {
            // Create the track
            const newTrack = new HydrowashWashingTrackDto();
            newTrack.id = this.trackForm.value.trackId;
            newTrack.name = this.trackForm.value.trackName;
            newTrack.description = this.trackForm.value.trackDescription;

            this.hydrowashWashingTrackService.createTrack(newTrack).pipe().subscribe(() => {
                this.notificationsService.success({title: 'services.hydrowash.notifications.addSuccess'});
                this.activeModal.close();
            });
        }
    }

    get trackName(): AbstractControl {
        return this.trackForm.get('trackName');
    }
}

<div *ngIf="!confirmBillingViewEnable">
    <div class="modal-header">
        <h4 class="modal-title" translate>services.craning.booking.form.modal.confirmation.title</h4>

        <button class="btn-close" aria-label="Close"
                (click)="craningBookingFormConfirmationModal.dismiss('Cross click')">

        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="craningBookingFormConfirmationModalForm">

            <div class="row" *ngIf="mode !== CraningBookingFormMode.BillingReservation">

                <!-- From -->
                <div class="col-sm-6">
                    <label class="form-label" translate>services.craning.booking.form.modal.confirmation.from</label>
                    <div>
                        {{'services.craning.booking.form.modal.confirmation.dateFormat' | translate : {
                        date: selectedSlot.date | date,
                        time: DateUtils.formatTime(selectedSlot.startTime)}
                        }}
                    </div>
                </div>

                <!-- TO -->
                <div class="col-sm-6">
                    <label class="form-label" translate>services.craning.booking.form.modal.confirmation.to</label>
                    <div>
                        {{'services.craning.booking.form.modal.confirmation.dateFormat' | translate : {
                        date: selectedSlot.date | date,
                        time: DateUtils.formatTime(selectedSlot.endTime)}
                        }}
                    </div>
                </div>
            </div>


            <div class="row">
                <!-- Deposit price -->
                <div class="col-sm-6" *ngIf="mode === CraningBookingFormMode.NewReservation">
                    <label class="form-label" translate>services.craning.booking.form.modal.confirmation.bookingDepositPrice</label>
                    <div>
                        {{ tariffDeposit.tariffPrices[0].priceByUnit | currencyFormat }}
                    </div>
                </div>

                <!-- Total price -->
                <div class="col-sm-6">
                    <label class="form-label" translate>services.craning.booking.form.modal.confirmation.bookingTotalPrice</label>
                    <div>
                        {{ totalPrice | currencyFormat }}
                    </div>
                </div>
            </div>

            <!-- Payment type -->
            <div class="form-group" *ngIf="!paymentType.disabled && availablePaymentTypes.length > 1">

                <label class="form-label">{{ 'common.terms.paymentType' | translate }}*</label>
                <div>
                    <app-payment-type-chooser
                            [availablePaymentTypes]="availablePaymentTypes"
                            (selectedPaymentType)="selectPaymentType($event)"></app-payment-type-chooser>
                </div>
                <app-form-error [control]="paymentType"></app-form-error>
            </div>

            <app-show-cgv [formGroup]="craningBookingFormConfirmationModalForm">
            </app-show-cgv>

        </form>
    </div>

    <div class="modal-footer">

        <button type="button" class="btn btn-outline-secondary"
                (click)="craningBookingFormConfirmationModal.close()" translate>
            common.cancel
        </button>
        <button type="submit" (click)="book()" class="btn btn-primary"
                [disabled]="craningBookingFormConfirmationModalForm.invalid" translate>
            common.save
        </button>

    </div>
</div>


<div *ngIf="confirmBillingViewEnable">
    <div class="modal-header">
        <h4 class="modal-title" translate>services.craning.booking.form.modal.confirmation.billing.title</h4>
        <button class="btn-close" aria-label="Close" (click)="toggleConfirmBillingView()">

        </button>
    </div>

    <div class="modal-body">
        <p class="translated-paragraph" translate>services.craning.booking.form.modal.confirmation.billing.message</p>
        <!-- Deposit price -->
        <div class="col-sm-6">
            <label class="form-label" translate>services.craning.booking.form.modal.confirmation.bookingDepositPrice</label>
            <div>
                {{ tariffDeposit.tariffPrices[0].priceByUnit | currencyFormat }}
            </div>
        </div>
    </div>


    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="toggleConfirmBillingView()" translate>
            common.cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="book()" translate>
            common.save
        </button>
    </div>
</div>

<h4 translate="services.publicLighting.lightZone.title"></h4>

<div class="btn-bar">
    <button class="btn btn-primary" [appHasAnyRole]="[AppRoles.admin]"
            (click)="openCreateLightZoneModal()"
            translate="services.publicLighting.lightZone.actions.add">
    </button>
</div>

<div class="table-responsive mt-4">
    <table class="table table-striped"
           [attr.aria-label]="'services.publicLighting.lightZone.table.title' | translate">
        <thead>
        <tr>
            <th scope="col" style="min-width: 150px"
                translate="services.publicLighting.lightZone.table.code">
            </th>
            <th scope="col" style="min-width: 300px"
                translate="services.publicLighting.lightZone.table.description">
            </th>
            <th scope="col" style="min-width: 150px"
                translate="services.publicLighting.lightZone.table.lightCount">
            </th>
            <th scope="col" style="min-width:125px"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="lightingZoneTable.length === 0">
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td></td>
        </tr>

        <tr *ngFor="let lightZone of lightingZoneTable">
            <td>{{ lightZone.code }}</td>

            <td>{{ lightZone['description' + currentLang.toUpperCase()] }}</td>

            <td>{{ lightZone.lightCount }}</td>

            <td class="text-end">
                <button class="btn btn-link btn-sm"
                        (click)="openEditLightZoneModal(lightZone)"
                        title="{{'common.tooltip.edit' | translate}}">
                    <fa-icon [icon]="icons.edit"></fa-icon>
                </button>

                <button class="btn btn-link btn-sm"
                        (click)="openLinkLightsModal(lightZone.id)"
                        title="{{'services.publicLighting.light.assign' | translate}}">
                    <fa-icon [icon]="icons.link"></fa-icon>
                </button>

                <button class="btn btn-link btn-sm"
                        (click)="openDeleteModal(lightZone.id)"
                        title="{{'services.publicLighting.lightZone.actions.delete' | translate}}">
                    <fa-icon [icon]="icons.delete"></fa-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>

import {Component, Input} from '@angular/core';
import {VisitorContactDto} from '../../../_services/configuration-services';

@Component({
  selector: 'app-user-visitor-summary',
  templateUrl: './user-visitor-summary.component.html'
})
export class UserVisitorSummaryComponent {

    @Input() hasAdminRole = false; // Depending on context (and not only the roles assigned to the user)

    @Input() visitor: VisitorContactDto | null = null;

}

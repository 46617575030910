import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DateRange} from '../../../../_shared/_components/date-range-picker/date-range-picker.component';
import {
    ConsumptionCockpitDataDto,
    EnergyService,
    MeasuringPointType,
    SearchMeasuringPointResultDto, SortCriteriaDto, SortDirection
} from '../../../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {firstValueFrom, Subscription} from 'rxjs';
import {ConsumptionData} from '../consumption-bar-chart/consumption-bar-chart.component';
import {DateUtils} from '../../../../_shared/date-utils';
import {TranslateUtils} from '../../../../_shared/translate-utils';

@Component({
    selector: 'app-cockpit-energy-consumption',
    templateUrl: './cockpit-energy-consumption.component.html',
    styleUrls: ['./cockpit-energy-consumption.component.scss']
})
export class CockpitEnergyConsumptionComponent implements OnInit, OnDestroy {

    @Input() serviceId: string;

    dateFormat = DateUtils.dateFormat;

    dateRange: DateRange;
    measuringPoint: SearchMeasuringPointResultDto | null;

    adminFirstSort: SortCriteriaDto = new SortCriteriaDto({
        direction: SortDirection.Asc,
        property: 'MeasuringPointType'
    });

    cockpitData: ConsumptionCockpitDataDto;
    consumptionData: ConsumptionData[];

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription$: Subscription;

    constructor(
        private readonly translateService: TranslateService,
        private readonly energyService: EnergyService,
        private readonly datePipe: DatePipe) {
    }

    ngOnInit(): void {
        this.setupLanguage();
        this.langChangeSubscription$ = this.translateService.onLangChange
            .subscribe(_ => {
                this.setupLanguage();
                window.location.reload();
            });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$?.unsubscribe();
    }

    setupLanguage(): void {
        this.crtLang = this.translateService.currentLang;
    }

    async dateRangeChange(event: DateRange): Promise<void> {
        this.dateRange = event;
        this.dateRange.end.setHours(23, 59, 59, 999);
        await this.fetchCockpitData();
    }

    async measuringPointSelected(measuringPoint: SearchMeasuringPointResultDto | null): Promise<void> {
        this.measuringPoint = measuringPoint;
        await this.fetchCockpitData();
    }

    async fetchCockpitData(): Promise<void> {
        if (this.measuringPoint) {

            this.cockpitData = await firstValueFrom(this.energyService.getDataForConsumptionCockpit(this.measuringPoint.id,
                this.formatDateForCockpitData(this.dateRange.start),
                this.formatDateForCockpitData(this.dateRange.end)));

            this.consumptionData = this.cockpitData.consumptionMeasures.map<ConsumptionData>(c => ({
                value: c.value,
                date: c.dateTime
            }));

        } else {
            this.cockpitData = null;
            this.consumptionData = [];
        }
    }

    formatDate(date: Date): string {
        return this.datePipe.transform(date, this.dateFormat);
    }

    private formatDateForCockpitData(date: Date): string {
        return [`0${date.getDate()}`.substr(-2), `0${date.getMonth() + 1}`.substr(-2), `${date.getFullYear()}`].join('-');
    }
}

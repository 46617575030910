<div class="content-container" *ngIf="wifiUserSubscriptions.length > 0">

    <h4 translate="services.wifi.subscriptions.userSubscriptions.title"></h4>

    <div class="row row-cols-1 g-4">

        <div *ngFor="let wifiSubscription of wifiUserSubscriptions" class="col">

            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">
                        {{getServiceName(wifiSubscription)}}
                        <span *ngIf="wifiSubscription.disabled" class="text-warning" translate="services.wifi.subscriptions.userSubscriptions.disabled"></span>
                    </h5>

                    <div class="mb-3 row">
                        <div class="col-sm-3" translate="services.wifi.subscriptions.userSubscriptions.period.label"></div>
                        <div class="col-sm-9">
                            {{'common.period.from' | translate}} {{wifiSubscription.startDateTime | date:getDisplayDateFormat()}}<br/>
                            {{'common.period.to' | translate}} {{wifiSubscription.endDateTime | date:getDisplayDateFormat()}}
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <div class="col-sm-3" translate="services.wifi.subscriptions.userSubscriptions.ssid"></div>
                        <div class="col-sm-9">{{wifiSubscription.wifiSsid}}</div>
                    </div>

                    <div class="mb-3 row">
                        <div class="col-sm-3" translate="services.wifi.subscriptions.userSubscriptions.password"></div>
                        <div class="col-sm-9">{{wifiSubscription.password}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

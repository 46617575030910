<div class="modal-header">

    <h4 class="modal-title">{{ (isDeviceEditMode ? 'alarms.device.modal.title.edit' : 'alarms.device.modal.title.add') | translate }}</h4>

    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">

    <form [formGroup]="deviceForm">

        <div class="row">

            <!-- Left Form - Base Config -->
            <div class="col-12 col-lg-6">
                <h4 translate="alarms.device.modal.section.device"></h4>

                <div class="row">

                    <!-- IoT Register -->
                    <div class="col-12">
                        <label class="form-label" for="iotRegister">{{ 'alarms.device.modal.form.designation' | translate }}*</label>
                        <app-search-iot-register id="iotRegister" #searchIotRegisterComponent
                                                 (selectedRegisterEvent)="iotRegister.setValue($event)"
                                                 [defaultRegister]="alarmDevice?.iotRegister"
                                                 [registerTypes]="[RegisterType.Alarm]"
                                                 [measuringPointType]="MeasuringPointType.Admin"
                                                 [disabled]="isDeviceEditMode"></app-search-iot-register>
                    </div>

                    <!-- Identifier -->
                    <div class="col-12" *ngIf="iotRegister.value">
                        <label class="form-label" for="identifier">{{ 'alarms.device.modal.form.identifier' | translate }}*</label>
                        <input class="form-control" id="identifier" type="text" name="identifier"
                               [value]="iotRegister.value.name" disabled />
                    </div>

                    <!-- Equipment -->
                    <div class="col-12" *ngIf="iotRegister.value">
                        <label class="form-label" for="equipment">{{ 'alarms.device.modal.form.equipment' | translate }}*</label>
                        <input class="form-control" id="equipment" type="text" name="equipment"
                               [value]="getIotRegisterEquipment(iotRegister.value)" disabled />
                    </div>

                    <!-- Location -->
                    <div class="col-12">
                        <label class="form-label" for="location">{{ 'alarms.device.modal.form.location' | translate }}*</label>
                        <input class="form-control" id="location" type="text" name="location"
                               formControlName="location"
                               maxlength="50" pattern="{{textInputPattern}}"/>
                        <app-form-error  [control]="location"></app-form-error>
                    </div>

                    <!-- Classification -->
                    <div class="col-12">
                        <label class="form-label" for="classification">{{ 'alarms.device.modal.form.classification' | translate }}*</label>
                        <select class="form-control form-select" id="classification" name="classification"
                                formControlName="classification">
                            <option *ngFor="let classification of availableClassifications;"
                                    [ngValue]="classification">{{ 'common.alarmClassification.' + classification | translate }}</option>
                        </select>
                        <app-form-error  [control]="classification"></app-form-error>
                    </div>

                    <!-- State -->
                    <div class="col-12">
                        <label class="form-label">{{ 'alarms.device.modal.form.state' | translate }}*</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" id="deviceState" type="checkbox" name="deviceState"
                                   formControlName="deviceState" />
                            <label class="form-check-label" for="deviceState">
                                {{ (this.deviceState.value ? 'alarms.device.state.active' : 'alarms.device.state.inactive') | translate }}
                            </label>
                        </div>
                    </div>

                    <div class="col-12">
                        <label class="form-label">{{ 'alarms.device.modal.form.comment' | translate }}</label>
                        <textarea class="form-control" id="comment" name="comment"
                                  formControlName="comment"
                                  pattern="{{textInputPattern}}" maxlength="255"></textarea>
                    </div>

                </div>

            </div>

            <!-- Right Form - Notification Config -->
            <div class="col-12 col-lg-6">

                <h4 translate="alarms.device.modal.section.notifications"></h4>

                <div class="row">

                    <!-- Notification Delay -->
                    <div class="col-12">
                        <label class="form-label" for="notificationDelay">{{ 'alarms.device.modal.form.notificationDelay' | translate }}*</label>
                        <input class="form-control col-lg-3 col-sm-6 col-12" id="notificationDelay" type="number" name="notificationDelay"
                               formControlName="notificationDelay"
                               onclick="this.focus();"
                               min="0" max="60" />
                        <app-form-error  [control]="notificationDelay"></app-form-error>
                    </div>

                    <!-- Notification Loop -->
                    <div class="col-12 mt-2" *ngIf="notificationDelay.value > 0">
                        <label>{{ 'alarms.device.modal.form.notificationLoop' | translate }}*</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" id="notificationLoop" type="checkbox" name="notificationLoop"
                                   formControlName="notificationLoop" />
                            <label class="form-check-label" for="notificationLoop">
                                {{ (this.notificationLoop.value ? 'common.state.active' : 'common.state.inactive') | translate }}
                            </label>
                        </div>
                    </div>

                </div>

                <div class="row mt-2" *ngIf="!!alarmDevice">

                    <div class="col-12">
                        <h4 translate="alarms.device.modal.section.receivers"></h4>

                        <table class="table table-striped" *ngIf="!displayReceiverForm"
                               [attr.aria-label]="'alarms.device.modal.section.receivers' | translate"
                               aria-hidden="true">
                            <tr *ngFor="let receiver of alarmDevice.alarmDeviceReceivers">
                                <td>
                                    <span>{{ receiver.contactName }}</span>
                                    <br />
                                    <span>{{ receiver.email }}</span>
                                </td>

                                <td class="text-end">
                                    <button class="btn btn-link btn-sm" type="button"
                                            (click)="toggleReceiverForm(receiver)"
                                            title="{{'common.tooltip.edit' | translate}}">
                                        <fa-icon [icon]="icons.edit"></fa-icon>
                                    </button>
                                    <button class="btn btn-link btn-sm" type="button"
                                            (click)="deleteReceiver(receiver)"
                                            title="{{'common.tooltip.delete' | translate}}">
                                        <fa-icon [icon]="icons.delete"></fa-icon>
                                    </button>
                                </td>
                            </tr>
                        </table>

                        <div class="btn-bar" *ngIf="!displayReceiverForm">
                            <button type="button" class="btn btn-primary"
                                    (click)="toggleReceiverForm()"
                                    translate="alarms.device.modal.button.addReceiver">
                            </button>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <form [formGroup]="receiverForm" *ngIf="displayReceiverForm">

                        <div class="col-12">
                            <label class="form-label" for="contactName">{{ 'alarms.device.modal.form.receiver.contactName' | translate }}*</label>
                            <input class="form-control" id="contactName" type="text" name="contactName"
                                   formControlName="contactName"
                                   maxlength="50" pattern="{{textInputPattern}}"/>
                            <app-form-error [control]="contactName"></app-form-error>
                        </div>

                        <div class="col-12">
                            <label class="form-label" for="email">{{ 'alarms.device.modal.form.receiver.email' | translate }}*</label>
                            <input class="form-control" id="email" type="text" name="email"
                                   formControlName="email"
                                   maxlength="75" pattern="{{textInputPattern}}"/>
                            <app-form-error [control]="email"></app-form-error>
                        </div>

                        <div class="col-12">
                            <label class="form-label">{{ 'alarms.device.modal.form.receiver.state' | translate }}</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input" id="receiverState" type="checkbox" name="receiverState"
                                       formControlName="receiverState" />
                                <label class="form-check-label" for="receiverState">
                                    {{ (this.receiverState.value ? 'alarms.device.modal.receiverState.active' : 'alarms.device.modal.receiverState.inactive') | translate }}
                                </label>
                            </div>
                        </div>

                        <div class="col-12">
                            <label class="form-label">{{ 'alarms.device.modal.form.receiver.reachable' | translate }}</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input" id="duringOfficeHour" type="checkbox" name="duringOfficeHour"
                                       formArrayName="officeHour"
                                       [formControl]="officeHour.get('0')"
                                       [value]="officeHour.value[0]"/>
                                <label class="form-check-label" for="duringOfficeHour" translate="alarms.device.modal.form.receiver.duringOfficeHour"></label>
                            </div>
                            <div class="form-check form-switch">
                                <input class="form-check-input" id="outsideOfficeHour" type="checkbox" name="outsideOfficeHour"
                                       formArrayName="officeHour"
                                       [formControl]="officeHour.get('1')"
                                       [value]="officeHour.value[1]" />
                                <label class="form-check-label" for="outsideOfficeHour" translate="alarms.device.modal.form.receiver.outsideOfficeHour"></label>
                            </div>
                        </div>

                        <div class="col-12">
                            <label class="form-label">{{ 'services.userAlarm.modal.form.transmissionMode' | translate }}</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input" id="checkbox-mail" type="checkbox" name="checkbox-mail"
                                       formArrayName="transmissionMode"
                                       [formControl]="transmissionMode.get('0')"
                                       [value]="transmissionMode.value[0]"/>
                                <label class="form-check-label" for="checkbox-mail" translate="services.userAlarm.modal.form.transmissionModes.mail"></label>
                            </div>
                            <div class="form-check form-switch">
                                <input class="form-check-input" id="checkbox-push" type="checkbox" name="checkbox-push"
                                       formArrayName="transmissionMode"
                                       [formControl]="transmissionMode.get('1')"
                                       [value]="transmissionMode.value[1]"/>
                                <label class="form-check-label" for="checkbox-push" translate="services.userAlarm.modal.form.transmissionModes.push"></label>
                            </div>
                        </div>

                        <div class="btn-bar">
                            <button type="button" class="btn btn-outline-secondary"
                                    (click)="toggleReceiverForm()"
                                    translate="common.cancel">
                            </button>

                            <button type="submit" class="btn btn-primary"
                                    (click)="saveReceiver()"
                                    [disabled]="receiverForm.invalid"
                                    translate="alarms.device.modal.button.saveReceiver">
                            </button>
                        </div>

                    </form>
                </div>

            </div>

        </div>
    </form>
</div>


<div class="modal-footer" *ngIf="!displayReceiverForm">
    <button type="button" class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.close">
    </button>

    <button type="submit" class="btn btn-primary"
            (click)="deviceForm.touched ? saveDevice() : activeModal.close()"
            [disabled]="deviceForm.invalid">
        {{ (isDeviceEditMode ? 'common.save' : 'common.add') | translate }}
    </button>
</div>

<div class="modal-header">
    <h4 class="modal-title" translate="common.cancelBookingPartially"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="cancel('Cross click')">
    </button>
</div>

<div class="modal-body">
    <p class="translated-paragraph" translate="bookings.modal.cancelBookingPartialMessage"></p>

    <app-date-period-picker [required]="true"
                            [fromMinDate]="minDate"
                            [toMinDate]="minDate"
                            [periodControl]="datePeriod"></app-date-period-picker>

</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="cancel('Cross click')"
            translate="common.back">
    </button>

    <button class="btn btn-primary"
            [disabled]="!datePeriod.valid"
            (click)="validate('confirm')"
            translate="common.confirm">
    </button>
</div>

<div class="btn-bar">
    <button *ngIf="selectedDate || selectedBooking || selectedSlot"
            class="btn btn-outline-secondary"
            translate="common.close"
            (click)="this.closeForm.emit()">
    </button>

</div>

<div *ngIf="selectedDate || selectedBooking || selectedSlot">

    <h3 *ngIf="!selectedBooking; else editBooking" translate="bookings.add"></h3>
    <ng-template #editBooking>
        <h3 translate="bookings.edit"></h3>
    </ng-template>

    <div [formGroup]="bookingForm">

        <div class="row">

            <legend *ngIf="bookingServiceConfig.bookingMode === BookingMode.Slots">
                <small class="text-danger" *ngIf="!selectedSlot" translate="bookings.selectedSlot.none"></small>
                <small class="font-weight-bold" *ngIf="selectedSlot"
                       [translateParams]="{
                            date: this.selectedSlot.date | date : DateUtils.dateFormat,
                            startTime: DateUtils.formatTime(this.selectedSlot.startTime),
                            endTime: DateUtils.formatTime(this.selectedSlot.endTime)
                       }" translate="bookings.selectedSlot.date">
                </small>
            </legend>

            <!-- Booking/Blocking Chooser -->
            <div class="row" *ngIf="hasAdminRoleForBooking && bookingServiceConfig.bookingMode !== BookingMode.Slots">
                <div class="col-12">
                    <div>
                        <div class="btn-group" role="group">
                            <input class="btn-check" type="radio"
                                   [formControl]="reservationType"
                                   [value]="ReservationType.Booking">
                            <label class="btn btn-toggle btn-toggle-left h-auto"
                                   [ngClass]="{ active: reservationType.value === ReservationType.Booking }"
                                   translate="common.reservationType.Booking"
                                   (click)="setReservationType(ReservationType.Booking)"
                                   (keyup)="setReservationType(ReservationType.Booking)"></label>

                            <input class="btn-check" type="radio"
                                   [formControl]="reservationType"
                                   [value]="ReservationType.Blocking">
                            <label class="btn btn-toggle btn-toggle-right h-auto"
                                   [ngClass]="{ active: reservationType.value === ReservationType.Blocking }"
                                   translate="common.reservationType.Blocking"
                                   (click)="setReservationType(ReservationType.Blocking)"
                                   (keyup)="setReservationType(ReservationType.Blocking)"></label>
                        </div>
                    </div>

                    <app-form-error [control]="debtorType"></app-form-error>
                </div>
            </div>

            <!-- Questions / Answers -->
            <app-item-with-questions-answers-form #itemWithAnswersFormComponent
                                                  [availableItems]="availableItems"
                                                  [reservationType]="reservationType.value"
                                                  [disableItemToBook]="bookingServiceConfig.bookingMode === BookingMode.Slots"
                                                  [selectedItemId]="selectedSlot?.itemToBookId ?? selectedBooking?.itemToBook.id ?? selectedItemId"
                                                  [itemWithAnswerForm]="itemWithAnswerForm"
                                                  [answers]="selectedBooking?.answers"
                                                  [crtLang]="crtLang"></app-item-with-questions-answers-form>

            <!-- Dates / Times -->
            <div class="col-12"
                 [ngClass]="{ 'd-none' : bookingServiceConfig.bookingMode === BookingMode.Slots }">
                <app-date-time-period-picker #dateTimeFormComponent
                                             [dateTimeForm]="dateRangeForm"
                                             [baseStartDate]="baseStartDate"
                                             [canStartInPast]="!!selectedBooking && isRecurrent.value"
                                             [baseStartTime]="baseStartTime"
                                             [baseEndDate]="baseEndDate"
                                             [baseEndTime]="baseEndTime"
                                             [bookingDuration]="bookingServiceConfig.initialBookingDuration"
                                             [bookingTimeUnit]="bookingServiceConfig.bookingTimeUnit"
                                             [disableStartTime]="false"
                                             [disableEndDateTime]="false"
                                             [minStartDate]="minStartDate"
                                             [maxStartDate]="maxStartDate"></app-date-time-period-picker>
            </div>

            <!-- Recurrence -->
            <div class="col-12" *ngIf="this.bookingServiceConfig.bookingMode === BookingMode.Free &&
                                       (this.hasAdminRoleForBooking || this.bookingServiceConfig.recurrentBookingAvailable)">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" name="recurrent-checkbox" id="recurrent-checkbox"
                           [formControl]="isRecurrent"
                           (change)="setIsRecurrent(isRecurrent.value)">
                    <label class="form-check-label" for="recurrent-checkbox" translate="bookings.modal.isRecurrent"></label>
                </div>
            </div>

            <!-- Days -->
            <app-days-picker #recurrentDaysPicker *ngIf="isRecurrent.value"
                             [form]="bookingForm"
                             [initialDays]="recurrenceDays"
                             [defaultActivation]="true"></app-days-picker>

            <!-- User or Visitor selector -->
            <app-user-visitor-selector #userVisitorSelectorComponent *ngIf="reservationType.value === ReservationType.Booking"
                                       [visitor]="selectedBooking?.visitorContact"
                                       [userId]="selectedBooking?.userId || this.userId"
                                       [hasAdminRole]="hasAdminRoleForBooking"
                                       [debtorType]="debtorType"
                                       [userIdControl]="userIdControl"
                                       [visitorContactForm]="visitorContactForm"
                                       (debtorTypeChange)="configurePaymentTypes()"></app-user-visitor-selector>

            <!-- Comment -->
            <div class="col-12">
                <label class="form-label">{{ 'bookings.modal.user.comment' | translate }}</label>
                <textarea class="form-control" id="comment" name="bookingComment"
                          [formControl]="comment"
                          placeholder="{{'bookings.modal.user.comment' | translate}}"
                          maxlength="255"></textarea>

                <app-form-error [control]="comment"></app-form-error>
            </div>

            <!-- Payment Types-->
            <div class="col-12" *ngIf="paymentType.enabled && availablePaymentTypes.length > 1">
                <label class="form-label">{{ 'common.terms.paymentType' | translate }}*</label>
                <div>
                    <app-payment-type-chooser
                        [availablePaymentTypes]="availablePaymentTypes"
                        (selectedPaymentType)="selectPaymentType($event)"></app-payment-type-chooser>
                </div>
            </div>

            <app-show-cgv *ngIf="paymentType.enabled"
                          [formGroup]="bookingForm">
            </app-show-cgv>

        </div>

        <div class="btn-bar">
            <button class="btn btn-primary" type="submit"
                    [disabled]="bookingForm.invalid || bookingForm.pristine"
                    (click)="openSaveBookingConfirmModal()"
                    translate="common.save">
            </button>
        </div>

    </div>
</div>

<h3 translate="publicLighting.lightZone.schedule.title"></h3>

<div class="btn-bar">
    <button class="btn btn-primary"
            (click)="openSchedulePeriodModal()"
            translate="publicLighting.lightZone.schedule.add">
    </button>
</div>

<ngx-datatable class="material"
               [columnMode]="'force'"
               [rows]="lightZonesSchedule"
               [headerHeight]="50"
               [footerHeight]="50"
               [reorderable]="false"
               rowHeight="50"
               [externalPaging]="true"
               [externalSorting]="true"
               [count]="lightZonesSchedule.length"
               [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                }"
               (sort)="onSort($event)"
               [scrollbarH]="true">

    <ngx-datatable-column prop="lightZoneCode" name="{{ 'publicLighting.lightZone.table.code' | translate }}"
                          [width]="100"
                          [sortable]="true">
        <ng-template ngx-datatable-cell-template let-row="row">
            {{ row.lightZoneCode }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="mode" name="{{ 'publicLighting.lightZone.table.mode' | translate }}"
                          [width]="200"
                          [sortable]="true">

        <ng-template ngx-datatable-cell-template let-row="row">
            {{ ('publicLighting.lightZone.enums.PublicLightingControlMode.' + row.mode) | translate }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="startDate" name="{{ 'common.date.date' | translate }}"
                          [width]="200"
                          [sortable]="true">

        <ng-template ngx-datatable-cell-template let-row="row">
            {{'services.view.availabilityRange.fromToDate' | translate: {
            startDate: (row.startDate | date: dateFormat),
            endDate: (row.endDate | date: dateFormat)
        } }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="weekDays" name="{{ 'common.dayOfWeek' | translate }}"
                          [width]="250"
                          [sortable]="false">

        <ng-template ngx-datatable-cell-template let-row="row">
            {{ row.days | dayRangeFormat }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="hourRange" name="{{ 'common.hours' | translate }}"
                          [width]="150"
                          [sortable]="false">

        <ng-template ngx-datatable-cell-template let-row="row">
            <ng-container *ngIf="row.endTime">
                {{ 'services.view.availabilityRange.fromToTime' | translate:{
                startTime: formatTimeStringToDisplay(row.startTime),
                endTime: formatTimeStringToDisplay(row.endTime)
            } }}
            </ng-container>

            <ng-container *ngIf="!row.endTime">
                {{ 'services.view.availabilityRange.sinceTime' | translate: {
                startTime: formatTimeStringToDisplay(row.startTime)
            } }}
            </ng-container>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="75"
                          [sortable]="false">
        <ng-template ngx-datatable-cell-template let-row="row" >
            <button class="btn btn-link btn-sm"
                    title="{{'common.tooltip.edit' | translate}}"
                    (click)="openSchedulePeriodModal(row)">
                <fa-icon [icon]="icons.edit"></fa-icon>
            </button>

            <button class="btn btn-link btn-sm"
                    title="{{'common.tooltip.delete' | translate}}"
                    (click)="openDeleteSchedulePeriodModal(row)">
                <fa-icon [icon]="icons.delete"></fa-icon>
            </button>

        </ng-template>
    </ngx-datatable-column>

</ngx-datatable>

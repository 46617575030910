import {Component, Input, OnInit} from '@angular/core';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {Subscription} from 'rxjs';
import {DeviceStateEnum, KocoDeviceDto, KocoService,} from '../../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';
import {DateUtils} from '../../../_shared/date-utils';

@Component({
    selector: 'app-koco-dumpster',
    templateUrl: './koco-dumpster.component.html'
})
export class KocoDumpsterComponent implements OnInit {

    @Input() serviceId!: string;
    DeviceStateEnum = DeviceStateEnum;


    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription;

    public kocoDevices: KocoDeviceDto[] = [];
    format = DateUtils.dateTimeWithoutSecondFormat;

    constructor(
        private readonly translateService: TranslateService,
        private readonly kocoService: KocoService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                this.crtLang = this.translateService.currentLang;
            });
        this.fetchKocoDevices();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    private fetchKocoDevices(): void {
        this.kocoService.getKocoDevicesByServiceId(this.serviceId)
            .pipe().subscribe(value => this.kocoDevices = value);
    }

}


<div class="content-container bg-white-no-shadow">

    <h3 translate="mooringPlace.table.title"></h3>

    <app-view-mooring-place [serviceId]="serviceId"></app-view-mooring-place>

    <!-- Filters -->
    <div class="row">

        <div class="default-filter-form-field">
            <label class="form-label" for="placeId" translate="mooringPlace.table.placeId"></label>
            <input class="form-control" type="text" id="placeId"
                   pattern="{{textInputPattern}}"
                   placeholder='{{"mooringPlace.table.placeId" | translate}}'
                   (keyup)="searchCriteriaChanged()"
                   [(ngModel)]="pageRequest.criteria.searchCriteriaDto.placeId">
        </div>

        <div class="default-filter-form-field">
            <label class="form-label" for="placeNum" translate="mooringPlace.table.placeNum"></label>
            <input class="form-control" type="text" id="placeNum"
                   pattern="{{textInputPattern}}"
                   placeholder='{{"mooringPlace.table.placeNum" | translate}}'
                   (keyup)="searchCriteriaChanged()" [(ngModel)]="pageRequest.criteria.searchCriteriaDto.placeNum">
        </div>
        <div class="default-filter-form-field">
            <label class="form-label" for="pontoon" translate="mooringPlace.table.pontoon"></label>
            <input class="form-control" type="text" id="pontoon"
                   pattern="{{textInputPattern}}"
                   placeholder='{{"mooringPlace.table.pontoon" | translate}}'
                   (keyup)="searchCriteriaChanged()"
                   [(ngModel)]="pageRequest.criteria.searchCriteriaDto.pontoon">
        </div>

        <div class=" default-filter-form-field">
            <label class="form-label" for="placeType" translate="mooringPlace.table.placeType"></label>
            <input class="form-control" type="text" id="placeType"
                   pattern="{{textInputPattern}}"
                   placeholder='{{"mooringPlace.table.placeType" | translate}}'
                   (keyup)="searchCriteriaChanged()"
                   [(ngModel)]="pageRequest.criteria.searchCriteriaDto.placeType">
        </div>

        <div class="default-filter-form-field">
            <label class="form-label" for="boatRegistrationNumber" translate="mooringPlace.table.boatRegistrationNumber"></label>
            <input class="form-control" type="text" id="boatRegistrationNumber"
                   pattern="{{textInputPattern}}"
                   placeholder='{{"mooringPlace.table.boatRegistrationNumber" | translate}}'
                   (keyup)="searchCriteriaChanged()"
                   [(ngModel)]="pageRequest.criteria.searchCriteriaDto.boatRegistrationNumber">
        </div>

        <div class="default-filter-form-field">
            <label class="form-label" translate="mooringPlace.table.owner"></label>
            <app-search-user ngModel #searchUserComponent
                             (selectedUserEvent)="userSelected($event)"
                             ngDefaultControl></app-search-user>
        </div>
    </div>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary"
                [disabled]="!isFiltering()"
                (click)="clearFilter()"
                translate="common.clearFilter">
        </button>
    </div>

    <ngx-datatable class="material" #mooringPlaceTable
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [columnMode]="'force'"
                   [count]="mooringPlaces.rowCount"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [footerHeight]="50"
                   [headerHeight]="50"
                   [reorderable]="false"
                   [limit]="mooringPlaces.pageSize"
                   [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                    }"
                   [rowClass]="getRowClass"
                   [offset]="crtPage"
                   [rowHeight]="getRowHeight"
                   [rows]="mooringPlaces.results"
                   [scrollbarH]="true">

        <ngx-datatable-column [width]="80" name="{{'mooringPlace.table.placeId' | translate}}" prop="placeId">
            <ng-template>
                <span [ngClass]="'padding-header-cell-filter'"
                      translate="mooringPlace.table.placeId"></span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="50" name="{{'mooringPlace.table.pontoon' | translate}}" prop="pontoon">
            <ng-template>
                <span class="sort-btn" [ngClass]="'padding-header-cell-filter'" translate="mooringPlace.table.pontoon"></span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="60" name="{{'mooringPlace.table.placeNum' | translate}}" prop="placeNum">
            <ng-template>
                <span class="sort-btn" [ngClass]="'padding-header-cell-filter'" translate="mooringPlace.table.placeNum"></span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="85" name="{{'mooringPlace.table.placeType' | translate}}" prop="placeType">
            <ng-template>
        <span class="sort-btn" [ngClass]="'padding-header-cell-filter'"
              translate="mooringPlace.table.placeType"></span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="135" name="{{'mooringPlace.table.boatRegistrationNumber' | translate}}"
                              prop="boatRegistrationNumber">
            <ng-template>
                <span class="sort-btn" translate="mooringPlace.table.boatRegistrationNumber"></span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="185" name="{{ 'mooringPlace.table.owner' | translate }}" prop="userId">
            <ng-template ngx-datatable-cell-template let-mooringPlace="row">
                <option> {{ mooringPlace.owner }}</option>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="75" name="" [sortable]="false">

            <ng-template let-row="row" ngx-datatable-cell-template>
            <span (click)="showDetails(row, $event)" class="btn btn-link btn-sm"
                  title="{{'common.tooltip.details' | translate}}">
                <fa-icon [icon]="faEye"></fa-icon>
            </span>
            </ng-template>

        </ngx-datatable-column>

    </ngx-datatable>

</div>

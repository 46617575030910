import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {
    Day,
    OfficeHourDto,
    OfficeHourExceptionDto,
    OfficeHoursDto,
    OfficeHourService
} from '../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OfficeHourModalComponent} from './office-hour-modal/office-hour-modal.component';
import {NotificationsService} from '../../_shared/notifications.service';
import {OfficeHourExceptionModalComponent} from './office-hour-exception-modal/office-hour-exception-modal.component';
import {DateUtils} from '../../_shared/date-utils';
import {ConfirmModalService} from '../../_shared/_components/confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-office-hour-management',
    templateUrl: './office-hour-management.component.html'
})
export class OfficeHourManagementComponent implements OnInit, OnDestroy {
    @ViewChild('officeHourTable') officeHourTable;
    @ViewChild('exceptionsTable') exceptionsTable;

    officeHours: OfficeHoursDto = new OfficeHoursDto();

    DateUtils = DateUtils;

    icons = {
        pen: faPen,
        trash: faTrash
    };

    constructor(
        private readonly modalService: NgbModal,
        private readonly officeHourService: OfficeHourService,
        private readonly translateService: TranslateService,
        private readonly confirmService: ConfirmModalService,
        private readonly notificationsService: NotificationsService) {
    }

    ngOnInit(): void {
        this.fetchOfficeHours();
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll('destroy');
    }

    updateOfficeHour(officeHourDto: OfficeHourDto): void {
        const modal = this.modalService.open(OfficeHourModalComponent, {centered: true});
        modal.componentInstance.officeHour = new OfficeHourDto(officeHourDto);

        modal.result.then(_ => {
            this.fetchOfficeHours();
        }, () => { /* catch the rejection */ });
    }

    createExceptionHour(): void {
        this.updateExceptionHour(null);
    }

    updateExceptionHour(officeHourExceptionDto: OfficeHourExceptionDto): void {
        const modal = this.modalService.open(OfficeHourExceptionModalComponent, {centered: true});
        modal.componentInstance.officeHourException = new OfficeHourExceptionDto(officeHourExceptionDto);

        modal.result.then(_ => {
            this.fetchOfficeHours();
        }, () => { /* catch the rejection */ });
    }

    deleteExceptionHour(officeHourExceptionDto: OfficeHourExceptionDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.officeHourService.deleteOfficeHourException(officeHourExceptionDto.id).pipe().subscribe({
                    error: error => {
                        this.fetchOfficeHours();
                        throw error;
                    },
                    next: () => {
                        this.notificationsService.success({title: 'officeHours.notifications.deleteOfficeHourExceptionSuccess'});
                        this.fetchOfficeHours();
                    }
                });
            }
        });
    }

    getSelectedDays(officeHourDto: OfficeHourDto): Day[] {
        const days = [];

        if (officeHourDto.monday) {
            days.push(Day.Monday);
        }
        if (officeHourDto.tuesday) {
            days.push(Day.Tuesday);
        }
        if (officeHourDto.wednesday) {
            days.push(Day.Wednesday);
        }
        if (officeHourDto.thursday) {
            days.push(Day.Thursday);
        }
        if (officeHourDto.friday) {
            days.push(Day.Friday);
        }
        if (officeHourDto.saturday) {
            days.push(Day.Saturday);
        }
        if (officeHourDto.sunday) {
            days.push(Day.Sunday);
        }

        return days;
    }

    formatSelectedDays(selectedDays: Day[]): string {
        if (!selectedDays || selectedDays.length === 0) {
            return '-';
        }
        return selectedDays.map(d => this.translateService.instant('common.day.' + d)).join(', ');
    }

    private fetchOfficeHours(): void {
        this.officeHourService.getOfficeHours().pipe().subscribe(result => {
            this.officeHours = result;
        });
    }
}

import { Component } from '@angular/core';
import {AppRoles} from '../app.constants';

@Component({
    selector: 'app-alarm',
    templateUrl: './alarm.component.html'
})
export class AlarmComponent {

    AppRoles = AppRoles;

    constructor() { }

}

import {Pipe, PipeTransform} from '@angular/core';
import {Day} from '../../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'dayRangeFormat'})
export class DayRangeFormat implements PipeTransform {

    constructor(private readonly translateService: TranslateService) {
    }

    transform(value: Day[]): string {
        let orderedDays: string;
        const orderedDaysList = [];
        let sorter = [];
        sorter = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        if (value.length === 7){
            return this.translateService.instant('services.view.availabilityRange.everyDay');
        } else {
            // Order by day of the week loop
            for (const day of sorter){
                if (value.includes(day)){
                   orderedDaysList.push(day);
                }
            }
            orderedDays = '';
            for (const day of orderedDaysList){
                orderedDays = orderedDays + this.translateService.instant('common.day.' + day) + ', ';
            }
            return orderedDays.slice(0, -2);
        }
    }
}

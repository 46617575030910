import {Component, OnDestroy, OnInit} from '@angular/core';
import {faPen, faToggleOff, faToggleOn, faTrash, faWallet} from '@fortawesome/free-solid-svg-icons';
import {
    ISortCriteriaDto,
    PageableRequestDtoOfUserCriteriaDto,
    PagedResultDtoOfUserLightDto, RoleDto, RoleService,
    SortCriteriaDto,
    SortDirection,
    UserCriteriaDto,
    UserSearchCriteriaDto,
    UserService
} from '../_services/configuration-services';
import {AppRoles} from '../app.constants';
import {FormUtils} from '../_shared/form-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserExportModalComponent} from './user-export-modal/user-export-modal.component';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../_shared/translate-utils';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {RolesService} from '../_shared/roles-service';
import {NotificationsService} from '../_shared/notifications.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

    icons = {
        edit: faPen,
        wallet: faWallet,
        trash: faTrash,
        toggleOn: faToggleOn,
        toggleOff: faToggleOff
    }

    users: PagedResultDtoOfUserLightDto = new PagedResultDtoOfUserLightDto();

    appRoles = AppRoles;
    textInputPattern = FormUtils.textInputPattern;
    crtPage = 0;

    readonly pageSize = 50;
    readonly defaultSortProperty = 'customerNumber';

    private searchTermsSubscription: Subscription;
    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    private pageRequest: PageableRequestDtoOfUserCriteriaDto;

    searchTermsFormGroup = new UntypedFormGroup({
        searchTerms: new UntypedFormControl(null)
    });

    filterFormGroup = new UntypedFormGroup({
        baseRoleKeys: new UntypedFormControl(null),
        customRoleKeys: new UntypedFormControl(null),
        accountEnabled: new UntypedFormControl(null)
    });

    roleMultiSelectSettings: IDropdownSettings = {};
    baseRoleMultiSelectSource = [];
    customRoleMultiSelectSource = [];
    roles: RoleDto[] = [];

    constructor(private readonly userService: UserService,
                private readonly modalService: NgbModal,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly roleService: RoleService,
                readonly rolesService: RolesService) {

        this.pageRequest = new PageableRequestDtoOfUserCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new UserCriteriaDto({
            searchCriteriaDto: new UserSearchCriteriaDto({
                baseRoleKeys: null,
                customRoleKeys: null,
                accountEnabled: null
            }),
            sortCriteria: new SortCriteriaDto({
                direction: SortDirection.Asc,
                property: this.defaultSortProperty
            })
        });

    }

    async ngOnInit(): Promise<void> {
        await this.searchUsers();

        this.crtLang = this.translateService.currentLang;
        this.populateRoleMultiSelects();

        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
            this.populateRoleMultiSelects();
        });

        this.roleService.getAllRoles(false).pipe().subscribe(roles => {
            this.roles = roles;
            this.populateRoleMultiSelects();
        });

        this.searchTermsSubscription = this.searchTerms.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(500)
            )
            .subscribe(async () => await this.searchCriteriaChanged());
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.searchTermsSubscription.unsubscribe();
    }

    async setPage(page: any): Promise<void> {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;
        await this.searchUsers();
    }

    async onSort(sortingValues: any): Promise<void> {
        this.goToFirstPage();
        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortingValues.sorts[0].dir,
                property: sortingValues.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteria = new SortCriteriaDto(sortCriteriaDto);
        await this.searchUsers();
    }

    async searchCriteriaChanged(): Promise<void> {
        this.goToFirstPage();
        await this.searchUsers();
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }


    private async searchUsers(): Promise<void> {
        if (this.pageRequest.criteria) {
            this.pageRequest.criteria.searchCriteriaDto.baseRoleKeys = this.baseRoleKeys.value?.map(item => item.itemId);
            this.pageRequest.criteria.searchCriteriaDto.customRoleKeys = this.customRoleKeys.value?.map(item => item.itemId);
            this.pageRequest.criteria.searchCriteriaDto.accountEnabled = this.accountEnabled.value;
            this.pageRequest.criteria.searchCriteriaDto.searchTerms = this.searchTerms.value;
        }

        this.users = await this.userService.searchUsers(this.pageRequest).toPromise();
    }

    private populateRoleMultiSelects(): void {
        this.roleMultiSelectSettings = {
            idField: 'itemId',
            textField: 'itemVal',
            singleSelection: false,
            selectAllText: this.translateService.instant('common.multiselect.selectAll'),
            unSelectAllText: this.translateService.instant('common.multiselect.unselectAll'),
            searchPlaceholderText: this.translateService.instant('common.multiselect.search'),
            noDataAvailablePlaceholderText: this.translateService.instant('common.multiselect.noResults'),
            itemsShowLimit: 3,
            allowSearchFilter: true
        }

        this.baseRoleMultiSelectSource = this.roles.filter(r => r.isBase)
            .sort((a, b) => a.roleKey.localeCompare(b.roleKey))
            .map(r => {
                return {
                    'itemId': r.roleKey,
                    'itemVal': r['label' + this.crtLang.toUpperCase()]
                }
            });

        this.customRoleMultiSelectSource = this.roles.filter(r => !r.isBase)
            .map(r => {
                return {
                    'itemId': r.roleKey,
                    'itemVal': r['label' + this.crtLang.toUpperCase()]
                }
            });
    }

    async clearFilter(): Promise<void> {
        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new UserCriteriaDto({
            searchCriteriaDto: new UserSearchCriteriaDto({
                baseRoleKeys: null,
                customRoleKeys: null,
                accountEnabled: null
            }),
            sortCriteria: new SortCriteriaDto({
                direction: SortDirection.Asc,
                property: this.defaultSortProperty
            })
        });

        this.searchTermsFormGroup.reset();
        this.filterFormGroup.reset();

        this.goToFirstPage();
        await this.searchUsers();
    }

    isFiltering(): boolean {
        const searchCriteriaDto = this.pageRequest.criteria?.searchCriteriaDto;
        return (searchCriteriaDto?.baseRoleKeys && searchCriteriaDto.baseRoleKeys.length > 0) ||
            (searchCriteriaDto?.customRoleKeys && searchCriteriaDto.customRoleKeys.length > 0) ||
            (searchCriteriaDto?.accountEnabled != null);
    }

    openExportModal(): void {
        const modal = this.modalService.open(UserExportModalComponent, {centered: true, size: 'lg'});
        modal.result.then(_ => {
        }, () => {
        });
    }

    deactivateUser(id) {
        this.userService.deactivateUser(id).pipe().subscribe(async _ => {
            this.notificationsService.success({title: 'users.notifications.deactivationSuccess'});
            await this.searchUsers();
        });
    }

    activateUser(id) {
        this.userService.activateUser(id).pipe().subscribe(async _ => {
            this.notificationsService.success({title: 'users.notifications.activationSuccess'});
            await this.searchUsers();
        });
    }

    get searchTerms(): UntypedFormControl {
        return this.searchTermsFormGroup.get('searchTerms') as UntypedFormControl;
    }

    get baseRoleKeys(): UntypedFormControl {
        return this.filterFormGroup.get('baseRoleKeys') as UntypedFormControl;
    }

    get customRoleKeys(): UntypedFormControl {
        return this.filterFormGroup.get('customRoleKeys') as UntypedFormControl;
    }

    get accountEnabled(): UntypedFormControl {
        return this.filterFormGroup.get('accountEnabled') as UntypedFormControl;
    }
}

import {Component, Input, OnInit} from '@angular/core';

import {
    ServiceDto,
    WifiDto,
    WifiService
} from '../../../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-wifi-modal',
    templateUrl: './wifi-modal.component.html'
})
export class WifiModalComponent implements OnInit {

    availableWifiNetworks: WifiDto[];

    selectWifiForm: UntypedFormGroup;

    @Input() isUpdateModal: boolean;
    @Input() crtLang: string;
    @Input() service: ServiceDto;

    constructor(
        public activeModal: NgbActiveModal,
        private readonly wifiService: WifiService,
        private readonly notificationsService: NotificationsService,
    ) {

        this.selectWifiForm = new UntypedFormGroup({
                wifiSelect: new UntypedFormControl('', Validators.required),
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        this.getAvailableWifiNetworks();
    }

    getWifiName(wifi: WifiDto): string {
        return wifi['name' + this.crtLang.toUpperCase()];
    }

    getAvailableWifiNetworks(): void {
        this.wifiService.getAllWifis(true).pipe().subscribe(wifiNetworks => {
            this.availableWifiNetworks = wifiNetworks;
            if (this.availableWifiNetworks.length === 0) {
                this.notificationsService.warning({title: 'services.wifi.notifications.noWifiAvailable'});
                this.activeModal.close();
            }
        });
    }

    assignWifi(): void{
        this.wifiService.assignWifiToService(this.selectWifiForm.value.wifiSelect, this.service.serviceId).pipe().subscribe(result => {
                this.notificationsService.success({title: 'services.wifi.notifications.assignSuccess'});
                this.activeModal.close();
        });
    }

    get wifiSelect(): UntypedFormControl {
        return this.selectWifiForm.get('wifiSelect') as UntypedFormControl;
    }
}

import {Injectable} from '@angular/core';
import {IndividualConfig, ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

const notificationConfig: Partial<IndividualConfig> = {
    easeTime: 100,
    enableHtml: true,
    titleClass: 'app-notification-title',
    messageClass: 'app-notification-message'
};

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(private readonly toastrService: ToastrService,
                private readonly translateService: TranslateService) {
    }

    public success(notification: Partial<Notification>, disableTimeOut: boolean = false): void {
        this.toastrService.success(
            this.translate(notification.message, notification.interpolateParams),
            this.translate(notification.title, notification.interpolateParams),
            this.getNotificationConfig(disableTimeOut));
    }


    public info(notification: Partial<Notification>, disableTimeOut: boolean = false): void {
        this.toastrService.info(
            this.translate(notification.message, notification.interpolateParams),
            this.translate(notification.title, notification.interpolateParams),
            this.getNotificationConfig(disableTimeOut));
    }

    public error(notification: Partial<Notification>, disableTimeOut: boolean = false): void {
        this.toastrService.error(
            this.translate(notification.message, notification.interpolateParams),
            this.translate(notification.title, notification.interpolateParams),
            this.getNotificationConfig(disableTimeOut));
    }

    public warning(notification: Partial<Notification>, disableTimeOut: boolean = false): void {
        this.toastrService.warning(
            this.translate(notification.message, notification.interpolateParams),
            this.translate(notification.title, notification.interpolateParams),
            this.getNotificationConfig(disableTimeOut));
    }

    public clearAll(): void {
        this.toastrService.clear();
    }

    private translate(msg: string | undefined, interpolateParams?: object | undefined): string {
        if (msg) {
            if (interpolateParams) {
                return this.translateService.instant(msg, interpolateParams);
            }
            return this.translateService.instant(msg);
        }
        return msg;
    }

    private getNotificationConfig(disableTimeOut: boolean = false): Partial<IndividualConfig> {
        if (!!disableTimeOut) {
            const customNotificationConfig = {...notificationConfig};
            customNotificationConfig.disableTimeOut = disableTimeOut;
            return customNotificationConfig;

        }
        return notificationConfig;
    }
}

export declare class Notification {
    title: string | null;
    message: string | null;
    interpolateParams: object | null;
}

import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    EnergyService, MeasuringPointAssignmentWithUserDto,
    MeasuringPointService,
    MeasuringPointState,
    MeasuringPointStateChangeSource,
    MeasuringPointType,
    SearchMeasuringPointResultDto,
    SearchUserDto, SortCriteriaDto, SortDirection,
    TariffDto, TariffService,
    UserCockpitDataDto
} from '../../../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom, Observable, Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {ConsumptionData} from '../consumption-bar-chart/consumption-bar-chart.component';
import {environment} from '../../../../../environments/environment';
import {RolesService} from '../../../../_shared/roles-service';
import {SearchMeasuringPointComponent} from '../../../../_shared/_components/search-measuring-point/search-measuring-point.component';
import {DateRange} from '../../../../_shared/_components/date-range-picker/date-range-picker.component';
import {DateUtils} from '../../../../_shared/date-utils';
import {TranslateUtils} from '../../../../_shared/translate-utils';
import {Dayjs} from "dayjs";
import * as dayjs from 'dayjs';


@Component({
    selector: 'app-user-energy-cockpit',
    templateUrl: './cockpit-energy-user.component.html',
    styleUrls: ['./cockpit-energy-user.component.scss']
})
export class CockpitEnergyUserComponent implements OnInit, OnDestroy {

    @Input() serviceId!: string;
    @Input() userId: string | null;
    @Input() measuringPoint: SearchMeasuringPointResultDto | null;
    @Input() isAdminMode = false;
    @Input() tariffs: [TariffDto] | null;

    @ViewChild('searchMeasuringPoint') searchMeasuringPoint: SearchMeasuringPointComponent = null;

    dateFormat = DateUtils.dateFormat;

    userMeasuringPointType = MeasuringPointType.User;
    MeasuringPointState = MeasuringPointState;
    MeasuringPointStateChangeSource = MeasuringPointStateChangeSource;

    canCommuteMeasuringPoint = false;

    dateRange: DateRange;
    maxDateFromForUser: Dayjs | null = null;
    minDateFromForUser: Dayjs | null = null;
    crtLang = TranslateUtils.defaultLanguage;
    userIsSelected = false;

    currentAssignedFirstSort = new SortCriteriaDto({
        direction: SortDirection.Asc,
        property: 'Assignment'
    });

    cockpitData: UserCockpitDataDto;

    // Displayed values
    tariff: TariffDto | null = null;
    actualIntensity = null;
    consumptionData: ConsumptionData[] = [];
    measuringPointAssignment: MeasuringPointAssignmentWithUserDto | null = null;

    private langChangeSubscription$: Subscription;

    constructor(
        private readonly translateService: TranslateService,
        private readonly energyService: EnergyService,
        private readonly measuringPointService: MeasuringPointService,
        private readonly rolesService: RolesService,
        private readonly datePipe: DatePipe,
        private readonly tariffService: TariffService) {
    }

    async ngOnInit(): Promise<void> {
        this.setupLanguage();

        this.langChangeSubscription$ = this.translateService.onLangChange
            .subscribe(_ => {
                this.setupLanguage();
                window.location.reload();
            });

        if (!this.isAdminMode) {
            this.userIsSelected = true;

            const availableRangeForUser = await firstValueFrom(this.energyService.getAvailablePeriodRangeForCurrentUserAndMeasuringPoint(this.measuringPoint.id));

            this.maxDateFromForUser = dayjs(availableRangeForUser.to);
            this.minDateFromForUser = dayjs(availableRangeForUser.from);
        }

        this.canCommuteMeasuringPoint = environment.canUserCommuteInCockpit || this.rolesService.hasRoleAdmin();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$?.unsubscribe();
    }

    async fetchCockpitData(): Promise<void> {
        if (this.measuringPoint) {

            let request: Observable<UserCockpitDataDto>;

            if (this.isAdminMode) {
                // Only the date will be used by the backend (the hour of the day is ignored)
                request = this.energyService.getDataForUserCockpitWithUser(this.measuringPoint.id,
                    this.userId,
                    this.formatDateForCockpitData(this.dateRange.start),
                    this.formatDateForCockpitData(this.dateRange.end));

            } else {
                // Update available daterange for measuring point
                const availableRangeForUser = await firstValueFrom(this.energyService.getAvailablePeriodRangeForCurrentUserAndMeasuringPoint(this.measuringPoint.id));

                this.maxDateFromForUser = dayjs(availableRangeForUser.to);
                this.minDateFromForUser = dayjs(availableRangeForUser.from);

                // Only the date will be used by the backend (the hour of the day is ignored)
                request = this.energyService.getDataForUserCockpit(this.measuringPoint.id,
                    this.formatDateForCockpitData(this.dateRange.start),
                    this.formatDateForCockpitData(this.dateRange.end));
            }

            this.cockpitData = await firstValueFrom(request);

            if (this.cockpitData.actualIntensity?.lastKnownValue != null && this.cockpitData.actualIntensity.lastKnownValue >= 0) {
                this.actualIntensity = this.cockpitData.actualIntensity.lastKnownValue.toFixed(2);

            } else {
                this.actualIntensity = '--';
            }

            this.consumptionData = this.cockpitData.consumptionMeasures.map<ConsumptionData>(c => ({
                value: c.value,
                date: c.dateTime
            }));
            this.measuringPointAssignment = this.cockpitData.assignment;

            if (this.consumptionData.length > 0) {
                this.dateRange.start = this.consumptionData[0].date;
                this.dateRange.end = this.consumptionData[this.consumptionData.length - 1].date;
            }

            await this.getTariffForUser(this.measuringPoint?.user?.id);

        } else {
            this.cockpitData = null;
            this.consumptionData = [];
        }
    }

    async measuringPointSelected(measuringPoint: SearchMeasuringPointResultDto | null): Promise<void> {
        this.measuringPoint = measuringPoint;
        await this.fetchCockpitData();
    }

    async dateRangeChange(event: DateRange): Promise<void> {
        this.dateRange = event;
        await this.fetchCockpitData();
    }

    async changeMeasuringPointState(newState: boolean): Promise<void> {
        this.cockpitData.measuringPointStateChangeSource = MeasuringPointStateChangeSource.Unknown;
        if (newState) {
            await firstValueFrom(this.measuringPointService.activateMeasuringPoint(this.measuringPoint.id));
        } else {
            await firstValueFrom(this.measuringPointService.deactivateMeasuringPoint(this.measuringPoint.id));
        }
        await this.fetchCockpitData();
    }

    setupLanguage(): void {
        this.crtLang = this.translateService.currentLang;
    }

    formatDate(date: Date): string {
        return this.datePipe.transform(date, DateUtils.dateFormat);
    }

    userSelected(user: SearchUserDto): void {
        this.userId = user ? user.id : null;
        this.userIsSelected = !!user;

        if (this.searchMeasuringPoint) {
            this.searchMeasuringPoint.clearInput();
            this.measuringPoint = null;
            this.cockpitData = null;
        }
    }

    private async getTariffForUser(userId: string | null): Promise<void> {
        if (userId) {
            this.tariff = await firstValueFrom(this.tariffService.getTariffForServiceAndUser(this.serviceId, userId));
        } else {
            this.tariff = this.tariffs.find(t => t.defaultTariff);
        }
    }

    private formatDateForCockpitData(date: Date): string {
        return [`0${date.getDate()}`.substr(-2), `0${date.getMonth() + 1}`.substr(-2), `${date.getFullYear()}`].join('-');
    }
}

import {Component, OnInit} from '@angular/core';
import {InformationLightDto, InformationService} from '../../_services/configuration-services';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../../_shared/translate-utils';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {AppRoles} from '../../app.constants';
import {RolesService} from '../../_shared/roles-service';
import {CustomDateFormatter} from '../../_shared/custom-date-formatter';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-informations',
    templateUrl: './informations.component.html',
    styleUrls: ['./informations.component.scss'],
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class InformationsComponent implements OnInit {

    AppRoles = AppRoles;

    informationArray: InformationLightDto[] = [];

    icons = {
        pen: faPen
    };

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(private readonly router: Router,
                private readonly route: ActivatedRoute,
                private readonly informationService: InformationService,
                private readonly translateService: TranslateService,
                private readonly rolesService: RolesService,
    ) {}

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.fetchInformations();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    private fetchInformations(): void {
        if (this.rolesService.hasRoleAdmin()) {
            this.informationService.getAll().pipe().subscribe(infoArray => {
                this.informationArray = infoArray;
            });

        } else {
            this.informationService.getAllActive().pipe().subscribe(infoArray => {
                this.informationArray = infoArray;
            });
        }
    }

    readInformation(id: string): void {
        this.router.navigate([id], {
            relativeTo: this.route
        });
    }
}

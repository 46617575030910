<div class="content-container bg-white">

    <div *ngIf="crtLang === 'fr'">

        <h1>Informations à tous les utilisateurs de Gestinergie</h1>

        <p>Vous pouvez demander un accès à vos données personnelles et/ou demander la suppression de votre compte.</p>
        <p>Pour ce faire, merci de suivre les procédures ci-dessous : </p>

        <h2>Procédure de droit d’accès (accès à mes données personnelles)</h2>
        <ol>
            <li>
                Faire un mail à <a href="mailto:{{clientEmail}}">{{clientEmail}}</a> via l’adresse e-mail de votre
                compte Gestinergie
            </li>
            <li>
                Objet du mail : Gestinergie – demande de droit d’accès à mes données personnelles [nom] + [prénom]
            </li>
            <li>
                Contenu du mail : dans votre mail merci de renseigner au minimum les informations suivantes :
                <ul>
                    <li>
                        Votre nom, prénom
                    </li>
                    <li>
                        Copie de la pièce d’identité
                    </li>
                    <li>
                        Votre numéro de téléphone (en cas de besoin d’information supplémentaire)
                    </li>
                </ul>
            </li>
        </ol>
        <p> Les demandes sont traitées par ordre d’arrivée. Vous recevrez un statut de votre demande au plus tard dans
            les 30 jours suivants votre requête.</p>


        <h2>Procédure de droit à l’oubli (suppression de mon compte)</h2>
        <ol>
            <li>
                Faire un mail à <a href="mailto:{{clientEmail}}">{{clientEmail}}</a> via l’adresse e-mail de votre
                compte Gestinergie
            </li>
            <li>
                Objet du mail : Gestinergie – demande de suppression de mon compte [nom] + [prénom]
            </li>
            <li>
                Contenu du mail : dans votre mail merci de renseigner au minimum les informations suivantes :
                <ul>
                    <li>
                        Votre nom, prénom
                    </li>
                    <li>
                        Copie de la pièce d’identité
                    </li>
                    <li>
                        Préciser la motivation de la suppression de votre compte.
                    </li>
                    <li>
                        Votre numéro de téléphone (en cas de besoin d’information supplémentaire)
                    </li>
                </ul>
            </li>
        </ol>
        <p> Les demandes sont traitées par ordre d’arrivée. Vous recevrez un statut de votre demande au plus tard dans
            les 30 jours suivants votre requête.</p>


        <p><strong>Important</strong></p>
        <p>
            Veuillez noter que la suppression de votre compte pourra se faire uniquement si les conditions suivantes
            sont remplies :
        </p>
        <ul>
            <li>
                Solde à 0.- de votre porte-monnaie virtuel
            </li>
            <li>
                Aucune facture en cours
            </li>
            <li>
                Aucune réservation de service dans le futur
            </li>
            <li>
                Aucun abonnement en cours
            </li>
            <li>
                Désassignation et restitution de votre carte RFID
            </li>
            <li>
                Désassignation de l'ensemble de vos emplacements et points de mesure si existants
            </li>
        </ul>

        <p>
            L’administrateur prendra contact avec vous afin de régler les points ci-dessus.
        </p>

    </div>

    <div *ngIf="crtLang === 'de'">

        <h1>Informationen für alle Nutzer von Gestinergie</h1>

        <p>Sie können den Zugriff auf Ihre personenbezogenen Daten anfordern und/oder die Löschung Ihres Kontos
            beantragen.</p>
        <p>Befolgen Sie dazu bitte die untenstehenden Verfahren : </p>

        <h2>Prozedere für die Zugangsgenehmigung (Zugriff auf meine personenbezogenen Daten)</h2>
        <ol>
            <li>
                Senden Sie eine E-Mail an <a href="mailto:{{clientEmail}}">{{clientEmail}}</a> über die E-Mail-Adresse
                Ihres Gestinergie-Kontos.
            </li>
            <li>
                Betreff der E-Mail: Gestinergie – Antrag auf Zugangsrecht zu meinen personenbezogenen Daten [Name] +
                [Vorname].
            </li>
            <li>
                Inhalt der E-Mail: Bitte geben Sie in Ihrer E-Mail mindestens die folgenden Informationen an:
                <ul>
                    <li>
                        Ihren Namen, Vornamen
                    </li>
                    <li>
                        Kopie des Personalausweises
                    </li>
                    <li>
                        Ihre Telefonnummer (falls weitere Informationen benötigt werden)
                    </li>
                </ul>
            </li>
        </ol>
        <p>Die Anfragen werden in der Reihenfolge ihres Eingangs bearbeitet. Sie erhalten spätestens 30 Tage nach Ihrer
            Anfrage einen Status Ihrer Anfrage.</p>


        <h2>Verfahren zum Recht auf Vergessen werden (Löschung meines Kontos)</h2>
        <ol>
            <li>
                Senden Sie eine E-Mail an <a href="mailto:{{clientEmail}}">{{clientEmail}}</a> über die E-Mail-Adresse
                Ihres Gestinergie-Kontos.
            </li>
            <li>
                Betreff der E-Mail: Gestinergie – Anfrage zum Löschen meines Kontos [Name] + [Vorname]
            </li>
            <li>
                Inhalt der E-Mail: Bitte geben Sie in Ihrer E-Mail mindestens die folgenden Informationen an:
                <ul>
                    <li>
                        Ihren Namen, Vornamen
                    </li>
                    <li>
                        Kopie des Personalausweises
                    </li>
                    <li>
                        Geben Sie den Grund für die Löschung Ihres Kontos an.
                    </li>
                    <li>
                        Ihre Telefonnummer (falls weitere Informationen benötigt werden)
                    </li>
                </ul>
            </li>
        </ol>
        <p>Die Anfragen werden in der Reihenfolge ihres Eingangs bearbeitet. Sie erhalten spätestens 30 Tage nach Ihrer
            Anfrage einen Status Ihrer Anfrage.</p>


        <p><strong>Wichtig</strong></p>
        <p>
            Bitte beachten Sie, dass die Löschung Ihres Kontos nur erfolgen kann, wenn die folgenden Bedingungen erfüllt
            sind:
        </p>
        <ul>
            <li>
                Guthaben bei 0.- auf Ihrer virtuellen Geldbörse.
            </li>
            <li>
                Keine laufende Rechnung.
            </li>
            <li>
                Keine zukünftigen Service-Reservierungen.
            </li>
            <li>
                Kein laufendes Abonnement.
            </li>
            <li>
                Abmeldung und Rückgabe Ihrer RFID-Karte.
            </li>
            <li>
                Abmeldung der Zuweisung all Ihrer Standorte und Messpunkte, falls vorhanden
            </li>
        </ul>

        <p>
            Der Administrator wird sich mit Ihnen in Verbindung setzen, um die oben genannten Punkte zu regeln.
        </p>

    </div>

    <div *ngIf="crtLang === 'it'">

        <h1>Informazione per gli utilizzatori di Gestinergie</h1>

        <p>Avete la possibilité di chiedere l’accesso ai vostri dati personali e/o chiedere la soppressione del vostro
            conto.</p>
        <p>Per sopprimerlo, vi preghiamo di seguire la procedura seguente :</p>

        <h2>Procedura di diritto di accesso (accesso ai miei dati personali)</h2>
        <ol>
            <li>
                Inviare un’e-mail a <a href="mailto:{{clientEmail}}">{{clientEmail}}</a> via l’indirizzo e-mail del
                vostro conto Gestinergie
            </li>
            <li>
                Oggetto dell’e-mail : Gestinergie – richiesta di diritto di accesso ai miei dati personali [cognome] +
                [nome]
            </li>
            <li>
                Contenuto dell’e-mail : nella vostra e-mail vi saremo grati di specificare almeno le seguenti
                informazioni :
                <ul>
                    <li>
                        Il vostro cognome, nome
                    </li>
                    <li>
                        Copia della vostra carta d’identità
                    </li>
                    <li>
                        Indicare il vostro numero di telefono (in caso di bisogno di ulteriori informazioni)
                    </li>
                </ul>
            </li>
        </ol>
        <p>Le richieste sono trattate per ordine di arrivo. Riceverete un messaggio di risposta al massimo 30 giorni
            dalla data della vostra richiesta.</p>


        <h2>Procedura di diritto all’oblio (soppressione del mio conto)</h2>
        <ol>
            <li>
                Inviare un’e-mail a <a href="mailto:{{clientEmail}}">{{clientEmail}}</a> via l’indirizzo e-mail del
                vostro conto Gestinergie
            </li>
            <li>
                Oggetto dell’e-mail : Gestinergie – richiesta di soppressione del mio conto [cognome] + [nome]
            </li>
            <li>
                Contenuto dell’e-mail : nella vostra e-mail vi saremo grati di notare almeno le seguenti informazioni :
                <ul>
                    <li>
                        Il vostro cognome, nome
                    </li>
                    <li>
                        Copia della vostra carta d’identità
                    </li>
                    <li>
                        Spiegare il motivo della vostra richiesta
                    </li>
                    <li>
                        Indicare il vostro numero di telefono (in caso di bisogno di ulteriori informazioni)
                    </li>
                </ul>
            </li>
        </ol>
        <p>Le richieste sono trattate per ordine di arrivo. Riceverete un messaggio di risposta al massimo 30 giorni
            dalla data della vostra richiesta.</p>


        <p><strong>Importante</strong></p>
        <p>
            Vogliate notare che la soppressione del vostro conto potrà effettuarsi solamente se le condizioni seguenti
            sono rispettate :
        </p>
        <ul>
            <li>
                Saldo del vostro portamonete virtuale a 0.- CHF
            </li>
            <li>
                Nessuna fattura in corso
            </li>
            <li>
                Nesssuna riservazione di servizio programmata
            </li>
            <li>
                Nessun abbonamento in corso
            </li>
            <li>
                Annulamento e restituzione della vostra carta RFID
            </li>
            <li>
                Dissociazione di tutti i posti e punti di misura a voi riservati, se esistenti
            </li>
        </ul>

        <p>
            L'amministratore vi contatterà per risolvere i punti di cui sopra descriti.
        </p>

    </div>

    <div *ngIf="crtLang === 'en'">

        <h1>Information to all Gestinergie users</h1>

        <p>You may request access to your personal data and/or request the deletion of your account.</p>
        <p>To do so, please follow the procedures below:</p>

        <h2>Right of access procedure (access to my personal data)</h2>
        <ol>
            <li>
                Send an email to <a href="mailto:{{clientEmail}}">{{clientEmail}}</a> using the email address of your
                Gestinergie account
            </li>
            <li>
                Subject of the email : Gestinergie - request for access to my personal data [name] + [first name].
            </li>
            <li>
                Content of the email: in your email please provide at least the following information:
                <ul>
                    <li>
                        Your name, first name
                    </li>
                    <li>
                        Copy of the identity card
                    </li>
                    <li>
                        Your phone number (in case of need for additional information)
                    </li>
                </ul>
            </li>
        </ol>
        <p>Requests are processed on a first-come, first-served basis. You will receive a status of your request within
            30 days of your request.</p>


        <h2>Right to be forgotten procedure (deletion of my account)</h2>
        <ol>
            <li>Send an email to <a href="mailto:{{clientEmail}}">{{clientEmail}}</a> using the email address of your
                Gestinergie account
            </li>
            <li>
                Subject of the email: Gestinergie - request for deletion of my account [name] + [first name]
            </li>
            <li>
                Content of the email: in your email please provide at least the following information:
                <ul>
                    <li>
                        Your name, first name
                    </li>
                    <li>
                        Copy of the identity card
                    </li>
                    <li>
                        Please specify the reason for deleting your account
                    </li>
                    <li>
                        Your phone number (in case of need for additional information)
                    </li>
                </ul>
            </li>
        </ol>
        <p>
            Requests are processed on a first-come, first-served basis. You will receive a status of your request within
            30 days of your request.
        </p>


        <p><strong>Important</strong></p>
        <p>
            Please note that deletion of your account can only be done if the following conditions are met:
        </p>
        <ul>
            <li>
                0.- balance in your virtual wallet
            </li>
            <li>
                No open invoices
            </li>
            <li>
                No ongoing service reservations in the future
            </li>
            <li>
                No active subscription
            </li>
            <li>
                Unassignment and return of your RFID card
            </li>
            <li>
                Unassignment of all your locations and measuring points if existing
            </li>
        </ul>

        <p>
            The administrator will contact you to resolve the above issues.
        </p>

    </div>

</div>

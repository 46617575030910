<h4 translate="services.wifi.title"></h4>

<div class="btn-bar">
    <button class="btn btn-primary" *ngIf="!!assignedWifi && assignedWifi.subscriptionCount === 0"
            (click)="openWifiModal()"
            translate="services.wifi.action.update">
    </button>
    <button class="btn btn-primary" *ngIf="!assignedWifi"
            (click)="openWifiModal()"
            translate="services.wifi.action.add">
    </button>
</div>

<div class="table-responsive">
    <table class="table table-striped"
           [attr.aria-label]="'common.wifiNetworks' | translate">
        <thead>
        <tr>
            <th scope="col" style="min-width: 40%" translate="services.wifi.name"></th>
            <th scope="col" style="min-width: 40%" translate="services.wifi.ssid"></th>
            <th scope="col" style="min-width: 20%" translate="services.wifi.subscriptionCount"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="!assignedWifi">
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>

        <tr *ngIf="assignedWifi">
            <td>{{ getWifiName(assignedWifi) }}</td>
            <td>{{ assignedWifi.ssid }}</td>
            <td>{{ assignedWifi.subscriptionCount }}</td>
        </tr>
        </tbody>
    </table>
</div>

<div class="modal-header">

    <h4 class="modal-title"
        [translate]="getModalTitle()"></h4>
    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">

    <form name="assignServiceMeasuringPoint">
        <div class="row">

            <div class="col-12">
                <label class="form-label" for="searchMeasuringPoint">{{ 'measuringPoints.table.filter.measuringPoint' | translate }}*</label>
                <app-search-measuring-point id="searchMeasuringPoint" #searchMeasuringPointComponent
                                            (selectedMeasuringPointEvent)="measuringPointSelected($event)"
                                            [defaultMeasuringPoint]="measuringPoint"
                                            [serviceId]="reassignUserMode ? serviceId : null"
                                            [serviceUnassignedOnly]="!reassignUserMode"
                                            [userUnassignedOnly]="reassignUserMode"
                                            [measuringPointTypes]="[measuringPointTypeToAdd]"
                                            [disabled]="assignUserMode"></app-search-measuring-point>
            </div>

            <div class="col-12" *ngIf="measuringPointTypeToAdd === measuringPointType.User">
                <label class="form-label" for="searchUser">{{ 'measuringPoints.table.filter.user' | translate }}</label>
                <app-search-user id="searchUser" #searchUserComponent
                                 (selectedUserEvent)="userSelected($event)"
                                 [userContact]="user"
                                 [disabled]="reassignUserMode"></app-search-user>
            </div>

        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>
    <button class="btn btn-primary" type="submit"
            [disabled]="measuringPointId === null"
            (click)="assignMeasuringPoint()"
            translate="common.save">
    </button>
</div>

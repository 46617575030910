<div>
    <h3 translate="bookings.title"></h3>

    <app-item-to-book-selection [items]="items"
                                [crtLang]="crtLang"
                                (selectItem)="selectItemToDisplayInCalendar($event)"
    ></app-item-to-book-selection>

    <full-calendar class="mt-4" *ngIf="calendarOptions"
                   #fullcalendar
                   [options]="calendarOptions"></full-calendar>

    <div class="btn-bar" *ngIf="hasAdminRoleForBooking">

        <button class="btn btn-primary" type="button"
                (click)="openSlotGenerationModal()"
                translate="bookings.slots.generate">
        </button>
        <button class="btn btn-primary" type="button"
                (click)="openSlotDeletionModal()"
                translate="bookings.slots.delete">
        </button>

    </div>
</div>

<ng-template #itemPopover let-resource="resource">
    <div>
        <div class="popover-resource">
            <h6>{{ resource.title }}</h6>
            <div class="text-pre-wrap">{{ resource.extendedProps.description }}</div>
        </div>
    </div>
</ng-template>

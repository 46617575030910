import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InformationState, SurveyDto, SurveyService} from '../../../_services/configuration-services';
import {DocumentViewComponent} from '../../../_shared/_components/document-management-component/document-view/document-view.component';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {DocumentManagementTypeEnum} from '../../../_shared/_components/document-management-component/document-management.component';
import {Location} from '@angular/common';
import {AppRoles} from '../../../app.constants';
import {CommunicationChannelConfigService} from '../../../_shared/communication-channel-config.service';

@Component({
    selector: 'app-view-survey',
    templateUrl: './view-survey.component.html'
})
export class ViewSurveyComponent implements OnInit, OnDestroy {
    AppRoles = AppRoles;
    InformationState = InformationState;
    DocumentManagementTypeEnum = DocumentManagementTypeEnum;

    @ViewChild('documentListComponent') documentListComponent: DocumentViewComponent;

    surveyId: string | null = null;
    survey!: SurveyDto;

    // Icons
    icons = {
        pen: faPen
    };

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(private readonly route: ActivatedRoute,
                private readonly router: Router,
                private readonly translateService: TranslateService,
                private readonly surveyService: SurveyService,
                private readonly communicationChannelConfigService: CommunicationChannelConfigService,
                private readonly location: Location) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.route.params.pipe().subscribe(params => {
            this.surveyId = params['id'] ?? null;

            this.surveyService.getSurvey(this.surveyId).pipe().subscribe(survey => {
                this.survey = survey;

                if (this.documentListComponent) {
                    this.documentListComponent.id = survey.id;
                    this.documentListComponent.fetchData();
                }
            }, _ => {
                this.router.navigate(['..'], {
                    relativeTo: this.route
                });
            });
        });

    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    openSurvey(): void {
        this.surveyService.addReadStatus(this.survey.id).pipe().subscribe(_ => {
            this.communicationChannelConfigService.fetchHasUnreadMessage();
            window.open(this.survey.surveyUrl, '_blank');
        });
    }

    back(): void {
        this.location.back();
    }

}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AuthenticationMethods, ChargingProcessService,
    ServiceDto,
    ServiceService,
    ServiceType,
    TariffDto,
    TariffService
} from '../../_services/configuration-services';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {faCircle, faPen} from '@fortawesome/free-solid-svg-icons';
import {NotificationsService} from '../../_shared/notifications.service';
import {TranslateService} from '@ngx-translate/core';
import {AppRoles} from '../../app.constants';
import {ActivateHydrowashModalComponent} from './activate-hydrowash-modal/activate-hydrowash-modal.component';
import {environment} from '../../../environments/environment';
import {ServiceIconUrlBuilder} from '../../_shared/service-icon-url-builder';
import {MsalService} from '@azure/msal-angular';
import {PurchaseServiceModalComponent} from './purchase-service-modal/purchase-service-modal.component';
import {RolesService} from '../../_shared/roles-service';
import {DateUtils} from '../../_shared/date-utils';
import {TranslateUtils} from '../../_shared/translate-utils';
import {LoaderService} from '../../_shared/loader-service';
import {
    ExportPottingerStatisticModalComponent
} from './pottinger/export-pottinger-statistic-modal/export-pottinger-statistic-modal.component';
import {ActivateServiceModalComponent} from './activate-service-modal/activate-service-modal.component';
import {BarcodeFormat} from '@zxing/library';
import {
    ActivateKocoDumpsterModalComponent
} from './koco-dumpster/activate-koco-dumpster-modal/activate-koco-dumpster-modal.component';
import {MeasuringPointsCockpitComponent} from './measuring-points-cockpit/measuring-points-cockpit.component';
import {ActivationManagementComponent} from './activation-management/activation-management.component';
import {
    ActivateChargingProcessModalComponent
} from './activate-charging-process-modal/activate-charging-process-modal.component';
import {ChargingProcessManagementComponent} from './charging-process-management/charging-process-management.component';
import {ConfirmModalService} from '../../_shared/_components/confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-view-service',
    templateUrl: './view-service.component.html',
    styleUrls: ['./view-service.component.scss'],
    providers: [NgbModalConfig, NgbModal]
})
export class ViewServiceComponent implements OnInit, OnDestroy {

    @ViewChild('measuringPointsCockpitComponent') measuringPointsCockpitComponent: MeasuringPointsCockpitComponent | null;
    @ViewChild('activationManagementComponent') activationManagementComponent: ActivationManagementComponent | null;
    @ViewChild('chargingProcessManagementComponent') chargingProcessManagementComponent: ChargingProcessManagementComponent | null;

    // Status retrieve by Datatrans to display the transaction result
    datatransTransactionStatus = '';

    buildIconUrl = ServiceIconUrlBuilder.buildIconUrl;

    qrCodeReaderEnabled: boolean;
    hasPermission: boolean;
    loggedIn: boolean;
    displayCameraAccessMessage: boolean;
    cameraIsActivated: boolean;

    serviceId!: string;
    deviceIdentifierToActivate!: string;
    evseIndexNumber: string | null; // For charging stations activation
    service!: ServiceDto;
    userId: string | null = null;
    lowestTariff: TariffDto | null;

    displayDescription = false;
    displayInstruction = false;

    // icons
    faPen = faPen;
    faCircle = faCircle;

    // Enums
    AppRoles = AppRoles;
    AuthenticationMethods = AuthenticationMethods;
    ServiceType = ServiceType;
    BarcodeFormat = BarcodeFormat;

    dateFormat = DateUtils.dateFormat;
    DateUtils = DateUtils;

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription;

    constructor(private readonly serviceService: ServiceService,
                private readonly tariffService: TariffService,
                private readonly route: ActivatedRoute,
                private readonly translateService: TranslateService,
                private readonly notificationService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                private readonly modalService: NgbModal,
                private readonly authService: MsalService,
                private readonly rolesService: RolesService,
                private readonly router: Router,
                private readonly loaderService: LoaderService,
                private readonly chargingProcessService: ChargingProcessService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                    window.location.reload();
                }
            });

        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;

        this.serviceId = this.route.snapshot.params['serviceId'];
        this.deviceIdentifierToActivate = this.route.snapshot.params['measuringPointId'] ?? this.route.snapshot.params['externalChargingStationId'];
        this.evseIndexNumber = this.route.snapshot.params['evseIndex'];

        this.datatransTransactionStatus = this.route.snapshot.queryParams['datatransStatus'];

        this.userId = this.rolesService.hasRole(AppRoles.admin) ? null : this.authService.instance.getActiveAccount()?.localAccountId ?? null;

        this.fetchService();

        this.stopChargeForVisitor(this.router.url);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    manageService(): void {
        this.router.navigate(['/services/manage/' + this.serviceId]);
    }

    back(): void {
        this.router.navigate(['/services']);
    }

    formatTimeStringToDisplay(time: string): string {
        return time.slice(0, -3).replace(/:/, 'h');
    }

    scanSuccessHandler(qrCodeValue: string): void {
        this.qrCodeReaderEnabled = false;
        this.cameraIsActivated = false;

        // Special case for Hydrowash service
        if (this.service.serviceConfig.serviceType === ServiceType.Hydrowash) {
            const qrCodeArray = qrCodeValue.split(':');
            if (qrCodeArray[0] === environment.qrCodeEnvPrefix) {
                if (qrCodeArray[1] === 'Hydrowash') {
                    this.openHydrowashActivationModal(qrCodeArray[2]);
                    return;
                }
            }

        }
        else if (this.service.serviceConfig.serviceType === ServiceType.ChargingStations) {
            const segments = qrCodeValue.split('/');

            if (segments.length === 10 && segments[4] === 'services' && segments[6] === 'start-charge' && segments[8] === 'evse') {

                let externalChargingStationId: string = segments[7];

                let evseIndex: number | null = parseInt(segments[9]) ?? null;

                if (evseIndex) {
                    this.openChargingProcessActivationModal(externalChargingStationId, evseIndex);

                    return;
                }
            }
        }
        else { // All other services
            this.deviceIdentifierToActivate = qrCodeValue.split('/activate/')[1] ?? 'unknown';
            this.openServiceActivationModal();
            return;
        }

        this.notificationService.error({
            title: 'error.qrcode.unknown.title',
            message: 'error.qrcode.unknown.message'
        });
    }

    openServiceActivationModal(): void {

        if (this.hasServiceAccessForServiceType(this.service?.serviceConfig.serviceType)) {
            if (this.deviceIdentifierToActivate && this.lowestTariff) {
                if (this.service.serviceConfig.serviceType === ServiceType.KocoDumpster) {
                    const modal = this.modalService.open(ActivateKocoDumpsterModalComponent, {centered: true});
                    modal.componentInstance.deviceIdentifierToActivate = this.deviceIdentifierToActivate;

                    modal.result.then(error => {
                        // Remove the params in URL
                        this.clearUrlAfterActivation();

                        if (error) {
                            if (error.status === 404) {
                                this.notificationService.error({
                                    title: 'error.qrcode.unknown.title',
                                    message: 'error.qrcode.unknown.message'
                                });
                            } else {
                                throw error;
                            }
                        }

                    }, error => {
                        // Remove the params in URL
                        if (error !== 'destroy') {
                            this.clearUrlAfterActivation();
                        }
                    });

                } else if (this.service.serviceConfig.serviceType === ServiceType.ChargingStations) {
                    this.openChargingProcessActivationModal(this.deviceIdentifierToActivate, parseInt(this.evseIndexNumber));
                }
                else {
                    const modal = this.modalService.open(ActivateServiceModalComponent, {centered: true});
                    modal.componentInstance.serviceId = this.serviceId;
                    modal.componentInstance.measuringPointId = this.deviceIdentifierToActivate;
                    modal.componentInstance.defaultTariff = this.lowestTariff;
                    modal.componentInstance.hasVisitorAccess = this.service.roles.length === 0;
                    modal.componentInstance.activationDuration = this.service.impulseDuration;
                    modal.componentInstance.activationUnit = this.service.impulseDurationUnit;

                    modal.result.then(value => {
                        if (value === 'MeasuringPointNotFound') {
                            this.notificationService.error({
                                title: 'error.qrcode.unknown.title',
                                message: 'error.qrcode.unknown.message'
                            });
                        } else {
                            this.activationManagementComponent?.reloadData();
                            this.measuringPointsCockpitComponent?.reloadData();
                        }

                        // Remove the params in URL
                        this.clearUrlAfterActivation();

                    }, error => {
                        // Remove the params in URL
                        if (error !== 'destroy') {
                            this.clearUrlAfterActivation();
                        }
                    });
                }
            }
        }
    }

    openHydrowashActivationModal(trackId: string): void {
        const modal = this.modalService.open(ActivateHydrowashModalComponent, {centered: true});
        modal.componentInstance.trackId = trackId;
        modal.componentInstance.availablePaymentTypes = this.lowestTariff?.paymentTypes;

        modal.result.then(async value => {
            if (value === 'confirm') {
                this.fetchService();
            }
        }, () => { /* catch the rejection */ });
    }

    openChargingProcessActivationModal(externalChargingStationId: string, evseIndex: number): void {
        const modal = this.modalService.open(ActivateChargingProcessModalComponent, {centered: true});

        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.externalChargingStationId = externalChargingStationId;
        modal.componentInstance.evseIndex = evseIndex;
        modal.componentInstance.defaultTariff = this.lowestTariff;
        modal.componentInstance.userId = this.userId;

        modal.result.then(async value => {
            if (value === 'success') {
                // Reload charging processes data
                await this.chargingProcessManagementComponent?.clearFilter();
            }
            else if (value === 'ChargingStationNotFound') {
                this.notificationService.error({
                    title: 'error.qrcode.unknown.title',
                    message: 'error.qrcode.unknown.message'
                });
            }

            // Remove the params in URL
            this.clearUrlAfterActivation();
        }, error => {
            // Remove the params in URL
            if (error !== 'destroy') {
                this.clearUrlAfterActivation();
            }
        });
    }

    canScanQrCode() {
        // Admin can not activate charging station service
        if (this.service.serviceConfig.serviceType == ServiceType.ChargingStations && this.rolesService.hasRoleAdmin()) {
            return false;
        }
        return !!this.service.authenticationMethods.find(am => am === AuthenticationMethods.Qrcode)
            && !!this.lowestTariff
            && !this.cameraIsActivated
            && this.hasServiceAccessForServiceType(this.service?.serviceConfig.serviceType);
    }

    startQrCodeReader(): void {
        this.qrCodeReaderEnabled = true;
        this.loaderService.start('QrCodeLoader');
    }

    stopQrCodeReader(): void {
        this.cameraIsActivated = false;
        this.qrCodeReaderEnabled = false;
    }

    startBuyProcess(): void {
        const modal = this.modalService.open(PurchaseServiceModalComponent, {centered: true});
        modal.componentInstance.tariff = this.lowestTariff;
        modal.componentInstance.service = this.service;
    }

    exportPottingerStatistics(): void {
        this.modalService.open(ExportPottingerStatisticModalComponent, {centered: true});
    }

    activateMeasuringPoint(measuringPointId: string): void {
        this.deviceIdentifierToActivate = measuringPointId;
        this.openServiceActivationModal();
    }

    permissionResponse(hasPermission: boolean): void {
        this.hasPermission = hasPermission;
        if (!hasPermission) {
            this.loaderService.stop('QrCodeLoader');
            this.qrCodeReaderEnabled = false;
            this.displayCameraAccessMessage = true;
        } else {
            this.displayCameraAccessMessage = false;
            this.cameraIsActivated = true;
        }
    }

    camerasFoundHandler(): void{
        this.loaderService.stop('QrCodeLoader');
    }

    hasRoleToAccessTheService(): boolean {
        if (this.service?.roles.length === 0){
            return true;
        }
        return this.rolesService.hasAnyRole(this.service.roles.map(r => r.roleKey))
    }

    hasAccessToBookingCockpit(): boolean {
        return this.service?.bookable && this.service?.items.length > 0 && this.hasServiceAccessForServiceType(ServiceType.Bookable);
    }

    hasRole(role: string): boolean {
        return this.rolesService.hasRole(role);
    }

    hasServiceAccessForServiceType(serviceType: ServiceType): boolean {
        // Check Service Type
        if (this.service?.serviceConfig.serviceType !== serviceType) {
            return false;
        }

        if (!this.service?.active) {
            // Only Admin can access inactive service
            // Harbourmaster is considered as Admin for MooringPlace, CraningAndWinterizing and Bookable services
            if (!this.rolesService.hasRoleAdmin() && !(this.rolesService.hasRoleHarbourMaster() &&
                (serviceType === ServiceType.MooringPlace || serviceType === ServiceType.CraningAndWinterizing || serviceType === ServiceType.Bookable))) {
                return false;
            }
        }

        // Check for service type
        switch (serviceType) {
            // Services only for admin
            case ServiceType.PublicLighting:
                return this.rolesService.hasRoleAdmin()

            // Services only for users (ignore tariffs)
            case ServiceType.UserAlarm:
                return this.rolesService.hasRoleUser() && this.hasRoleToAccessTheService();

            // Services only for admin and harbourmaster (ignore tariffs)
            case ServiceType.MooringPlace:
                return this.rolesService.hasRoleAdmin() || this.rolesService.hasRoleHarbourMaster();

            // Services for all (ignore tariffs)
            case ServiceType.Default:
            case ServiceType.FalconicDumpster:
            case ServiceType.KocoDumpster:
            case ServiceType.AccessControl:
            case ServiceType.TimeLimited:
            case ServiceType.Webcam:
            case ServiceType.Weather:
            case ServiceType.ChargingStations:
                return this.rolesService.hasRoleAdmin() || this.hasRoleToAccessTheService(); // Check Role + Public

            // Services for all (need tariff set)
            case ServiceType.Hydrowash:
            case ServiceType.Energy:
            case ServiceType.Wifi:
                return !!this.lowestTariff && (this.rolesService.hasRoleAdmin() || this.hasRoleToAccessTheService());

            // Services for all and Harbour Master is considered as "Admin" (need tariff set)
            case ServiceType.Bookable:
            case ServiceType.CraningAndWinterizing:
                return !!this.lowestTariff && (this.rolesService.hasRoleAdmin() || this.rolesService.hasRoleHarbourMaster() || this.hasRoleToAccessTheService());
        }

        return false;
    }

    private stopChargeForVisitor(url: string): void {
        const segments = url.split('/');

        if (segments.find(s => s === 'stop-charge')) {
            let chargeProcessId: string = segments[4];

            this.confirmService.confirm({titleKey: 'common.confirmModal.title.disable'}).then(result => {
                if (result === ConfirmModalService.yes) {
                    this.chargingProcessService.deactivateChargingProcessForVisitor(chargeProcessId).pipe().subscribe((): void => {
                        this.notificationService.success({
                            title: 'activations.terminateSuccessMessage',
                            message: 'activations.terminateSuccessExplanation',
                        });
                    });
                }
                this.clearUrlAfterActivation();
            });
        }
    }

    private fetchService(): void {
        this.serviceService.getService(this.serviceId).pipe().subscribe(service => {
            this.service = service;
            this.lowestTariff = service.tariffs.find(t => t.defaultTariff);

            if (this.userId) {
                this.tariffService.getTariffForServiceAndUser(this.serviceId, this.userId).pipe().subscribe(tariff => {
                    this.lowestTariff = tariff;
                    // Open the activation modal if necessary
                    this.openServiceActivationModal();
                });

            } else {
                // Open the activation modal if necessary
                this.openServiceActivationModal();
            }

            this.checkDisplayOfLanguageFields();

            // Keep only effective availability range
            this.service.availabilityRanges = this.service.availabilityRanges.filter(a => a.endDate == null || a.endDate > new Date());

            this.service.availabilityRanges.filter(a => a.endTime !== null && a.endDate != null).forEach(t => {
                const time = t.endTime.split(':');
                t.endDate.setHours(Number(time[0]));
                t.endDate.setMinutes(Number(time[1]));
                t.endDate.setSeconds(Number(time[2]));
            });

            // Sort availability ranges
            this.service.availabilityRanges.sort((a, b) => {
                if (a.startDate.getDate() === b.startDate.getDate()) {
                    // Sort by EndDate
                    if (!a.endDate) {
                        return 1;

                    } else if (!b.endDate) {
                        return -1;
                    }
                    return a.endDate.getDate() - b.endDate.getDate();

                } else {
                    // Sort by StartDate
                    return a.startDate.getDate() - b.startDate.getDate();
                }
            });

        }, () => this.router.navigate(['/']));
    }

    private checkDisplayOfLanguageFields(): void {
        if (this.crtLang === 'fr') {
            this.displayDescription = this.service.descriptionFR?.length > 0;
            this.displayInstruction = this.service.instructionFR?.length > 0;

        } else if (this.crtLang === 'de') {
            this.displayDescription = this.service.descriptionDE?.length > 0;
            this.displayInstruction = this.service.instructionDE?.length > 0;

        } else if (this.crtLang === 'en') {
            this.displayDescription = this.service.descriptionEN?.length > 0;
            this.displayInstruction = this.service.instructionEN?.length > 0;

        } else if (this.crtLang === 'it') {
            this.displayDescription = this.service.descriptionIT?.length > 0;
            this.displayInstruction = this.service.instructionIT?.length > 0;
        }
    }

    private clearUrlAfterActivation(): void {
        this.router.navigate(['/services/' + this.serviceId], {replaceUrl: true});
    }
}

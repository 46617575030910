<div>
    <div class="modal-header">
        <h4 class="modal-title"
            [translate]="tariff ? 'services.tariff.modal.modifyTitle' : 'services.tariff.modal.addTitle'"></h4>

        <button class="btn-close" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')">
        </button>
    </div>

    <div class="modal-body">
        <form name="tariffForm" [formGroup]="tariffForm">

            <!-- TariffConfig -->
            <div class="row" *ngIf="!serviceConfig.canUserChooseUnit">

                <div class="col-12">
                    <label class="form-label" for="tariffConfig">{{ 'services.tariff.modal.tariffConfig' | translate }}*</label>
                    <div class="col-12">
                        <select class="form-control form-select" id="tariffConfig" name="tariffConfig"
                                [formControl]="tariffConfig"
                                (change)="adaptFormDependingTariffConfig()">
                            <option *ngFor="let config of serviceConfig.tariffConfigs"
                                    [ngValue]="config">
                                {{ config['label' + this.crtLang.toUpperCase()] }}
                            </option>
                        </select>
                    </div>
                </div>

            </div>

            <!-- Night Rate -->
            <div class="row" *ngIf="serviceConfig.dayAndNightTariff">
                <div class="col-12">
                    <label class="form-label" for="nightRate" translate="services.tariff.modal.nightRate"></label>
                    <div class="form-check form-switch">
                        <input class="form-check-input" id="nightRate" type="checkbox" name="nightRate"
                               [formControl]="nightRate">
                        <label class="form-check-label" for="nightRate">
                            {{ (nightRate.value ? 'common.yes' : 'common.no') | translate }}
                        </label>
                    </div>
                </div>
            </div>

            @if (nightRate.value) {
                <div class="row">
                    <div class="col-12">
                        <h5 class="mt-4" translate="services.tariff.modal.nightRateManagement"></h5>
                    </div>

                    <div class="col-6">
                        <label class="form-label">{{ 'common.startTime' | translate }}*</label>
                        <ngb-timepicker formControlName="nightRateStart" [required]="nightRate.value"></ngb-timepicker>
                        <app-form-error [control]="nightRateStart"></app-form-error>
                    </div>
                    <div class="col-6">
                        <label class="form-label">{{ 'common.endTime' | translate }}*</label>
                        <ngb-timepicker formControlName="nightRateEnd" [required]="nightRate.value"></ngb-timepicker>
                        <app-form-error [control]="nightRateEnd"></app-form-error>
                    </div>
                </div>

                @if (nightRateStart.value && nightRateEnd.value) {
                    <p class="mb-0">{{ 'services.tariff.modal.dayRateInfo' | translate: { from: DateUtils.ngbTimeStructToString(nightRateEnd.value, true), to: DateUtils.ngbTimeStructMinusOneMinuteToString(nightRateStart.value) } }}</p>
                    <p>{{ 'services.tariff.modal.nightRateInfo' | translate: { from: DateUtils.ngbTimeStructToString(nightRateStart.value, true), to: DateUtils.ngbTimeStructMinusOneMinuteToString(nightRateEnd.value) } }}</p>
                }
            }

            <ng-container *ngIf="tariffConfig.value">

                <h5 class="mt-2" *ngIf="nightRate.value" translate="services.tariff.modal.daytimeSchedule"></h5>
                <app-tariff-prices-management #tariffPricesManagementComponent
                                              [canUserChooseUnit]="serviceConfig.canUserChooseUnit"
                                              [containsMultipleTariffs]="containsMultipleTariffs"
                                              [isDefaultTariff]="isDefaultTariff"
                                              [isNightRate]="false"
                                              [tariffConfig]="tariffConfig.value"
                                              [tariff]="tariff ?? defaultTariff"
                                              [parentForm]="tariffForm"></app-tariff-prices-management>

                @if (nightRate.value) {
                    <h5 class="mt-4" translate="services.tariff.modal.nightSchedule"></h5>
                    <app-tariff-prices-management #nightTariffPricesManagementComponent
                                                  [canUserChooseUnit]="serviceConfig.canUserChooseUnit"
                                                  [containsMultipleTariffs]="containsMultipleTariffs"
                                                  [isDefaultTariff]="isDefaultTariff"
                                                  [isNightRate]="true"
                                                  [tariffConfig]="tariffConfig.value"
                                                  [tariff]="tariff ?? defaultTariff"
                                                  [parentForm]="tariffForm"></app-tariff-prices-management>
                }

                <!-- Time of Payment -->
                <div class="col-12">
                    <label class="form-label" for="timeOfPayment">{{ 'common.terms.timeOfPayment' | translate }}*</label>
                    <select class="form-control form-select" id="timeOfPayment" name="timeOfPayment"
                            [formControl]="selectedTimeOfPayment"
                            (change)="initPaymentTypes()"
                            required>
                        <option *ngFor="let timeOfPayment of availableTimesOfPayment;"
                                [ngValue]="timeOfPayment">{{ 'common.timeOfPayment.' + timeOfPayment | translate }}
                        </option>
                    </select>
                    <app-form-error [control]="selectedTimeOfPayment"></app-form-error>
                </div>

                <!-- Billing Recurrence -->
                <div class="col-12" *ngIf="selectedBillingRecurrence.enabled">
                    <label class="form-label" for="billingRecurrence">{{ 'services.tariff.modal.billingRecurrence' | translate }}*</label>
                    <select class="form-control form-select" id="billingRecurrence" name="billingRecurrence"
                            [formControl]="selectedBillingRecurrence"
                            required>
                        <option *ngFor="let billingRecurrence of availableBillingRecurrence" [ngValue]="billingRecurrence">
                            {{ 'common.billingRecurrence.' + billingRecurrence | translate }}
                        </option>
                    </select>
                </div>

                <!-- Payment Types -->
                <div class="col-12">
                    <label class="form-label" for="paymentTypes">{{ 'common.terms.paymentType' | translate }}*</label>
                    <ng-multiselect-dropdown id="paymentTypes" style="width: 100%;"
                                             placeholder="{{ 'common.multiselect.placeholder' | translate }}"
                                             [formControl]="selectedPaymentTypes"
                                             [settings]="paymentTypeSelect.settings"
                                             [data]="paymentTypeSelect.dropdownList"
                                             [disabled]="!isDefaultTariff">
                    </ng-multiselect-dropdown>
                    <app-form-error [control]="selectedPaymentTypes"></app-form-error>
                </div>

                <!-- Custom Roles -->
                <div class="col-12" *ngIf="!!selectedCustomRoles">
                    <label class="form-label">{{ 'services.tariff.roles' | translate }}*</label>
                    <ng-multiselect-dropdown id="customRoles" style="width: 100%;"
                                             placeholder="{{ 'common.multiselect.placeholder' | translate }}"
                                             [formControl]="selectedCustomRoles"
                                             [settings]="customRoleSelect.settings"
                                             [data]="customRoleSelect.dropdownList">
                    </ng-multiselect-dropdown>
                    <app-form-error [control]="selectedCustomRoles"></app-form-error>
                </div>

                <!-- Validity Dates -->
                <div *ngIf="!!validityPeriod">
                    <app-date-period-picker [periodControl]="validityPeriod"
                                            [fromMinDate]="minDate"
                                            [toMinDate]="minDate"
                                            inputLabel="services.tariff.validity"></app-date-period-picker>
                </div>

            </ng-container>

        </form>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary"
                (click)="activeModal.close()"
                translate="common.cancel">
        </button>
        <button type="submit" class="btn btn-primary"
                (click)="addOrUpdateTariff()"
                [disabled]="tariffForm.pristine || tariffForm.invalid"
                translate="common.save">
        </button>
    </div>
</div>


import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    IotRegisterDto,
    IotRegisterService,
    PaymentTypes,
    RegisterType,
    TariffDto,
    UserAlarmSubscriptionDto,
    UserAlarmSubscriptionInputDto,
    UserAlarmSubscriptionService,
} from '../../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {FormUtils} from '../../../../_shared/form-utils';
import {TranslateUtils} from '../../../../_shared/translate-utils';

@Component({
    selector: 'app-user-alarm-modal',
    templateUrl: './user-alarm-modal.component.html'
})
export class UserAlarmModalComponent implements OnInit, OnDestroy {

    @Input() serviceId!: string;
    @Input() tariff!: TariffDto;
    @Input() userAlarmSubscription: UserAlarmSubscriptionDto | null = null;

    RegisterType = RegisterType;

    alarmForm: UntypedFormGroup;

    availableIotRegisters: IotRegisterDto[] = [];
    availablePaymentTypes: PaymentTypes[] = [];

    textInputPattern = FormUtils.textInputPattern;

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(public activeModal: NgbActiveModal,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly iotRegisterService: IotRegisterService,
                private readonly userAlarmSubscriptionService: UserAlarmSubscriptionService) {

        this.alarmForm = new UntypedFormGroup({
                iotRegister: new UntypedFormControl(null, Validators.required),
                designation: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
                active: new UntypedFormControl(true, Validators.required),
                transmissionMode: new UntypedFormArray([
                    new UntypedFormControl(true), // Mail
                    new UntypedFormControl(true) // Push
                ], Validators.required)
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.fetchIotRegisters();

        if (this.userAlarmSubscription) { // Edition mode
            this.iotRegister.setValue(this.userAlarmSubscription.iotRegister);
            this.designation.setValue(this.userAlarmSubscription.designation);
            this.active.setValue(this.userAlarmSubscription.active);

            this.transmissionMode.setValue([
                this.userAlarmSubscription.mailNotificationEnabled,
                this.userAlarmSubscription.pushNotificationEnabled]);


        } else { // Creation Mode
            this.alarmForm.addControl('paymentType', new UntypedFormControl(null, Validators.required));
            this.configurePaymentTypes(this.tariff);
        }
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    configurePaymentTypes(tariff: TariffDto): void {
        this.availablePaymentTypes = tariff.paymentTypes;

        if (this.availablePaymentTypes.length < 2) {
            this.paymentType?.setValue(this.availablePaymentTypes[0]);
        }
    }

    fetchIotRegisters(): void {
        this.iotRegisterService.getMyAlarmRegisters().pipe().subscribe(registers => {
            this.availableIotRegisters = registers;

            if (this.userAlarmSubscription) {
                // Select the correct register
                const ir = this.availableIotRegisters.find(r => r.id === this.iotRegister.value.id);
                this.iotRegister.setValue(ir);
                this.iotRegister.disable();
            }
        });
    }

    createSubscription(): void {
        const subscription = new UserAlarmSubscriptionInputDto({
            serviceId: this.serviceId,
            iotRegisterId: this.iotRegister.value.id,
            designation: this.designation.value,
            active: this.active.value,
            mailNotificationEnabled: this.transmissionMode.value[0],
            pushNotificationEnabled: this.transmissionMode.value[1],
            paymentType: this.paymentType.value
        });

        this.userAlarmSubscriptionService.createSubscription(subscription).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'services.userAlarm.subscriptions.notifications.addSuccess'});
            this.activeModal.close('success');
        });
    }

    editSubscription(): void {
        const subscription = Object.assign({}, this.userAlarmSubscription);
        subscription.designation = this.designation.value;
        subscription.active = this.active.value;
        subscription.mailNotificationEnabled = this.transmissionMode.value[0];
        subscription.pushNotificationEnabled = this.transmissionMode.value[1];

        this.userAlarmSubscriptionService.updateSubscription(subscription.id, subscription).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'services.userAlarm.subscriptions.notifications.updateSuccess'});
            this.activeModal.close('success');
        });
    }

    selectPaymentType(paymentType: PaymentTypes): void {
        this.paymentType?.setValue(paymentType);
    }

    getIotRegisterLabel(iotRegister: IotRegisterDto): string {
        return iotRegister['label' + this.crtLang.toUpperCase()];
    }

    get iotRegister(): AbstractControl {
        return this.alarmForm.get('iotRegister');
    }

    get designation(): AbstractControl {
        return this.alarmForm.get('designation');
    }

    get active(): AbstractControl {
        return this.alarmForm.get('active');
    }

    get transmissionMode(): AbstractControl {
        return this.alarmForm.get('transmissionMode');
    }

    get paymentType(): AbstractControl | null {
        return this.alarmForm.get('paymentType') ?? null;
    }

}

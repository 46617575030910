import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-public-lighting',
  templateUrl: './public-lighting.component.html'
})
export class PublicLightingComponent {

    @Input() serviceId: string;


}

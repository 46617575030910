<div class="modal-header">
    <h4 [translate]="!isUpdateModal ? 'services.wifi.assignWifi.add.title' : 'services.wifi.assignWifi.update.title'"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="selectWifiForm">
        <div class="row">

            <div class="col-12">
                <label class="form-label" for="wifiSelect" translate="services.wifi.wifiSelect"></label>
                <select class="form-control form-select" id="wifiSelect" formControlName="wifiSelect">
                    <option *ngFor="let wifi of availableWifiNetworks;"
                            [ngValue]="wifi.id">{{ getWifiName(wifi) }} ({{wifi.ssid}})</option>
                </select>
                <app-form-error [control]="wifiSelect"></app-form-error>
            </div>

        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="services.wifi.action.cancel">
    </button>
    <button class="btn btn-primary" type="submit"
            (click)="assignWifi()"
            [disabled]="selectWifiForm.invalid"
            translate="services.wifi.action.save">
    </button>
</div>


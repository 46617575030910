
<h4 translate="services.availabilityRange.availabilityRange"></h4>

<div class="btn-bar">
    <button class="btn btn-primary"
            (click)="createOrEditAvailabilityRange()"
            translate="services.availabilityRange.actions.add">
    </button>
</div>

<div class="table-responsive">
    <table class="table table-striped" [attr.aria-label]="'services.availabilityRange.availabilityRange' | translate">
        <thead>
        <tr>
            <th scope="col" style="min-width:300px" translate="services.availabilityRange.table.availabilityRange"></th>
            <th scope="col" style="min-width:200px"></th>
            <th scope="col" style="min-width:200px"></th>
            <th scope="col" style="min-width:100px"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="availabilityRanges.length === 0">
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td></td>
        </tr>

        <tr *ngFor="let availabilityRange of availabilityRanges;">

            <!-- Dates -->
            <td *ngIf="availabilityRange.endDate">
                {{'services.view.availabilityRange.fromToDate' | translate: {
                startDate: (availabilityRange.startDate | date: dateFormat),
                endDate: (availabilityRange.endDate | date: dateFormat) } }}
            </td>
            <td *ngIf="!availabilityRange.endDate">
                {{ 'services.view.availabilityRange.sinceDate' | translate: {
                startDate: (availabilityRange.startDate | date: dateFormat) } }}
            </td>

            <!-- Days -->
            <td *ngIf="availabilityRange.days">
                {{ availabilityRange.days | dayRangeFormat }}
            </td>

            <!-- Hours -->
            <td *ngIf="availabilityRange.startTime === '00:00:00' && availabilityRange.endTime === '23:59:00'">
                {{ 'services.view.availabilityRange.allDay' | translate }}
            </td>
            <td *ngIf="availabilityRange.startTime !== '00:00:00' && availabilityRange.endTime === '23:59:00'">
                {{ 'services.view.availabilityRange.sinceTime' | translate: {
                startTime: formatTimeStringToDisplay(availabilityRange.startTime) } }}
            </td>
            <td *ngIf="availabilityRange.startTime === '00:00:00' && availabilityRange.endTime !== '23:59:00'">
                {{ 'services.view.availabilityRange.untilTime' | translate: {
                endTime: formatTimeStringToDisplay(availabilityRange.endTime) } }}
            </td>
            <td *ngIf="availabilityRange.startTime !== '00:00:00' && availabilityRange.endTime !== '23:59:00'">
                {{ 'services.view.availabilityRange.fromToTime' | translate: {
                startTime: formatTimeStringToDisplay(availabilityRange.startTime),
                endTime: formatTimeStringToDisplay(availabilityRange.endTime) } }}
            </td>

            <td class="text-end">
                <button class="btn btn-link btn-sm"
                        (click)="updateAvailabilityRange(availabilityRange)"
                        title="{{'common.tooltip.edit' | translate}}">
                    <fa-icon [icon]="icons.edit"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm"
                        (click)="deleteAvailabilityRange(availabilityRange)"
                        title="{{'common.tooltip.delete' | translate}}">
                    <fa-icon [icon]="icons.delete"></fa-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>

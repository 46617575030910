<div class="modal-header">
    <h4 class="modal-title" translate="services.craning.booking.form.modal.confirmation.billing.title"></h4>

    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.close()">
    </button>
</div>

<div class="modal-body">

    <p class="translated-paragraph">{{ 'transactions.billings.description.' + billingStateDto.state.toLowerCase() | translate }}</p>

    <!-- Deposit price -->
    <div class="row">

        <div class="col-12">
            <label class="form-label" translate="transactions.billings.date"></label>
            <div class="input-group">
                <input class="form-control" name="date"
                       placeholder="{{ 'common.placeholders.dateFormat' | translate}}"
                       [formControl]="billingDate"
                       ngbDatepicker #d="ngbDatepicker">

                <span class="input-group-text">
                    <button class="btn btn-link btn-sm" (click)="d.toggle()" type="button">
                        <fa-icon [icon]="calendar"></fa-icon>
                    </button>
                </span>
            </div>
        </div>

    </div>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel"></button>

    <button type="button" class="btn btn-primary"
            (click)="confirm()"
            [disabled]="billingDate.invalid"
            translate="common.save"></button>
</div>


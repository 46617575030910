<div class="modal-header">
    <h4 class="modal-title" translate="activations.extend"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">

    <form [formGroup]="extendForm">

        <div class="row">

            <div class="col-12 col-lg-8">
                <label class="form-label">{{ 'activations.initialImpulseDuration' | translate }}</label>
                <div class="input-group">
                    <input class="form-control" id="impulseDuration" name="impulseDuration" type="number"
                           formControlName="impulseDuration">
                    <span class="input-group-text" translate="{{ 'common.impulseDurationUnit.' + activationUnit | translate }}"></span>
                </div>
            </div>

            <div class="col-12 col-lg-8">
                <label class="form-label">{{ 'activations.maxImpulseDuration' | translate }}</label>
                <div class="input-group">
                    <input class="form-control" id="maxImpulseDuration" name="maxImpulseDuration" type="number"
                           formControlName="maxImpulseDuration">
                    <span class="input-group-text" translate="{{ 'common.impulseDurationUnit.' + activationUnit | translate }}"></span>
                </div>
            </div>

            <div class="col-12 col-lg-8">
                <label class="form-label">{{ 'activations.extendedImpulseDuration' | translate }}</label>
                <div class="input-group">
                    <input class="form-control" id="extendedImpulseDuration" name="extendedImpulseDuration" type="number"
                           (keypress)="FormUtils.validateInputNumberLength($event, 8)"
                           formControlName="extendedImpulseDuration">
                    <span class="input-group-text" translate="{{ 'common.impulseDurationUnit.' + activationUnit | translate }}"></span>
                </div>
                <app-form-error [control]="extendedImpulseDuration"></app-form-error>
            </div>

        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>
    <button type="submit" class="btn btn-primary"
            (click)="extendActivation()"
            [disabled]="extendForm.invalid"
            translate="common.extend">
    </button>
</div>





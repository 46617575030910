import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RoleDto, RoleService} from '../../../_services/configuration-services';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TranslateUtils} from '../../translate-utils';

@Component({
    selector: 'app-role-management',
    templateUrl: './role-management.component.html'
})
export class RoleManagementComponent implements OnInit, OnDestroy {

    @Input() enablePublicAccess!: boolean;
    @Input() enableBaseRoles!: boolean;
    @Input() enableCustomRoles!: boolean;

    @Input() userOnly!: boolean;
    @Input() hideCustomIfUserSelected: boolean = false;
    @Input() requiredFields = true;

    @Input() roleForm!: UntypedFormGroup;

    public baseRoles: RoleDto[] = [];
    public customRoles: RoleDto[] = [];
    loaded = false;

    userRoleIdx: number;

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    private publicRoleChangeSubscription: Subscription | null;

    constructor(private readonly roleService: RoleService,
                private readonly translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.roleService.getAllRoles(false).pipe().subscribe(roles => {
            if (this.userOnly) {
                this.baseRoles = roles.filter(r => r.roleKey === 'User');
            } else {
                this.baseRoles = roles.filter(r => r.isBase).sort((a, b) => a.roleKey.localeCompare(b.roleKey));
            }
            this.customRoles = roles.filter(r => !r.isBase).sort((a, b) => a.roleKey.localeCompare(b.roleKey));
            this.userRoleIdx = this.baseRoles.map(v => v.roleKey).indexOf('User');
            this.loaded = true;
        });

        if (this.enablePublicAccess) {
            this.publicRoleChangeSubscription = this.publicRolesFormControl.valueChanges.subscribe(value => {
                this.enableCustomRoles = !value;
                this.enableBaseRoles = !value;
            });
            this.enableCustomRoles = !this.publicRolesFormControl.value;
            this.enableBaseRoles = !this.publicRolesFormControl.value;
        }
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        if (this.publicRoleChangeSubscription) {
            this.publicRoleChangeSubscription.unsubscribe();
        }
    }

    public getSelectedRoles(): [] {
        if (this.publicRolesFormControl?.value === true) {
            return [];
        }

        let selectedRoles = this.baseRolesFormArray.value
            .map((checked: boolean, i: number) => checked ? this.baseRoles[i] : null)
            .filter((v: any) => v !== null);

        if (this.enableCustomRoles && !this.areCustomRolesHiddenByUserRole) {
            selectedRoles = selectedRoles.concat(this.customRolesFormArray.value
                .map((checked: boolean, i: number) => checked ? this.customRoles[i] : null)
                .filter((v: any) => v !== null));
        }

        return selectedRoles;
    }

    get areCustomRolesHiddenByUserRole(): boolean {
        return this.hideCustomIfUserSelected ? this.baseRolesFormArray.value[this.userRoleIdx] : false;
    }

    get publicRolesFormControl(): UntypedFormControl {
        return this.roleForm.controls['publicAccess'] as UntypedFormControl;
    }

    get baseRolesFormArray(): UntypedFormArray {
        return this.roleForm.controls['baseRolesForm'] as UntypedFormArray;
    }

    get customRolesFormArray(): UntypedFormArray {
        return this.roleForm.controls['customRolesForm'] as UntypedFormArray;
    }
}

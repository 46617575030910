import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    ISortCriteriaDto,
    PageableRequestDtoOfWifiSubscriptionSearchCriteriaDto,
    PagedResultDtoOfWifiSubscriptionDto,
    SearchUserDto,
    ServiceService,
    ServiceType,
    SortCriteriaDto,
    SortDirection,
    WifiSubscriptionDto,
    WifiSubscriptionSearchCriteriaDto,
    WifiSubscriptionService,
    WifiSubscriptionState
} from '../../../../_services/configuration-services';
import {Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {faBan, faCalendar, faTimes} from '@fortawesome/free-solid-svg-icons';
import {DateUtils} from '../../../../_shared/date-utils';
import {CustomDateFormatter} from '../../../../_shared/custom-date-formatter';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {FormUtils} from '../../../../_shared/form-utils';
import {ConfirmModalService} from '../../../../_shared/_components/confirm-modal/confirm-modal.component';
import {TranslateUtils} from '../../../../_shared/translate-utils';
import {SearchUserComponent} from "../../../../_shared/_components/search-user/search-user.component";

@Component({
    selector: 'app-wifi-subscription-list-for-admin',
    templateUrl: './wifi-subscription-list-for-admin.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})

export class WifiSubscriptionListForAdminComponent implements OnInit, OnDestroy {

    @ViewChild('subscriptionTable') table;
    @ViewChild('userSearch') userSearch: SearchUserComponent;

    // Filters
    filter: {
        formGroup: UntypedFormGroup,
        // User
        selectedUser: SearchUserDto,

        // Services DropDown
        servicesDropdownList: {
            items: any[],
            settings: IDropdownSettings
        }
        // State DropDown
        statesDropdownList: {
            items: any[],
        }
    } = {
        formGroup: null,
        selectedUser: null,
        servicesDropdownList: {
            items: [],
            settings: {}
        },
        statesDropdownList: {
            items: Object.keys(WifiSubscriptionState)
        }
    };

    // Pagination
    readonly pageSize = 10;
    crtPage = 0;

    // Table content
    wifiSubscriptions: PagedResultDtoOfWifiSubscriptionDto = new PagedResultDtoOfWifiSubscriptionDto();

    dateTimeFormat = DateUtils.dateTimeFormat;

    // Icons
    icons = {
        times: faTimes,
        calendar: faCalendar,
        disable: faBan
    };

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Desc,
        property: 'startDateTime'
    });

    private pageRequest: PageableRequestDtoOfWifiSubscriptionSearchCriteriaDto;

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(
        private readonly wifiSubscriptionService: WifiSubscriptionService,
        private readonly translateService: TranslateService,
        private readonly serviceService: ServiceService,
        private readonly confirmService: ConfirmModalService,
        private readonly notificationsService: NotificationsService,
        private readonly modalService: NgbModal) {

        this.filter.formGroup = new UntypedFormGroup({
                serviceIds: new UntypedFormControl(''),
                period: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
                userId: new UntypedFormControl(''),
                state: new UntypedFormControl(''),
            },
            {updateOn: 'change'}
        );
        this.filter.formGroup.reset();
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
            this.clearFilter();
            this.loadServiceDropDown();
        });

        this.initializeBasePageRequest();

        this.loadServiceDropDown();
        this.fetchWifiSubscriptions(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }


    fetchWifiSubscriptions(pageableRequest: PageableRequestDtoOfWifiSubscriptionSearchCriteriaDto): void {
        this.wifiSubscriptionService.searchSubscriptions(pageableRequest).pipe().subscribe(result => {
            this.wifiSubscriptions = result;
        });
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;
        this.fetchWifiSubscriptions(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.goToFirstPage();
        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = new SortCriteriaDto(sortCriteriaDto);
        this.fetchWifiSubscriptions(this.pageRequest);
    }

    clearFilter(): void {
        // Clear inputs
        this.filter.formGroup.reset();
        this.userSearch.clearInput();

        // clear search
        this.initializeBasePageRequest();
        this.fetchWifiSubscriptions(this.pageRequest);
    }

    clearDateToFilter(): void {
        this.getPeriodFromGroup().controls['to'].reset();
    }

    clearDateFromFilter(): void {
        this.getPeriodFromGroup().controls['from'].reset();
    }

    selectUser(user: SearchUserDto): void {
        this.filter.selectedUser = user;
    }

    applyWifiSubscriptionFilters(): void {
        if (!this.filter.formGroup.valid) {
            return;
        }

        this.crtPage = 0;

        const period = this.getPeriodFromGroup().value;

        const dateFrom = DateUtils.ngbDateStructToDate(period.from);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(period.to);
        dateTo?.setHours(23, 59, 59, 59);

        this.initializeBasePageRequest();

        this.pageRequest.criteria = new WifiSubscriptionSearchCriteriaDto({
            from: dateFrom,
            to: dateTo,
            serviceIds: this.filter.formGroup.value.serviceIds?.map(it => it.itemId),
            userId: this.filter.selectedUser?.id,
            state: this.filter.formGroup.value.state,
            sortCriteriaDto: this.currentSort
        });
        this.fetchWifiSubscriptions(this.pageRequest);
    }

    isPeriodValid(): boolean {
        return this.getPeriodFromGroup().valid;
    }

    getPeriodFromGroup(): UntypedFormGroup {
        return this.filter.formGroup.get('period') as UntypedFormGroup;
    }

    canBeDisabled(subscription: WifiSubscriptionDto): boolean {
        return subscription.state === WifiSubscriptionState.OnGoing
            || subscription.state === WifiSubscriptionState.Reserved;
    }

    disableSubscription(subscription: WifiSubscriptionDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.disable'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.wifiSubscriptionService.disableSubscription(subscription.id).pipe().subscribe({
                    error: error => {
                        this.fetchWifiSubscriptions(this.pageRequest);
                        throw error;
                    },
                    next: () => {
                        this.notificationsService.success({title: 'services.wifi.notifications.subscription.disabled'});
                        this.fetchWifiSubscriptions(this.pageRequest);
                    }
                });
            }
        });
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }

    private initializeBasePageRequest(): void {
        this.pageRequest = new PageableRequestDtoOfWifiSubscriptionSearchCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new WifiSubscriptionSearchCriteriaDto({
            from: null,
            to: null,
            serviceIds: null,
            userId: null,
            state: null,
            sortCriteriaDto: this.currentSort
        });
    }

    private loadServiceDropDown(): void {
        this.serviceService.getServiceNamesForServiceType(ServiceType.Wifi).pipe().subscribe(services => {
            this.filter.servicesDropdownList.items = [];
            const serviceNames = services.sort((a, b) => a['name' + this.crtLang.toUpperCase()].localeCompare(b['name' + this.crtLang.toUpperCase()]));
            serviceNames.forEach(r => this.filter.servicesDropdownList.items.push({
                itemId: r.serviceId,
                itemVal: r['name' + this.crtLang.toUpperCase()]
            }));
        });

        // Dropdown
        this.filter.servicesDropdownList.settings = {
            singleSelection: false,
            idField: 'itemId',
            textField: 'itemVal',
            selectAllText: this.translateService.instant('common.multiselect.selectAll'),
            unSelectAllText: this.translateService.instant('common.multiselect.unselectAll'),
            searchPlaceholderText: this.translateService.instant('common.multiselect.search'),
            noDataAvailablePlaceholderText: this.translateService.instant('common.multiselect.noResults'),
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }
}

<div class="content-container">

    <div *ngFor="let webcam of webcams;">

        <h4>{{webcam['location' + crtLang.toUpperCase()]}}</h4>

        <p class="text-justify">{{webcam['description' + crtLang.toUpperCase()]}}</p>

        <div class="text-center mb-4" *ngIf="webcam.imageUrl">
            <img class="rounded w-100 mw-100" [src]="webcam.imageUrl"
                 alt="{{ 'services.webcam.modal.waiting' | translate }}"/>
        </div>

    </div>
</div>

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {CONFIGURATION_SERVICE_BASE_URL} from './app/_services/configuration-services';

const providers = [
  {provide: CONFIGURATION_SERVICE_BASE_URL, useValue: environment.configurationServiceBaseUrl}
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));

import {Component, Input, OnInit} from '@angular/core';
import {
    WebcamDto, WebcamService
} from '../../../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {CustomerConfigService} from '../../../../_shared/customer-config-service';
import {RolesService} from '../../../../_shared/roles-service';
import {FormUtils} from "../../../../_shared/form-utils";

@Component({
    selector: 'app-webcam-management-modal',
    templateUrl: './webcam-management-modal.component.html'
})
export class WebcamManagementModalComponent implements OnInit {

    @Input() serviceId: string;
    @Input() webcam: WebcamDto | null;

    webcamForm: UntypedFormGroup;

    constructor(public activeModal: NgbActiveModal,
                private readonly webcamService: WebcamService,
                private readonly notificationService: NotificationsService,
                private readonly roleService: RolesService,
                public readonly customerConfigService: CustomerConfigService) {

        this.webcamForm = new UntypedFormGroup({
                locationFR: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
                locationDE: new UntypedFormControl('', Validators.maxLength(50)),
                locationIT: new UntypedFormControl('', Validators.maxLength(50)),
                locationEN: new UntypedFormControl('', Validators.maxLength(50)),
                imageUrl: new UntypedFormControl('', [Validators.required, FormUtils.urlValidator, Validators.maxLength(1000)]),
                descriptionFR: new UntypedFormControl('', Validators.maxLength(500)),
                descriptionDE: new UntypedFormControl('', Validators.maxLength(500)),
                descriptionIT: new UntypedFormControl('', Validators.maxLength(500)),
                descriptionEN: new UntypedFormControl('', Validators.maxLength(500)),
                active: new UntypedFormControl(true)
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        if (this.webcam) {
            this.locationFR.setValue(this.webcam.locationFR);
            this.locationDE.setValue(this.webcam.locationDE);
            this.locationIT.setValue(this.webcam.locationIT);
            this.locationEN.setValue(this.webcam.locationEN);
            this.imageUrl.setValue(this.webcam.imageUrl);
            this.descriptionFR.setValue(this.webcam.descriptionFR);
            this.descriptionDE.setValue(this.webcam.descriptionDE);
            this.descriptionIT.setValue(this.webcam.descriptionIT);
            this.descriptionEN.setValue(this.webcam.descriptionEN);
            this.active.setValue(this.webcam.active);
        }

        if (!this.roleService.hasRoleConfigurator()) {
            this.imageUrl.disable();
        }
    }

    addOrUpdateWebcam(): void {
        if (!this.webcamForm.valid) {
            return;
        }
        const webcamToSave = new WebcamDto({
            id: this.webcam?.id,
            serviceId: this.serviceId,
            imageUrl: this.imageUrl.value,
            locationFR: this.locationFR.value,
            locationDE: this.locationDE.value,
            locationIT: this.locationIT.value,
            locationEN: this.locationEN.value,
            descriptionFR: this.descriptionFR.value,
            descriptionDE: this.descriptionDE.value,
            descriptionIT: this.descriptionIT.value,
            descriptionEN: this.descriptionEN.value,
            active: this.active.value,
            position: this.webcam?.position
        });

        if (!this.webcam) {
            webcamToSave.position = 0;
            this.webcamService.createWebcam(webcamToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.webcam.notifications.addSuccess'});
                this.activeModal.close('success');
            });

        } else {
            webcamToSave.id = this.webcam.id;
            this.webcamService.updateWebcam(this.webcam.id, webcamToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.webcam.notifications.updateSuccess'});
                this.activeModal.close('success');
            });
        }
    }

    get locationFR(): UntypedFormControl {
        return this.webcamForm.get('locationFR') as UntypedFormControl;
    }

    get locationDE(): UntypedFormControl {
        return (this.webcamForm.get('locationDE') as UntypedFormControl);
    }

    get locationIT(): UntypedFormControl {
        return this.webcamForm.get('locationIT') as UntypedFormControl;
    }

    get locationEN(): UntypedFormControl {
        return this.webcamForm.get('locationEN') as UntypedFormControl;
    }

    get descriptionFR(): UntypedFormControl {
        return this.webcamForm.get('descriptionFR') as UntypedFormControl;
    }

    get descriptionDE(): UntypedFormControl {
        return this.webcamForm.get('descriptionDE') as UntypedFormControl;
    }

    get descriptionIT(): UntypedFormControl {
        return this.webcamForm.get('descriptionIT') as UntypedFormControl;
    }

    get descriptionEN(): UntypedFormControl {
        return this.webcamForm.get('descriptionEN') as UntypedFormControl;
    }

    get imageUrl(): UntypedFormControl {
        return this.webcamForm.get('imageUrl') as UntypedFormControl;
    }

    get active(): UntypedFormControl {
        return this.webcamForm.get('active') as UntypedFormControl;
    }

}

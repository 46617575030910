import {Component, OnDestroy, OnInit} from '@angular/core';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {
    EquipmentInterventionCriteriaDto,
    EquipmentInterventionSearchCriteriaDto, EquipmentInterventionService,
    ISortCriteriaDto,
    PageableRequestDtoOfEquipmentCriteriaDto,
    PageableRequestDtoOfEquipmentInterventionCriteriaDto,
    PagedResultDtoOfEquipmentInterventionLightDto,
    SortCriteriaDto,
    SortDirection
} from "../../../_services/configuration-services";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {FormUtils} from "../../../_shared/form-utils";
import {DateUtils} from "../../../_shared/date-utils";
import {NgbDateParserFormatter, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CustomDateFormatter} from "../../../_shared/custom-date-formatter";
import {NotificationsService} from "../../../_shared/notifications.service";
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-interventions',
    templateUrl: './interventions.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class InterventionsComponent implements OnInit, OnDestroy {

    filterForm: UntypedFormGroup;

    equipmentInterventions: PagedResultDtoOfEquipmentInterventionLightDto = new PagedResultDtoOfEquipmentInterventionLightDto();

    readonly pageSize = 10;
    crtPage = 0;

    icons = {
        edit: faPen,
        delete: faTrash
    }

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Asc,
        property: 'date'
    });

    private pageRequest: PageableRequestDtoOfEquipmentInterventionCriteriaDto;

    constructor(
        private readonly equipmentInterventionService: EquipmentInterventionService,
        private readonly notificationService: NotificationsService,
        private confirmService: ConfirmModalService,
        private readonly modalService: NgbModal) {

        this.pageRequest = new PageableRequestDtoOfEquipmentInterventionCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new EquipmentInterventionCriteriaDto({
            searchCriteriaDto: new EquipmentInterventionSearchCriteriaDto({
                from: null,
                to: null,
                equipmentName: null,
                personInCharge: null,
                monitoringPerson: null
            }),
            sortCriteriaDto: this.currentSort
        });

        this.filterForm = new UntypedFormGroup({
            date: new UntypedFormGroup({
                from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]),
            equipmentName: new UntypedFormControl(null),
            personInCharge: new UntypedFormControl(null),
            monitoringPerson: new UntypedFormControl(null)
        });
    }

    ngOnInit(): void {
        this.fetchInterventions(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll('destroy');
    }

    fetchInterventions(pageRequest: PageableRequestDtoOfEquipmentCriteriaDto) {
        this.equipmentInterventionService.searchEquipmentInterventions(pageRequest).pipe().subscribe(
            value => this.equipmentInterventions = value
        );
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;

        this.fetchInterventions(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;

        const sortCriteriaDto: ISortCriteriaDto = {
            direction: sortOrder.sorts[0].dir,
            property: sortOrder.sorts[0].prop
        };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = sortCriteriaDto as SortCriteriaDto;

        this.fetchInterventions(this.pageRequest);
    }

    filterInterventions(): void {
        this.crtPage = 0;

        const dateFrom = DateUtils.ngbDateStructToDate(this.dateFrom.value);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(this.dateTo.value);
        dateTo?.setHours(23, 59, 59, 59);

        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new EquipmentInterventionCriteriaDto({
                searchCriteriaDto: new EquipmentInterventionSearchCriteriaDto({
                    from: dateFrom,
                    to: dateTo,
                    equipmentName: this.equipmentName.value,
                    personInCharge: this.personInCharge.value,
                    monitoringPerson: this.monitoringPerson.value
                }),
                sortCriteriaDto: new SortCriteriaDto({
                    direction: this.currentSort.direction,
                    property: this.currentSort.property
                })
            });

        this.fetchInterventions(this.pageRequest);
    }

    clearFilter(): void {
        // Clear inputs
        this.dateFrom.setValue(null);
        this.dateTo.setValue(null);
        this.equipmentName.setValue(null);
        this.personInCharge.setValue(null);
        this.monitoringPerson.setValue(null);
        this.crtPage = 0;

        // clear search
        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new EquipmentInterventionCriteriaDto({
            searchCriteriaDto: new EquipmentInterventionSearchCriteriaDto({
                from: null,
                to: null,
                equipmentName: null,
                personInCharge: null,
                monitoringPerson: null
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            })
        });

        this.fetchInterventions(this.pageRequest);
    }

    deleteIntervention(id: string): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.equipmentInterventionService.deleteEquipmentIntervention(id).pipe().subscribe(_ => {
                    this.notificationService.success({title: 'equipments.interventions.notifications.deleteSuccess'});
                    this.fetchInterventions(this.pageRequest);
                });
            }
        });
    }

    get date(): UntypedFormGroup {
        return this.filterForm.get('date') as UntypedFormGroup;
    }

    get dateFrom(): UntypedFormControl {
        return this.date.get('from') as UntypedFormControl;
    }

    get dateTo(): UntypedFormControl {
        return this.date.get('to') as UntypedFormControl;
    }

    get equipmentName(): UntypedFormControl {
        return this.filterForm.get('equipmentName') as UntypedFormControl;
    }

    get personInCharge(): UntypedFormControl {
        return this.filterForm.get('personInCharge') as UntypedFormControl;
    }

    get monitoringPerson(): UntypedFormControl {
        return this.filterForm.get('monitoringPerson') as UntypedFormControl;
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MapCoordinatesDto, MapDto, MapService} from '../../../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {DecimalPipe} from '@angular/common';
import {FormUtils} from '../../../../_shared/form-utils';
import {firstValueFrom} from 'rxjs';

@Component({
    selector: 'app-manage-map-modal',
    templateUrl: './manage-map-modal.component.html',
    styleUrls: ['./manage-map-modal.component.scss']
})
export class ManageMapModalComponent implements OnInit {

    @Input() serviceId: string;
    @Input() mapDto: MapDto;

    mapForm: UntypedFormGroup;

    mapToUpload: File;
    mapUrl = null;
    mapValid = true;
    maxMapSizeInKb = 2500;
    placeholder = '0000000.0000';
    textInputPattern = FormUtils.textInputPattern;

    constructor(
        public readonly activeModal: NgbActiveModal,
        private readonly notificationsService: NotificationsService,
        private readonly decimalPipe: DecimalPipe,
        private readonly mapService: MapService) {
    }

    ngOnInit(): void {
        const coordinatesValidator = [Validators.required, FormUtils.coordinatesPatternValidator];

        this.mapForm = new UntypedFormGroup({
                latitudeTl: new UntypedFormControl(
                    this.mapDto?.mapCoordinates?.latitudeTl ?? '',
                    coordinatesValidator),
                longitudeTl: new UntypedFormControl(
                    this.mapDto?.mapCoordinates?.longitudeTl ?? '',
                    coordinatesValidator),
                latitudeTr: new UntypedFormControl(
                    this.mapDto?.mapCoordinates?.latitudeTr ?? '',
                    coordinatesValidator),
                longitudeTr: new UntypedFormControl(
                    this.mapDto?.mapCoordinates?.longitudeTr ?? '',
                    coordinatesValidator),
                latitudeBr: new UntypedFormControl(
                    this.mapDto?.mapCoordinates?.latitudeBr ?? '',
                    coordinatesValidator),
                longitudeBr: new UntypedFormControl(
                    this.mapDto?.mapCoordinates?.longitudeBr ?? '',
                    coordinatesValidator),
                latitudeBl: new UntypedFormControl(
                    this.mapDto?.mapCoordinates?.latitudeBl ?? '',
                    coordinatesValidator),
                longitudeBl: new UntypedFormControl(
                    this.mapDto?.mapCoordinates?.longitudeBl ?? '',
                    coordinatesValidator),
            },
            {updateOn: 'change'}
        );
    }

    async save(): Promise<void> {
        if (this.mapForm.valid) {
            if (this.mapDto == null) {
                this.mapDto = new MapDto();
                this.mapDto.mapCoordinates = new MapCoordinatesDto();
            }
            this.mapDto.mapCoordinates.latitudeTl = parseFloat(this.mapForm.value.latitudeTl);
            this.mapDto.mapCoordinates.longitudeTl = parseFloat(this.mapForm.value.longitudeTl);
            this.mapDto.mapCoordinates.latitudeTr = parseFloat(this.mapForm.value.latitudeTr);
            this.mapDto.mapCoordinates.longitudeTr = parseFloat(this.mapForm.value.longitudeTr);
            this.mapDto.mapCoordinates.latitudeBl = parseFloat(this.mapForm.value.latitudeBl);
            this.mapDto.mapCoordinates.longitudeBl = parseFloat(this.mapForm.value.longitudeBl);
            this.mapDto.mapCoordinates.latitudeBr = parseFloat(this.mapForm.value.latitudeBr);
            this.mapDto.mapCoordinates.longitudeBr = parseFloat(this.mapForm.value.longitudeBr);

            try {
                await firstValueFrom(this.mapService.saveMapCoordinates(this.serviceId, this.mapDto.mapCoordinates));
                this.notificationsService.success({ message: 'map.notifications.addCoordinatesSuccess' });
                this.activeModal.close();

            } catch (_) {
                this.notificationsService.error({ message: 'map.notifications.addCoordinatesError' });
            }
        }
    }

    fileSelected(files: FileList): void {
        if (files.length > 0) {
            this.mapToUpload = files[0];

            const imgSize = this.mapToUpload.size;
            if ((imgSize / 1024) > this.maxMapSizeInKb) {
                this.mapValid = false;
                this.mapToUpload = null;
            } else {
                this.mapValid = true;
            }
        }
    }

    async uploadMap(): Promise<void> {
        try {
            const map = await firstValueFrom(this.mapService.uploadMapFile(this.serviceId, {
                data: this.mapToUpload,
                fileName: this.mapToUpload.name
            }));

            this.mapUrl = map.mapUrl;
            this.mapToUpload = null;

            this.notificationsService.success({ message: 'map.notifications.importMapSuccess' });

        } catch (_) {
            this.notificationsService.error({ message: 'map.notifications.importMapError' });
        }
    }

}

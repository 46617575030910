import {Component, Input, OnInit} from '@angular/core';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    CraningServiceAnswerType,
    CraningServiceQuestionDto,
    CraningServiceQuestionInputDto,
    CraningServiceQuestionService
} from '../../../../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../../../_shared/notifications.service';
import {FormUtils} from '../../../../../../_shared/form-utils';
import {ConfirmModalService} from '../../../../../../_shared/_components/confirm-modal/confirm-modal.component';
import {CustomerConfigService} from '../../../../../../_shared/customer-config-service';
import {TranslateUtils} from '../../../../../../_shared/translate-utils';

@Component({
    selector: 'app-craning-service-question-management-modal',
    templateUrl: './craning-service-question-management-modal.component.html'
})
export class CraningServiceQuestionManagementModalComponent implements OnInit {

    @Input() craningServiceId: string;
    @Input() crtLang = TranslateUtils.defaultLanguage;

    craningServiceQuestionForm: UntypedFormGroup;

    craningServiceQuestions: CraningServiceQuestionDto[] = [];
    selectedCraningServiceQuestionToEdit: CraningServiceQuestionDto | null;

    answerTypes = Object.keys(CraningServiceAnswerType);

    icons = {
        edit: faPen,
        delete: faTrash
    };

    textInputPattern = FormUtils.textInputPattern;

    constructor(public activeModal: NgbActiveModal,
                private readonly craningServiceQuestionService: CraningServiceQuestionService,
                private readonly notificationsService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                public readonly customerConfigService: CustomerConfigService) {

        this.craningServiceQuestionForm = new UntypedFormGroup({
            questionFR: new UntypedFormControl(null, [Validators.maxLength(255), Validators.required]),
            questionDE: new UntypedFormControl(null, Validators.maxLength(255)),
            questionEN: new UntypedFormControl(null, Validators.maxLength(255)),
            questionIT: new UntypedFormControl(null, Validators.maxLength(255)),
            answerType: new UntypedFormControl(null, Validators.required),
        }, {updateOn: 'change'});
    }

    ngOnInit(): void {
        this.fetchCraningServiceQuestions();
    }

    addOrUpdateCraningService(): void {
        if (!this.craningServiceQuestionForm.valid) {
            return;
        }
        if (!this.selectedCraningServiceQuestionToEdit.id) {
            this.craningServiceQuestionService.create(this.craningServiceId, this.getFormResult()).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.common.question.notifications.createQuestionSuccess'});
                this.finalizeAddOrUpdateCraningQuestion();
            });
        } else {
            this.craningServiceQuestionService.update(this.selectedCraningServiceQuestionToEdit.id, this.getFormResult())
                .pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.common.question.notifications.updateQuestionSuccess'});
                this.finalizeAddOrUpdateCraningQuestion();
            });
        }
    }

    deleteCraningServiceQuestion(id: string): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.craningServiceQuestionService.delete(id).pipe().subscribe(_ => {
                    this.fetchCraningServiceQuestions();
                    this.notificationsService.success({title: 'services.common.question.notifications.deleteQuestionSuccess'});
                });
            }
        });
    }

    get questionFR(): UntypedFormControl {
        return this.craningServiceQuestionForm.get('questionFR') as UntypedFormControl;
    }

    get questionDE(): UntypedFormControl {
        return this.craningServiceQuestionForm.get('questionDE') as UntypedFormControl;
    }

    get questionEN(): UntypedFormControl {
        return this.craningServiceQuestionForm.get('questionEN') as UntypedFormControl;
    }

    get questionIT(): UntypedFormControl {
        return this.craningServiceQuestionForm.get('questionIT') as UntypedFormControl;
    }

    get answerType(): UntypedFormControl {
        return this.craningServiceQuestionForm.get('answerType') as UntypedFormControl;
    }

    getCraningServiceQuestionLabel(craningServiceQuestion: CraningServiceQuestionDto): string {
        return craningServiceQuestion['question' + this.crtLang.toUpperCase()];
    }

    createCraningServiceQuestion(): void {
        this.selectedCraningServiceQuestionToEdit = new CraningServiceQuestionDto();
        this.answerType.enable({onlySelf: true});
        this.craningServiceQuestionForm.reset();
    }

    updateCraningServiceQuestion(id: string): void {
        this.craningServiceQuestionService.get(id).pipe().subscribe(craningQuestion => {
            this.selectedCraningServiceQuestionToEdit = craningQuestion;
            this.setCraningServiceQuestionForm(craningQuestion);
        });
    }

    cancelCraningServiceQuestion(): void {
        this.selectedCraningServiceQuestionToEdit = null;
    }

    private getFormResult(): CraningServiceQuestionInputDto {
        return new CraningServiceQuestionInputDto({
            questionFR: this.questionFR.value,
            questionDE: this.questionDE.value,
            questionEN: this.questionEN.value,
            questionIT: this.questionIT.value,
            answerType: this.answerType.value
        });
    }

    private setCraningServiceQuestionForm(craningQuestion: CraningServiceQuestionDto): void {
        this.questionFR.setValue(craningQuestion.questionFR);
        this.questionDE.setValue(craningQuestion.questionDE);
        this.questionEN.setValue(craningQuestion.questionEN);
        this.questionIT.setValue(craningQuestion.questionIT);
        this.answerType.setValue(craningQuestion.answerType);
        if (!craningQuestion.id) {
            this.answerType.enable({onlySelf: true});
        } else {
            this.answerType.disable({onlySelf: true});
        }
    }

    private finalizeAddOrUpdateCraningQuestion(): void {
        this.selectedCraningServiceQuestionToEdit = null;
        this.fetchCraningServiceQuestions();
        this.craningServiceQuestionForm.reset();
    }

    private fetchCraningServiceQuestions(): void {
        this.craningServiceQuestionService.getAll(this.craningServiceId).pipe().subscribe(craningQuestions => {
            this.craningServiceQuestions = craningQuestions;
            this.craningServiceQuestionForm.reset();
        });
    }

}

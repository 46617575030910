<div class="modal-header">
    <h4 class="modal-title" *ngIf="this.lightZone === null"
        translate="services.publicLighting.lightZone.modal.addTitle">
    </h4>
    <h4 class="modal-title" *ngIf="this.lightZone !== null"
        translate="services.publicLighting.lightZone.modal.editTitle">
    </h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form name="lightZoneForm" [formGroup]="lightZoneForm">

        <div class="row">

            <!-- code -->
            <div class="col-12">
                <label class="form-label" for="code">{{ 'services.publicLighting.lightZone.modal.code' | translate }}*</label>
                <input class="form-control" id="code" type="text" name="code" maxlength="255"
                       [formControl]="code"
                       pattern="{{ FormUtils.textInputPattern }}"
                       placeholder="{{ 'services.publicLighting.lightZone.modal.code' | translate }}"
                       required>
                <app-form-error [control]="code"></app-form-error>
            </div>

            <!-- Description -->
            <div class="col-12 col-md-6">
                <label class="form-label">{{ 'services.publicLighting.lightZone.modal.descriptionFR' | translate }}*</label>
                <textarea class="form-control" id="descriptionFR"
                          placeholder="{{ 'services.publicLighting.lightZone.modal.descriptionFR' | translate }}"
                          maxLength="255" rows="3"
                          [formControl]="descriptionFR"
                          pattern="{{ FormUtils.textInputPattern }}"
                          required></textarea>
                <app-form-error [control]="descriptionFR"></app-form-error>
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <label class="form-label">{{ 'services.publicLighting.lightZone.modal.descriptionDE' | translate }}</label>
                <textarea class="form-control" id="descriptionDE"
                          placeholder="{{ 'services.publicLighting.lightZone.modal.descriptionDE' | translate }}"
                          maxLength="255" rows="3"
                          [formControl]="descriptionDE"
                          pattern="{{ FormUtils.textInputPattern }}"></textarea>
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <label class="form-label">{{ 'services.publicLighting.lightZone.modal.descriptionIT' | translate }}</label>
                <textarea class="form-control" id="descriptionIT"
                          placeholder="{{ 'services.publicLighting.lightZone.modal.descriptionIT' | translate }}"
                          maxLength="255" rows="3"
                          [formControl]="descriptionIT"
                          pattern="{{ FormUtils.textInputPattern }}"></textarea>
            </div>

            <div class="col-12 col-md-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <label class="form-label">{{ 'services.publicLighting.lightZone.modal.descriptionEN' | translate }}</label>
                <textarea class="form-control" id="descriptionEN"
                          placeholder="{{ 'services.publicLighting.lightZone.modal.descriptionEN' | translate }}"
                          maxLength="255" rows="3"
                          [formControl]="descriptionEN"
                          pattern="{{ FormUtils.textInputPattern }}"></textarea>
            </div>

        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>
    <button class="btn btn-primary" type="submit"
            [disabled]="lightZoneForm.invalid"
            (click)="saveLightZone()"
            translate="common.save">
    </button>
</div>

import {Injectable} from '@angular/core';
import {AppRoles} from '../app.constants';
import {MsalService} from '@azure/msal-angular';

@Injectable({
    providedIn: 'root'
})
export class RolesService {

    appRoles = AppRoles;

    constructor(private readonly authService: MsalService) {
    }

    hasRole(role: string): boolean {
        if (this.authService.instance.getAllAccounts().length === 0) {
            return false;
        }

        const userRoles = this.getUserRoles();
        return userRoles.indexOf(role) > -1;
    }

    hasAnyRole(allowedRoles: string[]): boolean {
        const userRoles = this.getUserRoles();
        const matchingRoles = userRoles.filter(x => allowedRoles.includes(x));
        return matchingRoles.length > 0;
    }

    hasRoleUser(): boolean {
        return this.hasRole(this.appRoles.user);
    }

    hasRoleAdmin(): boolean {
        return this.hasRole(this.appRoles.admin);
    }

    hasRoleAlarmManager(): boolean {
        return this.hasRole(this.appRoles.alarmManager);
    }

    hasRoleConfigurator(): boolean {
        return this.hasRole(this.appRoles.configurator);
    }

    hasRoleHarbourMaster(): boolean {
        return this.hasRole(this.appRoles.harbourmaster);
    }

    hasRoleCashier(): boolean {
        return this.hasRole(this.appRoles.cashier);
    }

    isVisitor(): boolean {
        return !this.getUserId();
    }

    getUserId(): string | null {
        if (this.authService.instance.getAllAccounts().length > 0) {
            const token = this.authService.instance.getAllAccounts()[0].idTokenClaims as any;
            return token.oid ?? null;
        }
        return null;
    }

    private getUserRoles(): string[] {
        if (this.authService.instance.getAllAccounts().length > 0) {
            const token = this.authService.instance.getActiveAccount()?.idTokenClaims as any;
            if (token?.extension_Roles) {
                return token.extension_Roles.split(',');
            }
        }
        return [];
    }
}

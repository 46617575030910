import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PublicLightingControlMode} from '../../../../../_services/configuration-services';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-light-zone-mode-selector',
    templateUrl: './light-zone-mode-selector.component.html'
})
export class LightZoneModeSelectorComponent implements OnInit {
    @Input() unavailableModes: PublicLightingControlMode[] = [];
    @Input() selected: PublicLightingControlMode | null = null;
    @Output() selectedMode = new EventEmitter<PublicLightingControlMode>();


    modeSelectorForm: FormGroup<ModeSelectorForm>;
    availableModes: PublicLightingControlMode[] = [];

    constructor() {
        this.modeSelectorForm = new FormGroup<ModeSelectorForm>({
            mode: new FormControl<PublicLightingControlMode | null>(null, Validators.required)
        }, {updateOn: 'change'});
    }

    ngOnInit(): void {
        const modes = Object.values(PublicLightingControlMode);

        for (const mode of modes) {
            if (!this.unavailableModes.includes(mode)) {
                this.availableModes.push(mode);
            }
        }

        if (this.selected) {
            this.mode.setValue(this.selected);
        }
    }

    reset(): void {
        this.modeSelectorForm.reset();
    }

    updateMode(value: PublicLightingControlMode): void {
        this.mode.setValue(value);
        this.selectedMode.emit(value);
    }

    get mode(): FormControl<PublicLightingControlMode | null> {
        return this.modeSelectorForm.controls.mode;
    }
}

interface ModeSelectorForm {
    mode: FormControl<PublicLightingControlMode | null>
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    ItemToBookDto,
    ItemToBookService,
    MeasuringPointLightDto
} from '../../../_services/configuration-services';
import {ItemModalComponent} from './item-modal/item-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../_shared/notifications.service';
import {TranslateService} from '@ngx-translate/core';
import {faPen, faQuestion, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {Subscription} from "rxjs";
import {
    ItemQuestionManagementModalComponent
} from "./item-question-management-modal/item-question-management-modal.component";

@Component({
    selector: 'app-item-management',
    templateUrl: './item-management.component.html'
})
export class ItemManagementComponent implements OnInit, OnDestroy {

    @Input() serviceId: string;
    items: ItemToBookDto[] = [];
    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription$: Subscription;

    icons = {
        edit: faPen,
        delete: faTrash,
        questions: faQuestion
    };

    constructor(private readonly modalService: NgbModal,
                private readonly itemToBookService: ItemToBookService,
                private readonly translateService: TranslateService,
                private readonly confirmService: ConfirmModalService,
                private readonly notificationsService: NotificationsService
    ) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription$ = this.translateService.onLangChange
            .subscribe(_ => {
                this.crtLang = this.translateService.currentLang;
            });
        this.fetchItems();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    createItem(): void {
        const modal = this.modalService.open(ItemModalComponent, {centered: true});
        modal.componentInstance.items = this.items;
        modal.componentInstance.serviceId = this.serviceId;
        modal.result.then(() => {
            this.fetchItems();
        }, () => { /* catch the rejection */ });
    }

    updateItem(item: ItemToBookDto): void {
        const modal = this.modalService.open(ItemModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.items = this.items;
        modal.componentInstance.item = item;
        modal.result.then(() => {
            this.fetchItems();
        }, () => { /* catch the rejection */ });
    }

    editItemQuestions(item: ItemToBookDto) {
        const modal = this.modalService.open(ItemQuestionManagementModalComponent, {
            centered: true,
            size: 'lg'
        });
        modal.componentInstance.itemToBookId = item.id;
        modal.componentInstance.crtLang = this.crtLang;
        modal.result
            .then(_ => {
                this.fetchItems();
            }, () => { /* catch the rejection */ });
    }

    deleteItem(item: ItemToBookDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.itemToBookService.deleteItem(item.id).pipe().subscribe(() => {
                    this.notificationsService.success({title: 'services.items.notifications.deleteSuccess'});
                    this.fetchItems();
                });
            }
        });
    }

    getMeasuringPointName(measuringPoint: MeasuringPointLightDto | null): string {
        if (!measuringPoint) {
            return '';
        }
        return `${measuringPoint['name' + this.crtLang.toUpperCase()]}`;
    }

    private fetchItems(): void {
        this.itemToBookService.getAllItemsForService(this.serviceId).pipe().subscribe(items => {
            this.items = items;
        });
    }
}

<div class="modal-header">
    <h4 class="modal-title" translate>{{ options.titleKey }}</h4>
    <button class="btn-close" aria-label="Close" (click)="close()">

    </button>
</div>

<div class="modal-body">
    <p class="translated-paragraph" *ngIf="!!options.messageKey" translate>{{ options.messageKey }}</p>

    <p class="translated-paragraph" translate>{{ defaultMessageKey }}</p>

    <p class="translated-paragraph" *ngIf="!!options.infoKey" translate>{{ options.infoKey }}</p>

    <ul class="info-list-scroll" *ngIf="!!options.infoList && options.infoList.length > 0">
        <li *ngFor="let info of options.infoList" class="mb-2">{{ info }}</li>
    </ul>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="no()" translate>
        {{ options.noKey }}
    </button>
    <button type="button" class="btn btn-primary" (click)="yes()" translate>
        {{ options.yesKey }}
    </button>
</div>

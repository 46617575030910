import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {faArrowCircleDown, faArrowCircleUp, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {
    EwalletDto,
    ISortCriteriaDto,
    PageableRequestDtoOfUserTransactionCriteriaDto,
    PagedResultDtoOfTransactionDto,
    ServiceService,
    SortCriteriaDto,
    SortDirection,
    TransactionSource,
    UserService,
    UserTransactionCriteriaDto,
    UserTransactionSearchCriteriaDto,
} from '../../_services/configuration-services';
import {AppRoles} from '../../app.constants';
import {InternalDatatransService} from '../../_services/internal-datatrans.service';
import {NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from '../../_shared/custom-date-formatter';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {CustomerConfigService} from '../../_shared/customer-config-service';
import {FormUtils} from '../../_shared/form-utils';
import {DateUtils} from '../../_shared/date-utils';
import {TranslateUtils} from '../../_shared/translate-utils';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {ReloadWalletModalComponent} from "./reload-wallet-modal/reload-wallet-modal.component";

@Component({
    selector: 'app-ewallet-view',
    templateUrl: './ewallet-view.component.html',
    styleUrls: ['./ewallet-view.component.scss'],
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class EwalletViewComponent implements OnInit, OnDestroy {

    FormUtils = FormUtils;
    AppRoles = AppRoles;

    // Status retrieve by Datatrans to display the transaction result
    datatransTransactionStatus = '';

    readonly pageSize = 10;
    crtPage = 0;

    userId: string;
    userName: string;

    ewallet: EwalletDto;
    transactions: PagedResultDtoOfTransactionDto;

    filterForm: UntypedFormGroup;
    dropdownSettings: IDropdownSettings = {};
    transactionSourcesDropdown = [];
    servicesDropdown = [];

    dateTimeFormat = DateUtils.dateTimeFormat;

    // Icons
    icons = {
        arrowRight : faArrowRight,
        arrowCircleUp : faArrowCircleUp,
        arrowCircleDown : faArrowCircleDown,
    }

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription;

    private pageRequest: PageableRequestDtoOfUserTransactionCriteriaDto;

    constructor(private readonly route: ActivatedRoute,
                private readonly userService: UserService,
                private readonly internalDatatransService: InternalDatatransService,
                private readonly modalService: NgbModal,
                private readonly translateService: TranslateService,
                private readonly location: Location,
                private readonly serviceService: ServiceService,
                public readonly customerConfigService: CustomerConfigService,
                public formatter: NgbDateParserFormatter) {

        this.pageRequest = new PageableRequestDtoOfUserTransactionCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new UserTransactionCriteriaDto({
            userSearchCriteriaDto: new UserTransactionSearchCriteriaDto({
                from: null,
                to: null,
                amountFrom: null,
                amountTo: null,
                ewalletBalanceFrom: null,
                ewalletBalanceTo: null,
                serviceId: null,
                transactionSource: null
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: SortDirection.Desc,
                property: 'dateTime'
            })
        });

        this.filterForm = new UntypedFormGroup({
            validityPeriod: new UntypedFormGroup({
                from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]),
            transactionSource: new UntypedFormControl(null),
            service: new UntypedFormControl(null),
            amountRange: new UntypedFormGroup({
                min: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
                max: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
            }, [FormUtils.rangeValidator]),
            eWalletBalance: new UntypedFormGroup({
                min: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
                max: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
            }, [FormUtils.rangeValidator])
        });

    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;

        this.dropdownSettings = {
            singleSelection: true,
            idField: 'itemId',
            textField: 'itemVal',
            searchPlaceholderText: this.translateService.instant('common.multiselect.search'),
            noDataAvailablePlaceholderText: this.translateService.instant('common.multiselect.noResults'),
            itemsShowLimit: 1,
            allowSearchFilter: true
        };

        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
            this.dropdownSettings.searchPlaceholderText = this.translateService.instant('common.multiselect.search');
            this.populateEnumDropdowns();
            this.clearFilter()
        });

        this.userId = this.route.snapshot.params['userId'];
        this.datatransTransactionStatus = this.route.snapshot.queryParamMap.get('datatransStatus');

        this.fetchUserName(this.userId);
        this.fetchEwallet(this.userId);
        this.fetchTransactions(this.userId, this.pageRequest);
        this.populateEnumDropdowns();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    fetchUserName(userId: string): void {
        if (userId) {
            this.userService.getUserName(userId).subscribe(user => {
                this.userName = `${user.firstName} ${user.lastName}`;
            });
        } else {
            this.userService.getMe().subscribe(user => {
                this.userName = `${user.firstName} ${user.lastName}`;
            });
        }
    }

    fetchEwallet(userId: string): void {
        if (userId) {
            this.userService.getEwalletByUserId(userId).pipe().subscribe(value => this.ewallet = value);
        } else {
            this.userService.getMyEwallet().pipe().subscribe(value => this.ewallet = value);
        }
    }

    fetchTransactions(userId: string, pageableRequest: PageableRequestDtoOfUserTransactionCriteriaDto): void {
        if (userId) {
            this.userService.searchTransactionsByUserId(userId, pageableRequest).pipe().subscribe(value => this.transactions = value);
        } else {
            this.userService.searchMyTransactions(pageableRequest).pipe().subscribe(value => this.transactions = value);
        }
    }

    filterTransaction(): void {
        this.crtPage = 0;

        const dateFrom = DateUtils.ngbDateStructToDate(this.dateFrom.value);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(this.dateTo.value);
        dateTo?.setHours(23, 59, 59, 59);

        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new UserTransactionCriteriaDto({
            userSearchCriteriaDto: new UserTransactionSearchCriteriaDto({
                from: dateFrom,
                to: dateTo,
                amountFrom: !!this.amountFrom.value || this.amountFrom.value === 0 ? Number(this.amountFrom.value) : null,
                amountTo: !!this.amountTo.value || this.amountTo.value === 0 ? Number(this.amountTo.value) : null,
                ewalletBalanceFrom: !!this.eWalletBalanceFrom.value || this.eWalletBalanceFrom.value === 0 ? Number(this.eWalletBalanceFrom.value) : null,
                ewalletBalanceTo: !!this.eWalletBalanceTo.value || this.eWalletBalanceTo.value === 0 ? Number(this.eWalletBalanceTo.value) : null,
                serviceId: this.extractDropdownFormValue(this.service),
                transactionSource: this.extractDropdownFormValue(this.transactionSource)
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: SortDirection.Desc,
                property: 'dateTime'
            })
        });

        this.fetchTransactions(this.userId, this.pageRequest);
    }

    clearFilter(): void {
        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new UserTransactionCriteriaDto({
            userSearchCriteriaDto: new UserTransactionSearchCriteriaDto({
                from: null,
                to: null,
                amountFrom: null,
                amountTo: null,
                ewalletBalanceFrom: null,
                ewalletBalanceTo: null,
                serviceId: null,
                transactionSource: null
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: SortDirection.Desc,
                property: 'dateTime'
            })
        });

        this.filterForm.reset();
        this.fetchTransactions(this.userId, this.pageRequest);
    }

    reloadWallet(): void {
        const modal = this.modalService.open(ReloadWalletModalComponent, {centered: true});
        modal.result.then(_ => {}, _ => {
            this.fetchTransactions(this.userId, this.pageRequest);
        });
    }

    // Start Ngx DataTable
    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;
        this.fetchTransactions(this.userId, this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.goToFirstPage();
        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.fetchTransactions(this.userId, this.pageRequest);
    }

    back(): void {
        this.location.back();
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }
    // End Ngx DataTable

    private populateEnumDropdowns(): void {
        this.transactionSourcesDropdown = [];
        const transactions = Object.keys(TransactionSource).filter(s => s !== TransactionSource.Unknown);
        transactions.forEach(value => {
            this.transactionSourcesDropdown.push({
                itemId: value,
                itemVal: this.translateService.instant('transactions.enums.sources.' + value.toLowerCase())
            });
        });
        this.serviceService.getServiceNames().pipe().subscribe(res => {
            this.servicesDropdown = [];
            const serviceNames = res.sort((a, b) => a['name' + this.crtLang.toUpperCase()].localeCompare(b['name' + this.crtLang.toUpperCase()]));
            serviceNames.forEach(r => this.servicesDropdown.push({
                itemId: r.serviceId,
                itemVal: r['name' + this.crtLang.toUpperCase()]
            }));
        });
    }

    private extractDropdownFormValue(control:UntypedFormControl): any | null{
        return (!!control.value && !!control.value[0] ) ? control.value[0].itemId : null;
    }

    get validityPeriod(): UntypedFormGroup {
        return this.filterForm.get('validityPeriod') as UntypedFormGroup;
    }

    get dateFrom(): UntypedFormControl {
        return this.validityPeriod.get('from') as UntypedFormControl;
    }

    get dateTo(): UntypedFormControl {
        return this.validityPeriod.get('to') as UntypedFormControl;
    }

    get transactionSource(): UntypedFormControl {
        return this.filterForm.get('transactionSource') as UntypedFormControl;
    }

    get service(): UntypedFormControl {
        return this.filterForm.get('service') as UntypedFormControl;
    }

    get amountRange(): UntypedFormGroup {
        return this.filterForm.get('amountRange') as UntypedFormGroup;
    }

    get amountFrom(): UntypedFormControl {
        return this.amountRange.get('min') as UntypedFormControl;
    }

    get amountTo(): UntypedFormControl {
        return this.amountRange.get('max') as UntypedFormControl;
    }

    get eWalletBalanceRange(): UntypedFormGroup {
        return this.filterForm.get('eWalletBalance') as UntypedFormGroup;
    }

    get eWalletBalanceFrom(): UntypedFormControl {
        return this.eWalletBalanceRange.get('min') as UntypedFormControl;
    }

    get eWalletBalanceTo(): UntypedFormControl {
        return this.eWalletBalanceRange.get('max') as UntypedFormControl;
    }
}

import {Injectable} from '@angular/core';
import {
    BookingDto, BookingLightDto, BookingServiceConfigDto
} from '../_services/configuration-services';
import {DateUtils} from './date-utils';

@Injectable({
    providedIn: 'root',
})
export class BookingUtils {

    public static areBookingStartingTogether(booking: BookingDto | BookingLightDto, booking2: BookingDto | BookingLightDto): boolean {
        return booking.startDate.getTime() === booking2.startDate.getTime()
            && booking.localStartTime === booking2.localStartTime;
    }

    public static canEditOrCancelBooking(booking: BookingDto, bookingConfig : BookingServiceConfigDto, hasAdminRole: boolean): boolean {
        if (booking.recurring) {
            return true;
        }

        let limitDate = new Date();

        if (!hasAdminRole) {
            const delayInHours = DateUtils.convertValueAndUnitToHours(
                bookingConfig.deadlineForUpdatingBeforeBookingPeriod ?? 0, bookingConfig.deadlineForUpdatingBeforeBookingPeriodTimeUnit);

            limitDate.setTime(limitDate.getTime() + (delayInHours * 60 * 60 * 1000));
        }

        const bookingStartDate = DateUtils.dateToNgbDateStruct(booking.startDate);
        const bookingStartTime = DateUtils.stringToNgbTimeStruct(booking.localStartTime);
        const bookingDate = DateUtils.ngbDateStructToDateTime(bookingStartDate, bookingStartTime);

        return bookingDate.getTime() > limitDate.getTime();
    }

}

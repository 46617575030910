<div class="btn-bar">
    <button class="btn btn-primary" *ngIf="!createMode && !dragMode"
            (click)="startCreateMode()"
            translate="map.add">
    </button>
    <button class="btn btn-primary" *ngIf="!createMode && !dragMode"
            (click)="startDragMode()" translate="map.drag">
    </button>

    <button class="btn btn-outline-secondary" *ngIf="createMode || dragMode"
            (click)="resetMapAndStopEditMode()"
            translate="common.cancel">
    </button>
    <button class="btn btn-primary" *ngIf="dragMode"
            (click)="emitSelectedItem()"
            [disabled]="!selectedItem"
            translate="common.save">
    </button>
</div>

<div class="col-12 text-center" *ngIf="dragMode">
    <p translate="services.manage.coordinates.notifications.selectPlace"></p>
</div>
<div class="col-12 text-center" *ngIf="createMode">
    <p translate="services.manage.coordinates.notifications.createPlace"></p>
</div>

<div class="map-container overflow-hidden">
    <div class="btn-container">
        <div class="btn-group" role="group">
            <button id="zoom-in" type="button" class="btn btn-primary">+</button>
            <button id="zoom-out" type="button" class="btn btn-primary">-</button>
        </div>
    </div>
    <svg class="map" id="d3_map" width="1240" height="500"></svg>
</div>

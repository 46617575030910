import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-cockpit-battery',
    templateUrl: './cockpit-battery.component.html',
    styleUrls: ['./cockpit-battery.component.scss']
})
export class CockpitBatteryComponent implements OnInit, OnChanges {

    @Input() currentValue: number;
    @Input() valueMax;
    @Input() chargeLimit;

    ngOnChanges(changes: SimpleChanges): void {
        let crtValue = this.currentValue;
        let valMax = this.valueMax;
        let limit = this.chargeLimit;
        if (changes['currentValue'] != null) {
            crtValue = changes['currentValue'].currentValue;
        }
        if (changes['valueMax'] != null) {
            valMax = changes['valueMax'].currentValue;
        }
        if (changes['chargeLimit'] != null) {
            limit = changes['chargeLimit'].currentValue;
        }

        const percentageValue = ((+crtValue * 100) / +valMax);
        if (!isNaN(percentageValue)) {
            this.currentValue = percentageValue;
        }

        const percentageLimit = ((+limit * 100) / +valMax);
        if (!isNaN(percentageLimit)) {
            this.currentValue = percentageValue;
        }
    }

    constructor() {
    }

    ngOnInit(): void {
        this.chargeLimit = (this.chargeLimit * 100) / this.valueMax;
    }

    getClass(): string {
        if (this.currentValue <= 50) {
            return 'battery-normal'; // green

        } else if (this.currentValue > 50 && this.currentValue <= this.chargeLimit) {
            return 'battery-warning'; // yellow

        } else if (this.currentValue > this.chargeLimit) {
            return 'battery-alert'; // red
        }

        return '';
    }

}

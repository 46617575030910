<div class="input-group">
    <span class="input-group-text">
        <button class="btn btn-link btn-sm"
                (click)="prev()">
            <fa-icon [icon]="icons.previous"></fa-icon>
        </button>
    </span>
    <input type="text" class="form-control" name="daterange"
           pattern="{{textInputPattern}}"
           ngxDaterangepickerMd
           [alwaysShowCalendars]="true"
           [locale]="{applyLabel: 'ok', format: 'DD.MM.YYYY', firstDay: 1}"
           [(ngModel)]="selected"
           (change)="change($event)"
           [maxDate]="maxDate"
           [minDate]="minDate"
           [ranges]="ranges"/>
    <span class="input-group-text">
        <button class="btn btn-link btn-sm"
                (click)="next()">
            <fa-icon [icon]="icons.next"></fa-icon>
        </button>
    </span>
</div>


<h4 translate="services.tariff.title"></h4>

<div class="btn-bar">
    <button class="btn btn-primary"
            (click)="createTariff()"
            translate="services.tariff.actions.add">
    </button>
</div>

<div class="table-responsive">
    <table class="table table-striped"
           [attr.aria-label]="'services.tariff.title' | translate">
        <thead>
        <tr>
            <th scope="col" style="min-width:325px" translate="services.tariff.table.tariff"></th>
            <th scope="col" style="min-width:225px" translate="common.terms.timeOfPayment"></th>
            <th scope="col" style="min-width:225px" translate="services.tariff.table.priceByUnit"></th>
            <th scope="col" style="min-width:100px"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="tariffs.length === 0">
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td></td>
        </tr>

        <tr *ngFor="let tariff of tariffs;">
            <td>
                <p class="mb-0">[{{ tariff.defaultTariff ? ('services.tariff.default' | translate) : getTranslatedRoles(tariff.customRoles)}}]</p>
                <p class="mb-0">{{ getValidity(tariff.validityFrom, tariff.validityTo) }}</p>
            </td>

            <td>
                <p class="mb-0">{{ 'common.timeOfPayment.' + tariff.timeOfPayment | translate }}</p>
                <p class="mb-0" *ngIf="tariff.billingRecurrence">({{ 'common.billingRecurrence.' + tariff.billingRecurrence | translate }})</p>
            </td>

            <td>
                <p class="mb-0" *ngFor="let price of tariff.tariffPrices">

                    @if (price.tariffConfigUnit) {
                        {{ price.priceByUnit | currencyFormat }} / {{ 'common.units.' + price.tariffConfigUnit.unitEnumName + '.' + price.tariffConfigUnit.unitEnumValue | translate }}

                        <span *ngIf="price.nightRate">({{ 'services.tariff.modal.nightRate' | translate }})</span>
                    } @else {
                        {{ price.priceByUnit | currencyFormat }} / {{ tariff['customUnit' + crtLang.toUpperCase()] ? tariff['customUnit' + crtLang.toUpperCase()] : tariff.customUnitFR}}

                        <span *ngIf="price.nightRate">({{ 'services.tariff.modal.nightRate' | translate }})</span>
                    }

                </p>
            </td>

            <td class="text-end">
                <button class="btn btn-link btn-sm"
                        (click)="updateTariff(tariff)"
                        [disabled]="serviceId === null"
                        title="{{'common.tooltip.edit' | translate}}">
                    <fa-icon [icon]="faPen"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm"
                        (click)="deleteTariff(tariff)"
                        [disabled]="!canDeleteTariff(tariff)"
                        title="{{'common.tooltip.delete' | translate}}">
                    <fa-icon [icon]="faTrash"></fa-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>

import {Component} from '@angular/core';
import {AppRoles} from '../app.constants';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html'
})
export class AlertComponent {

    AppRoles = AppRoles;

    constructor() {}

}

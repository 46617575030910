
<div class="content-container bg-white">

    <h1>{{ (!this.surveyId ? 'communicationChannel.survey.edition.addTitle' : 'communicationChannel.survey.edition.modifyTitle') | translate }}</h1>

    <div class="btn-bar">
        <button type="button" class="btn btn-outline-secondary"
                (click)="back()"
                translate="common.back">
        </button>

        <button type="submit" class="btn btn-outline-danger" *ngIf="!!this.surveyId"
                (click)="deleteSurvey()"
                translate="common.delete">
        </button>
    </div>

    <form name="surveyForm" [formGroup]="surveyForm">

        <div class="row">
            <!-- Titles -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="titleFR">{{'communicationChannel.survey.edition.form.titleFR' | translate}}*</label>
                <input class="form-control" id="titleFR" type="text" maxlength="50"
                       [formControl]="titleFR"
                       placeholder="{{ 'communicationChannel.survey.edition.placeholder.titleFR' | translate }}">
                <app-form-error [control]="titleFR"></app-form-error>
            </div>

            <div class="col-12 col-lg-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <label class="form-label" for="titleDE">{{'communicationChannel.survey.edition.form.titleDE' | translate}}</label>
                <input class="form-control" id="titleDE" type="text" maxlength="50"
                       [formControl]="titleDE"
                       placeholder="{{ 'communicationChannel.survey.edition.placeholder.titleDE' | translate }}">
                <app-form-error [control]="titleDE"></app-form-error>
            </div>

            <div class="col-12 col-lg-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <label class="form-label" for="titleIT">{{'communicationChannel.survey.edition.form.titleIT' | translate}}</label>
                <input class="form-control" id="titleIT" type="text" maxlength="50"
                       [formControl]="titleIT"
                       placeholder="{{ 'communicationChannel.survey.edition.placeholder.titleIT' | translate }}">
                <app-form-error [control]="titleIT"></app-form-error>
            </div>

            <div class="col-12 col-lg-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <label class="form-label" for="titleEN">{{'communicationChannel.survey.edition.form.titleEN' | translate}}</label>
                <input class="form-control" id="titleEN" type="text" maxlength="50"
                       [formControl]="titleEN"
                       placeholder="{{ 'communicationChannel.survey.edition.placeholder.titleEN' | translate }}">
                <app-form-error [control]="titleEN"></app-form-error>
            </div>
        </div>

        <!-- Descriptions -->
        <div class="col-12">
            <label class="form-label" for="descriptionFR">{{'communicationChannel.survey.edition.form.descriptionFR' | translate}}*</label>
            <textarea class="form-control" id="descriptionFR" type="textarea" name="descriptionFR"
                      maxlength="2500"
                      rows="5"
                      [formControl]="descriptionFR"
                      placeholder="{{ 'communicationChannel.survey.edition.placeholder.descriptionFR' | translate }}"></textarea>
            <app-form-error [control]="descriptionFR"></app-form-error>
        </div>

        <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('de')">
            <label class="form-label" for="descriptionDE">{{'communicationChannel.survey.edition.form.descriptionDE' | translate}}</label>
            <textarea class="form-control" id="descriptionDE" type="textarea" name="descriptionDE"
                      maxlength="2500"
                      rows="5"
                      [formControl]="descriptionDE"
                      placeholder="{{ 'communicationChannel.survey.edition.placeholder.descriptionDE' | translate }}"></textarea>
            <app-form-error [control]="descriptionDE"></app-form-error>
        </div>

        <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('it')">
            <label class="form-label" for="descriptionIT">{{'communicationChannel.survey.edition.form.descriptionIT' | translate}}</label>
            <textarea class="form-control" id="descriptionIT" type="textarea" name="descriptionIT"
                      maxlength="2500"
                      rows="5"
                      [formControl]="descriptionIT"
                      placeholder="{{ 'communicationChannel.survey.edition.placeholder.descriptionIT' | translate }}"></textarea>
            <app-form-error [control]="descriptionIT"></app-form-error>
        </div>

        <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('en')">
            <label class="form-label" for="descriptionEN">{{'communicationChannel.survey.edition.form.descriptionEN' | translate}}</label>
            <textarea class="form-control" id="descriptionEN" type="textarea" name="descriptionEN"
                      maxlength="2500"
                      rows="5"
                      [formControl]="descriptionEN"
                      placeholder="{{ 'communicationChannel.survey.edition.placeholder.descriptionEN' | translate }}"></textarea>
            <app-form-error [control]="descriptionEN"></app-form-error>
        </div>

        <!-- URL -->
        <div class="col-12">
            <label class="form-label" for="surveyUrl">{{'communicationChannel.survey.edition.form.surveyUrl' | translate}}*</label>
            <input class="form-control" id="surveyUrl" type="text" maxlength="1000"
                   [formControl]="surveyUrl"
                   placeholder="{{ 'communicationChannel.survey.edition.placeholder.surveyUrl' | translate }}">
            <app-form-error [control]="surveyUrl"></app-form-error>
        </div>

        <hr>

        <!-- Results -->
        <div class="row">
            <div class="col-12">
                <label class="form-label" for="resultsContentFR">{{'communicationChannel.survey.edition.form.resultsContentFR' | translate}}</label>
                <textarea class="form-control" id="resultsContentFR" type="textarea" name="resultsContentFR"
                          maxlength="2500"
                          rows="5"
                          [formControl]="resultsContentFR"
                          placeholder="{{ 'communicationChannel.survey.edition.placeholder.resultsContentFR' | translate }}"></textarea>
                <app-form-error [control]="resultsContentFR"></app-form-error>
            </div>

            <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <label class="form-label" for="resultsContentDE">{{'communicationChannel.survey.edition.form.resultsContentDE' | translate}}</label>
                <textarea class="form-control" id="resultsContentDE" type="textarea" name="resultsContentDE"
                          maxlength="2500"
                          rows="5"
                          [formControl]="resultsContentDE"
                          placeholder="{{ 'communicationChannel.survey.edition.placeholder.resultsContentDE' | translate }}"></textarea>
                <app-form-error [control]="resultsContentDE"></app-form-error>
            </div>

            <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <label class="form-label" for="resultsContentIT">{{'communicationChannel.survey.edition.form.resultsContentIT' | translate}}</label>
                <textarea class="form-control" id="resultsContentIT" type="textarea" name="resultsContentIT"
                          maxlength="2500"
                          rows="5"
                          [formControl]="resultsContentIT"
                          placeholder="{{ 'communicationChannel.survey.edition.placeholder.resultsContentIT' | translate }}"></textarea>
                <app-form-error [control]="resultsContentIT"></app-form-error>
            </div>

            <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <label class="form-label" for="resultsContentEN">{{'communicationChannel.survey.edition.form.resultsContentEN' | translate}}</label>
                <textarea class="form-control" id="resultsContentEN" type="textarea" name="resultsContentEN"
                          maxlength="2500"
                          rows="5"
                          [formControl]="resultsContentEN"
                          placeholder="{{ 'communicationChannel.survey.edition.placeholder.resultsContentEN' | translate }}"></textarea>
                <app-form-error [control]="resultsContentEN"></app-form-error>
            </div>
        </div>

        <hr>

        <!-- Roles -->
        <div class="mt-4">

            <h4 translate="communicationChannel.survey.edition.form.roles"></h4>
            <app-role-management [enablePublicAccess]="false"
                                 [enableBaseRoles]="true"
                                 [enableCustomRoles]="true"
                                 [userOnly]="true"
                                 [hideCustomIfUserSelected]="true"
                                 [roleForm]="rolesFormArray"
                                 [requiredFields]="false"></app-role-management>

            <div class="btn-bar">
                <button type="button" class="btn btn-outline-secondary"
                        (click)="back()"
                        translate="common.back">
                </button>

                <button type="submit" class="btn btn-primary"
                        [disabled]="surveyForm.invalid || surveyForm.pristine"
                        (click)="saveSurvey()"
                        translate="common.save">
                </button>
            </div>

        </div>

        <!-- Illustration, Documents & Publication -->
        <ng-container *ngIf="!!surveyId">
            <hr>
            <h4 translate="communicationChannel.survey.edition.form.illustration"></h4>

            <!-- Illustration -->
            <form name="illustrationForm" [formGroup]="illustrationForm" *ngIf="!!surveyId">

                <div class="row">
                    <div class="col-12 col-lg-6">
                        <label class="form-label" for="file">{{ 'communicationChannel.survey.edition.form.illustration' | translate }}</label>
                        <p translate="common.imageMaxSize" [translateParams]="{ImageMaxSize: imageMaxSizeInKB}"></p>

                        <input class="form-control" id="file" type="file"
                               accept="{{ supportedImage }}"
                               (change)="fileSelected($event.target.files)"
                               [formControl]="file">
                        <app-form-error [control]="file"></app-form-error>
                    </div>

                    <div class="col-12 col-lg-6">
                        <img class="img-thumbnail" *ngIf="illustration.value"
                             src="{{ illustration.value }}" alt="Illustration">
                    </div>
                </div>

                <div class="btn-bar">
                    <button type="submit" class="btn btn-primary"
                            [disabled]="illustrationForm.invalid || illustrationForm.pristine"
                            (click)="saveIllustration()" translate="common.saveIllustration">
                    </button>
                </div>
            </form>

            <hr>

            <!-- Documents -->
            <document-management-component [documentManagementType]="documentManagementTypeEnum.Survey"
                                           [id]="surveyId"></document-management-component>

            <hr>

            <!-- Publication -->
            <h4 translate="communicationChannel.information.edition.form.publication" #publicationSection></h4>

            <form name="publicationForm" [formGroup]="publicationForm">

                <!-- Publication Dates -->
                <div class="row">
                    <div class="col-12 col-lg-6 col-xl-4">
                        <app-date-period-picker [isFromRequired]="true"
                                                [periodControl]="periodForm"
                                                inputLabel="communicationChannel.survey.edition.form.publicationPeriod">
                        </app-date-period-picker>
                    </div>

                </div>

                <p class="text-error col-12" *ngIf="usersAlreadyNotifiedForSurvey" translate="communicationChannel.publication.messages.alreadyNotified"></p>
                <p class="text-error col-12" *ngIf="!usersAlreadyNotifiedForSurvey" translate="communicationChannel.publication.messages.noAlreadyNotified"></p>

                <!-- Results Publication Dates -->
                <div class="row">
                    <div class="col-12 col-lg-6 col-xl-4">
                        <app-date-period-picker [periodControl]="resultsPeriodForm"
                                                inputLabel="communicationChannel.survey.edition.form.resultsPeriod">
                        </app-date-period-picker>
                    </div>

                </div>

                <div class="form-row">
                    <div class="form-group">
                        <p class="text-error col-12" *ngIf="usersAlreadyNotifiedForResults" translate="communicationChannel.publication.messages.alreadyNotifiedForResults"></p>
                        <p class="text-error col-12" *ngIf="!usersAlreadyNotifiedForResults" translate="communicationChannel.publication.messages.noAlreadyNotifiedForResults"></p>
                    </div>
                </div>

                <!-- Mail Notification -->
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">{{ 'communicationChannel.survey.edition.form.mailNotification' | translate }}*</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" id="mailNotification" type="checkbox" name="mailNotification"
                                   [formControl]="mailNotificationEnabled" />
                            <label class="form-check-label" for="mailNotification">{{ (this.mailNotificationEnabled.value ? 'common.yes' : 'common.no') | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="btn-bar">
                    <button type="button" class="btn btn-outline-secondary"
                            (click)="back()"
                            translate="common.back">
                    </button>

                    <button type="submit" class="btn btn-primary"
                            [disabled]="publicationForm.invalid || publicationForm.pristine"
                            (click)="publishSurvey()"
                            translate="common.publish">
                    </button>
                </div>

            </form>

        </ng-container>

    </form>

</div>

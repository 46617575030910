import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppRoles} from '../../../../app.constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LightZoneManagementModalComponent} from './light-zone-management-modal/light-zone-management-modal.component';
import {LightZoneLightsManagementModalComponent} from './light-zone-lights-management-modal/light-zone-lights-management-modal.component';
import {
    LightZoneWithLightCountDto,
    PublicLightingLightService,
    PublicLightingZoneService
} from '../../../../_services/configuration-services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, firstValueFrom } from 'rxjs';
import {faPen, faLink, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ConfirmModalService} from '../../../../_shared/_components/confirm-modal/confirm-modal.component';
import {NotificationsService} from '../../../../_shared/notifications.service';

@Component({
    selector: 'app-light-zone-management',
    templateUrl: './light-zone-management.component.html'
})
export class LightZoneManagementComponent implements OnInit, OnDestroy {
    @Input() serviceId: string;

    currentLang: string;
    lightingZoneTable: LightZoneWithLightCountDto[] = [];

    languageSubscription: Subscription;

    protected readonly AppRoles = AppRoles;

    // Icons
    icons = {
        edit: faPen,
        link: faLink,
        delete: faTrash
    };

    constructor(
                private readonly modalService: NgbModal,
                private readonly notificationsService: NotificationsService,
                private readonly lightZoneService: PublicLightingZoneService,
                private readonly lightService: PublicLightingLightService,
                private readonly translate: TranslateService,
                private readonly confirmService: ConfirmModalService) {
    }

    async ngOnInit(): Promise<void> {
      this.currentLang = this.translate.currentLang;
      this.languageSubscription = this.translate.onLangChange.subscribe(langChangeEvent =>
          this.currentLang = langChangeEvent.lang);
      await this.fetchLightZones();
    }

    ngOnDestroy(): void {
        this.languageSubscription.unsubscribe();
    }

    openCreateLightZoneModal(): void {
        const modal = this.modalService.open(LightZoneManagementModalComponent, {centered: true, size: 'lg'});
        modal.componentInstance.serviceId = this.serviceId;

        modal.result.then(async result => {
          if (result === 'success') {
            await this.fetchLightZones();
        }});
    }

    openEditLightZoneModal(lightZone: LightZoneWithLightCountDto): void {
        const modal = this.modalService.open(LightZoneManagementModalComponent,  {centered: true, size: 'lg'})
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.lightZone = lightZone;

        modal.result.then(async result => {
            if (result === 'success') {
                await this.fetchLightZones();
            }});
    }

    openLinkLightsModal(lightZoneId: string): void {
        const modal = this.modalService.open(LightZoneLightsManagementModalComponent, {centered: true, size: 'lg'});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.lightZoneId = lightZoneId;

        modal.result.then(async result => {
            if (result === 'success') {
                await this.fetchLightZones();
            }});
    }

    async openDeleteModal(lightZoneId: string): Promise<void> {
        const lightsAssignedToZone = await firstValueFrom(this.lightService.getLightsByServiceIdAndZoneId(this.serviceId, lightZoneId));

        const lightsCode = lightsAssignedToZone.map(l => l.code);

        this.confirmService.confirm({
            titleKey: 'common.confirmModal.title.delete',
            infoKey: lightsCode.length > 0 ? 'services.publicLighting.lightZone.modal.list' : null,
            infoList: lightsCode
        }).then(async result => {
            if (result === ConfirmModalService.yes) {
                await firstValueFrom(this.lightZoneService.deleteLightZone(lightZoneId));

                this.notificationsService.success({title: 'services.publicLighting.lightZone.notifications.deleteSuccess'});
                await this.fetchLightZones();
            }
        });
    }

    private async fetchLightZones(): Promise<void> {
        this.lightingZoneTable = await firstValueFrom(this.lightZoneService.getLightZonesWithLightCountByServiceId(this.serviceId));
    }
}

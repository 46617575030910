<div>
    <h3 translate="bookings.title"></h3>

    <app-item-to-book-selection [items]="items"
                                [crtLang]="crtLang"
                                (selectItem)="selectItemToDisplayInCalendar($event)"></app-item-to-book-selection>

    <div class="row" *ngIf="hasAdminRoleForBooking">
        <div class="col-12">
            <label class="form-label" for="searchUser" translate="bookings.searchBooking"></label>
            <input class="form-control" id="searchUser" name="searchUser" type="text"
                   #searchUser
                   [ngbTypeahead]="search"
                   pattern="{{ textInputPattern }}"
                   placeholder="{{ 'bookings.placeholder.searchBooking' | translate }}">
        </div>
    </div>

    <full-calendar class="mt-4" *ngIf="calendarOptions"
                   #fullcalendar
                   [options]="calendarOptions"></full-calendar>
</div>

<ng-template #eventPopover let-event="event">
    <h6 *ngIf="event.extendedProps.booking.reservationType === ReservationType.Booking
    && (hasAdminRoleForBooking || (event.extendedProps.booking.userId && event.extendedProps.booking.userId === userId)); else bookingBlocked">
        {{ event.extendedProps.booking | firstAndLastname }}</h6>
    <ng-template #bookingBlocked>
        <h6 translate="bookings.blocked"></h6>
    </ng-template>

    <p>{{ event.start  | date: 'HH:mm' }} - {{ event.end  | date: 'HH:mm' }} </p>

    <p>
        <span *ngIf="event.extendedProps.booking.reservationType === ReservationType.Booking">
            <strong translate="bookings.calendar.tooltip.itemToBook"></strong>
        </span>
        <span *ngIf="event.extendedProps.booking.reservationType === ReservationType.Blocking">
            <strong translate="bookings.calendar.tooltip.itemBlocked"></strong>
        </span>
        <br/>
        <span>{{ event.extendedProps.item }}</span>
    </p>

    <p *ngIf="hasAdminRoleForBooking && event.extendedProps.booking.comment">
        <span><strong translate="bookings.calendar.tooltip.comment"></strong></span><br/>
        <span>{{ event.extendedProps.booking.comment }}</span>
    </p>
</ng-template>

<ng-template #itemPopover let-resource="resource">
    <div>
        <div class="popover-resource">
            <h6>{{ resource.title }}</h6>
            <div class="text-pre-wrap">{{ resource.extendedProps.description }}</div>
        </div>
    </div>
</ng-template>

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    IotRegisterDto,
    IotRegisterSearchCriteriaDto,
    IotRegisterService,
    MeasuringPointType,
    RegisterType
} from '../../../_services/configuration-services';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {EMPTY, merge, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {faEraser} from '@fortawesome/free-solid-svg-icons';
import {FormUtils} from '../../form-utils';
import {TranslateUtils} from '../../translate-utils';

@Component({
    selector: 'app-search-iot-register',
    templateUrl: './search-iot-register.component.html'
})
export class SearchIotRegisterComponent implements OnInit, OnDestroy {

    @Input() defaultRegister: IotRegisterDto | null;
    @Input() registerTypes: RegisterType[];
    @Input() measuringPointType: MeasuringPointType | null;
    @Input() disabled = false;

    @Output() selectedRegisterEvent = new EventEmitter<IotRegisterDto>();

    searchSubject$ = new Subject<string>();

    searchText: string | null = null;
    searchCriteriaDto: IotRegisterSearchCriteriaDto;
    selectedRegister: IotRegisterDto | null;
    printedSelectedRegister: string | null = null;

    private langChangeSubscription: Subscription;
    textInputPattern = FormUtils.textInputPattern;

    searchFailed = false;
    crtLang = TranslateUtils.defaultLanguage;

    icons = {
        erase: faEraser
    };

    iotRegisters = new Array<IotRegisterDto>();

    formatter = (iotRegister: IotRegisterDto | null) => iotRegister ? `${iotRegister['label' + this.crtLang.toUpperCase()]}` : '';

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged());

        return merge(debouncedText$, this.searchSubject$).pipe(
            switchMap(term => {
                if (term.length < 1) {
                    this.selectIotRegister(null);
                }

                if (term === this.printedSelectedRegister) {
                    // Ignore the term if it is the same as the selected Register
                    return EMPTY;
                }

                this.searchCriteriaDto.searchText = term;
                return this.iotRegisterService.searchIotRegisters(this.searchCriteriaDto)
                    .pipe(tap(res => {
                            if (res == null || res.length === 0) {
                                this.searchFailed = true;
                                this.selectIotRegister(null);
                                return of([]);
                            }
                            this.searchFailed = false;
                            return of([]);
                        }),
                        catchError(() => {
                            this.searchFailed = true;
                            this.selectIotRegister(null);
                            return of([]);
                        }));
            }));
    }

    constructor(private readonly translateService: TranslateService,
                private readonly iotRegisterService: IotRegisterService) { }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(async _ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                    this.searchCriteriaDto.language = this.crtLang;
                }
            });

        // Select the default register if set
        if (this.defaultRegister) {
            this.selectIotRegister(this.defaultRegister);
        }

        this.searchCriteriaDto = new IotRegisterSearchCriteriaDto({
            language: this.crtLang,
            searchText: this.searchText,
            registerTypes: this.registerTypes,
            measuringPointType: this.measuringPointType
        });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    selectItem(selectedItemEvent: NgbTypeaheadSelectItemEvent<IotRegisterDto>): void {
        this.selectIotRegister(selectedItemEvent.item);
    }

    clearInput(): void {
        this.searchFailed = false;
        this.selectIotRegister(null, true);
    }

    private selectIotRegister(iotRegister: IotRegisterDto | null, force = false): void {
        if (iotRegister || force) {
            this.selectedRegister = iotRegister;
            this.printedSelectedRegister = iotRegister ? this.formatter(iotRegister) : null;
            this.selectedRegisterEvent.emit(iotRegister);

        } else {
            this.selectedRegisterEvent.emit(null);
        }

    }

}

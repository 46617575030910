<div class="modal-header">
    <h4 class="modal-title" translate="services.publicLighting.light.detail"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="this.activeModal.close('close')">
    </button>
</div>

<div class="modal-body custom-layout" *ngIf="isLoaded">
    <form>

        <!-- Last data update date -->
        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'publicLighting.light.modal.lastDataUpdate' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <p class="form-label">{{ light.lastDataUpdate | date:dateTimeFormat }}</p>
            </div>
        </div>

        <h6 translate="publicLighting.light.modal.providerInformationsTitle"></h6>

        <!-- Provider information -->
        <ng-container *ngIf="light.lightProvider && light.externalId; else noProvider">

            <div *ngIf="light.externalStatus === PublicLightingExternalStatus.NotAvailable" class="row">
                <div>
                    <p class="form-label text-error">
                        {{ 'publicLighting.light.modal.lightNotAvailable' | translate: {id: light.externalId} }}</p>
                </div>
            </div>

            <div *ngIf="light.externalStatus === PublicLightingExternalStatus.NotExisting" class="row">
                <div>
                    <p class="form-label text-error">
                        {{ 'publicLighting.light.modal.lightNotExisting' | translate: {id: light.externalId} }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="form-label">{{ 'publicLighting.light.modal.name' | translate }}</label>
                </div>
                <div class="col-6 text-end">
                    <p class="form-label">{{ light.lightProvider }}</p>
                </div>
            </div>

            <div *ngIf="light.externalStatus === PublicLightingExternalStatus.Available" class="row">
                <div class="col-6">
                    <label class="form-label">{{ 'publicLighting.light.modal.lightId' | translate }}</label>
                </div>
                <div class="col-6 text-end">
                    <p class="form-label">{{ light.externalName }}</p>
                </div>
            </div>
        </ng-container>

        <h6 translate="publicLighting.light.modal.lightInformationsTitle"></h6>

        <!-- Light information -->
        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'publicLighting.light.modal.code' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <p class="form-label">{{ light.code }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'publicLighting.light.modal.description' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <p class="form-label">{{ light['description' + crtLang.toUpperCase()] }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'publicLighting.light.modal.zoneCode' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <p class="form-label">{{ light.lightZoneCode }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'publicLighting.light.modal.position' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <p class="form-label" *ngIf="light.externalCoordinates; else noData">
                    {{ light.externalCoordinates }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'publicLighting.light.modal.consumption' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <p class="form-label" *ngIf="light.totalConsumption; else noData">
                    {{ light.totalConsumption.toFixed(2) }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'publicLighting.light.modal.intensity' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <p class="form-label" *ngIf="light.intensity || light.intensity === 0; else noData">
                    {{ light.intensity }} %</p>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'publicLighting.light.modal.state' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <ng-container *ngIf="light.publicLightingState; else noData">
                    <p class="form-label" *ngIf="light.publicLightingState === PublicLightingState.Connected">
                        {{ 'publicLighting.light.state.connected' | translate }}</p>
                    <p class="form-label" *ngIf="light.publicLightingState === PublicLightingState.Disconnected">
                        {{ 'publicLighting.light.state.disconnected' | translate }}</p>
                    <p class="form-label" *ngIf="light.publicLightingState === PublicLightingState.Unknown">
                        {{ 'publicLighting.light.state.unknown' | translate }}</p>
                </ng-container>

            </div>
        </div>

    </form>
</div>

<div class="modal-footer flex-end">

    <button class="btn btn-outline-danger" *ngIf="light && light.alarmId"
            (click)="goToTechnicalAlarmDetails()"
            translate="publicLighting.light.modal.alarm">
    </button>

</div>

<ng-template #noProvider>
    <div class="row">
        <div>
            <label class="form-label">{{ 'publicLighting.light.modal.lightNotLinked' | translate }}</label>
        </div>
    </div>
</ng-template>

<ng-template #noData>
    <p class="form-label">--</p>
</ng-template>

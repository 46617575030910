
<div class="content-container" *ngIf="!!survey">

    <h1>{{ survey['title' + crtLang.toUpperCase()] }}</h1>
    <h6>{{ survey.publicationDate | date }}</h6>

    <div class="btn-bar justify-content-between" [appHasAnyRole]="[AppRoles.admin]">

        <button class="btn btn-link" [routerLink]="['../manage', survey.id]">
            <fa-icon class="me-2" [icon]="icons.pen"></fa-icon>
            <span translate="common.manageInfo"></span>
        </button>

        <div class="right-bar">
            <button class="btn btn-outline-secondary"
                    (click)="back()"
                    translate="common.back">
            </button>

            <button class="btn btn-primary" *ngIf="survey.surveyState === InformationState.Ongoing"
                    (click)="openSurvey()"
                    translate="communicationChannel.survey.participate">
            </button>
        </div>
    </div>



    <!-- Content -->
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="no-border-radius area-link"
                 [innerHTML]="survey['description'  + crtLang.toUpperCase()] | urlTextToLink">
            </div>
        </div>

        <div class="col-12 col-lg-6" *ngIf="!!survey.resultIllustrationUrl">
            <div class="text-center">
                <a href="{{ survey.resultIllustrationUrl }}" target="_blank" rel="noopener">
                    <img class="rounded mw-100" [src]="survey.resultIllustrationUrl"
                         alt="{{ 'communicationChannel.information.illustration' | translate }}"/>
                </a>
            </div>
        </div>
    </div>

    <!-- Results -->
    <ng-container *ngIf="survey.resultState === InformationState.Ongoing">

        <h5 class="mt-4" translate="communicationChannel.survey.resultsTitle"></h5>

        <h6 *ngIf="!!survey.resultsPublicationDate">{{ survey.resultsPublicationDate | date }}</h6>

        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="no-border-radius area-link"
                     [innerHTML]="survey['resultContent'  + crtLang.toUpperCase()] ?? '' | urlTextToLink">
                </div>
            </div>
        </div>
    </ng-container>

</div>

<app-document-view #documentListComponent *ngIf="!!survey"
                   [documentManagementType]="DocumentManagementTypeEnum.Survey"
                   [id]="survey.id"></app-document-view>

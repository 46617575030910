import {Component, Input, OnInit} from '@angular/core';
import {
    CraningServicePricingDto,
    CraningServicePricingInputDto,
    CraningServicePricingService,
    CraningServicePricingType,
} from '../../../../../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../../../_shared/notifications.service';
import {FormUtils} from '../../../../../../_shared/form-utils';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ConfirmModalService} from '../../../../../../_shared/_components/confirm-modal/confirm-modal.component';
import {CustomerConfigService} from '../../../../../../_shared/customer-config-service';
import {TranslateUtils} from '../../../../../../_shared/translate-utils';
import {GesConstants} from '../../../../../../app.constants';

@Component({
    selector: 'app-craning-service-pricing-management-modal',
    templateUrl: './craning-service-pricing-management-modal.component.html'
})
export class CraningServicePricingManagementModalComponent implements OnInit {

    @Input() craningServiceId: string;
    @Input() crtLang = TranslateUtils.defaultLanguage;

    craningFlatPricingForm: UntypedFormGroup;
    craningRangePricingForm: UntypedFormGroup;

    craningPricings: CraningServicePricingDto[];
    selectedRangePricingToEdit: CraningServicePricingDto | null;

    GesConstants = GesConstants;

    activeForm = 'craningFlatPricingFormTab';

    icons = {
        edit: faPen,
        delete: faTrash
    };

    FormUtils = FormUtils;
    PricingType = CraningServicePricingType;

    readonly numRegex = /^-?\d*[.,]?\d{0,2}$/;

    constructor(public activeModal: NgbActiveModal,
                private readonly craningServicePricingService: CraningServicePricingService,
                private readonly notificationsService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                public readonly customerConfigService: CustomerConfigService) {

        this.craningFlatPricingForm = new UntypedFormGroup({
            categoryNameFR: new UntypedFormControl(null, [Validators.maxLength(30), Validators.required]),
            categoryNameDE: new UntypedFormControl(null, Validators.maxLength(30)),
            categoryNameEN: new UntypedFormControl(null, Validators.maxLength(30)),
            categoryNameIT: new UntypedFormControl(null, Validators.maxLength(30)),
            price: new UntypedFormControl(null, [Validators.required, Validators.min(0), FormUtils.numberDecimalPatternValidator]),
        }, {updateOn: 'change'});

        this.craningRangePricingForm = new UntypedFormGroup({
            categoryNameFR: new UntypedFormControl(null, [Validators.maxLength(30), Validators.required]),
            categoryNameDE: new UntypedFormControl(null, Validators.maxLength(30)),
            categoryNameEN: new UntypedFormControl(null, Validators.maxLength(30)),
            categoryNameIT: new UntypedFormControl(null, Validators.maxLength(30)),
            price: new UntypedFormControl(null, [Validators.required, Validators.min(0), FormUtils.numberDecimalPatternValidator]),
            range: new UntypedFormGroup({
                min: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.pattern(this.numRegex)]),
                max: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.pattern(this.numRegex)]),
            }, [FormUtils.rangeValidator]),
        }, {updateOn: 'change'});

    }

    ngOnInit(): void {
        this.fetchCraningServicePricings();
    }

    addOrUpdateCraningPricing(formGroup: UntypedFormGroup, craningServicePricingId: string | null): void {
        if (!formGroup.valid) {
            return;
        }
        if (!craningServicePricingId) {
            this.craningServicePricingService.create(this.craningServiceId, this.getFormResult(formGroup)).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.craning.notifications.createPricingSuccess'});
                this.finalizeAddOrUpdateCraningPricing(formGroup);
            });
        } else {
            this.craningServicePricingService.update(craningServicePricingId, this.getFormResult(formGroup)).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.craning.notifications.updatePricingSuccess'});
                this.finalizeAddOrUpdateCraningPricing(formGroup);
            });
        }
    }

    deleteCraningServicePricing(id: string, close: boolean): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.craningServicePricingService.delete(id).pipe().subscribe(_ => {
                    this.fetchCraningServicePricings();
                    this.notificationsService.success({title: 'services.craning.notifications.deletePricingSuccess'});
                    if (close) {
                        this.activeModal.close();
                    }
                });
            }
        });
    }

    onActiveFormChange(changeEvent: string): void {
        switch (changeEvent) {
            case 'craningFlatPricingFormTab':
                this.craningFlatPricingForm.reset();
                this.setCraningPricingForm(this.flatPricing);
                this.selectedRangePricingToEdit = null;
                break;

            case 'craningWeightRangePricingFormTab':
                this.min.addValidators(FormUtils.integerPatternValidator);
                this.max.addValidators(FormUtils.integerPatternValidator);
                this.resetCraningRangeForm();

                if (this.craningPricings.length <= 0) {
                    this.createCraningPricing();
                }
                break;

            case 'craningLengthRangePricingFormTab':
            case 'craningWidthRangePricingFormTab':
            case 'craningAreaRangePricingFormTab':
                this.min.removeValidators(FormUtils.integerPatternValidator);
                this.max.removeValidators(FormUtils.integerPatternValidator);
                this.resetCraningRangeForm();

                if (this.craningPricings.length <= 0) {
                    this.createCraningPricing();
                }
                break;
        }

    }

    getCraningPricingCategoryName(craningServicePricing: CraningServicePricingDto): string {
        return craningServicePricing['categoryName' + this.crtLang.toUpperCase()];
    }

    createCraningPricing(): void {
        this.selectedRangePricingToEdit = new CraningServicePricingDto();
        this.resetCraningRangeForm();
    }

    updateCraningPricing(id: string): void {
        this.craningServicePricingService.get(id).pipe().subscribe(craningPricing => {
            this.selectedRangePricingToEdit = craningPricing;
            this.setCraningPricingForm(craningPricing);
        });
    }

    cancelCraningPricing(): void {
        this.selectedRangePricingToEdit = null;

        if (this.craningPricings.length <= 0) {
            this.activeModal.close();
        }
    }

    isRangeValid(): boolean {
        return this.range.valid || !this.min.value || !this.max.value || !this.range.hasError('invalidRange');
    }

    isFormTabVisible(pricingType: CraningServicePricingType): boolean {
        return this.craningPricings == null ||
            this.craningPricings.length === 0 ||
            this.craningPricings.filter(p => p.pricingType !== pricingType).length === 0;
    }

    getRangeUnit(): string {
        switch (this.activeForm) {
            case 'craningWeightRangePricingFormTab':
                return 'kg';
            case 'craningLengthRangePricingFormTab':
            case 'craningWidthRangePricingFormTab':
                return 'm';
            case 'craningAreaRangePricingFormTab':
                return 'm\u{00B2}';
        }
        return '';
    }

    private finalizeAddOrUpdateCraningPricing(formGroup: UntypedFormGroup): void {
        if (formGroup === this.craningFlatPricingForm) {
            this.activeModal.close();
        } else {
            this.selectedRangePricingToEdit = null;
            this.fetchCraningServicePricings();
            this.resetCraningRangeForm();
        }
    }

    private getFormResult(formGroup: UntypedFormGroup): CraningServicePricingInputDto {
        return new CraningServicePricingInputDto({
            categoryNameFR: this.categoryNameFR(formGroup).value,
            categoryNameDE: this.categoryNameDE(formGroup).value,
            categoryNameEN: this.categoryNameEN(formGroup).value,
            categoryNameIT: this.categoryNameIT(formGroup).value,
            price: this.price(formGroup).value,
            max: formGroup === this.craningRangePricingForm ? this.max.value : null,
            min: formGroup === this.craningRangePricingForm ? this.min.value : null,
            pricingType: this.getSelectedType()
        });
    }

    private setCraningPricingForm(craningPricing: CraningServicePricingDto): void {
        let form: UntypedFormGroup;
        if (craningPricing.pricingType === CraningServicePricingType.Flat) {
            form = this.craningFlatPricingForm;

        } else {
            form = this.craningRangePricingForm;
            this.max.setValue(craningPricing.max);
            this.min.setValue(craningPricing.min);
        }

        this.categoryNameFR(form).setValue(craningPricing.categoryNameFR);
        this.categoryNameDE(form).setValue(craningPricing.categoryNameDE);
        this.categoryNameEN(form).setValue(craningPricing.categoryNameEN);
        this.categoryNameIT(form).setValue(craningPricing.categoryNameIT);
        this.price(form).setValue(craningPricing.price);
    }

    private resetCraningRangeForm(): void {
        this.craningRangePricingForm.reset();
    }

    private fetchCraningServicePricings(): void {
        this.craningServicePricingService.getAll(this.craningServiceId).pipe().subscribe(craningPricings => {
            this.craningPricings = craningPricings;
            this.setCraningPricingForm(this.flatPricing);
            this.resetCraningRangeForm();

            if (this.craningPricings.length > 0) {
                switch (this.craningPricings[0].pricingType) {
                    case CraningServicePricingType.Flat:
                        this.activeForm = 'craningFlatPricingFormTab';
                        break;
                    case CraningServicePricingType.Weight:
                        this.activeForm = 'craningWeightRangePricingFormTab';
                        break;
                    case CraningServicePricingType.Length:
                        this.activeForm = 'craningLengthRangePricingFormTab';
                        break;
                    case CraningServicePricingType.Width:
                        this.activeForm = 'craningWidthRangePricingFormTab';
                        break;
                    case CraningServicePricingType.Area:
                        this.activeForm = 'craningAreaRangePricingFormTab';
                        break;
                }
            } else {
                this.activeForm = 'craningFlatPricingFormTab';
            }

            this.onActiveFormChange(this.activeForm);
        });
    }

    private getSelectedType(): CraningServicePricingType {
        switch (this.activeForm) {
            case 'craningFlatPricingFormTab':
                return CraningServicePricingType.Flat;
            case 'craningWeightRangePricingFormTab':
                return CraningServicePricingType.Weight;
            case 'craningLengthRangePricingFormTab':
                return CraningServicePricingType.Length;
            case 'craningWidthRangePricingFormTab':
                return CraningServicePricingType.Width;
            case 'craningAreaRangePricingFormTab':
                return CraningServicePricingType.Area;
            default:
                return CraningServicePricingType.Flat;
        }
    }

    categoryNameFR(formGroup: UntypedFormGroup): UntypedFormControl {
        return formGroup.get('categoryNameFR') as UntypedFormControl;
    }

    categoryNameDE(formGroup: UntypedFormGroup): UntypedFormControl {
        return formGroup.get('categoryNameDE') as UntypedFormControl;
    }

    categoryNameEN(formGroup: UntypedFormGroup): UntypedFormControl {
        return formGroup.get('categoryNameEN') as UntypedFormControl;
    }

    categoryNameIT(formGroup: UntypedFormGroup): UntypedFormControl {
        return formGroup.get('categoryNameIT') as UntypedFormControl;
    }

    price(formGroup: UntypedFormGroup): UntypedFormControl {
        return formGroup.get('price') as UntypedFormControl;
    }

    get range(): UntypedFormGroup {
        return this.craningRangePricingForm.get('range') as UntypedFormGroup;
    }

    get min(): UntypedFormControl {
        return this.range.get('min') as UntypedFormControl;
    }

    get max(): UntypedFormControl {
        return this.range.get('max') as UntypedFormControl;
    }

    get flatPricing(): CraningServicePricingDto {
        return this.craningPricings?.find(p => p.pricingType === CraningServicePricingType.Flat) ?? new CraningServicePricingDto({pricingType: CraningServicePricingType.Flat});
    }

    get rangePricings(): CraningServicePricingDto[] {
        return this.craningPricings?.filter(p => p.pricingType !== CraningServicePricingType.Flat) ?? [];
    }
}


<ng-container *ngIf="tariff">

    <p class="text-error" *ngIf="!bookingServiceConfig"
       translate="error.configuration.noBookingConfiguration"></p>

    <div class="content-container bg-white-no-shadow" *ngIf="bookingServiceConfig">

        <div class="btn-bar" #buttonBar>
            <button *ngIf="selectedBooking && BookingUtils.canEditOrCancelBooking(selectedBooking, bookingServiceConfig, hasAdminRoleForBooking)"
                    class="btn btn-outline-danger"
                    (click)="confirmAndDeleteBooking()"
                    translate="common.cancelBooking">
            </button>

            <button *ngIf="selectedBooking && selectedBooking.recurring"
                    class="btn btn-outline-danger"
                    (click)="openPartialCancelFormModal()"
                    translate="common.cancelBookingPartially">
            </button>

            <button *ngIf="!isFormOpen && selectedBooking && BookingUtils.canEditOrCancelBooking(selectedBooking, bookingServiceConfig, hasAdminRoleForBooking)"
                    class="btn btn-primary"
                    translate="bookings.update"
                    (click)="isFormOpen = true;">
            </button>

            <button *ngIf="!isFormOpen"
                    class="btn btn-primary"
                    translate="bookings.add"
                    (click)="initFormWithStartDate()">
            </button>
        </div>

        <!-- Booking details view -->
        <app-booking-viewer *ngIf="!isFormOpen && selectedBooking"
                            [booking]="selectedBooking"
                            [hasAdminRoleForBooking]="hasAdminRoleForBooking"
                            [bookingServiceConfig]="bookingServiceConfig"
                            [userId]="userId"></app-booking-viewer>

        <app-booking-form *ngIf="isFormOpen && (selectedDate || selectedBooking || selectedSlot)"
                          [availableItems]="items"
                          [bookingServiceConfig]="bookingServiceConfig"
                          [hasAdminRoleForBooking]="hasAdminRoleForBooking"
                          [selectedBooking]="selectedBooking"
                          [selectedItemId]="selectedItemId"
                          [selectedDate]="selectedDate"
                          [selectedSlot]="selectedSlot"
                          [tariff]="tariff"
                          [serviceId]="serviceId"
                          [userId]="userId"
                          (closeForm)="closeForm()"
                          (refreshCalendar)="refreshCalendar()"></app-booking-form>

        <!-- Calendar for free booking mode -->
        <app-free-booking-calendar #freeBookingCalendarComponent *ngIf="bookingServiceConfig.bookingMode === BookingMode.Free"
                                   [serviceId]="serviceId"
                                   [userId]="userId"
                                   [bookingServiceConfig]="bookingServiceConfig"
                                   [items]="items"
                                   [hasAdminRoleForBooking]="hasAdminRoleForBooking"
                                   (selectDate)="selectDate($event)"
                                   (selectItemId)="selectItem($event)"
                                   (selectBooking)="selectBooking($event)"></app-free-booking-calendar>

        <!-- Calendar for slots booking mode -->
        <app-slots-calendar #slotsCalendarComponent *ngIf="bookingServiceConfig.bookingMode === BookingMode.Slots"
                            [userId]="userId"
                            [serviceId]="serviceId"
                            [bookingServiceConfig]="bookingServiceConfig"
                            [items]="items"
                            [hasAdminRoleForBooking]="hasAdminRoleForBooking"
                            (selectSlot)="selectSlot($event)"
                            (selectItemId)="selectItem($event)"></app-slots-calendar>

    </div>

</ng-container>

import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AuthenticationMethods,
    ImpulseDurationUnit,
    MeasuringPointLightDto,
    MeasuringPointService,
    MeasuringPointType,
    RoleDto,
    RoleService,
    SearchMeasuringPointResultDto,
    SearchUserDto,
    ServiceCategoryDto,
    ServiceCategoryService,
    ServiceConfigDto,
    ServiceDto,
    ServiceInputDto,
    ServiceService,
    ServiceType,
    UserLightDto
} from '../../_services/configuration-services';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {firstValueFrom, Subscription} from 'rxjs';
import {faQrcode, faTrash, faUserEdit, faUserPlus, faUserSlash} from '@fortawesome/free-solid-svg-icons';
import {AppConstants, AppRoles} from '../../app.constants';
import {TranslateService} from '@ngx-translate/core';
import {CustomerConfigService} from '../../_shared/customer-config-service';
import {NotificationsService} from '../../_shared/notifications.service';
import {
    AssignServiceMeasuringPointModalComponent
} from './assign-service-measuring-point-modal/assign-service-measuring-point-modal.component';
import {RolesService} from '../../_shared/roles-service';
import {FormUtils} from '../../_shared/form-utils';
import {DateUtils} from '../../_shared/date-utils';
import {ConfirmModalService} from '../../_shared/_components/confirm-modal/confirm-modal.component';
import {TranslateUtils} from '../../_shared/translate-utils';
import {FormControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {RoleManagementComponent} from '../../_shared/_components/role-management/role-management.component';
import {QrCodeViewComponent} from './qr-code-view/qr-code-view.component';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';

@Component({
    selector: 'app-manage-service',
    templateUrl: './manage-service.component.html',
    providers: [NgbModalConfig, NgbModal]
})
export class ManageServiceComponent implements OnInit, OnDestroy {

    AppRoles = AppRoles;
    FormUtils = FormUtils;
    ServiceType = ServiceType;

    serviceForm: UntypedFormGroup;

    serviceId: string | null = null;
    service: ServiceDto | null;
    serviceConfig!: ServiceConfigDto;

    categories: ServiceCategoryDto[];
    impulseDurationUnits = Object.keys(ImpulseDurationUnit)
        .filter(unit => ImpulseDurationUnit[unit] !== ImpulseDurationUnit.Millisecond);
    hoursOfDay: string[] = [];
    baseRoles: RoleDto[] = [];
    customRoles: RoleDto[] = [];

    dateFormat = DateUtils.dateFormat;

    supportedImage = AppConstants.supportedImageType;
    imageMaxSizeInKB = AppConstants.imageMaxSizeInKB;
    imageToUpload: File | null = null;
    imageUrl: string | null = null;
    imageValid = true;

    // icons
    icons = {
        userSlash: faUserSlash,
        userEdit: faUserEdit,
        userPlus: faUserPlus,
        trash: faTrash,
        qrcode: faQrcode
    }

    // authentication methods Mutli-select
    authenticationMethodDropdownSources = [];
    authenticationMethodDropdownSettings: IDropdownSettings = {};

    // measuring points
    userMeasuringPoints: Array<MeasuringPointLightDto> = new Array<MeasuringPointLightDto>();
    adminMeasuringPoints: Array<MeasuringPointLightDto> = new Array<MeasuringPointLightDto>();

    // Used to know if we need to display the filters after a search
    keepDisplayUserMeasuringPointTable = false;

    searchMeasuringPointUserId = '';
    searchAdminMeasuringPointId = '';
    searchUserMeasuringPointId = '';

    adminMeasuringPointType = MeasuringPointType.Admin;
    userMeasuringPointType = MeasuringPointType.User;
    // ---------------

    @ViewChild(RoleManagementComponent) roleComponent: RoleManagementComponent;
    @ViewChild('serviceChildrenSection', {static: false}) serviceChildrenSection: ElementRef;

    private langChangeSubscription$: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(private readonly serviceService: ServiceService,
                private readonly categoryService: ServiceCategoryService,
                public readonly customerConfigService: CustomerConfigService,
                private readonly route: ActivatedRoute,
                private readonly notificationsService: NotificationsService,
                private readonly roleService: RoleService,
                private readonly confirmService: ConfirmModalService,
                private readonly modalService: NgbModal,
                private readonly translateService: TranslateService,
                private readonly router: Router,
                public readonly rolesService: RolesService,
                private readonly measuringPointService: MeasuringPointService) {

        this.serviceForm = new UntypedFormGroup({
            serviceId: new UntypedFormControl({value: null, disabled: true}),
            serviceState: new UntypedFormControl(false, [Validators.required]),
            category: new UntypedFormControl(null, [Validators.required]),
            code: new UntypedFormControl(null, [Validators.required]),
            nameFR: new UntypedFormControl(null, [Validators.required]),
            nameDE: new UntypedFormControl(null),
            nameIT: new UntypedFormControl(null),
            nameEN: new UntypedFormControl(null),
            descriptionFR: new UntypedFormControl(null),
            descriptionDE: new UntypedFormControl(null),
            descriptionIT: new UntypedFormControl(null),
            descriptionEN: new UntypedFormControl(null),
            instructionFR: new UntypedFormControl(null),
            instructionDE: new UntypedFormControl(null),
            instructionIT: new UntypedFormControl(null),
            instructionEN: new UntypedFormControl(null),
            authenticationMethod: new UntypedFormControl(null),
            minimalWalletBalanceRequired: new UntypedFormControl(0, [Validators.required]),
            rolesForm: new UntypedFormGroup({
                publicAccess: new UntypedFormControl(false, {updateOn: 'change'}),
                baseRolesForm: new UntypedFormArray([]),
                customRolesForm: new UntypedFormArray([])
            }, {
                updateOn: 'change'
            })
        });
    }

    async ngOnInit(): Promise<void> {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription$ = this.translateService.onLangChange.subscribe(_ => {
            this.crtLang = this.translateService.currentLang;
            this.populateAuthenticationMethodsSelect();
        });

        this.serviceId = this.route.snapshot.params['serviceId'] ?? null

        this.populateAuthenticationMethodsSelect();

        if (this.rolesService.hasRoleAdmin()) {
            this.hoursOfDay = DateUtils.generateHoursOfDayBy30Minutes();

            this.categoryService.getCategories().pipe().subscribe(categories => {
                this.categories = categories;
            });

            const allRoles = await firstValueFrom(this.roleService.getAllRoles(false));
            this.baseRoles = allRoles.filter(r => r.isBase);
            this.customRoles = allRoles.filter(r => !r.isBase);

            if (this.serviceId) { // Edit a service
                await this.fetchService();

            } else {
                if (history.state.data) { // Create a new service
                    this.serviceConfig = ServiceConfigDto.fromJS(history.state.data);

                    this.setupForm();
                    this.addSpecificControlsForService();

                } else {
                    // Return to service list in case of a refresh on an unsaved service
                    await this.backToServiceList();
                }
            }

        } else if (this.rolesService.hasRoleConfigurator()) {
            await this.fetchService();
        }
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    setupForm(): void {
        if (this.canConfigureBookable()) {
            this.serviceForm.addControl('bookable', new FormControl<boolean>(true, [Validators.required]));
            this.bookable?.disable();
        }

        if (this.service) {
            this.serviceIdForm.setValue(this.service.serviceId);
            this.serviceState.setValue(this.service.active);
            this.bookable?.setValue(true);
            this.category.setValue(this.service.categoryId);
            this.code.setValue(this.service.code);
            this.nameFR.setValue(this.service.nameFR);
            this.nameDE.setValue(this.service.nameDE);
            this.nameIT.setValue(this.service.nameIT);
            this.nameEN.setValue(this.service.nameEN);
            this.descriptionFR.setValue(this.service.descriptionFR);
            this.descriptionDE.setValue(this.service.descriptionDE);
            this.descriptionIT.setValue(this.service.descriptionIT);
            this.descriptionEN.setValue(this.service.descriptionEN);
            this.instructionFR.setValue(this.service.instructionFR);
            this.instructionDE.setValue(this.service.instructionDE);
            this.instructionIT.setValue(this.service.instructionIT);
            this.instructionEN.setValue(this.service.instructionEN);
            this.minimalWalletBalanceRequired.setValue(this.service.minimalWalletBalanceRequired);

            const selectedAuthMethods = [];
            this.service.authenticationMethods.filter(r => r !== AuthenticationMethods.None)
                .forEach(r => selectedAuthMethods.push({
                    itemId: r,
                    itemVal: this.translateService.instant('common.authenticationMethod.' + r)
                }));
            this.authenticationMethod.patchValue(selectedAuthMethods);
        }

        this.populateRolesForm();
    }

    createOrEditService(): void {
        let serviceInput = new ServiceInputDto({
            serviceConfigId: this.serviceConfig.id,
            active: this.serviceState.value,
            categoryId: this.category.value,
            code: this.code.value,
            bookable: this.bookable?.value ?? false,
            nameFR: this.nameFR.value,
            nameDE: this.nameDE.value,
            nameIT: this.nameIT.value,
            nameEN: this.nameEN.value,
            descriptionFR: this.descriptionFR.value,
            descriptionDE: this.descriptionDE.value,
            descriptionIT: this.descriptionIT.value,
            descriptionEN: this.descriptionEN.value,
            instructionFR: this.instructionFR.value,
            instructionDE: this.instructionDE.value,
            instructionIT: this.instructionIT.value,
            instructionEN: this.instructionEN.value,
            authenticationMethods: this.authenticationMethod.value?.map(item => item.itemId),
            minimalWalletBalanceRequired: this.minimalWalletBalanceRequired.value,
        });

        if (!serviceInput.authenticationMethods || serviceInput.authenticationMethods?.length < 1) {
            serviceInput.authenticationMethods = [AuthenticationMethods.None];
        }

        if (this.canConfigureRoles()) {
            serviceInput.roles = this.roleComponent.getSelectedRoles();

        } else if (this.isMooringServiceType()) {
            serviceInput.roles = this.baseRoles.filter(value => value.roleKey === AppRoles.admin || value.roleKey === AppRoles.harbourmaster);

        } else {
            serviceInput.roles = this.baseRoles.filter(value => value.roleKey === AppRoles.admin);
        }

        if (this.canConfigureImpulseDuration()) {
            serviceInput.impulseDuration = this.impulseDuration.value;
            serviceInput.impulseDurationUnit = this.impulseDurationUnit.value;
            serviceInput.maxImpulseDuration = this.maxImpulseDuration?.value;
        }

        if (this.canConfigureAmountToReserveForServiceConsumption()) {
            serviceInput.amountToReserveForServiceConsumption = this.amountToReserveForServiceConsumption.value;
        }

        if (this.serviceId) {
            this.serviceService.updateService(this.service.serviceId, serviceInput).pipe().subscribe(value => {
                this.service = value;
                this.notificationsService.success({title: 'services.manage.notifications.updateSuccess'});
            });

        } else {
            this.serviceService.createService(serviceInput).pipe().subscribe(value => {
                this.notificationsService.success({title: 'services.manage.notifications.addSuccess'});
                this.service = value;
                this.serviceId = this.service.serviceId;

                // Scroll down the page to display the children forms
                setTimeout(() => this.serviceChildrenSection.nativeElement.scrollIntoView(), 0);
            });
        }
    }

    async backToServiceList(): Promise<void> {
        if (this.service?.serviceId) {
            await this.router.navigate(['/services/' + this.service.serviceId]);

        } else {
            await this.router.navigate(['/services'], {replaceUrl: true});
        }
    }

    async deleteService(): Promise<void> {
        if (this.serviceId) {
            const result = await this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'});
            if (result === ConfirmModalService.yes) {
                await firstValueFrom(this.serviceService.deleteService(this.service.serviceId));
                this.notificationsService.success({title: 'services.manage.notifications.deleteSuccess'});
                await this.router.navigate(['/services'], {relativeTo: this.route});
            }
        }
    }

    fileSelected(files: FileList): void {
        if (files.length > 0) {
            this.imageToUpload = files[0];
            const imgSize = this.imageToUpload.size;
            if ((imgSize / 1024) > AppConstants.imageMaxSizeInKB) {
                this.imageValid = false;
                this.imageToUpload = null;
            } else {
                this.imageValid = true;
            }
        }
    }

    uploadImage(): void {
        this.serviceService.uploadServiceImage(this.service.serviceId, {
            data: this.imageToUpload,
            fileName: this.imageToUpload.name
        }).pipe().subscribe(value => {
            this.imageUrl = value.imageUrl;
            this.imageToUpload = null;
        });
    }

    openQrCode(adminMeasuringPoint): void {
        const modal = this.modalService.open(QrCodeViewComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.qrRawData = adminMeasuringPoint.id;
        modal.componentInstance.qrName = adminMeasuringPoint['name' + this.crtLang.toUpperCase()];
    }

    isFormValid(): boolean {
        if (this.serviceConfig.serviceType === ServiceType.MooringPlace
            || this.serviceConfig.serviceType === ServiceType.PublicLighting) {
            // No custom role management
            return true;
        }

        const atLeastOneRoleSelected = FormUtils.atLeastOneSelectedValidator(this.baseRolesFormArray) === null
            || FormUtils.atLeastOneSelectedValidator(this.customRolesFormArray) === null
            || this.publicRolesFormControl.value;

        if (this.serviceConfig.serviceType === ServiceType.TimeLimited) {
            return atLeastOneRoleSelected;
        }
        return atLeastOneRoleSelected;
    }

    // region MeasuringPoints
    async getMeasuringPoints(): Promise<void> {
        if (this.rolesService.hasRoleAdmin()) {
            this.userMeasuringPoints = await firstValueFrom(this.measuringPointService.get20FirstForService(this.serviceId,
                this.searchUserMeasuringPointId,
                this.searchMeasuringPointUserId,
                this.crtLang,
                this.userMeasuringPointType));
        }
        if (this.rolesService.hasRoleConfigurator()) {
            this.adminMeasuringPoints = await firstValueFrom(this.measuringPointService.get20FirstForService(this.serviceId,
                this.searchAdminMeasuringPointId,
                '',
                this.crtLang,
                this.adminMeasuringPointType));
        }
    }

    async searchByUserMeasuringPointId(measuringPoint: SearchMeasuringPointResultDto | null): Promise<void> {
        this.searchUserMeasuringPointId = measuringPoint?.id ?? '';
        await this.getMeasuringPoints();
    }

    async searchByAdminMeasuringPointId(measuringPoint: SearchMeasuringPointResultDto | null): Promise<void> {
        this.searchAdminMeasuringPointId = measuringPoint?.id ?? '';
        await this.getMeasuringPoints();
    }

    async searchByUserId(user: SearchUserDto | null): Promise<void> {
        this.keepDisplayUserMeasuringPointTable = true;
        this.searchMeasuringPointUserId = user?.id ?? '';
        await this.getMeasuringPoints();
    }

    async unassignMeasuringPointFromService(measuringPoint: MeasuringPointLightDto): Promise<void> {
        const result = await this.confirmService.confirm({titleKey: 'measuringPoints.unassignConfirmModal.title'});

        if (result === ConfirmModalService.yes) {
            await firstValueFrom(this.measuringPointService.unassignCurrentServiceFromMeasuringPoint(measuringPoint.id));
            this.notificationsService.success({title: 'measuringPoints.notifications.unassignSuccess'});
            await this.getMeasuringPoints();
        }
    }

    assignNewMeasuringPoint(measuringPointType: MeasuringPointType): void {
        const modal = this.modalService.open(AssignServiceMeasuringPointModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.measuringPointTypeToAdd = measuringPointType;
        modal.componentInstance.editMode = false;
        modal.result
            .then(async value => {
                if (value === 'success') {
                    await this.getMeasuringPoints();
                }
            }, () => { /* catch the rejection */ });
    }

    printMeasuringPointUser(user: UserLightDto | null): string {
        if (user == null) {
            return '';
        } else {
            return `${user.lastName} ${user.firstName}`;
        }
    }

    printMeasuringPointName(measuringPoint: MeasuringPointLightDto | null): string {
        if (!measuringPoint) {
            return '';
        }
        return `${measuringPoint['name' + this.crtLang.toUpperCase()]}`;
    }

    editMeasuringPointUserAssignation(measuringPoint: MeasuringPointLightDto): void {
        const modal = this.modalService.open(AssignServiceMeasuringPointModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.measuringPoint = measuringPoint;
        modal.componentInstance.user = measuringPoint.user;
        modal.componentInstance.measuringPointTypeToAdd = MeasuringPointType.User;
        modal.componentInstance.editMode = true;
        modal.result
            .then(async value => {
                if (value === 'success') {
                    await this.getMeasuringPoints();
                }
            }, () => { /* catch the rejection */ });
    }

    async unassignMeasuringPointFromUser(measuringPoint: MeasuringPointLightDto): Promise<void> {
        const result = await this.confirmService.confirm({
            titleKey: 'common.confirmModal.title.unassign'
        });

        if (result === ConfirmModalService.yes) {
            await firstValueFrom(this.measuringPointService.unassignCurrentUserFromMeasuringPoint(measuringPoint.id));

            this.notificationsService.success({title: 'measuringPoints.notifications.unassignUserSuccess'});
            await this.getMeasuringPoints();
        }
    }

    // endRegion

    hasRoleAdmin(): boolean {
        return this.rolesService.hasRoleAdmin();
    }

    isMooringServiceType(): boolean {
        return this.serviceConfig.serviceType === ServiceType.MooringPlace;
    }

    isAccessControlServiceType(): boolean {
        return this.serviceConfig.serviceType === ServiceType.AccessControl;
    }

    canConfigureMap(): boolean {
        return this.serviceConfig.serviceType === ServiceType.MooringPlace
            || this.serviceConfig.serviceType === ServiceType.PublicLighting;
    }

    canConfigureAvailabilityRanges(): boolean {
        return this.serviceConfig.serviceType !== ServiceType.Weather
            && this.serviceConfig.serviceType !== ServiceType.Webcam
            && this.serviceConfig.serviceType !== ServiceType.PublicLighting;
    }

    canConfigureBookable(): boolean {
        return this.serviceConfig.serviceType === ServiceType.Bookable;
    }

    canConfigureTariffs(): boolean {
        return this.serviceConfig.serviceType !== ServiceType.MooringPlace
            && this.serviceConfig.serviceType !== ServiceType.PointOfSale
            && this.serviceConfig.serviceType !== ServiceType.Weather
            && this.serviceConfig.serviceType !== ServiceType.Webcam
            && this.serviceConfig.serviceType !== ServiceType.PublicLighting;
    }

    canConfigureAdminMeasuringPoint(): boolean {
        return this.serviceConfig.serviceType === ServiceType.Bookable
            || this.serviceConfig.serviceType === ServiceType.Energy
            || this.serviceConfig.serviceType === ServiceType.TimeLimited
            || this.serviceConfig.serviceType === ServiceType.Weather;
    }

    canConfigureUserMeasuringPoint(): boolean {
        return this.serviceConfig.serviceType === ServiceType.Energy ||
            this.serviceConfig.serviceType === ServiceType.UserAlarm;
    }

    canConfigureImpulseDuration(): boolean {
        return this.serviceConfig.serviceType === ServiceType.TimeLimited
            || this.serviceConfig.serviceType === ServiceType.Bookable;
    }

    canConfigureRoles(): boolean {
        return this.serviceConfig.serviceType !== ServiceType.MooringPlace
            && this.serviceConfig.serviceType !== ServiceType.PublicLighting;
    }

    canConfigurePublicRole(): boolean {
        const publicServiceTypes = [
            ServiceType.Default,
            ServiceType.Bookable,
            ServiceType.TimeLimited,
            ServiceType.CraningAndWinterizing,
            ServiceType.Webcam,
            ServiceType.Weather,
            ServiceType.ChargingStations,
        ]
        return publicServiceTypes.indexOf(this.serviceConfig.serviceType) >= 0;
    }

    /**
     * Minimal Wallet balance is unavailable for :
     *  - Hydrowash
     *  - Default
     *  - MooringPlace
     */
    canConfigureMinimalWalletBalance(): boolean {
        if (this.customerConfigService.isEwalletEnabled()) {
            return !(this.serviceConfig.serviceType === ServiceType.Hydrowash
                || this.serviceConfig.serviceType === ServiceType.Default
                || this.serviceConfig.serviceType === ServiceType.MooringPlace);
        }
        return false;
    }

    canConfigureAmountToReserveForServiceConsumption(): boolean {
        return this.serviceConfig.serviceType === ServiceType.ChargingStations;
    }

    canConfigureActivation(): boolean {
        return this.serviceConfig.serviceType !== ServiceType.PublicLighting;
    }

    private addSpecificControlsForService(): void {
        if (this.canConfigureImpulseDuration()) {
            this.serviceForm.addControl('impulseDuration', new UntypedFormControl(this.service?.impulseDuration));
            this.serviceForm.addControl('impulseDurationUnit', new UntypedFormControl(this.service?.impulseDurationUnit));

            if (this.serviceConfig.serviceType === ServiceType.TimeLimited) {
                this.impulseDuration.setValidators(Validators.required);
                this.serviceForm.addControl('maxImpulseDuration', new UntypedFormControl(this.service?.maxImpulseDuration));
                this.maxImpulseDuration.setValidators([Validators.required, Validators.min(this.impulseDuration.value)]);
                this.impulseDurationUnit.setValidators(Validators.required);
            }
        }

        if (this.canConfigureAmountToReserveForServiceConsumption()) {
            this.serviceForm.addControl('amountToReserveForServiceConsumption', new UntypedFormControl(this.service?.amountToReserveForServiceConsumption, [Validators.required, Validators.min(0), FormUtils.integerPatternValidator]));
        }
    }

    private populateRolesForm(): void {
        // Special case for UserAlarms
        if (this.serviceConfig.serviceType === ServiceType.UserAlarm) {
            this.baseRoles = this.baseRoles.filter(r => r.roleKey === AppRoles.user);
        }

        this.customRoles.forEach(role => {
            this.customRolesFormArray.push(new UntypedFormControl(
                this.service?.roles ? !!(this.service.roles.find(value => value.roleKey === role.roleKey)) : false));
        });

        this.baseRoles.forEach(role => {
            this.baseRolesFormArray.push(new UntypedFormControl(
                this.service?.roles ? !!(this.service.roles.find(value => value.roleKey === role.roleKey)) : false));
        });

        if (this.serviceId && this.canConfigurePublicRole() && (!this.service.roles || this.service.roles.length === 0)) {
            this.publicRolesFormControl.setValue(true);
        }
    }

    private populateAuthenticationMethodsSelect() {
        this.authenticationMethodDropdownSources = [];
        Object.keys(AuthenticationMethods).filter(r => r !== AuthenticationMethods.None)
            .forEach(r => {
                this.authenticationMethodDropdownSources.push({
                    itemId: r,
                    itemVal: this.translateService.instant('common.authenticationMethod.' + r)
                })
            });
        this.authenticationMethodDropdownSettings = {
            idField: 'itemId',
            textField: 'itemVal',
            singleSelection: false,
            selectAllText: this.translateService.instant('common.multiselect.selectAll'),
            unSelectAllText: this.translateService.instant('common.multiselect.unselectAll'),
            searchPlaceholderText: this.translateService.instant('common.multiselect.search'),
            noDataAvailablePlaceholderText: this.translateService.instant('common.multiselect.noResults'),
            itemsShowLimit: 3,
            allowSearchFilter: false
        };
    }

    private async fetchService(): Promise<void> {
        const service = await firstValueFrom(this.serviceService.getService(this.serviceId));
        this.service = service;
        this.serviceConfig = service.serviceConfig;
        this.imageUrl = service.imageUrl;

        this.setupForm();
        this.addSpecificControlsForService();

        if (this.canConfigureAdminMeasuringPoint() || this.canConfigureUserMeasuringPoint()) {
            await this.getMeasuringPoints();
        }
    }

    public get serviceType(): typeof ServiceType {
        return ServiceType;
    }

    get serviceIdForm(): UntypedFormControl {
        return this.serviceForm.get('serviceId') as UntypedFormControl;
    }

    get rolesFormArray(): UntypedFormGroup {
        return this.serviceForm.get('rolesForm') as UntypedFormGroup;
    }

    get publicRolesFormControl(): UntypedFormControl {
        return this.rolesFormArray.controls['publicAccess'] as UntypedFormControl;
    }

    get baseRolesFormArray(): UntypedFormArray {
        return this.rolesFormArray.get('baseRolesForm') as UntypedFormArray;
    }

    get customRolesFormArray(): UntypedFormArray {
        return this.rolesFormArray.get('customRolesForm') as UntypedFormArray;
    }

    get serviceState(): UntypedFormControl {
        return this.serviceForm.get('serviceState') as UntypedFormControl;
    }

    get category(): UntypedFormControl {
        return this.serviceForm.get('category') as UntypedFormControl;
    }

    get code(): UntypedFormControl {
        return this.serviceForm.get('code') as UntypedFormControl;
    }

    get nameFR(): UntypedFormControl {
        return this.serviceForm.get('nameFR') as UntypedFormControl;
    }

    get nameDE(): UntypedFormControl {
        return this.serviceForm.get('nameDE') as UntypedFormControl;
    }

    get nameIT(): UntypedFormControl {
        return this.serviceForm.get('nameIT') as UntypedFormControl;
    }

    get nameEN(): UntypedFormControl {
        return this.serviceForm.get('nameEN') as UntypedFormControl;
    }

    get descriptionFR(): UntypedFormControl {
        return this.serviceForm.get('descriptionFR') as UntypedFormControl;
    }

    get descriptionDE(): UntypedFormControl {
        return this.serviceForm.get('descriptionDE') as UntypedFormControl;
    }

    get descriptionIT(): UntypedFormControl {
        return this.serviceForm.get('descriptionIT') as UntypedFormControl;
    }

    get descriptionEN(): UntypedFormControl {
        return this.serviceForm.get('descriptionEN') as UntypedFormControl;
    }

    get instructionFR(): UntypedFormControl {
        return this.serviceForm.get('instructionFR') as UntypedFormControl;
    }

    get instructionDE(): UntypedFormControl {
        return this.serviceForm.get('instructionDE') as UntypedFormControl;
    }

    get instructionIT(): UntypedFormControl {
        return this.serviceForm.get('instructionIT') as UntypedFormControl;
    }

    get instructionEN(): UntypedFormControl {
        return this.serviceForm.get('instructionEN') as UntypedFormControl;
    }

    get bookable(): FormControl<boolean> | null {
        return this.serviceForm.get('bookable') as FormControl<boolean> ?? null;
    }

    get authenticationMethod(): UntypedFormControl {
        return this.serviceForm.get('authenticationMethod') as UntypedFormControl;
    }

    get minimalWalletBalanceRequired(): UntypedFormControl {
        return this.serviceForm.get('minimalWalletBalanceRequired') as UntypedFormControl;
    }

    get amountToReserveForServiceConsumption(): UntypedFormControl {
        return this.serviceForm.get('amountToReserveForServiceConsumption') as UntypedFormControl;
    }

    get impulseDuration(): UntypedFormControl {
        return this.serviceForm.get('impulseDuration') as UntypedFormControl;
    }

    get maxImpulseDuration(): UntypedFormControl | null {
        return this.serviceForm.get('maxImpulseDuration') as UntypedFormControl;
    }

    get impulseDurationUnit(): UntypedFormControl {
        return this.serviceForm.get('impulseDurationUnit') as UntypedFormControl;
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {
    FormControl,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {
    CraningBookingSlotService,
    CraningBookingSlotState,
    Day,
    GeneratingCraningBookingSlotsInputDto,
    GeneratingCraningBookingSlotsOutputDto
} from '../../../../../_services/configuration-services';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {FormUtils} from '../../../../../_shared/form-utils';
import {DateUtils} from '../../../../../_shared/date-utils';
import {CustomDateFormatter} from '../../../../../_shared/custom-date-formatter';

@Component({
    selector: 'app-craning-booking-slots-generation-modal',
    templateUrl: './craning-booking-slots-generation-modal.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class CraningBookingSlotsGenerationModalComponent implements OnInit {

    @Input() serviceId: string;

    craningBookingSlotsGenerationForm: UntypedFormGroup;

    daysOfWeek = Object.keys(Day);
    CraningBookingSlotState = CraningBookingSlotState;

    lastCraningBookingSlotsGenerationResult: GeneratingCraningBookingSlotsOutputDto | null = null;

    minDate: NgbDateStruct;

    DateUtils = DateUtils;

    constructor(public craningBookingSlotsGenerationModal: NgbActiveModal,
                private readonly craningBookingSlotService: CraningBookingSlotService,
                private readonly notificationsService: NotificationsService) {
    }

    ngOnInit(): void {

        const dayFormArray = [];
        for (const day of this.daysOfWeek) {
            dayFormArray.push(new UntypedFormControl(true));
        }

        this.craningBookingSlotsGenerationForm = new UntypedFormGroup({
            datePeriod: new UntypedFormGroup({
                from: new UntypedFormControl(null, [Validators.required, FormUtils.datePatternValidator]),
                to: new UntypedFormControl(null, [Validators.required, FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]),
            timePeriod: new UntypedFormGroup({
                from: new UntypedFormControl(null, Validators.required),
                to: new UntypedFormControl(null, Validators.required),
            }, [FormUtils.timePeriodValidator]),
            slotDuration: new UntypedFormControl('', Validators.required),
            state: new FormControl<CraningBookingSlotState>(null, Validators.required),
            days: new UntypedFormArray(dayFormArray, FormUtils.atLeastOneSelectedValidator),
        }, {updateOn: 'change'});

        this.minDate = DateUtils.dateToNgbDateStruct(new Date());
    }

    get datePeriod(): UntypedFormGroup {
        return this.craningBookingSlotsGenerationForm.get('datePeriod') as UntypedFormGroup;
    }

    get startDate(): UntypedFormControl {
        return this.datePeriod.get('from') as UntypedFormControl;
    }

    get endDate(): UntypedFormControl {
        return this.datePeriod.get('to') as UntypedFormControl;
    }

    get timePeriod(): UntypedFormGroup {
        return this.craningBookingSlotsGenerationForm.get('timePeriod') as UntypedFormGroup;
    }

    get startTime(): UntypedFormControl {
        return this.timePeriod.get('from') as UntypedFormControl;
    }

    get lastStartTimeOfTheDay(): UntypedFormControl {
        return this.timePeriod.get('to') as UntypedFormControl;
    }

    get slotDuration(): UntypedFormGroup {
        return this.craningBookingSlotsGenerationForm.get('slotDuration') as UntypedFormGroup;
    }

    get state(): FormControl<CraningBookingSlotState> {
        return this.craningBookingSlotsGenerationForm.get('state') as FormControl<CraningBookingSlotState>;
    }

    get days(): UntypedFormGroup {
        return this.craningBookingSlotsGenerationForm.get('days') as UntypedFormGroup;
    }

    generateCraningBookingSlots(): void {
        if (!this.craningBookingSlotsGenerationForm.valid) {
            return;
        }
        this.lastCraningBookingSlotsGenerationResult = null;

        this.craningBookingSlotService.generateCraningBookingSlots(this.serviceId, this.getFormResult()).pipe().subscribe(result => {
            if (result.success) {
                this.notificationsService.success({
                    title: 'services.craning.notifications.generateCraningBookingSlotsSuccess',
                    interpolateParams: {numberOfCreatedSlots: result.numberOfCreatedSlots}
                });
                this.craningBookingSlotsGenerationModal.close('success');
            } else {
                this.notificationsService.warning({
                    title: 'services.craning.notifications.generateCraningBookingSlotsWithConflicts',
                    interpolateParams: {numberOfConflicts: result.conflictingBookingSlots.length}
                });
                this.lastCraningBookingSlotsGenerationResult = result;
            }
        });
    }

    private getFormResult(): GeneratingCraningBookingSlotsInputDto {
        const selectedDays = [];
        for (const [index, value] of this.daysOfWeek.entries()) {
            if (this.days.get(index.toString()).value) {
                selectedDays.push(value);
            }
        }

        return new GeneratingCraningBookingSlotsInputDto({
            startDate: DateUtils.ngbDateStructToDate(this.startDate.value),
            endDate: DateUtils.ngbDateStructToDate(this.endDate.value),
            startTime: DateUtils.ngbTimeStructToString(this.startTime.value),
            lastStartTimeOfTheDay: DateUtils.ngbTimeStructToString(this.lastStartTimeOfTheDay.value),
            duration: DateUtils.ngbTimeStructToString(this.slotDuration.value),
            state: this.state.value,
            days: selectedDays
        });
    }
}

import {Injectable, OnDestroy} from '@angular/core';
import {
    CommunicationChannelConfigurationDto,
    ConfigurationService,
    CustomerFeatureConfigurationDto,
    CustomerLanguageConfigurationDto
} from '../_services/configuration-services';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomerConfigService implements OnDestroy {

    private static configLoaded = false;

    private languageConfiguration: CustomerLanguageConfigurationDto | null;
    private featureConfiguration: CustomerFeatureConfigurationDto | null;
    public minimumWalletCreditAmount : number | null;
    private _customerNumberAutoIncrementEnabled: boolean;

    private configurationLoadedSubject$ = new BehaviorSubject(false);
    public configurationLoadedMessage = this.configurationLoadedSubject$.asObservable();

    constructor(private readonly configurationService: ConfigurationService) {
        this.fetchCustomerConfiguration();
    }

    static isConfigLoaded(): boolean {
        return CustomerConfigService.configLoaded;
    }

    fetchCustomerConfiguration(): void {
        this.configurationService.getCustomerConfiguration().pipe().subscribe(config => {
            CustomerConfigService.configLoaded = true;

            this.languageConfiguration = config.languageConfiguration;
            this.featureConfiguration = config.featureConfiguration;
            this.minimumWalletCreditAmount = config.minimumWalletCreditAmount ?? 0;

            this._customerNumberAutoIncrementEnabled = config.customerNumberAutoIncrementEnabled ?? false;

            this.configurationLoadedSubject$.next(true);

        }, error => {
            this.configurationLoadedSubject$.next(false);
            throw error;
        });
    }

    isLanguageAvailable(lang: string): boolean {
        switch (lang) {
            case 'fr':
                return true;
            case 'de':
                return this.languageConfiguration?.germanEnabled ?? false;
            case 'en':
                return this.languageConfiguration?.englishEnabled ?? false;
            case 'it':
                return this.languageConfiguration?.italianEnabled ?? false;
            default:
                return false;
        }
    }

    isAlarmsEnabled(): boolean {
        return this.featureConfiguration?.alarmsEnabled ?? false;
    }

    isInfrastructureEnabled(): boolean {
        return this.featureConfiguration?.infrastructureEnabled ?? false;
    }

    isEwalletEnabled(): boolean {
        return this.featureConfiguration?.eWalletEnabled ?? false;
    }

    isEPaymentEnabled(): boolean {
        return this.featureConfiguration?.ePaymentEnabled ?? false;
    }

    isCashMachineEnabled(): boolean {
        return this.featureConfiguration?.cashMachineEnabled ?? false;
    }

    isExternalBillingEnabled(): boolean {
        return this.featureConfiguration?.externalBillingEnabled ?? false;
    }

    get communicationChannelConfiguration(): CommunicationChannelConfigurationDto | null {
        return this.featureConfiguration?.communicationChannelConfiguration ?? null;
    }

    get customerNumberAutoIncrementEnabled(): boolean {
        return this._customerNumberAutoIncrementEnabled;
    }

    ngOnDestroy(): void {
        if (!!this.configurationLoadedSubject$) {
            this.configurationLoadedSubject$.next(false);
            this.configurationLoadedSubject$.complete();
            this.configurationLoadedSubject$ = null;
        }
    }
}

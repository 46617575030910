<h4 translate="services.craning.service.title"></h4>

<div class="btn-bar">
    <button class="btn btn-primary"
            (click)="createCraningService()"
            translate="services.craning.service.actions.add">
    </button>
</div>

<div class="table-responsive">
    <table class="table table-striped" [attr.aria-label]="'services.craning.service.title' | translate">
        <thead>
        <tr>
            <th scope="col" style="min-width: 40%" translate="services.craning.service.label"></th>
            <th scope="col" style="min-width: 40%" translate="services.craning.service.pricings"></th>
            <th scope="col" translate="services.craning.service.questions"></th>
            <th scope="col" style="min-width:150px"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="craningServices.length === 0">
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td></td>
        </tr>

        <tr *ngFor="let craningService of craningServices;">
            <td>{{ getCraningServiceLabel(craningService) }}</td>

            <td>
                <ng-container *ngFor="let pricing of craningService.pricings" >
                    <div class="d-flex">
                        <div class="w-100"><p><strong>{{ getCraningServicePricingCategoryName(pricing) }}</strong></p></div>
                        <div class="flex-shrink-1 text-nowrap">{{ pricing.price | currencyFormat }}</div>
                    </div>
                </ng-container>
                <p *ngIf="craningService.pricings.length === 0">-</p>
            </td>

            <td>
                {{craningService.questions.length}}
            </td>

            <td class="text-end">
                <button class="btn btn-link btn-sm"
                        (click)="editCraningServicePricings(craningService)"
                        title="{{'services.craning.tooltip.pricings' | translate}}">
                    <fa-icon [icon]="icons.pricings"></fa-icon>
                </button>

                <button class="btn btn-link btn-sm"
                        (click)="editCraningServiceQuestions(craningService)"
                        title="{{'services.craning.tooltip.questions' | translate}}">
                    <fa-icon [icon]="icons.questions"></fa-icon>
                </button>

                <button class="btn btn-link btn-sm"
                        (click)="updateCraningService(craningService)"
                        title="{{'common.tooltip.edit' | translate}}">
                    <fa-icon [icon]="icons.edit"></fa-icon>
                </button>

                <button class="btn btn-link btn-sm" type="button"
                        (click)="deleteCraningService(craningService)"
                        title="{{'common.tooltip.delete' | translate}}">
                    <fa-icon [icon]="icons.delete"></fa-icon>
                </button>
            </td>

        </tr>
        </tbody>

    </table>
</div>

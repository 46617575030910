<div class="content-container bg-white">
    <h1 translate="menu.navigation.transactions.main"></h1>

    <div class="row mb-4" [formGroup]="searchTermsFormGroup">

        <div class="default-filter-form-field w-100">
            <input class="form-control" [placeholder]="'common.search.label' | translate" [formControl]="searchTerms"/>
        </div>

    </div>

    <div ngbAccordion>
        <div ngbAccordionItem>
            <ng-container ngbAccordionHeader>
                <button ngbAccordionButton>
                    <span translate="common.search.advanced"></span>
                </button>
            </ng-container>

            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <div class="row" [formGroup]="filterFormGroup">

                            <!-- Date -->
                            <div class="default-filter-form-field" *ngIf="validityPeriod">
                                <app-date-period-picker [periodControl]="validityPeriod"
                                                        (dateFromSelected)="searchCriteriaChanged()"
                                                        (dateFromReset)="searchCriteriaChanged()"
                                                        (dateToSelected)="searchCriteriaChanged()"
                                                        (dateToReset)="searchCriteriaChanged()"
                                                        inputLabel="transactions.filter.date"></app-date-period-picker>
                            </div>

                            <!-- Amount Filter -->
                            <div class="default-filter-form-field">

                                <div [formGroup]="amountRange">
                                    <label class="form-label" translate="transactions.filter.amount"></label>
                                    <div class="input-group mb-2">
                                        <span class="input-group-text"
                                              translate="transactions.filter.amountFrom"></span>
                                        <input class="form-control" id="amountFrom" name="amountFrom" type="number"
                                               [formControl]="amountFrom"
                                               (ngModelChange)="searchCriteriaChanged()"
                                               (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                                               placeholder="{{ 'transactions.filter.amountFrom' | translate }}">
                                        <span class="input-group-text">CHF</span>
                                    </div>
                                    <app-form-error [control]="amountFrom"></app-form-error>

                                    <div class="input-group mb-2">
                                        <span class="input-group-text" translate="transactions.filter.amountTo"></span>
                                        <input class="form-control" id="amountTo" name="amountTo" type="number"
                                               [formControl]="amountTo"
                                               (ngModelChange)="searchCriteriaChanged()"
                                               (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                                               placeholder="{{ 'transactions.filter.amountTo' | translate }}">
                                        <span class="input-group-text">CHF</span>
                                    </div>
                                    <app-form-error [control]="amountTo"></app-form-error>
                                    <app-form-error [control]="amountRange"></app-form-error>
                                </div>
                            </div>

                            <!-- Service Filter -->
                            <div class="default-filter-form-field">
                                <label class="form-label" translate="transactions.filter.service"></label>
                                <ng-multiselect-dropdown
                                    placeholder="{{'common.multiselect.placeholder' | translate}}"
                                    [formControl]="service"
                                    (ngModelChange)="searchCriteriaChanged()"
                                    [settings]="dropdownSettings"
                                    [data]="servicesDropdownList">
                                </ng-multiselect-dropdown>
                            </div>

                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary" type="button"
                [disabled]="!isFiltering()"
                (click)="clearFilter()"
                translate="common.clearFilter">
        </button>
    </div>

    <ngx-datatable
        class="material"
        (page)="setPage($event)"
        (sort)="onSort($event)"
        [columnMode]="'force'"
        [count]="transactions.rowCount"
        [externalPaging]="true"
        [externalSorting]="true"
        [footerHeight]="50"
        [headerHeight]="50"
        [limit]="transactions.pageSize"
        [messages]="{
                  totalMessage: 'common.datatable.total' | translate
                  }"
        [sorts]="[{prop: currentSort.property, dir: currentSort.direction}]"
        [offset]="crtPage"
        [reorderable]="false"
        [rowHeight]="'auto'"
        [rows]="transactions.results"
        [scrollbarH]="true"
        [rowClass]="getRowClass"
        >

        <!-- Id -->
        <ngx-datatable-column [width]="125" prop="id" name="{{'transactions.table.id' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.id }}
            </ng-template>
        </ngx-datatable-column>

        <!-- Date -->
        <ngx-datatable-column [width]="80" prop="dateTime" name="{{'transactions.table.date' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.dateTime | date:dateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <!-- Amount -->
        <ngx-datatable-column [width]="80" prop="amount" name="{{'transactions.table.amount' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                <fa-icon [icon]="icons.arrowCircleUp" *ngIf="row.amount > 0"
                         class="arrow-circle text-primary"></fa-icon>
                <fa-icon [icon]="icons.arrowCircleDown" *ngIf="row.amount < 0"
                         class="arrow-circle text-danger"></fa-icon>
                {{ row.amount > 0 ? '+' : '' }}{{ row.amount | currencyFormat }}
            </ng-template>
        </ngx-datatable-column>

        <!-- Service -->
        <ngx-datatable-column [width]="140" sortable="true" prop="serviceName{{ crtLang.toUpperCase() }}"
                              name="{{'transactions.table.service' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row['serviceName' + crtLang.toUpperCase()] }}
            </ng-template>
        </ngx-datatable-column>

        <!-- User -->
        <ngx-datatable-column [width]="180" [sortable]="false" prop="userId"
                              name="{{'transactions.table.user' | translate}}">
            <ng-template ngx-datatable-cell-template let-transaction="row">
                <div>{{transaction.userId}}</div>
                <div *ngFor="let rfidCard of transaction.userRfidCards" translate="users.rfid.formattedIdentifier" [translateParams]="{cardNumber: rfidCard.cardNumber, uid: rfidCard.uid}"></div>
            </ng-template>
        </ngx-datatable-column>

        <!-- Technical Comment -->
        <ngx-datatable-column [width]="140" [sortable]="false" prop="technicalComment"
                              name="{{'transactions.table.technicalComment' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.technicalComment }}
            </ng-template>
        </ngx-datatable-column>


    </ngx-datatable>
</div>



import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {
    BookingInputDto,
    BookingLightDto, BookingService,
    ItemToBookDto, PaymentTypes, ReservationType,
    TariffDto,
} from '../../../../../_services/configuration-services';
import {TranslateUtils} from '../../../../../_shared/translate-utils';
import {firstValueFrom} from 'rxjs';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {OverlappingBookingModalComponent} from './overlapping-booking-modal/overlapping-booking-modal.component';
import {InternalDatatransService} from "../../../../../_services/internal-datatrans.service";

@Component({
    selector: 'app-booking-form-confirmation-modal',
    templateUrl: './booking-form-confirmation-modal.component.html'
})
export class BookingFormConfirmationModalComponent implements OnInit {

    @Input() booking!: BookingInputDto;
    @Input() bookingId: string | null = null;
    @Input() amount: number;
    @Input() tariff!: TariffDto;
    @Input() itemToBook!: ItemToBookDto;
    @Input() hasAdminRoleForBooking: boolean = false;
    @Input() userId: string | null = null;
    @Input() selectedBooking: BookingLightDto | null = null;

    crtLang = TranslateUtils.defaultLanguage;
    ReservationType = ReservationType;

    constructor(public activeModal: NgbActiveModal,
                private readonly modalService: NgbModal,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly internalDatatransService: InternalDatatransService,
                private readonly bookingService: BookingService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
    }

    async validate(reason: string): Promise<void> {
        // Copy of the booking to remove visitor contact in case of user, this avoids validation issue when the user has no phone number
        const booking = BookingInputDto.fromJS(this.booking.toJSON());

        if (booking.userId) {
            booking.visitorContact = null;
        }

        let overlappingBookings = [];
        if (!this.booking.bookingSlotId) {
            const defaultGuid = '00000000-0000-0000-0000-000000000000';
            overlappingBookings = await firstValueFrom(this.bookingService.getOverlappingBookings(this.selectedBooking?.id ?? defaultGuid, booking));
        }

        if (overlappingBookings.length > 0) {
            const modal = this.modalService.open(OverlappingBookingModalComponent, {
                centered: true
            });
            modal.componentInstance.overlappingBookings = overlappingBookings;
            modal.result
                .then(result => {
                    if (result === 'confirm') {
                        this.activeModal.close();
                    }
                }, () => { /* catch the rejection */ });

        } else {
            if (this.booking.reservationType === ReservationType.Blocking) {
                await this.saveBlockingPeriod(booking);

            } else {
                await this.saveBooking(booking);
            }

            this.activeModal.close(reason);
        }
    }

    cancel(reason: string): void {
        this.activeModal.dismiss(reason);
    }

    private async saveBlockingPeriod(blockingPeriod: BookingLightDto): Promise<void> {
        if (this.selectedBooking) {
            await firstValueFrom(this.bookingService.updateBlocking(this.selectedBooking.id, blockingPeriod));
            this.notificationsService.success({title: 'bookings.notifications.updateBlockingSuccess'});

        } else {
            await firstValueFrom(this.bookingService.createBlocking(blockingPeriod));
            this.notificationsService.success({title: 'bookings.notifications.addBlockingSuccess'})
        }
    }

    private async saveBooking(booking: BookingInputDto): Promise<void> {
        if (this.selectedBooking) {
            if (this.hasAdminRoleForBooking) {
                await firstValueFrom(this.bookingService.updateBooking(this.selectedBooking.id, booking));

            } else if (this.userId) {
                await firstValueFrom(this.bookingService.updateBookingForUser(this.selectedBooking.id, booking));

            }
            this.notificationsService.success({title: 'bookings.notifications.updateBookingSuccess'});
        } else {
            if (this.hasAdminRoleForBooking) {
                await firstValueFrom(this.bookingService.createBooking(booking));

            } else if (booking.paymentType === PaymentTypes.Ebanking) {
                this.internalDatatransService.payBooking(booking, this.amount * 100);
                return; // Do not display success message

            } else if (this.userId) {
                await firstValueFrom(this.bookingService.createBookingForUser(booking));

            } else {
                await firstValueFrom(this.bookingService.createBookingForVisitor(booking));
            }
            this.notificationsService.success({title: 'bookings.notifications.addBookingSuccess'});
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(duration: string | null): string {
    if (!duration) {
      return '';
    }

    // 'hh:mm:ss'
    if (duration.length == 8) {
      return duration;
    }

    const durationHasADayPattern = /^\d+\./;
    // transform 'd.hh:mm:ss' to 'hh:mm:ss'
    if (durationHasADayPattern.test(duration)) {
        const parts = duration.replace('.', ':').split(':');

        let days = parseInt(parts[0]);
        let hours = parseInt(parts[1]);
        let minutes = parseInt(parts[2]);
        let seconds = parseInt(parts[3]);

        const totalHours = days * 24 + hours;

        return `${totalHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    // transform 'hh:mm:ss.ms' to 'hh:mm:ss'
    else {
         return duration.split('.')[0];
    }
  }

}

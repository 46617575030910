
<div class="content-container bg-white">

    <h1 translate="communicationChannel.contact.title"></h1>

    <div class="btn-bar justify-content-start" [appHasAnyRole]="[AppRoles.admin]">
        <button class="btn btn-link" [routerLink]="['manage']">
            <fa-icon class="me-2" [icon]="icons.pen"></fa-icon>
            <span translate="common.manageInfo"></span>
        </button>
    </div>

    <div class="row" *ngIf="!!config">

        <div class="col-12">
            <label class="form-label" for="message">{{ 'contact.message' | translate }}*</label>
            <textarea id="message" class="form-control"
                      name="message"
                      rows="4"
                      placeholder="{{ 'contact.placeholder' | translate }}"
                      [formControl]="message"></textarea>
        </div>

    </div>

    <div class="btn-bar">
        <button type="submit" class="btn btn-primary"
                [disabled]="message.invalid"
                (click)="sendMessage()"
                translate="common.send">
        </button>
    </div>

    <h5 *ngIf="config" translate="common.cgu"></h5>
    <h5 *ngIf="!config" translate="communicationChannel.contact.notification.noConfiguration"></h5>

    <div>
        {{ (!!this.config) ? this.config['cgu' + crtLang.toUpperCase()] : '' }}
    </div>

</div>

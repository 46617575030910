import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
    BookingDto,
    BookingInputDto,
    BookingMode,
    BookingService,
    BookingServiceConfigDto,
    BookingSlotDto,
    Day,
    ItemToBookAnswerType,
    ItemToBookDto,
    PaymentTypes,
    ReservationType,
    TariffDto,
    VisitorContactDto
} from '../../../../_services/configuration-services';
import {NgbDateParserFormatter, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from '../../../../_shared/custom-date-formatter';
import {FormControl, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {ContactForm} from '../../../../_shared/_components/user-data-form/user-data-form.component';
import {FormUtils} from '../../../../_shared/form-utils';
import {TranslateUtils} from '../../../../_shared/translate-utils';
import {firstValueFrom, Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {
    DateTimePeriodPickerComponent
} from '../../../../_shared/_components/date-time-period-picker/date-time-period-picker.component';
import {DateUtils} from '../../../../_shared/date-utils';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';
import {TariffUtils} from '../../../../_shared/tariff-utils';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {
    DebtorType,
    UserVisitorSelectorComponent
} from '../../../../_shared/_components/user-visitor-selector/user-visitor-selector.component';
import {
    ItemWithAnswerForm,
    ItemWithQuestionsAnswersFormComponent
} from './item-with-questions-answers-form/item-with-questions-answers-form.component';
import {
    BookingFormConfirmationModalComponent
} from './booking-form-confirmation-modal/booking-form-confirmation-modal.component';
import {DaysPickerComponent} from 'src/app/_shared/_components/days-picker/days-picker.component';
import {BookingUtils} from '../../../../_shared/booking-utils';

@Component({
    selector: 'app-booking-form',
    templateUrl: './booking-form.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class BookingFormComponent implements OnInit, OnDestroy {

    @Input() bookingServiceConfig: BookingServiceConfigDto = null!;
    @Input() hasAdminRoleForBooking = false;
    @Input() userId: string | null = null;
    @Input() serviceId: string = null!;
    @Input() availableItems = new Array<ItemToBookDto>();
    @Input() tariff: TariffDto = null!;
    @Input() selectedItemId: string | null = null;

    @Input()
    get selectedDate(): Date | null {
        return this._selectedDate;
    }

    set selectedDate(date: Date | null) {
        if (date && !this._selectedDate && !this._selectedBooking) {
            this._selectedDate = date;
            this.initFormWithStartDate(date);
        }
    }
    private _selectedDate: Date | null = null;

    @Input()
    get selectedBooking(): BookingDto | null {
        return this._selectedBooking;
    }
    set selectedBooking(booking: BookingDto | null) {
        if (booking && !this.selectedBooking && !this._selectedDate) {
            this.initFormWithBooking(booking);
        }
    }
    private _selectedBooking: BookingDto | null = null;

    @Input()
    get selectedSlot(): BookingSlotDto | null {
        return this._selectedSlot;
    }

    set selectedSlot(slot: BookingSlotDto | null) {
        if (slot) {
            this.initFormWithSlot(slot);
        }
    }

    private _selectedSlot: BookingSlotDto | null = null;

    @Output() closeForm = new EventEmitter<any>();
    @Output() refreshCalendar = new EventEmitter<any>();

    @ViewChild('dateTimeFormComponent') dateTimePicker: DateTimePeriodPickerComponent;
    @ViewChild('recurrentDaysPicker') dayPicker: DaysPickerComponent;
    @ViewChild('itemWithAnswersFormComponent') itemWithAnswersFormComponent: ItemWithQuestionsAnswersFormComponent;
    @ViewChild('userVisitorSelectorComponent') userVisitorSelectorComponent: UserVisitorSelectorComponent;

    FormUtils = FormUtils;
    ItemToBookAnswerType = ItemToBookAnswerType;
    ReservationType = ReservationType;
    BookingMode = BookingMode;
    DateUtils = DateUtils;

    daysOfWeek = Object.keys(Day);

    bookingForm: FormGroup<BookingForm>

    baseStartDate: NgbDateStruct = null!;
    baseEndDate: NgbDateStruct = null!;
    baseStartTime: NgbTimeStruct = null!;
    baseEndTime: NgbTimeStruct = null!;

    minStartDate: NgbDateStruct = null!;
    maxStartDate: NgbDateStruct | null = null;
    maxDurationInHours: number = null!;

    availablePaymentTypes: PaymentTypes[] = [];

    crtLang = TranslateUtils.defaultLanguage;

    private subscriptions: Subscription[] = [];

    recurrenceDays: Day[] = [];

    constructor(private readonly bookingService: BookingService,
                private readonly translateService: TranslateService,
                private readonly modalService: NgbModal,
                private readonly notificationsService: NotificationsService) {

        this.bookingForm = new FormGroup<BookingForm>({
            reservationType: new FormControl(ReservationType.Booking, Validators.required),
            itemWithAnswer: new UntypedFormGroup({}),
            dateRange: new FormGroup({
                startDate: new FormControl(null, Validators.required),
                endDate: new FormControl(null, Validators.required),
                startTime: new FormControl(null, Validators.required),
                endTime: new FormControl(null, Validators.required),
            }, [FormUtils.dateTimePeriodValidator]),
            isRecurrent: new FormControl(false),
            debtorType: new FormControl(null, Validators.required),
            userId: new FormControl(null, Validators.required),
            visitorContactForm: new UntypedFormGroup({}), // the FormGroup is filled in the child
            comment: new FormControl(null, Validators.maxLength(255)),
            paymentType: new FormControl(null, Validators.required)
        }, {updateOn: 'change'});

        this.visitorContactForm.disable();
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.subscriptions.push(this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
        }));

        this.minStartDate = DateUtils.dateToNgbDateStruct(new Date());

        if (this.bookingServiceConfig.bookingMode === BookingMode.Free && !this.hasAdminRoleForBooking) {
            if (this.bookingServiceConfig.maximumBookingAdvanceTimeInDay) {
                const now = new Date();
                this.maxStartDate = DateUtils.dateToNgbDateStruct(new Date(now.setDate(now.getDate() + this.bookingServiceConfig.maximumBookingAdvanceTimeInDay)));
            }

            if (this.bookingServiceConfig.maxBookingDuration) {
                this.maxDurationInHours = DateUtils.convertValueAndUnitToHours(this.bookingServiceConfig.maxBookingDuration, this.bookingServiceConfig.bookingTimeUnit);
                this.dateRangeForm.addValidators(FormUtils.dateTimeMaxIntervalValidator(this.maxDurationInHours, 'startDate', 'endDate', 'startTime', 'endTime'));
            }
        }

        if (!this.hasAdminRoleForBooking) {
            this.debtorType.disable();
            this.userIdControl.disable();
        }

        if (this.bookingServiceConfig.bookingMode === BookingMode.Slots) {
            this.setReservationType(ReservationType.Booking);
            this.reservationType.markAsPristine();
            this.isRecurrent.disable();
        }

        this.configurePaymentTypes();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    async fetchBooking(bookingId: string): Promise<BookingDto> {
        if (this.hasAdminRoleForBooking) {
            return await firstValueFrom(this.bookingService.getBooking(bookingId));

        } else {
            return await firstValueFrom(this.bookingService.getMyBooking(bookingId));
        }
    }

    selectPaymentType(paymentType: PaymentTypes): void {
        this.paymentType.setValue(paymentType);
    }

    async openSaveBookingConfirmModal(): Promise<void> {
        const booking = new BookingInputDto({
            reservationType: this.reservationType.value,
            itemToBookId: this.itemWithAnswerForm.get('itemToBook').value.id,
            itemAnswers: this.itemWithAnswersFormComponent.getAnswers(),
            serviceId: this.serviceId,
            comment: this.comment.value,
            startDate: DateUtils.ngbDateStructToDate(this.dateRangeForm.value.startDate),
            endDate: DateUtils.ngbDateStructToDate(this.dateRangeForm.value.endDate),
            localStartTime: DateUtils.ngbTimeStructToString(this.dateRangeForm.value.startTime),
            localEndTime: DateUtils.ngbTimeStructToString(this.dateRangeForm.value.endTime),
            recurring: this.isRecurrent.value,
            paymentType: this.paymentType.value,
            days: null
        });

        if (this.isRecurrent.value) {
            booking.days = this.dayPicker.getSelectedDays()
        }

        if (this.reservationType.value !== ReservationType.Blocking) {
            if (this.debtorType.value === DebtorType.User) {
                booking.userId = this.userIdControl.value;
                const userContact = this.userVisitorSelectorComponent.getSelectedUser();
                booking.visitorContact = new VisitorContactDto({
                    firstName : userContact.firstName,
                    lastName: userContact.lastName,
                    email: userContact.email,
                    phone: userContact.phone,
                    street: userContact.street,
                    postalCode: userContact.postalCode,
                    city: userContact.city,
                    country: userContact.country
                });
            } else {
                booking.visitorContact = new VisitorContactDto({
                    firstName : this.visitorContactForm.value.firstname,
                    lastName: this.visitorContactForm.value.lastname,
                    email: this.visitorContactForm.value.email,
                    phone: this.visitorContactForm.value.phone,
                    street: this.visitorContactForm.value.street,
                    postalCode: this.visitorContactForm.value.postalCode,
                    city: this.visitorContactForm.value.city,
                    country: this.visitorContactForm.value.country
                });
            }
        }

        if (this.bookingServiceConfig.bookingMode === BookingMode.Slots) {
            booking.bookingSlotId = this.selectedSlot.id;
        }

        const amount = await this.getAmountToBill(booking);

        const modal = this.modalService.open(BookingFormConfirmationModalComponent, {
            centered: true
        });
        modal.componentInstance.booking = booking;
        modal.componentInstance.amount = amount;
        modal.componentInstance.tariff = this.tariff;
        modal.componentInstance.itemToBook = this.availableItems.find(i => i.id === this.itemWithAnswerForm.get('itemToBook').value.id);
        modal.componentInstance.hasAdminRoleForBooking = this.hasAdminRoleForBooking;
        modal.componentInstance.userId = this.userId;
        modal.componentInstance.selectedBooking = this.selectedBooking;

        modal.result
            .then(result => {
                if (result === 'confirm') {
                    this.refreshCalendar.emit();
                    this.closeForm.emit();
                }
            }, () => { /* catch the rejection */
            });
    }

    setReservationType(reservationType: ReservationType): void {
        this.reservationType.setValue(reservationType);
        this.reservationType.markAsDirty();

        if (reservationType === ReservationType.Blocking) {
            this.userIdControl.disable();
            this.visitorContactForm.disable();
            this.debtorType.disable();
            this.paymentType.disable();

        } else {
            this.debtorType.enable();
            this.paymentType.enable();
        }
    }

    setIsRecurrent(isRecurrent: boolean): void {
        this.isRecurrent.setValue(isRecurrent);

        if (this.bookingServiceConfig.bookingMode === BookingMode.Free && !this.hasAdminRoleForBooking) {
            this.dateRangeForm.clearValidators();

            if (isRecurrent) {
                this.dateRangeForm.setValidators([FormUtils.dateTimePeriodValidator,
                    FormUtils.timePeriodMaxIntervalValidator(this.maxDurationInHours, 'startTime', 'endTime')]);

            } else {
                this.dateRangeForm.setValidators([FormUtils.dateTimePeriodValidator,
                    FormUtils.dateTimeMaxIntervalValidator(this.maxDurationInHours, 'startDate', 'endDate', 'startTime', 'endTime')]);
            }

            this.dateRangeForm.updateValueAndValidity();
        }
    }

    configurePaymentTypes(): void {
        this.availablePaymentTypes = this.tariff.paymentTypes.map(value => value);

        const isVisitor = (this.hasAdminRoleForBooking && this.debtorType.value === DebtorType.Visitor)
            || (!this.hasAdminRoleForBooking && !this.userId);

        TariffUtils.removeUnavailablePaymentTypes(this.availablePaymentTypes, this.hasAdminRoleForBooking, isVisitor);

        if (this.availablePaymentTypes.length === 0) {
            this.notificationsService.warning({
                title: 'epayment.notifications.warningBookingTitle',
                message: 'epayment.notifications.noPaymentType'
            });

        } else if (this.availablePaymentTypes.length === 1) {
            this.paymentType.setValue(this.availablePaymentTypes[0]);
        }
    }

    private initFormWithStartDate(date: Date): void {
        if (this.bookingServiceConfig.bookingMode === BookingMode.Free) {
            if (DateUtils.isTodayInThePast(date)) {
                date = DateUtils.dateRoundedToNextThirtyMinutes(new Date());
            }
            this._selectedDate = date;

            this.baseStartDate = DateUtils.dateToNgbDateStruct(date);
            this.baseStartTime = DateUtils.dateToNgbTimeStruct(date);

            const endDate = DateUtils.getEndDateBasedOnBookingDuration(date,
                this.bookingServiceConfig.bookingTimeUnit,
                this.bookingServiceConfig.initialBookingDuration);

            this.baseEndDate = DateUtils.dateToNgbDateStruct(endDate);
            this.baseEndTime = DateUtils.dateToNgbTimeStruct(endDate);
        }
    }

    private async initFormWithSlot(slot: BookingSlotDto): Promise<void> {
        this.selectedItemId = slot.itemToBookId;

        if (slot.booking) {
            const selectedBooking = await this.fetchBooking(slot.booking.id);

            this.debtorType.disable();
            this.paymentType.disable();

            if (!selectedBooking.userId) {
                this.visitorContactForm.enable();
                this.userIdControl.disable();
            }

            this.comment.setValue(selectedBooking.comment);

            this._selectedSlot = slot;
            this._selectedBooking = selectedBooking;
        }

        this._selectedSlot = slot;
        this.setBasePeriod(this.selectedSlot.date,
            this.selectedSlot.date,
            this.selectedSlot.startTime,
            this.selectedSlot.endTime);

        if (this.dateTimePicker) {
            this.dateTimePicker.updatePeriod(this.baseStartDate,
                this.baseEndDate,
                this.baseStartTime,
                this.baseEndTime);
        }

        // We should be able to save the form if we change the slot of a booking
        if (!this.bookingForm.invalid) {
            this.bookingForm.markAsDirty();
        }
    }

    private async initFormWithBooking(booking: BookingDto): Promise<void> {
        this.selectedItemId = booking.itemToBook.id;

        this.setReservationType(booking.reservationType);
        this.reservationType.disable();

        this.paymentType.disable();
        this.debtorType.disable();

        if (!booking.userId) {
            this.visitorContactForm.enable();
            this.userIdControl.disable();
        }

        this.comment.setValue(booking.comment);

        const initialBooking = await this.fetchBooking(booking.id);

        // Directly open the form in case of booking moved in free calendar
        if (!BookingUtils.areBookingStartingTogether(booking, initialBooking)) {
            this.dateRangeForm.markAsDirty();
        }

        // The min start date for recurring event can be in the past
        if (booking.recurring) {
            this.minStartDate = DateUtils.dateToNgbDateStruct(booking.startDate);
        }

        this._selectedBooking = booking;

        this.setBasePeriod(this.selectedBooking.startDate,
            this.selectedBooking.endDate,
            this.selectedBooking.localStartTime,
            this.selectedBooking.localEndTime);

        this.setIsRecurrent(booking.recurring);

        if (this.selectedBooking.days) {
            this.recurrenceDays = [...this.selectedBooking.days]
        }
    }

    async getAmountToBill(booking: BookingInputDto): Promise<number> {
        let price: number = -1;

        if (this.selectedBooking) {
            price = await firstValueFrom(this.bookingService.getPriceOfBookingChange(
                this.selectedBooking.id,
                booking
            ));
        } else {
            price = await firstValueFrom(this.bookingService.getPriceOfNewBooking(
                booking
            ));
        }

        return price
    }

    private setBasePeriod(startDate: Date, endDate: Date, localStartTime: string, localEndTime: string) {
        this.baseStartDate = DateUtils.dateToNgbDateStruct(startDate);
        this.baseEndDate = DateUtils.dateToNgbDateStruct(endDate);

        const startTime = localStartTime.split(':');
        this.baseStartTime = {hour: +startTime[0], minute: +startTime[1], second: +startTime[2]};

        const endTime = localEndTime.split(':');
        this.baseEndTime = {hour: +endTime[0], minute: +endTime[1], second: +endTime[2]};
    }

    get reservationType(): FormControl<ReservationType> {
        return this.bookingForm.get('reservationType') as FormControl<ReservationType>;
    }

    get itemWithAnswerForm(): FormGroup<ItemWithAnswerForm> {
        return this.bookingForm.get('itemWithAnswer') as FormGroup<ItemWithAnswerForm>;
    }

    get dateRangeForm(): FormGroup<DateRangeForm> {
        return this.bookingForm.get('dateRange') as FormGroup<DateRangeForm>;
    }

    get isRecurrent(): FormControl<boolean> {
        return this.bookingForm.get('isRecurrent') as FormControl<boolean>;
    }

    get debtorType(): FormControl<DebtorType> {
        return this.bookingForm.get('debtorType') as FormControl<DebtorType>;
    }

    get userIdControl(): FormControl<string | null> {
        return this.bookingForm.get('userId') as FormControl<string | null>;
    }

    get visitorContactForm(): FormGroup<ContactForm> {
        return this.bookingForm.get('visitorContactForm') as FormGroup<ContactForm>;
    }

    get comment(): FormControl<string> {
        return this.bookingForm.get('comment') as FormControl<string>;
    }

    get paymentType(): FormControl<PaymentTypes> {
        return this.bookingForm.get('paymentType') as FormControl<PaymentTypes>;
    }
}

interface BookingForm {
    reservationType: FormControl<ReservationType>,
    itemWithAnswer: FormGroup<ItemWithAnswerForm>,
    dateRange: FormGroup<DateRangeForm>,
    isRecurrent: FormControl<boolean>,
    userId: FormControl<string | null>,
    debtorType: FormControl<DebtorType>,
    visitorContactForm: FormGroup<ContactForm>,
    comment: FormControl<string | null>,
    paymentType: FormControl<PaymentTypes>
}

interface DateRangeForm {
    startDate: FormControl<NgbDateStruct>,
    endDate: FormControl<NgbDateStruct>,
    startTime: FormControl<NgbTimeStruct>,
    endTime: FormControl<NgbTimeStruct>
}

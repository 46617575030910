import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormUtils} from '../../../../_shared/form-utils';
import {
    TariffConfigDto,
    TariffConfigUnitDto,
    TariffDto,
    TariffPriceInputDto,
    TariffType
} from '../../../../_services/configuration-services';
import {CustomerConfigService} from '../../../../_shared/customer-config-service';
import {FormArray, FormControl, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-tariff-prices-management',
    templateUrl: './tariff-prices-management.component.html'
})
export class TariffPricesManagementComponent implements OnInit, OnDestroy, OnChanges {

    @Input() parentForm: UntypedFormGroup = null!;

    @Input() tariff: TariffDto = null!;
    @Input() tariffConfig: TariffConfigDto = null!;
    @Input() canUserChooseUnit = false;
    @Input() containsMultipleTariffs = false;
    @Input() isDefaultTariff = false;
    @Input() isNightRate = false;

    pricesForm: FormGroup<TariffPricesForm> = null!;

    availableConfigUnits: TariffConfigUnitDto[] = [];
    availableSecondConfigUnits: TariffConfigUnitDto[] = [];
    availableThirdConfigUnits: TariffConfigUnitDto[] = [];

    FormUtils = FormUtils;
    TariffType = TariffType;

    constructor(public readonly customerConfigService: CustomerConfigService) {

        this.pricesForm = new FormGroup<TariffPricesForm>({
            priceByUnit: new FormControl<number | null>(null, [Validators.required, Validators.min(0)]),
            unit: new FormControl<TariffConfigUnitDto | null>(null, [Validators.required]),
            secondPriceByUnit: new FormControl<number | null>(null, [Validators.required, Validators.min(0)]),
            secondUnit: new FormControl<TariffConfigUnitDto | null>(null, [Validators.required]),
            thirdPriceByUnit: new FormControl<number | null>(null, [Validators.required, Validators.min(0)]),
            thirdUnit: new FormControl<TariffConfigUnitDto | null>(null, [Validators.required]),
            units: new FormArray<FormControl<TariffConfigUnitDto | null>>([], [FormUtils.allRequiredValidator]),
            prices: new FormArray<FormControl<number | null>>([], [FormUtils.allRequiredValidator]),
            customUnitFR: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(25)]),
            customUnitDE: new FormControl<string | null>(null, [Validators.maxLength(25)]),
            customUnitEN: new FormControl<string | null>(null, [Validators.maxLength(25)]),
            customUnitIT: new FormControl<string | null>(null, [Validators.maxLength(25)])
        });
    }

    ngOnInit(): void {
        this.adaptFormDependingTariffConfig(this.tariffConfig);

        if (this.tariff) {
            this.fillPricesForm(this.tariff);
        }

        // Link to the parent form
        const formGroupName = `prices_${this.isNightRate ? 'night' : 'day'}`
        this.parentForm.addControl(formGroupName, this.pricesForm);
    }

    ngOnDestroy(): void {
        this.parentForm.removeControl(`prices_${this.isNightRate ? 'night' : 'day'}`);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['tariffConfig'].currentValue != null && this.pricesForm) {
            this.adaptFormDependingTariffConfig(changes['tariffConfig'].currentValue);
        }
    }

    adaptFormDependingTariffConfig(tariffConfig: TariffConfigDto | null): void {
        const selectedTariffConfig = tariffConfig;

        if (!this.isDefaultTariff) {
            this.customUnitFR.disable();
            this.customUnitDE.disable();
            this.customUnitEN.disable();
            this.customUnitIT.disable();

        } else {
            this.customUnitFR.enable();
            this.customUnitDE.enable();
            this.customUnitEN.enable();
            this.customUnitIT.enable();
        }

        if (selectedTariffConfig.tariffConfigUnits.length > 0) {

            if (this.canUserChooseUnit) {
                this.initUnitChoicesFields(selectedTariffConfig);

                this.unit.disable();
                this.priceByUnit.disable();
                this.secondUnit.disable();
                this.secondPriceByUnit.disable();
                this.thirdUnit.disable();
                this.thirdPriceByUnit.disable();

            } else if (selectedTariffConfig.tariffType === TariffType.ConsumptionAndDuration || // Manage two units
                selectedTariffConfig.tariffType === TariffType.FlatAndConsumption ||
                selectedTariffConfig.tariffType === TariffType.FlatAndDuration) {

                this.initDoubleUnitFields(selectedTariffConfig);

                this.units.disable();
                this.prices.disable();
                this.thirdUnit.disable();
                this.thirdPriceByUnit.disable();

            } else if (selectedTariffConfig.tariffType === TariffType.FlatAndConsumptionAndDuration) { // Manage three units
                this.initThirdUnitFields(selectedTariffConfig);

                this.unit.disable();
                this.units.disable();
                this.prices.disable();

            } else { // Manage single unit
                this.initSingleUnitField(selectedTariffConfig, this.tariff);

                this.secondUnit.disable();
                this.secondPriceByUnit.disable();
                this.thirdUnit.disable();
                this.thirdPriceByUnit.disable();
                this.units.disable();
                this.prices.disable();
            }

            if (selectedTariffConfig.tariffType !== TariffType.FlatAndConsumption &&
                selectedTariffConfig.tariffType !== TariffType.FlatAndDuration &&
                selectedTariffConfig.tariffType !== TariffType.FlatAndConsumptionAndDuration) {
                this.customUnitFR.disable();
                this.customUnitDE.disable();
                this.customUnitEN.disable();
                this.customUnitIT.disable();
            }

        } else {

            this.unit.reset();
            this.unit.disable();
            this.secondUnit.disable();
            this.secondPriceByUnit.disable();
            this.thirdUnit.disable();
            this.thirdPriceByUnit.disable();
            this.units.disable();
            this.prices.disable();
        }

        this.tariffConfig = selectedTariffConfig;
    }

    fillPricesForm(tariff: TariffDto): void {
        const prices = tariff.tariffPrices.filter(p => p.nightRate === this.isNightRate);

        if (prices.length > 0) {
            this.priceByUnit.patchValue(prices[0].priceByUnit);
        }

        if (prices.length > 1) {
            this.secondPriceByUnit.patchValue(prices[1].priceByUnit);
        }

        if (prices.length > 2) {
            this.thirdPriceByUnit.patchValue(prices[2].priceByUnit);
        }

        this.customUnitFR.patchValue(this.tariff.customUnitFR);
        this.customUnitDE.patchValue(this.tariff.customUnitDE);
        this.customUnitIT.patchValue(this.tariff.customUnitIT);
        this.customUnitEN.patchValue(this.tariff.customUnitEN);
    }

    getTariffPricesFromPricesForm(): TariffPriceInputDto[] {
        const tariffPrices: TariffPriceInputDto[] = [];

        if (this.units.enabled) { // Multiple units
            for (let i = 0; i < this.units.value.length; i++) {
                if (this.prices.value[i] !== null) {
                    tariffPrices.push(new TariffPriceInputDto({
                        nightRate: this.isNightRate,
                        priceByUnit: this.prices.value[i],
                        tariffConfigUnitId: this.units.value[i].id
                    }));
                }
            }

        } else if (this.secondPriceByUnit.enabled) { // Double units
            tariffPrices.push(
                new TariffPriceInputDto({
                    nightRate: this.isNightRate,
                    priceByUnit: this.priceByUnit.value,
                    tariffConfigUnitId: this.unit.value?.id
                }),
                new TariffPriceInputDto({
                    nightRate: this.isNightRate,
                    priceByUnit: this.secondPriceByUnit.value,
                    tariffConfigUnitId: this.secondUnit.value.id
                }));

            if (this.thirdPriceByUnit.enabled) { // Triple units
                tariffPrices.push(
                    new TariffPriceInputDto({
                        nightRate: this.isNightRate,
                        priceByUnit: this.thirdPriceByUnit.value,
                        tariffConfigUnitId: this.thirdUnit.value.id
                    })
                );
            }

        } else if (this.unit.value) { // Single unit
            tariffPrices.push(
                new TariffPriceInputDto({
                    nightRate: this.isNightRate,
                    priceByUnit: this.priceByUnit.value,
                    tariffConfigUnitId: this.unit.value.id
                }));

        } else { // Single unit
            tariffPrices.push(
                new TariffPriceInputDto({
                    nightRate: this.isNightRate,
                    priceByUnit: this.priceByUnit.value,
                    tariffConfigUnitId: null
                }));
        }

        return tariffPrices;
    }

    private initSingleUnitField(selectedTariffConfig: TariffConfigDto, tariff: TariffDto | null): void {
        this.availableConfigUnits = selectedTariffConfig.tariffConfigUnits;
        const selectedUnit = this.availableConfigUnits.find(u => u.id === tariff?.tariffPrices[0]?.tariffConfigUnitId);

        this.unit.patchValue(selectedUnit ?? this.availableConfigUnits[0]);

        if (!this.isDefaultTariff || this.containsMultipleTariffs) {
            this.unit.disable();

        } else {
            this.unit.enable();
        }

        // Use Global tariff to ensure we fill the form only for update mode
        this.priceByUnit.patchValue(this.tariff?.tariffPrices[0]?.priceByUnit);
    }

    private initDoubleUnitFields(selectedTariffConfig: TariffConfigDto): void {

        // Set of two enum names
        const unitEnumNames = Array.from(new Set(selectedTariffConfig.tariffConfigUnits.map(u => u.unitEnumName)));

        // Use Global tariff to ensure we fill the form only for update mode
        let firstPrice = this.tariff?.tariffPrices.find(p => p.tariffConfigUnit?.unitEnumName === unitEnumNames[0]);
        let secondPrice = this.tariff?.tariffPrices.find(p => p.tariffConfigUnit?.unitEnumName === unitEnumNames[1]);

        let firstConfigUnit: TariffConfigUnitDto = null!;
        let secondConfigUnit: TariffConfigUnitDto = null!;

        if (selectedTariffConfig.tariffType == TariffType.FlatAndDuration || selectedTariffConfig.tariffType == TariffType.FlatAndConsumption) {

            // First price is the flat price, and does not have a unit
            firstPrice = this.tariff?.tariffPrices.find(p => p.tariffConfigUnit?.unitEnumName === null);
            secondPrice = this.tariff?.tariffPrices.find(p => p.tariffConfigUnit?.unitEnumName === unitEnumNames[0]);

            this.availableConfigUnits = [];
            this.availableSecondConfigUnits = selectedTariffConfig.tariffConfigUnits.filter(u => u.unitEnumName === unitEnumNames[0]);

        } else {
            this.availableConfigUnits = selectedTariffConfig.tariffConfigUnits.filter(u => u.unitEnumName === unitEnumNames[0]);
            this.availableSecondConfigUnits = selectedTariffConfig.tariffConfigUnits.filter(u => u.unitEnumName === unitEnumNames[1]);
        }

        // Display unit select fields
        if (this.availableConfigUnits.length > 0) {
            firstConfigUnit = this.availableConfigUnits[0];
            if (firstPrice) {
                firstConfigUnit = this.availableConfigUnits
                        .find(u => u.unitEnumName === firstPrice.tariffConfigUnit.unitEnumName
                            && u.unitEnumValue === firstPrice.tariffConfigUnit.unitEnumValue)
                    ?? this.availableConfigUnits[0];
            }
        }

        secondConfigUnit = this.availableSecondConfigUnits[0];
        if (secondPrice) {
            secondConfigUnit = this.availableSecondConfigUnits
                    .find(u => u.unitEnumName === secondPrice.tariffConfigUnit.unitEnumName
                        && u.unitEnumValue === secondPrice.tariffConfigUnit.unitEnumValue)
                ?? this.availableSecondConfigUnits[0];
        }

        this.unit.patchValue(firstConfigUnit);
        this.secondUnit.patchValue(secondConfigUnit);

        if (!this.isDefaultTariff || this.containsMultipleTariffs) {

            this.unit.disable();
            this.secondUnit.disable();

        } else {

            if (selectedTariffConfig.tariffType == TariffType.FlatAndDuration || selectedTariffConfig.tariffType == TariffType.FlatAndConsumption) {
                this.unit.disable();
            } else {
                this.unit.enable();
            }

            this.secondUnit.enable();
        }

        this.priceByUnit.patchValue(firstPrice?.priceByUnit);
        this.secondPriceByUnit.patchValue(secondPrice?.priceByUnit);
        this.secondPriceByUnit.enable({onlySelf: true, emitEvent: false});
    }

    private initThirdUnitFields(selectedTariffConfig: TariffConfigDto): void {
        // Set of three enum names
        const unitEnumNames = Array.from(new Set(selectedTariffConfig.tariffConfigUnits.map(u => u.unitEnumName)));

        // Use Global tariff to ensure we fill the form only for update mode
        const firstPrice = this.tariff?.tariffPrices.find(p => p.tariffConfigUnit?.unitEnumName === null);
        const secondPrice = this.tariff?.tariffPrices.find(p => p.tariffConfigUnit?.unitEnumName === unitEnumNames[0]);
        const thirdPrice = this.tariff?.tariffPrices.find(p => p.tariffConfigUnit?.unitEnumName === unitEnumNames[1]);

        let firstConfigUnit: TariffConfigUnitDto = null!;
        let secondConfigUnit: TariffConfigUnitDto = null!;
        let thirdConfigUnit: TariffConfigUnitDto = null!;

        this.availableConfigUnits = [];
        this.availableSecondConfigUnits = selectedTariffConfig.tariffConfigUnits.filter(u => u.unitEnumName === unitEnumNames[0]);
        this.availableThirdConfigUnits = selectedTariffConfig.tariffConfigUnits.filter(u => u.unitEnumName === unitEnumNames[1]);

        secondConfigUnit = this.availableSecondConfigUnits[0];
        if (secondPrice) {
            secondConfigUnit = this.availableSecondConfigUnits
                    .find(u => u.unitEnumName === secondPrice.tariffConfigUnit.unitEnumName
                        && u.unitEnumValue === secondPrice.tariffConfigUnit.unitEnumValue)
                ?? this.availableSecondConfigUnits[0];
        }

        thirdConfigUnit = this.availableThirdConfigUnits[0];
        if (thirdPrice) {
            thirdConfigUnit = this.availableThirdConfigUnits.find(u => u.unitEnumName === thirdPrice.tariffConfigUnit.unitEnumName) ?? this.availableThirdConfigUnits[0];
        }

        this.unit.patchValue(firstConfigUnit);
        this.secondUnit.patchValue(secondConfigUnit);
        this.thirdUnit.patchValue(thirdConfigUnit);

        if (!this.isDefaultTariff || this.containsMultipleTariffs) {
            this.unit.disable();
            this.secondUnit.disable();
            this.thirdUnit.disable();

        } else {
            this.unit.enable();
            this.secondUnit.enable();
            this.thirdUnit.enable();
        }

        this.priceByUnit.patchValue(firstPrice?.priceByUnit);
        this.secondPriceByUnit.patchValue(secondPrice?.priceByUnit);
        this.secondPriceByUnit.enable({onlySelf: true, emitEvent: false});
        this.thirdPriceByUnit.patchValue(thirdPrice?.priceByUnit);
        this.thirdPriceByUnit.enable({onlySelf: true, emitEvent: false});
    }

    private initUnitChoicesFields(selectedTariffConfig: TariffConfigDto): void {
        this.units.clear();
        this.prices.clear();

        selectedTariffConfig.tariffConfigUnits.forEach(u => {
            this.units.push(new FormControl<TariffConfigUnitDto | null>(u));
            // Use Global tariff to ensure we fill the form only for update mode
            this.prices.push(new FormControl<number | null>(this.tariff?.tariffPrices.find(p => p.tariffConfigUnitId === u.id)?.priceByUnit));
        });
    }

    get priceByUnit(): FormControl<number | null> {
        return this.pricesForm.get('priceByUnit') as FormControl<number | null>;
    }

    get unit(): FormControl<TariffConfigUnitDto | null> {
        return this.pricesForm.get('unit') as FormControl<TariffConfigUnitDto | null>;
    }

    get secondPriceByUnit(): FormControl<number | null> {
        return this.pricesForm.get('secondPriceByUnit') as FormControl<number | null>;
    }

    get secondUnit(): FormControl<TariffConfigUnitDto | null> {
        return this.pricesForm.get('secondUnit') as FormControl<TariffConfigUnitDto | null>;
    }

    get thirdPriceByUnit(): FormControl<number | null> {
        return this.pricesForm.get('thirdPriceByUnit') as FormControl<number | null>;
    }

    get thirdUnit(): FormControl<TariffConfigUnitDto | null> {
        return this.pricesForm.get('thirdUnit') as FormControl<TariffConfigUnitDto | null>;
    }

    get customUnitFR(): FormControl<string | null> {
        return this.pricesForm.get('customUnitFR') as FormControl<string | null>;
    }

    get customUnitDE(): FormControl<string | null> {
        return this.pricesForm.get('customUnitDE') as FormControl<string | null>;
    }

    get customUnitIT(): FormControl<string | null> {
        return this.pricesForm.get('customUnitIT') as FormControl<string | null>;
    }

    get customUnitEN(): FormControl<string | null> {
        return this.pricesForm.get('customUnitEN') as FormControl<string | null>;
    }

    get units(): FormArray<FormControl<TariffConfigUnitDto | null>> {
        return this.pricesForm.get('units') as FormArray<FormControl<TariffConfigUnitDto | null>>;
    }

    get prices(): FormArray<FormControl<number | null>> {
        return this.pricesForm.get('prices') as FormArray<FormControl<number | null>>;
    }

}

export interface TariffPricesForm {
    priceByUnit: FormControl<number | null>;
    unit: FormControl<TariffConfigUnitDto | null>;
    secondPriceByUnit: FormControl<number | null>;
    secondUnit: FormControl<TariffConfigUnitDto | null>;
    thirdPriceByUnit: FormControl<number | null>;
    thirdUnit: FormControl<TariffConfigUnitDto | null>;
    units: FormArray<FormControl<TariffConfigUnitDto | null>>;
    prices: FormArray<FormControl<number | null>>;
    customUnitFR: FormControl<string | null>;
    customUnitDE: FormControl<string | null>;
    customUnitEN: FormControl<string | null>;
    customUnitIT: FormControl<string | null>;
}

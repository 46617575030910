<div class="modal-header">

    <h4 class="modal-title" translate="{{ lightZoneSchedule ? 'publicLighting.lightZone.schedule.update' : 'publicLighting.lightZone.schedule.add' }}"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="scheduleControlModeForm">
        <div class="row">

            <!-- Zone -->
            <div class="col-6">
                <label class="form-label">{{ 'services.publicLighting.light.modal.zone' | translate }}*</label>
                <select class="form-control form-select" [formControl]="lightZone">
                    <option *ngFor="let lightZone of lightZones;"
                            [ngValue]="lightZone">{{ lightZone.code }}</option>
                </select>
            </div>

            <!-- Date -->
            <div class="row">
                <div class="col-12">
                    <app-date-period-picker [required]="true"
                                            [periodControl]="dateRange">
                    </app-date-period-picker>
                </div>
            </div>

            <!-- Days -->
            <app-days-picker [form]="scheduleControlModeForm"></app-days-picker>

            <!-- Hours -->
            <div class="col-6">
                <label class="form-label" for="startTime">{{ 'services.availabilityRange.modal.startTime' | translate }}*</label>

                <ngb-timepicker id="startTime" [formControl]="timeFrom"></ngb-timepicker>
                <app-form-error [control]="timeFrom"></app-form-error>
            </div>

            <div class="col-6" *ngIf="this.controlMode.value !== PublicLightingControlMode.Automatic">
                <label class="form-label" for="endTime">{{ 'services.availabilityRange.modal.endTime' | translate }}*</label>
                
                <ngb-timepicker id="endTime" [formControl]="timeTo"></ngb-timepicker>
                <app-form-error [control]="timeTo"></app-form-error>
            </div>

            <app-form-error [control]="timeRange"></app-form-error>

            <!-- Mode -->
            <div class="col-6">
                <label class="form-label" for="controlMode">{{ 'publicLighting.lightZone.table.mode' | translate }}*</label>

                <div>
                    <app-light-zone-mode-selector id="controlMode"
                                                  [selected]="lightZoneSchedule ? lightZoneSchedule.mode : null"
                                                  (selectedMode)="selectedMode($event)">
                    </app-light-zone-mode-selector>
                </div>

                <app-form-error [control]="controlMode"></app-form-error>
            </div>

        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="services.availabilityRange.modal.cancel">
    </button>
    <button class="btn btn-primary"
            (click)="addOrUpdateScheduleControlMode()"
            [disabled]="scheduleControlModeForm.invalid || scheduleControlModeForm.pristine"
            translate="{{ lightZoneSchedule ? 'common.modify' : 'common.add' }}">
    </button>
</div>

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    Day,
    ServiceHourlyUsage,
    ServiceService,
    ServiceStatisticsInputDto,
    StatisticsService
} from '../_services/configuration-services';
import {TranslateUtils} from '../_shared/translate-utils';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../_shared/form-utils';
import {ChartData} from 'chart.js';
import {DateUtils} from '../_shared/date-utils';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from '../_shared/custom-date-formatter';
import {Subscription} from 'rxjs';
import {BaseChartDirective} from 'ng2-charts';

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateFormatter}
    ]
})
export class StatisticsComponent implements OnInit, OnDestroy {

    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    filter: {
        formGroup: UntypedFormGroup,
        servicesDropdownList: any[]
    } = { formGroup: null, servicesDropdownList: []};

    statisticsLoaded = false;
    private fullDataToDisplayInChart = [];

    barChartData: ChartData<'bar'> = {
        labels: [],
        datasets: []
    };

    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription;

    constructor(private readonly serviceService: ServiceService,
                private readonly statisticsService: StatisticsService,
                private readonly translateService: TranslateService) {

        this.filter.formGroup = new UntypedFormGroup({
                period: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
                service: new UntypedFormControl(null, Validators.required)
            },
            {updateOn: 'change'});
    }

    ngOnInit(): void {
        this.loadServices();

        this.crtLang = this.translateService.currentLang;

        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;

                    // Sort the Service dropdown list
                    this.filter.servicesDropdownList.sort((a, b) => a['name' + this.crtLang.toUpperCase()].localeCompare(b['name' + this.crtLang.toUpperCase()]));

                    // Reload the Chart labels
                    this.reloadChart();
                }
            });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    loadServiceStatisticsData(): void {
        if (this.filter.formGroup.invalid) {
            return;
        }

        const period = this.period.value;

        const dateFrom = DateUtils.ngbDateStructToDate(period.from);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(period.to);
        dateTo?.setHours(23, 59, 59, 59);

        this.statisticsService.serviceHourlyUsageByDaysOfWeek(new ServiceStatisticsInputDto({
           serviceId: this.service.value,
           dateFrom: dateFrom,
           dateTo: dateTo
        })).pipe().subscribe(stats => {
            this.processData(stats.usageData);
        });
    }

    processData(dailyUsageData: ServiceHourlyUsage[]): void {
        // Add data for each hour of each day (0 or the data received from API)
        this.fullDataToDisplayInChart = [];

        let stillDataToInsert = dailyUsageData.length > 0;
        let dailyUsageDataIdx = 0;
        for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
            for (let hourOfDay = 0; hourOfDay < 24; hourOfDay++) {

                if (stillDataToInsert) {
                    let nextData = dailyUsageData[dailyUsageDataIdx];

                    if (nextData.hourOfDay === hourOfDay && nextData.dayOfWeek === dayOfWeek) {
                        this.fullDataToDisplayInChart.push(nextData);
                        dailyUsageDataIdx++;
                        stillDataToInsert = dailyUsageDataIdx < dailyUsageData.length;

                    } else {
                        this.fullDataToDisplayInChart.push(new ServiceHourlyUsage({
                            transactionsCount: 0,
                            dayOfWeek: dayOfWeek,
                            hourOfDay: hourOfDay
                        }));
                    }

                } else {
                    this.fullDataToDisplayInChart.push(new ServiceHourlyUsage({
                        transactionsCount: 0,
                        dayOfWeek: dayOfWeek,
                        hourOfDay: hourOfDay
                    }));
                }
            }
        }

        this.reloadChart();
    }

    private loadServices(): void {
        this.serviceService.getServiceNames().pipe().subscribe(res => {
            this.filter.servicesDropdownList = [];
            const serviceNames = res.sort((a, b) => a['name' + this.crtLang.toUpperCase()].localeCompare(b['name' + this.crtLang.toUpperCase()]));
            serviceNames.forEach(r => this.filter.servicesDropdownList.push({
                serviceId: r.serviceId,
                nameFR: r.nameFR,
                nameDE: r.nameDE,
                nameEN: r.nameEN,
                nameIT: r.nameIT
            }));
        });
    }

    private reloadChart(): void {
        if (this.periodFrom.value) {
            // We can calculate the average between from and to (or today)
            const weekdaysOccurences = DateUtils.getWeekdaysCountsBetweenTwoDates(
                DateUtils.ngbDateStructToDate(this.periodFrom.value), DateUtils.ngbDateStructToDate(this.periodTo.value));

            this.fillChartData(
                this.translateService.instant('statistics.transactionAverage'),
                this.fullDataToDisplayInChart.map(d => + (d.transactionsCount / weekdaysOccurences[d.dayOfWeek - 1]).toFixed(2)));

        } else {
            // Display the sum of transactions
            this.fillChartData(
                this.translateService.instant('statistics.transactionCount'),
                this.fullDataToDisplayInChart.map(d => + d.transactionsCount));
        }

        this.statisticsLoaded = true;
    }

    private fillChartData(barTitle: string, data: number[]): void {
        this.barChartData.datasets[0] = {
            data: data,
            label: barTitle,
            backgroundColor: '#003a7c',
            hoverBackgroundColor: '#4b68b1'
        };

        this.barChartData.labels = this.fullDataToDisplayInChart.map(d => this.formatLabelsForHoursAndDayOfWeeks(d.dayOfWeek, d.hourOfDay));

        this.chart?.update();
    }

    private formatLabelsForHoursAndDayOfWeeks(dayOfWeek: number, hourOfDay: number): string {
        const timeStr = DateUtils.formatTime('' + hourOfDay + ':00');

        if (hourOfDay === 0) {
            switch (dayOfWeek) {
                case 1:
                    return this.translateService.instant('common.day.' + Day.Monday) + ' ' + timeStr;
                case 2:
                    return this.translateService.instant('common.day.' + Day.Tuesday) + ' ' + timeStr;
                case 3:
                    return this.translateService.instant('common.day.' + Day.Wednesday) + ' ' + timeStr;
                case 4:
                    return this.translateService.instant('common.day.' + Day.Thursday) + ' ' + timeStr;
                case 5:
                    return this.translateService.instant('common.day.' + Day.Friday) + ' ' + timeStr;
                case 6:
                    return this.translateService.instant('common.day.' + Day.Saturday) + ' ' + timeStr;
                case 7:
                    return this.translateService.instant('common.day.' + Day.Sunday) + ' ' + timeStr;
                default:
                    return null;
            }
        } else {
            return timeStr;
        }
    }

    get period(): UntypedFormGroup {
        return this.filter.formGroup.get('period') as UntypedFormGroup;
    }

    get periodFrom(): UntypedFormControl {
        return this.period.get('from') as UntypedFormControl;
    }

    get periodTo(): UntypedFormControl {
        return this.period.get('to') as UntypedFormControl;
    }

    get service(): UntypedFormControl {
        return this.filter.formGroup.get('service') as UntypedFormControl;
    }
}

<div class="content-container bg-white" [appHasAnyRole]="[AppRoles.admin, AppRoles.alarmManager]">

    <h1 translate="alarms.management.title"></h1>

    <form [formGroup]="filter.formGroup"
          (keyup.enter)="applyFilters()">

        <div class="row">

            <!-- Classification -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="alarms.management.filter.label.classification"></label>
                <select class="form-control form-select" id="classification" name="classification"
                        formControlName="classification">
                    <option [ngValue]="null">-</option>
                    <option *ngFor="let classification of filter.classificationsDropdownList"
                            [ngValue]="classification">{{'common.alarmClassification.' + classification | translate}}</option>
                </select>
            </div>

            <!-- Appeared Date -->
            <div class="default-filter-form-field">
                <app-date-period-picker inputLabel="alarms.management.filter.label.appearedDate"
                                        [periodControl]="period"></app-date-period-picker>
            </div>

            <!-- State -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="alarms.management.filter.label.state"></label>
                <select class="form-control form-select" id="state" name="state"
                        formControlName="state">
                    <option [ngValue]="null">-</option>
                    <option *ngFor="let state of filter.statesDropdownList"
                            [ngValue]="state">{{'common.alarmState.' + state | translate}}</option>
                </select>
            </div>

            <!-- Designation -->
            <div class="default-filter-form-field">
                <label class="form-label" for="designation" translate="alarms.management.filter.label.designation"></label>
                <input class="form-control" id="designation" type="text" name="designation"
                       formControlName="designation"
                       placeholder="{{'alarms.management.filter.label.designation' | translate}}"
                       maxlength="50" pattern="{{textInputPattern}}" />
            </div>

            <!-- Location -->
            <div class="default-filter-form-field">
                <label class="form-label" for="location" translate="alarms.management.filter.label.location"></label>
                <input class="form-control" id="location" type="text" name="location"
                       formControlName="location"
                       placeholder="{{'alarms.management.filter.label.location' | translate}}"
                       maxlength="50" pattern="{{textInputPattern}}" />
            </div>

        </div>

        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   (click)="clearFilter()">

            <input class="btn btn-primary" type="button"
                   [value]="'common.filter' | translate"
                   (click)="applyFilters()"
                   [disabled]="filter.formGroup.invalid">
        </div>

    </form>

    <!-- Table -->
    <ngx-datatable #devicesTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="alarms.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   rowHeight="50"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="alarms.rowCount"
                   [sorts]="[{prop: 'appearedDateTime', dir: 'desc'}]"
                   [messages]="{
                       totalMessage: 'common.datatable.total' | translate
                   }"
                   [offset]="crtPage"
                   [limit]="alarms.pageSize"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [scrollbarH]="true">

        <ngx-datatable-column [width]="100" prop="alarmClassification" sortable="true"
                              name="{{'alarms.management.table.classification' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ 'common.alarmClassification.' + row.alarmClassification | translate }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="125" prop="appearedDateTime" sortable="true"
                              name="{{'alarms.management.table.appearedDate' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.appearedDateTime | date:dateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="100" prop="alarmState" sortable="true"
                              name="{{'alarms.management.table.state' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span class="dot white-border"
                      [class.red]="row.alarmState === AlarmState.Active"
                      [class.yellow]="row.alarmState === AlarmState.InProgress"
                      [class.green]="row.alarmState === AlarmState.Solved"></span>
                {{ 'common.alarmState.' + row.alarmState | translate }}
            </ng-template>

        </ngx-datatable-column>

        <ngx-datatable-column [width]="175" prop="designation{{crtLang.toUpperCase()}}" sortable="true"
                              name="{{'alarms.management.table.designation' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="location" sortable="true"
                              name="{{'alarms.management.table.location' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="110" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <button class="btn btn-link btn-sm"
                        (click)="openAlarmDetail(row)"
                        title="{{'common.tooltip.open' | translate}}">
                    <fa-icon [icon]="icons.open"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm" *ngIf="row.alarmState === AlarmState.Active"
                        (click)="setAlarmInProgress(row)"
                        title="{{ 'common.tooltip.inProgress' | translate }}">
                    <fa-icon [icon]="icons.inProgress"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm" *ngIf="row.alarmState !== AlarmState.Solved"
                        (click)="releaseAlarm(row)"
                        title="{{ 'common.tooltip.release' | translate }}">
                    <fa-icon [icon]="icons.release"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div class="page-count">
                    {{ rowCount }} {{ 'common.datatable.total' | translate }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="devicesTable.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

</div>

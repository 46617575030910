<h3 *ngIf="userId === booking.userId" translate="bookings.viewMyBooking"></h3>
<h3 *ngIf="userId !== booking.userId" translate="bookings.viewExistingBooking"></h3>

<div class="row">
    <legend>
        <!-- Booking on same day -->
        <small class="font-weight-bold" *ngIf="booking.startDate === booking.endDate"
               [translateParams]="{
                            date: booking.startDate | date : DateUtils.dateFormat,
                            startTime: DateUtils.formatTime(booking.localStartTime),
                            endTime: DateUtils.formatTime(booking.localEndTime)
                       }"
               translate="bookings.selectedSlot.date">
        </small>
        <!-- Recurring Booking or Booking on several days -->
        <small class="font-weight-bold" *ngIf="booking.startDate !== booking.endDate"
               [translateParams]="{
                            startDate: booking.startDate | date : DateUtils.dateFormat,
                            endDate: booking.endDate | date : DateUtils.dateFormat,
                            startTime: DateUtils.formatTime(booking.localStartTime),
                            endTime: DateUtils.formatTime(booking.localEndTime)
                       }"
               translate="{{ booking.recurring ? 'bookings.recurrentlyPlanned' : 'bookings.plannedForRange' }}">
        </small>
    </legend>
</div>


<!-- Selected item display -->
<div class="row mt-4">
    <div class="col-12">
        <h4>{{ booking.itemToBook['name' + crtLang.toUpperCase()] }}</h4>

        <div class="text-pre-wrap" *ngIf="booking.itemToBook['description' + crtLang.toUpperCase()]">
            {{ booking.itemToBook['description' + crtLang.toUpperCase()] }}
        </div>
    </div>
</div>

<app-user-visitor-summary [hasAdminRole]="hasAdminRoleForBooking"
                          [visitor]="booking.visitorContact"></app-user-visitor-summary>

<app-user-cards-summary *ngIf="hasAdminRoleForBooking && bookingServiceConfig.displayUserCards && booking.userId"
                        [userId]="booking.userId"></app-user-cards-summary>

<app-user-boats-summary *ngIf="hasAdminRoleForBooking && bookingServiceConfig.displayUserBoats && booking.userId"
                        [userId]="booking.userId"></app-user-boats-summary>

<!-- Answers -->
<div class="row mt-4" *ngIf="questions.length > 0">
    <fieldset class="col-12 questions">
        <legend>{{ 'bookings.questions' | translate }}</legend>

        <div class="row" *ngFor="let question of questions">
            <p class="mb-0"><strong>{{ question['question' + crtLang.toUpperCase()] }}</strong></p>

            <p *ngIf="question.answerType === ItemToBookAnswerType.Boolean; else textAnswer" translate="{{ answers.get(question.id) ? 'common.yes' : 'common.no' }}"></p>
            <ng-template #textAnswer>
                <p>{{ answers.get(question.id) }}</p>
            </ng-template>
        </div>

    </fieldset>
</div>

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppRoles} from '../../app.constants';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {
    AlarmDeviceSearchCriteriaDto,
    AlarmHistorySearchCriteriaDto,
    AlarmLightDto,
    AlarmService,
    AlarmState,
    ISortCriteriaDto, MeasuringPointType, PageableRequestDtoOfAlarmDeviceSearchCriteriaDto,
    PageableRequestDtoOfAlarmHistorySearchCriteriaDto,
    PagedResultDtoOfAlarmHistoryLightDto, RegisterType,
    SortCriteriaDto,
    SortDirection
} from '../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormUtils} from '../../_shared/form-utils';
import {
    faCalendar,
    faExternalLinkAlt,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {AlarmManagementModalComponent} from '../alarm-management/alarm-management-modal/alarm-management-modal.component';
import {NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SearchIotRegisterComponent} from '../../_shared/_components/search-iot-register/search-iot-register.component';
import {SearchMeasuringPointComponent} from '../../_shared/_components/search-measuring-point/search-measuring-point.component';
import {CustomDateFormatter} from '../../_shared/custom-date-formatter';
import {SearchUserComponent} from '../../_shared/_components/search-user/search-user.component';
import {DateUtils} from '../../_shared/date-utils';
import {TranslateUtils} from '../../_shared/translate-utils';

@Component({
    selector: 'app-alarm-history',
    templateUrl: './alarm-history.component.html',
    providers: [
        {provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class AlarmHistoryComponent implements OnInit, OnDestroy {

    @ViewChild('iotRegisterSearch') iotRegisterSearch: SearchIotRegisterComponent;
    @ViewChild('measuringPointSearch') measuringPointSearch: SearchMeasuringPointComponent;
    @ViewChild('userSearch') userSearch: SearchUserComponent;

    AppRoles = AppRoles;
    AlarmState = AlarmState;
    RegisterType = RegisterType;
    MeasuringPointType = MeasuringPointType;

    // Filters
    filter: {
        formGroup: UntypedFormGroup,
    } = {
        formGroup: null,
    };

    textInputPattern = FormUtils.textInputPattern;
    alarmDateTimeFormat = DateUtils.dateTimeWithoutSecondFormat;

    // Icons
    icons = {
        calendar: faCalendar,
        open: faExternalLinkAlt,
        times: faTimes
    };

    // Pagination
    readonly pageSize = 10;
    crtPage = 0;

    // Table content
    alarms: PagedResultDtoOfAlarmHistoryLightDto = new PagedResultDtoOfAlarmHistoryLightDto();

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Desc,
        property: 'releasedDateTime'
    });

    private pageRequest: PageableRequestDtoOfAlarmHistorySearchCriteriaDto;

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(private readonly modalService: NgbModal,
                private readonly translateService: TranslateService,
                private readonly alarmService: AlarmService) {

        this.filter.formGroup = new UntypedFormGroup({
                iotRegister: new UntypedFormControl(''),
                measuringPoint: new UntypedFormControl(''),
                user: new UntypedFormControl(''),
                appearedPeriod: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
                inProgressPeriod: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
                releasedPeriod: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator])
            },
            {updateOn: 'change'});

        this.filter.formGroup.reset();
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.initializeBasePageRequest();

        this.fetchAlarms(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    fetchAlarms(pageableRequest: PageableRequestDtoOfAlarmHistorySearchCriteriaDto): void {
        this.alarmService.searchAlarmHistory(pageableRequest).pipe().subscribe(alarms => {
            this.alarms = alarms;
        });
    }

    openAlarmDetail(alarm: AlarmLightDto): void {
        const modal = this.modalService.open(AlarmManagementModalComponent, {size: 'lg'});
        modal.componentInstance.alarmId = alarm.id;
    }

    applyFilters(): void {
        if (this.filter.formGroup.invalid) {
            return;
        }

        this.crtPage = 0;
        this.initializeBasePageRequest();

        const appearedPeriod = this.appearedPeriod.value;

        const appearedPeriodDateFrom = DateUtils.ngbDateStructToDate(appearedPeriod.from);
        appearedPeriodDateFrom?.setHours(0, 0, 0, 0);
        const appearedPeriodDateTo = DateUtils.ngbDateStructToDate(appearedPeriod.to);
        appearedPeriodDateTo?.setHours(23, 59, 59, 59);

        const inProgressPeriod = this.inProgressPeriod.value;

        const inProgressPeriodDateFrom = DateUtils.ngbDateStructToDate(inProgressPeriod.from);
        inProgressPeriodDateFrom?.setHours(0, 0, 0, 0);
        const inProgressPeriodDateTo = DateUtils.ngbDateStructToDate(inProgressPeriod.to);
        inProgressPeriodDateTo?.setHours(23, 59, 59, 59);

        const releasedPeriod = this.releasedPeriod.value;

        const releasedPeriodDateFrom = DateUtils.ngbDateStructToDate(releasedPeriod.from);
        releasedPeriodDateFrom?.setHours(0, 0, 0, 0);
        const releasedPeriodDateTo = DateUtils.ngbDateStructToDate(releasedPeriod.to);
        releasedPeriodDateTo?.setHours(23, 59, 59, 59);

        this.pageRequest.criteria = new AlarmHistorySearchCriteriaDto({
            iotRegisterId: this.iotRegister.value?.id,
            measuringPointId: this.measuringPoint.value?.id,
            releasedUserId: this.user.value?.id,
            appearedFrom: appearedPeriodDateFrom,
            appearedTo: appearedPeriodDateTo,
            inProgressFrom: inProgressPeriodDateFrom,
            inProgressTo: inProgressPeriodDateTo,
            releasedFrom: releasedPeriodDateFrom,
            releasedTo: releasedPeriodDateTo,
            sortCriteriaDto: this.currentSort,
            language: this.crtLang
        });

        this.fetchAlarms(this.pageRequest);
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;

        this.fetchAlarms(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;

        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = new SortCriteriaDto(sortCriteriaDto);

        this.fetchAlarms(this.pageRequest);
    }

    clearFilter(): void {
        // Clear inputs
        this.filter.formGroup.reset();
        this.iotRegisterSearch.clearInput();
        this.measuringPointSearch.clearInput();
        this.userSearch.clearInput();

        // clear search
        this.initializeBasePageRequest();
        this.fetchAlarms(this.pageRequest);
    }

    private initializeBasePageRequest(): void {
        this.pageRequest = new PageableRequestDtoOfAlarmHistorySearchCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new AlarmHistorySearchCriteriaDto({
            iotRegisterId: null,
            measuringPointId: null,
            releasedUserId: null,
            appearedFrom: null,
            appearedTo: null,
            inProgressFrom: null,
            inProgressTo: null,
            releasedFrom: null,
            releasedTo: null,
            sortCriteriaDto: this.currentSort
        });
    }

    get iotRegister(): UntypedFormControl {
        return this.filter.formGroup.get('iotRegister') as UntypedFormControl;
    }

    get measuringPoint(): UntypedFormControl {
        return this.filter.formGroup.get('measuringPoint') as UntypedFormControl;
    }

    get user(): UntypedFormControl {
        return this.filter.formGroup.get('user') as UntypedFormControl;
    }

    get appearedPeriod(): UntypedFormGroup {
        return this.filter.formGroup.get('appearedPeriod') as UntypedFormGroup;
    }

    get appearedPeriodFrom(): UntypedFormControl {
        return this.appearedPeriod.get('from') as UntypedFormControl;
    }

    get appearedPeriodTo(): UntypedFormControl {
        return this.appearedPeriod.get('to') as UntypedFormControl;
    }

    get inProgressPeriod(): UntypedFormGroup {
        return this.filter.formGroup.get('inProgressPeriod') as UntypedFormGroup;
    }

    get inProgressPeriodFrom(): UntypedFormControl {
        return this.inProgressPeriod.get('from') as UntypedFormControl;
    }

    get inProgressPeriodTo(): UntypedFormControl {
        return this.inProgressPeriod.get('to') as UntypedFormControl;
    }

    get releasedPeriod(): UntypedFormGroup {
        return this.filter.formGroup.get('releasedPeriod') as UntypedFormGroup;
    }

    get releasedPeriodFrom(): UntypedFormControl {
        return this.releasedPeriod.get('from') as UntypedFormControl;
    }

    get releasedPeriodTo(): UntypedFormControl {
        return this.releasedPeriod.get('to') as UntypedFormControl;
    }
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    ContractType,
    EquipmentCategoryDto,
    EquipmentCategoryService,
    EquipmentContractDto,
    EquipmentDto,
    EquipmentInputDto,
    EquipmentLightDto,
    EquipmentService
} from '../../../_services/configuration-services';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {faCalendar, faTimes} from '@fortawesome/free-solid-svg-icons';
import {NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from '../../../_shared/custom-date-formatter';
import {DateUtils} from '../../../_shared/date-utils';
import {FormUtils} from '../../../_shared/form-utils';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {NotificationsService} from '../../../_shared/notifications.service';
import {EquipmentEventModalComponent} from '../equipment-event-modal/equipment-event-modal.component';
import {MaintenanceContractViewComponent} from '../maintenance-contract-view/maintenance-contract-view.component';
import {DocumentManagementTypeEnum} from '../../../_shared/_components/document-management-component/document-management.component';
import {Location} from '@angular/common';

@Component({
    selector: 'app-manage-equipment',
    templateUrl: './manage-equipment.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class ManageEquipmentComponent implements OnInit, OnDestroy {

    @ViewChild('maintenanceContractViewComponent') maintenanceContractView: MaintenanceContractViewComponent;
    @ViewChild('troubleshootingContractViewComponent') troubleshootingContractView: MaintenanceContractViewComponent;

    ContractType = ContractType;
    documentManagementTypeEnum = DocumentManagementTypeEnum;

    equipmentForm: UntypedFormGroup;
    maintenanceForm: UntypedFormGroup;
    troubleshootingForm: UntypedFormGroup;

    maintenanceContract: EquipmentContractDto;
    troubleshootingContract: EquipmentContractDto;

    equipmentId: string | null;
    equipmentCategories: EquipmentCategoryDto[];
    equipmentDto: EquipmentDto;

    icons = {
        calendar: faCalendar,
        close: faTimes
    };

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(
        private readonly confirmService: ConfirmModalService,
        private readonly router: Router,
        private readonly notificationsService: NotificationsService,
        private readonly translateService: TranslateService,
        private readonly modalService: NgbModal,
        private readonly equipmentService: EquipmentService,
        private readonly equipmentCategoryService: EquipmentCategoryService,
        private readonly route: ActivatedRoute,
        private readonly location: Location) {

        this.equipmentForm = new UntypedFormGroup({
            equipmentName: new UntypedFormControl(null, Validators.required),
            equipmentIdNumber: new UntypedFormControl(null),
            equipmentLocation: new UntypedFormControl(null, Validators.required),
            equipmentInstallDate: new UntypedFormControl(null),
            equipmentCategory: new UntypedFormControl(null, Validators.required),
            equipmentBrand: new UntypedFormControl(null),
            equipmentProductName: new UntypedFormControl(null),
            equipmentSupplierName: new UntypedFormControl(null),
            equipmentSupplierContact: new UntypedFormControl(null)
        });

        this.maintenanceForm = new UntypedFormGroup({
            enterprise: new UntypedFormControl(null, Validators.required),
            name: new UntypedFormControl(null, Validators.required),
            phone1: new UntypedFormControl(null, [Validators.required, FormUtils.phoneNumberPatternValidator]),
            phone2: new UntypedFormControl(null, FormUtils.phoneNumberPatternValidator),
            email: new UntypedFormControl(null, [Validators.required, FormUtils.emailValidator]),
        });

        this.troubleshootingForm = new UntypedFormGroup({
            enterprise: new UntypedFormControl(null, Validators.required),
            name: new UntypedFormControl(null, Validators.required),
            phone1: new UntypedFormControl(null, [Validators.required, FormUtils.phoneNumberPatternValidator]),
            phone2: new UntypedFormControl(null, FormUtils.phoneNumberPatternValidator),
            email: new UntypedFormControl(null, [Validators.required, FormUtils.emailValidator]),
        });
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.fetchCategories();

        this.equipmentId = this.route.snapshot.params['id'] ?? null;

        if (!!this.equipmentId) {
            this.equipmentService.getEquipmentById(this.equipmentId).pipe().subscribe(value => {
                this.equipmentDto = value;
                this.maintenanceContract = value.maintenanceContract;
                this.troubleshootingContract = value.troubleshootingContract;

                this.setEquipmentData(value);
            });
        }
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    setEquipmentData(equipmentDto: EquipmentDto): void {
        this.equipmentId = equipmentDto.id;

        this.equipmentName.setValue(equipmentDto.name);
        this.equipmentIdNumber.setValue(equipmentDto.identificationNumber);
        this.equipmentLocation.setValue(equipmentDto.location);
        this.equipmentInstallDate.setValue(DateUtils.dateToNgbDateStruct(equipmentDto.installationDate));
        this.equipmentCategory.setValue(equipmentDto.equipmentCategory.id);
        this.equipmentBrand.setValue(equipmentDto.brand);
        this.equipmentProductName.setValue(equipmentDto.productName);
        this.equipmentSupplierName.setValue(equipmentDto.supplierName);
        this.equipmentSupplierContact.setValue(equipmentDto.supplierContact);

        if (!!this.maintenanceContractView && !!this.troubleshootingContractView) {
            this.maintenanceContractView.updateContractForm(equipmentDto.maintenanceContract);
            this.troubleshootingContractView.updateContractForm(equipmentDto.troubleshootingContract);
        }
    }

    addOrUpdateEquipment(): void {
        let equipment = new EquipmentInputDto({
            name: this.equipmentName.value,
            location: this.equipmentLocation.value,
            identificationNumber: this.equipmentIdNumber.value,
            installationDate: DateUtils.ngbDateStructToDate(this.equipmentInstallDate.value),
            brand: this.equipmentBrand.value,
            productName: this.equipmentProductName.value,
            supplierName: this.equipmentSupplierName.value,
            supplierContact: this.equipmentSupplierContact.value,
            equipmentCategoryId: this.equipmentCategory.value
        });

        if (!!this.equipmentId) {
            this.equipmentService.updateEquipment(this.equipmentId, equipment).pipe().subscribe(equipmentDto => {
                    this.setEquipmentData(equipmentDto);
                    this.notificationsService.success({title: 'equipments.notifications.updateSuccess'});
                }
            );

        } else {
            this.equipmentService.createEquipment(equipment).pipe().subscribe(equipmentDto => {
                    this.notificationsService.success({title: 'equipments.notifications.addSuccess'});
                    this.router.navigate(['../' + equipmentDto.id], {relativeTo: this.route, replaceUrl: true});
                }
            );
        }
    }

    deleteEquipment(): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.cancel'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.equipmentService.deleteEquipment(this.equipmentId).pipe().subscribe(_ => {
                        this.notificationsService.success({title: 'equipments.notifications.deleteSuccess'});
                        this.back();
                    },
                    _ => this.notificationsService.error({title: 'equipments.notifications.deleteError'}));
            }
        });
    }

    openEventModal(): void {
        let modal = this.modalService.open(EquipmentEventModalComponent, {centered: true});
        modal.componentInstance.initialDate = new Date();
        modal.componentInstance.equipmentLight = new EquipmentLightDto({
            id: this.equipmentId,
            name: this.equipmentName.value
        });
    }

    updateContractData($event: ContractType) {
        this.equipmentService.getEquipmentById(this.equipmentId).pipe().subscribe(value => {
            this.maintenanceContract = value.maintenanceContract;
            this.troubleshootingContract = value.troubleshootingContract;
            this.setEquipmentData(value);
            if ($event === ContractType.Troubleshooting) {
                this.maintenanceContractView.populateContractSelect();
            } else {
                this.troubleshootingContractView.populateContractSelect();
            }
        });
    }

    back(): void {
        this.location.back();
    }

    private fetchCategories(): void {
        this.equipmentCategoryService.getAllEquipmentCategories().pipe().subscribe(res => {
            this.equipmentCategories = res.sort((a, b) => a['label' + this.crtLang.toUpperCase()].localeCompare(b['label' + this.crtLang.toUpperCase()]));
        });
    }

    get equipmentName(): UntypedFormControl {
        return this.equipmentForm.get('equipmentName') as UntypedFormControl;
    }

    get equipmentIdNumber(): UntypedFormControl {
        return this.equipmentForm.get('equipmentIdNumber') as UntypedFormControl;
    }

    get equipmentLocation(): UntypedFormControl {
        return this.equipmentForm.get('equipmentLocation') as UntypedFormControl;
    }

    get equipmentInstallDate(): UntypedFormControl {
        return this.equipmentForm.get('equipmentInstallDate') as UntypedFormControl;
    }

    get equipmentCategory(): UntypedFormControl {
        return this.equipmentForm.get('equipmentCategory') as UntypedFormControl;
    }

    get equipmentBrand(): UntypedFormControl {
        return this.equipmentForm.get('equipmentBrand') as UntypedFormControl;
    }

    get equipmentProductName(): UntypedFormControl {
        return this.equipmentForm.get('equipmentProductName') as UntypedFormControl;
    }

    get equipmentSupplierName(): UntypedFormControl {
        return this.equipmentForm.get('equipmentSupplierName') as UntypedFormControl;
    }

    get equipmentSupplierContact(): UntypedFormControl {
        return this.equipmentForm.get('equipmentSupplierContact') as UntypedFormControl;
    }
}

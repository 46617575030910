import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    MapDto,
    MapService, MooringPlaceDto, MooringPlaceLightDto, MooringPlaceService,
} from '../../../_services/configuration-services';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MooringPlaceViewModalComponent} from '../../../_shared/_components/mooring-place-view-modal/mooring-place-view-modal.component';
import {firstValueFrom} from 'rxjs';
import { ManageMapContentComponent, MapContent } from '../manage-map-content/manage-map-content.component';

@Component({
    selector: 'app-edit-mooring-place',
    templateUrl: './edit-mooring-place.component.html'
})
export class EditMooringPlaceComponent implements OnInit {

    @Input() serviceId: string;

    @ViewChild(ManageMapContentComponent) manageMapContent: ManageMapContentComponent = null;

    map: MapDto | null = null;

    mooringPlaces: MooringPlaceLightDto[] = [];
    mooringPlacesMapContent: MapContent[] = [];

    constructor(private readonly mapService: MapService,
                private readonly mooringPlaceService: MooringPlaceService,
                private readonly modalService: NgbModal) {
    }

    async ngOnInit(): Promise<void> {
        this.map = await firstValueFrom(this.mapService.getMap(this.serviceId));
        if (this.map) {
            await this.fetchMooringPlaces();
        }
    }

    async openMooringPlaceModal(mapContent: MapContent): Promise<void> {
        let mooringPlace: MooringPlaceDto | null = null;
        if (mapContent.id) {
            mooringPlace = await firstValueFrom(this.mooringPlaceService.getMooringPlace(mapContent.id));
        }

        const modal = this.modalService.open(MooringPlaceViewModalComponent, {centered: true});
        modal.componentInstance.onlyPlaceInfo = true;
        modal.componentInstance.mooringPlace = mooringPlace;
        modal.componentInstance.mapContent = mapContent;

        modal.result.then(async () => {
            await this.fetchMooringPlaces()
            await this.manageMapContent.resetMapAndStopEditMode(this.mooringPlacesMapContent);
        });
    }

    private async fetchMooringPlaces(): Promise<void> {
        this.mooringPlaces = await firstValueFrom(this.mooringPlaceService.getAllMooringPlaces());
        this.mooringPlacesMapContent = this.mooringPlaces.map(dto => new Object({
                ...dto,
                id: dto.placeId,
                displayName: dto.placeNum
            }) as MapContent);
    }
}


import { Component, OnInit } from '@angular/core';
import {AppRoles} from '../../../app.constants';
import {
    MeasuringPointService,
    SearchMeasuringPointDto,
    SearchMeasuringPointResultDto,
    ServiceDto,
    ServiceService,
    ServiceType
} from '../../../_services/configuration-services';
import {MsalService} from '@azure/msal-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {firstValueFrom} from 'rxjs';
import {RolesService} from '../../../_shared/roles-service';

@Component({
  selector: 'app-energy',
  templateUrl: './energy.component.html',
  styleUrls: ['./energy.component.scss']
})
export class EnergyComponent implements OnInit {

    CockpitView = CockpitView;

    crtLang = TranslateUtils.defaultLanguage;
    appRoles = AppRoles;

    service: ServiceDto;
    userId: string | null;
    serviceId: string;

    // cockpit
    cockpitView: CockpitView;
    userMeasuringPoints: SearchMeasuringPointResultDto[] = [];

  constructor(private readonly authService: MsalService,
              private readonly serviceService: ServiceService,
              private readonly measuringPointService: MeasuringPointService,
              private readonly translateService: TranslateService,
              private readonly rolesService: RolesService,
              private readonly router: Router,
              private readonly route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
      this.crtLang = this.translateService.currentLang;
      this.serviceId = this.route.snapshot.params['serviceId'];

      if (this.serviceId) {
          try {
              if (this.rolesService.hasRole(this.appRoles.admin)) {
                  this.userId = null;
              } else {
                  this.userId = this.authService.instance.getActiveAccount()?.localAccountId ?? null;
              }
              this.service = await firstValueFrom(this.serviceService.getService(this.serviceId));
              if (this.userId) {
                  this.userMeasuringPoints = await this.fetchMeasuringPoint();
              }

              // Cockpit
              if (this.service.serviceConfig.serviceType === ServiceType.Energy) {
                  if (this.rolesService.hasRole(this.appRoles.admin)) {
                      this.cockpitView = CockpitView.State;
                  } else {
                      this.cockpitView = CockpitView.User;
                  }
              }
          } catch (e) {
              const status = (e as any).status;
              if (status === 404) {
                  await this.router.navigate(['/']);
              } else {
                  throw e;
              }
          }
      }
  }

    // region Cockpit
    changeCockpitView(view: CockpitView): void {
        this.cockpitView = view;
    }

    hasRole(role: string): boolean {
        return this.rolesService.hasRole(role);
    }

    private async fetchMeasuringPoint(): Promise<SearchMeasuringPointResultDto[]> {
        if (this.service.serviceConfig.serviceType === ServiceType.Energy && this.userId) {
            return firstValueFrom(this.measuringPointService.searchMeasuringPoints(
                new SearchMeasuringPointDto({
                        serviceId: this.serviceId, serviceUnassignedOnly: false, language: this.crtLang, userId: this.userId, includeMeasuringPointWithHistoricalAssignments: true
                    }
                )));
        }
        return [];
    }

}

enum CockpitView {
    State,
    Consumption,
    User
}

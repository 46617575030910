import {Component, Input, OnInit} from '@angular/core';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateUtils} from "../../../../_shared/translate-utils";
import {
    ItemToBookAnswerType, ItemToBookQuestionDto, ItemToBookQuestionInputDto, ItemToBookQuestionService
} from "../../../../_services/configuration-services";
import {FormUtils} from "../../../../_shared/form-utils";
import {NotificationsService} from "../../../../_shared/notifications.service";
import {ConfirmModalService} from "../../../../_shared/_components/confirm-modal/confirm-modal.component";
import {CustomerConfigService} from "../../../../_shared/customer-config-service";

@Component({
    selector: 'app-item-question-management-modal',
    templateUrl: './item-question-management-modal.component.html'
})
export class ItemQuestionManagementModalComponent implements OnInit {

    @Input() itemToBookId: string;
    @Input() crtLang = TranslateUtils.defaultLanguage;

    itemToBookQuestionForm: FormGroup<ItemToBookQuestionForm>;

    itemToBookQuestions: ItemToBookQuestionDto[] = [];
    itemToBookQuestionToEdit: ItemToBookQuestionDto | null;

    answerTypes = Object.keys(ItemToBookAnswerType);

    icons = {
        edit: faPen,
        delete: faTrash
    };

    textInputPattern = FormUtils.textInputPattern;

    constructor(public activeModal: NgbActiveModal,
                private readonly itemToBookQuestionService: ItemToBookQuestionService,
                private readonly notificationsService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                public readonly customerConfigService: CustomerConfigService) {

        this.itemToBookQuestionForm = new FormGroup<ItemToBookQuestionForm>({
            questionFR: new FormControl<string>(null, [Validators.maxLength(255), Validators.required]),
            questionDE: new FormControl<string>(null, Validators.maxLength(255)),
            questionEN: new FormControl<string>(null, Validators.maxLength(255)),
            questionIT: new FormControl<string>(null, Validators.maxLength(255)),
            answerType: new FormControl<ItemToBookAnswerType>(null, Validators.required),
        }, {updateOn: 'change'});
    }

    ngOnInit(): void {
        this.fetchItemToBookQuestions();
    }

    addOrUpdateItemToBookQuestion(): void {
        if (!this.itemToBookQuestionForm.valid) {
            return;
        }
        if (!this.itemToBookQuestionToEdit.id) {
            this.itemToBookQuestionService.create(this.itemToBookId, this.getFormResult()).pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.common.question.notifications.createQuestionSuccess'});
                this.finalizeAddOrUpdateItemToBookQuestion();
            });
        } else {
            this.itemToBookQuestionService.update(this.itemToBookQuestionToEdit.id, this.getFormResult())
                .pipe().subscribe(_ => {
                this.notificationsService.success({title: 'services.common.question.notifications.updateQuestionSuccess'});
                this.finalizeAddOrUpdateItemToBookQuestion();
            });
        }
    }

    deleteItemToBookQuestion(id: string): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.itemToBookQuestionService.delete(id).pipe().subscribe(_ => {
                    this.fetchItemToBookQuestions();
                    this.notificationsService.success({title: 'services.common.question.notifications.deleteQuestionSuccess'});
                });
            }
        });
    }

    createItemToBookQuestion(): void {
        this.itemToBookQuestionToEdit = new ItemToBookQuestionDto();
        this.answerType.enable({onlySelf: true});
        this.itemToBookQuestionForm.reset();
    }

    updateItemToBookQuestion(id: string): void {
        this.itemToBookQuestionService.get(id).pipe().subscribe(itemToBookQuestion => {
            this.itemToBookQuestionToEdit = itemToBookQuestion;
            this.setItemToBookQuestionForm(itemToBookQuestion);
        });
    }

    cancelItemToBookQuestion(): void {
        this.itemToBookQuestionToEdit = null;
    }

    private getFormResult(): ItemToBookQuestionInputDto {
        return new ItemToBookQuestionInputDto({
            questionFR: this.questionFR.value,
            questionDE: this.questionDE.value,
            questionEN: this.questionEN.value,
            questionIT: this.questionIT.value,
            answerType: this.answerType.value
        });
    }

    private setItemToBookQuestionForm(itemToBookQuestion: ItemToBookQuestionDto): void {
        this.questionFR.setValue(itemToBookQuestion.questionFR);
        this.questionDE.setValue(itemToBookQuestion.questionDE);
        this.questionEN.setValue(itemToBookQuestion.questionEN);
        this.questionIT.setValue(itemToBookQuestion.questionIT);
        this.answerType.setValue(itemToBookQuestion.answerType);

        if (!itemToBookQuestion.id) {
            this.answerType.enable({onlySelf: true});
        } else {
            this.answerType.disable({onlySelf: true});
        }
    }

    private finalizeAddOrUpdateItemToBookQuestion(): void {
        this.itemToBookQuestionToEdit = null;
        this.fetchItemToBookQuestions();
        this.itemToBookQuestionForm.reset();
    }

    private fetchItemToBookQuestions(): void {
        this.itemToBookQuestionService.getAll(this.itemToBookId).pipe().subscribe(itemToBookQuestions => {
            this.itemToBookQuestions = itemToBookQuestions;
            this.itemToBookQuestionForm.reset();
        });
    }

    get questionFR(): FormControl<string> {
        return this.itemToBookQuestionForm.get('questionFR') as FormControl<string>;
    }

    get questionDE(): FormControl<string> {
        return this.itemToBookQuestionForm.get('questionDE') as FormControl<string>;
    }

    get questionEN(): FormControl<string> {
        return this.itemToBookQuestionForm.get('questionEN') as FormControl<string>;
    }

    get questionIT(): FormControl<string> {
        return this.itemToBookQuestionForm.get('questionIT') as FormControl<string>;
    }

    get answerType(): FormControl<ItemToBookAnswerType> {
        return this.itemToBookQuestionForm.get('answerType') as FormControl<ItemToBookAnswerType>;
    }
}

interface ItemToBookQuestionForm {
    questionFR: FormControl<string>;
    questionDE: FormControl<string>;
    questionEN: FormControl<string>;
    questionIT: FormControl<string>;
    answerType: FormControl<ItemToBookAnswerType>;
}

<div class="content-container bg-white">
    <h1 translate="communicationChannel.survey.title.view"></h1>

    <div class="btn-bar" [appHasAnyRole]="[AppRoles.admin]">
        <button class="btn btn-primary" [routerLink]="['manage']">
            <span translate="communicationChannel.survey.btn.manageSurveys"></span>
        </button>
    </div>

    <h5 *ngIf="surveys.length === 0" translate="communicationChannel.survey.empty"></h5>

    <div class="communication-channel-list-container" *ngIf="surveys.length > 0">

        <div class="list-item-container"
             *ngFor="let survey of surveys"
             [routerLink]="[survey.id]">

            <div class="icon-container">
                <img class="icon" src="../assets/icons/communication_channel_survey.svg" alt="icon" height="60px" width="auto">
            </div>

            <div class="content-container">
                <h3 translate>{{ survey['title' + crtLang.toUpperCase()] }}</h3>
                <h6>{{ survey.publicationDate | date }}</h6>
            </div>
        </div>
    </div>

</div>

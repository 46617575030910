<div class="nav-menu dark" [appHasAnyRole]="[AppRoles.admin, AppRoles.alarmManager]">
    <ul class="navbar-nav">
        <li class="nav-item">
            <div class="menu">
                <a class="nav-link" href="#"
                   [ngClass]='{"selected": alarmManagementLink.isActive}'
                   [routerLink]="['alarm-management']"
                   routerLinkActive
                   #alarmManagementLink="routerLinkActive">
                    <span translate="menu.navigation.alarms.alarmManagement"></span>
                </a>
            </div>
        </li>

        <li class="nav-item">
            <div class="menu">
                <a class="nav-link" href="#"
                   [ngClass]='{"selected": alarmHistoryLink.isActive}'
                   [routerLink]="['alarm-history']"
                   routerLinkActive
                   #alarmHistoryLink="routerLinkActive">
                    <span translate="menu.navigation.alarms.alarmHistory"></span>
                </a>
            </div>
        </li>

        <li class="nav-item">
            <div class="menu">
                <a class="nav-link" href="#"
                   [ngClass]='{"selected": alarmDeviceManagement.isActive}'
                   [routerLink]="['alarm-device-management']"
                   routerLinkActive
                   #alarmDeviceManagement="routerLinkActive">
                    <span translate="menu.navigation.alarms.alarmDeviceManagement"></span>
                </a>
            </div>
        </li>
    </ul>
</div>

<router-outlet></router-outlet>

export class AppConstants {
    public static readonly appVersion = 'app_version';

    public static readonly imageMaxSizeInKB = 500;
    public static readonly supportedImageType = '.png, .jpg, .jpeg';

    public static readonly communicationChannelImageMaxSizeInKB = 5000;

    // Language activation constants
    public static readonly isDeEnabled = true;
    public static readonly isItEnabled = false;
    public static readonly isEnEnabled = false;

    // LocalStorage Keys
    public static readonly languageKey = 'language';
    public static readonly pushPermissionAskedKey = 'push_permission_asked'; // Use to know if the user already answered the question
    public static readonly pushRegistrationTokenKey = 'push_registrationToken';
    public static readonly pushRegistrationEmailKey = 'push_registrationEmail';
}

export class AppRoles {
    public static readonly admin = 'Admin';
    public static readonly configurator = 'Configurator';
    public static readonly cashier = 'Cashier';
    public static readonly user = 'User';
    public static readonly alarmManager = 'AlarmManager';
    public static readonly harbourmaster = 'Harbourmaster';
}

export const GesConstants = {
    defaultNumeralFormat: '0,0.00',
    intNumeralFormat: '0,0',
    defaultCurrencyFormat: '0,0.00 $',
};

export const CssConstants = {
    unlimitedRowHeightClass: 'unlimited-row-height',
    max150pxRowHeightClass: 'max-150px-row-height',
}

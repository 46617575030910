import {Component, Input} from '@angular/core';
import {BookingLightDto} from '../../../../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DateUtils} from '../../../../../../_shared/date-utils';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {TranslateUtils} from '../../../../../../_shared/translate-utils';

@Component({
  selector: 'app-overlapping-booking-modal',
  templateUrl: './overlapping-booking-modal.component.html'
})
export class OverlappingBookingModalComponent {

    @Input() overlappingBookings: BookingLightDto[] = [];

    crtLang = TranslateUtils.defaultLanguage;

    constructor(public activeModal: NgbActiveModal,
                private readonly translateService: TranslateService,
                private readonly datepipe: DatePipe) {
    }

    cancel(reason: string): void {
        this.activeModal.dismiss(reason);
    }

    confirm(reason: string): void {
        this.activeModal.close(reason);
    }

    printBookingDate(booking: BookingLightDto): string {
        const startDate = this.datepipe.transform(booking.startDate, DateUtils.dateFormat);
        const endDate = this.datepipe.transform(booking.endDate, DateUtils.dateFormat);
        return this.translateService.instant('bookings.modal.overlappingBookingsModal.overlappingBookings.duration',
            {StartDateTime: `${startDate} ${booking.localStartTime}`, EndDateTime: `${endDate} ${booking.localEndTime} `});
    }
}

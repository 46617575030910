import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../_shared/notifications.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HydrowashWashingTrackService, PaymentTypes} from '../../../_services/configuration-services';
import {environment} from '../../../../environments/environment';
import {InternalDatatransService} from '../../../_services/internal-datatrans.service';

@Component({
    selector: 'app-activate-hydrowash-modal',
    templateUrl: './activate-hydrowash-modal.component.html'
})
export class ActivateHydrowashModalComponent implements OnInit {

    @Input() trackId!: string;
    @Input() availablePaymentTypes: PaymentTypes[] = [];

    creditForm: UntypedFormGroup;

    selectedPaymentType: PaymentTypes;

    constructor(public activeModal: NgbActiveModal,
                private readonly notificationService: NotificationsService,
                private readonly hydrowashWashingTrackService: HydrowashWashingTrackService,
                private readonly internalDatatransService: InternalDatatransService,
    ) {
        this.creditForm = new UntypedFormGroup({
            creditAmount: new UntypedFormControl('', Validators.min(0.1))
        },
        {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        if (this.availablePaymentTypes?.length === 1) {
            this.selectPaymentType(this.availablePaymentTypes[0]);
        }
    }

    activateHydrowash(): void {
        if (this.selectedPaymentType === PaymentTypes.Ebanking) {
            this.internalDatatransService.payHydrowash(this.trackId, (this.creditForm.value.creditAmount * 100));

        } else {
            this.hydrowashWashingTrackService.activateTrack(this.trackId, this.creditForm.value.creditAmount).toPromise().then(result => {
                this.activeModal.close();
                const track = this.trackId.replace(environment.qrCodeTrackPrefix, '');
                this.notificationService.success({
                    title: 'services.hydrowash.activation.title',
                    message: 'services.hydrowash.activation.message',
                    interpolateParams: {trackId: track}
                });
            });
        }
    }

    selectPaymentType(paymentType: PaymentTypes): void {
        this.selectedPaymentType = paymentType;
    }
}

<div class="modal-header">
    <h4 *ngIf="booking.reservationType === ReservationType.Booking; else blockingTitle"
        class="modal-title" translate="bookings.modal.confirmation.title"></h4>
    <ng-template #blockingTitle>
        <h4 class="modal-title" translate="bookings.modal.confirmation.blockingTitle"></h4>
    </ng-template>

    <button class="btn-close" aria-label="Close"
            (click)="cancel('Cross click')">
    </button>
</div>

<div class="modal-body">
    <p class="translated-paragraph" *ngIf="selectedBooking && booking.reservationType === ReservationType.Booking && selectedBooking.recurring"
       translate="common.confirmModal.message.refundsAreNotAutomaticWarningMessage"></p>

    <form>
        <div class="row">

            <!-- From -->
            <div class="col-12 col-md-6">
                <label class="form-label" translate="common.date.from"></label>
                <p class="m-0">{{ 'common.date.dateTimeTextFormat' | translate : {
                    date: booking.startDate | date,
                    time: booking.localStartTime.substr(0, 5)
                } }}</p>
            </div>

            <!-- To -->
            <div class="col-12 col-md-6">
                <label class="form-label" translate="common.date.to"></label>
                <p class="m-0">{{ 'common.date.dateTimeTextFormat' | translate : {
                    date: booking.endDate | date,
                    time: booking.localEndTime.substr(0, 5)
                } }}</p>
            </div>

            <!-- Recurrence -->
            <ng-container *ngIf="booking.recurring">
                <label class="form-label" translate="bookings.modal.recurrent"></label>
                <div class="col-12 col-md-6">
                    <ul>
                        <li *ngFor="let day of booking.days">
                            <span>{{ 'common.day.' + day | translate }}</span>
                        </li>
                    </ul>
                </div>
            </ng-container>

            <ng-container *ngIf="!bookingId && booking.paymentType">
                <!-- Price -->
                <div class="col-12 col-md-6">
                    <label class="form-label" translate="bookings.modal.confirmation.amount"></label>
                    <p class="m-0">{{ amount | currencyFormat }}</p>
                </div>

                <!-- PaymentType -->
                <div class="col-12 col-md-6">
                    <label class="form-label" translate="common.terms.paymentType"></label>
                    <p class="m-0">{{ 'common.paymentType.' + booking.paymentType | translate }}</p>
                </div>
            </ng-container>

            <ng-container *ngIf="booking.visitorContact">
                <label class="form-label" translate="bookings.modal.confirmation.user"></label>

                <div class="ms-3" *ngIf="booking.visitorContact">
                    <p class="m-0">{{ booking.visitorContact.firstName }} {{ booking.visitorContact.lastName }}</p>
                    <p class="m-0">{{ booking.visitorContact.email }}</p>
                    <p class="m-0">{{ booking.visitorContact.phone }}</p>
                    <p class="m-0">{{ booking.visitorContact.street }}</p>
                    <p class="m-0">{{ booking.visitorContact.postalCode }} {{ booking.visitorContact.city }}</p>
                    <p class="m-0">{{ booking.visitorContact.country }}</p>
                </div>
            </ng-container>

            <label class="form-label" translate="bookings.modal.confirmation.item"></label>
            <div class="ms-3">
                <p class="m-0">{{ itemToBook['name' + crtLang.toUpperCase()] }}</p>
                <p class="m-0 text-pre-wrap">{{ itemToBook['description' + crtLang.toUpperCase()] }}</p>
            </div>

        </div>
    </form>


</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="cancel('Cross click')"
            translate="common.back">
    </button>
    <button class="btn btn-primary"
            (click)="validate('confirm')"
            translate="common.confirm">
    </button>
</div>

<div class="modal-header">
    <h4 class="modal-title" translate="common.confirmModal.title.pushRegistration"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="close()">
    </button>
</div>

<div class="modal-body">

    <p class="text-pre-wrap" translate="common.confirmModal.message.pushRegistration"></p>

    <ng-container *ngIf="rolesService.isVisitor()">

        <div class="row">
            <div class="col-12">
                <label class="form-label" for="email">{{ 'common.contact.email' | translate }}*</label>
                <input class="form-control " id="email" type="text" maxlength="75"
                       placeholder="{{ 'common.contact.email' | translate }}"
                       [formControl]=email>
                <app-form-error [control]="email"></app-form-error>
            </div>
        </div>
        <div class="btn-bar">
            <button type="button" class="btn btn-primary"
                    (click)="ok()"
                    [disabled]="rolesService.isVisitor() && email.invalid"
                    translate="common.validate">
            </button>
        </div>

    </ng-container>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-danger"
            (click)="nok()"
            translate="common.refuse">
    </button>
    <button class="btn btn-outline-secondary"
            (click)="close()"
            translate="common.confirmModal.button.askAgain">
    </button>

    <button class="btn btn-primary" *ngIf="rolesService.isVisitor()"
            (click)="login()"
            translate="common.iHaveAnAccount">
    </button>
    <button class="btn btn-primary" *ngIf="!rolesService.isVisitor()"
            (click)="ok()"
            translate="common.accept">
    </button>
</div>

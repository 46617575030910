<div class="content-container bg-white">
    <h1 translate="ewallet.listing.title"></h1>

    <!-- Filters -->
    <div [formGroup]="filterForm">

        <div class="row">

            <div class="btn-bar">
                <input class="btn btn-primary" type="button"
                       [value]="'ewallet.listing.action.export' | translate"
                       (click)="export()">
            </div>

            <!-- Amount Filter -->
            <div class="default-filter-form-field" *ngIf="!!amountRange && !!amountFrom && !!amountTo">

                <div [formGroup]="amountRange">
                    <label class="form-label" translate="ewallet.table.balance"></label>
                    <div class="input-group mb-2">
                        <span class="input-group-text" translate="transactions.filter.amountFrom"></span>
                        <input class="form-control" id="amountFrom" name="amountFrom" type="number"
                               [formControl]="amountFrom"
                               (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                               placeholder="{{ 'transactions.filter.amountFrom' | translate }}">
                        <span class="input-group-text">CHF</span>
                    </div>
                    <app-form-error [control]="amountFrom"></app-form-error>
                    <div class="input-group mb-2">
                        <span class="input-group-text" translate="transactions.filter.amountTo"></span>
                        <input class="form-control" id="amountTo" name="amountTo" type="number"
                               [formControl]="amountTo"
                               (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                               placeholder="{{ 'transactions.filter.amountTo' | translate }}">
                        <span class="input-group-text">CHF</span>
                    </div>
                    <app-form-error [control]="amountTo"></app-form-error>
                    <app-form-error [control]="amountRange"></app-form-error>
                </div>
            </div>

            <!-- User Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="transactions.filter.user"></label>
                <app-search-user #searchUserComponent
                                 (selectedUserEvent)="user.setValue($event)"></app-search-user>
            </div>

            <!-- Card Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" for="cardNumber" translate="rfidCards.table.cardNumber"></label>
                <input class="form-control" id="cardNumber"
                       placeholder='{{"users.table.cardNumber" | translate}}'
                       [formControl]="cardNumber"/>
            </div>

        </div>

        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   (click)="clearFilter()">

            <input class="btn btn-primary" type="button" (click)="filterEwallets()"
                   [value]="'ewallet.listing.action.filter' | translate"
                   [disabled]="filterForm.invalid">
        </div>

    </div>

    <ngx-datatable
        class="material"
        [columnMode]="'force'"
        [rows]="eWallets.results"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [externalPaging]="true"
        [externalSorting]="true"
        [count]="eWallets.rowCount"
        [messages]="{
                    totalMessage: 'common.datatable.total' | translate
                    }"
        [offset]="crtPage"
        [limit]="eWallets.pageSize"
        (page)="setPage($event)"
        (sort)="onSort($event)"
        [sorts]="[{prop: 'customerNumber', dir: 'asc'}]"
        [scrollbarH]="true"
        [rowClass]="getRowClass">


        <ngx-datatable-column [width]="400" name="{{'ewallet.listing.table.user' | translate}}" prop="customerNumber" [sortable]="true">
            <ng-template ngx-datatable-cell-template let-eWallet="row">
                {{eWallet.customerNumber + ' ' + eWallet.firstName + ' ' + eWallet.lastName}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="300" name="{{'ewallet.listing.table.contactInfo' | translate}}" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-eWallet="row">
                <option *ngIf="eWallet.email">{{eWallet.email}}</option>
                <option *ngIf="eWallet.phone" class="mt-1">{{eWallet.phone}}</option>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="200" name="{{'ewallet.listing.table.rfidCards' | translate}}" prop="cardNumber" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-eWallet="row">
                {{ eWallet.rfidCards | rfidCardsToInlineText }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" name="{{'ewallet.listing.table.balance' | translate}}" prop="balance" [sortable]="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.balance | currencyFormat }}
            </ng-template>
        </ngx-datatable-column>


        <ngx-datatable-column [width]="25" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-ewallet="row">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-link btn-sm"
                            title="{{'common.tooltip.showWallet' | translate}}"
                            [routerLink]="['/ewallet/', ewallet.userId]">
                        <fa-icon [icon]="icons.wallet"></fa-icon>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>

    <div class="stat-container">
        <h4 translate="ewallet.stats.title"></h4>

        <div class="d-lg-flex flex-row justify-content-end gap-1">
            <p class="flex-grow-1" translate="ewallet.stats.cumulativeSum"></p>
            <strong class="d-block text-nowrap flex-shrink-0 text-end">{{ totalEwallet | currencyFormat }}</strong>
        </div>
    </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RfidCardDto, RfidCardService, RfidCardState} from '../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-rfid-card-modal',
    templateUrl: './rfid-card-modal.component.html'
})
export class RfidCardModalComponent implements OnInit {

    @Input() rfidCard: RfidCardDto | null;
    rfidForm: UntypedFormGroup;
    cardStates = Object.keys(RfidCardState);

    constructor(public activeModal: NgbActiveModal,
                private readonly rfidCardService: RfidCardService) {
        this.rfidForm = new UntypedFormGroup({
                cardNumber: new UntypedFormControl(null, Validators.required),
                uid: new UntypedFormControl(null, Validators.required),
                cardState: new UntypedFormControl(null, Validators.required)
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        if (this.rfidCard == null) {
            this.rfidCard = new RfidCardDto();
        } else {
            this.rfidForm.controls['cardNumber'].disable();
        }
        this.rfidForm.patchValue(this.rfidCard);

        if (this.rfidCard.userId) {
            this.cardStates = this.cardStates.filter(c => c !== RfidCardState.Unassigned)
        } else {
            this.cardStates = this.cardStates.filter(c => c !== RfidCardState.Assigned)
        }

        this.cardState.setValue(this.rfidCard.cardState);
    }

    async addOrUpdate(): Promise<void> {
        const rfiCard = new RfidCardDto();
        rfiCard.cardNumber = this.rfidForm.controls['cardNumber'].value.trim();
        rfiCard.uid = this.rfidForm.controls['uid'].value.trim();
        rfiCard.userId = this.rfidCard.userId;

        rfiCard.cardState = this.cardState.value;

        if (this.rfidCard.cardNumber == null) {
            this.rfidCardService.createRfidCard(rfiCard).toPromise().then(() => {
                this.activeModal.close('success');
            });
        } else {
            this.rfidCardService.updateRfidCard(this.rfidCard.cardNumber, rfiCard).toPromise().then(() => {
                this.activeModal.close('success');
            });
        }
    }

    get cardNumber(): UntypedFormControl {
        return this.rfidForm.get('cardNumber') as UntypedFormControl;
    }

    get uid(): UntypedFormControl {
        return this.rfidForm.get('uid') as UntypedFormControl;
    }

    get cardState(): UntypedFormControl {
        return this.rfidForm.get('cardState') as UntypedFormControl;
    }
}

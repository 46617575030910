
<div class="map-container overflow-hidden">

    <div class="btn-container">
        <div class="btn-group" role="group">
            <button id="zoom-in" type="button" class="btn btn-primary">+</button>
            <button id="zoom-out" type="button" class="btn btn-primary">-</button>
        </div>
    </div>

    <svg class="map" id="d3_map" width="1240" height="500"></svg>
</div>


import {Component, Input, OnInit} from '@angular/core';
import {
    LightDto,
    PublicLightingLightService,
    PublicLightingZoneService
} from '../../../../../_services/configuration-services';
import {firstValueFrom, Subscription} from 'rxjs';
import {FormArray, FormControl, FormGroup, UntypedFormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {NotificationsService} from '../../../../../_shared/notifications.service';

@Component({
  selector: 'app-light-zone-lights-management-modal',
  templateUrl: './light-zone-lights-management-modal.component.html'
})
export class LightZoneLightsManagementModalComponent implements OnInit {
    @Input() serviceId: string;
    @Input() lightZoneId: string;

    languageSubscription: Subscription;
    currentLang: string;

    lightsForm: UntypedFormGroup;

    lights: LightDto[] = [];

    constructor(public activeModal: NgbActiveModal,
                private readonly translate: TranslateService,
                private readonly notificationService: NotificationsService,
                private readonly lightService: PublicLightingLightService,
                private readonly zoneService: PublicLightingZoneService) {

        this.lightsForm = new UntypedFormGroup({
            isAssignedToZoneArray: new FormArray([])
        });
    }

    async ngOnInit(): Promise<void> {
        this.currentLang = this.translate.currentLang;
        this.languageSubscription = this.translate.onLangChange.subscribe(langChangeEvent =>
            this.currentLang = langChangeEvent.lang);

        this.lights.push(
            ...await firstValueFrom(this.lightService.getLightsByServiceIdAndZoneId(this.serviceId, this.lightZoneId)),
            ...await firstValueFrom(this.lightService.getLightsByServiceIdNotAssignedToZone(this.serviceId))
        );

        for (const light of this.lights) {
            this.isAssignedToZoneArray.push(
                new FormGroup({isAssignedToZone: new FormControl(light.lightZoneId !== null)})
            );
        }
    }

    async saveLightsAssignation(): Promise<void> {
        const formArray = this.isAssignedToZoneArray.value;
        const lightsToAssign: string[] = [];

        for (let i = 0; i < this.lights.length; i++) {
            if (formArray[i].isAssignedToZone) {
                lightsToAssign.push(this.lights[i].id);
            }
        }

        await firstValueFrom(this.zoneService.assignLightsToZone(this.lightZoneId, lightsToAssign));

        this.notificationService.success({title: 'services.publicLighting.light.notifications.assignSuccess'});
        this.activeModal.close('success');
    }

    get isAssignedToZoneArray(): FormArray {
        return this.lightsForm.get('isAssignedToZoneArray') as FormArray;
    }
}


<!-- User Summary -->
<div class="row mt-4">
    <fieldset class="col-12">
        <legend [translate]="hasAdminRole ? 'common.debtorDetails' : 'common.debtorMyDetails'"></legend>

        <div class="col-sm-6">
            <table class="table table-borderless no-padding"
                   [attr.aria-label]="(hasAdminRole ? 'common.debtorDetails' : 'common.debtorMyDetails') | translate"
                   aria-hidden="true">
                <tbody>
                <tr>
                    <td translate="common.name"></td>
                    <td>{{ this.visitor.firstName }} {{ this.visitor.lastName }}</td>
                </tr>
                <tr>
                    <td translate="common.street"></td>
                    <td>{{ this.visitor.street }}</td>
                </tr>
                <tr>
                    <td translate="common.city"></td>
                    <td>{{ this.visitor.postalCode }} {{ this.visitor.city }}</td>
                </tr>
                <tr>
                    <td translate="common.country"></td>
                    <td>{{ this.visitor.country }}</td>
                </tr>
                <tr>
                    <td translate="common.email"></td>
                    <td>{{ this.visitor.email }}</td>
                </tr>
                <tr>
                    <td translate="common.phoneNumber"></td>
                    <td>{{ this.visitor.phone }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </fieldset>
</div>

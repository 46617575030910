
<!-- Filters -->
<div class="row">

    <div class="col-lg-4" *ngIf="isAdminMode">
        <label class="form-label" for="userSelection" translate="services.energy.cockpit.filter.label.user"></label>
        <app-search-user id="userSelection"
                         (selectedUserEvent)="userSelected($event)"></app-search-user>
    </div>

    <div class="col-lg-4">
        <label class="form-label" for="searchMeasuringPoint"
               translate="services.energy.cockpit.filter.label.measuringPoint"></label>
        <app-search-measuring-point id="searchMeasuringPoint"
                                    #searchMeasuringPoint
                                    [userId]="userId"
                                    [serviceId]="serviceId"
                                    [measuringPointTypes]="[userMeasuringPointType]"
                                    [allowDropdownEmptySelection]="true"
                                    [includeMeasuringPointWithHistoricalAssignments]="true"
                                    [sort]="currentAssignedFirstSort"
                                    (selectedMeasuringPointEvent)="measuringPointSelected($event)"
                                    [defaultMeasuringPoint]="measuringPoint"></app-search-measuring-point>
    </div>

    <div class="col-lg-4">
        <label class="form-label" for="dateRange" translate="services.energy.cockpit.filter.label.dateRange"></label>
        <app-date-range-picker id="dateRange"
                               [maxDate]="maxDateFromForUser"
                               [minDate]="minDateFromForUser"
                               (dateRangeChange)="dateRangeChange($event)"></app-date-range-picker>
    </div>

</div>

<div *ngIf="!measuringPoint" class="text-center">
    <em translate="services.energy.cockpit.message.selectMeasuringPoint"></em>
</div>

<div *ngIf="measuringPoint">

    <div *ngIf="!cockpitData || !cockpitData.assignment; else withAssignment" class="text-center">
        <p translate="services.energy.cockpit.message.noDataAvailable"></p>
        <p *ngIf="isAdminMode && !userIsSelected && cockpitData && cockpitData.assignment === null" translate="services.energy.cockpit.message.measuringPointNotAssigned"></p>
    </div>

    <ng-template #withAssignment>
        <h4 class="text-center">{{ 'services.energy.cockpit.user.title' | translate }}</h4>
        <h4 class="text-center">{{'services.energy.cockpit.value.fromTo'| translate:{
            from: formatDate(dateRange.start), to: formatDate(dateRange.end)
        } }}</h4>

        <h5 class="text-center">
            <span>{{ measuringPoint['name' + crtLang.toUpperCase()] }}</span>
            <span *ngIf="measuringPointAssignment?.userId"> ({{ measuringPointAssignment.userFirstName }} {{ measuringPointAssignment.userLastName }})</span>
        </h5>

        <div *ngIf="cockpitData" class="mt-5">

            <div class="cockpit-data-container">

                <div class="d-flex flex-row justify-content-between gap-3">
                    <p class="title-label" translate="services.energy.cockpit.label.actualIntensity"></p>

                    <p class="text-end">{{ 'services.energy.cockpit.value.intensity_A' | translate: {
                        value: actualIntensity
                    } }}</p>
                </div>

                <div class="d-flex flex-row justify-content-between gap-3">
                    <p class="title-label" translate="services.energy.cockpit.label.totalConsumption"></p>

                    <p class="text-end">{{ 'services.energy.cockpit.value.consumption_kWh' | translate: {
                        value: cockpitData.totalEnergyConsumption ?
                            cockpitData.totalEnergyConsumption.toFixed(2) : '--'
                    } }}
                    </p>
                </div>

                <div class="d-flex flex-row justify-content-between gap-3">
                    <p class="title-label" translate="services.energy.cockpit.label.priceByUnitWithCurrentTariff"></p>

                    <p class="text-end" *ngIf="!!tariff">{{ tariff.tariffPrices[0].priceByUnit | currencyFormat }}</p>
                    <p class="text-end" *ngIf="!tariff">-- CHF</p>
                </div>

                <div class="d-flex flex-row justify-content-between gap-3">
                    <p class="title-label" translate="services.energy.cockpit.label.maxDayConsumption"></p>

                    <p class="text-end"
                       *ngIf="cockpitData.maximumDailyEnergyConsumption">{{ 'services.energy.cockpit.value.consumption_kWh_date' | translate: {
                        value: cockpitData.maximumDailyEnergyConsumption.value.toFixed(2),
                        date: cockpitData.maximumDailyEnergyConsumption.dateTime | date:dateFormat
                    } }}</p>
                    <p class="text-end"
                       *ngIf="!cockpitData.maximumDailyEnergyConsumption">
                        {{ 'services.energy.cockpit.value.consumption_kWh' | translate: {
                        value: '--'
                    } }}
                    </p>
                </div>

                <div class="d-flex flex-row justify-content-between gap-3">
                    <p class="title-label" translate="services.energy.cockpit.label.totalPriceWithCurrentTariff"></p>

                    <p class="text-end">{{ cockpitData.totalEnergyConsumption ?
                        (cockpitData.totalEnergyConsumption * (tariff?.tariffPrices[0].priceByUnit ? tariff.tariffPrices[0].priceByUnit : 0) | currencyFormat) : '-- CHF' }}</p>
                </div>

                <div class="d-flex flex-row justify-content-between gap-3">
                    <p class="title-label" translate="services.energy.cockpit.label.measuringPointState"></p>

                    <div>
                        <span>{{ 'common.measuringPointState.' + cockpitData.measuringPointState | translate }}</span>

                        <span *ngIf="cockpitData.measuringPointState === MeasuringPointState.Inactive &&
                            cockpitData.measuringPointStateChangeSource === MeasuringPointStateChangeSource.Admin">
                        {{ 'services.energy.cockpit.value.stateChangedSourceAdmin' | translate }}
                    </span>
                        <span *ngIf="cockpitData.measuringPointState === MeasuringPointState.Inactive &&
                            cockpitData.measuringPointStateChangeSource === MeasuringPointStateChangeSource.User">
                        {{ 'services.energy.cockpit.value.stateChangedSourceUser' | translate }}
                    </span>
                        <span *ngIf="cockpitData.measuringPointState === MeasuringPointState.Inactive &&
                            cockpitData.measuringPointStateChangeSource === MeasuringPointStateChangeSource.Ewallet">
                        {{ 'services.energy.cockpit.value.stateChangedSourceEwallet' | translate }}
                    </span>
                    </div>
                </div>

                <div class="d-flex flex-row justify-content-between gap-3" *ngIf="canCommuteMeasuringPoint">
                    <p class="title-label" translate="services.energy.cockpit.label.commuteMooringPlace"></p>

                    <div class="btn-group" role="group">
                        <input class="btn-check" type="radio"
                               [value]="MeasuringPointState.Active">
                        <label class="btn btn-toggle btn-toggle-left h-auto"
                               [ngClass]="{ active: cockpitData.measuringPointState === MeasuringPointState.Active }"
                               (click)="changeMeasuringPointState(true)"
                               (keyup)="changeMeasuringPointState(true)">ON</label>

                        <input class="btn-check" type="radio"
                               [value]="MeasuringPointState.Inactive">
                        <label class="btn btn-toggle btn-toggle-right h-auto"
                               [ngClass]="{ active: cockpitData.measuringPointState === MeasuringPointState.Inactive }"
                               (click)="changeMeasuringPointState(false)"
                               (keyup)="changeMeasuringPointState(false)">OFF</label>
                    </div>

                </div>
            </div>

            <div class="mt-3 mb-3">
                <app-consumption-bar-chart [consumptionData]="consumptionData"></app-consumption-bar-chart>
            </div>

        </div>
    </ng-template>

</div>


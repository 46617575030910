import {Component, OnDestroy, OnInit} from '@angular/core';
import {WifiSubscriptionService, WifiSubscriptionWithAuthenticationDto} from '../../../../_services/configuration-services';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../../../../_shared/translate-utils';

@Component({
    selector: 'app-wifi-subscription-list-for-user',
    templateUrl: './wifi-subscription-list-for-user.component.html'
})
export class WifiSubscriptionListForUserComponent implements OnInit, OnDestroy {

    crtLang = TranslateUtils.defaultLanguage;

    wifiUserSubscriptions: WifiSubscriptionWithAuthenticationDto[] = [];

    private langChangeSubscription: Subscription;

    constructor(
        private readonly wifiSubscriptionService: WifiSubscriptionService,
        private readonly translateService: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.fetchWifiSubscriptions();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    getServiceName(wifiSubscription: WifiSubscriptionWithAuthenticationDto): string {
        return wifiSubscription['serviceName' + this.crtLang.toUpperCase()];
    }

    getDisplayDateFormat(): string {
        return this.translateService.instant('common.period.dateTimeFormat');
    }

    private fetchWifiSubscriptions(): void {
        this.wifiSubscriptionService.getMySubscriptions().pipe().subscribe(result => {
            this.wifiUserSubscriptions = result;
        });
    }

}

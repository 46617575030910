import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
    ItemToBookDto, ItemToBookService, MeasuringPointLightDto, MeasuringPointType,
} from '../../../../_services/configuration-services';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {FormUtils} from '../../../../_shared/form-utils';
import {CustomerConfigService} from "../../../../_shared/customer-config-service";

@Component({
    selector: 'app-item-modal',
    templateUrl: './item-modal.component.html'
})
export class ItemModalComponent implements OnInit {

    @Input() serviceId!: string;
    @Input() item: ItemToBookDto;
    @Input() items: ItemToBookDto[];

    // ---------

    itemForm: UntypedFormGroup;
    adminMeasurePointType = MeasuringPointType.Admin;
    measuringPoint: MeasuringPointLightDto;
    textInputPattern = FormUtils.textInputPattern;

    constructor(public activeModal: NgbActiveModal,
                private readonly notificationsService: NotificationsService,
                private readonly itemToBookService: ItemToBookService,
                public readonly customerConfigService: CustomerConfigService) {
        this.itemForm = new UntypedFormGroup({
                itemNameFR: new UntypedFormControl(null, Validators.required),
                itemNameDE: new UntypedFormControl(null),
                itemNameIT: new UntypedFormControl(null),
                itemNameEN: new UntypedFormControl(null),
                itemDescriptionFR: new UntypedFormControl(null),
                itemDescriptionDE: new UntypedFormControl(null),
                itemDescriptionIT: new UntypedFormControl(null),
                itemDescriptionEN: new UntypedFormControl(null)
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        if (this.item == null) {
            this.item = new ItemToBookDto();
        } else {
            this.measuringPoint = this.item.measuringPoint;
            this.itemNameFR.setValue(this.item.nameFR);
            this.itemNameDE.setValue(this.item.nameDE);
            this.itemNameIT.setValue(this.item.nameIT);
            this.itemNameEN.setValue(this.item.nameEN);
            this.itemDescriptionFR.setValue(this.item.descriptionFR);
            this.itemDescriptionDE.setValue(this.item.descriptionDE);
            this.itemDescriptionIT.setValue(this.item.descriptionIT);
            this.itemDescriptionEN.setValue(this.item.descriptionEN);
        }
    }

    async addOrUpdateItem(): Promise<void> {
        this.item.nameFR = !!this.itemNameFR.value ? this.itemNameFR.value : '';
        this.item.nameDE = !!this.itemNameDE.value ? this.itemNameDE.value : '';
        this.item.nameIT = !!this.itemNameIT.value ? this.itemNameIT.value : '';
        this.item.nameEN = !!this.itemNameEN.value ? this.itemNameEN.value : '';
        this.item.descriptionFR = this.itemDescriptionFR.value;
        this.item.descriptionDE = this.itemDescriptionDE.value;
        this.item.descriptionIT = this.itemDescriptionIT.value;
        this.item.descriptionEN = this.itemDescriptionEN.value;
        this.item.measuringPoint = this.measuringPoint;
        this.item.serviceId = this.serviceId;

        if (this.item.id == null) {
            this.itemToBookService.createItem(this.item).pipe().subscribe(() => {
                this.notificationsService.success({title: 'services.items.notifications.addSuccess'});
                this.activeModal.close();
            });
        } else {
            this.itemToBookService.updateItem(this.item.id, this.item).pipe().subscribe(() => {
                this.notificationsService.success({title: 'services.items.notifications.updateSuccess'});
                this.activeModal.close();
            });
        }
    }

    validateItemName(control: UntypedFormControl): void {
        control.setErrors(null);
        control.updateValueAndValidity();

        let value = control?.value;
        if (!value) {
            return;
        }

        for (const it of this.items) {
            if (it.id !== this.item.id) {
                if (it.nameFR.toLowerCase() === value.toLowerCase()) {
                    control.setErrors({duplicateNameFR: true});
                    break;
                }
                if (it.nameDE.toLowerCase() === value.toLowerCase() && this.customerConfigService.isLanguageAvailable('de')) {
                    control.setErrors({duplicateNameDE: true});
                    break;
                }
                if (it.nameIT.toLowerCase() === value.toLowerCase() && this.customerConfigService.isLanguageAvailable('it')) {
                    control.setErrors({duplicateNameIT: true});
                    break;
                }
                if (it.nameEN.toLowerCase() === value.toLowerCase() && this.customerConfigService.isLanguageAvailable('en')) {
                    control.setErrors({duplicateNameEN: true});
                    break;
                }
            }
        }
    }

    get itemNameFR(): UntypedFormControl {
        return this.itemForm.get('itemNameFR') as UntypedFormControl;
    }

    get itemNameDE(): UntypedFormControl {
        return this.itemForm.get('itemNameDE') as UntypedFormControl;
    }

    get itemNameIT(): UntypedFormControl {
        return this.itemForm.get('itemNameIT') as UntypedFormControl;
    }

    get itemNameEN(): UntypedFormControl {
        return this.itemForm.get('itemNameEN') as UntypedFormControl;
    }

    get itemDescriptionFR(): UntypedFormControl {
        return this.itemForm.get('itemDescriptionFR') as UntypedFormControl;
    }

    get itemDescriptionDE(): UntypedFormControl {
        return this.itemForm.get('itemDescriptionDE') as UntypedFormControl;
    }

    get itemDescriptionIT(): UntypedFormControl {
        return this.itemForm.get('itemDescriptionIT') as UntypedFormControl;
    }

    get itemDescriptionEN(): UntypedFormControl {
        return this.itemForm.get('itemDescriptionEN') as UntypedFormControl;
    }

    measuringPointSelected(measuringPoint: MeasuringPointLightDto | null): void {
        this.measuringPoint = measuringPoint;
    }
}

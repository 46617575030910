import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {TranslateUtils} from "../_shared/translate-utils";
import {environment} from "../../environments/environment";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-cgv',
  templateUrl: './cgv.component.html',
  styleUrls: ['./cgv.component.scss']
})
export class CgvComponent implements OnInit {

    private langChangeSubscription: Subscription;

    crtLang = TranslateUtils.defaultLanguage;

    clientContacts = [];

    cgu = environment.cgu;

    constructor(private readonly translateService: TranslateService) { }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        if(environment.cgu.email){
            this.clientContacts.push(environment.cgu.email);
        }

        if(environment.cgu.phone){
            this.clientContacts.push(environment.cgu.phone);
        }
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

}

import {Component, OnInit} from '@angular/core';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../_services/configuration-services';

@Component({
    selector: 'app-user-delete-confirmation-modal',
    templateUrl: './user-delete-confirmation-modal.component.html'
})
export class UserDeleteConfirmationModalComponent implements OnInit {

    public userId: string;

    reasons: number[] = [];
    loading: boolean = false;


    constructor(
        private readonly userService: UserService,
        public readonly activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.userService.getUserNotDeletableReasons(this.userId).pipe().subscribe(result => {
            this.reasons = result;
            this.loading = false;
        })
    }

    close(): void {
        this.activeModal.close(ConfirmModalService.no);
    }

    yes(): void {
        this.activeModal.close(ConfirmModalService.yes);
    }

    no(): void {
        this.activeModal.close(ConfirmModalService.no);
    }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {faCalendar, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FormControl, FormGroup} from '@angular/forms';
import {NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from "../../custom-date-formatter";

@Component({
    selector: 'app-date-period-picker',
    templateUrl: './date-period-picker.component.html',
    providers: [ {provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class DatePeriodPickerComponent implements OnInit {

    @Input() inputLabel: string | null;

    @Input() fromMinDate: NgbDateStruct;
    @Input() fromMaxDate: NgbDateStruct;

    @Input() toMinDate: NgbDateStruct;
    @Input() toMaxDate: NgbDateStruct;

    @Input() isFromRequired: boolean = false;

    @Input() required: boolean | null;

    @Input() periodControl: FormGroup<DatePeriod>;

    @Output() dateFromSelected = new EventEmitter<NgbDate>();
    @Output() dateToSelected = new EventEmitter<NgbDate>();
    @Output() dateFromReset = new EventEmitter<null>();
    @Output() dateToReset = new EventEmitter<null>();

    icons = {
        calendar: faCalendar,
        times: faTimes
    };

    ngOnInit(): void {
        if (!this.inputLabel) {
            this.inputLabel = 'common.form.date-period-picker.defaultLabel';
        }
    }

    resetFromDate(): void {
        this.from.setValue(null);
        this.from.markAsDirty();
        this.dateFromReset.emit(null)
    }

    resetToDate(): void {
        this.to.setValue(null);
        this.to.markAsDirty();
        this.dateToReset.emit(null)
    }

    get from(): FormControl<NgbDateStruct> {
        return this.periodControl.get('from') as FormControl<NgbDateStruct>;
    }

    get to(): FormControl<NgbDateStruct> {
        return this.periodControl.get('to') as FormControl<NgbDateStruct>;
    }

}

export interface DatePeriod {
    from: FormControl<NgbDateStruct>,
    to: FormControl<NgbDateStruct>
}

import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {
    MooringPlaceBoatDto,
    MooringPlaceService
} from '../../../_services/configuration-services';
import {firstValueFrom} from 'rxjs';
import {GesConstants} from '../../../app.constants';

@Component({
  selector: 'app-user-boats-summary',
  templateUrl: './user-boats-summary.component.html'
})
export class UserBoatsSummaryComponent implements OnChanges {

    @Input() userId: string = null!;

    userBoats: MooringPlaceBoatDto[] = [];

    Csts = GesConstants;

    constructor(private readonly mooringPlaceService: MooringPlaceService) {
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['userId'] && changes['userId'].currentValue) {
            this.userBoats = await firstValueFrom(this.mooringPlaceService.getMooringPlaceBoatsForUser(this.userId));
        }
    }
}

import {Injectable} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as m from 'moment';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';
import {BookingTimeUnit} from '../_services/configuration-services';

@Injectable({
    providedIn: 'root'
})
export class DateUtils {

    // Dates and Times formats
    public static readonly dateFormat = 'dd.MM.yyyy';
    public static readonly dateTimeFormat = 'dd.MM.yy HH:mm:ss';
    public static readonly dateTimeWithoutSecondFormat = 'dd.MM.yy HH:mm';
    public static readonly timeWithoutSecondFormat = 'HH:mm';

    public static ngbDateStructToDate(dateStruct: NgbDateStruct | null): Date | null {
        if (dateStruct == null) {
            return null;
        }
        return new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day);
    }

    public static ngbDateStructToDateTime(dateStruct: NgbDateStruct | null, timeStruct: NgbTimeStruct | null): Date | null {
        if (!dateStruct) {
            return null;
        }
        if (!timeStruct) {
            return DateUtils.ngbDateStructToDate(dateStruct);
        }
        return new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day, timeStruct.hour, timeStruct.minute, timeStruct.second, 0);
    }

    public static dateToNgbDateStruct(date: Date | null): NgbDateStruct | null {
        if (date == null) {
            return null;
        }
        return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
    }

    public static dateToNgbTimeStruct(date: Date | null) : NgbTimeStruct | null {
        if (date == null) {
            return null;
        }
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        return {hour: hours, minute: minutes, second: seconds};
    }

    public static stringToNgbTimeStruct(value: string | null): NgbTimeStruct | null {
        if (!value) {
            return null;
        }
        const time = value.split(':');
        return {hour: +time[0], minute: +time[1], second: +time[2]};
    }

    public static ngbTimeStructToString(value: NgbTimeStruct | null, hideSeconds = false): string | null {
        if (!value) {
            return null;
        }

        if (hideSeconds) {
            return `${value.hour.toString().padStart(2, '0')}:${value.minute.toString().padStart(2, '0')}`;

        } else {
            return `${value.hour.toString().padStart(2, '0')}:${value.minute.toString().padStart(2, '0')}:00`;
        }
    }

    public static ngbTimeStructMinusOneMinuteToString(time: NgbTimeStruct): string {
        const newTime = this.subtractNgbTimeStruct(time, {hour: 0, minute: 1, second: 0});
        return this.ngbTimeStructToString(newTime, true);
    }

    public static ngbDateStructToString(value: NgbDateStruct | null): string | null {
        if (!value) {
            return null;
        }
        return `${value.day.toString().padStart(2, '0')}/${value.month.toString().padStart(2, '0')}/${value.year}`;
    }

    public static addNgbTimeStruct(value1: NgbTimeStruct | null, value2: NgbTimeStruct | null): NgbTimeStruct | null {
        if (!value1 || !value2) {
            return null;
        }
        let seconds = value1.second + value2.second;
        let minutes = value1.minute + value2.minute;
        let hours = value1.hour + value2.hour;

        if (seconds >= 60) {
            seconds -= 60;
            minutes++;
        }
        if (minutes >= 60) {
            minutes -= 60;
            hours++;
        }

        return {hour: hours, minute: minutes, second: seconds};
    }

    public static subtractNgbTimeStruct(value1: NgbTimeStruct | null, value2: NgbTimeStruct | null): NgbTimeStruct | null {
        if (!value1 || !value2) {
            return null;
        }
        let seconds = value1.second - value2.second;
        let minutes = value1.minute - value2.minute;
        let hours = value1.hour - value2.hour;

        if (seconds < 0) {
            seconds += 60;
            minutes--;
        }
        if (minutes < 0) {
            minutes += 60;
            hours--;
        }

        return {hour: hours, minute: minutes, second: seconds};
    }

    public static before(one: NgbDateStruct | null, two: NgbDateStruct | null): boolean {
        return one == null || two == null ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
            ? false : one.day <= two.day : one.month <= two.month : one.year <= two.year;
    }

    public static equals(one: NgbDateStruct | null, two: NgbDateStruct | null): boolean {
        return one == null || two == null ? false : two.year === one.year && two.month === one.month && two.day === one.day;
    }

    public static after(one: NgbDateStruct | null, two: NgbDateStruct | null): boolean {
        return one == null || two == null ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
            ? false : one.day > two.day : one.month > two.month : one.year > two.year;
    }

    public static beforeOrEquals(one: NgbDateStruct | null, two: NgbDateStruct | null): boolean {
        return this.before(one, two) || this.equals(one, two);
    }

    public static afterOrEquals(one: NgbDateStruct | null, two: NgbDateStruct | null): boolean {
        return this.after(one, two) || this.equals(one, two);
    }

    public static between(date: NgbDateStruct | null, one: NgbDateStruct | null, two: NgbDateStruct | null): boolean {
        return this.before(date, two) && this.after(date, one);
    }

    public static isFullDay(dateFrom: Date, dateTo: Date): boolean {
        return dateTo.getHours() === 23 && dateTo.getMinutes() === 59 && dateTo.getSeconds() === 59 &&
            dateFrom.getHours() === 0 && dateFrom.getMinutes() === 0 && dateFrom.getSeconds() === 0;
    }

    public static isInPast(date: Date): boolean {
        return date < new Date();
    }

    public static isInPastDays(date: Date): boolean {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        return date < today;
    }

    public static isTodayInThePast(date: Date): boolean {
        const now = new Date();

        const isToday = this.isToday(date);

        const isInThePast = date <= now;

        return isToday && isInThePast;
    }

    public static isToday(date: Date): boolean {
        const now = new Date();

        return date.getFullYear() === now.getFullYear()
            && date.getMonth() === now.getMonth()
            && date.getDate() === now.getDate();
    }

    public static dateRoundedToNextThirtyMinutes(date: Date): Date {
        if (date.getMinutes() < 28) { // 2 minutes margin
            return new Date(date.getTime() + (30 - date.getMinutes()) * 60000);

        } else if (date.getMinutes() < 58) { // 2 minutes margin
            return new Date(date.getTime() + (60 - date.getMinutes()) * 60000);

        } else { // to next 30 minutes
            return new Date(date.getTime() + (90 - date.getMinutes()) * 60000);
        }
    }

    public static generateHoursOfDayBy30Minutes(): string[] {
        const hours = [];
        for (let hour = 0; hour < 24; hour++) {
            hours.push(m({hour}).format('HH:mm'));
            hours.push(
                m({
                    hour,
                    minute: 30
                }).format('HH:mm')
            );
        }
        return hours;
    }

    public static formatTime(time: string | null, separator?: string): string {
        if (!time) {
            return '-';
        }
        if (!separator) {
            separator = ':';
        }
        const timeStruct = DateUtils.stringToNgbTimeStruct(time);
        return `${timeStruct.hour.toString().padStart(2, '0')}${separator}${timeStruct.minute.toString().padStart(2, '0')}`;
    }

    public static isDateValid(date: NgbDateStruct | null): boolean{
        return !date || (date.year >= 1000 && date.year <= 9999);
    }

    public static getWeekdaysCountsBetweenTwoDates(startDate: Date, endDate?: Date): number[] {
        const daysOfWeek = [0, 0, 0, 0, 0, 0, 0];

        const start = startDate.getTime();
        const end = endDate?.getTime() ?? new Date().getTime();

        const oneDayInMs = 24 * 60 * 60 * 1000;
        for (let i = start; i <= end; i += oneDayInMs) {
            const currentDate = new Date(i);
            const day = currentDate.getDay();
            daysOfWeek[(day + 6) % 7]++; // Start on Monday instead of Sunday
        }

        return daysOfWeek;
    }

    public static convertHoursToSeconds(hours: number): number {
        return hours * 3600;
    }

    public static getEndDateBasedOnBookingDuration(date: Date, bookingUnit: BookingTimeUnit, bookingDuration: number): Date {
        let endDate = date;
        switch (bookingUnit) {
            case BookingTimeUnit.Minute:
                endDate.setMinutes(date.getMinutes() + bookingDuration);
                break;
            case BookingTimeUnit.Hour:
                endDate.setHours(date.getHours() + bookingDuration);
                break;
            case BookingTimeUnit.Day:
                endDate.setDate(date.getDate() + bookingDuration);
                break;
            case BookingTimeUnit.Month:
                endDate.setMonth(date.getMonth() + bookingDuration);
                break;
            case BookingTimeUnit.Year:
                endDate.setFullYear(date.getFullYear() + bookingDuration);
                break;
        }

        return endDate;
    }

    public static convertValueAndUnitToHours(value: number, unit: BookingTimeUnit): number {
        switch (unit) {
            case BookingTimeUnit.Minute:
                return value / 60;
            case BookingTimeUnit.Hour:
                return value;
            case BookingTimeUnit.Day:
                return value * 24;
            case BookingTimeUnit.Month:
                return value * 24 * 30;
            case BookingTimeUnit.Year:
                return value * 24 * 365;
        }
    }

}

<div>
    <div class="modal-header">
        <h4 class="modal-title" translate="bookings.modal.slotManagement.title"></h4>

        <button class="btn-close" aria-label="Close"
                (click)="bookingSlotManagementModal.dismiss('Cross click')">
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="bookingSlotManagementForm">
            <div class="row">

                <div class="col-12 col-sm-6" formGroupName="timePeriod">
                    <label class="form-label" for="startTime">{{ 'services.craning.slots.form.management.startTime' | translate }}*</label>

                    <ngb-timepicker id="startTime" formControlName="from"></ngb-timepicker>
                    <app-form-error [control]="startTime"></app-form-error>
                </div>

                <div class="col-12 col-sm-6" formGroupName="timePeriod">
                    <label class="form-label" for="duration">{{ 'services.craning.slots.form.management.slotDuration' | translate }}*</label>

                    <ngb-timepicker id="duration" formControlName="duration"></ngb-timepicker>
                    <app-form-error [control]="duration"></app-form-error>
                    <app-form-error [control]="timePeriod"></app-form-error>
                </div>

                <!-- state -->
                <div class="col-12" *ngIf="!isSlotBooked">
                    <label class="form-label">{{ 'services.craning.slots.form.management.state' | translate }}*</label>

                    <div>
                        <div class="btn-group" role="group">
                            <input class="btn-check" type="radio"
                                   [formControl]="state"
                                   [value]="BookingSlotState.Free">
                            <label class="btn btn-toggle btn-toggle-left h-auto"
                                   [ngClass]="{ active: state.value === BookingSlotState.Free }"
                                   (click)="state.setValue(BookingSlotState.Free); state.markAsDirty();"
                                   (keyup)="state.setValue(BookingSlotState.Free); state.markAsDirty();">
                                {{ 'bookings.slots.stateEnum.' + BookingSlotState.Free | translate }}
                            </label>

                            <input class="btn-check" type="radio"
                                   [formControl]="state"
                                   [value]="BookingSlotState.Blocked">
                            <label class="btn btn-toggle btn-toggle-right h-auto"
                                   [ngClass]="{ active: state.value === BookingSlotState.Blocked }"
                                   (click)="state.setValue(BookingSlotState.Blocked); state.markAsDirty();"
                                   (keyup)="state.setValue(BookingSlotState.Blocked); state.markAsDirty();">
                                {{ 'bookings.slots.stateEnum.' + BookingSlotState.Blocked | translate }}
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>

    <div class="modal-footer">

        <button class="btn btn-outline-danger" *ngIf="!isSlotBooked"
                (click)="deleteSlot()"
                translate="common.delete">
        </button>

        <button class="btn btn-outline-secondary" *ngIf="isSlotBooked"
                (click)="displayBooking()"
                translate="services.craning.booking.actions.displayBooking">
        </button>

        <button class="btn btn-outline-secondary" *ngIf="selectedSlot.state === BookingSlotState.Free && !BookingSlotUtils.isSlotInPast(selectedSlot)"
                (click)="openBookingForm()"
                translate="{{ isSlotBooked ? 'bookings.update' : 'bookings.selectedSlot.addBooking' }}">
        </button>

        <button class="btn btn-primary" *ngIf="!BookingSlotUtils.isSlotInPast(selectedSlot)"
                [disabled]="bookingSlotManagementForm.invalid || bookingSlotManagementForm.pristine"
                (click)="saveSlot()"
                translate="common.save">
        </button>

    </div>
</div>


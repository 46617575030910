// Configuration to run the frontend app on Azure DEV environment.
// The property "production" is true to enable the PWA features

export const environment = {
    authConfiguration: {
        redirectUri: 'https://dev.gestinergie.ch/',
        postLogoutRedirectUri: 'https://dev.gestinergie.ch/',
    },
    azureStorageAccountUrl: 'https://stgestwebappdev.blob.core.windows.net/$web',
    b2cConfigServiceClientId: '906bccb0-18f0-4b23-b6a1-2b43cb047e18',
    b2cDomainName: 'gestinergiedev',
    b2cSignInSignUpPolicy: 'B2C_1_UserSignIn',
    b2cResetPasswordPolicy: 'B2C_1_PasswordReset',
    b2cWebClientClientId: '05005f1c-4c95-44f9-9ca4-9612c7207616',
    canUserCommuteInCockpit: true,
    clientCode: 'sftc',
    configurationServiceBaseUrl: 'https://app-gestinergie-configuration-dev.azurewebsites.net',
    envName: 'dev',
    firebase: {
        apiKey: 'AIzaSyDhZbX1r4fTlr37-EXhUpz7HzNXvmtZeAY',
        authDomain: 'gestinergie.firebaseapp.com',
        projectId: 'gestinergie',
        storageBucket: 'gestinergie.appspot.com',
        messagingSenderId: '852104364728',
        appId: '1:852104364728:web:14eeabed9c67d441fdea4d'
    },
    fullCalendarLicenceKey: '0550664320-fcs-1680274161',
    production: true,
    qrCodeEnvPrefix: 'GestinergieDEV',
    qrCodeTrackPrefix: 'PISTE',
    cgu: {
        mandant: 'Softcom - DEV',
        address: {
            street: 'Rte du Jura',
            number: '37',
            extra: null,
            zip: '1700',
            locality: 'Fribourg'
        },
        phone: '0XX XXX XX XX',
        email: 'softcom@gestinergie.ch',
        tva: [
            'TVA Port : CHE-XXX.XXX.XXX',
            'TVA Camping : CHE-XXX.XXX.XXX'
        ]
    }
};

import {Component, Input, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../../../_shared/form-utils';
import {
    LightZoneDto, LightZoneScheduleDto,
    LightZoneScheduleInputDto, LightZoneScheduleUpdateDto,
    PublicLightingControlMode, PublicLightingControlService,
    PublicLightingZoneService
} from '../../../../../_services/configuration-services';
import {DateUtils} from '../../../../../_shared/date-utils';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';
import {firstValueFrom} from 'rxjs';
import {DaysPickerComponent} from '../../../../../_shared/_components/days-picker/days-picker.component';
import {NotificationsService} from '../../../../../_shared/notifications.service';
import {CustomDateFormatter} from '../../../../../_shared/custom-date-formatter';

@Component({
    selector: 'app-schedule-period-modal',
    templateUrl: './schedule-period-modal.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class SchedulePeriodModalComponent {
    @ViewChild(DaysPickerComponent) daysPickerComponent: DaysPickerComponent | null = null;

    @Input() serviceId: string;
    @Input() lightZoneSchedule: LightZoneScheduleDto | null = null;

    lightZones: LightZoneDto[] = [];

    scheduleControlModeForm: FormGroup<ScheduleControlModeInputForm>;
    PublicLightingControlMode = PublicLightingControlMode;

    timeRangeValidators = [FormUtils.timePeriodMaxIntervalValidator(12)]

    constructor(protected activeModal: NgbActiveModal,
                private notificationService: NotificationsService,
                private zoneService: PublicLightingZoneService,
                private controlService: PublicLightingControlService) {

        this.scheduleControlModeForm = new FormGroup<ScheduleControlModeInputForm>({
            lightZone: new FormControl<LightZoneDto>(null, Validators.required),
            dateRange: new FormGroup<DateRangeForm>({
                from: new FormControl(null, [Validators.required, FormUtils.datePatternValidator]),
                to: new FormControl(null, [Validators.required, FormUtils.datePatternValidator])
            }, [FormUtils.dateTimePeriodValidator]),
            timeRange: new FormGroup<TimeRangeForm>({
                from: new FormControl(null, Validators.required),
                to: new FormControl(null, Validators.required)
            }, this.timeRangeValidators),
            controlMode: new FormControl<PublicLightingControlMode>(null, Validators.required)
        })
    }

    async ngOnInit(): Promise<void> {
        this.lightZones = await firstValueFrom(this.zoneService.getLightZonesByServiceId(this.serviceId));

        if (this.lightZoneSchedule) {
            this.lightZone.setValue(this.lightZones.find(lz => lz.id === this.lightZoneSchedule.lightZoneId));

            this.dateFrom.setValue(DateUtils.dateToNgbDateStruct(this.lightZoneSchedule.startDate));
            this.dateTo.setValue(DateUtils.dateToNgbDateStruct(this.lightZoneSchedule.endDate));

            this.daysPickerComponent.setSelectedDay(this.lightZoneSchedule.days);

            this.timeFrom.setValue(DateUtils.stringToNgbTimeStruct(this.lightZoneSchedule.startTime));
            this.timeTo.setValue(DateUtils.stringToNgbTimeStruct(this.lightZoneSchedule.endTime));

            this.controlMode.setValue(this.lightZoneSchedule.mode);
        }

    }

    async addOrUpdateScheduleControlMode(): Promise<void> {

        const commonScheduleData = {
            lightZoneId: this.lightZone.value.id,
            mode: this.controlMode.value,
            startDate: DateUtils.ngbDateStructToDate(this.dateFrom.value),
            endDate: DateUtils.ngbDateStructToDate(this.dateTo.value),
            startTime: DateUtils.ngbTimeStructToString(this.timeFrom.value),
            endTime: DateUtils.ngbTimeStructToString(this.timeTo.value),
            days: this.daysPickerComponent.getSelectedDays()
        };

        if (!this.lightZoneSchedule) {
            const scheduleInput: LightZoneScheduleInputDto = new LightZoneScheduleInputDto({
                lightZoneServiceId: this.serviceId,
                ...commonScheduleData
            });

            this.controlService.scheduleLightZoneControlMode(scheduleInput.lightZoneId, scheduleInput).pipe().subscribe(_ => {
                this.notificationService.success({title: 'publicLighting.lightZone.schedule.notifications.addSuccess'});
                this.activeModal.close('success');
            });
        } else {
            const scheduleUpdate: LightZoneScheduleUpdateDto = new LightZoneScheduleUpdateDto({
                id: this.lightZoneSchedule.id,
                ...commonScheduleData
            });

            this.controlService.updateLightZoneControlModeSchedule(this.lightZoneSchedule.id, scheduleUpdate).pipe().subscribe(_ => {
                this.notificationService.success({title: 'publicLighting.lightZone.schedule.notifications.editSuccess'});
                this.activeModal.close('success');
            });
        }

    }

    selectedMode($event: PublicLightingControlMode): void {
        this.controlMode.setValue($event);
        this.controlMode.markAsDirty();

        if ($event === PublicLightingControlMode.Automatic) {
            this.timeRange.removeValidators(this.timeRangeValidators);
            this.timeTo.removeValidators(Validators.required);
        } else {
            this.timeRange.addValidators(this.timeRangeValidators);
            this.timeTo.addValidators(Validators.required);
        }

        this.timeTo.updateValueAndValidity();
        this.timeRange.updateValueAndValidity();
    }

    get lightZone(): FormControl<LightZoneDto> {
        return this.scheduleControlModeForm.controls.lightZone;
    }

    get dateRange(): FormGroup<DateRangeForm> {
        return this.scheduleControlModeForm.controls.dateRange;
    }

    get dateFrom(): FormControl<NgbDateStruct> {
        return this.scheduleControlModeForm.controls.dateRange.controls.from;
    }

    get dateTo(): FormControl<NgbDateStruct> {
        return this.scheduleControlModeForm.controls.dateRange.controls.to;
    }

    get timeRange(): FormGroup<TimeRangeForm> {
        return this.scheduleControlModeForm.controls.timeRange;
    }

    get timeFrom(): FormControl<NgbTimeStruct> {
        return this.scheduleControlModeForm.controls.timeRange.controls.from;
    }

    get timeTo(): FormControl<NgbTimeStruct> {
        return this.scheduleControlModeForm.controls.timeRange.controls.to;
    }

    get controlMode(): FormControl<PublicLightingControlMode> {
        return this.scheduleControlModeForm.controls.controlMode;
    }
}

interface ScheduleControlModeInputForm {
    lightZone: FormControl<LightZoneDto>,
    dateRange: FormGroup<DateRangeForm>,
    timeRange: FormGroup<TimeRangeForm>,
    controlMode: FormControl<PublicLightingControlMode>
}

interface DateRangeForm {
    from: FormControl<NgbDateStruct>,
    to: FormControl<NgbDateStruct>
}

interface TimeRangeForm {
    from: FormControl<NgbTimeStruct>,
    to: FormControl<NgbTimeStruct>
}


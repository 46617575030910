import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
    AlarmClassification,
    AlarmLightDto,
    AlarmSearchCriteriaDto,
    AlarmService,
    AlarmState,
    ISortCriteriaDto,
    PageableRequestDtoOfAlarmSearchCriteriaDto,
    PagedResultDtoOfAlarmLightDto,
    SortCriteriaDto,
    SortDirection
} from '../../_services/configuration-services';
import {
    faCalendar,
    faCheck,
    faExternalLinkAlt,
    faPen,
    faToggleOff,
    faToggleOn,
    faTrash,
    faWrench,
    faBan,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import {firstValueFrom, from, Subscription, switchMap} from 'rxjs';
import {NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {NotificationsService} from '../../_shared/notifications.service';
import {FormUtils} from '../../_shared/form-utils';
import {AppRoles} from '../../app.constants';
import {CustomDateFormatter} from '../../_shared/custom-date-formatter';
import {DateUtils} from '../../_shared/date-utils';
import {AlarmManagementModalComponent} from './alarm-management-modal/alarm-management-modal.component';
import {ConfirmModalService} from '../../_shared/_components/confirm-modal/confirm-modal.component';
import {TranslateUtils} from '../../_shared/translate-utils';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-alarm-management',
    templateUrl: './alarm-management.component.html',
    providers: [
        {provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class AlarmManagementComponent implements OnInit, OnDestroy {

    AppRoles = AppRoles;
    AlarmState = AlarmState;

    // Filters
    filter: {
        formGroup: UntypedFormGroup,
        classificationsDropdownList: string[]
        statesDropdownList: string[]
    } = {
        formGroup: null,
        classificationsDropdownList: Object.keys(AlarmClassification).filter(c => c !== AlarmClassification.Unknown),
        statesDropdownList: Object.keys(AlarmState).filter(s => s !== AlarmState.Unknown && s !== AlarmState.Solved)
    };

    textInputPattern = FormUtils.textInputPattern;
    dateTimeFormat = DateUtils.dateTimeFormat;

    // Icons
    icons = {
        calendar: faCalendar,
        edit: faPen,
        delete: faTrash,
        disable: faBan,
        inProgress: faWrench,
        open: faExternalLinkAlt,
        release: faCheck,
        times: faTimes,
        toggleOn: faToggleOn,
        toggleOff: faToggleOff
    };

    // Pagination
    readonly pageSize = 10;
    crtPage = 0;

    // Table content
    alarms: PagedResultDtoOfAlarmLightDto = new PagedResultDtoOfAlarmLightDto();

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Desc,
        property: 'appearedDateTime'
    });

    private pageRequest: PageableRequestDtoOfAlarmSearchCriteriaDto;

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    queryParams: any;

    constructor(private readonly modalService: NgbModal,
                private readonly confirmService: ConfirmModalService,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly alarmService: AlarmService,
                private readonly route: ActivatedRoute) {

        this.filter.formGroup = new UntypedFormGroup({
                classification: new UntypedFormControl(''),
                period: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
                // iotRegister: new FormControl(''),
                designation: new UntypedFormControl(''),
                location: new UntypedFormControl(''),
                state: new UntypedFormControl('')
            },
            {updateOn: 'change'});

        this.filter.formGroup.reset();
    }

    async ngOnInit(): Promise<void> {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.initializeBasePageRequest();

        await this.fetchAlarms(this.pageRequest);

        this.queryParams = this.route.snapshot.queryParams;
        if (this.queryParams.alarmId) {
            const alarm = this.alarms.results.find(a => a.id == this.queryParams.alarmId);
            this.openAlarmDetail(alarm);
        }
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    async fetchAlarms(pageableRequest: PageableRequestDtoOfAlarmSearchCriteriaDto): Promise<void> {
        this.alarms = await firstValueFrom(this.alarmService.searchAlarms(pageableRequest));
    }

    async setAlarmInProgress(alarm: AlarmLightDto): Promise<void> {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.inProgress'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.alarmService.setAlarmInProgressAlarm(alarm.id)
                    .pipe(
                        switchMap(_ => from(this.fetchAlarms(this.pageRequest)))
                    )
                    .subscribe(_ =>
                        this.notificationsService.success({message: 'alarms.management.notification.setInProgressSuccess'})
                    );
            }
        });
    }

    async releaseAlarm(alarm: AlarmLightDto): Promise<void> {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.release'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.alarmService.releaseAlarm(alarm.id)
                    .pipe(
                        switchMap(_ => from(this.fetchAlarms(this.pageRequest)))
                    )
                    .subscribe(_ =>
                        this.notificationsService.success({message: 'alarms.management.notification.releasedSuccess'})
                    );
            }
        });
    }

    openAlarmDetail(alarm: AlarmLightDto): void {
        const modal = this.modalService.open(AlarmManagementModalComponent, {size: 'xl'});
        modal.componentInstance.alarmId = alarm.id;
        modal.result
            .then(_ => {
                this.fetchAlarms(this.pageRequest);
            }, () => { /* catch the rejection */
            });
    }

    async applyFilters(): Promise<void> {
        if (this.filter.formGroup.invalid) {
            return;
        }

        this.crtPage = 0;
        this.initializeBasePageRequest();

        const period = this.period.value;

        const dateFrom = DateUtils.ngbDateStructToDate(period.from);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(period.to);
        dateTo?.setHours(23, 59, 59, 59);

        this.pageRequest.criteria = new AlarmSearchCriteriaDto({
            classification: AlarmClassification[this.classification.value],
            states: this.state.value ? [this.state.value] : [AlarmState.Active, AlarmState.InProgress],
            from: dateFrom,
            to: dateTo,
            designation: this.designation.value,
            location: this.location.value,
            sortCriteriaDto: this.currentSort,
            language: this.crtLang
        });

        await this.fetchAlarms(this.pageRequest);
    }

    async setPage(page: any): Promise<void> {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;

        await this.fetchAlarms(this.pageRequest);
    }

    async onSort(sortOrder: any): Promise<void> {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;

        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = new SortCriteriaDto(sortCriteriaDto);

        await this.fetchAlarms(this.pageRequest);
    }

    async clearFilter(): Promise<void> {
        // Clear inputs
        this.filter.formGroup.reset();

        // clear search
        this.initializeBasePageRequest();
        await this.fetchAlarms(this.pageRequest);
    }

    get classification(): UntypedFormControl {
        return this.filter.formGroup.get('classification') as UntypedFormControl;
    }

    get period(): UntypedFormGroup {
        return this.filter.formGroup.get('period') as UntypedFormGroup;
    }

    get periodFrom(): UntypedFormControl {
        return this.period.get('from') as UntypedFormControl;
    }

    get periodTo(): UntypedFormControl {
        return this.period.get('to') as UntypedFormControl;
    }

    get designation(): UntypedFormControl {
        return this.filter.formGroup.get('designation') as UntypedFormControl;
    }

    get location(): UntypedFormControl {
        return this.filter.formGroup.get('location') as UntypedFormControl;
    }

    get state(): UntypedFormControl {
        return this.filter.formGroup.get('state') as UntypedFormControl;
    }

    private initializeBasePageRequest(): void {
        this.pageRequest = new PageableRequestDtoOfAlarmSearchCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new AlarmSearchCriteriaDto({
            classification: null,
            from: null,
            to: null,
            designation: null,
            location: null,
            states: [AlarmState.Active, AlarmState.InProgress],
            sortCriteriaDto: this.currentSort
        });
    }
}

<div class="modal-header">
    <h4 translate="common.qrcode"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body text-center">
    <h5>{{ qrName }}</h5>
    <qrcode [qrdata]="qrData" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    <div class="text-break">{{ qrData }}</div>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()" translate="common.close">
    </button>
</div>


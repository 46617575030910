import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    ActivationSearchCriteriaDto,
    ActivationService, ISortCriteriaDto, MeasuringPointType,
    PageableRequestDtoOfActivationSearchCriteriaDto,
    PagedResultDtoOfActivationDto, SearchMeasuringPointResultDto, SearchUserDto,
    SortCriteriaDto,
    SortDirection,
} from '../../../_services/configuration-services';
import {DateUtils} from '../../../_shared/date-utils';
import {RolesService} from '../../../_shared/roles-service';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {faPlugCirclePlus, faPlugCircleXmark} from '@fortawesome/free-solid-svg-icons';
import {ExtendActivationModalComponent} from '../limited-duration/extend-activation-modal/extend-activation-modal.component';
import {NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../_shared/notifications.service';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormUtils} from '../../../_shared/form-utils';
import {SearchUserComponent} from '../../../_shared/_components/search-user/search-user.component';
import {SearchMeasuringPointComponent} from '../../../_shared/_components/search-measuring-point/search-measuring-point.component';
import {CustomDateFormatter} from '../../../_shared/custom-date-formatter';

@Component({
    selector: 'app-activation-management',
    templateUrl: './activation-management.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class ActivationManagementComponent implements OnInit, OnDestroy {

    @Input() serviceId!: string;

    @ViewChild('userSearch') userSearch: SearchUserComponent;
    @ViewChild('measuringPointSearch') measuringPointSearch: SearchMeasuringPointComponent;
    @ViewChild('activationTable') table;

    MeasuringPointType = MeasuringPointType;

    // Filters
    filterFormGroup: UntypedFormGroup = null;

    // Table content
    activations: PagedResultDtoOfActivationDto = new PagedResultDtoOfActivationDto();

    icons = {
        extend: faPlugCirclePlus,
        terminate: faPlugCircleXmark
    }

    // Pagination
    readonly pageSize = 10;
    crtPage = 0;

    private pageRequest: PageableRequestDtoOfActivationSearchCriteriaDto;

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Desc,
        property: 'firstActivationDateTime'
    });

    dateTimeFormat = DateUtils.dateTimeFormat;

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(private readonly activationService: ActivationService,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly modalService: NgbModal,
                private readonly confirmModal: ConfirmModalService,
                public readonly rolesService: RolesService) {

        this.filterFormGroup = new UntypedFormGroup({
                period: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
                userId: new UntypedFormControl(null),
                measuringPointId: new UntypedFormControl(null),
                state: new UntypedFormControl(null),
            },
            {updateOn: 'change'}
        );

    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.initializeBasePageRequest();

        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.fetchActivations(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    fetchActivations(pageRequest: PageableRequestDtoOfActivationSearchCriteriaDto): void {
        this.activationService.searchActivations(pageRequest).pipe().subscribe(result => {
            this.activations = result;
        });
    }

    extendActivation(activationId: string): void {
        const modal = this.modalService.open(ExtendActivationModalComponent, {centered: true});
        modal.componentInstance.activationId = activationId;
        modal.componentInstance.serviceId = this.serviceId;

        modal.result.then(() => {
            this.reloadData();
        });
    }

    terminateActivation(activationId: string): void {
        this.confirmModal.confirm({titleKey: 'common.confirmModal.title.disable'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.activationService.terminateActivation(activationId).pipe().subscribe(() => {
                    this.notificationsService.success({
                        title: 'activations.terminateSuccessMessage',
                        message: 'activations.terminateSuccessExplanation',
                    });
                    this.reloadData();
                });
            }
        });
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;

        this.fetchActivations(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.goToFirstPage();

        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteria = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = new SortCriteriaDto(sortCriteriaDto);

        this.fetchActivations(this.pageRequest);
    }

    reloadData(): void {
        this.fetchActivations(this.pageRequest);
    }

    selectUser(user: SearchUserDto | null): void {
        this.userId.setValue(user?.id);
    }

    selectMeasuringPoint(measuringPoint: SearchMeasuringPointResultDto | null): void {
        this.measuringPointId.setValue(measuringPoint?.id);
    }

    applyFilter(): void {
        this.initializeBasePageRequest();
        this.fetchActivations(this.pageRequest);
    }

    clearFilter(): void {
        // Clear inputs
        this.filterFormGroup.reset();
        this.userSearch?.clearInput();
        this.measuringPointSearch.clearInput();

        this.applyFilter();
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }

    private initializeBasePageRequest(): void {
        let userId = null;
        if (!this.rolesService.hasRoleAdmin()) {
            userId = this.rolesService.getUserId();
        }

        const period = this.period.value;

        const dateFrom = DateUtils.ngbDateStructToDate(period.from);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(period.to);
        dateTo?.setHours(23, 59, 59, 59);

        this.pageRequest = new PageableRequestDtoOfActivationSearchCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new ActivationSearchCriteriaDto({
            serviceId: this.serviceId,
            userId: this.userId?.value ?? userId,
            measuringPointId: this.measuringPointId.value,
            from: dateFrom,
            to: dateTo,
            active: this.state.value,
            sortCriteria: this.currentSort
        });
    }

    get period(): UntypedFormGroup {
        return this.filterFormGroup.get('period') as UntypedFormGroup;
    }

    get userId(): UntypedFormControl {
        return this.filterFormGroup.get('userId') as UntypedFormControl;
    }

    get measuringPointId(): UntypedFormControl {
        return this.filterFormGroup.get('measuringPointId') as UntypedFormControl;
    }

    get state(): UntypedFormControl {
        return this.filterFormGroup.get('state') as UntypedFormControl;
    }
}

import {Injectable} from '@angular/core';
import {DynamicScriptLoaderService} from './dynamic-script-loader.service';
import {
    ActivationInputDto,
    BookingDto, ConsumeServicePayloadDtoOfChargingProcessInputDto,
    CraningBookingInputDto,
    DatatransCurrency, DatatransPaymentRequestDto,
    DatatransPaymentRequestInputDto,
    DatatransService,
    DatatransTransactionPayload,
    InitRequestLanguage,
    PurchaseInputDto,
    ServiceType,
} from './configuration-services';
import {TranslateUtils} from '../_shared/translate-utils';
import {firstValueFrom, Observable} from 'rxjs';

declare const Datatrans: any;

@Injectable({
    providedIn: 'root'
})
export class InternalDatatransService {

    private static readonly DATATRANS_SCRIPT_KEY = 'datatrans';

    constructor(
        private readonly datatransService: DatatransService,
        private readonly dynamicScriptLoaderService: DynamicScriptLoaderService,
        private readonly translateUtils: TranslateUtils
    ) {
    }

    async prepare(): Promise<void> {
        const configuration = await firstValueFrom(this.datatransService.configuration());
        this.dynamicScriptLoaderService.AddScript([{
            name: InternalDatatransService.DATATRANS_SCRIPT_KEY,
            src: configuration.datatransJsUrl
        }]);
    }

    payEwallet(amountInCent: number): void {
        this.pay(new DatatransPaymentRequestInputDto({
            amount: amountInCent,
            currency: DatatransCurrency.Chf,
            redirectUrl: InternalDatatransService.buildRedirectUrl(null),
            language: this.getLanguage()
        }), false);
    }

    payHydrowash(trackId: string, amountInCent: number): void {
        this.pay(new DatatransPaymentRequestInputDto({
            amount: amountInCent,
            currency: DatatransCurrency.Chf,
            serviceType: ServiceType.Hydrowash,
            payload: new DatatransTransactionPayload({
                hydrowashTrackId: trackId
            }),
            redirectUrl: InternalDatatransService.buildRedirectUrl(null),
            language: this.getLanguage()
        }), false);
    }

    payActivation(activation: ActivationInputDto, amountInCent: number): void {
        const redirectUrl = encodeURIComponent(window.location.toString().split('/activate/')[0])
        this.pay(new DatatransPaymentRequestInputDto({
            amount: amountInCent,
            currency: DatatransCurrency.Chf,
            serviceType: ServiceType.TimeLimited,
            payload: new DatatransTransactionPayload({
                activationInputDto: activation
            }),
            redirectUrl: InternalDatatransService.buildRedirectUrl(redirectUrl),
            language: this.getLanguage()
        }), !activation.userId);
    }

    payBooking(booking: BookingDto, amountInCent: number): void {
        this.pay(new DatatransPaymentRequestInputDto({
            amount: amountInCent,
            currency: DatatransCurrency.Chf,
            serviceType: ServiceType.Bookable,
            payload: new DatatransTransactionPayload({
                bookingInputDto: booking
            }),
            redirectUrl: InternalDatatransService.buildRedirectUrl(null),
            language: this.getLanguage()
        }), !booking.userId);
    }

    payCraningBooking(craningBookingSlotId: number, craningBooking: CraningBookingInputDto, amountInCent: number): void {
        this.pay(new DatatransPaymentRequestInputDto({
            amount: amountInCent,
            currency: DatatransCurrency.Chf,
            serviceType: ServiceType.CraningAndWinterizing,
            payload: new DatatransTransactionPayload({
                craningBookingInputDto: craningBooking,
                craningBookingSlotId
            }),
            redirectUrl: InternalDatatransService.buildRedirectUrl(null),
            language: this.getLanguage()
        }), !craningBooking.userId);
    }

    payWifi(purchase: PurchaseInputDto, amountInCent: number): void {
        this.pay(new DatatransPaymentRequestInputDto({
            amount: amountInCent,
            currency: DatatransCurrency.Chf,
            serviceType: ServiceType.Wifi,
            payload: new DatatransTransactionPayload({
                purchaseInputDto: purchase
            }),
            redirectUrl: InternalDatatransService.buildRedirectUrl(null),
            language: this.getLanguage()
        }), false);
    }

    payChargingProcess(consumeServicePayload: ConsumeServicePayloadDtoOfChargingProcessInputDto, amountInCent: number): void {
        const redirectUrl = encodeURIComponent(window.location.toString().split('/start-charge/')[0]);
        this.pay(new DatatransPaymentRequestInputDto({
            amount: amountInCent,
            currency: DatatransCurrency.Chf,
            serviceType: ServiceType.ChargingStations,
            payload: new DatatransTransactionPayload({
                consumeServicePayload: consumeServicePayload
            }),
            redirectUrl: InternalDatatransService.buildRedirectUrl(redirectUrl),
            language: this.getLanguage()
        }), !consumeServicePayload.userId);
    }

    private async pay(requestInputDto: DatatransPaymentRequestInputDto, forVisitor: boolean): Promise<void> {
        await this.prepare();
        await this.dynamicScriptLoaderService.load(InternalDatatransService.DATATRANS_SCRIPT_KEY);

        let request: Observable<DatatransPaymentRequestDto>;
        if (forVisitor) {
            request = this.datatransService.paymentRequestForVisitor(requestInputDto);

        } else {
            request = this.datatransService.paymentRequest(requestInputDto);
        }

        request.pipe().subscribe(datatransPaymentRequest => {
            Datatrans.startPayment({
                transactionId: datatransPaymentRequest.datatransTransactionId
            });
        });
    }

    private static buildRedirectUrl(redirectUrl: string | null): string {
        redirectUrl = redirectUrl ?? encodeURIComponent(window.location.toString().split('?')[0]);
        return redirectUrl;
    }

    private getLanguage(): InitRequestLanguage {
        const lang = this.translateUtils.getLanguage();

        switch (lang) {
            case 'fr':
                return InitRequestLanguage.Fr;
            case 'de':
                return InitRequestLanguage.De;
            case 'it':
                return InitRequestLanguage.It;
            case 'en':
                return InitRequestLanguage.En;
            default:
                return InitRequestLanguage.Fr;
        }
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {
    KocoDeviceDto,
    KocoDeviceInputDto,
    KocoService
} from '../../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {CustomerConfigService} from '../../../../_shared/customer-config-service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../../_shared/form-utils';

@Component({
    selector: 'app-koco-dumpster-management-modal',
    templateUrl: './koco-dumpster-management-modal.component.html'
})
export class KocoDumpsterManagementModalComponent implements OnInit {

    @Input() serviceId: string;
    @Input() kocoDevice: KocoDeviceDto | null;

    FormUtils = FormUtils;

    kocoDeviceForm: UntypedFormGroup;

    constructor(public activeModal: NgbActiveModal,
                private readonly kocoService: KocoService,
                private readonly notificationService: NotificationsService,
                public readonly customerConfigService: CustomerConfigService) {

        this.kocoDeviceForm = new UntypedFormGroup({
                deviceId: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
                nameFR: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
                nameDE: new UntypedFormControl(null, Validators.maxLength(50)),
                nameIT: new UntypedFormControl(null, Validators.maxLength(50)),
                nameEN: new UntypedFormControl(null, Validators.maxLength(50)),
                descriptionFR: new UntypedFormControl(null, Validators.maxLength(100)),
                descriptionDE: new UntypedFormControl(null, Validators.maxLength(100)),
                descriptionIT: new UntypedFormControl(null, Validators.maxLength(100)),
                descriptionEN: new UntypedFormControl(null, Validators.maxLength(100)),
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        if (this.kocoDevice) {
            this.deviceId.setValue(this.kocoDevice.deviceId);
            this.nameFR.setValue(this.kocoDevice.nameFR);
            this.nameDE.setValue(this.kocoDevice.nameDE);
            this.nameIT.setValue(this.kocoDevice.nameIT);
            this.nameEN.setValue(this.kocoDevice.nameEN);
            this.descriptionFR.setValue(this.kocoDevice.descriptionFR);
            this.descriptionDE.setValue(this.kocoDevice.descriptionDE);
            this.descriptionIT.setValue(this.kocoDevice.descriptionIT);
            this.descriptionEN.setValue(this.kocoDevice.descriptionEN);
        }
    }

    public addOrUpdateKocoDevice(): void {
        if (this.kocoDeviceForm.invalid) {
            return;
        }
        const deviceToSave = new KocoDeviceInputDto({
            deviceId: this.deviceId.value,
            serviceId: this.serviceId,
            nameFR: this.nameFR.value,
            nameDE: this.nameDE.value,
            nameIT: this.nameIT.value,
            nameEN: this.nameEN.value,
            descriptionFR: this.descriptionFR.value,
            descriptionDE: this.descriptionDE.value,
            descriptionIT: this.descriptionIT.value,
            descriptionEN: this.descriptionEN.value,
        });

        if (!this.kocoDevice) {
            this.kocoService.createKocoDevice(deviceToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.kocoDumpster.notifications.addSuccess'});
                this.activeModal.close('success');
            });

        } else {
            this.kocoService.updateKocoDevice(this.kocoDevice.deviceId, deviceToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.kocoDumpster.notifications.updateSuccess'});
                this.activeModal.close('success');
            });
        }
    }

    get deviceId(): UntypedFormControl {
        return this.kocoDeviceForm.get('deviceId') as UntypedFormControl;
    }

    get nameFR(): UntypedFormControl {
        return this.kocoDeviceForm.get('nameFR') as UntypedFormControl;
    }

    get nameDE(): UntypedFormControl {
        return (this.kocoDeviceForm.get('nameDE') as UntypedFormControl);
    }

    get nameIT(): UntypedFormControl {
        return this.kocoDeviceForm.get('nameIT') as UntypedFormControl;
    }

    get nameEN(): UntypedFormControl {
        return this.kocoDeviceForm.get('nameEN') as UntypedFormControl;
    }

    get descriptionFR(): UntypedFormControl {
        return this.kocoDeviceForm.get('descriptionFR') as UntypedFormControl;
    }

    get descriptionDE(): UntypedFormControl {
        return this.kocoDeviceForm.get('descriptionDE') as UntypedFormControl;
    }

    get descriptionIT(): UntypedFormControl {
        return this.kocoDeviceForm.get('descriptionIT') as UntypedFormControl;
    }

    get descriptionEN(): UntypedFormControl {
        return this.kocoDeviceForm.get('descriptionEN') as UntypedFormControl;
    }
}

<div class="modal-header">
    <h4 class="modal-title" translate="services.hydrowash.activation.title"></h4>
    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">

    <form [formGroup]="creditForm">

        <div class="row">
            <div class="col-12">
                <p><strong>{{trackId}}</strong></p>
            </div>

            <div class="col-12">
                <label class="form-label">{{ 'services.hydrowash.activation.form.amount' | translate }}*</label>

                <div class="input-group">
                    <input class="form-control" id="amount" type="number" name="amount"
                           min="0.1"
                           step="any"
                           formControlName="creditAmount" required>
                    <span class="input-group-text">CHF</span>
                </div>
            </div>

            <div class="col-12" *ngIf="availablePaymentTypes.length > 1">
                <label class="form-label">{{ 'common.terms.paymentType' | translate }}*</label>

                <div>
                    <app-payment-type-chooser
                        [availablePaymentTypes]="availablePaymentTypes"
                        (selectedPaymentType)="selectPaymentType($event)"></app-payment-type-chooser>
                </div>
            </div>

            <app-show-cgv [formGroup]="creditForm">
            </app-show-cgv>

        </div>

    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.no">
    </button>

    <button type="submit" class="btn btn-primary"
            [disabled]="creditForm.invalid"
            (click)="activateHydrowash()"
            translate="services.hydrowash.activation.confirmText">
    </button>
</div>





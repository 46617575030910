import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../notifications.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
    selector: 'document-upload-component',
    templateUrl: './document-upload.component.html'
})
export class DocumentUploadComponent implements OnInit {

    // modal inputs
    @Input() id: string | null;
    @Input() uploadAction: any;

    equipmentDocumentForm: UntypedFormGroup;
    private maxFileSizeInMB = 50;

    constructor(public activeModal: NgbActiveModal,
                private readonly notificationService: NotificationsService) {
    }

    ngOnInit(): void {
        this.equipmentDocumentForm = new UntypedFormGroup({
            title: new UntypedFormControl(null, Validators.required),
            file: new UntypedFormControl(null, Validators.required),
            fileData: new UntypedFormControl(null, Validators.required),
        });
    }


    fileSelected(files: FileList): void {
        if (files.length > 0) {
            const fileData = files[0];
            const size = fileData.size;
            if ((size / 1024 / 1024) > this.maxFileSizeInMB) {
                this.file.setErrors({overSizeLimit: {max: '50'}});
            } else {
                this.file.setErrors(null);
                this.fileData.patchValue(fileData);
            }
        }
    }

    uploadDocument() {
        let upload: Observable<void>;
        const file = {
            data: this.fileData.value,
            fileName: this.fileData.value.name
        };
        upload = this.uploadAction(this.id, this.title.value, file);
        if (upload !== null) {
            upload.pipe().subscribe({
                next: () => {
                    this.notificationService.success({title: 'common.document.notifications.addSuccess'});
                    this.activeModal.close('success');
                },
                error: () => this.notificationService.error({title: 'common.document.notifications.error'})
            });
        }
    }

    get title(): UntypedFormControl {
        return this.equipmentDocumentForm.get('title') as UntypedFormControl;
    }

    get file(): UntypedFormControl {
        return this.equipmentDocumentForm.get('file') as UntypedFormControl;
    }

    get fileData(): UntypedFormControl {
        return this.equipmentDocumentForm.get('fileData') as UntypedFormControl;
    }

}

import {Component, TemplateRef, ViewChild} from '@angular/core';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-event-popover',
    templateUrl: './calendar-event-popover.component.html'
})
export class CalendarEventPopoverComponent {
    template: TemplateRef<any>;
    @ViewChild(NgbPopover, {static: true}) popover: NgbPopover;
}

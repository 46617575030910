import {Injectable} from '@angular/core';
import {
    BillingRecurrence,
    PaymentTypes,
    ServiceType,
    TariffConfigDto,
    TariffType,
    TimeOfPayment
} from '../_services/configuration-services';

@Injectable({
    providedIn: 'root'
})
export class TariffUtils {

    public static initTimeOfPayment(serviceType: ServiceType, selectedTariffConfig: TariffConfigDto): TimeOfPayment[] {
        let timesOfPaymentAllowed: TimeOfPayment[] = [];

        switch (serviceType) {
            case ServiceType.FalconicDumpster:
                timesOfPaymentAllowed.push(TimeOfPayment.Consumption);
                break;
            case ServiceType.KocoDumpster:
                timesOfPaymentAllowed.push(TimeOfPayment.Consumption);
                break;
            case ServiceType.Hydrowash:
                timesOfPaymentAllowed.push(TimeOfPayment.Activation);
                break;
            case ServiceType.AccessControl:
                timesOfPaymentAllowed.push(TimeOfPayment.Consumption);
                break;
            case ServiceType.Energy:
                timesOfPaymentAllowed.push(TimeOfPayment.Periodic);
                break;
            case ServiceType.TimeLimited:
                timesOfPaymentAllowed.push(TimeOfPayment.Activation, TimeOfPayment.Consumption);
                break;
            case ServiceType.Bookable:
                timesOfPaymentAllowed.push(TimeOfPayment.Reservation, TimeOfPayment.Activation);
                break;
            case ServiceType.Wifi:
                timesOfPaymentAllowed.push(TimeOfPayment.Reservation);
                break;
            case ServiceType.CraningAndWinterizing:
                timesOfPaymentAllowed.push(TimeOfPayment.Reservation);
                break;
            case ServiceType.MooringPlace:
                // No payment available
                break;
            case ServiceType.UserAlarm:
                timesOfPaymentAllowed.push(TimeOfPayment.Periodic);
                break;
            case ServiceType.ChargingStations:
                timesOfPaymentAllowed.push(TimeOfPayment.Activation, TimeOfPayment.Consumption);
                break;
            case ServiceType.Default:
                timesOfPaymentAllowed.push(TimeOfPayment.Reservation);
                break;
        }

        // Remove payment methods depending the selected TariffType
        switch (selectedTariffConfig.tariffType){
            case TariffType.Flat:
                if (serviceType === ServiceType.KocoDumpster) {
                    timesOfPaymentAllowed = timesOfPaymentAllowed.filter(t => t === TimeOfPayment.Activation || t === TimeOfPayment.Periodic || t === TimeOfPayment.Reservation || t === TimeOfPayment.Consumption);
                }
                else if (serviceType === ServiceType.ChargingStations) {
                    timesOfPaymentAllowed = timesOfPaymentAllowed.filter(t => t === TimeOfPayment.Activation || t === TimeOfPayment.Consumption);
                }
                else {
                    timesOfPaymentAllowed = timesOfPaymentAllowed.filter(t => t === TimeOfPayment.Activation || t === TimeOfPayment.Periodic || t === TimeOfPayment.Reservation);
                }
                break;
            case TariffType.Consumption:
                timesOfPaymentAllowed = timesOfPaymentAllowed.filter(t => t === TimeOfPayment.Consumption || t === TimeOfPayment.Periodic);
                break;
            case TariffType.Duration:
                timesOfPaymentAllowed = timesOfPaymentAllowed.filter(t => t === TimeOfPayment.Consumption);
                break;
            case TariffType.ConsumptionAndDuration:
                timesOfPaymentAllowed = timesOfPaymentAllowed.filter(t => t === TimeOfPayment.Consumption);
                break;
            case TariffType.Prepaid:
                timesOfPaymentAllowed = timesOfPaymentAllowed.filter(t => t === TimeOfPayment.Activation);
                break;
            case TariffType.FlatAndDuration:
            case TariffType.FlatAndConsumption:
            case TariffType.FlatAndConsumptionAndDuration:
                timesOfPaymentAllowed = timesOfPaymentAllowed.filter(t => t === TimeOfPayment.Consumption);
                break;
        }

        return timesOfPaymentAllowed;
    }

    public static initBillingRecurrence(serviceType: ServiceType): BillingRecurrence[] {
        let availableBillingRecurrence = [];

        if (serviceType === ServiceType.Energy) {
            availableBillingRecurrence = Object.keys(BillingRecurrence);

        } else if (serviceType === ServiceType.UserAlarm) {
            availableBillingRecurrence = [BillingRecurrence.Annual];
        }

        return availableBillingRecurrence;
    }

    public static initPaymentTypes(serviceType: ServiceType, selectedTimeOfPayment: TimeOfPayment): PaymentTypes[] {
        let paymentTypesAllowed: PaymentTypes[] = [];

        switch (serviceType) {
            case ServiceType.FalconicDumpster:
                paymentTypesAllowed.push(PaymentTypes.Wallet);
                break;
            case ServiceType.KocoDumpster:
                paymentTypesAllowed.push(PaymentTypes.Wallet);
                break;
            case ServiceType.Hydrowash:
                paymentTypesAllowed.push(PaymentTypes.Wallet, PaymentTypes.Ebanking, PaymentTypes.Billing);
                break;
            case ServiceType.AccessControl:
                paymentTypesAllowed.push(PaymentTypes.Wallet);
                break;
            case ServiceType.Energy:
                paymentTypesAllowed.push(PaymentTypes.Wallet, PaymentTypes.Billing);
                break;
            case ServiceType.TimeLimited:
                paymentTypesAllowed.push(PaymentTypes.Wallet, PaymentTypes.Ebanking, PaymentTypes.Billing);
                break;
            case ServiceType.Bookable:
                paymentTypesAllowed.push(PaymentTypes.Wallet, PaymentTypes.Ebanking, PaymentTypes.Billing, PaymentTypes.Cash);
                break;
            case ServiceType.Wifi:
                paymentTypesAllowed.push(PaymentTypes.Wallet, PaymentTypes.Ebanking, PaymentTypes.Billing, PaymentTypes.Cash);
                break;
            case ServiceType.CraningAndWinterizing:
                paymentTypesAllowed.push(PaymentTypes.Wallet, PaymentTypes.Ebanking, PaymentTypes.Billing, PaymentTypes.Cash);
                break;
            case ServiceType.MooringPlace:
                // No payment available
                break;
            case ServiceType.UserAlarm:
                paymentTypesAllowed.push(PaymentTypes.Wallet, PaymentTypes.Billing);
                break;
            case ServiceType.ChargingStations:
                paymentTypesAllowed.push(PaymentTypes.Wallet, PaymentTypes.Ebanking, PaymentTypes.Billing);
                break;
            case ServiceType.Default:
                paymentTypesAllowed.push(PaymentTypes.Cash);
                break;
        }

        switch (selectedTimeOfPayment) {
            case TimeOfPayment.Reservation:
                // Nothing to filter
                break;

            case TimeOfPayment.Activation:
                // Remove E-Banking because the activation is done outside the webapp
                if (serviceType === ServiceType.Bookable) {
                    paymentTypesAllowed = paymentTypesAllowed.filter(t => t !== PaymentTypes.Ebanking);
                }
                break;

            case TimeOfPayment.Consumption:
                // Charging Stations services allow to pay consumption with E-Banking
                if (serviceType === ServiceType.ChargingStations) {
                    paymentTypesAllowed = paymentTypesAllowed.filter(t => t === PaymentTypes.Billing || t === PaymentTypes.Wallet || t === PaymentTypes.Ebanking);

                } else {
                    paymentTypesAllowed = paymentTypesAllowed.filter(t => t === PaymentTypes.Billing || t === PaymentTypes.Wallet);
                }
                break;

            case TimeOfPayment.Periodic:
                paymentTypesAllowed = paymentTypesAllowed.filter(t => t === PaymentTypes.Billing || t === PaymentTypes.Wallet);
                break;
        }

        return paymentTypesAllowed;
    }

    public static removeUnavailablePaymentTypes(paymentTypes: PaymentTypes[], hasAdminRole: boolean, isVisitor): void {
        if (isVisitor) {
           const idx = paymentTypes.indexOf(PaymentTypes.Wallet);
           if (idx > -1) {
               paymentTypes.splice(idx, 1);
           }
       }

        // Remove Ebanking payment for admin
        if (hasAdminRole) {
            const idx = paymentTypes.indexOf(PaymentTypes.Ebanking);
            if (idx > -1) {
                paymentTypes.splice(idx, 1);
            }
        }

        // Remove Cash payment if not admin
        if (!hasAdminRole) {
            const idx = paymentTypes.indexOf(PaymentTypes.Cash);
            if (idx > -1) {
                paymentTypes.splice(idx, 1);
            }
        }
    }
}

<div class="content-container bg-white">
    <h1 translate="maintenance.title"></h1>

    <h4 translate="equipments.title"></h4>

    <div class="btn-bar">
        <button class="btn btn-primary" type="submit" [routerLink]="['equipment/create']"
                translate="equipments.addEquipment">
        </button>
    </div>

    <!-- Filters -->
    <div [formGroup]="filterForm">
        <div class="row">
            <!-- Name Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="equipments.filter.name"></label>
                <input class="form-control " id="name"
                       type="text" maxlength="75"
                       placeholder="{{ 'equipments.filter.name' | translate }}"
                       [formControl]="name">
            </div>

            <!-- Location Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="equipments.filter.location"></label>
                <input class="form-control " id="location"
                       type="text" maxlength="75"
                       placeholder="{{ 'equipments.filter.location' | translate }}"
                       [formControl]="equipmentLocation">
            </div>

            <!-- Category Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="equipments.filter.equipmentCategory"></label>
                <select class="form-control form-select" name="equipmentCategory" [formControl]="equipmentCategory">
                    <option [ngValue]="null" translate="equipments.filter.null"></option>
                    <option *ngFor="let item of equipmentCategories;"
                            [ngValue]="item.id">{{item['label' + crtLang.toUpperCase()]}}</option>
                </select>
            </div>

            <!-- Date -->
            <div class="default-filter-form-field">
                <app-date-period-picker [periodControl]="nextEventPeriod"
                                        inputLabel="equipments.filter.date"></app-date-period-picker>
            </div>
        </div>
        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   (click)="clearFilter()">
            <input class="btn btn-primary" type="button" (click)="filterBillings()"
                   [value]="'common.filter' | translate"
                   [disabled]="filterForm.invalid">
        </div>
    </div>

    <!-- Datatable -->
    <ngx-datatable #table
                   class="material"
                   [columnMode]="'force'"
                   [rows]="equipments.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   [rowHeight]="'100%'"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="equipments.rowCount"
                   [sorts]="[{prop: 'Name', dir: 'Desc'}]"
                   [messages]="{
                        totalMessage: 'common.datatable.total' | translate
                }"
                   [offset]="crtPage"
                   [limit]="equipments.pageSize"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [scrollbarH]="true">

        <!-- Name -->
        <ngx-datatable-column [width]="150" prop="name"
                              [name]="'equipments.table.name' | translate"
                              sortable="true">
        </ngx-datatable-column>

        <!-- Location -->
        <ngx-datatable-column [width]="150" prop="location"
                              [name]="'equipments.table.location' | translate"
                              sortable="true">
        </ngx-datatable-column>

        <!-- Category -->
        <ngx-datatable-column [width]="175  " prop="equipmentCategory"
                              [name]="'equipments.table.equipmentCategory' | translate"
                              sortable="true">

            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.category['label' + crtLang.toUpperCase()] }}
            </ng-template>

        </ngx-datatable-column>

        <!-- NextEvent Date-->
        <ngx-datatable-column [width]="150" prop="nextEvent"
                              [name]="'equipments.table.nextEvent' | translate"
                              sortable="true">

            <ng-template ngx-datatable-cell-template let-row="row">
                <a class="link-primary" *ngIf="row.nextEvent"
                   (click)="openEventModal({startDate: null, eventId: row.nextEventId})">
                    {{ row.nextEvent | date }}
                </a>
                <span *ngIf="!row.nextEvent">-</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="50" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <button class="btn btn-link btn-sm" [routerLink]="['equipment', row.id]"
                        [title]="'common.tooltip.edit' | translate">
                    <fa-icon [icon]="icons.edit"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-curPage="curPage">
                <div class="page-count">
                    {{ 'equipments.table.results' | translate:{EquipmentCount: rowCount} }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="table.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

    <h4 translate="equipments.events.calendar"></h4>

    <div class="btn-bar">
        <button class="btn btn-primary" type="button" (click)="openEventModal(null)"
                translate="equipments.events.add">
        </button>
    </div>

    <app-maintenance-calendar #eventCalendar
                              (openEventModal)="openEventModal($event)"></app-maintenance-calendar>
</div>

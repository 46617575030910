import {Component, OnDestroy, OnInit} from '@angular/core';

import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    CashierTransactionInputDto,
    PaymentMethod,
    ServiceLightDto,
    ServiceService,
    ServiceType,
    TransactionService,
    TransactionSource,
    TransactionType,
    UserService
} from '../../../_services/configuration-services';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {FormUtils} from '../../../_shared/form-utils';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {TranslateUtils} from "../../../_shared/translate-utils";
import {switchMap} from "rxjs/operators";
import {CustomerConfigService} from '../../../_shared/customer-config-service';

@Component({
    selector: 'app-cashier-transaction',
    templateUrl: './cashier-transaction.component.html'
})
export class CashierTransactionComponent implements OnInit, OnDestroy {

    FormUtils = FormUtils;
    TransactionType = TransactionType;
    TransactionSource = TransactionSource;

    cashierTransactionForm: FormGroup<CashierTransactionForm>;

    ewalletId: string = null!;
    private userId: string = null!;
    userName: string | null = null;

    transactionSources = [];
    disabledSources = [];
    // Sources that are disabled globally because of platform features enabled/disabled
    globallyDisabledSources = [];
    transactionTypes = [];
    services: ServiceLightDto[] = [];
    paymentMethods = [];

    private transactionTypeValueChanges$: Subscription = null!;
    private transactionSourceValueChanges$: Subscription = null!;
    private customerConfigLoadedSubscription: Subscription = null!;

    private langChangeSubscription: Subscription = null!;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(
        private readonly userService: UserService,
        private readonly serviceService: ServiceService,
        private readonly transactionService: TransactionService,
        private readonly translateService: TranslateService,
        private readonly modalService: NgbModal,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly location: Location,
        private readonly customerConfigService: CustomerConfigService) {

        this.cashierTransactionForm = new FormGroup<CashierTransactionForm>({
            comment: new FormControl<string>(null, [Validators.required, Validators.maxLength(255)]),
            amount: new FormControl<number>(null, [Validators.required, FormUtils.allNumberDecimalExceptZeroPatternValidator]),
            transactionType: new FormControl<TransactionType>(TransactionType.EWalletCredit, [Validators.required]),
            transactionSource: new FormControl<TransactionSource>(TransactionSource.Ebanking, [Validators.required]),
            serviceId: new FormControl<string>(null),
            paymentMethod: new FormControl<PaymentMethod | null>(null)
        });
    }

    async ngOnInit(): Promise<void> {
        this.userId = this.route.snapshot.params['userId'];
        this.userName = this.route.snapshot.queryParamMap.get('username');

        this.customerConfigLoadedSubscription = this.customerConfigService.configurationLoadedMessage.subscribe(isConfigurationLoaded => {
            if (!isConfigurationLoaded) {
                return;
            }

            if (!this.customerConfigService.isCashMachineEnabled()) {
                this.globallyDisabledSources.push(TransactionSource.CashMachine);
            }

            if (!this.customerConfigService.isEPaymentEnabled()) {
                this.globallyDisabledSources.push(TransactionSource.Ebanking);
            }

            if (!this.customerConfigService.isExternalBillingEnabled()) {
                this.globallyDisabledSources.push(TransactionSource.Billing);
            }

            if (!this.customerConfigService.isEwalletEnabled()) {
                this.globallyDisabledSources.push(TransactionSource.EWallet);
            }

            this.populateTransactionSourcesEnumDropdowns();
        });

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
            this.populateServicesEnumDropdowns();
            this.populateTransactionTypesEnumDropdowns();
            this.populateTransactionSourcesEnumDropdowns();
            this.populatePaymentMethodsEnumDropdowns();
        });

        const ewallet = await firstValueFrom(this.userService.getEwalletByUserId(this.userId));
        this.ewalletId = ewallet.id;

        this.transactionTypeValueChanges$ = this.cashierTransactionForm.get('transactionType').valueChanges.subscribe(() => {
            this.disabledSources = [];

            switch (this.transactionType.value) {
                case TransactionType.ServiceConsumption:
                    this.disabledSources.push(TransactionSource.CashMachine);
                    this.serviceId.setValidators([Validators.required]);
                    break;
                default:
                    this.disabledSources.push(TransactionSource.EWallet);
                    this.serviceId.clearValidators();
                    this.serviceId.reset();
                    break;
            }

            this.serviceId.updateValueAndValidity();

            this.populateTransactionSourcesEnumDropdowns();
        });

        this.transactionSourceValueChanges$ = this.cashierTransactionForm.get('transactionSource').valueChanges.pipe(
            switchMap((source) => {
                if (source === TransactionSource.Cash && this.transactionType.value === TransactionType.EWalletCredit) {
                    // Add payment method to the form
                    this.paymentMethod.setValue(PaymentMethod.Cash);
                    this.paymentMethod.setValidators([Validators.required]);
                } else {
                    // Remove payment method from the form
                    this.paymentMethod.clearValidators();
                    this.paymentMethod.reset();
                }

                if (source === TransactionSource.PointOfSale) {
                    // Filter services to only show those that are POS
                    return this.populateServicesEnumDropdowns(ServiceType.PointOfSale);
                } else {
                    return this.populateServicesEnumDropdowns();
                }
            })
        ).subscribe();

        await this.populateServicesEnumDropdowns();
        this.populateTransactionTypesEnumDropdowns();
        this.populateTransactionSourcesEnumDropdowns();
        this.populatePaymentMethodsEnumDropdowns();
    }

    ngOnDestroy(): void {
        this.transactionTypeValueChanges$.unsubscribe();
        this.transactionSourceValueChanges$.unsubscribe();
        this.customerConfigLoadedSubscription.unsubscribe();

        this.langChangeSubscription.unsubscribe();

        this.modalService.dismissAll('destroy');
    }

    async submit(): Promise<void> {
        if (this.cashierTransactionForm.invalid) {
            return;
        }

        const transaction: CashierTransactionInputDto = new CashierTransactionInputDto({
            amount: this.cashierTransactionForm.value.amount,
            userComment: this.cashierTransactionForm.value.comment.trim(),
            ewalletId: this.ewalletId,
            userId: this.userId,
            transactionType: this.transactionType.value,
            transactionSource: this.transactionSource.value,
            serviceId: this.serviceId.value,
            paymentMethod: this.paymentMethod.value
        });

        try {
            await firstValueFrom(this.transactionService.createCashierTransaction(transaction));
            await this.router.navigate(['ewallet', this.userId]);
        } catch (error) {
            throw error;
        }
    }

    private populateTransactionSourcesEnumDropdowns(): void {
        this.transactionSources = [];

        const transactionSources = Object.keys(TransactionSource).filter(s => s !== TransactionSource.Unknown);

        transactionSources.forEach(value => {
            if (!this.disabledSources.concat(this.globallyDisabledSources).includes(TransactionSource[value])) {
                this.transactionSources.push({
                    itemId: value,
                    itemVal: this.translateService.instant('transactions.enums.sources.' + value.toLowerCase())
                });
            }
        });

        if (this.transactionSources.length > 0) {
            this.transactionSource.setValue(this.transactionSources[0].itemId);
        }
    }

    private populateTransactionTypesEnumDropdowns(): void {
        this.transactionTypes = [];

        const types = Object.keys(TransactionType);
        types.forEach(value => this.transactionTypes.push({
            itemId: value,
            itemVal: this.translateService.instant('transactions.enums.types.' + value.toLowerCase())
        }));

        if (this.transactionTypes.length > 0) {
            this.transactionType.setValue(this.transactionTypes[0].itemId);
        }
    }

    private async populateServicesEnumDropdowns(serviceType = null): Promise<void> {
        let services: any[];

        if (serviceType) {
            services = await firstValueFrom(this.serviceService.getServiceNamesForServiceType(serviceType));
        }
        else {
            services = await firstValueFrom(this.serviceService.getServiceNames());
        }

        this.services = services.sort((a, b) => a['name' + this.crtLang.toUpperCase()].localeCompare(b['name' + this.crtLang.toUpperCase()]));

        if (this.services.length > 0 && this.transactionType.value === TransactionType.ServiceConsumption) {
            this.serviceId.setValue(this.services[0].serviceId);
        }
    }

    private populatePaymentMethodsEnumDropdowns(): void {
        this.paymentMethods = [];

        const paymentMethods = Object.keys(PaymentMethod);
        paymentMethods.forEach(value => this.paymentMethods.push({
            itemId: value,
            itemVal: this.translateService.instant('transactions.enums.payments.' + value.toLowerCase())
        }));
    }

    async open(content): Promise<void> {
        this.modalService.open(content, {centered: true, ariaLabelledBy: 'modal-basic-title'}).result.then(async result => {
            if (result === 'confirm') {
                await this.submit();
            }
        }, () => { /* catch the rejection */ });
    }

    cancel(): void {
        this.location.back();
    }

    get amount(): FormControl<number> {
        return this.cashierTransactionForm.get('amount') as FormControl<number>;
    }

    get comment(): FormControl<string> {
        return this.cashierTransactionForm.get('comment') as FormControl<string>;
    }

    get transactionSource(): FormControl<TransactionSource> {
        return this.cashierTransactionForm.get('transactionSource') as FormControl<TransactionSource>;
    }

    get transactionType(): FormControl<TransactionType> {
        return this.cashierTransactionForm.get('transactionType') as FormControl<TransactionType>;
    }

    get serviceId(): FormControl<string> {
        return this.cashierTransactionForm.get('serviceId') as FormControl<string>;
    }

    get paymentMethod(): FormControl<PaymentMethod | null> {
        return this.cashierTransactionForm.get('paymentMethod') as FormControl<PaymentMethod | null>;
    }
}

interface CashierTransactionForm {
    amount: FormControl<number>;
    comment: FormControl<string>;
    transactionSource: FormControl<TransactionSource>;
    transactionType: FormControl<TransactionType>;
    serviceId: FormControl<string>;
    paymentMethod: FormControl<PaymentMethod | null>;
}

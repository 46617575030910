import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
    AlarmClassification, AlertComparisonSymbol,
    AlertLightDto,
    AlertSearchCriteriaDto,
    AlertService,
    ISortCriteriaDto,
    PageableRequestDtoOfAlertSearchCriteriaDto,
    PagedResultDtoOfAlertLightDto,
    SortCriteriaDto,
    SortDirection
} from '../../_services/configuration-services';
import {
    faCalendar,
    faCheck,
    faExternalLinkAlt,
    faPen,
    faToggleOff,
    faToggleOn,
    faTrash,
    faWrench,
    faBan,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {NotificationsService} from '../../_shared/notifications.service';
import {FormUtils} from '../../_shared/form-utils';
import {AppRoles} from '../../app.constants';
import {DateUtils} from '../../_shared/date-utils';
import {ConfirmModalService} from '../../_shared/_components/confirm-modal/confirm-modal.component';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from '../../_shared/custom-date-formatter';
import {TranslateUtils} from '../../_shared/translate-utils';

@Component({
  selector: 'app-alert-management',
  templateUrl: './alert-management.component.html',
    providers: [
        {provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class AlertManagementComponent implements OnInit, OnDestroy {


    AppRoles = AppRoles;

    // Filters
    filter: {
        formGroup: UntypedFormGroup,
        classificationsDropdownList: string[]
        comparisonSymbolsDropdownList: string[]
    } = {
        formGroup: null,
        classificationsDropdownList: Object.keys(AlarmClassification).filter(c => c !== AlarmClassification.Unknown),
        comparisonSymbolsDropdownList: Object.keys(AlertComparisonSymbol).filter(c => c !== AlertComparisonSymbol.Unknown)
    };

    textInputPattern = FormUtils.textInputPattern;
    dateTimeFormat = DateUtils.dateTimeFormat;

    // Icons
    icons = {
        calendar: faCalendar,
        edit: faPen,
        delete: faTrash,
        disable: faBan,
        inProgress: faWrench,
        open: faExternalLinkAlt,
        release: faCheck,
        times: faTimes,
        toggleOn: faToggleOn,
        toggleOff: faToggleOff
    };

    // Pagination
    readonly pageSize = 10;
    crtPage = 0;

    // Table content
    alerts: PagedResultDtoOfAlertLightDto = new PagedResultDtoOfAlertLightDto();

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Desc,
        property: 'appearedDateTime'
    });

    private pageRequest: PageableRequestDtoOfAlertSearchCriteriaDto;

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(
                private readonly confirmService: ConfirmModalService,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly alertService: AlertService) {

        this.filter.formGroup = new UntypedFormGroup({
                classification: new UntypedFormControl(null),
                period: new UntypedFormGroup({
                    from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                    to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                }, [FormUtils.periodValidator]),
                designation: new UntypedFormControl(null),
                location: new UntypedFormControl(null),
                threshold: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
                comparisonSymbol: new UntypedFormControl(null),
            },
            {updateOn: 'change'});

        this.filter.formGroup.reset();
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        this.initializeBasePageRequest();

        this.fetchAlerts(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    fetchAlerts(pageableRequest: PageableRequestDtoOfAlertSearchCriteriaDto): void {
        this.alertService.searchAlerts(pageableRequest).pipe().subscribe(alerts => {
            this.alerts = alerts;
        });
    }


    releaseAlert(alert: AlertLightDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.release'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.alertService.releaseAlert(alert.id).pipe().subscribe(_ => {
                    this.notificationsService.success({message: 'alerts.management.notification.releasedSuccess'});
                    this.fetchAlerts(this.pageRequest);
                });
            }
        });
    }


    applyFilters(): void {
        if (this.filter.formGroup.invalid) {
            return;
        }

        this.crtPage = 0;
        this.initializeBasePageRequest();

        const period = this.period.value;

        const dateFrom = DateUtils.ngbDateStructToDate(period.from);
        dateFrom?.setHours(0, 0, 0, 0);
        const dateTo = DateUtils.ngbDateStructToDate(period.to);
        dateTo?.setHours(23, 59, 59, 59);

        this.pageRequest.criteria = new AlertSearchCriteriaDto({
            classification: AlarmClassification[this.classification.value],
            from: dateFrom,
            to: dateTo,
            designation: this.designation.value,
            location: this.location.value,
            threshold: this.threshold.value,
            comparisonSymbol: this.comparisonSymbol.value,
            sortCriteriaDto: this.currentSort,
            language: this.crtLang,
        });

        this.fetchAlerts(this.pageRequest);
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;

        this.fetchAlerts(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;

        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = new SortCriteriaDto(sortCriteriaDto);

        this.fetchAlerts(this.pageRequest);
    }

    clearFilter(): void {
        // Clear inputs
        this.filter.formGroup.reset();

        // clear search
        this.initializeBasePageRequest();
        this.fetchAlerts(this.pageRequest);
    }

    get classification(): UntypedFormControl {
        return this.filter.formGroup.get('classification') as UntypedFormControl;
    }

    get period(): UntypedFormGroup {
        return this.filter.formGroup.get('period') as UntypedFormGroup;
    }

    get periodFrom(): UntypedFormControl {
        return this.period.get('from') as UntypedFormControl;
    }

    get periodTo(): UntypedFormControl {
        return this.period.get('to') as UntypedFormControl;
    }

    get designation(): UntypedFormControl {
        return this.filter.formGroup.get('designation') as UntypedFormControl;
    }

    get location(): UntypedFormControl {
        return this.filter.formGroup.get('location') as UntypedFormControl;
    }

    get comparisonSymbol(): UntypedFormControl {
        return this.filter.formGroup.get('comparisonSymbol') as UntypedFormControl;
    }

    get threshold(): UntypedFormControl {
        return this.filter.formGroup.get('threshold') as UntypedFormControl;
    }

    private initializeBasePageRequest(): void {
        this.pageRequest = new PageableRequestDtoOfAlertSearchCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new AlertSearchCriteriaDto({
            classification: null,
            from: null,
            to: null,
            designation: null,
            location: null,
            sortCriteriaDto: this.currentSort,
            comparisonSymbol: null,
            threshold: null
        });
    }
}

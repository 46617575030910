<div class="header">

    <div class="ribbon ribbon-top-left"
         *ngIf="envName !== ''">
        <span [ngClass]="{
                'bg-local': envName === 'local',
                'bg-dev': envName === 'dev',
                'bg-qa': envName === 'QA'}">
            {{envName}}</span>
    </div>

    <!-- Desktop Menu -->
    <div class="container d-flex flex-row justify-content-around" *ngIf="!isMobile">

        <div class="d-flex flex-row justify-content-start w-100">

            <div class="icons-wrapper">
                <a href="#">
                    <img alt="logo Groupe E" src="../assets/icons/logo_ge.jpg" height="40" width="136">
                </a>
                <img class="logo-client" alt="logo client" src="{{env.azureStorageAccountUrl}}/logo/logo_client.jpg" height="50" width="35">
            </div>

            <div class="mt-2">
                <p ngSwitch="{{env.clientCode}}">
                    <span *ngSwitchCase="'cvx'">{{ 'menu.clients.chevroux' | translate }} - </span>
                    <span *ngSwitchCase="'ccc'">{{ 'menu.clients.cheyres' | translate }} - </span>
                    <span *ngSwitchCase="'sftc'">{{ 'menu.clients.softcom' | translate }} - </span>
                    <span *ngSwitchCase="'nrz'">{{ 'menu.clients.neyruz' | translate }} - </span>
                    <span *ngSwitchCase="'clf'">{{ 'menu.clients.leflon' | translate }} - </span>
                    <span *ngSwitchCase="'vdf'">{{ 'menu.clients.fribourg' | translate }} - </span>
                    <span *ngSwitchCase="'sem'">{{ 'menu.clients.semsales' | translate }} - </span>
                    <span *ngSwitchCase="'hrfr'">{{ 'menu.clients.hauterivefr' | translate }} - </span>
                    <span *ngSwitchCase="'por'">{{ 'menu.clients.portalban' | translate }} - </span>
                    <span *ngSwitchCase="'swelm'">{{ 'menu.clients.swisselementic' | translate }} - </span>
                    <span *ngSwitchCase="'demo'">{{ 'menu.clients.demo' | translate }} - </span>
                    <span *ngSwitchCase="'cvf'">{{ 'menu.clients.villazfr' | translate }} - </span>
                    <span *ngSwitchCase="'fty'">{{ 'menu.clients.fetigny' | translate }} - </span>
                    <span *ngSwitchCase="'pdl'">{{ 'menu.clients.lausanne' | translate }} - </span>
                    <span>{{ 'menu.title' | translate }} {{envName}} </span>
                </p>
            </div>

        </div>

        <div class="d-flex flex-row justify-content-end w-100">

            <!-- My Infos - Login/logout -->
            <div class="mt-2 text-end">
                <button *ngIf="loggedIn && userDisplayName"
                        [routerLink]="['users/me']"
                        (click)="toggleMobileMenu()">
                    {{ 'menu.myInfos' | translate }} ({{ userDisplayName }})
                </button>

                <button title="{{'menu.login' | translate}}" *ngIf="!loggedIn"
                        (click)="login()" translate>menu.login
                </button>
                <button title="{{'menu.logout' | translate}}" *ngIf="loggedIn"
                        (click)="logout()">{{ 'menu.logout' | translate }} <fa-icon [icon]="icons.signOut"></fa-icon>
                </button>
            </div>

            <!-- language settings -->
            <div class="mt-2 text-end">
                <button (click)="changeLanguage('fr')" [ngClass]='{"active": currentLanguage === "fr"}'>
                    FR
                </button>
                <button *ngIf="isLanguageAvailable('de')"
                        (click)="changeLanguage('de')" [ngClass]='{"active": currentLanguage === "de"}'>
                    DE
                </button>
                <button *ngIf="isLanguageAvailable('it')"
                        (click)="changeLanguage('it')" [ngClass]='{"active": currentLanguage === "it"}'>
                    IT
                </button>
                <button *ngIf="isLanguageAvailable('en')"
                        (click)="changeLanguage('en')" [ngClass]='{"active": currentLanguage === "en"}'>
                    EN
                </button>
            </div>
        </div>

    </div>

    <!-- Mobile Menu -->
    <div class="container d-flex flex-row justify-content-between" *ngIf="isMobile">

        <div class="icons-wrapper d-flex flex-row justify-content-start">
            <a href="#">
                <img alt="logo Groupe E" src="../assets/icons/logo_ge.jpg">
            </a>

            <p class="ms-4 text-word-overflow-wrap" ngSwitch="{{ env.clientCode }}">
                <span *ngSwitchCase="'cvx'">{{ 'menu.clients.chevroux' | translate }} - </span>
                <span *ngSwitchCase="'ccc'">{{ 'menu.clients.cheyres' | translate }} - </span>
                <span *ngSwitchCase="'sftc'">{{ 'menu.clients.softcom' | translate }} - </span>
                <span *ngSwitchCase="'nrz'">{{ 'menu.clients.neyruz' | translate }} - </span>
                <span *ngSwitchCase="'clf'">{{ 'menu.clients.leflon' | translate }} - </span>
                <span *ngSwitchCase="'vdf'">{{ 'menu.clients.fribourg' | translate }} - </span>
                <span *ngSwitchCase="'sem'">{{ 'menu.clients.semsales' | translate }} - </span>
                <span *ngSwitchCase="'hrfr'">{{ 'menu.clients.hauterivefr' | translate }} - </span>
                <span *ngSwitchCase="'por'">{{ 'menu.clients.portalban' | translate }} - </span>
                <span *ngSwitchCase="'swelm'">{{ 'menu.clients.swisselementic' | translate }} - </span>
                <span *ngSwitchCase="'demo'">{{ 'menu.clients.demo' | translate }} - </span>
                <span *ngSwitchCase="'cvf'">{{ 'menu.clients.villazfr' | translate }} - </span>
                <span *ngSwitchCase="'fty'">{{ 'menu.clients.fetigny' | translate }} - </span>
                <span *ngSwitchCase="'pdl'">{{ 'menu.clients.lausanne' | translate }} - </span>
                <span>{{ 'menu.title' | translate }} {{envName}}</span>
            </p>

        </div>

        <!--Burger menu display mobile-->
        <div class="burger-menu">
            <button class="navbar-toggler toggler-example" type="button"
                    [ngClass]='{ "show": isExpanded }'
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-label="Toggle navigation"
                    (click)="toggleMobileMenu()">
                    <span>
                        {{ (isExpanded ? 'menu.close' : 'menu.open') | translate }}
                        <fa-icon *ngIf="isExpanded" [icon]="icons.cross"></fa-icon>
                        <fa-icon *ngIf="!isExpanded" [icon]="icons.menu"></fa-icon>
                    </span>
            </button>

            <span class="dot red dot-mobile-menu" *ngIf="(isAlarmTriggering || isAlertTriggering || hasUnreadMessage) && !isExpanded"
                  [ngClass]='{"overlaps-over-menu": !isExpanded}'></span>
        </div>
    </div>

    <div class="container main-menu">
        <nav class="navbar navbar-collapse collapse navbar-expand-lg navbar-light"
             [ngClass]='{"show": !isMobile || (isMobile && isExpanded)}'>

            <ng-container id="navbarSupportedContent">

                <!--Main menu-->
                <div class="nav-menu" *ngIf="loggedIn">
                    <ul class="navbar-nav">
                        <li class="nav-item" *ngIf="customerConfigService.isAlarmsEnabled()" [appHasAnyRole]="[AppRoles.admin, AppRoles.alarmManager]">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#" [routerLink]="['alarms']"
                                   routerLinkActive
                                   #alarmsLink="routerLinkActive"
                                   [ngClass]='{"selected": alarmsLink.isActive}'>
                                <span translate="menu.navigation.alarms.main">
                                    <fa-icon [icon]="icons.alertNotification" class="alert-notification-bell" *ngIf="isAlarmTriggering"></fa-icon>
                                </span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item" *ngIf="customerConfigService.isAlarmsEnabled()" [appHasAnyRole]="[AppRoles.admin, AppRoles.alarmManager]">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#" [routerLink]="['alerts']"
                                   routerLinkActive
                                   #alertsLink="routerLinkActive"
                                   [ngClass]='{"selected": alertsLink.isActive}'>
                                <span translate="menu.navigation.alerts.main">
                                    <fa-icon [icon]="icons.alertNotification" class="alert-notification-bell" *ngIf="isAlertTriggering"></fa-icon>
                                </span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item" [appHasAnyRole]="[AppRoles.admin]">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#" [routerLink]="['users']"
                                   routerLinkActive
                                   #userLink="routerLinkActive"
                                   [ngClass]='{"selected": userLink.isActive}'>
                                    <span translate="menu.navigation.users"></span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#" [routerLink]="['services']"
                                   routerLinkActive
                                   #serviceLink="routerLinkActive"
                                   [ngClass]='{"selected": serviceLink.isActive}'>
                                    <span translate="menu.navigation.services"></span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item" *ngIf="customerConfigService.isEwalletEnabled()" [appHasAnyRole]="[AppRoles.user]">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#" [routerLink]="['ewallet']"
                                   routerLinkActive
                                   #walletLink="routerLinkActive"
                                   [ngClass]='{"selected": walletLink.isActive}'>
                                    <span translate="menu.navigation.wallet"></span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item" [appHasAnyRole]="[AppRoles.cashier]">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#" [routerLink]="['ewallets']"
                                   routerLinkActive
                                   #ewalletsLink="routerLinkActive"
                                   [ngClass]='{"selected": ewalletsLink.isActive}'>
                                    <span translate="menu.navigation.wallets"></span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item" *ngIf="customerConfigService.isEwalletEnabled()" [appHasAnyRole]="[AppRoles.cashier, AppRoles.user, AppRoles.configurator]">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#" [routerLink]="getTransactionRootRoute()"
                                   routerLinkActive
                                   #transactionsLink="routerLinkActive"
                                   [ngClass]='{"selected": transactionsLink.isActive}'>
                                    <span translate="menu.navigation.transactions.main"></span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item" [appHasAnyRole]="[AppRoles.admin]">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#" [routerLink]="['rfid-cards']"
                                   routerLinkActive
                                   #rfidCardsLink="routerLinkActive"
                                   [ngClass]='{"selected": rfidCardsLink.isActive}'>
                                    <span translate="menu.navigation.rfidCards"></span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item" *ngIf="customerConfigService.isAlarmsEnabled() || customerConfigService.isInfrastructureEnabled()"
                            [appHasAnyRole]="[AppRoles.admin]">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#" [routerLink]="['infrastructure']"
                                   routerLinkActive
                                   #insfrastructureLink="routerLinkActive"
                                   [ngClass]='{"selected": insfrastructureLink.isActive}'>
                                    <span translate="menu.navigation.infrastructure.main"></span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item" *ngIf="communicationChannelConfigService.isCommunicationChannelEnabled() && loggedIn"
                            [appHasAnyRole]="[AppRoles.admin, AppRoles.user]">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#" [routerLink]="['communication-channel']"
                                   routerLinkActive
                                   #communicationChannel="routerLinkActive"
                                   [ngClass]='{"selected": communicationChannel.isActive}'>
                                <span translate="menu.navigation.communicationChannel.main">
                                    <fa-icon [icon]="icons.alertNotification" class="alert-notification-bell" *ngIf="hasUnreadMessage"></fa-icon>
                                </span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item" [appHasAnyRole]="[AppRoles.admin]">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#" [routerLink]="['statistics']"
                                   routerLinkActive
                                   #statisticsLink="routerLinkActive"
                                   [ngClass]='{"selected": statisticsLink.isActive}'>
                                    <span translate="menu.navigation.statistics"></span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>

                <!-- Visitor Menu -->
                <div class="nav-menu" *ngIf="!loggedIn">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <div class="menu" (click)="toggleMobileMenu()">
                                <a class="nav-link max-width" href="#"
                                   [routerLink]="['services']"
                                   routerLinkActive
                                   #serviceLink="routerLinkActive"
                                   [ngClass]='{"selected": serviceLink.isActive}'>
                                    <span translate="menu.navigation.services"></span>
                                    <fa-icon [icon]="icons.chevronRight" class="chevron-right" *ngIf="isMobile"></fa-icon>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>

            </ng-container>

            <div class="secondary-menu mobile" *ngIf="isMobile"
                 [ngClass]='{ "logged-in-upper-menu": loggedIn }'>

                <!-- My Infos - Login/logout -->
                <div class="login-info">
                    <button class="my-info" *ngIf="loggedIn && userDisplayName"
                            [routerLink]="['users/me']"
                            (click)="toggleMobileMenu()">
                        {{ 'menu.myInfos' | translate }} ({{ userDisplayName }})
                    </button>

                    <button title="{{'menu.login' | translate}}" *ngIf="!loggedIn"
                            (click)="login()" translate>menu.login
                    </button>
                    <button title="{{'menu.logout' | translate}}" *ngIf="loggedIn"
                            (click)="logout()">{{ 'menu.logout' | translate }} <fa-icon [icon]="icons.signOut"></fa-icon>
                    </button>
                </div>

                <!-- language settings -->
                <div class="language-settings">
                    <button (click)="changeLanguage('fr')" [ngClass]='{"active": currentLanguage === "fr"}'>
                        FR
                    </button>
                    <button *ngIf="isLanguageAvailable('de')"
                            (click)="changeLanguage('de')" [ngClass]='{"active": currentLanguage === "de"}'>
                        DE
                    </button>
                    <button *ngIf="isLanguageAvailable('it')"
                            (click)="changeLanguage('it')" [ngClass]='{"active": currentLanguage === "it"}'>
                        IT
                    </button>
                    <button *ngIf="isLanguageAvailable('en')"
                            (click)="changeLanguage('en')" [ngClass]='{"active": currentLanguage === "en"}'>
                        EN
                    </button>
                </div>
            </div>

        </nav>
    </div>

</div>

<div class="main-container container">
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
    <app-application-loader *ngIf="!isIframe">
    </app-application-loader>
</div>

<div class="footer container">
    <a href="#"
       [routerLink]="['cgv']" translate>{{ 'common.cgv' }}</a>

    <a href="#"
       [routerLink]="['cgu']" translate>{{ 'common.cgu' }}</a>

    <a href="#" *ngIf="loggedIn"
       [routerLink]="['forgetme']" translate>{{ 'common.erasureRight' }}</a>

    <a href="javascript:void(0);" translate
       (click)="downloadInstructionsManual()">{{ 'common.instructionsManual' }}</a>
</div>

<ng-template appConfirmModal>
    <app-confirm-modal></app-confirm-modal>
</ng-template>

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../_shared/notifications.service';
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    ChargingProcessInputDto,
    ChargingProcessService,
    ChargingStationDto,
    ChargingStationEvseStatus,
    ChargingStationService,
    ConsumeServicePayloadDtoOfChargingProcessInputDto,
    PaymentTypes,
    TariffDto,
} from '../../../_services/configuration-services';
import {InternalDatatransService} from '../../../_services/internal-datatrans.service';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {
    PaymentTypeChooserComponent
} from '../../../_shared/_components/payment-type-chooser/payment-type-chooser.component';
import {FormUtils} from '../../../_shared/form-utils';
import {firstValueFrom} from 'rxjs';
import {ContactForm, UserDataFormComponent} from '../../../_shared/_components/user-data-form/user-data-form.component';
import {TariffUtils} from '../../../_shared/tariff-utils';
import {RolesService} from '../../../_shared/roles-service';

@Component({
    selector: 'app-activate-charging-process-modal',
    templateUrl: './activate-charging-process-modal.component.html'
})
export class ActivateChargingProcessModalComponent implements OnInit {

    @Input() serviceId!: string;
    @Input() externalChargingStationId!: string;
    @Input() evseIndex: number = 0;
    @Input() defaultTariff!: TariffDto;
    @Input() userId!: string;

    @ViewChild('userDataFormComponent') userDataFormComponent: UserDataFormComponent | null;
    @ViewChild('paymentTypeChooserComponent') paymentTypeChooserComponent: PaymentTypeChooserComponent | null;

    FormUtils = FormUtils;

    activationForm: FormGroup<ActivationForm>;

    chargingStation: ChargingStationDto;
    availablePaymentTypes: PaymentTypes[] = [];
    evseState: ChargingStationEvseStatus = ChargingStationEvseStatus.Unknown;

    crtLang = TranslateUtils.defaultLanguage;
    ChargingStationEvseStatus = ChargingStationEvseStatus;


    constructor(public activeModal: NgbActiveModal,
                private readonly notificationsService: NotificationsService,
                private readonly internalDatatransService: InternalDatatransService,
                private readonly chargingStationService: ChargingStationService,
                private readonly chargingProcessService: ChargingProcessService,
                private readonly rolesService: RolesService) {

        this.activationForm = new FormGroup<ActivationForm>({
            visitorForm: new UntypedFormGroup({}),
            paymentType: new UntypedFormControl('', Validators.required)
        }, {updateOn: 'change'});
    }

    async ngOnInit(): Promise<void> {
        await this.fetchChargingStation();

        this.availablePaymentTypes = this.defaultTariff.paymentTypes;

        TariffUtils.removeUnavailablePaymentTypes(this.availablePaymentTypes,
            this.rolesService.hasRoleAdmin(),
            !this.userId);

        if (this.availablePaymentTypes.length === 0) {
            this.notificationsService.warning({
                title: 'epayment.notifications.warningActivateTitle',
                message: 'epayment.notifications.noPaymentType'
            });

        } else if (this.availablePaymentTypes.length === 1) {
            this.paymentType.setValue(this.availablePaymentTypes[0]);
        }
    }

    async activateService(): Promise<void> {
        if (this.activationForm.invalid) {
            return;
        }

        const consumeService = new ConsumeServicePayloadDtoOfChargingProcessInputDto({
            serviceId: this.serviceId,
            userId: this.userId,
            visitorContact: this.userDataFormComponent?.visitorContact,
            paymentType: this.paymentType.value,
            serviceInputPayload: new ChargingProcessInputDto({
                chargingStationId: this.chargingStation.id,
                evseIndexNumber: this.evseIndex
            })
        });

        if (this.paymentType.value === PaymentTypes.Ebanking) {
            const amount = this.defaultTariff.tariffPrices[0].priceByUnit;
            this.internalDatatransService.payChargingProcess(consumeService, (amount * 100));

        } else {
            if (this.userId) {
                await firstValueFrom(this.chargingProcessService.activateChargingProcess(consumeService));
            } else {
                await firstValueFrom(this.chargingProcessService.activateChargingProcessForVisitor(consumeService));
            }

            this.notificationsService.success({
                title: 'services.activation.notification.success',
            });

            this.activeModal.close('success');
        }
    }

    selectPaymentType(paymentType: PaymentTypes): void {
        this.paymentType.setValue(paymentType);
    }

    private async fetchChargingStation(): Promise<void> {
        this.chargingStation = await firstValueFrom(this.chargingStationService.getChargingStationByExternalId(this.serviceId, this.externalChargingStationId));

        if (!this.chargingStation) {
            this.activeModal.close('ChargingStationNotFound');
        }

        this.evseState = await firstValueFrom(this.chargingStationService.getChargingStationEvseState(this.chargingStation.id, this.evseIndex));
    }

    get visitorForm(): FormGroup<ContactForm> | null {
        return this.activationForm.get('visitorForm') as FormGroup<ContactForm>;
    }

    get paymentType(): FormControl<PaymentTypes> {
        return this.activationForm.get('paymentType') as FormControl<PaymentTypes>;
    }
}

interface ActivationForm {
    visitorForm: FormGroup<ContactForm | null>
    paymentType: FormControl<PaymentTypes>
}

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {faSort} from '@fortawesome/free-solid-svg-icons';
import {TranslateUtils} from '../../translate-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-sortable-list-modal',
    templateUrl: './sortable-list-modal.component.html',
    styleUrls: ['./sortable-list-modal.component.scss']
})
export class SortableListModalComponent implements OnInit {

    @Input() sortableData: SortableData[];

    sortableListStyle = {
        width: 'auto',
    }

    edited = false;

    icons = {
        sort: faSort
    };

    crtLang = TranslateUtils.defaultLanguage;

    constructor(public activeModal: NgbActiveModal,
                private readonly translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
    }
    saveSort(): void {
        this.activeModal.close(this.sortableData);
    }

    listOrderChanged(sortedList: any): void {
        this.edited = true;
        this.sortableData = sortedList;
    }
}

export interface SortableData {
    id?: any;
    displayTextFR?: string;
    displayTextDE?: string;
    displayTextIT?: string;
    displayTextEN?: string;
}

<h3 translate="publicLighting.lightZone.table.title"></h3>

<div class="table-responsive mt-4">
    <table class="table table-striped"
           [attr.aria-label]="'services.publicLighting.lightZone.table.title' | translate">
        <thead>
        <tr>
            <th scope="col" style="min-width: 150px"
                translate="publicLighting.lightZone.table.code">
            </th>
            <th scope="col" style="min-width: 300px"
                translate="publicLighting.lightZone.table.description">
            </th>
            <th scope="col" style="min-width: 150px"
                translate="publicLighting.lightZone.table.lightCount">
            </th>
            <th scope="col" style="min-width: 150px"
                translate="publicLighting.lightZone.table.mode">
            </th>
            <th scope="col" style="min-width:45px"></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="lightZones.length === 0">
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td></td>
        </tr>

        <tr *ngFor="let lightZone of lightZones">
            <td class="align-middle">{{ lightZone.code }}</td>

            <td class="align-middle">{{ lightZone['description' + currentLang.toUpperCase()] }}</td>

            <td class="align-middle">{{ lightZone.lightCount }}</td>

            <td class="align-middle">{{ ('publicLighting.lightZone.enums.PublicLightingControlMode.' + lightZone.controlMode) | translate }}</td>

            <td class="align-middle text-end">
                <button class="btn btn-link btn-sm"
                        (click)="openManageModeModal(lightZone)"
                        title="{{'publicLighting.lightZone.manageMode.modal.title' | translate}}">
                    <fa-icon [icon]="icons.manageMode"></fa-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="content-container bg-white" [appHasAnyRole]="[AppRoles.admin, AppRoles.alarmManager]">

    <h1 translate="alarms.history.title"></h1>

    <form [formGroup]="filter.formGroup"
          (keyup.enter)="applyFilters()">

        <div class="row">

            <!-- IotRegister -->
            <div class="default-filter-form-field">
                <label class="form-label" for="iotRegister" translate="alarms.history.filter.label.iotRegister"></label>
                <app-search-iot-register id="iotRegister" #iotRegisterSearch
                                         (selectedRegisterEvent)="iotRegister.setValue($event)"
                                         [registerTypes]="[RegisterType.Alarm]"
                                         [measuringPointType]="MeasuringPointType.Admin"
                                         [disabled]="false"></app-search-iot-register>
            </div>

            <!-- Measuring Point -->
            <div class="default-filter-form-field">
                <label class="form-label" for="measuringPoint" translate="alarms.history.filter.label.measuringPoint"></label>
                <app-search-measuring-point id="measuringPoint" #measuringPointSearch
                                            (selectedMeasuringPointEvent)="measuringPoint.setValue($event)"
                                            [measuringPointTypes]="[MeasuringPointType.Admin]"></app-search-measuring-point>
            </div>

            <!-- Released User -->
            <div class="default-filter-form-field">
                <label class="form-label" for="user" translate="alarms.history.filter.label.releasedUser"></label>
                <app-search-user id="user" #userSearch
                                 (selectedUserEvent)="user.setValue($event)"></app-search-user>
            </div>

            <!-- Appeared Date -->
            <div class="default-filter-form-field">
                <app-date-period-picker inputLabel="alarms.history.filter.label.appearedDate"
                                        [periodControl]="appearedPeriod"></app-date-period-picker>
            </div>

            <!-- In Progress Date -->
            <div class="default-filter-form-field">
                <app-date-period-picker inputLabel="alarms.history.filter.label.inProgressDate"
                                        [periodControl]="inProgressPeriod"></app-date-period-picker>
            </div>

            <!-- Released Date -->
            <div class="default-filter-form-field">
                <app-date-period-picker inputLabel="alarms.history.filter.label.releasedDate"
                                        [periodControl]="releasedPeriod"></app-date-period-picker>
            </div>

        </div>

        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   (click)="clearFilter()">

            <input class="btn btn-primary" type="button"
                   [value]="'common.filter' | translate"
                   (click)="applyFilters()"
                   [disabled]="filter.formGroup.invalid">
        </div>

    </form>

    <!-- Table -->
    <ngx-datatable #devicesTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="alarms.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   rowHeight="50"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="alarms.rowCount"
                   [sorts]="[{prop: 'releasedDateTime', dir: 'desc'}]"
                   [messages]="{
                       totalMessage: 'common.datatable.total' | translate
                   }"
                   [offset]="crtPage"
                   [limit]="alarms.pageSize"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [scrollbarH]="true">

        <ngx-datatable-column [width]="150" prop="IotRegister.Label{{crtLang.toUpperCase()}}" sortable="true"
                              name="{{'alarms.history.table.designation' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row['designation' + crtLang.toUpperCase()] }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="MeasuringPoint.Name{{crtLang.toUpperCase()}}" sortable="true"
                              name="{{'alarms.history.table.equipment' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row['equipment' + crtLang.toUpperCase()] }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="100" prop="appearedDateTime" sortable="true"
                              name="{{'alarms.history.table.appearedDate' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.appearedDateTime | date:alarmDateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="100" prop="inProgressDateTime" sortable="true"
                              name="{{'alarms.history.table.inProgressDate' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.inProgressDateTime | date:alarmDateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="100" prop="releasedDateTime" sortable="true"
                              name="{{'alarms.history.table.releasedDate' | translate}}">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ row.releasedDateTime | date:alarmDateTimeFormat}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="150" prop="releasedUser" sortable="true"
                              name="{{'alarms.history.table.user' | translate}}">
        </ngx-datatable-column>

        <ngx-datatable-column [width]="35" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <button class="btn btn-link btn-sm"
                        (click)="openAlarmDetail(row)"
                        title="{{'common.tooltip.open' | translate}}">
                    <fa-icon [icon]="icons.open"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div class="page-count">
                    {{ rowCount }} {{ 'common.datatable.total' | translate }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="devicesTable.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>

    </ngx-datatable>

</div>


<div class="content-container bg-white">

    <h1 translate="menu.navigation.transactions.transactionBilling"></h1>

    <!-- Filters -->
    <div [formGroup]="filterForm">

        <div class="row">
            <!-- Date -->
            <div class="default-filter-form-field">
                <app-date-period-picker [periodControl]="validityPeriod"
                                        inputLabel="transactions.filter.date"></app-date-period-picker>
            </div>

            <!-- Amount Filter -->
            <div class="default-filter-form-field">

                <div [formGroup]="amountRange">
                    <label class="form-label" translate="transactions.filter.amount"></label>
                    <div class="input-group mb-2">
                        <span class="input-group-text" translate="transactions.filter.amountFrom"></span>
                        <input class="form-control" id="amountFrom" name="amountFrom" type="number"
                               [formControl]="amountFrom"
                               (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                               placeholder="{{ 'transactions.filter.amountFrom' | translate }}">
                        <span class="input-group-text">CHF</span>
                    </div>
                    <app-form-error [control]="amountFrom"></app-form-error>

                    <div class="input-group mb-2">
                        <span class="input-group-text" translate="transactions.filter.amountTo"></span>
                        <input class="form-control" id="amountTo" name="amountTo" type="number"
                               [formControl]="amountTo"
                               (keydown)="FormUtils.validateInputNumberLength($event, 8)"
                               placeholder="{{ 'transactions.filter.amountTo' | translate }}">
                        <span class="input-group-text">CHF</span>
                    </div>
                    <app-form-error [control]="amountTo"></app-form-error>
                    <app-form-error [control]="amountRange"></app-form-error>
                </div>
            </div>

            <!-- State Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="transactions.filter.state"></label>
                <ng-multiselect-dropdown
                    placeholder="{{'transactions.filter.state' | translate}}"
                    [settings]="dropdownSettings"
                    [data]="stateData"
                    [formControl]="state">
                </ng-multiselect-dropdown>
            </div>

            <!-- BillNumber Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="transactions.filter.billingNumber"></label>
                <input class="form-control" type="text"
                       maxlength="50"
                       placeholder="{{ 'transactions.filter.billingNumber' | translate }}"
                       [formControl]="codeBillingNumber">
            </div>

            <!-- User Filter -->
            <div class="default-filter-form-field">
                <label class="form-label" translate="transactions.filter.user"></label>
                <app-search-user #searchUserComponent
                                 (selectedUserEvent)="user.setValue($event)"></app-search-user>
            </div>
        </div>

        <div class="btn-bar">
            <input class="btn btn-outline-secondary" type="button"
                   [value]="'common.clearFilter' | translate"
                   (click)="clearFilter()">

            <input class="btn btn-primary" type="button"
                   [value]="'transactions.filter.billings' | translate"
                   (click)="filterBillings()"
                   [disabled]="filterForm.invalid">
        </div>

    </div>


    <!-- Datatable -->
    <ngx-datatable #myTable
                   class="material"
                   [columnMode]="'force'"
                   [rows]="billings.results"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [reorderable]="false"
                   [rowHeight]="'100%'"
                   [externalPaging]="true"
                   [externalSorting]="true"
                   [count]="billings.rowCount"
                   [sorts]="[{prop: 'ToBillDate', dir: 'desc'}]"
                   [messages]="{
                                    totalMessage: 'common.datatable.total' | translate
                            }"
                   [offset]="crtPage"
                   [limit]="billings.pageSize"
                   (page)="setPage($event)"
                   (sort)="onSort($event)"
                   [scrollbarH]="true">

        <!-- Date -->
        <ngx-datatable-column [width]="150" prop="ToBillDate" name="{{'transactions.table.date' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{ getDateByState(row) | date:dateTimeFormat }}
            </ng-template>
        </ngx-datatable-column>

        <!-- Billing Number -->
        <ngx-datatable-column [width]="160" prop="codeBillingNumber"
                              name="{{'transactions.table.billingNumber' | translate}}"
                              sortable="true">
        </ngx-datatable-column>

        <!-- Billing State-->
        <ngx-datatable-column [width]="120" prop="state"
                              name="{{'transactions.table.state' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{((row.state) ? ('transactions.enums.state.' + row.state.toLowerCase()) : '') | translate}}
            </ng-template>
        </ngx-datatable-column>

        <!-- User -->
        <ngx-datatable-column [width]="160" prop="userId" name="{{'transactions.table.user' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{row.user}}
            </ng-template>
        </ngx-datatable-column>

        <!-- Amount -->
        <ngx-datatable-column [width]="130" prop="amount" name="{{'transactions.table.amount' | translate}}"
                              sortable="true">
            <ng-template ngx-datatable-cell-template let-row="row">
                <fa-icon [icon]="icons.arrowCircleUp" *ngIf="row.amount > 0"
                         class="arrow-circle text-primary"></fa-icon>
                <fa-icon [icon]="icons.arrowCircleDown" *ngIf="row.amount < 0"
                         class="arrow-circle text-danger"></fa-icon>
                {{ row.amount > 0 ? '+' : '' }}{{ row.amount | currencyFormat }}
            </ng-template>
        </ngx-datatable-column>

        <!-- Bill Download -->
        <ngx-datatable-column [width]="110" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <button class="btn btn-link btn-sm"
                        (click)="downloadReceipt(row.transactionId)"
                        title="{{ 'common.tooltip.receipt' | translate }}">
                    <fa-icon [icon]="icons.receipt"></fa-icon>
                </button>

                <button class="btn btn-link btn-sm"
                        (click)="changeState(row,false)"
                        title="{{ 'transactions.billings.tooltip.'+ row.state.toLowerCase() | translate }}"
                        [disabled]="!allowStateChange(row)">
                    <fa-icon [icon]="icons.coins"></fa-icon>
                </button>
                <button class="btn btn-link btn-sm"
                        (click)="changeState(row,true)"
                        title="{{ 'transactions.billings.tooltip.cancelled' | translate }}"
                        [disabled]="!allowStateChange(row)">
                    <fa-icon [icon]="icons.trash"></fa-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div class="page-count">
                    {{ rowCount }} {{ 'common.datatable.total' | translate }}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="table.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

    <div class="btn-bar">
        <button class="btn btn-outline-secondary" type="button"
                (click)="back()"
                translate="common.back">
        </button>
    </div>
</div>

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    BillingCriteriaDto,
    BillingDto,
    BillingSearchCriteriaDto,
    BillingService,
    BillingState,
    BillingStateDto,
    ISortCriteriaDto,
    PageableRequestDtoOfBillingCriteriaDto,
    PagedResultDtoOfBillingDto,
    SearchUserDto,
    SortCriteriaDto,
    SortDirection,
    TransactionService,
} from '../../_services/configuration-services';
import {Location} from '@angular/common';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as fileSaver from 'file-saver';
import {faArrowCircleDown, faArrowCircleUp, faCoins, faReceipt, faTrash} from '@fortawesome/free-solid-svg-icons';
import {DateUtils} from '../../_shared/date-utils';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormUtils} from '../../_shared/form-utils';
import {SearchUserComponent} from '../../_shared/_components/search-user/search-user.component';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {TranslateUtils} from '../../_shared/translate-utils';
import {Subscription} from 'rxjs';
import {CustomDateFormatter} from '../../_shared/custom-date-formatter';
import {BillingStateModalComponent} from './billing-state-modal/billing-state-modal.component';
import {NotificationsService} from '../../_shared/notifications.service';

@Component({
    selector: 'app-transaction-billing',
    templateUrl: './transaction-billing.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class TransactionBillingComponent implements OnInit, OnDestroy {

    @ViewChild('searchUserComponent') searchUser: SearchUserComponent;
    @ViewChild('myTable') table;

    FormUtils = FormUtils;

    dateTimeFormat = DateUtils.dateTimeFormat;

    filterForm: UntypedFormGroup;

    billings: PagedResultDtoOfBillingDto = new PagedResultDtoOfBillingDto();
    selectedUser: SearchUserDto;

    // Icons
    icons = {
        arrowCircleUp: faArrowCircleUp,
        arrowCircleDown: faArrowCircleDown,
        receipt: faReceipt,
        coins: faCoins,
        trash: faTrash
    }

    dropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'itemId',
        textField: 'itemVal',
    };

    stateData = [];

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    readonly pageSize = 10;
    crtPage = 0;

    private currentSort = new SortCriteriaDto({
        direction: SortDirection.Desc,
        property: 'toBillDate'
    });

    private pageRequest: PageableRequestDtoOfBillingCriteriaDto;

    constructor(private readonly billingService: BillingService,
                private readonly transactionService: TransactionService,
                private readonly location: Location,
                private readonly translateService: TranslateService,
                private readonly modalService: NgbModal,
                private readonly notificationService: NotificationsService,
                public readonly formatter: NgbDateParserFormatter) {

        this.pageRequest = new PageableRequestDtoOfBillingCriteriaDto({
            page: this.crtPage + 1,
            pageSize: this.pageSize
        });
        this.pageRequest.criteria = new BillingCriteriaDto({
            searchCriteriaDto: new BillingSearchCriteriaDto({
                userId: null,
                from: null,
                to: null,
                amountFrom: null,
                amountTo: null,
                codeBillingNumber: null,
                state: null
            }),
            sortCriteriaDto: this.currentSort
        });

        this.filterForm = new UntypedFormGroup({
            validityPeriod: new UntypedFormGroup({
                from: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]),
            state: new UntypedFormControl([]),
            codeBillingNumber: new UntypedFormControl(null),
            user: new UntypedFormControl(),
            amountRange: new UntypedFormGroup({
                min: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
                max: new UntypedFormControl(null, FormUtils.numberDecimalAllowingNegativePatternValidator),
            }, [FormUtils.rangeValidator])
        });
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
            this.clearFilter();
            this.populateStateForm();
        });

        this.populateStateForm();

        this.fetchBillings(this.pageRequest);
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    fetchBillings(pageableRequest: PageableRequestDtoOfBillingCriteriaDto): void {
        this.billingService.searchBillings(pageableRequest).pipe().subscribe(value => {
            this.billings = value;
        });
    }

    populateStateForm(): void {
        this.stateData = [];
        const states = Object.keys(BillingState);
        states.forEach(value => {
            this.stateData.push({
                itemId: value,
                itemVal: this.translateService.instant('transactions.enums.state.' + value.toLowerCase())
            });
        });
    }

    public downloadReceipt(id: string): void {
        this.transactionService.generateTransactionReceipt(id).pipe().subscribe(response =>
            fileSaver.saveAs(response.data, response.fileName));
    }

    public changeState(billingDto: BillingDto, cancel: boolean): void {

        const billingStateDto = new BillingStateDto({
            id: billingDto.id,
            dateTime: null
        });

        if (cancel) {
            billingStateDto.state = BillingState.Cancelled;

        } else {
            switch (billingDto.state) {
                case BillingState.ToBill:
                    billingStateDto.state = BillingState.Billed;
                    break;
                case BillingState.Billed:
                    billingStateDto.state = BillingState.Paid;
                    break;
                case BillingState.Cancelled:
                case BillingState.Paid:
                    return;
            }
        }

        const modal = this.modalService.open(BillingStateModalComponent, {centered: true});
        modal.componentInstance.billingStateDto = billingStateDto;

        modal.result.then(async value => {
            if (!!value && value.action === 'ok') {
                this.notificationService.success({
                    title: 'transactions.notifications.stateChangeSuccess',
                    interpolateParams: {state: this.translateService.instant('transactions.enums.state.' + value.state)}
                });
            }
            this.fetchBillings(this.pageRequest);
        }, () => { /* catch the rejection */ });
    }

    filterBillings(): void {
        this.crtPage = 0;

        const dateFrom = DateUtils.ngbDateStructToDate(this.dateFrom.value);
        dateFrom?.setHours(0, 0, 0, 0);

        const dateTo = DateUtils.ngbDateStructToDate(this.dateTo.value);
        dateTo?.setHours(23, 59, 59, 59);

        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new BillingCriteriaDto({
            searchCriteriaDto: new BillingSearchCriteriaDto({
                from: dateFrom,
                to: dateTo,
                codeBillingNumber: this.codeBillingNumber.value,
                state: this.state.value[0]?.itemId,
                amountFrom: !!this.amountFrom.value || this.amountFrom.value === 0 ? Number(this.amountFrom.value) : null,
                amountTo: !!this.amountTo.value || this.amountTo.value === 0 ? Number(this.amountTo.value) : null,
                userId: this.user?.value?.id
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            })
        });

        this.fetchBillings(this.pageRequest);
    }

    setPage(page: any): void {
        this.crtPage = page.offset;
        this.pageRequest.page = this.crtPage + 1;
        this.fetchBillings(this.pageRequest);
    }

    onSort(sortOrder: any): void {
        this.goToFirstPage();
        const sortCriteriaDto: ISortCriteriaDto =
            {
                direction: sortOrder.sorts[0].dir,
                property: sortOrder.sorts[0].prop
            };
        this.pageRequest.criteria.sortCriteriaDto = new SortCriteriaDto(sortCriteriaDto);
        this.currentSort = sortCriteriaDto as SortCriteriaDto;
        this.fetchBillings(this.pageRequest);
    }

    private goToFirstPage(): void {
        this.crtPage = 0;
        this.pageRequest.page = this.crtPage + 1;
    }

    back(): void {
        this.location.back();
    }

    clearFilter(): void {
        // Clear inputs
        this.dateFrom.setValue(null);
        this.dateTo.setValue(null);
        this.state.setValue([]);
        this.codeBillingNumber.setValue(null);
        this.amountFrom.setValue(null);
        this.amountTo.setValue(null);

        this.crtPage = 0;
        this.selectedUser = null;
        this.searchUser.clearInput();

        // clear search
        this.pageRequest.page = this.crtPage + 1;
        this.pageRequest.pageSize = this.pageSize;
        this.pageRequest.criteria = new BillingCriteriaDto({
            searchCriteriaDto: new BillingSearchCriteriaDto({
                from: null,
                to: null,
                amountFrom: null,
                amountTo: null,
                userId: null,
                codeBillingNumber: null,
                state: null
            }),
            sortCriteriaDto: new SortCriteriaDto({
                direction: this.currentSort.direction,
                property: this.currentSort.property
            })
        });

        this.fetchBillings(this.pageRequest);
    }

    public allowStateChange(billingDto: BillingDto): boolean {
        return billingDto.state !== BillingState.Cancelled && billingDto.state !== BillingState.Paid;
    }

    public getDateByState(billingDto: BillingDto): Date | null {
        switch (billingDto.state) {
            case BillingState.Cancelled:
                return billingDto.cancelledDate;
            case BillingState.Paid:
                return billingDto.paidDate;
            case BillingState.Billed:
                return billingDto.billedDate;
            case BillingState.ToBill:
                return billingDto.toBillDate;
            default:
                return null;
        }
    }

    get validityPeriod(): UntypedFormGroup {
        return this.filterForm.get('validityPeriod') as UntypedFormGroup;
    }

    get dateFrom(): UntypedFormControl {
        return this.validityPeriod.get('from') as UntypedFormControl;
    }

    get dateTo(): UntypedFormControl {
        return this.validityPeriod.get('to') as UntypedFormControl;
    }

    get amountRange(): UntypedFormGroup {
        return this.filterForm.get('amountRange') as UntypedFormGroup;
    }

    get amountFrom(): UntypedFormControl {
        return this.amountRange.get('min') as UntypedFormControl;
    }

    get amountTo(): UntypedFormControl {
        return this.amountRange.get('max') as UntypedFormControl;
    }

    get state(): UntypedFormControl {
        return this.filterForm.get('state') as UntypedFormControl;
    }

    get codeBillingNumber(): UntypedFormControl {
        return this.filterForm.get('codeBillingNumber') as UntypedFormControl;
    }

    get user(): UntypedFormControl {
        return this.filterForm.get('user') as UntypedFormControl;
    }

}

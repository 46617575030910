import { Component, Input } from '@angular/core';
import {firstValueFrom} from 'rxjs';
import { DateUtils } from '../../../../../_shared/date-utils';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BookingSlotService } from 'src/app/_services/configuration-services';
import { NotificationsService } from 'src/app/_shared/notifications.service';
import { FormUtils } from 'src/app/_shared/form-utils';

@Component({
  selector: 'app-booking-slots-deletion-modal-component',
  templateUrl: './booking-slots-deletion-modal.component.html',
})
export class BookingSlotsDeletionModalComponent {

    @Input() serviceId: string;

    bookingSlotsDeletionForm: FormGroup;

    minDate: NgbDateStruct;

    DateUtils = DateUtils;

    constructor(public activeModal: NgbActiveModal,
                private readonly bookingService: BookingSlotService,
                private readonly notificationsService: NotificationsService) {
    }

    ngOnInit(): void {

        this.bookingSlotsDeletionForm = new FormGroup({
            datePeriod: new FormGroup({
                from: new FormControl(null, [Validators.required, FormUtils.datePatternValidator]),
                to: new FormControl(null, [Validators.required, FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator])
        }, {updateOn: 'change'});

        this.minDate = DateUtils.dateToNgbDateStruct(new Date());
    }

    get datePeriod(): FormGroup {
        return this.bookingSlotsDeletionForm.get('datePeriod') as FormGroup;
    }

    get startDate(): FormControl {
        return this.datePeriod.get('from') as FormControl;
    }

    get endDate(): FormControl {
        return this.datePeriod.get('to') as FormControl;
    }

    async deleteBookingSlots(): Promise<void> {
        if (!this.bookingSlotsDeletionForm.valid) {
            return;
        }

        const result = await firstValueFrom(this.bookingService.deleteBookingSlotGroup(
            this.serviceId,
            DateUtils.ngbDateStructToDate(this.startDate.value),
            DateUtils.ngbDateStructToDate(this.endDate.value)
        ));

        this.notificationsService.success({
            title: 'bookings.notifications.deleteBookingSlotsSuccess',
            interpolateParams: {numberOfDeletedSlots: result}
        });
        this.activeModal.close('success');
    }
}

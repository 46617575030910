import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {NotificationsService} from './notifications.service';
import {NGXLogger} from 'ngx-logger';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private notificationsService: NotificationsService = null;
    private router: Router;
    private log: NGXLogger = null;
    private translate: TranslateService = null;
    private zone: NgZone = null;

    private ClosingModalError = 'Cross click';

    constructor(private readonly injector: Injector) {
    }

    handleError(error: any): void {
        this.inject();

        const rejection = (error as any).rejection;
        if (rejection != null) {
            error = rejection;
        }

        // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
        if (error.errorMessage?.includes('AADB2C90118')) {
            // Need to login again => No error to show
            return;

        } else if (error.errorMessage?.includes('AADB2C90091')) {
            // User cancelled B2C action => No error to show
            return;
        }

        if (error === this.ClosingModalError || error === 0) {
            // Occurs when closing a modal
            console.log('Caught: Closing a modal');
            return;
        }

        // AD B2C error
        if (error.errorCode === 'interaction_in_progress') {
            // user will be redirected to AAD B2C => No error to show
            return;
        }

        // Zxing ngx-scanner issues
        if (error.message === 'setOptions failed' ||
            error.message === 'The associated Track is in an invalid state') {
            // Error: Uncaught (in promise): UnknownError: setOptions failed - https://github.com/zxing-js/ngx-scanner/issues/297#issuecomment-621782384
            console.log('Caught (ngx-scanner): ' + error.message);
            return;
        }

        console.log(error.message);

        this.zone.run(() => {

            if (error === 1) {
                // Used to catch the escape button when a modal is displayed
                // TODO Find a better way to catch this action
                return;
            }

            const status = (error as any).status;
            console.error(error);

            switch (status) {
                case 401:
                case 403:
                    this.notificationsService.error({title: 'error.network.status.' + status});

                    this.router.navigateByUrl('/', {skipLocationChange: false})
                        .then(() => {
                            window.location.reload();
                        });

                    break;
                case 404:
                    this.notificationsService.error({title: 'error.network.status.' + status});
                    break;
                case 422: // Business Exception
                    const response = JSON.parse(error.response);
                    this.log.error('An error occurred', response);
                    if (response.businessCode) {
                        this.notificationsService.error({
                            title: 'error.network.businessException.' + response.businessCode,
                            interpolateParams: response.extensions
                        });
                    }
                    break;
                case 500:
                    if (error.response) {
                        this.log.error('An error occurred', error);
                    }
                    this.notificationsService.error({title: 'error.unhandled'});
                    break;

                default:
                    this.notificationsService.error({title: 'error.unhandled'});
            }
        });
    }

    private inject(): void {
        if (!this.log) {
            this.log = this.injector.get(NGXLogger);
            this.notificationsService = this.injector.get(NotificationsService);
            this.translate = this.injector.get(TranslateService);
            this.zone = this.injector.get(NgZone);
            this.router = this.injector.get(Router);
        }
    }
}

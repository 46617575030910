import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {UntypedFormControl, Validators} from "@angular/forms";
import {MsalService} from "@azure/msal-angular";
import {BaseAuthRequest} from "@azure/msal-common";
import {FormUtils} from '../../form-utils';
import {RolesService} from '../../roles-service';
import {AppConstants} from '../../../app.constants';
import {ConfirmModalService} from '../confirm-modal/confirm-modal.component';
import {TranslateUtils} from '../../translate-utils';

@Component({
    selector: 'app-push-notification-modal',
    templateUrl: './push-notification-modal.component.html'
})
export class PushNotificationModalComponent implements OnInit {

    crtLang = TranslateUtils.defaultLanguage;

    email = new UntypedFormControl(null, [Validators.required, FormUtils.emailValidator]);

    constructor(public activeModal: NgbActiveModal,
                private readonly msalService: MsalService,
                public readonly rolesService: RolesService,
                private readonly translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
    }

    ok(): void {
        if (this.email.value) {
            localStorage.setItem(AppConstants.pushRegistrationEmailKey, this.email.value);
        }
        this.activeModal.close(ConfirmModalService.yes);
    }

    nok(): void {
        this.activeModal.close(ConfirmModalService.no);
    }

    close(): void {
        this.activeModal.close('cancel');
    }

    login(userFlowRequest?: BaseAuthRequest): void {
        this.msalService.loginRedirect(userFlowRequest);
    }
}

<div class="modal-header">
    <h4 class="modal-title" translate="officeHours.opening.modal.title"></h4>

    <button type="button" class="btn-close" aria-label="Close"
            (click)="officeHourModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="officeHourForm">
        <div class="row">

            <div class="col-6">
                <label class="form-label"
                       for="fromHour">{{ 'officeHours.opening.modal.form.startHour' | translate }}*</label>
                <ngb-timepicker id="fromHour" [formControl]="startHour"></ngb-timepicker>
                <app-form-error [control]="startHour"></app-form-error>
            </div>

            <div class="col-6">
                <label class="form-label"
                       for="toHour">{{ 'officeHours.opening.modal.form.endHour' | translate }}*</label>
                <ngb-timepicker id="toHour" [formControl]="endHour"></ngb-timepicker>
                <app-form-error [control]="endHour"></app-form-error>
            </div>

            <div *ngIf="!isOpeningHourValid()">
                <small class="text-danger" translate="officeHours.opening.modal.form.errors.invalidHour"></small>
            </div>

            <div class="col-6">
                <label class="form-label" translate="officeHours.opening.modal.form.days"></label>

                <div class="form-check form-switch" *ngFor="let day of daysOfWeek; let i = index">
                    <input class="form-check-input" type="checkbox" id="{{day}}"
                           formArrayName="days"
                           [formControl]="days.get('' + i)"
                           [value]="days.value[i]">
                    <label class="form-check-label" for="{{day}}">{{'common.day.' + day | translate}}</label>
                </div>
            </div>
        </div>

    </form>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="officeHourModal.close()"
            translate="common.cancel">
    </button>
    <button type="submit" class="btn btn-primary"
            [disabled]="officeHourForm.invalid"
            (click)="updateOfficeHour()"
            translate="common.modify">
    </button>
</div>

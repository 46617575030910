import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    HydrowashWashingTrackDto,
    HydrowashWashingTrackService,
} from '../../../_services/configuration-services';
import {HydrowashModalComponent} from './hydrowash-modal/hydrowash-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../_shared/notifications.service';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-hydrowash-management',
    templateUrl: './hydrowash-management.component.html'
})
export class HydrowashManagementComponent implements OnInit, OnDestroy {

    hydrowashTracks: HydrowashWashingTrackDto[];

    // icons
    faPen = faPen;
    faTrash = faTrash;

    constructor(private readonly notificationsService: NotificationsService,
                private readonly hydrowashWashingTrackService: HydrowashWashingTrackService,
                private readonly confirmService: ConfirmModalService,
                private readonly modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.getAllTracks();
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll('destroy');
    }

    getAllTracks(): void {
        this.hydrowashWashingTrackService.getAllTracks().pipe().subscribe(tracks => {
            this.hydrowashTracks = tracks;
        });
    }

    openHydrowashModal(track?: HydrowashWashingTrackDto): void {
        const modal = this.modalService.open(HydrowashModalComponent, {centered: true});
        modal.componentInstance.track = track;
        if (!track) {
            if (this.hydrowashTracks?.length > 0) {
                modal.componentInstance.lastTrackId = this.hydrowashTracks[(this.hydrowashTracks.length - 1)].id;
            } else {
                modal.componentInstance.lastTrackId = 'PISTE0';
            }
        }
        modal.result.then(async () => {
            this.getAllTracks();
        }, () => { /* catch the rejection */ });
    }

    deleteHydrowashTrack(trackId: string): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.hydrowashWashingTrackService.deleteTrack(trackId).pipe().subscribe(() => {
                    this.notificationsService.success({title: 'services.hydrowash.notifications.deleteSuccess'});
                    this.getAllTracks();
                });
            }
        });
    }

}

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
    MeasuringPointLightDto,
    MeasuringPointService,
    MeasuringPointType,
    SearchMeasuringPointResultDto,
    SearchUserDto,
    UserLightDto
} from '../../../_services/configuration-services';
import {SearchUserComponent} from '../../../_shared/_components/search-user/search-user.component';
import {SearchMeasuringPointComponent} from '../../../_shared/_components/search-measuring-point/search-measuring-point.component';
import {TranslateService} from '@ngx-translate/core';
import {NotificationsService} from '../../../_shared/notifications.service';
import {RolesService} from '../../../_shared/roles-service';
import {firstValueFrom} from 'rxjs';

@Component({
    selector: 'app-assign-service-measuring-point-modal',
    templateUrl: './assign-service-measuring-point-modal.component.html'
})
export class AssignServiceMeasuringPointModalComponent implements OnInit {

    @Input() serviceId!: string;
    @Input() measuringPoint: MeasuringPointLightDto | null = null;
    @Input() user: UserLightDto | null = null;
    @Input() measuringPointTypeToAdd: MeasuringPointType = MeasuringPointType.User;

    userId: string | null = null;
    measuringPointId: string | null = null;


    editMode: boolean;
    assignUserMode = false;
    reassignUserMode = false;
    oldMeasuringPointId: string | null = null;

    @ViewChild('searchUserComponent') searchUser: SearchUserComponent;
    @ViewChild('searchMeasuringPointComponent') searchMeasuringPoint: SearchMeasuringPointComponent;

    constructor(public activeModal: NgbActiveModal,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly rolesService: RolesService,
                private readonly measuringPointService: MeasuringPointService) {
    }

    ngOnInit(): void {
        this.assignUserMode = this.measuringPoint != null && this.measuringPoint.user == null;
        this.reassignUserMode = this.measuringPoint?.user != null;
        this.measuringPointId = this.measuringPoint?.id ?? null;

        if (this.reassignUserMode) {
            this.oldMeasuringPointId = this.measuringPoint?.id ?? null;
        }

        this.userId = this.user?.id ?? null;
    }

    async assignMeasuringPoint(): Promise<void> {
        if (this.measuringPointTypeToAdd === MeasuringPointType.User) {
            if (this.reassignUserMode) {
                await firstValueFrom(this.measuringPointService.reassignMeasuringPointUser(this.measuringPointId, this.userId, this.oldMeasuringPointId));
                this.notificationsService.success({title: 'measuringPoints.notifications.reassignSuccess'});
                this.activeModal.close('success');
            } else {
                if (this.userId) {
                    if (this.editMode) {
                        await firstValueFrom(this.measuringPointService.assignMeasuringPointToUser(this.measuringPointId, this.userId));

                    } else {
                        await firstValueFrom(this.measuringPointService.assignMeasuringPointToServiceAndUser(this.measuringPointId,
                            this.serviceId, this.userId));

                    }
                    this.notificationsService.success({title: 'measuringPoints.notifications.assignUserSuccess'});
                    this.activeModal.close('success');

                } else {
                    await firstValueFrom(this.measuringPointService.assignMeasuringPointToService(this.measuringPointId,
                        this.serviceId));
                    this.notificationsService.success({title: 'measuringPoints.notifications.assignSuccess'});
                    this.activeModal.close('success');
                }
            }
        } else if (this.measuringPointTypeToAdd === MeasuringPointType.Admin) {
            await firstValueFrom(this.measuringPointService.assignMeasuringPointToService(this.measuringPointId,
                this.serviceId));
            this.notificationsService.success({title: 'measuringPoints.notifications.assignSuccess'});
            this.activeModal.close('success');
        }
    }

    measuringPointSelected(measuringPoint: SearchMeasuringPointResultDto | null): void {
        this.measuringPointId = measuringPoint?.id ?? null;
    }

    userSelected(user: SearchUserDto | null): void {
        this.userId = user?.id ?? null;
    }

    getModalTitle(): string {
        if (this.assignUserMode) {
            return this.translateService.instant('measuringPoints.assignModal.assignUserTitle');
        }
        if (this.reassignUserMode) {
            return this.translateService.instant('measuringPoints.assignModal.reassignUserTitle');
        }
        return this.translateService.instant('measuringPoints.assignModal.title');
    }

    public get measuringPointType(): typeof MeasuringPointType {
        return MeasuringPointType;
    }

}

<div class="modal-header">
    <h4 class="modal-title" translate="services.view.purchase.modal.title"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">

    <form [formGroup]="purchaseForm">

        <div class="row">

            <!-- Date selection -->
            <div class="col-12 col-lg-6">
                <label class="form-label">{{ 'services.view.purchase.modal.startDate' | translate }}*</label>
                <div class="input-group">
                    <input class="form-control" name="dp"
                           formControlName="date"
                           ngbDatepicker #d="ngbDatepicker"
                           [minDate]="minDate" required>
                    <span class="input-group-text">
                        <button class="btn btn-link btn-sm"
                                (click)="d.toggle()">
                            <fa-icon [icon]="calendar"></fa-icon>
                        </button>
                        <button class="btn btn-link btn-sm" *ngIf="date.value"
                                (click)="date.setValue(null)">
                            <fa-icon [icon]="times"></fa-icon>
                        </button>
                    </span>
                </div>
                <app-form-error [control]="date"
                                invalidKey="common.form.errors.dateInPast"></app-form-error>
            </div>

            <!-- Hour selection -->
            <div class="col-12 col-lg-6">
                <label class="form-label">{{'services.view.purchase.modal.startTime' | translate}}*</label>
                <select class="form-control form-select" id="startTime" name="startTime"
                        formControlName="time"
                        required>
                    <option *ngFor="let hour of hoursOfDay;"
                            [value]="hour">{{hour}}</option>
                </select>
                <app-form-error [control]="time"></app-form-error>
            </div>

            <!-- User search -->
            <div class="col-12 col-lg-6" *ngIf="user">
                <label class="form-label">{{ 'transactions.filter.user' | translate }}*</label>
                <app-search-user ngModel #searchUserComponent
                                 (selectedUserEvent)="selectUser($event)"
                                 formControlName="user"
                                 ngDefaultControl></app-search-user>
            </div>

            <!-- Quantity -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="quantity" translate="services.view.purchase.modal.quantity"></label>
                <input class="form-control" id="quantity" name="quantity" type="number"
                       [formControl]="quantity" placeholder="1"
                       (keydown)="FormUtils.validateInputNumberLength($event, 10)" required>
                <app-form-error [control]="quantity"></app-form-error>
            </div>

            <!-- Unit -->
            <div class="col-12 col-lg-6">
                <label class="form-label" for="unit">{{ 'services.tariff.modal.unit' | translate }}*</label>
                <select class="form-control form-select" id="unit" name="unit"
                        [formControl]="unit">
                    <option *ngFor="let tariffUnit of availableTariffUnits"
                            [ngValue]="tariffUnit">{{ 'common.units.' + tariffUnit.unitEnumName + '.' + tariffUnit.unitEnumValue | translate }}</option>
                </select>
            </div>

            <!-- Price -->
            <div class="col-12 col-lg-6" *ngIf="!!unit && !!quantity">
                <label class="form-label" for="price">{{ 'services.view.purchase.modal.price' | translate }}*</label>
                <div class="input-group">
                    <input class="form-control" id="price" type="number"
                           value="{{ getPriceByUnit() * quantity.value }}" readonly>
                    <span class="input-group-text">CHF</span>
                </div>
            </div>

            <!-- Payment type -->
            <div class="col-12" *ngIf="availablePaymentTypes.length > 1">
                <label class="form-label">{{ 'common.terms.paymentType' | translate }}*</label>
                <div>
                    <app-payment-type-chooser
                        [availablePaymentTypes]="availablePaymentTypes"
                        (selectedPaymentType)="selectPaymentType($event)"></app-payment-type-chooser>
                </div>
                <app-form-error [control]="paymentType"></app-form-error>
            </div>

            <app-show-cgv [formGroup]="purchaseForm">
            </app-show-cgv>

        </div>

    </form>
</div>


<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="common.cancel">
    </button>

    <button class="btn btn-primary" type="submit"
            (click)="purchase()"
            [disabled]="purchaseForm.invalid"
            translate="services.view.purchase.modal.confirmText">
    </button>
</div>

<div class="row" [formGroup]="roleForm" *ngIf="loaded">

    <!--- publicRole -->
    <div class="col-12" *ngIf="enablePublicAccess">
        <label class="form-label">{{ 'roles.public' | translate }}<span *ngIf="!requiredFields">*</span></label>

        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="public"
                   [formControl]="publicRolesFormControl">
            <label class="form-check-label" for="public">{{ (publicRolesFormControl.value ? 'common.yes' : 'common.no') | translate}}</label>
        </div>
    </div>

    <!--- BasicRole -->
    <div class="col-12 col-sm-6" *ngIf="enableBaseRoles">
        <label class="form-label">{{ 'roles.label' | translate }}<span *ngIf="requiredFields">*</span></label>

        <div class="form-check form-switch" *ngFor="let _ of baseRolesFormArray.controls; let i = index"
             formArrayName="baseRolesForm">
            <input class="form-check-input" type="checkbox" id="{{i}}"
                   [formControlName]="i">
            <label class="form-check-label" for="{{i}}">{{ baseRoles[i]['label' + crtLang.toUpperCase()] }}</label>
        </div>
    </div>

    <!--- CustomRole -->
    <div class="col-12 col-sm-6" *ngIf="enableCustomRoles && !areCustomRolesHiddenByUserRole">
        <label class="form-label">{{ 'users.form.customUserRoles' | translate }}</label>

        <div class="form-check form-switch" *ngFor="let _ of customRolesFormArray.controls; let id = index"
             formArrayName="customRolesForm">
            <input class="form-check-input" type="checkbox" id="custom-role-{{id}}"
                   [formControlName]="id">
            <label class="form-check-label" for="custom-role-{{id}}">{{ customRoles[id]['label' + crtLang.toUpperCase()] }}</label>
        </div>
    </div>
</div>

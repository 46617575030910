<div class="modal-header">
    <h4 class="modal-title" translate="services.publicLighting.light.detail"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="this.activeModal.close('close')">
    </button>
</div>

<div class="modal-body custom-layout">
    <form [formGroup]="lightForm" class="m-0">

        <h6 translate="services.publicLighting.light.informations"></h6>

        <!-- Light information-->
        <div class="row">
            <div class="col-6">
                <label class="form-label" for="code">{{ 'services.publicLighting.light.modal.code' | translate }}*</label>
            </div>
            <div class="col-6">
                <input class="form-control" id="code" type="text" maxlength="5"
                       pattern="{{textInputPattern}}"
                       [formControl]="code" required>
                <app-form-error [control]="code"></app-form-error>
            </div>
        </div>

        <!-- Description -->
        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'services.publicLighting.light.modal.descriptionFR' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <textarea class="form-control" id="descriptionFR"
                      placeholder="{{ 'services.publicLighting.light.modal.descriptionFR' | translate }}"
                      maxLength="255" rows="3"
                      [formControl]="descriptionFR"
                      pattern="{{ FormUtils.textInputPattern }}"></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'services.publicLighting.light.modal.descriptionDE' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <textarea class="form-control" id="descriptionDE"
                      placeholder="{{ 'services.publicLighting.light.modal.descriptionDE' | translate }}"
                      maxLength="255" rows="3"
                      [formControl]="descriptionDE"
                      pattern="{{ FormUtils.textInputPattern }}"></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'services.publicLighting.light.modal.descriptionIT' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <textarea class="form-control" id="descriptionIT"
                      placeholder="{{ 'services.publicLighting.light.modal.descriptionIT' | translate }}"
                      maxLength="255" rows="3"
                      [formControl]="descriptionIT"
                      pattern="{{ FormUtils.textInputPattern }}"></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'services.publicLighting.light.modal.descriptionEN' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <textarea class="form-control" id="descriptionEN"
                      placeholder="{{ 'services.publicLighting.light.modal.descriptionEN' | translate }}"
                      maxLength="255" rows="3"
                      [formControl]="descriptionEN"
                      pattern="{{ FormUtils.textInputPattern }}"></textarea>
            </div>
        </div>

        <!-- Coordinates -->
        <div class="row">
            <div class="col-6">
                <label class="form-label" for="coordinateLatitude">{{ 'services.manage.coordinates.latitude' | translate }}*</label>
            </div>
            <div class="col-6">
                <input class="form-control" type="text" id="coordinateLatitude"
                       pattern="{{textInputPattern}}"
                       [formControl]="latitude" required>
                <app-form-error [control]="latitude"></app-form-error>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label class="form-label" for="coordinateLongitude">{{ 'services.manage.coordinates.longitude' | translate }}*</label>
            </div>
            <div class="col-6">
                <input class="form-control" id="coordinateLongitude" type="text"
                       pattern="{{ textInputPattern }}"
                       [formControl]="longitude" required>
                <app-form-error [control]="longitude"></app-form-error>
            </div>
        </div>

        <!-- Zone -->
        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'services.publicLighting.light.modal.zone' | translate }}</label>
            </div>
            <div class="col-6 text-end">
                <select class="form-control form-select" [formControl]="lightZone">
                    <option [value]="null">-</option>
                    <option *ngFor="let lightZone of lightZones;"
                            [ngValue]="lightZone">{{ lightZone.code }}</option>
                </select>
            </div>
        </div>

        <!-- Link with External Service -->
        <div class="row">
            <div class="col-6">
                <label class="form-label">{{ 'services.publicLighting.light.modal.provider' | translate }}</label>
            </div>
            <div class="col-6 text-end">

                <ng-container *ngIf="lightProvider">
                    <input class="form-control margin-bottom" type="text"
                           [formControl]="lightProvider">

                    <input class="form-control margin-bottom" type="text"
                           pattern="{{ textInputPattern }}"
                           [value]="externalLight.value.name"
                           disabled>
                </ng-container>

                <div class="btn-bar">
                    <button class="btn btn-outline-secondary"
                            *ngIf="externalLight && initialExternalLight !== externalLight.value"
                            (click)="resetToInitialValue()"
                            translate="common.cancel">
                    </button>

                    <button class="btn btn-primary"
                            (click)="openConfigureProviderModal()"
                            translate="common.configure">
                    </button>
                </div>

            </div>
        </div>

    </form>

</div>

<div class="modal-footer space-between">
    
    <button class="btn btn-outline-danger" *ngIf="editionMode"
            (click)="delete()"
            translate="common.delete">
    </button>
    
    <div>
        <button class="btn btn-outline-secondary"
                (click)="this.activeModal.close('close')"
                translate="common.cancel">
        </button>

        <button class="btn btn-primary"
                [disabled]="lightForm.invalid"
                (click)="save()"
                translate="common.save">
        </button>
    </div>
</div>

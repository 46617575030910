import {Component, Input, OnInit} from '@angular/core';
import {
    PointOfSaleDto, PointOfSaleInputDto,
    PointOfSaleService
} from '../../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../../_shared/notifications.service';
import {CustomerConfigService} from '../../../../_shared/customer-config-service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../../_shared/form-utils';

@Component({
    selector: 'app-point-of-sale-management-modal',
    templateUrl: './point-of-sale-management-modal.component.html'
})
export class PointOfSaleManagementModalComponent implements OnInit {

    @Input() serviceId: string;
    @Input() pointOfSale: PointOfSaleDto | null;

    FormUtils = FormUtils;

    pointOfSaleForm: UntypedFormGroup;

    constructor(public activeModal: NgbActiveModal,
                private readonly pointOfSaleService: PointOfSaleService,
                private readonly notificationService: NotificationsService,
                public readonly customerConfigService: CustomerConfigService) {

        this.pointOfSaleForm = new UntypedFormGroup({
                posId: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
                nameFR: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
                nameDE: new UntypedFormControl(null, Validators.maxLength(50)),
                nameIT: new UntypedFormControl(null, Validators.maxLength(50)),
                nameEN: new UntypedFormControl(null, Validators.maxLength(50)),
                descriptionFR: new UntypedFormControl(null, Validators.maxLength(100)),
                descriptionDE: new UntypedFormControl(null, Validators.maxLength(100)),
                descriptionIT: new UntypedFormControl(null, Validators.maxLength(100)),
                descriptionEN: new UntypedFormControl(null, Validators.maxLength(100)),
            },
            {updateOn: 'change'}
        );
    }

    ngOnInit(): void {
        if (this.pointOfSale) {
            this.posId.setValue(this.pointOfSale.posId);
            this.nameFR.setValue(this.pointOfSale.nameFR);
            this.nameDE.setValue(this.pointOfSale.nameDE);
            this.nameIT.setValue(this.pointOfSale.nameIT);
            this.nameEN.setValue(this.pointOfSale.nameEN);
            this.descriptionFR.setValue(this.pointOfSale.descriptionFR);
            this.descriptionDE.setValue(this.pointOfSale.descriptionDE);
            this.descriptionIT.setValue(this.pointOfSale.descriptionIT);
            this.descriptionEN.setValue(this.pointOfSale.descriptionEN);
        }
    }

    public addOrUpdatePointOfSale(): void {
        if (this.pointOfSaleForm.invalid) {
            return;
        }
        const pointOfSaleToSave = new PointOfSaleInputDto({
            posId: this.posId.value,
            serviceId: this.serviceId,
            nameFR: this.nameFR.value,
            nameDE: this.nameDE.value,
            nameIT: this.nameIT.value,
            nameEN: this.nameEN.value,
            descriptionFR: this.descriptionFR.value,
            descriptionDE: this.descriptionDE.value,
            descriptionIT: this.descriptionIT.value,
            descriptionEN: this.descriptionEN.value,
        });

        if (!this.pointOfSale) {
            this.pointOfSaleService.createPointOfSale(pointOfSaleToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.pointOfSale.notifications.addSuccess'});
                this.activeModal.close('success');
            });

        } else {
            this.pointOfSaleService.updatePointOfSale(this.pointOfSale.posId,pointOfSaleToSave).pipe().subscribe(_ => {
                this.notificationService.success({title: 'services.pointOfSale.notifications.updateSuccess'});
                this.activeModal.close('success');
            });
        }
    }

    get posId(): UntypedFormControl {
        return this.pointOfSaleForm.get('posId') as UntypedFormControl;
    }

    get nameFR(): UntypedFormControl {
        return this.pointOfSaleForm.get('nameFR') as UntypedFormControl;
    }

    get nameDE(): UntypedFormControl {
        return (this.pointOfSaleForm.get('nameDE') as UntypedFormControl);
    }

    get nameIT(): UntypedFormControl {
        return this.pointOfSaleForm.get('nameIT') as UntypedFormControl;
    }

    get nameEN(): UntypedFormControl {
        return this.pointOfSaleForm.get('nameEN') as UntypedFormControl;
    }

    get descriptionFR(): UntypedFormControl {
        return this.pointOfSaleForm.get('descriptionFR') as UntypedFormControl;
    }

    get descriptionDE(): UntypedFormControl {
        return this.pointOfSaleForm.get('descriptionDE') as UntypedFormControl;
    }

    get descriptionIT(): UntypedFormControl {
        return this.pointOfSaleForm.get('descriptionIT') as UntypedFormControl;
    }

    get descriptionEN(): UntypedFormControl {
        return this.pointOfSaleForm.get('descriptionEN') as UntypedFormControl;
    }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSegment} from '@angular/router';
import {ServicesComponent} from './services/services.component';
import {MsalGuard} from '@azure/msal-angular';
import {ManageServiceComponent} from './services/manage-service/manage-service.component';
import {UsersComponent} from './users/users.component';
import {EwalletViewComponent} from './ewallet/ewallet-view/ewallet-view.component';
import {CashierTransactionComponent} from './ewallet/transactions/cashier-transaction/cashier-transaction.component';
import {RoleGuard} from './_shared/role-guard';
import {AppRoles} from './app.constants';
import {UserManagementComponent} from './users/user-management/user-management.component';
import {ViewServiceComponent} from './services/view-service/view-service.component';
import {CguComponent} from './cgu/cgu.component';
import {TransactionsComponent} from './transactions/transactions.component';
import {RfidCardsComponent} from './rfid-cards/rfid-cards.component';
import {AlarmComponent} from './alarms/alarm.component';
import {AlarmManagementComponent} from './alarms/alarm-management/alarm-management.component';
import {AlarmHistoryComponent} from './alarms/alarm-history/alarm-history.component';
import {AlarmDeviceManagementComponent} from './alarms/alarm-device-management/alarm-device-management.component';
import {AlertComponent} from './alerts/alert.component';
import {AlertHistoryComponent} from './alerts/alert-history/alert-history.component';
import {AlertDeviceManagementComponent} from './alerts/alert-device-management/alert-device-management.component';
import {AlertManagementComponent} from './alerts/alert-management/alert-management.component';
import {OfficeHourManagementComponent} from './infrastructure/office-hour-management/office-hour-management.component';
import {InfrastructureComponent} from './infrastructure/infrastructure.component';
import {CraningBookingPrintComponent} from './services/view-service/craning/craning-booking/craning-booking-print/craning-booking-print.component';
import {TransactionBillingComponent} from './transactions/transaction-billing/transaction-billing.component';
import {TransactionCommonComponent} from './transactions/transaction-common/transaction-common.component';
import {TransactionType} from './_services/configuration-services';
import {MaintenanceComponent} from './infrastructure/maintenance/maintenance.component';
import {ManageEquipmentComponent} from './infrastructure/maintenance/manage-equipment/manage-equipment.component';
import {InterventionsComponent} from './infrastructure/maintenance/interventions/interventions.component';
import {InformationsComponent} from './communication-channel/informations/informations.component';
import {SurveysComponent} from './communication-channel/surveys/surveys.component';
import {ContactComponent} from './communication-channel/contact/contact.component';
import {CommunicationChannelComponent} from './communication-channel/communication-channel.component';
import {CommunicationChannelGuard} from './communication-channel/utils/communication-channel.guard';
import {ContactManagementComponent} from './communication-channel/contact/contact-management/contact-management.component';
import {InformationsManagementComponent} from './communication-channel/informations/informations-management/informations-management.component';
import {SurveysManagementComponent} from './communication-channel/surveys/surveys-management/surveys-management.component';
import {ViewInfoComponent} from './communication-channel/informations/view-info/view-info.component';
import {ManageSurveyComponent} from './communication-channel/surveys/surveys-edition/manage-survey.component';
import {ManageInformationComponent} from './communication-channel/informations/manage-information/manage-information.component';
import {ViewSurveyComponent} from './communication-channel/surveys/view-survey/view-survey.component';
import {ForgetMeComponent} from './forget-me/forget-me.component';
import {ManageInterventionComponent} from './infrastructure/maintenance/interventions/manage-intervention/manage-intervention.component';
import {EwalletsComponent} from "./ewallet/ewallets.component";
import {StatisticsComponent} from './statistics/statistics.component';
import {TransactionFullSearchComponent} from './transactions/transaction-full-search/transaction-full-search.component';
import {CgvComponent} from "./cgv/cgv.component";
import {EwalletListComponent} from './ewallet/ewallet-list/ewallet-list.component';
import {EwalletClosureScheduleComponent} from './ewallet/ewallet-closure-schedule/ewallet-closure-schedule.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/services',
        pathMatch: 'full',
    },
    {
        path: 'alarms',
        redirectTo: '/alarms/alarm-management',
        pathMatch: 'full',
    },
    {
        path: 'alarms',
        component: AlarmComponent,
        children: [
            {
                path: 'alarm-management',
                component: AlarmManagementComponent,
                data: {
                    allowedRoles: [AppRoles.admin, AppRoles.alarmManager]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'alarm-history',
                component: AlarmHistoryComponent,
                data: {
                    allowedRoles: [AppRoles.admin, AppRoles.alarmManager]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'alarm-device-management',
                component: AlarmDeviceManagementComponent,
                data: {
                    allowedRoles: [AppRoles.admin, AppRoles.alarmManager]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            }
        ],
        data: {
            allowedRoles: [AppRoles.admin, AppRoles.alarmManager]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'alerts',
        redirectTo: '/alerts/alert-management',
        pathMatch: 'full',
    },
    {
        path: 'alerts',
        component: AlertComponent,
        children: [
            {
                path: 'alert-management',
                component: AlertManagementComponent,
                data: {
                    allowedRoles: [AppRoles.admin, AppRoles.alarmManager]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'alert-history',
                component: AlertHistoryComponent,
                data: {
                    allowedRoles: [AppRoles.admin, AppRoles.alarmManager]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'alert-device-management',
                component: AlertDeviceManagementComponent,
                data: {
                    allowedRoles: [AppRoles.admin, AppRoles.alarmManager]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            }
        ],
        data: {
            allowedRoles: [AppRoles.admin, AppRoles.alarmManager]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'cgu',
        component: CguComponent,
    },
    {
        path: 'cgv',
        component: CgvComponent,
    },
    {
        path: 'communication-channel',
        component: CommunicationChannelComponent,
        children: [
            {
                path: 'information',
                component: InformationsComponent,
                data: {
                    communicationChannelType: 'information'
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            }, {
                path: 'information/manage',
                component: InformationsManagementComponent,
                data: {
                    allowedRoles: [AppRoles.admin],
                    communicationChannelType: 'information'
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            },
            {
                path: 'information/manage/create',
                component: ManageInformationComponent,
                data: {
                    allowedRoles: [AppRoles.admin],
                    communicationChannelType: 'information'
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            },
            {
                path: 'information/manage/:id',
                component: ManageInformationComponent,
                data: {
                    allowedRoles: [AppRoles.admin],
                    communicationChannelType: 'information'
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            },
            {
                path: 'information/:id',
                component: ViewInfoComponent,
                data: {
                    communicationChannelType: 'information'
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            },
            {
                path: 'surveys',
                component: SurveysComponent,
                data: {
                    communicationChannelType: 'survey'
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            }, {
                path: 'surveys/manage',
                component: SurveysManagementComponent,
                data: {
                    allowedRoles: [AppRoles.admin],
                    communicationChannelType: 'survey'
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            },
            {
                path: 'surveys/manage/create',
                component: ManageSurveyComponent,
                data: {
                    allowedRoles: [AppRoles.admin],
                    communicationChannelType: 'survey'
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            },
            {
                path: 'surveys/manage/:id',
                component: ManageSurveyComponent,
                data: {
                    allowedRoles: [AppRoles.admin],
                    communicationChannelType: 'survey'
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            },
            {
                path: 'surveys/:id',
                component: ViewSurveyComponent,
                data: {
                    communicationChannelType: 'survey'
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            },
            {
                path: 'contact',
                component: ContactComponent,
                data: {
                    communicationChannelType: 'contact'
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            }, {
                path: 'contact/manage',
                component: ContactManagementComponent,
                data: {
                    communicationChannelType: 'contact',
                    allowedRoles: [AppRoles.admin]
                },
                canActivate: [
                    MsalGuard, RoleGuard, CommunicationChannelGuard
                ]
            }],
        data: {
            communicationChannelType: 'general',
            allowedRoles: [AppRoles.admin, AppRoles.user]
        },
        canActivate: [
            MsalGuard, RoleGuard, CommunicationChannelGuard
        ]
    },
    {
        path: 'ewallet',
        component: EwalletViewComponent,
        data: {
            allowedRoles: [AppRoles.user]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ],
    },
    {
        path: 'ewallet/:userId',
        component: EwalletViewComponent,
        data: {
            allowedRoles: [AppRoles.cashier]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ],
    },
    {
        path: 'ewallet/:userId/cashier-operation',
        component: CashierTransactionComponent,
        data: {
            allowedRoles: [AppRoles.cashier]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ],
    },
    {
        path: 'ewallets',
        redirectTo: '/ewallets/list',
        pathMatch: 'full',
    },
    {
        path: 'ewallets',
        component: EwalletsComponent,
        children: [
            {
                path: 'list',
                component: EwalletListComponent,
                data: {
                    allowedRoles: [AppRoles.cashier]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'closure-schedule',
                component: EwalletClosureScheduleComponent,
                data: {
                    allowedRoles: [AppRoles.cashier]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            }
        ],
        data: {
            allowedRoles: [AppRoles.cashier]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ],
    },
    {
        path: 'forgetme',
        component: ForgetMeComponent,
        canActivate: [
            MsalGuard
        ]
    },
    {
        path: 'infrastructure',
        redirectTo: '/infrastructure/office-hour-management',
        pathMatch: 'full',
    },
    {
        path: 'infrastructure',
        component: InfrastructureComponent,
        children: [
            {
                path: 'office-hour-management',
                component: OfficeHourManagementComponent,
                data: {
                    allowedRoles: [AppRoles.admin]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'maintenance',
                component: MaintenanceComponent,
                data: {
                    allowedRoles: [AppRoles.admin]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'maintenance/equipment/create',
                component: ManageEquipmentComponent,
                data: {
                    allowedRoles: [AppRoles.admin]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'maintenance/equipment/:id',
                component: ManageEquipmentComponent,
                data: {
                    allowedRoles: [AppRoles.admin]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'interventions',
                component: InterventionsComponent,
                data: {
                    allowedRoles: [AppRoles.admin]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'interventions/create',
                component: ManageInterventionComponent,
                data: {
                    allowedRoles: [AppRoles.admin]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'interventions/:id',
                component: ManageInterventionComponent,
                data: {
                    allowedRoles: [AppRoles.admin]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
        ],
        data: {
            allowedRoles: [AppRoles.admin]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'rfid-cards',
        component: RfidCardsComponent,
        data: {
            allowedRoles: [AppRoles.admin]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'services',
        component: ServicesComponent,
    },
    {
        path: 'services/manage',
        component: ManageServiceComponent,
        data: {
            allowedRoles: [AppRoles.admin, AppRoles.configurator]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'services/manage/:serviceId',
        component: ManageServiceComponent,
        data: {
            allowedRoles: [AppRoles.admin, AppRoles.configurator]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'services/:serviceId',
        component: ViewServiceComponent
    },
    {
        path: 'services/:serviceId/activate/:measuringPointId',
        component: ViewServiceComponent
    },
    {
        path: 'services/:serviceId/craning-booking/print/:from/:to',
        component: CraningBookingPrintComponent,
        data: {
            allowedRoles: [AppRoles.admin, AppRoles.harbourmaster]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'services/:serviceId/craning-booking/print/:craningBookingId',
        component: CraningBookingPrintComponent,
        data: {
            allowedRoles: [AppRoles.admin, AppRoles.harbourmaster]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'services/:serviceId/start-charge/:externalChargingStationId/evse/:evseIndex',
        component: ViewServiceComponent
    },
    {
        path: 'services/:serviceId/stop-charge/:chargeProcessId',
        component: ViewServiceComponent
    },
    {
        path: 'statistics',
        component: StatisticsComponent,
        data: {
            allowedRoles: [AppRoles.admin]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'transactions',
        redirectTo: '/transactions/transaction-consumption',
        pathMatch: 'full',

    },
    {
        path: 'transactions',
        component: TransactionsComponent,
        children: [
            {
                path: 'transaction-consumption',
                component: TransactionCommonComponent,
                data: {
                    allowedRoles: [AppRoles.cashier, AppRoles.user],
                    transactionType: TransactionType.ServiceConsumption
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'transaction-credit',
                component: TransactionCommonComponent,
                data: {
                    allowedRoles: [AppRoles.cashier],
                    transactionType: TransactionType.EWalletCredit
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'transaction-billing',
                component: TransactionBillingComponent,
                data: {
                    allowedRoles: [AppRoles.cashier]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            },
            {
                path: 'search',
                component: TransactionFullSearchComponent,
                data: {
                    allowedRoles: [AppRoles.configurator]
                },
                canActivate: [
                    MsalGuard, RoleGuard
                ]
            }
        ],
        data: {
            allowedRoles: [AppRoles.cashier, AppRoles.user, AppRoles.configurator]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'users',
        component: UsersComponent,
        data: {
            allowedRoles: [AppRoles.admin]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'users/create',
        component: UserManagementComponent,
        data: {
            allowedRoles: [AppRoles.admin]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'users/me',
        component: UserManagementComponent,
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
        path: 'users/:id',
        component: UserManagementComponent,
        data: {
            allowedRoles: [AppRoles.admin]
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    },
    {
       // Handle B2C redirection where the path contains a token
       matcher: (url: UrlSegment[]) => {
           return isB2CRedirectionPath(url);
       },
       component: ServicesComponent,
   }
];

const isB2CRedirectionPath = (url: UrlSegment[]) => {
    return url.length === 1 && (url[0].path.startsWith('state') || url[0].path.startsWith('error') || url[0].path.startsWith('code'))
        ? { consumed: url } : null;
}

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    useHash: true,
    initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
    scrollPositionRestoration: 'top'
})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

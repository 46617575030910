import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    EnergyService, MeasureService,
    MeasuringPointType,
    SearchMeasuringPointResultDto,
    StateCockpitDataDto
} from '../../../../_services/configuration-services';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {DateUtils} from '../../../../_shared/date-utils';
import {TranslateUtils} from '../../../../_shared/translate-utils';

@Component({
    selector: 'app-cockpit-energy-state',
    templateUrl: './cockpit-energy-state.component.html',
    styleUrls: ['./cockpit-energy-state.component.scss']
})
export class CockpitEnergyStateComponent implements OnInit, OnDestroy {
    @Input() serviceId: string;

    private langChangeSubscription$: Subscription;
    private refreshInterval: number;

    measuringPointTypeAdmin = MeasuringPointType.Admin;

    // Displayed values
    configuredIntensityLimit = null;
    actualIntensity = null;
    actualPower = null;
    intensityMax15Min = null;

    // Battery values
    batteryChargeLimit = null;
    batteryCurrentValue = null;
    batteryValueMax = null;

    measuringPoint: SearchMeasuringPointResultDto;
    cockpitData: StateCockpitDataDto;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(
        private readonly datePipe: DatePipe,
        private readonly energyService: EnergyService,
        private readonly measureService: MeasureService,
        private readonly translateService: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.refreshInterval = setInterval(async _ => {
            if (this.measuringPoint) {
                await this.fetchCockpitData();
            }
        }, 60000);

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription$ = this.translateService.onLangChange
            .subscribe(_ => {
                this.setupLanguage();
                window.location.reload();
            });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$?.unsubscribe();
        clearInterval(this.refreshInterval);
    }

    async measuringPointSelected(measuringPoint: SearchMeasuringPointResultDto | null): Promise<void> {
        this.measuringPoint = measuringPoint;
        if (this.measuringPoint) {
            await this.fetchCockpitData();
        }
    }

    async fetchCockpitData(): Promise<void> {
        this.cockpitData = await this.energyService.getDataForAdminCockpit(this.measuringPoint.id).toPromise();

        // Displayed values
        if (this.cockpitData.maxIntensityConfigured?.lastKnownValue != null
                && this.cockpitData.maxIntensityConfigured.lastKnownValue >= 0) {
            this.configuredIntensityLimit = this.cockpitData.maxIntensityConfigured?.lastKnownValue;
            this.batteryChargeLimit = this.configuredIntensityLimit;

        } else {
            this.configuredIntensityLimit = '--';
            this.batteryChargeLimit = null;
        }

        if (this.cockpitData.actualIntensity?.lastKnownValue != null && this.cockpitData.actualIntensity.lastKnownValue >= 0) {
            this.actualIntensity = this.cockpitData.actualIntensity.lastKnownValue.toFixed(2);
            this.batteryCurrentValue = this.actualIntensity;

        } else {
            this.actualIntensity = '--';
            this.batteryCurrentValue = 0;
        }

        if (this.cockpitData.actualPower?.lastKnownValue != null && this.cockpitData.actualPower.lastKnownValue >= 0) {
            this.actualPower = this.cockpitData.actualPower.lastKnownValue.toFixed(2);
        } else {
            this.actualPower = '--';
        }

        if (this.cockpitData.maxIntensity15Min?.lastKnownValue != null && this.cockpitData.maxIntensity15Min.lastKnownValue >= 0) {
            this.intensityMax15Min = this.cockpitData.maxIntensity15Min.lastKnownValue.toFixed(2);
        } else {
            this.intensityMax15Min = '--';
        }

        this.batteryValueMax = Math.max(this.batteryCurrentValue, this.configuredIntensityLimit);
    }

    resetMaxIntensity(): void {
        this.measureService.resetMeasure(this.cockpitData.maxIntensity15Min.id).toPromise()
            .then(r => this.fetchCockpitData());
    }

    setupLanguage(): void {
        this.crtLang = this.translateService.currentLang;
    }

    formatDate(date: Date): string {
        return this.datePipe.transform(date, DateUtils.dateTimeWithoutSecondFormat);
    }

}

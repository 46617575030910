import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    PageableRequestDto,
    PagedResultDtoOfUserAuditEntryDto,
    RfidCardDto,
    RfidCardService,
    RoleDto,
    RoleService,
    Title,
    UserAuditEntryDto,
    UserAuditEntryPropertyChangeType,
    UserInputDto,
    UserMeInputDto,
    UserService
} from '../../_services/configuration-services';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsService} from '../../_shared/notifications.service';
import {Location} from '@angular/common';
import {NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserRfidCardModalComponent} from './user-rfid-card-modal/user-rfid-card-modal.component';
import {faCalendar, faTimes, faUserSlash} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../environments/environment';
import {MsalService} from '@azure/msal-angular';
import {FormUtils} from '../../_shared/form-utils';
import {DateUtils} from '../../_shared/date-utils';
import {ConfirmModalService} from '../../_shared/_components/confirm-modal/confirm-modal.component';
import {CustomDateFormatter} from '../../_shared/custom-date-formatter';
import {RoleManagementComponent} from '../../_shared/_components/role-management/role-management.component';
import {AppRoles, CssConstants} from '../../app.constants';
import {
    UserDeleteConfirmationModalComponent
} from './user-delete-confirmation-modal/user-delete-confirmation-modal.component';
import {firstValueFrom, Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TranslateUtils} from '../../_shared/translate-utils';
import {CustomerConfigService} from '../../_shared/customer-config-service';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    providers: [
        {provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class UserManagementComponent implements OnInit, OnDestroy {

    @ViewChild(RoleManagementComponent) roleComponent: RoleManagementComponent;

    UserAuditEntryPropertyChangeType = UserAuditEntryPropertyChangeType;
    AppRoles = AppRoles;
    textInputPattern = FormUtils.textInputPattern;
    dateTimeFormat = DateUtils.dateTimeFormat;

    userForm: UntypedFormGroup;

    userId: string | null = null;
    editingMyProfile = false;

    rfidCards: RfidCardDto[] = [];

    // icons
    icons = {
        calendar: faCalendar,
        times: faTimes,
        userSlash: faUserSlash
    };

    public roles: RoleDto[] = [];
    public customRoles: RoleDto[] = [];

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    pagedAudits: PagedResultDtoOfUserAuditEntryDto | null = null;
    auditCurrentPage = 0;
    auditPageSize = 10;

    constructor(
        private readonly roleService: RoleService,
        private readonly userService: UserService,
        private readonly router: Router,
        private readonly notificationsService: NotificationsService,
        private readonly modalService: NgbModal,
        private readonly route: ActivatedRoute,
        private readonly confirmService: ConfirmModalService,
        private readonly authService: MsalService,
        private readonly location: Location,
        private readonly rfidCardService: RfidCardService,
        private readonly translateService: TranslateService,
        private readonly customerConfigService: CustomerConfigService) {

        this.userForm = new UntypedFormGroup({
                id: new UntypedFormControl(null),
                title: new UntypedFormControl(Title.NotSpecified, [Validators.required]),
                firstname: new UntypedFormControl(null, [Validators.required]),
                lastname: new UntypedFormControl(null, [Validators.required]),
                birthDate: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
                email: new UntypedFormControl(null, [Validators.required, FormUtils.emailValidator]),
                phone: new UntypedFormControl(null, [FormUtils.phoneNumberPatternValidator]),
                mobilePhone: new UntypedFormControl(null, [FormUtils.phoneNumberPatternValidator]),
                businessPhone: new UntypedFormControl(null, [FormUtils.phoneNumberPatternValidator]),
                street: new UntypedFormControl(null, [Validators.required]),
                postalCode: new UntypedFormControl(null, [Validators.required]),
                city: new UntypedFormControl(null, [Validators.required]),
                country: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]),
                customerNumber: new UntypedFormControl(null, [Validators.required]),
                rolesForm: new UntypedFormGroup({
                    publicAccess: new UntypedFormControl(false, {updateOn: 'change'}),
                    baseRolesForm: new UntypedFormArray([], {
                        validators: FormUtils.atLeastOneSelectedValidator,
                        updateOn: 'change'
                    }),
                    customRolesForm: new UntypedFormArray([], {updateOn: 'change'})
                }),
                attentionTo: new UntypedFormControl(null),
                accountEnabled: new UntypedFormControl(true, {validators: Validators.required}),
                comment: new UntypedFormControl(null),
                internalField1: new UntypedFormControl(null),
                internalField2: new UntypedFormControl(null),
                internalField3: new UntypedFormControl(null),
                internalField4: new UntypedFormControl(null),
                internalField5: new UntypedFormControl(null),
            },
            {updateOn: 'change'}
        );
    }

    async ngOnInit(): Promise<void> {
        this.userId = this.route.snapshot.params['id'] ?? null;

        this.crtLang = this.translateService.currentLang;

        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        if (!!this.userId) { // Edit User (Admin)
            this.userService.getUser(this.userId).pipe().subscribe(user => {
                this.rfidCards = user.rfidCards ?? [];

                this.roleService.getAllRoles(false).pipe().subscribe(roles => {
                    this.roles = roles.filter(r => r.isBase).sort((a, b) => a.roleKey.localeCompare(b.roleKey));
                    this.customRoles = roles.filter(r => !r.isBase);

                    this.setupFormToEditFullProfile(user);
                });

            }, () => this.back());
        } else if (this.route.routeConfig.path.endsWith('create')) { // Create User (Admin)
            this.roleService.getAllRoles(false).pipe().subscribe(async roles => {
                this.roles = roles.filter(r => r.isBase).sort((a, b) => a.roleKey.localeCompare(b.roleKey));
                this.customRoles = roles.filter(r => !r.isBase);

                if (this.customerConfigService.customerNumberAutoIncrementEnabled) {
                    const customerNumber = await firstValueFrom(this.userService.getNextCustomerNumber());

                    this.customerNumber.setValue(customerNumber);
                }

                this.configureRolesControl(null);
            });

        } else { // Edit my profile
            this.editingMyProfile = true;

            this.userService.getMe().pipe().subscribe(user => {
                this.rfidCards = user.rfidCards ?? [];
                this.setupFormToEditMyProfile(user);
            });
        }
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll('destroy');

        this.langChangeSubscription.unsubscribe();
    }

    back(): void {
        this.location.back();
    }

    save(): void {
        if (this.editingMyProfile) {

            if (this.userForm.valid) {
                const birthDate = DateUtils.ngbDateStructToDate(this.birthDate.value);
                birthDate?.setHours(0, 0, 0, 0);

                const userMeInputDto: UserInputDto = new UserInputDto({
                    id: this.id.value,
                    title: this.title.value as Title,
                    firstName: this.firstname.value,
                    lastName: this.lastname.value,
                    email: this.email.value,
                    birthDate,
                    street: this.street.value,
                    postalCode: this.postalCode.value.toString(),
                    city: this.city.value,
                    country: this.country.value,
                    phoneMobile: this.mobilePhone.value,
                    phonePrivate: this.phone.value,
                    phoneProfessional: this.businessPhone.value,
                });

                this.userService.updateMe(userMeInputDto).pipe().subscribe(() => {
                    this.notificationsService.success({title: 'user-creation.notifications.updateMeSuccess'});
                });

            } else {
                window.scroll(0, 0);
            }

        } else {

            if (this.userForm.valid) {
                const birthDate = DateUtils.ngbDateStructToDate(this.birthDate.value);
                birthDate?.setHours(0, 0, 0, 0);

                const userInputDto: UserInputDto = new UserInputDto({
                    id: this.id.value,
                    title: this.title.value as Title,
                    firstName: this.firstname.value,
                    lastName: this.lastname.value,
                    email: this.email.value,
                    birthDate,
                    street: this.street.value,
                    postalCode: this.postalCode.value.toString(),
                    city: this.city.value,
                    country: this.country.value,
                    phoneMobile: this.mobilePhone.value,
                    phonePrivate: this.phone.value,
                    phoneProfessional: this.businessPhone.value,
                    roles: this.roleComponent.getSelectedRoles(),
                    accountEnabled: this.accountEnabled.value,
                    customerNumber: this.customerNumber.value,
                    attentionTo: this.attentionTo.value,
                    rfidCards: this.rfidCards,
                    comment: this.comment.value,
                    internalField1: this.internalField1.value,
                    internalField2: this.internalField2.value,
                    internalField3: this.internalField3.value,
                    internalField4: this.internalField4.value,
                    internalField5: this.internalField5.value
                });

                if (!userInputDto.id) {
                    this.userService.createUser(userInputDto).pipe().subscribe(() => {
                        this.notificationsService.success({title: 'user-creation.notifications.addSuccess'});
                        this.back();
                    });

                } else {
                    if (this.email.dirty && this.firstname.dirty && this.lastname.dirty) {
                       this.confirmService.confirm({
                            titleKey: 'users.form.reallocation-confirm.title',
                            messageKey: 'users.form.reallocation-confirm.message'
                        }).then(result => {
                            if (result === ConfirmModalService.yes) {
                                this.userService.updateUser(userInputDto.id, userInputDto).pipe().subscribe(() => {
                                    this.notificationsService.success({title: 'user-creation.notifications.updateSuccess'});
                                    this.userForm.markAsPristine();
                                });
                            }
                        });
                    } else {
                        this.userService.updateUser(userInputDto.id, userInputDto).pipe().subscribe(() => {
                            this.notificationsService.success({title: 'user-creation.notifications.updateSuccess'});
                            this.userForm.markAsPristine();
                        });
                    }
                }
            } else {
                window.scroll(0, 0);
            }
        }
    }

    addRfidCard(): void {
        const modal = this.modalService.open(UserRfidCardModalComponent, {centered: true});
        modal.componentInstance.userId = this.userId;
        modal.componentInstance.rfidCards = this.rfidCards;
        modal.result
            .then(rfidCards => this.rfidCards = rfidCards, () => { /* catch the rejection */
            });
    }

    unassignRfidCard(rfid: RfidCardDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.unassign'}).then(result => {
            if (result === ConfirmModalService.yes) {
                const index = this.rfidCards.indexOf(rfid);

                if (!this.userId) {
                    if (index > -1) {
                        this.rfidCards.splice(index, 1);
                    }

                } else {
                    this.rfidCardService.unassignRfidCard(rfid).pipe().subscribe(() => {
                        if (index > -1) {
                            this.rfidCards.splice(index, 1);
                        }
                        this.notificationsService.success({message: 'rfidCards.notifications.unassignSuccess'});
                    });
                }
            }
        });
    }

    resetPassword(): void {
        const passwordResetUrl = `https://${environment.b2cDomainName}.b2clogin.com/${environment.b2cDomainName}.onmicrosoft.com/${environment.b2cResetPasswordPolicy}`;
        this.authService.loginRedirect({authority: passwordResetUrl, scopes: []});
    }

    deleteUser(): void {
        const modal = this.modalService.open(UserDeleteConfirmationModalComponent, {centered: true});
        modal.componentInstance.userId = this.userId;
        modal.result
            .then(value => {
                if (value === ConfirmModalService.yes) {
                    this.userService.deleteUser(this.userId).pipe().subscribe(_ => {
                            this.notificationsService.success({title: 'users.notifications.deleteSuccess'});
                            this.router.navigate(['users']);
                        },
                        _ => this.notificationsService.error({title: 'users.notifications.deleteError'}));
                }
            }, () => { /* catch the rejection */
            });
    }

    async setAuditsPage(page: any): Promise<void> {
        this.auditCurrentPage = page.offset;

        await this.fetchAudits();
    }

    getRowClass(row: UserAuditEntryDto): string {
        return row.changeType == UserAuditEntryPropertyChangeType.PropertyChange && row.properties.length > 1 ?
            CssConstants.max150pxRowHeightClass : CssConstants.unlimitedRowHeightClass;
    }

    async fetchAudits() {
        this.pagedAudits = await firstValueFrom(this.userService.getUserAuditEntries(this.userId, new PageableRequestDto({page: this.auditCurrentPage + 1, pageSize: this.auditPageSize})));
    }

    private setupFormToEditMyProfile(userMeInput: UserMeInputDto): void {
        this.id.setValue(userMeInput.id);
        this.title.setValue(userMeInput.title);
        this.firstname.setValue(userMeInput.firstName);
        this.lastname.setValue(userMeInput.lastName);
        this.birthDate.setValue(DateUtils.dateToNgbDateStruct(userMeInput.birthDate));
        this.email.setValue(userMeInput.email);
        this.street.setValue(userMeInput.street);
        this.postalCode.setValue(userMeInput.postalCode);
        this.city.setValue(userMeInput.city);
        this.country.setValue(userMeInput.country);
        this.phone.setValue(userMeInput.phonePrivate);
        this.mobilePhone.setValue(userMeInput.phoneMobile);
        this.businessPhone.setValue(userMeInput.phoneProfessional);

        // Remove unused controls
        this.userForm.removeControl('customerNumber');
        this.userForm.removeControl('rolesForm');
        this.userForm.removeControl('attentionTo');
        this.userForm.removeControl('accountEnabled');
        this.userForm.removeControl('comment');
        this.userForm.removeControl('internalField1');
        this.userForm.removeControl('internalField2');
        this.userForm.removeControl('internalField3');
        this.userForm.removeControl('internalField4');
        this.userForm.removeControl('internalField5');
    }

    private async setupFormToEditFullProfile(userInput: UserInputDto): Promise<void> {
        this.id.setValue(userInput.id);
        this.title.setValue(userInput.title);
        this.accountEnabled.setValue(userInput.accountEnabled);
        this.firstname.setValue(userInput.firstName);
        this.lastname.setValue(userInput.lastName);
        this.birthDate.setValue(DateUtils.dateToNgbDateStruct(userInput.birthDate));
        this.email.setValue(userInput.email);
        this.street.setValue(userInput.street);
        this.postalCode.setValue(userInput.postalCode);
        this.city.setValue(userInput.city);
        this.country.setValue(userInput.country);
        this.phone.setValue(userInput.phonePrivate);
        this.mobilePhone.setValue(userInput.phoneMobile);
        this.businessPhone.setValue(userInput.phoneProfessional);
        this.customerNumber.setValue(userInput.customerNumber);
        this.attentionTo.setValue(userInput.attentionTo);
        this.comment.setValue(userInput.comment);
        this.internalField1.setValue(userInput.internalField1);
        this.internalField2.setValue(userInput.internalField2);
        this.internalField3.setValue(userInput.internalField3);
        this.internalField4.setValue(userInput.internalField4);
        this.internalField5.setValue(userInput.internalField5);

        this.configureRolesControl(userInput);
    }

    private configureRolesControl(userInput: UserInputDto | null): void {
        this.baseRolesFormArray.valueChanges.subscribe(value => {
            const userRoleIdx = this.roles.map(v => v.roleKey).indexOf('User');
            this.roleComponent.enableCustomRoles = value[userRoleIdx];
        });

        this.roles.forEach(role => this.baseRolesFormArray.push(
            new UntypedFormControl(!!userInput?.roles.find(value => value.roleKey === role.roleKey))
        ));

        this.customRoles.forEach(role => this.customRolesFormArray.push(
            new UntypedFormControl(!!userInput?.roles.find(value => value.roleKey === role.roleKey))
        ));
    }

    get id(): UntypedFormControl {
        return this.userForm.get('id') as UntypedFormControl;
    }

    get firstname(): UntypedFormControl {
        return this.userForm.get('firstname') as UntypedFormControl;
    }

    get lastname(): UntypedFormControl {
        return this.userForm.get('lastname') as UntypedFormControl;
    }

    get title(): UntypedFormControl {
        return this.userForm.get('title') as UntypedFormControl;
    }

    get accountEnabled(): UntypedFormControl {
        return this.userForm.get('accountEnabled') as UntypedFormControl;
    }

    get street(): UntypedFormControl {
        return this.userForm.get('street') as UntypedFormControl;
    }

    get city(): UntypedFormControl {
        return this.userForm.get('city') as UntypedFormControl;
    }

    get country(): UntypedFormControl {
        return this.userForm.get('country') as UntypedFormControl;
    }

    get postalCode(): UntypedFormControl {
        return this.userForm.get('postalCode') as UntypedFormControl;
    }

    get birthDate(): UntypedFormControl {
        return this.userForm.get('birthDate') as UntypedFormControl;
    }

    get email(): UntypedFormControl {
        return this.userForm.get('email') as UntypedFormControl;
    }

    get phone(): UntypedFormControl {
        return this.userForm.get('phone') as UntypedFormControl;
    }

    get mobilePhone(): UntypedFormControl {
        return this.userForm.get('mobilePhone') as UntypedFormControl;
    }

    get businessPhone(): UntypedFormControl {
        return this.userForm.get('businessPhone') as UntypedFormControl;
    }

    get customerNumber(): UntypedFormControl {
        return this.userForm.get('customerNumber') as UntypedFormControl;
    }

    get rolesFormArray(): UntypedFormGroup {
        return this.userForm.get('rolesForm') as UntypedFormGroup;
    }

    get baseRolesFormArray(): UntypedFormArray {
        return this.rolesFormArray.get('baseRolesForm') as UntypedFormArray;
    }

    get customRolesFormArray(): UntypedFormArray {
        return this.rolesFormArray.get('customRolesForm') as UntypedFormArray;
    }

    get attentionTo(): UntypedFormControl {
        return this.userForm.get('attentionTo') as UntypedFormControl;
    }

    get comment(): UntypedFormControl {
        return this.userForm.get('comment') as UntypedFormControl;
    }

    get internalField1(): UntypedFormControl {
        return this.userForm.get('internalField1') as UntypedFormControl;
    }

    get internalField2(): UntypedFormControl {
        return this.userForm.get('internalField2') as UntypedFormControl;
    }

    get internalField3(): UntypedFormControl {
        return this.userForm.get('internalField3') as UntypedFormControl;
    }

    get internalField4(): UntypedFormControl {
        return this.userForm.get('internalField4') as UntypedFormControl;
    }

    get internalField5(): UntypedFormControl {
        return this.userForm.get('internalField5') as UntypedFormControl;
    }
}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ContractType, EquipmentContractDto, EquipmentContractService,} from '../../../_services/configuration-services';
import {NotificationsService} from '../../../_shared/notifications.service';
import {
    DocumentManagementComponent,
    DocumentManagementTypeEnum
} from '../../../_shared/_components/document-management-component/document-management.component';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-maintenance-contract-view',
    templateUrl: './maintenance-contract-view.component.html'
})
export class MaintenanceContractViewComponent implements OnInit {

    @Input() contractType: ContractType;
    @Input() equipmentId: string;
    @Input() contractForm: UntypedFormGroup;
    @Input() contractTitle: string;
    @Input() contract: EquipmentContractDto | null;

    @ViewChild('equipmentDocumentListComponent') equipmentDocumentListComponent: DocumentManagementComponent;
    documentManagementTypeEnum: typeof DocumentManagementTypeEnum = DocumentManagementTypeEnum;

    @Output() updateData = new EventEmitter<ContractType>();

    contracts: EquipmentContractDto[];
    contractListForm: UntypedFormControl;

    creating = false;

    constructor(
        private readonly equipmentContractService: EquipmentContractService,
        private readonly notificationsService: NotificationsService,
        private readonly confirmService: ConfirmModalService,) {
        this.contractListForm = new UntypedFormControl(null);
    }

    ngOnInit(): void {
        this.populateContractSelect();
    }

    linkContract(): void {
        let contractId = this.contractsList.value;
        this.equipmentContractService.linkContractToEquipment(contractId, this.equipmentId, this.contractType).pipe().subscribe(
            value => {
                this.contract = value;
                this.updateContractForm();
                this.updateData.emit(this.contractType);
                this.notificationsService.success({title: 'equipments.notifications.contractLinkSuccess'});
            }
        );
    }

    createNewContract(): void {
        this.creating = true;
        this.contract = new EquipmentContractDto();
        this.contractForm.reset();
    }

    addOrUpdateContract(): void {
        this.contract.enterprise = this.contractEnterprise.value;
        this.contract.contactName = this.contractContactName.value;
        this.contract.phone1 = this.contractPhone1.value;
        this.contract.phone2 = this.contractPhone2.value;
        this.contract.email = this.contractEmail.value;

        if (!!this.contract?.id) {
            this.equipmentContractService.updateContract(this.contract.id, this.contract).pipe().subscribe(
                value => {
                    this.contract = value;
                    this.updateContractForm();
                    this.updateData.emit(this.contractType);
                    this.notificationsService.success({title: 'equipments.notifications.contractUpdateSuccess'});
                }
            );
        } else {
            this.equipmentContractService.createContractForEquipment(this.equipmentId, this.contractType, this.contract).pipe().subscribe(
                value => {
                    this.contract = value;
                    this.updateContractForm();
                    this.updateData.emit(this.contractType);
                    this.notificationsService.success({title: 'equipments.notifications.contractAddSuccess'});
                }
            );
        }
        this.creating = false;
    }

    unlinkContract(confirmed = false): void {
        this.equipmentContractService.unlinkContractFormEquipment(this.contract.id, this.equipmentId, this.contractType, confirmed).pipe().subscribe(
            wasConfirmed => {
                if (!wasConfirmed) {
                    // It is a deletion and we need to confirm it !
                    this.confirmService.confirm({
                        titleKey: 'common.confirmModal.title.delete',
                        messageKey: 'common.confirmModal.message.deleteContract'
                    }).then(result => {

                        if (result === ConfirmModalService.yes) { // Relaunch unlink with confirmation
                            this.unlinkContract(true);
                        }
                    });

                } else {
                    this.contract = null;
                    this.notificationsService.success({title: 'equipments.notifications.contractUnlinkSuccess'});
                    this.contractForm.reset();
                    this.contractListForm.setValue(null);
                    this.populateContractSelect();
                    this.updateData.emit(this.contractType);
                }
            }
        );
    }

    public populateContractSelect(): void {
        this.contracts = [];
        this.equipmentContractService.getContracts().pipe().subscribe(contracts => this.contracts = contracts);
    }

    public updateContractForm(contractDto: EquipmentContractDto | null = null): void {
        if (!!contractDto) {
            this.contract = contractDto;
        }
        this.contractEnterprise.setValue(this.contract?.enterprise);
        this.contractContactName.setValue(this.contract?.contactName);
        this.contractPhone1.setValue(this.contract?.phone1);
        this.contractPhone2.setValue(this.contract?.phone2);
        this.contractEmail.setValue(this.contract?.email);

        // refresh documents
        if (this.equipmentDocumentListComponent) {
            this.equipmentDocumentListComponent.fetchData();
        }

        this.contractForm.markAsPristine();
    }

    get maintenance(): UntypedFormGroup {
        return this.contractForm as UntypedFormGroup;
    }

    get contractEnterprise(): UntypedFormControl {
        return this.maintenance.get('enterprise') as UntypedFormControl;
    }

    get contractContactName(): UntypedFormControl {
        return this.maintenance.get('name') as UntypedFormControl;
    }

    get contractPhone1(): UntypedFormControl {
        return this.maintenance.get('phone1') as UntypedFormControl;
    }

    get contractPhone2(): UntypedFormControl {
        return this.maintenance.get('phone2') as UntypedFormControl;
    }

    get contractEmail(): UntypedFormControl {
        return this.maintenance.get('email') as UntypedFormControl;
    }

    get contractsList(): UntypedFormControl {
        return this.contractListForm as UntypedFormControl;
    }

    fileListChangeEvent() {
        this.updateData.emit(this.contractType);
    }
}

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    LightDto,
    MapDto,
    MapService,
    PublicLightingLightService
} from '../../../_services/configuration-services';
import {LightViewModalComponent} from '../../../_shared/_components/light-view-modal/light-view-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ManageMapContentComponent, MapContent} from '../manage-map-content/manage-map-content.component';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-public-lighting-management',
  templateUrl: './public-lighting-management.component.html'
})
export class PublicLightingManagementComponent implements OnInit {

    @Input() serviceId: string;

    @ViewChild(ManageMapContentComponent) manageMapContent: ManageMapContentComponent = null!;

    lights: LightDto[];
    lightsMap: MapContent[] = [];

    map: MapDto | null = null;

    constructor(private readonly modalService: NgbModal,
                private readonly mapService: MapService,
                private readonly lightService: PublicLightingLightService) {
    }

    async ngOnInit(): Promise<void> {
        this.map = await firstValueFrom(this.mapService.getMap(this.serviceId));
         if (this.map) {
             await this.loadLights();
         }
    }

    openLightModal(lightMapContent: MapContent): void {
        const modal = this.modalService.open(LightViewModalComponent, {centered: true});
        modal.componentInstance.serviceId = this.serviceId;
        modal.componentInstance.mapContent = lightMapContent;

        if (lightMapContent.id) {
            modal.componentInstance.lightDto = this.lights.find(lights => lights.id === lightMapContent.id);
        }

        modal.result.then(async res => {
            if (res === 'success') {
                await this.loadLights();
            }
            await this.manageMapContent.resetMapAndStopEditMode(this.lightsMap);
        });
    }

    async loadLights(): Promise<void> {
        this.lights = await firstValueFrom(this.lightService.getLightsByServiceId(this.serviceId));
        this.lightsMap = [];

        for (const light of this.lights) {
            this.lightsMap.push({
                ...light,
                displayName: light.code
            } as MapContent)
        }
    }

}

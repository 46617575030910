import {Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Calendar, CalendarOptions, EventInput, ToolbarInput} from '@fullcalendar/core';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {environment} from '../../../../environments/environment';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocal from '@fullcalendar/core/locales/fr';
import deLocale from '@fullcalendar/core/locales/de';
import {CalendarUtils} from '../../../_shared/calendar-utils';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {Subscription} from 'rxjs';
import {EquipmentEventLightDto, EquipmentEventService,} from '../../../_services/configuration-services';
import {NotificationsService} from '../../../_shared/notifications.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormUtils} from '../../../_shared/form-utils';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import itLocale from '@fullcalendar/core/locales/it';

@Component({
    selector: 'app-maintenance-calendar',
    templateUrl: './maintenance-calendar.component.html'
})
export class MaintenanceCalendarComponent implements OnInit, OnDestroy {

    calendarOptions: CalendarOptions;

    @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;
    @ViewChild('popoverTmpl', {static: true}) popoverTmpl: TemplateRef<any>;

    @Output() openEventModal = new EventEmitter<{startDate: Date, eventId: string}>();

    currentView = 'dayGridMonth';
    calendarToolbarRightBtn = 'dayGridMonth,timeGridWeek,timeGridDay,listWeek';
    events = new Array<EventInput>();

    // Search Equipment Events --------
    searchEquipmentEventForm: UntypedFormGroup;
    searchTerms: string | null = null;
    private beforeSearchCalendarView;
    private beforeSearchCalendarDate;
    private inSearchMode = false;
    private searchEquipmentEventTermsSubscription: Subscription | null;
    textInputPattern = FormUtils.textInputPattern;

    constructor(private readonly equipmentEventService: EquipmentEventService,
                private readonly translateService: TranslateService,
                private readonly datePipe: DatePipe,
                private readonly modalService: NgbModal,
                private readonly calendarUtils: CalendarUtils,
                private readonly notificationService: NotificationsService) {
        this.searchEquipmentEventForm = new UntypedFormGroup({
            searchTerms: new UntypedFormControl(null),
        }, {updateOn: 'change'});
    }


    // Languages ----------
    crtLang = TranslateUtils.defaultLanguage;
    private langChangeSubscription: Subscription;

    // --------------------

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.crtLang = event.lang;
            this.buildCalendar();
        });

        forwardRef(() => Calendar);
        this.buildCalendar();

        this.searchEquipmentEventTermsSubscription = this.equipmentEventSearchTerms.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe((searchTerms: string) => this.searchEvents(searchTerms));
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.searchEquipmentEventTermsSubscription.unsubscribe();
    }

    public refreshCalendar(): void {
        this.buildCalendar();
    }

    private buildCalendar(): void {
        let initialView = 'dayGridMonth';
        let maxStartDate = null;

        this.calendarOptions = {
            schedulerLicenseKey: environment.fullCalendarLicenceKey,
            plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
            editable: true,
            locales: [frLocal, deLocale, itLocale],
            locale: this.crtLang,
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: this.calendarToolbarRightBtn
            },
            resourceOrder: 'title',
            initialView,
            nowIndicator: true,
            events: this.fetchEvents.bind(this),
            eventTimeFormat: {
                hour: '2-digit',
                minute: '2-digit',
            },
            dayHeaderFormat: {
                weekday: 'short'
            },
            selectMirror: true,
            dayMaxEvents: 10,
            allDaySlot: false,
            validRange: {
                end: maxStartDate
            },
            dateClick: this.handleDateClick.bind(this),
            eventClick: this.handleEventClick.bind(this),
            viewDidMount: this.viewDidMount.bind(this),
            eventDidMount: this.renderTooltip.bind(this)
        };
    }

    handleDateClick(arg): void {
        this.openEventModal.emit({startDate: arg.date, eventId: null});
    }

    handleEventClick(arg): void {
        const event = arg.event;
        const eventProp = event.extendedProps.equipmentEvent;
        this.openEventModal.emit({startDate: event.date, eventId: eventProp.id});
    }

    viewDidMount(): void {
        this.getEquipmentEvents();
    }

    renderTooltip(event): void {
        this.currentView = event.view.type;

        if (CalendarUtils.shouldDisplayEventPopover(this.fullcalendar)) {
            this.calendarUtils.getRenderTooltipHandler(this.popoverTmpl)(event);
        }
    }

    private getEquipmentEvents(): void {
        const startDate = CalendarUtils.getStartDate(this.fullcalendar) ?? new Date();
        const endDate = CalendarUtils.getEndDate(this.fullcalendar) ?? new Date();
        this.equipmentEventService.getEquipmentEventsForPeriod(startDate, endDate).pipe().subscribe(result => this.buildEvents(result));
    }

    private fetchEvents(args: {
                            start: Date;
                            end: Date;
                        },
                        successCallback: (events: EventInput[]) => void,
                        failureCallback: (error) => void): void {
        if (this.searchTerms != null) {
            this.equipmentEventService.searchEquipmentEvents(this.searchTerms).pipe().subscribe(result => {
                this.buildEvents(result);
                successCallback(this.events);
            }, error => failureCallback(error));
        } else {
            this.equipmentEventService.getEquipmentEventsForPeriod(args.start, args.end).pipe().subscribe(result => {
                this.buildEvents(result);
                successCallback(this.events);
            }, error => failureCallback(error));
        }
    }

    private buildEvents(equipmentEvents: EquipmentEventLightDto[]): void {
        this.events = [];
        equipmentEvents.forEach(ee => {
            const startDate = new DatePipe('en-US').transform(ee.startDateTime, 'yyyy-MM-ddTHH:mm');
            const endDate = new DatePipe('en-US').transform(ee.endDateTime, 'yyyy-MM-ddTHH:mm');
            let title = `${ee.equipmentName} - ${ee.description}`;
            this.events.push({
                editable: false,
                display: null,
                title: title,
                start: startDate,
                end: endDate,
                id: ee.id,
                equipmentEvent: ee,
                durationEditable: false,
            });
        });
    }

    get equipmentEventSearchTerms(): UntypedFormControl {
        return this.searchEquipmentEventForm.get('searchTerms') as UntypedFormControl;
    }


    private searchEvents(searchTerms: string) {
        const trimmedSearchTerms = searchTerms.trim();
        const charactersNumberOfSearchTerms = trimmedSearchTerms.length;
        switch (charactersNumberOfSearchTerms) {
            case 0:
                if (this.inSearchMode) {
                    this.inSearchMode = false;
                    CalendarUtils.setCalendarView(this.fullcalendar, this.beforeSearchCalendarView);
                    CalendarUtils.goToDate(this.fullcalendar, this.beforeSearchCalendarDate);
                    const toolBar = this.calendarOptions.headerToolbar as ToolbarInput;
                    toolBar.right = this.calendarToolbarRightBtn;

                    this.calendarOptions.validRange = null;
                    this.searchTerms = null;
                    this.fullcalendar.getApi().refetchEvents();
                }
                break;
            case 1:
                break;
            default:
                if (!this.inSearchMode) {
                    const today = new Date();
                    this.inSearchMode = true;
                    this.beforeSearchCalendarView = this.fullcalendar.getApi().view.type;
                    this.beforeSearchCalendarDate = CalendarUtils.getDate(this.fullcalendar);
                    CalendarUtils.setCalendarView(this.fullcalendar, 'listYear');
                    CalendarUtils.goToDate(this.fullcalendar, today);

                    const toolBar = this.calendarOptions.headerToolbar as ToolbarInput;
                    toolBar.right = null;

                    this.calendarOptions.validRange = {
                        start: new Date(today.getFullYear() - 2, today.getMonth(), today.getDate()),
                        end: new Date(today.getFullYear() + 2, today.getMonth(), today.getDate())
                    };
                }
                this.searchTerms = trimmedSearchTerms;
                this.fullcalendar.getApi().refetchEvents();
                break;
        }
    }
}

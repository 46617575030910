import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CustomerConfigService} from './customer-config-service';
import {AppConstants} from '../app.constants';

@Injectable({
    providedIn: 'root'
})
export class TranslateUtils {

    public static readonly defaultLanguage = 'fr';

    // All "Possible" languages but the available is a sub list depending the backend configuration
    public static readonly possibleLanguages = ['fr', 'de', 'en', 'it'];

    constructor(private readonly translateService: TranslateService,
                private readonly customerConfig: CustomerConfigService) {
        this.loadTranslations();
    }

    private validateOrDefault(lang: string): string {
        if (CustomerConfigService.isConfigLoaded() && !this.customerConfig.isLanguageAvailable(lang)) {
            return TranslateUtils.defaultLanguage;
        }
        return lang;
    }

    public getLanguage(): string {
        let language = localStorage.getItem(AppConstants.languageKey);
        if (!language) {
            language = navigator.language.substr(0, 2);
        }

        if (TranslateUtils.possibleLanguages.indexOf(language) >= 0) {
            return language;
        } else {
            return TranslateUtils.defaultLanguage;
        }
    }

    /**
     * Change the current language of the app
     */
    public setLanguage(lang: string): void {
        const newLang = this.validateOrDefault(lang);
        this.translateService.use(newLang);
        localStorage.setItem(AppConstants.languageKey, newLang);
        document.getElementsByTagName('html')[0].lang=lang;
    }

    /**
     * Call this method to load translations inside your app
     */
    public loadTranslations(): void {
        this.setLanguage(this.getLanguage());
    }
}

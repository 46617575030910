import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../../_shared/notifications.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    ActivationInputDto,
    ActivationService,
    ImpulseDurationUnit,
    MeasuringPointLightDto,
    MeasuringPointService,
    MeasuringPointUsageState,
    PaymentTypes, SearchUserDto,
    TariffDto, TariffService,
} from '../../../_services/configuration-services';
import {InternalDatatransService} from '../../../_services/internal-datatrans.service';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {RolesService} from '../../../_shared/roles-service';
import {BaseAuthRequest} from '@azure/msal-common';
import {MsalService} from '@azure/msal-angular';
import {UserDataFormComponent} from '../../../_shared/_components/user-data-form/user-data-form.component';
import {PaymentTypeChooserComponent} from '../../../_shared/_components/payment-type-chooser/payment-type-chooser.component';
import {FormUtils} from '../../../_shared/form-utils';

@Component({
    selector: 'app-activate-service-modal',
    templateUrl: './activate-service-modal.component.html'
})
export class ActivateServiceModalComponent implements OnInit {

    @Input() measuringPointId!: string;
    @Input() serviceId!: string;
    @Input() defaultTariff!: TariffDto;
    @Input() hasVisitorAccess = false;
    @Input() activationDuration = 0;
    @Input() activationUnit!: ImpulseDurationUnit;

    @ViewChild('userDataFormComponent') userDataFormComponent: UserDataFormComponent | null;
    @ViewChild('paymentTypeChooserComponent') paymentTypeChooserComponent: PaymentTypeChooserComponent | null;

    FormUtils = FormUtils;
    MeasuringPointUsageState = MeasuringPointUsageState;

    activateForm: UntypedFormGroup;

    measuringPoint!: MeasuringPointLightDto;
    availablePaymentTypes: PaymentTypes[] = [];

    loggedIn = false;
    userId: string | null = null;
    displayedTariff: TariffDto | null = null;

    crtLang = TranslateUtils.defaultLanguage;

    constructor(public activeModal: NgbActiveModal,
                private readonly msalService: MsalService,
                private readonly rolesService: RolesService,
                private readonly notificationsService: NotificationsService,
                private readonly internalDatatransService: InternalDatatransService,
                private readonly activationService: ActivationService,
                private readonly measuringPointService: MeasuringPointService,
                private readonly tariffService: TariffService) {

        this.activateForm = new UntypedFormGroup({
            visitorForm: new UntypedFormGroup({}),
            paymentType: new UntypedFormControl('', Validators.required)
        }, {updateOn: 'change'});
    }

    ngOnInit(): void {
        this.checkIfLoggedIn();

        if (!!this.userId || this.hasVisitorAccess) {
            this.fetchMeasuringPoint();
        }

        if (this.isAdmin()) {
            this.activateForm.addControl('user', new UntypedFormControl(null, Validators.required));
            this.activateForm.addControl('impulseDuration', new UntypedFormControl(this.activationDuration, [Validators.required, Validators.min(0)]));
        }

        this.displayedTariff = this.defaultTariff;
        this.configurePaymentTypes();
    }

    activateService(): void {
        if (this.activateForm.invalid) {
            return;
        }

        const activation = new ActivationInputDto({
            serviceId: this.serviceId,
            measuringPointId: this.measuringPointId,
            paymentType: this.paymentType.value,
            userId: this.user?.value ?? this.userId,
            visitorContact: this.userDataFormComponent?.visitorContact
        });

        if (this.paymentType.value === PaymentTypes.Ebanking) {
            const amount = this.displayedTariff.tariffPrices[0].priceByUnit;
            this.internalDatatransService.payActivation(activation, (amount * 100));

        } else {

            if (this.user?.value) {
                activation.overridenImpulseDuration = this.impulseDuration.value;
                this.activationService.activateServiceForAnother(activation).pipe().subscribe(() => {
                    this.notificationsService.success({
                        title: 'services.activation.notification.success',
                    });

                    this.activeModal.close();
                });

            } else if (this.loggedIn) {
                this.activationService.activateService(activation).pipe().subscribe(() => {
                    this.notificationsService.success({
                        title: 'services.activation.notification.success',
                    });

                    this.activeModal.close();
                });

            } else {
                this.activationService.activateServiceForVisitor(activation).pipe().subscribe(() => {
                    this.notificationsService.success({
                        title: 'services.activation.notification.success',
                    });

                    this.activeModal.close();
                });
            }
        }
    }

    login(userFlowRequest?: BaseAuthRequest): void {
        this.msalService.loginRedirect(userFlowRequest);
    }

    selectPaymentType(paymentType: PaymentTypes): void {
        this.paymentType.setValue(paymentType);
    }

    userSelected(searchUser: SearchUserDto): void {
        this.user.setValue(searchUser?.id);
        this.paymentType.reset();
        this.paymentTypeChooserComponent?.reset();

        if (searchUser) {
            this.tariffService.getTariffForServiceAndUser(this.serviceId, searchUser.id).pipe().subscribe(tariff => {
                this.displayedTariff = tariff;
                this.configurePaymentTypes();
            });

        } else {
            this.displayedTariff = this.defaultTariff;
            this.configurePaymentTypes();
        }
    }

    isAdmin(): boolean {
        return this.rolesService.hasRoleAdmin();
    }

    private configurePaymentTypes(): void {
        this.availablePaymentTypes = this.displayedTariff.paymentTypes;

        if (this.user?.value) {
            // Remove E-Banking when activating for another for visitor
            this.availablePaymentTypes = this.availablePaymentTypes.filter(p => p !== PaymentTypes.Ebanking);

        } else if (!this.userId) {
            // Remove E-Wallet for visitor
            this.availablePaymentTypes = this.availablePaymentTypes.filter(p => p !== PaymentTypes.Wallet);
        }

        if (this.availablePaymentTypes.length === 0) {
            this.notificationsService.warning({
                title: 'epayment.notifications.warningActivateTitle',
                message: 'epayment.notifications.noPaymentType'
            });

        } else if (this.availablePaymentTypes.length === 1) {
            this.paymentType.setValue(this.availablePaymentTypes[0]);
        }
    }

    private fetchMeasuringPoint(): void {
        this.measuringPointService.getMeasuringPointWithStateById(this.measuringPointId, this.serviceId).pipe().subscribe({
            next: mp => this.measuringPoint = mp,
            error: () => this.activeModal.close('MeasuringPointNotFound')
        });
    }

    private checkIfLoggedIn(): boolean {
        if (this.msalService.instance.getAllAccounts().length > 0) {
            const account = this.msalService.instance.getAllAccounts()[0];
            this.msalService.instance.setActiveAccount(account);
            this.userId = this.rolesService.getUserId();
            this.loggedIn = true;

            this.activateForm.removeControl('visitorForm');

        } else {
            this.loggedIn = false;
        }

        return this.loggedIn;
    }

    get paymentType(): UntypedFormControl {
        return this.activateForm.get('paymentType') as UntypedFormControl;
    }

    get visitorForm(): UntypedFormGroup | null {
        return this.activateForm.get('visitorForm') as UntypedFormGroup;
    }

    get user(): UntypedFormControl | null {
        return this.activateForm.get('user') as UntypedFormControl;
    }

    get impulseDuration(): UntypedFormControl | null {
        return this.activateForm.get('impulseDuration') as UntypedFormControl;
    }
}

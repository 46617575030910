
<div class="nav-menu dark" [appHasAnyRole]="[AppRoles.cashier]">

    <ul class="navbar-nav">
        <li class="nav-item">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['list']"
                   #list="routerLinkActive"
                   [ngClass]='{"selected": list.isActive}' routerLinkActive>
                    <span translate>menu.navigation.wallets</span>
                </a>
            </div>
        </li>

        <li class="nav-item">
            <div class="menu">
                <a class="nav-link" href="#"
                   [routerLink]="['closure-schedule']"
                   #closureSchedule="routerLinkActive"
                   [ngClass]='{"selected": closureSchedule.isActive}' routerLinkActive>
                    <span translate>menu.navigation.walletClosureSchedules</span>
                </a>
            </div>
        </li>

    </ul>
</div>

<router-outlet></router-outlet>

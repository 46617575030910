import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {RoleManagementComponent} from '../../../_shared/_components/role-management/role-management.component';
import {DocumentManagementTypeEnum} from '../../../_shared/_components/document-management-component/document-management.component';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    InformationDto,
    InformationInputDto, InformationPublishInputDto,
    InformationService, InformationState,
    RoleDto,
    RoleService
} from '../../../_services/configuration-services';
import {AppConstants, AppRoles} from '../../../app.constants';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {NotificationsService} from '../../../_shared/notifications.service';
import {CustomerConfigService} from '../../../_shared/customer-config-service';
import {FormUtils} from '../../../_shared/form-utils';
import {DateUtils} from '../../../_shared/date-utils';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateFormatter} from "../../../_shared/custom-date-formatter";

@Component({
    selector: 'app-information-edition',
    templateUrl: './manage-information.component.html',
    providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class ManageInformationComponent implements OnInit {

    @ViewChild(RoleManagementComponent) roleComponent: RoleManagementComponent;
    @ViewChild('publicationSection', {static: false}) publicationSection: ElementRef;

    informationId: string | null = null;

    DocumentManagementTypeEnum = DocumentManagementTypeEnum;
    InformationState = InformationState;

    informationForm: UntypedFormGroup;
    illustrationForm: UntypedFormGroup;
    publicationForm: UntypedFormGroup;

    public customRoles: RoleDto[] = [];
    public basicRoles: RoleDto[] = [];

    informationState: InformationState | null = null;
    usersAlreadyNotified = false;

    supportedImage = AppConstants.supportedImageType;
    imageMaxSizeInKB = AppConstants.communicationChannelImageMaxSizeInKB;

    constructor(private readonly confirmService: ConfirmModalService,
                private readonly roleService: RoleService,
                private readonly location: Location,
                private readonly route: ActivatedRoute,
                private readonly router: Router,
                private readonly notificationsService: NotificationsService,
                private readonly informationService: InformationService,
                public readonly customerConfigService: CustomerConfigService) {

        this.informationForm = new UntypedFormGroup({
            titleFR: new UntypedFormControl(null, [Validators.required, Validators.maxLength(50)]),
            titleDE: new UntypedFormControl(null, Validators.maxLength(50)),
            titleIT: new UntypedFormControl(null, Validators.maxLength(50)),
            titleEN: new UntypedFormControl(null, Validators.maxLength(50)),

            contentFR: new UntypedFormControl(null, [Validators.required, Validators.maxLength(2500)]),
            contentDE: new UntypedFormControl(null, Validators.maxLength(2500)),
            contentIT: new UntypedFormControl(null, Validators.maxLength(2500)),
            contentEN: new UntypedFormControl(null, Validators.maxLength(2500)),

            mailNotificationEnabled: new UntypedFormControl(true),

            rolesForm: new UntypedFormGroup({
                baseRolesForm: new UntypedFormArray([]),
                customRolesForm: new UntypedFormArray([], {updateOn: 'change'})
            }),
        });

        this.illustrationForm = new UntypedFormGroup({
            file: new UntypedFormControl(null),
            fileData: new UntypedFormControl(null),
            illustration: new UntypedFormControl(null)
        });

        this.publicationForm = new UntypedFormGroup({
            period: new UntypedFormGroup({
                from: new UntypedFormControl(null, [FormUtils.datePatternValidator, Validators.required]),
                to: new UntypedFormControl(null, [FormUtils.datePatternValidator]),
            }, [FormUtils.periodValidator]),
        });
    }

    ngOnInit(): void {
        this.informationId = this.route.snapshot.params['id'] ?? null;

        this.roleService.getAllRoles(false).pipe().subscribe(roles => {
            this.customRoles = roles.filter(r => !r.isBase);
            this.basicRoles = roles.filter(r => r.roleKey === AppRoles.user);
            if (this.informationId) {
                this.informationService.getInformation(this.informationId).pipe()
                    .subscribe(information => this.fillForm(information));

            } else {
                this.initializeRolesForm();
            }
        });
    }

    saveInformation(): void {
        const information = new InformationInputDto({
            titleFR: this.titleFR.value,
            titleDE: this.titleDE.value,
            titleIT: this.titleIT.value,
            titleEN: this.titleEN.value,

            contentFR: this.contentFR.value,
            contentDE: this.contentDE.value,
            contentIT: this.contentIT.value,
            contentEN: this.contentEN.value,

            roles: this.roleComponent.getSelectedRoles()
        });

        if (this.informationId === null) {
            this.informationService.createInformation(information).pipe().subscribe(infoId => {
                this.notificationsService.success({title: 'communicationChannel.information.notification.addSuccess'});
                this.router.navigate(['../' + infoId], {relativeTo: this.route, replaceUrl: true});
            });

        } else {
            this.informationService.updateInformation(this.informationId, information).pipe().subscribe(() => {
                this.notificationsService.success({title: 'communicationChannel.information.notification.updateSuccess'});
                this.informationForm.markAsPristine();
            });
        }
    }

    publishInformation(): void {
        const from = DateUtils.ngbDateStructToDate(this.startDate.value);
        from?.setHours(0, 0, 0, 0);
        const to = DateUtils.ngbDateStructToDate(this.endDate.value);
        to?.setHours(23, 59, 59, 59);

        const publishInfo = new InformationPublishInputDto({
            publicationDate: from,
            endPublicationDate: to,
            mailNotificationEnabled: this.mailNotificationEnabled.value
        });

        this.informationService.publishInformation(this.informationId, publishInfo).pipe().subscribe(information => {
            this.notificationsService.success({title: 'communicationChannel.information.notification.updateSuccess'});
            this.fillForm(information);
            this.publicationForm.markAsPristine();
        });
    }

    deleteInformation(): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.informationService.deleteInformation(this.informationId).pipe().subscribe(() => {
                    this.notificationsService.success({title: 'communicationChannel.information.notification.deleteSuccess'});
                    this.back();
                });
            }
        });
    }

    saveIllustration(): void {
        if (this.fileData.value) {
            const file = {
                data: this.fileData.value,
                fileName: this.fileData.value.name
            };
            this.illustrationForm.reset();
            this.informationService.uploadIllustration(this.informationId, file).pipe().subscribe(res => {
                this.notificationsService.success({title: 'communicationChannel.information.notification.imageAddSuccess'});
                this.illustration.setValue(res.illustrationUrl);
            });
        }
    }

    fileSelected(files: FileList): void {
        if (files.length > 0) {
            const fileData = files[0];
            const size = fileData.size;
            if ((size / 1024) > this.imageMaxSizeInKB) {
                this.file.setErrors({overSizeLimitKb: {max: this.imageMaxSizeInKB}});
            } else {
                this.file.setErrors(null);
                this.fileData.patchValue(fileData);
            }
        }
    }

    back(): void {
        this.location.back();
    }

    private initializeRolesForm(): void {
        this.customRoles.forEach(role => this.customRolesFormArray.push(
            new UntypedFormControl(false)
        ));

        this.basicRoles.forEach(role => this.baseRolesFormArray.push(
            new UntypedFormControl(false)
        ));
    }

    private fillForm(info: InformationDto): void {
        this.informationState = info.informationState;
        this.usersAlreadyNotified = info.publicationNotificationSent;

        this.titleFR.setValue(info.titleFR);
        this.titleDE.setValue(info.titleDE);
        this.titleIT.setValue(info.titleIT);
        this.titleEN.setValue(info.titleEN);

        this.contentFR.setValue(info.contentFR);
        this.contentDE.setValue(info.contentDE);
        this.contentIT.setValue(info.contentIT);
        this.contentEN.setValue(info.contentEN);

        this.illustration.setValue(info.illustrationUrl);

        this.startDate.setValue(info.publicationDate === null ? null : DateUtils.dateToNgbDateStruct(info.publicationDate));
        this.endDate.setValue(info.endPublicationDate === null ? null : DateUtils.dateToNgbDateStruct(info.endPublicationDate));
        this.mailNotificationEnabled.setValue(info.mailNotificationEnabled);

        this.customRolesFormArray.clear();
        this.customRoles.forEach(role => this.customRolesFormArray.push(
            new UntypedFormControl(info.roles ? info.roles.find(value => value.roleKey === role.roleKey) != null : false)
        ));
        this.baseRolesFormArray.clear();
        this.basicRoles.forEach(role => this.baseRolesFormArray.push(
            new UntypedFormControl(info.roles ? info.roles.find(value => value.roleKey === role.roleKey) != null : false)
        ));

        // Scroll down the page to display publication form
        if (info.publicationDate === null) {
            setTimeout(() => this.publicationSection.nativeElement.scrollIntoView(), 0);
        }
    }

    get titleFR(): UntypedFormControl {
        return this.informationForm.get('titleFR') as UntypedFormControl;
    }

    get titleDE(): UntypedFormControl {
        return this.informationForm.get('titleDE') as UntypedFormControl;
    }

    get titleIT(): UntypedFormControl {
        return this.informationForm.get('titleIT') as UntypedFormControl;
    }

    get titleEN(): UntypedFormControl {
        return this.informationForm.get('titleEN') as UntypedFormControl;
    }

    get contentFR(): UntypedFormControl {
        return this.informationForm.get('contentFR') as UntypedFormControl;
    }

    get contentDE(): UntypedFormControl {
        return this.informationForm.get('contentDE') as UntypedFormControl;
    }

    get contentIT(): UntypedFormControl {
        return this.informationForm.get('contentIT') as UntypedFormControl;
    }

    get contentEN(): UntypedFormControl {
        return this.informationForm.get('contentEN') as UntypedFormControl;
    }

    get mailNotificationEnabled(): UntypedFormControl {
        return this.informationForm.get('mailNotificationEnabled') as UntypedFormControl;
    }

    get rolesFormArray(): UntypedFormGroup {
        return this.informationForm.get('rolesForm') as UntypedFormGroup;
    }

    get baseRolesFormArray(): UntypedFormArray {
        return this.rolesFormArray.get('baseRolesForm') as UntypedFormArray;
    }

    get customRolesFormArray(): UntypedFormArray {
        return this.rolesFormArray.get('customRolesForm') as UntypedFormArray;
    }

    get file(): UntypedFormControl {
        return this.illustrationForm.get('file') as UntypedFormControl;
    }

    get fileData(): UntypedFormControl {
        return this.illustrationForm.get('fileData') as UntypedFormControl;
    }

    get illustration(): UntypedFormControl {
        return this.illustrationForm.get('illustration') as UntypedFormControl;
    }

    get periodForm(): UntypedFormGroup {
        return this.publicationForm.get('period') as UntypedFormGroup;
    }

    get startDate(): UntypedFormControl {
        return this.periodForm.get('from') as UntypedFormControl;
    }

    get endDate(): UntypedFormControl {
        return this.periodForm.get('to') as UntypedFormControl;
    }

}

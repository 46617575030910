<div>
    <div class="modal-header">
        <h4 class="modal-title" translate>common.document.modal.title</h4>

        <button class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">

        </button>
    </div>

    <div class="modal-body">

        <form name="equipmentDocumentForm" [formGroup]="equipmentDocumentForm">
            <div class="form-row">
                <div class="form-group col-12">
                    <div class="col-12">
                        <label class="col-form-label"
                               for="title">{{'common.document.modal.form.title' | translate}}*</label>
                    </div>
                    <div class="col-12">
                        <input class="form-control" id="title" type="text" [formControl]="title">
                    </div>
                    <div class="col-12">
                        <app-form-error [control]="title"></app-form-error>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-12">
                    <div class="col-12">
                        <label class="col-form-label"
                               for="title">{{'common.document.modal.form.file' | translate}}*</label>
                    </div>
                    <div class="col-12">
                        <input class="form-control" id="file" type="file"
                               (change)="fileSelected($event.target.files)"
                               [formControl]="file">
                    </div>
                    <div class="col-12">
                        <app-form-error [control]="file"></app-form-error>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="modal-footer">

        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()" translate>
            common.cancel
        </button>
        <button type="submit" class="btn btn-primary"
                [disabled]="equipmentDocumentForm.invalid"
                (click)="uploadDocument()" translate>
            common.add
        </button>
    </div>
</div>


<div class="content-container bg-white">

    <div *ngIf="crtLang === 'fr'">

        <h1>Plateforme Gestinergie 4.0</h1>
        <h2>Conditions générales de vente</h2>

        <small>Version : 1.00</small>

        <h3>1. Introduction</h3>

        <p>
            Les présentes Conditions générales de vente (ci-après dénommées « CGV ») régissent les prestations
            effectuées via la plateforme informatique Gestinergie 4.0 (ci-après également la « Plateforme » ou le « site
            »). Il est précisé que, malgré le terme « vente » utilisé dans ce document, les prestations proposées à
            travers la Plateforme ne relèvent pas du contrat de vente au sens du code des obligations
        </p>
        <p>
            Les prestations proposées via la Plateforme sont réalisées par le(s) Prestataire(s) désigné(s) ci-après :
        </p>

        <p><strong>{{cgu.mandant}}</strong> <br/>
            {{cgu.address.street}} {{cgu.address.number}} <br/>
            {{cgu.address.extra}} <br *ngIf="cgu.address.extra"/>
            {{cgu.address.zip}} {{cgu.address.locality}} <br/>
            {{cgu.phone}}<br *ngIf="cgu.phone"/>
            <a href="mailto:{{cgu.email}}">{{cgu.email}} </a>
        </p>

        <p>
            Les présentes conditions devront être acceptées lors de l’enregistrement sur la Plateforme par les
            Utilisateurs enregistrés ou avant chaque utilisation par les Utilisateurs non enregistrés.
        </p>

        <p>
            La Plateforme peut faire en tout temps l’objet de mise à jour de nature informative ou technologique. Le cas
            échéant, pour ces raisons ou pour tout autre raison pertinente, le Prestataire se réserve le droit de
            modifier les présentes cgu.
        </p>

        <h3>2. Domaine d’application</h3>

        <p>
            Les présentes CGV font partie intégrante des conditions contractuelles applicables aux prestations de
            service
            proposées par la Plateforme et s’appliquent à la relation contractuelle entre le Prestataire et
            l’Utilisateur (ci-après « les parties »). Elles sont applicables à toutes les opérations réalisées sur la
            Plateforme, sous réserve d’éventuelles dispositions particulières.
        </p>

        <p>
            Dès la première opération effectuée sur la Plateforme, l’Utilisateur accepte sans réserve de se conformer
            aux présentes cgu.
        </p>

        <h3>3. Prestations proposées</h3>

        <p>
            Le Prestataire met à disposition de l’Utilisateur des services disponibles sur la plateforme Gestinergie
            4.0, contre rétribution. Le Prestataire définit librement le prix de la prestation ainsi que les moyens de
            paiement acceptés en fonction des services.
        </p>

        <p>
            Le Prestataire peut à tout moment modifier les prestations proposées. Dans la mesure du possible, il en
            informe les Utilisateurs au préalable.
        </p>

        <h3>4. Conditions de paiement</h3>

        <h4>A. Modalités de paiement</h4>

        <p>
            Le montant de la prestation est indiqué sur le descriptif général du service accessible sur la Plateforme.
        </p>

        <p>
            Les modalités de paiement disponibles sur la Plateforme sont définies pour chaque service. Lors de
            l’utilisation d’un service payant, la Plateforme indique à l’Utilisateur le processus de paiement au plus
            tard au moment de la commande pour ce service.
        </p>

        <h4>B. Moyens de paiement</h4>

        <p>
            Les moyens de paiement disponibles sur la Plateforme sont définis en fonction des services. Les moyens
            actuellement disponibles pour les paiements sont les suivants :
        </p>

        <ul>
            <li>Débit sur le portemonnaie virtuel</li>
            <li>Sur facture</li>
            <li>Via E-paiement</li>
        </ul>

        <h3>5. Remboursement</h3>

        <p>Tout remboursement est à demander directement auprès du Prestataire.</p>

        <h3>6. Conditions d’accès</h3>

        <p>Les conditions d’accès à la Plateforme sont définies dans les <a href="/#/cgu">conditions générales
            d’utilisation.</a></p>

        <p>Les conditions d’accès au paiement des services sont disponibles comme suit :</p>
        <ul>
            <li>
                L’Utilisateur est enregistré sur la Plateforme et consomme les services selon « 4.B Moyens de paiement ».
            </li>
            <li>L’Utilisateur n’est pas enregistré sur la Plateforme (visiteur) et consomme les services via E-paiement
                ou sur facture conformément aux choix disponibles pour le service concerné.
            </li>
        </ul>

        <h3>7. Protection des données</h3>

        <p>
            Les données collectées par le Prestataire (responsable du traitement) sont traitées uniquement pour les
            finalités de fourniture des prestations et de gestion efficace de la Plateforme. A cet effet, le Prestataire
            est soumis, selon son statut (entité publique ou assimilée ou entité privée), à la loi cantonale ou à la loi
            fédérale sur la protection des données.
        </p>

        <p>
            Les Utilisateurs peuvent faire valoir les droits qui leur sont reconnus par la législation applicable
            directement auprès du Prestataire et aux coordonnées indiquées au chapitre introductif ci-dessus.
        </p>

        <h3>8. Droit applicable et for</h3>

        <p>
            Le droit suisse s’applique à toutes les relations juridiques entre le Prestataire et l’Utilisateur et en
            rapport avec l’utilisation de la Plateforme.
        </p>

        <p>
            Le for en cas de litige est le siège du Prestataire ou au lieu de la fourniture de la prestation.
        </p>

        <p>
            Les présentes CGV sont valables dès leur publication (la version en ligne sur la Plateforme fait foi) et
            s’appliquent dès la première utilisation de la Plateforme par l’Utilisateur. Elles annulent et remplacent
            toutes les versions précédentes.
        </p>

        <p>
            Les présentes CGV sont publiées en français et en allemand. La version en français fait foi.
        </p>

    </div>

    <div *ngIf="crtLang === 'de'">

        <h1>Plattform Gestinergie 4.0</h1>
        <h2>Allgemeinen Geschäftsbedingungen</h2>

        <small>Version : 1.00</small>

        <h3>1. Einleitung</h3>

        <p>
            Die Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") regeln die Leistungen, die über die IT-Plattform
            Gestinergie 4.0 (nachfolgend auch die "Plattform" oder die "Website") erbracht werden. Es wird darauf
            hingewiesen, dass trotz des in diesem Dokument verwendeten Begriffs "Verkauf" die über die Plattform
            angebotenen Leistungen nicht unter einen Kaufvertrag im Sinne des Obligationenrechts fallen.
        </p>

        <p>
            Die über die Plattform angebotenen Leistungen werden von dem/den nachfolgend bezeichneten Anbieter(n)
            erbracht:
        </p>

        <p><strong>{{cgu.mandant}}</strong> <br/>
            {{cgu.address.street}} {{cgu.address.number}} <br/>
            {{cgu.address.extra}} <br *ngIf="!!cgu.address.extra"/>
            {{cgu.address.zip}} {{cgu.address.locality}} <br/>
            {{cgu.phone}}<br *ngIf="!!cgu.phone"/>
            <a href="mailto:{{cgu.email}}">{{cgu.email}} </a>
        </p>

        <p>
            Diese Bedingungen müssen bei der Registrierung auf der Plattform von registrierten Nutzern oder vor jeder
            Nutzung von nicht registrierten Nutzern akzeptiert werden.
        </p>

        <p>
            Die Plattform kann jederzeit Gegenstand von Aktualisierungen informativer oder technologischer Natur sein.
            Gegebenenfalls behält sich der Anbieter aus diesen oder anderen relevanten Gründen das Recht vor, die
            vorliegenden AGB zu ändern.
        </p>

        <h3>2. Anwendungsbereich</h3>

        <p>
            Die vorliegenden AGB sind Bestandteil der Vertragsbedingungen für die von der Plattform angebotenen
            Dienstleistungen und gelten für das Vertragsverhältnis zwischen dem Anbieter und dem Nutzer (im Folgenden
            "die Parteien"). Sie gelten für alle auf der Plattform durchgeführten Transaktionen, vorbehaltlich etwaiger
            Sonderbestimmungen.
        </p>
        <p>
            Mit der ersten Transaktion auf der Plattform erklärt sich der Nutzer vorbehaltlos bereit, die vorliegenden
            AGB einzuhalten.
        </p>

        <h3>3. Angebotene leistungen</h3>

        <p>
            Der Auftragnehmer stellt dem Nutzer die auf der Plattform Gestinergie 4.0 verfügbaren Dienste gegen Entgelt
            zur Verfügung. Der Anbieter legt den Preis der Dienstleistung sowie die akzeptierten Zahlungsmethoden je
            nach Dienstleistung frei fest.
        </p>

        <p>
            Der Anbieter kann die angebotenen Leistungen jederzeit ändern. Soweit möglich, informiert er die Nutzerinnen
            und Nutzer vorab darüber.
        </p>

        <h3>4. Zahlungsbedingungen</h3>

        <h4> A. Zahlungsmodalitäten</h4>

        <p>
            Der Betrag der Leistung ist in der allgemeinen Beschreibung des Dienstes angegeben, die auf der Plattform
            zugänglich ist.
        </p>
        <p>
            Die auf der Plattform verfügbaren Zahlungsmodalitäten werden für jeden Dienst festgelegt. Bei der Nutzung
            eines kostenpflichtigen Dienstes zeigt die Plattform dem Nutzer den Zahlungsvorgang spätestens bei der
            Bestellung für diesen Dienst an.
        </p>

        <h4>B. Zahlungsmittel</h4>

        <p>
            Die auf der Plattform verfügbaren Zahlungsmittel werden je nach Dienstleistung festgelegt. Die derzeit
            verfügbaren Mittel für Zahlungen sind die folgenden:
        </p>


        <ul>
            <li>Abbuchung vom virtuellen Geldbeutel</li>
            <li>Auf Rechnung</li>
            <li>Via E-Payment</li>
        </ul>


        <h3>5. Rückerstattung</h3>

        <p>
            Jede Rückerstattung ist direkt beim Auftragnehmer zu beantragen.
        </p>

        <h3>6. Zugangsbedingungen</h3>

        <p>
            Die Bedingungen für den Zugang zur Plattform sind in den <a href="/#/cgu">Allgemeinen Nutzungsbedingungen
            festgelegt</a>.
        </p>
        <p>
            Die Zugangsbedingungen für die Bezahlung der Dienste sind wie folgt verfügbar:
        </p>

        <ul>
            <li>Der Nutzer ist auf der Plattform registriert und konsumiert die Dienstleistungen gemäß "4.B
                Zahlungsmittel".
            </li>
            <li>Der Nutzer ist nicht auf der Plattform registriert (Besucher) und konsumiert die Dienste per E-Payment
                oder auf Rechnung gemäss den für den jeweiligen Dienst verfügbaren Auswahlmöglichkeiten.
            </li>
        </ul>

        <h3>7. Datenschutz</h3>

        <p>
            Die vom Auftragnehmer (für die Verarbeitung Verantwortlicher) gesammelten Daten werden nur zu den Zwecken
            der Leistungserbringung und der effizienten Verwaltung der Plattform verarbeitet. Zu diesem Zweck unterliegt
            der Auftragnehmer je nach seinem Status (öffentliche oder gleichgestellte Einrichtung oder private
            Einrichtung) dem kantonalen Gesetz oder dem Bundesgesetz über den Datenschutz.
        </p>
        <p>
            Die Nutzer können die Rechte, die ihnen nach geltendem Recht zustehen, direkt beim Anbieter und unter den im
            obigen einleitenden Kapitel genannten Kontaktdaten geltend machen.
        </p>
        <h3>8. Anwendbares recht und gerichtsstand</h3>

        <p>
            Auf alle Rechtsbeziehungen zwischen dem Anbieter und dem Nutzer und im Zusammenhang mit der Nutzung der
            Plattform findet das Schweizer Recht Anwendung.
        </p>
        <p>
            Der Gerichtsstand für Streitigkeiten ist der Sitz des Auftragnehmers oder der Ort, an dem die Leistung
            erbracht wurde.
        </p>
        <p>
            Die vorliegenden AGB sind ab ihrer Veröffentlichung gültig (die Online-Version auf der Plattform ist
            massgeblich) und gelten ab der ersten Nutzung der Plattform durch den Nutzer. Sie annullieren und ersetzen
            alle vorherigen Versionen.
        </p>

        <p class="info-cgv">
            Die vorliegenden AGB werden in deutscher und französischer Sprache veröffentlicht. Die französische Version
            ist verbindlich.
        </p>

    </div>

    <div *ngIf="crtLang === 'en'">

        <h1>Gestinergie 4.0 Platform</h1>
        <h2>General Terms and Conditions of Sale</h2>

        <small>Version: 1.00</small>

        <h3>1. Introduction</h3>

        <p>
            These General Terms and Conditions of Sale (hereinafter the "GTC") govern the services provided via the
            Gestinergie 4.0 computer platform (hereinafter also the "Platform" or the "Site"). It is specified that,
            despite the term "sale" used in this document, the services offered through the Platform are not covered by
            a sales contract within the meaning of the Code of Obligations.
        </p>

        <p>
            The services offered through the Platform are provided by the following Provider(s):
        </p>

        <p><strong>{{cgu.mandant}}</strong> <br/>
            {{cgu.address.street}} {{cgu.address.number}} <br/>
            {{cgu.address.extra}} <br *ngIf="!!cgu.address.extra"/>
            {{cgu.address.zip}} {{cgu.address.locality}} <br/>
            {{cgu.phone}}<br *ngIf="!!cgu.phone"/>
            <a href="mailto:{{cgu.email}}">{{cgu.email}} </a>
        </p>

        <p>
            These terms and conditions must be accepted upon registration on the Platform by registered Users or before
            each use by unregistered Users.
        </p>

        <p>
            The Platform may be updated at any time for informational or technological reasons. If necessary, for these
            or any other relevant reasons, the Provider reserves the right to modify these GTC.
        </p>

        <h3>2. Field of application</h3>

        <p>
            These GTC are an integral part of the contractual conditions applicable to the services offered by the
            Platform and apply to the contractual relationship between the Provider and the User (hereinafter "the
            parties"). They are applicable to all operations performed on the Platform, subject to any special
            provisions.
        </p>

        <p>
            As soon as the first transaction is carried out on the Platform, the User accepts without reservation to
            comply with the present GTC.
        </p>

        <h3>3. Services offered</h3>

        <p>
            The Provider provides the User with services available on the Gestinergie 4.0 platform, in return for
            payment. The Provider is free to define the price of the service as well as the means of payment accepted
            depending on the services.
        </p>

        <p>
            The Provider may at any time modify the services offered. Insofar as possible, the Provider shall inform the
            Users in advance.
        </p>

        <h3>4. Conditions of payment</h3>

        <h4>A. Terms of payment</h4>

        <p>
            The cost of the service is indicated on the general description of the service accessible on the Platform.
        </p>

        <p>
            The terms of payment available on the Platform are defined for each service. When using a paid service, the
            Platform indicates to the User the payment process at the latest when ordering this service.
        </p>

        <h4>B. Payment methods</h4>

        <p>
            The payment methods available on the Platform are defined according to the services. The methods currently
            available for payment are as follows:
        </p>

        <ul>
            <li>Debit on the virtual wallet</li>
            <li>On invoice</li>
            <li>Via E-payment</li>
        </ul>

        <h3>5.Reimbursement</h3>

        <p>
            Any refund is to be requested directly from the Provider.
        </p>

        <h3>6. Access conditions</h3>

        <p>
            The access conditions to the Platform are defined in the <a href="/#/cgu">general conditions of use</a>.
        </p>

        <p>
            The access conditions for the payment of the services are available as follows:
        </p>
        <ul>
            <li>The User is registered on the Platform and consumes the services according to "4.B Payment methods".
            </li>
            <li>The User is not registered on the Platform (visitor) and consumes the services via E-payment or on
                invoice in accordance with the choices available for the service concerned.
            </li>
        </ul>

        <h3>7. Data protection</h3>

        <p>
            The data collected by the Provider (data controller) is processed solely for the purposes of providing the
            services and efficiently managing the Platform. For this purpose, the Provider is subject, depending on its
            status (public or similar entity or private entity), to the cantonal law or the federal law on data
            protection.
        </p>

        <p>
            The Users may assert the rights granted to them by the applicable legislation directly with the Provider and
            at the contact details indicated in the introduction chapter above.
        </p>

        <h3>8. Applicable law and jurisdiction</h3>

        <p>
            All legal relations between the Provider and the User in connection with the use of the Platform shall be
            governed by Swiss law.
        </p>

        <p>
            The place of jurisdiction in case of a dispute shall be the registered office of the Provider or the place
            where the service is provided.
        </p>

        <p>
            These GTC are valid as soon as they are published (the online version on the Platform is binding) and apply
            as of the first use of the Platform by the User. They cancel and replace all previous versions.
        </p>

        <p class="info-cgv">
            These GTC are published in German and French. The French version is binding.
        </p>


    </div>

    <div *ngIf="crtLang === 'it'">

        <h1>Piattaforma Gestinergie 4.0</h1>
        <h2>Condizioni generali di vendita</h2>

        <small>Versione: 1.00</small>

        <h3>1. Introduzione</h3>

        <p>
            Le presenti Condizioni generali di vendita (denominati di seguito « CGV ») regolano le prestazioni
            effettuate attraverso la piattaforma informatica Gestinergie 4.0 (di seguito ugualmente la « Piattaforma » o
            il « sito »). Si precisa che, nonostante il termine « vendita » utilizzato in questo documento, le
            prestazioni proposte attraverso la Piattaforma non sostituiscono il contratto di vendita nel senso del
            codice delle obbligazioni.
        </p>

        <p>
            Le prestazioni proposte attraverso la Piattaforma sono realizzate da(i)l Fornitore(i) designato(i) come
            segue:
        </p>


        <p><strong>{{cgu.mandant}}</strong> <br/>
            {{cgu.address.street}} {{cgu.address.number}} <br/>
            {{cgu.address.extra}} <br *ngIf="!!cgu.address.extra"/>
            {{cgu.address.zip}} {{cgu.address.locality}} <br/>
            {{cgu.phone}}<br *ngIf="!!cgu.phone"/>
            <a href="mailto:{{cgu.email}}">{{cgu.email}} </a>
        </p>

        <p>
            Le presenti condizioni dovranno essere accettate al momento dell’iscrizione sulla Piattaforma da parte degli
            Utilizzatori registrati o prima di ogni utilizzazione da parte degli Utilizzatori non registrati.
        </p>

        <p>
            La Piattaforma puo’ ad ogni momento essere oggetto di aggiornamento di natura informatica o tecnologica. In
            questo caso, per le ragioni appena citate o per qualsiasi altra ragione pertinente, il Fornitore si riserva
            il diritto di modificare le presenti cgu.
        </p>

        <h3>2. Campo di applicazione</h3>

        <p>
            Le presenti CGV fanno parte integrante delle condizioni contrattuali applicabili alle prestazioni di
            servizio proposte dalla Piattaforma e si applicano alla relazione contrattuale tra il Fornitore e
            l’Utilizzatore (di seguito “le parti”). Sono applicabili a tutte le operazioni realizzate sulla Piattaforma,
            salvo eventuali disposizioni particolari.
        </p>

        <p>
            A partire dalla prima operazione effettuata sulla Piattaforma, l’Utilizzatore accetta senza riserve di
            rispettare le presenti cgu.
        </p>


        <h3>3. Prestazioni proposte</h3>

        <p>
            Il Fornitore mette a disposizione dell’Utilizzatore dei servizi disponibili sulla Piattaforma Gestinergie
            4.0 previa retribuzione. Il Fornitore definisce liberamente il costo della prestazione e i mezzi di
            pagamento accettati in funzione dei servizi.
        </p>

        <p>
            Il Fornitore può ad ogni momento modificare le prestazioni proposte. Ogni qualvolta sia possibile, ne
            informa gli Utilizzatori in anticipo.
        </p>

        <h3>4. Condizioni di pagamento</h3>

        <h4>A. Modalità di pagamento</h4>
        <p>
            L’importo della prestazione è indicato nella descrizione generale del servizio accessibile sulla
            Piattaforma.
        </p>

        <p>
            Le modalità di pagamento disponibili sulla Piattaforma sono specificati per ogni servizio. In caso di
            utilizzo di un servizio a pagamento, la Piattaforma indica all’Utilizzatore la modalità di pagamento al più
            tardi nel momento dell’ordine di tale servizio.
        </p>

        <h4>B. Metodo di pagamento</h4>
        <p>
            I metodi di pagamento disponibili sulla Piattaforma sono definiti in funzione dei servizi. I metodi
            attualmente disponibili per i pagamenti sono i seguenti:
        </p>

        <ul>
            <li>Addebito sul portamonete virtuale</li>
            <li>Fattura</li>
            <li>Pagamento elettronico</li>
        </ul>


        <h3>5. Rimborso</h3>

        <p>
            Tutti i rimborsi devono essere richiesti direttamente al Fornitore.
        </p>

        <h3>6. Condizioni di accesso</h3>

        <p>
            Le condizioni di acesso alla Piattaforma sono definite nelle <a href="/#/cgu">condizioni generali di
            utilizzo</a>.
        </p>

        <p>
            Le condizioni di acesso al pagamento dei servizi sono disponibili come segue :
        </p>

        <ul>
            <li>L’Utilizzatore è registrato sulla Piattaforma ed utilizza i servizi secondo “4.B Metodo di pagamento”
            </li>
            <li>L’Utilizzatore non è registrato sulla Piattaforma (visitatore) e utilizza i servizi attraverso il
                pagamento elettronico o fattura, secondo le scelte disponibili per il servizio selezionato
            </li>
        </ul>

        <h3>7. Protezione dei dati</h3>

        <p>
            I dati raccolti dal Fornitore (responsabile del trattamento) sono utilizzati esclusivamente ai fini della
            fornitura dei servizi e di una gestion efficace della Piattaforma. A tal fine, il Fornitore è soggetto,
            secondo il suo statuto (entità pubblica o comparabile o ente privato), alla legge cantonale o legge federale
            sulla protezione dei dati.
        </p>

        <p>
            Gli Utilizzatori possono far valere i propri diritti ai sensi della legge applicabile direttamente al
            Fornitore e rivolgendosi ai recapiti indicati nel capitolo introduttivo qui sopra.
        </p>

        <h3>8. Diritto applicabile e foro</h3>

        <p>
            Il diritto svizzero si applica a tutte le relazioni giuridiche riguardanti il Fornitore e l’Utilizzatore /
            le parti e relative all’utilizzo della Piattaforma.
        </p>

        <p>
            Il foro in caso di litigio è la sede legale del Fornitore o il luogo di fornitura del servizio.
        </p>

        <p>
            Le presenti CGV sono valide a partire dalla loro pubblicazione (la versione in linea sulla Piattaforma fa
            fede) e si applicano a pieno diritto a qualunque Utilizzatore della Piattaforma. Le condizioni descritte
            annullano e sostituiscono tutte le versioni precedenti.
        </p>

        <p class="info-cgv">
            Le presenti CGV sono pubblicate in francese e in tedesco. La versione di riferimento è quella in francese.
        </p>


    </div>

</div>

<h4 translate="services.manage.map.title"></h4>

<div class="btn-bar">
    <button class="btn btn-primary"
            (click)="openManageMapModal()"
            translate="services.manage.map.edit">
    </button>
</div>

<div *ngIf="mapDto && mapDto.mapUrl" class="row">
    <div class="col-6">
        <img class="img-thumbnail" src="{{mapDto.mapUrl}}" alt="Illustration">
    </div>
</div>
<div *ngIf="!mapDto || !mapDto.mapUrl">
    <div class="col-12">
        <p translate="services.manage.map.missing"></p>
    </div>
</div>

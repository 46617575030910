<div class="modal-header">

    <h4 class="modal-title"
        [translate]="availabilityRange?.id ? 'services.availabilityRange.modal.modifyTitle' : 'services.availabilityRange.modal.addTitle'"></h4>

    <button class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="availabilityRangeForm">
        <div class="row">

            <div class="col-12">
                <app-date-period-picker [isFromRequired]="true"
                                        [periodControl]="dateRange">
                </app-date-period-picker>
            </div>

            <!-- DAYS OF WEEK -->
            <div class="col-12">
                <label class="form-label">{{ 'common.dayOfWeek' | translate }}*</label>

                <div class="form-check form-switch" *ngFor="let day of daysOfWeek; let i = index">
                    <input class="form-check-input" type="checkbox" id="{{ day }}"
                           formArrayName="days"
                           [formControl]="days.get('' + i)"
                           [value]="days.value[i]">
                    <label class="form-check-label" for="{{ day }}">{{ 'common.day.' + day | translate }}</label>
                </div>
            </div>

            <div class="col-6">
                <label class="form-label" for="startTime">{{ 'services.availabilityRange.modal.startTime' | translate }}*</label>
                <ngb-timepicker id="startTime" [formControl]="startTime"></ngb-timepicker>
                <app-form-error [control]="startTime"></app-form-error>
            </div>

            <div class="col-6">
                <label class="form-label" for="endTime">{{ 'services.availabilityRange.modal.endTime' | translate }}*</label>
                <ngb-timepicker id="endTime" [formControl]="endTime"></ngb-timepicker>
                <app-form-error [control]="endTime"></app-form-error>
            </div>

            <app-form-error [control]="timeRange"></app-form-error>

        </div>
    </form>
</div>

<div class="modal-footer">
    <button class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="services.availabilityRange.modal.cancel">
    </button>
    <button class="btn btn-primary"
            (click)="addOrUpdateAvailabilityRange()"
            [disabled]="availabilityRangeForm.invalid || availabilityRangeForm.pristine"
            [translate]="availabilityRange?.id ? 'common.save' : 'common.add'">
    </button>
</div>

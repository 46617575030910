<div class="input-group">
    <input type="text" class="form-control" pattern="{{textInputPattern}}"
           [class.is-invalid]="searchFailed"
           [ngbTypeahead]="search"
           [inputFormatter]="formatter"
           [resultFormatter]="formatter"
           [resultTemplate]="rtEquipment"
           [disabled]="disabled"
           [(ngModel)]="selectedEquipment"
           (selectItem)="selectItem($event)"
           (focus)="searchSubject$.next($event.target['value'])"
           (keyup.enter)="searchSubject$.next($event.target.value)"
           placeholder="{{ 'equipments.events.form.equipment' | translate }}" />

    <span class="input-group-text">
        <button class="btn btn-link btn-sm" *ngIf="!disabled && (selectedEquipment || searchText)"
                title="{{ 'common.tooltip.erase' | translate }}"
                (click)="clearInput()">
            <fa-icon [icon]="icons.erase"></fa-icon>
        </button>
    </span>

    <div class="invalid-feedback" *ngIf="searchFailed" translate="common.search.message.failed"></div>

    <ng-template #rtEquipment let-equipment="result" let-t="term">
        <ngb-highlight [result]="equipment.name" [term]="t"></ngb-highlight>
    </ng-template>
</div>

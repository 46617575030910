import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    AlarmDto,
    AlarmLightDto,
    AlarmService,
    AlarmState,
    CraningServicePricingType
} from '../../../_services/configuration-services';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {NotificationsService} from '../../../_shared/notifications.service';
import {Subscription} from 'rxjs';
import {faCheck, faWrench} from '@fortawesome/free-solid-svg-icons';
import {DateUtils} from '../../../_shared/date-utils';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {TranslateUtils} from '../../../_shared/translate-utils';

@Component({
    selector: 'app-alarm-management-modal',
    templateUrl: './alarm-management-modal.component.html'
})
export class AlarmManagementModalComponent implements OnInit, OnDestroy {

    @Input() alarmId: number;

    AlarmState = AlarmState;

    dateTimeFormat = DateUtils.dateTimeFormat;

    alarm: AlarmDto;

    // Icons
    icons = {
        inProgress: faWrench,
        release: faCheck
    };

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(public activeModal: NgbActiveModal,
                private readonly confirmService: ConfirmModalService,
                private readonly translateService: TranslateService,
                private readonly notificationsService: NotificationsService,
                private readonly alarmService: AlarmService) { }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
            this.crtLang = event.lang;
        });

        if (this.alarmId) {
            this.fetchAlarm();
        }
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    fetchAlarm(): void {
        this.alarmService.getAlarm(this.alarmId).pipe().subscribe(alarm => {
            this.alarm = alarm;
        });
    }

    setAlarmInProgress(alarm: AlarmLightDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.inProgress'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.alarmService.setAlarmInProgressAlarm(alarm.id).pipe().subscribe(_ => {
                    this.notificationsService.success({message: 'alarms.management.notification.setInProgressSuccess'});
                    this.fetchAlarm();
                });
            }
        });
    }

    releaseAlarm(alarm: AlarmLightDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.release'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.alarmService.releaseAlarm(alarm.id).pipe().subscribe(_ => {
                    this.notificationsService.success({message: 'alarms.management.notification.releasedSuccess'});
                    this.fetchAlarm();
                });
            }
        });
    }

    protected readonly CraningServicePricingType = CraningServicePricingType;
}

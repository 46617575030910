import {Component} from '@angular/core';
import {FormControl, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {InternalDatatransService} from "../../../_services/internal-datatrans.service";
import {FormUtils} from "../../../_shared/form-utils";
import {CustomerConfigService} from '../../../_shared/customer-config-service';

@Component({
    selector: 'app-reload-wallet-modal',
    templateUrl: './reload-wallet-modal.component.html',
})
export class ReloadWalletModalComponent {

    FormUtils = FormUtils;
    reloadWalletForm: UntypedFormGroup;

    constructor(public reloadWalletModal: NgbActiveModal,
                private readonly internalDatatransService: InternalDatatransService,
                private readonly customerConfigService: CustomerConfigService) {

        this.reloadWalletForm = new FormGroup(
            {
                amount: new FormControl(customerConfigService.minimumWalletCreditAmount, [Validators.required, Validators.min(customerConfigService.minimumWalletCreditAmount)])
            });
    }

    startDatatransPayment(): void {
        const amntInCent = this.amount.value * 100;
        this.internalDatatransService.payEwallet(amntInCent);
        this.reloadWalletModal.close()
    }

    get amount(): FormControl<number> {
        return this.reloadWalletForm.get('amount') as FormControl;
    }

}

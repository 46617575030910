<h3 translate>services.craning.booking.calendar.title</h3>

<form [formGroup]="searchBookingForm" *ngIf="craningBookingUtils.hasAdminView()">
    <div class="form-group row mb-4">
        <label class="form-label m-auto" for="searchTerms">{{ 'services.craning.booking.calendar.search.booking.label' | translate }}</label>
        <input class="form-control" id="searchTerms" name="searchTerms" formControlName="searchTerms" type="text"
               pattern="{{textInputPattern}}"
               placeholder="{{ 'services.craning.booking.calendar.search.booking.placeholder' | translate }}">
    </div>
</form>

<full-calendar
    *ngIf="calendarOptions"
    #calendar
    [options]="calendarOptions"></full-calendar>

<ng-template #calendarPopoverTmpl let-event="event">
    <h6>{{ getStateName(event.extendedProps.slot) }}: <span class="small">{{ event.start  | date:'HH:mm'}} - {{ event.end  | date:'HH:mm'}}</span></h6>

    <ng-container *ngIf="(craningBookingUtils.hasAdminView() || (event.extendedProps.slot.isMine)) && (event.extendedProps.slot.state === CraningBookingSlotState.Paid || event.extendedProps.slot.state === CraningBookingSlotState.Booked)">
        <p><strong>{{ event.extendedProps.slot.firstName}} {{ event.extendedProps.slot.lastName}}</strong></p>
        <p translate>{{ 'services.craning.slots.popover.boatRegistrationNumber' | translate }} : <strong>{{ event.extendedProps.slot.boatRegistrationNumber}}</strong></p>
        <ng-container *ngIf="!!event.extendedProps.slot.email" translate><span>{{ 'services.craning.slots.popover.email' | translate }} : <strong>{{ event.extendedProps.slot.email}}</strong></span><br/></ng-container>
        <ng-container *ngIf="!!event.extendedProps.slot.mobilePhone" translate><span>{{ 'services.craning.slots.popover.mobilePhone' | translate }} : <strong>{{ event.extendedProps.slot.mobilePhone}}</strong></span><br/></ng-container>
        <ng-container *ngIf="!!event.extendedProps.slot.phonePrivate" translate><span>{{ 'services.craning.slots.popover.phonePrivate' | translate }} : <strong>{{ event.extendedProps.slot.phonePrivate}}</strong></span><br/></ng-container>
        <ng-container *ngIf="!!event.extendedProps.slot.phoneProfessional" translate><span>{{ 'services.craning.slots.popover.phoneProfessional' | translate }} : <strong>{{ event.extendedProps.slot.phoneProfessional}}</strong></span></ng-container>
    </ng-container>
</ng-template>

<div class="content-container bg-white-no-shadow">

    <app-view-lights [serviceId]="serviceId"></app-view-lights>

    <hr class="mt-4 mb-4">

    <app-view-light-zones [serviceId]="serviceId"></app-view-light-zones>

    <hr class="mt-4 mb-4">

    <app-light-zones-schedule-control [serviceId]="serviceId"></app-light-zones-schedule-control>
</div>

<div class="nav-menu dark" [appHasAnyRole]="[AppRoles.admin, AppRoles.alarmManager]">

    <ul class="navbar-nav">
        <li class="nav-item">
            <div class="menu">
                <a class="nav-link" href="#"
                   [ngClass]='{"selected": alertManagementLink.isActive}'
                   [routerLink]="['alert-management']"
                   routerLinkActive
                   #alertManagementLink="routerLinkActive">
                    <span translate="menu.navigation.alerts.alertManagement"></span>
                </a>
            </div>
        </li>
        <li class="nav-item">
            <div class="menu">
                <a class="nav-link" href="#"
                   [ngClass]='{"selected": alertHistoryLink.isActive}'
                   [routerLink]="['alert-history']"
                   routerLinkActive
                   #alertHistoryLink="routerLinkActive">
                    <span translate="menu.navigation.alerts.alertHistory"></span>
                </a>
            </div>
        </li>
        <li class="nav-item">
            <div class="menu">
                <a class="nav-link" href="#"
                   [ngClass]='{"selected": alertDeviceManagement.isActive}'
                   [routerLink]="['alert-device-management']"
                   routerLinkActive
                   #alertDeviceManagement="routerLinkActive">
                    <span translate="menu.navigation.alerts.alertDeviceManagement"></span>
                </a>
            </div>
        </li>
    </ul>
</div>

<router-outlet></router-outlet>

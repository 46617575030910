import {Component, Input, OnInit} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {faCalendar, faTimes} from '@fortawesome/free-solid-svg-icons';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';
import {BookingTimeUnit} from '../../../_services/configuration-services';
import {DateUtils} from '../../date-utils';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-date-time-period-picker',
    templateUrl: './date-time-period-picker.component.html'
})
export class DateTimePeriodPickerComponent implements OnInit {

    @Input() dateTimeForm: UntypedFormGroup;

    @Input() disableStartTime: boolean = false;
    @Input() disableEndDateTime: boolean = false;
    @Input() disableFullForm: boolean = false;
    @Input() canStartInPast: boolean = false;

    @Input() bookingDuration: number | null = null;
    @Input() bookingTimeUnit?: BookingTimeUnit | null = null;

    @Input() baseStartDate?: NgbDateStruct | null = null;
    @Input() baseEndDate?: NgbDateStruct | null = null;
    @Input() baseStartTime?: NgbTimeStruct | null = null;
    @Input() baseEndTime?: NgbTimeStruct | null = null;

    @Input() minStartDate?: NgbDateStruct | null = null;
    @Input() maxStartDate?: NgbDateStruct | null = null;

    icons = {
        calendar: faCalendar,
        times: faTimes
    };

    constructor(private readonly datePipe: DatePipe) {
    }

    ngOnInit(): void {

        // Configure fields
        this.startDate.setValue(this.baseStartDate);
        this.startTime.setValue(this.baseStartTime);
        this.endDate.setValue(this.baseEndDate);
        this.endTime.setValue(this.baseEndTime);

        // Disable fields
        if (this.disableFullForm) {
            this.dateTimeForm.disable();

        } else {

            if (this.disableStartTime) {
                this.startTime.disable();
            }

            if (this.disableEndDateTime) {
                this.endTime.disable();
            }
        }

        // Manage form validation
        this.checkValidity();

        this.startDate.valueChanges.pipe().subscribe(_ => {
            this.checkValidity();
        });
        this.startTime.valueChanges.pipe().subscribe(_ => {
            this.checkValidity();
        });
        this.endDate.valueChanges.pipe().subscribe(_ => {
            this.checkValidity();
        });
        this.endTime.valueChanges.pipe().subscribe(_ => {
            this.checkValidity();
        });
    }

    public updatePeriod(startDate: NgbDateStruct, endDate: NgbDateStruct, startTime: NgbTimeStruct, endTime: NgbTimeStruct) {
        this.startDate.setValue(startDate);
        this.endDate.setValue(endDate);
        this.startTime.setValue(startTime);
        this.endTime.setValue(endTime);
    }

    public changeDateTimeState(state: boolean): void {
        if (this.disableFullForm) {
            return;
        }

        this.disableEndDateTime = !state;

        if (state) {
            this.endTime.enable();
            this.endDate.enable();

        } else {
            this.endTime.disable();
            this.endDate.disable();
        }
    }

    private calculateAndSetEndDate(): void {
        const startDateTime = new Date(DateUtils.ngbDateStructToDate(this.startDate.value).setHours(this.startTime.value.hour, this.startTime.value.minute));
        const endDateTime = new Date(startDateTime);

        switch (this.bookingTimeUnit) {
            case BookingTimeUnit.Minute:
                endDateTime.setMinutes(endDateTime.getMinutes() + this.bookingDuration);
                break;
            case BookingTimeUnit.Hour:
                endDateTime.setHours(endDateTime.getHours() + this.bookingDuration);
                break;
            case BookingTimeUnit.Day:
                endDateTime.setDate(endDateTime.getDate() + this.bookingDuration);
                break;
            case BookingTimeUnit.Month:
                endDateTime.setMonth(endDateTime.getMonth() + this.bookingDuration);
                break;
            case BookingTimeUnit.Year:
                endDateTime.setFullYear(endDateTime.getFullYear() + this.bookingDuration);
                break;
        }

        this.endDate.setValue(DateUtils.dateToNgbDateStruct(endDateTime), {emitEvent: false});
        this.endTime.setValue({
            hour: endDateTime.getHours(),
            minute: endDateTime.getMinutes(),
            second: 0
        }, {emitEvent: false});
    }

    public checkValidity(): void {
        this.checkDatesValidity();

        if (!this.startDate.value) {
            this.startDate.setErrors({'ngbDate': true});
        }
        if (!this.endDate.value) {
            this.endDate.setErrors({'ngbDate': true});
        }

        if (!this.startTime.value) {
            this.startTime.setErrors({'ngbDate': true});
        }
        if (!this.endTime.value) {
            this.endTime.setErrors({'ngbDate': true});
        }
    }

    public checkDatesValidity(): void {
        this.startTime.setErrors(null);
        this.endTime.setErrors(null);
        this.startDate.setErrors(null);
        this.endDate.setErrors(null);

        if (this.disableFullForm) {
            return;
        }

        // Calculate the end date
        if (this.startDate.value && this.disableEndDateTime && !!this.bookingTimeUnit && !!this.bookingDuration) {
            this.calculateAndSetEndDate();
        }

        this.dateTimeForm.updateValueAndValidity();

        // No start Date => no check
        if (!this.startDate.value || !this.startTime.value) {
            return;
        }

        // Check start Date
        if (!(!!this.startDate.value && DateUtils.isDateValid(this.startDate.value))) {
            this.startDate.markAsDirty()
            this.startDate.setErrors({'ngbDate': true});
        }

        if (this.maxStartDate && !DateUtils.beforeOrEquals(this.startDate.value, this.maxStartDate)) {
            this.startDate.markAsDirty();
            const maxDate = this.datePipe.transform(new Date(DateUtils.ngbDateStructToDate(this.maxStartDate)), DateUtils.dateFormat);
            this.startDate.setErrors({startDateTooLate: {max: maxDate}});
        }

        const startDateTime = new Date(DateUtils.ngbDateStructToDate(this.startDate.value)
          .setHours(this.startTime.value.hour, this.startTime.value.minute));

        if (startDateTime < new Date() && !this.canStartInPast) {
            this.startTime.markAsDirty()
            this.startTime.setErrors({'dateInPast': true});
        }

        if (!this.endDate.value || !this.endTime.value) {
            return;

        } else {
            // Check Dates
            if (!(!!this.endDate.value && DateUtils.isDateValid(this.endDate.value))) {
                this.endDate.markAsDirty();
                this.endDate.setErrors({'ngbDate': true});
            }
        }
    }

    get startDate(): UntypedFormControl {
        return this.dateTimeForm.get('startDate') as UntypedFormControl;
    }

    get endDate(): UntypedFormControl {
        return this.dateTimeForm.get('endDate') as UntypedFormControl;
    }

    get startTime(): UntypedFormControl {
        return this.dateTimeForm.get('startTime') as UntypedFormControl;
    }

    get endTime(): UntypedFormControl {
        return this.dateTimeForm.get('endTime') as UntypedFormControl;
    }

    get dateTime(): UntypedFormGroup {
        return this.dateTimeForm;
    }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TariffModalComponent} from './tariff-modal/tariff-modal.component';
import {
    PaymentTypes,
    RoleDto, RoleService, ServiceConfigDto,
    TariffDto,
    TariffService, TariffType, TimeOfPayment
} from '../../../_services/configuration-services';
import {NotificationsService} from '../../../_shared/notifications.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {DateUtils} from '../../../_shared/date-utils';
import {ConfirmModalService} from '../../../_shared/_components/confirm-modal/confirm-modal.component';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../../../_shared/translate-utils';

@Component({
  selector: 'app-tariff-management',
  templateUrl: './tariff-management.component.html'
})
export class TariffManagementComponent implements OnInit, OnDestroy {

    @Input() serviceConfig!: ServiceConfigDto;
    @Input() serviceId!: string;

    TariffType = TariffType;
    TimeOfPayment = TimeOfPayment;
    PaymentTypes = PaymentTypes;

    tariffs: TariffDto[] = [];
    roles: RoleDto[] = [];

    faPen = faPen;
    faTrash = faTrash;

    private langChangeSubscription$: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    constructor(private readonly tariffService: TariffService,
                private readonly roleService: RoleService,
                private readonly notificationsService: NotificationsService,
                private readonly confirmService: ConfirmModalService,
                private readonly translateService: TranslateService,
                private readonly datepipe: DatePipe,
                private readonly modalService: NgbModal) { }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription$ = this.translateService.onLangChange.subscribe(_ => {
            this.crtLang = this.translateService.currentLang;
        });

        this.roleService.getAllRoles(false).pipe().subscribe(roles => {
            this.roles = roles;
            this.fetchTariffs();
        });
    }

    ngOnDestroy(): void {
        this.langChangeSubscription$.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    createTariff(): void {
        if (this.serviceConfig.tariffConfigs.length > 0) {
            const modal = this.modalService.open(TariffModalComponent, {centered: true});
            modal.componentInstance.serviceConfig = this.serviceConfig;
            modal.componentInstance.serviceId = this.serviceId;
            modal.componentInstance.defaultTariff = this.tariffs.filter(t => t.defaultTariff)[0] ?? null;
            modal.result
                .then(value => {
                    if (value === 'success') {
                        this.fetchTariffs();
                    }
                }, () => { /* catch the rejection */ });
        } else {
            this.notificationsService.error({message: 'services.tariff.notifications.noTariffConfig'});
        }
    }

    updateTariff(tariff: TariffDto): void {
        if (this.serviceConfig.tariffConfigs.length > 0) {
            const modal = this.modalService.open(TariffModalComponent, {centered: true});
            modal.componentInstance.serviceConfig = this.serviceConfig;
            modal.componentInstance.serviceId = this.serviceId;
            modal.componentInstance.defaultTariff = this.tariffs.filter(t => t.defaultTariff)[0] ?? null;
            modal.componentInstance.tariff = tariff;
            modal.componentInstance.containsMultipleTariffs = this.tariffs.length > 1;
            modal.result
                .then(value => {
                    if (value === 'success') {
                        this.fetchTariffs();
                    }
                }, () => { /* catch the rejection */ });
        } else {
            this.notificationsService.error({message: 'services.tariff.notifications.noTariffConfig'});
        }
    }

    deleteTariff(tariff: TariffDto): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.tariffService.deleteTariff(tariff.id).pipe().subscribe(_ => {
                    this.notificationsService.success({title: 'services.tariff.notifications.deleteSuccess'});
                    this.fetchTariffs();
                });
            }
        });
    }

    canDeleteTariff(tariff: TariffDto): boolean {
        if (this.tariffs.length === 1) {
            return true;
        }
        return !tariff.defaultTariff;
    }

    getTranslatedRoles(roles: string[]): string {
        const translatedRoles = roles.map(roleKey => {
            const role = this.roles.find(r => r.roleKey === roleKey);

            if (!role) {
                return null;
            }

            if (role.isBase) {
                return this.translateService.instant('roles.enum.' + role.roleKey.toLowerCase());

            } else {
                return role['label' + this.crtLang.toUpperCase()];
            }
        });

        return translatedRoles.filter(r => !!r).join(', ').trim();
    }

    getValidity(validityFrom: Date, validityTo: Date): string {
        const validityFromStr = this.datepipe.transform(validityFrom, DateUtils.dateFormat);
        const validityToStr = this.datepipe.transform(validityTo, DateUtils.dateFormat);
        if (validityFromStr != null && validityToStr == null) {
            return this.translateService.instant('services.tariff.table.availableFrom', {ValidityFrom: validityFromStr});

        } else if (validityFromStr == null && validityToStr != null) {
            return this.translateService.instant('services.tariff.table.availableTo', {ValidityTo: validityToStr});

        } else if (validityFromStr != null && validityToStr != null) {
            return this.translateService.instant('services.tariff.table.availableFromTo',
                {ValidityFrom: validityFromStr, ValidityTo: validityToStr});
        }
        return '';
    }

    private fetchTariffs(): void {
        this.tariffService.getTariffsForService(this.serviceId).pipe().subscribe(tariffs => {
            this.tariffs = tariffs;
        });
    }
}


<div class="modal-header">
    <h4 class="modal-title" translate="transactions.modal.import.title"></h4>

    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.close()">
    </button>
</div>

<div class="modal-body">

    <form [formGroup]="importFileFormGroup">
        <div class="row">

            <div class="col-12">
                <label class="form-label" for="file">{{ 'transactions.modal.import.file' | translate }}*</label>
                <input id="file" type="file" class="form-control"
                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                       (change)="fileSelected($event.target['files'])">
                <app-form-error [control]="file"></app-form-error>
            </div>

        </div>
    </form>

</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="activeModal.close()"
            translate="services.availabilityRange.modal.cancel">
    </button>
    <button type="button" class="btn btn-primary"
            [disabled]="importFileFormGroup.invalid"
            (click)="import()"
            translate="services.actions.import">
    </button>
</div>

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RouterService {

    constructor(private readonly router: Router) {}

    public getObjectFromRouteState(key: string, includePreviousNavigation = false): any {
        let result = null;

        let navigationExtrasState = this.router.getCurrentNavigation()?.extras?.state ?? null;
        if (navigationExtrasState) {
            result = navigationExtrasState[key] ?? null;
        }

        if (includePreviousNavigation && result === null) {
            navigationExtrasState = this.router.getCurrentNavigation()?.previousNavigation?.extras?.state ?? null;
            if (navigationExtrasState) {
                result = navigationExtrasState[key] ?? null;
            }
        }
        return result;
    }
}
